import React, { useEffect, useState, forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ContextMenuTrigger } from 'react-contextmenu';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { modalOpen, modalType } from '../../redux/actions';
import theme, { rem } from '../../theme';
import Group from '../Group';
import Text from '../Text';
import ListItem from '../ListItem';
import Switch from '../Switch';
import Chip from '../Chip';
import ScrollSpyNav from '../ScrollSpyNav';
import ScrollSpyContent from '../ScrollSpyContent';
import Grid from '../Grid';
import FilterSearch from '../FilterSearch';
import Badge from '../Badge';
import withMemo from '../../utils/withMemo';
import SearchBar from '../SearchBar';
import CustomToast from '../CustomToast';
import Button from '../Button';
import firebase, {
  acceptRequest,
  declineRequest,
  undoRequest,
  userListner,
  removeFriend,
  addNotification,
  dashboardListner,
  inviteUser,
} from '../../firebase';
import Animation from '../LottieControl';
import ContextMenuItems from '../ContextMenuItems';

const StyledMainWrapper = styled.div`
  ${({ elementDimensions }) => `
    transition: 0.4s;
    position: absolute;
    left: 0;
    opacity: 0;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: ${rem(10)};
  `}
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: ${rem(10)};
`;

const StyledCard = styled.div`
  ${({ noPadding, customPadding }) => `
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: ${noPadding ? 0 : customPadding ? customPadding : rem(20)};
    background: rgba(0, 0, 0, 0.3);
    border-radius: ${rem(10)};
    margin-bottom: ${rem(15)};
    margin-top: ${rem(5)};
  `}
`;

const StyledInnerWrapper = styled.div`
  padding: 0 ${rem(20)} ${rem(20)} ${rem(20)};
  margin-top: -0.5rem;
`;

const StyledGroup = styled(Group)`
  flex-direction: column;
  margin-bottom: ${rem(10)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledGridWrapper = styled(Grid)`
  height: ${rem(300)};
`;

const StyledUserSearch = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledRolesWrapper = styled(Group)`
  flex-wrap: wrap;
  display: inline-flex;
  flex-direction: row;
`;

const StyledButton = styled(Button)`
  margin-top: -${rem(8)};
  min-width: ${rem(30)};
  min-height: ${rem(30)};
`;

const StyledIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${theme.color.light[8]};
  width: ${rem(30)};
  height: ${rem(30)};
  transition: 0.2s;
  position: absolute;
  right: 0;
`;

const StyledIndicatorLabelText = styled(Text)`
  width: auto;
`;

const StyledIndicatorText = styled(Text)`
  font-weight: 600;
  font-size: ${rem(10)};
`;

const StyledIndicatorWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledAnimation = styled(Animation)`
  cursor: help;
`;

const StyledTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${rem(20)};
  width: ${rem(250)};
`;

const StyledTooltip = styled(ReactTooltip)`
  border-radius: ${rem(5)} !important;
  padding: ${rem(5)} ${rem(10)} !important;
`;

const StyledToolTipText = styled(Text)`
  margin-bottom: ${rem(15)};
`;

const StyledToolTipAnimation = styled(Animation)`
  margin-bottom: ${rem(15)};
`;

const StyledChip = styled(Chip)`
  margin-bottom: ${rem(8)};
`;

const StyledSwitchWrapper = styled(Group)`
  margin-bottom: ${rem(20)};
  align-items: flex-start;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledText = styled(Text)`
  font-size: ${rem(13)};
`;

const StyledSubText = styled(Text)`
  font-size: ${rem(11)};
`;

const StyledEmptyText = styled(Text)`
  width: auto;
  margin-top: -0.5rem;
  margin-right: ${rem(10)};
  font-style: italic;
`;

/**
 * Overview component
 *
 */
const AdminSettings = forwardRef((props, ref) => {
  const { activeDashboardId, elementDimensions, menuColor } = props;

  //States
  const [roleInfo, setRoleInfo] = useState({});
  const [permissionInfo, setPermissionInfo] = useState({});
  const [totalRoles, setTotalRoles] = useState();
  const [maxRoles, setMaxRoles] = useState();
  const [maxExceeded, setMaxExceeded] = useState(false);
  //permission States
  const [permissionResize, setPermissionResize] = useState(false);
  const [permissionDrag, setPermissionDrag] = useState(false);
  const [permissionSubitems, setPermissionSubitems] = useState(false);
  const [permissionMenuItem, setPermissionMenuItem] = useState(false);
  const [permissionEverybody, setPermissionEverybody] = useState(false);

  //UserId
  const uuid = firebase.auth().currentUser.uid;

  //Translation
  const [t] = useTranslation(['tooltip', 'text', 'emptystate']);

  //Callback
  const onClickHandler = useCallback(id => {
    props.modalOpen(true);
    props.modalType(['editRoles', activeDashboardId, id]);
  }, []);

  /** Info click handler */
  const infoClickHandler = (type, id) => {
    props.modalOpen(true);
    props.modalType(['updateRole', activeDashboardId, type, id]);
  };

  /** Create clidk handler */
  const createClickHandler = () => {
    props.modalOpen(true);
    props.modalType(['createRole', activeDashboardId]);
  };

  useEffect(() => {
    listnerSetup();
  }, []);

  /** Save permissions */
  const savePermissions = () => {
    let saveObject = {
      everybody: permissionEverybody,
    };

    //Open modal
    props.modalOpen(true);
    props.modalType(['savePermissions', activeDashboardId, saveObject]);
  };

  /** Setup the listners */
  const listnerSetup = () => {
    dashboardListner
      .child(activeDashboardId)
      .once('value', snap => {
        if (snap.val()) {
          setMaxRoles(snap.val().maxRoles);
        }
      })
      .then(parentData => {
        dashboardListner
          .child(activeDashboardId)
          .child('roles')
          .on('value', snapShot => {
            if (snapShot.val()) {
              setRoleInfo(snapShot.val());
              setTotalRoles(Object.keys(snapShot.val()).length);
              if (
                Object.keys(snapShot.val()).length >=
                (maxRoles ? maxRoles : parentData.val().maxRoles)
              ) {
                setMaxExceeded(true);
              } else {
                setMaxExceeded(false);
              }
            } else {
              setRoleInfo({});
              setTotalRoles('0');
            }
          });
      })
      .then(() => {
        dashboardListner
          .child(activeDashboardId)
          .child('permissions')
          .on('value', snapShot => {
            if (snapShot.val()) {
              // setPermissionResize(snapShot.val().resize);
              // setPermissionDrag(snapShot.val().drag);
              // setPermissionSubitems(snapShot.val().subitems);
              // setPermissionMenuItem(snapShot.val().menuitem);
              setPermissionEverybody(snapShot.val().everybody);
            } else {
              setPermissionInfo({});
            }
          });
      });
  };

  return (
    <StyledMainWrapper
      ref={ref}
      elementDimensions={elementDimensions}
      {...props}
    >
      <Grid>
        <StyledWrapper>
          <StyledInnerWrapper menuColor={menuColor}>
            <ScrollSpyNav
              fixed
              itemId="dashboard"
              iconColor="#fff"
              icons="verified_user assignment_ind people"
              indicators="verified_user assignment_ind people"
              scrollTos="scrollAdminSettings scrollRolesSettings scrollEverybodySettings"
              // badges={`empty ${!newInvite ? 'information' : 'empty'} empty`}
              toolTips={t('menu.admin.tooltips')}
            />
            <ScrollSpyContent scrollIds="scrollAdminSettings scrollRolesSettings scrollEverybodySettings">
              <div>
                <Text
                  align="left"
                  text={t('text:menu.labels.admin.roles')}
                  textStyle="h6"
                  textColor="#fff"
                />
                <StyledCard noPadding>
                  <StyledGridWrapper columns="1fr" parentId="dashboardMembers">
                    <StyledUserSearch>
                      <FilterSearch
                        noPadding
                        hasButtons={false}
                        id="admin"
                        type="admin"
                        elementDimensions={{ width: rem(270) }}
                        backgroundColor="transparent"
                        onItemClick={id => onClickHandler(id)}
                        //setActive={newSelectedUsers}
                        activeDashboardId={activeDashboardId}
                        rightIcon={{ icon: 'more_vert', size: 24 }}
                      />
                    </StyledUserSearch>
                  </StyledGridWrapper>
                </StyledCard>
              </div>

              <div>
                <Group>
                  <StyledIndicatorLabelText
                    align="left"
                    text={t('text:menu.labels.admin.rolespermissions')}
                    textStyle="h6"
                    textColor="#fff"
                  />
                  {maxExceeded && (
                    <React.Fragment>
                      <StyledAnimation
                        animation="alert"
                        animationWidth={30}
                        animationHeight={30}
                        data-for="tooltipMenuDashboardRoles"
                        data-tip=""
                      />
                      <StyledTooltip
                        id="tooltipMenuDashboardRoles"
                        getContent={() => {
                          return (
                            <StyledTooltipWrapper>
                              <StyledToolTipText
                                text={t('errors.roles.title')}
                                textStyle="h6"
                                textColor="#fff"
                                align="center"
                              />
                              <StyledToolTipAnimation
                                animation="cry"
                                animationWidth={rem(100)}
                                animationHeight={rem(100)}
                              />
                              <Text
                                text={t('errors.roles.text')}
                                textStyle="subtitle"
                                textColor="#fff"
                                align="center"
                              />
                            </StyledTooltipWrapper>
                          );
                        }}
                      />
                    </React.Fragment>
                  )}

                  <StyledIndicator>
                    <StyledIndicatorText
                      text={`${totalRoles}/${maxRoles}`}
                      //text="2/20"
                      textColor={theme.color.light[100]}
                      align="center"
                    />
                  </StyledIndicator>
                </Group>
                <StyledCard
                  customPadding={`${rem(20)} ${rem(20)} ${rem(10)} ${rem(20)}`}
                >
                  <StyledRolesWrapper fullWidth>
                    {roleInfo && Object.keys(roleInfo).length > 0 ? (
                      Object.entries(roleInfo).map(role => {
                        return (
                          <StyledChip
                            id={`chip-overview-${role[1].id}`}
                            text={role[0]}
                            backgroundColor={
                              role[1].details && role[1].details.color
                            }
                            key={role[1].id}
                            type="info"
                            onClick={() =>
                              infoClickHandler(role[0], role[1].id)
                            }
                          />
                        );
                      })
                    ) : (
                      <StyledEmptyText
                        text={t('emptystate:noroles.title')}
                        textColor="rgba(255, 255, 255, 0.7)"
                        textStyle="description"
                      />
                    )}
                    {!maxExceeded && (
                      <StyledButton
                        id="addNewRole"
                        disabled={maxExceeded ? true : false}
                        variant="icon"
                        onClick={() => createClickHandler()}
                        bgColor={theme.color.light[40]}
                        icon={{
                          size: 20,
                          padding: rem(0),
                          icon: 'add',
                          color: '#fff',
                        }}
                      />
                    )}
                  </StyledRolesWrapper>
                </StyledCard>
              </div>

              <div>
                <Text
                  align="left"
                  text={t('text:menu.labels.admin.everybody')}
                  textStyle="h6"
                  textColor="#fff"
                />
                <StyledCard>
                  <StyledGroup fullWidth>
                    <StyledSwitchWrapper>
                      <Group fullwidth>
                        <StyledText
                          align="left"
                          text={t('text:menu.labels.admin.allPermissions')}
                          textColor="#fff"
                          textStyle="h6"
                        />
                        <StyledSubText
                          align="left"
                          text={t('text:menu.text.admin.allPermissions')}
                          textColor="rgba(255, 255, 255, 0.7)"
                        />
                      </Group>
                      <Switch
                        name="everybodyRuleSwitch"
                        value="everybodyRuleSwitch"
                        label=""
                        checked={permissionEverybody}
                        onClick={() =>
                          setPermissionEverybody(!permissionEverybody)
                        }
                        fullWidth={false}
                        sliderBackground={theme.color.light[40]}
                        noMargin
                      />
                    </StyledSwitchWrapper>
                    <Button
                      fullWidth
                      onClick={() => savePermissions()}
                      bgColor={theme.color.success}
                      text={{
                        text: t('text:menu.button.admin.apply'),
                        textColor: '#fff',
                        textStyle: 'overline',
                        align: 'center',
                      }}
                    />
                  </StyledGroup>
                </StyledCard>
              </div>
            </ScrollSpyContent>
          </StyledInnerWrapper>
        </StyledWrapper>
      </Grid>
    </StyledMainWrapper>
  );
});

AdminSettings.propTypes = {
  activeDashboardId: PropTypes.string,
  elementDimensions: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  menuColor: PropTypes.string,
};

AdminSettings.defaultProps = {
  activeDashboardId: null,
  elementDimensions: null,
  menuColor: null,
};

export default connect(
  null,
  {
    modalOpen,
    modalType,
  },
  null,
  { forwardRef: true }
)(withMemo(AdminSettings, ['elementDimensions']));
