import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { modalType, modalOpen } from '../../redux/actions';
import Group from '../Group';
import Button from '../Button';
import Animation from '../LottieControl';
import Text from '../Text';
import TextField from '../TextField';
import firebase, { registerUser } from '../../firebase';
import theme, { rem } from '../../theme';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(10)};
`;

const StyledContentWrapper = styled(Group)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledGroup = styled(Group)`
  flex-direction: column;
  margin-bottom: ${rem(10)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledButton = styled(Button)`
  margin: ${rem(20)} 0;
`;

const StyledInlineGroup = styled(Group)`
  display: inline-flex;
`;

const StyledErrorText = styled(Text)`
  background-color: salmon;
  color: darkred;
  padding: 8px;
  border-radius: 2px;
  border: 1px solid darkred;
  margin-top: 7px;
`;

/** todo */
const registration = props => {
  //Reft
  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmationRef = useRef();

  //states
  const [errorMessage, setErrorMessage] = useState('');

  /**
   * onSubmit register
   */
  const onSubmitRegister = (
    username,
    email,
    password,
    passwordConfirmation
  ) => {
    if (username && email && password && passwordConfirmation) {
      if (password === passwordConfirmation) {
        firebase
          .auth()
          .createUserWithEmailAndPassword(email, password)
          .then(createdUser => {
            createdUser.user
              .updateProfile({
                displayName: username,
              })
              .then(() => {
                registerUser(createdUser);
              });
          })
          .catch(function(error) {
            setErrorMessage(error.message);
          });
      } else {
        setErrorMessage('password and password confirmation do not match');
      }
    } else {
      setErrorMessage('one of the required fields is missing');
    }
  };

  /** Return Chat messages */
  return (
    <StyledWrapper>
      <StyledContentWrapper fullwidth>
        <StyledText
          align="center"
          text="Sign up!"
          textColor="#fff"
          textStyle="h3"
          tag="h1"
        />

        <StyledText
          align="center"
          text="We would love to seen you using StudentsPlus online dashboard and by the way.. it's free!"
          textColor={theme.color.light[80]}
          tag="p"
        />

        <Animation
          animation="funny"
          animationHeight={rem(150)}
          animationWidth={rem(150)}
        />

        <StyledGroup fullwidth>
          <Text
            align="left"
            text="Username"
            textStyle="description"
            textColor="#fff"
          />
          <TextField
            placeholdercolor="#fff"
            backgroundcolor={theme.color.dark[40]}
            isFocused
            textcolor="#fff"
            inputId="modalName"
            placeholder=""
            borders={false}
            value=""
            ref={nameRef}
            type="text"
          />
        </StyledGroup>
        <StyledGroup fullwidth>
          <Text
            align="left"
            text="Email"
            textStyle="description"
            textColor="#fff"
          />
          <TextField
            placeholdercolor="#fff"
            backgroundcolor={theme.color.dark[40]}
            textcolor="#fff"
            inputId="modalEmail"
            placeholder=""
            borders={false}
            value=""
            ref={emailRef}
            type="email"
          />
        </StyledGroup>
        <StyledGroup fullwidth>
          <Text
            align="left"
            text="Password"
            textStyle="description"
            textColor="#fff"
          />
          <TextField
            placeholdercolor="#fff"
            backgroundcolor={theme.color.dark[40]}
            textcolor="#fff"
            inputId="modalPassword"
            placeholder=""
            borders={false}
            value=""
            ref={passwordRef}
            type="password"
          />
        </StyledGroup>

        <StyledGroup fullwidth>
          <Text
            align="left"
            text="Confirm password"
            textStyle="description"
            textColor="#fff"
          />
          <TextField
            placeholdercolor="#fff"
            backgroundcolor={theme.color.dark[40]}
            textcolor="#fff"
            inputId="modalPasswordConfirmation"
            placeholder=""
            borders={false}
            value=""
            ref={passwordConfirmationRef}
            type="password"
          />
        </StyledGroup>
        {errorMessage && <StyledErrorText align="left" text={errorMessage} />}

        <StyledButton
          fullWidth
          text={{
            text: "Let's go!",
            textColor: '#fff',
            align: 'center',
            textStyle: 'overline',
          }}
          bgColor={theme.color.primary[100]}
          onClick={() => {
            onSubmitRegister(
              nameRef.current.value,
              emailRef.current.value,
              passwordRef.current.value,
              passwordConfirmationRef.current.value
            );
          }}
        />
        <StyledInlineGroup>
          <Text
            align="left"
            text="Already have an account?"
            textColor="#fff"
            tag="a"
            onClick={() => {
              props.modalOpen(true);
              props.modalType(['login']);
            }}
            inline
          />
        </StyledInlineGroup>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

registration.propTypes = {};

registration.defaultProps = {};

export default connect(null, {
  modalType,
  modalOpen,
})(registration);
