import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EmptyState from '../EmptyState';
import { rem } from '../../theme';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d1d1d;
  height: 100%;
  width: 100%;
`;

/** invite url route */
const NotFound = props => {
  const { userId } = props;

  //Translation
  const [t] = useTranslation(['emptystate']);

  return (
    <StyledWrapper>
      <EmptyState
        textColor='#000'
        animation='empty-box'
        width={rem(500)}
        height='auto'
        hasButton={false}
        text={t('emptystate:notfound.title')}
      />
    </StyledWrapper>
  );
};

NotFound.propTypes = {
  /** Current userId */
  userId: PropTypes.string,
};

NotFound.defaultProps = {
  userId: '',
};

export default NotFound;
