import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import Grid from '../Grid';
import theme, { rem } from '../../theme';
import firebase, {
  listListner,
  createNewSubItem,
  updateSubTitle,
} from '../../firebase';
import Group from '../Group';
import Button from '../Button';
import Icon from '../Icon';
import TextField from '../TextField';
import Upload from '../Dropzone';
import { globalDragInfo, storeWidgetDrag } from '../../redux/actions';
import EmptyState from '../EmptyState';
import DragList from '../DragList';
import Help from '../Help';
import Animation from '../LottieControl';
import Text from '../Text';

const StyledMainWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: ${rem(17)} ${rem(17)} 0 ${rem(17)};
`;

const StyledContentWrapper = styled.div`
  background: #fff;
  display: flex;
  width: 100%;
  border-radius: 15px;
  background: #fff;
  margin-bottom: 0.2rem;
  padding: 15px;
`;

const StyledMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${rem(12)} 0 0 0;
`;

const StyledEmpty = styled.div`
  border-radius: ${rem(8)};
  background: ${theme.color.dark[4]};
  width: 100%;
  height: ${rem(40)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${rem(16)};
  cursor: default;
`;

const StyledAnimation = styled(Animation)`
  cursor: help;
`;

const StyledTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${rem(20)};
  width: ${rem(250)};
`;

const StyledTooltip = styled(ReactTooltip)`
  border-radius: ${rem(5)} !important;
  padding: ${rem(5)} ${rem(10)} !important;
`;

const StyledToolTipText = styled(Text)`
  margin-bottom: ${rem(15)};
`;

const StyledToolTipAnimation = styled(Animation)`
  margin-bottom: ${rem(15)};
`;

const StyledMain = styled.div`
  display: flex;
  height: 100%;
`;

/** ListContent */
const ListContent = props => {
  const { currentId, data, activeDashboardId, widgetId } = props;
  const [files, setFiles] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [maxItems, setMaxItems] = useState();
  const [totalItems, setTotalItems] = useState();
  const [maxExceeded, setMaxExceeded] = useState(false);
  const [maxLength] = useState(140);
  const [errorType, setErrorType] = useState(false);

  //Refs
  const createListItemRef = useRef();

  //Translation
  const [t] = useTranslation(['fb', 'help', 'textfield', 'text', 'emptystate']);

  useEffect(() => {
    if (currentId) setListners();
  }, []);

  /** Set listners */
  const setListners = () => {
    listListner
      .child(currentId)
      .child('items')
      .on('child_removed', snap => {
        if (!snap.val().remove) {
          updateSubTitle(
            'lists',
            currentId,
            t('fb:removed.default') + snap.val().text
          );
        }
      });

    listListner
      .child(currentId)
      .child('maxItems')
      .on('value', snap => {
        setMaxItems(snap.val());
      });

    listListner
      .child(currentId)
      .child('itemCount')
      .on('value', snapshot => {
        if (snapshot.val()) {
          listListner
            .child(currentId)
            .child('maxItems')
            .once('value', snap => {
              if (snapshot.val() >= snap.val()) {
                setMaxExceeded(true);
              } else {
                setMaxExceeded(false);
              }
            });
          setTotalItems(snapshot.val());
        }
      });
  };

  /** Create list items based on paste action */
  const pastedListItemsHandler = (listId, text) => {
    //IMPORT LIST FUNCTIONALITY
    const list_items_array = text
      .split('\n')
      .slice(0, maxItems)
      .reverse();

    list_items_array.map(value => {
      createNewSubItem(
        firebase.auth().currentUser.uid,
        listId,
        value,
        null,
        'lists',
        'items'
      );
    });

    updateSubTitle('lists', listId, t('fb:pasted.default'));
  };

  /** Onpaste action */
  const pasteHandler = (e, listId) => {
    //Get the data from the clipboard
    const pastedData = e.clipboardData.getData('text');
    if (pastedData != '') {
      //Create list items from the text of the clipboard
      pastedListItemsHandler(listId, pastedData);
      //Remove the inputfield
      e.preventDefault();
      return false;
    }
  };

  /** Handle blur */
  const handleSend = (inputId, listId, table) => {
    if (
      createListItemRef.current.value.trim() !== '' ||
      (files && files.length > 0)
    ) {
      const value = document
        .getElementById(inputId)
        .value.substring(0, maxLength);
      createNewSubItem(
        firebase.auth().currentUser.uid,
        listId,
        value,
        files,
        'lists',
        'items'
      );
      document.getElementById(inputId).value = '';
      setEmpty(true);
      setFiles([]);
      //Scroll to top
      setScroll(true);
      //Set state back to false
      setTimeout(() => setScroll(false), 200);
      updateSubTitle(table, listId, t('fb:added.default') + value);
    }
  };

  /** Keydown handlger */
  const keyDownHandler = (e, inputId, listId) => {
    if (e.key === 'Enter') {
      handleSend(inputId, listId, 'lists');
    }
  };

  /** Keep track of the selected files */
  const handleFiles = data => {
    setFiles(data);
  };

  /**
   * Handle Drag Over
   */
  const handleDragOver = (ev, id) => {
    ev.preventDefault();
    let typeSmall = '';
    let isImage = false;

    if (props.storeDrag) return;

    if (ev.dataTransfer.types && ev.dataTransfer.types[0] == 'Files') {
      //Drag from outside the browser
    } else if (props.dragInfo.name) {
      typeSmall = props.dragInfo.name.split('.').pop();
      isImage = props.dragInfo.datatype.includes('image');

      if (isImage) {
        typeSmall = 'image';
      }
    }

    if (typeSmall === 'image') {
      document.getElementById(id).style.backgroundColor = theme.color.success;
      document.getElementById(id).style.transition = '0.35s';
    }
  };

  /**
   * Handle drop
   */
  const handleDrop = async (ev, id) => {
    ev.preventDefault();
    document.getElementById(id).style.backgroundColor = '';
    document.getElementById(id).style.transition = '';

    //Check if the drag is from the dashboard itself
    if (props.dragInfo.type === 'document') {
      let typeSmall = props.dragInfo.name.split('.').pop();
      const isImage = props.dragInfo.datatype.includes('image');
      let xhr = new XMLHttpRequest();

      if (isImage) {
        typeSmall = 'image';
      }

      switch (typeSmall) {
        default:
          return;
        case 'image': {
          xhr.onload = function() {
            const input = 'listItemControls-' + currentId;
            const value = document
              .getElementById('listItemControls-' + currentId)
              .value.substring(0, maxLength);

            createNewSubItem(
              firebase.auth().currentUser.uid,
              currentId,
              value,
              [xhr.response],
              'lists',
              'items'
            );

            document.getElementById(input).value = '';
            setEmpty(true);
            setFiles([]);
            //Scroll to top
            setScroll(true);
            //Set state back to false
            setTimeout(() => setScroll(false), 200);
            updateSubTitle('lists', currentId, t('fb:added.default') + value);
          };
          xhr.open('GET', props.dragInfo.url);
          xhr.responseType = 'blob';
          xhr.send();
          break;
        }
      }

      props.globalDragInfo({});
    } else if (ev && ev.dataTransfer && ev.dataTransfer.types) {
      //Check if external drop e.g. local files
      for (var i = 0; i < ev.dataTransfer.types.length; i++) {
        if (ev.dataTransfer.types && ev.dataTransfer.types[i] == 'Files') {
          let currentFile = ev.dataTransfer.items[i];
          let type = currentFile.type.slice(0, currentFile.type.indexOf('/'));
          let file = ev.dataTransfer.files[0];
          let typeSmall = file.name.split('.').pop();
          const acceptedTypes = ['xlsx', 'docx', 'txt', 'pdf', 'ppt'];

          if (type === 'image') typeSmall = 'image';

          if (type !== 'image' && !acceptedTypes.includes(typeSmall)) {
            setErrorType(true);
          } else {
            const input = 'listItemControls-' + currentId;
            const value = document
              .getElementById('listItemControls-' + currentId)
              .value.substring(0, maxLength);

            createNewSubItem(
              firebase.auth().currentUser.uid,
              currentId,
              value,
              [file],
              'lists',
              'items'
            );

            document.getElementById(input).value = '';
            setEmpty(true);
            setFiles([]);
            //Scroll to top
            setScroll(true);
            //Set state back to false
            setTimeout(() => setScroll(false), 200);
            updateSubTitle('lists', currentId, t('fb:added.default') + value);
          }
        }
      }
    }
  };

  return (
    <StyledMain>
      <Help
        outside
        activeDashboardId={activeDashboardId}
        currentId={currentId}
        url='/resources/listhelp.gif'
        title={t('help:listcontent.title')}
        text={t('help:listcontent.text')}
      />
      <StyledMainWrapper
        onDrop={e => handleDrop(e, currentId)}
        onDragOver={e => handleDragOver(e, currentId)}
      >
        {currentId ? (
          <React.Fragment>
            <StyledContentWrapper>
              <Grid rows='1fr' scrollbar={false}>
                <Grid rows='1fr' scrollTop={scroll}>
                  {data && (
                    <DragList
                      currentId={currentId}
                      data={data}
                      listId={currentId}
                      activeDashboardId={activeDashboardId}
                    />
                  )}
                </Grid>
                <StyledMessageWrapper>
                  {!maxExceeded ? (
                    <Group>
                      <TextField
                        name='message'
                        ref={createListItemRef}
                        inputId={'listItemControls-' + currentId}
                        backgroundcolor={theme.color.dark[4]}
                        borders={false}
                        placeholder={t('textfield:listcontent.placeholder')}
                        activeDashboardId={activeDashboardId}
                        onPaste={event => pasteHandler(event, currentId)}
                        onSubmit={() =>
                          handleSend(
                            'listItemControls-' + currentId,
                            currentId,
                            'lists'
                          )
                        }
                        className='editable'
                        value={
                          createListItemRef.current &&
                          createListItemRef.current.value.substring(
                            0,
                            maxLength
                          )
                        }
                        style={{
                          paddingLeft: rem(16),
                          paddingRight: rem(8),
                          marginRight: rem(16),
                        }}
                        onKeyDown={event =>
                          keyDownHandler(
                            event,
                            'listItemControls-' + currentId,
                            currentId
                          )
                        }
                      />
                      <Group type='inputOption'>
                        <Button
                          className='editable'
                          variant='icon'
                          onClick={() =>
                            handleSend(
                              'listItemControls-' + currentId,
                              currentId,
                              'lists'
                            )
                          }
                        >
                          <Icon
                            icon='send'
                            padding={rem(6)}
                            size={16}
                            color={theme.color.dark[40]}
                          />
                        </Button>
                        <Upload
                          type='button'
                          empty={empty}
                          preview
                          previewId='previewListAttachment'
                          onFileSelect={handleFiles}
                          newFiles={files}
                        />
                      </Group>
                    </Group>
                  ) : (
                    <Group>
                      <StyledEmpty>
                        <Text
                          text={t('text:error.exceeded')}
                          textColor={theme.color.dark[40]}
                        />
                        <React.Fragment>
                          <StyledAnimation
                            animation='alert'
                            animationWidth={30}
                            animationHeight={30}
                            data-for='tooltipMenuListItems'
                            data-tip=''
                          />
                          <StyledTooltip
                            id='tooltipMenuListItems'
                            getContent={() => {
                              return (
                                <StyledTooltipWrapper>
                                  <StyledToolTipText
                                    text={t('text:error.limit')}
                                    textStyle='h6'
                                    textColor='#fff'
                                    align='center'
                                  />
                                  <StyledToolTipAnimation
                                    animation='cry'
                                    animationWidth={rem(100)}
                                    animationHeight={rem(100)}
                                  />
                                  <Text
                                    text={t('text:error.remove')}
                                    textStyle='subtitle'
                                    textColor='#fff'
                                    align='center'
                                  />
                                </StyledTooltipWrapper>
                              );
                            }}
                          />
                        </React.Fragment>
                      </StyledEmpty>
                    </Group>
                  )}
                </StyledMessageWrapper>
              </Grid>
            </StyledContentWrapper>
          </React.Fragment>
        ) : (
          <EmptyState
            widgetId={widgetId}
            text={t('emptystate:listcontent.title')}
          />
        )}
      </StyledMainWrapper>
    </StyledMain>
  );
};

ListContent.propTypes = {
  /** The List data from Firebase */
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /** Active dashboard */
  activeDashboardId: PropTypes.string,
  currentId: PropTypes.string,
  /** Current widget Id */
  widgetId: PropTypes.string,
};

ListContent.defaultProps = {
  data: null,
  activeDashboardId: null,
  currentId: null,
  widgetId: null,
};

//export default ListContent;
/** pass currentUser and currentChannel from redux to global props */
const mapStateToProps = state => ({
  dragInfo: state.globaldraginfo.globalDragInfo,
  storeDrag: state.storewidgetdrag.storeWidgetDrag,
});

//export
export default connect(mapStateToProps, {
  globalDragInfo,
  storeWidgetDrag,
})(ListContent);
