/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme, { rem } from '../../theme';
import Image from '../Image';
import Avatar from '../Avatar';
import Animation from '../LottieControl';
import firebase, {
  dashboardListner,
  userListner,
  userDashboardListner,
} from '../../firebase';

const StyledWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(40)};
  height: ${rem(40)};
  z-index: 999;
`;

const StyledImage = styled(Image)`
  position: absolute;
  width: ${rem(20)};
  left: 2px;
  top: 0px;
  transform: translateZ(0) rotate(99deg) scale(1, 1);
  backface-visibility: hidden;
`;

const StyledAnimation = styled(Animation)`
  position: absolute;
  z-index: -1;
`;

const StyledIndicatorAnimation = styled(Animation)`
  align-self: center;
`;

const SpeechBubble = styled.div`
  position: absolute;
  top: -${rem(45)};
  display: flex;
  justify-content: center;
  right: -${rem(18)};
  background: ${theme.color.success};
  border-radius: 0.4em;
  height: ${rem(35)};
  width: ${rem(45)};

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: ${theme.color.success};
    border-bottom: 0;
    border-left: 0;
    margin-left: -5px;
    margin-bottom: -10px;
  }
`;

/**
 * Overview component
 * @param {string} activeDashboardId
 */
const Cursor = props => {
  const { activeDashboardId } = props;

  //UserId
  const uuid = firebase.auth().currentUser.uid;

  //States
  const [friendsDetail, setFriendsDetail] = useState({});
  const [cursorEventType, setCursorEventType] = useState({});

  useEffect(() => {
    getDashboardUsers();
    settingsListner();
  }, []);

  /** settingsListner */
  const settingsListner = () => {
    const connectedRef = firebase.database().ref('.info/connected');
    const listner = userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('activity')
      .child('cursor')
      .child('visible');

    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('removing')
      .on('value', snap => {
        if (snap.val() === false) {
          listner.onDisconnect().set(false);
        } else if (snap.val() === true) {
          listner.onDisconnect().cancel();
        }
      });

    dashboardListner
      .child(activeDashboardId)
      .child('members')
      .on('child_removed', snap => {
        if (snap.val()) {
          delete friendsDetail[snap.key];
          delete cursorEventType[snap.key];

          setFriendsDetail(prevState => {
            // Object.assign would also work
            return { ...prevState, ...friendsDetail };
          });
          setCursorEventType(prevState => {
            // Object.assign would also work
            return { ...prevState, ...cursorEventType };
          });
        }
      });

    connectedRef.on('value', snap => {
      if (snap.val() === true) {
        listner.set(true);
      } else {
        listner.set(false);
      }
    });
    userListner
      .child(uuid)
      .child('public')
      .child('indicator')
      .on('value', snap => {
        if (snap.val()) {
          const type = snap.val();
          switch (type) {
            default:
            case 'online': {
              listner.set(true);
              break;
            }

            case 'offline': {
              listner.set(false);
              break;
            }

            case 'away':
              listner.set(false);
              break;

            case 'busy':
              listner.set(false);
              break;
          }
        }
      });
  };

  /** get Dashboard users */
  const getDashboardUsers = () => {
    dashboardListner
      .child(activeDashboardId)
      .child('members')
      .on('value', snap => {
        if (snap.val() && Object.keys(snap.val()).length > 1) {
          snap.forEach(function(childSnapshot) {
            if (childSnapshot.key !== uuid) {
              userListner
                .child(childSnapshot.key)
                .child('public')
                .once('value', snap => {
                  if (childSnapshot.key !== uuid) {
                    friendsDetail[childSnapshot.key] = {};
                    friendsDetail[childSnapshot.key]['name'] = snap.val().name
                      ? snap.val().name
                      : '';
                    friendsDetail[childSnapshot.key]['color'] =
                      snap.val().color && snap.val().color;
                    friendsDetail[childSnapshot.key]['avatar'] = snap.val()
                      .avatar
                      ? snap.val().avatar.cropped
                      : '';
                  }
                })
                .then(() => {
                  if (childSnapshot.key !== uuid) {
                    userDashboardListner
                      .child(childSnapshot.key)
                      .child(activeDashboardId)
                      .child('activity')
                      .child('cursor')
                      .on('value', snap => {
                        if (snap.val() && childSnapshot.key !== uuid) {
                          cursorEventType[childSnapshot.key] = {};
                          cursorEventType[childSnapshot.key][
                            'type'
                          ] = snap.val().action;

                          cursorEventType[childSnapshot.key][
                            'visible'
                          ] = snap.val().visible;

                          cursorEventType[childSnapshot.key][
                            'forcedHidden'
                          ] = snap.val().forcedHidden;

                          // if (
                          //   document.getElementById(
                          //     'cursor' + childSnapshot.key
                          //   )
                          // ) {
                          //   /** Place the element based on the coordinates */
                          //   let d = document.getElementById(
                          //     'cursor' + childSnapshot.key
                          //   );
                          //   d.style.left = snap.val().x + '%';
                          //   d.style.top = snap.val().y + '%';
                          // }

                          setCursorEventType(prevState => {
                            return { ...prevState, ...cursorEventType };
                          });
                        }
                      });
                  }

                  setFriendsDetail(friendsDetail);
                });

              userListner
                .child(childSnapshot.key)
                .child('public')
                .on('child_changed', snapChild => {
                  if (friendsDetail[childSnapshot.key]) {
                    if (snapChild.key === 'name')
                      friendsDetail[childSnapshot.key][
                        'name'
                      ] = snapChild.val();

                    if (snapChild.key === 'avatar')
                      friendsDetail[childSnapshot.key][
                        'avatar'
                      ] = snapChild.val().cropped;
                    setFriendsDetail(prevState => {
                      return { ...prevState, ...friendsDetail };
                    });
                  }
                });
            }
          });
        } else {
          setFriendsDetail({});
          setCursorEventType({});
        }
      });
  };

  return cursorEventType && friendsDetail
    ? Object.entries(friendsDetail).map(user => {
        if (
          user[0] === uuid ||
          (cursorEventType[user[0]] && !cursorEventType[user[0]].visible) ||
          (cursorEventType[user[0]] && cursorEventType[user[0]].forcedHidden)
        )
          return;
        return (
          <StyledWrapper key={user[0]} id={'cursor' + user[0]}>
            <StyledImage src='/resources/point.svg' />
            <Avatar
              source={user[1].avatar}
              size='small'
              title={user[1].name}
              color={user[1].color && user[1].color}
            />
            {cursorEventType[user[0]] &&
              cursorEventType[user[0]].type &&
              cursorEventType[user[0]].type === 'click' && (
                <StyledAnimation
                  animation='click2'
                  animationWidth={rem(80)}
                  animationHeight={rem(80)}
                  loop={false}
                />
              )}
            {cursorEventType[user[0]] &&
              cursorEventType[user[0]].type &&
              cursorEventType[user[0]].type === 'typing' && (
                <SpeechBubble>
                  <Animation
                    animation='typing'
                    animationWidth={rem(35)}
                    animationHeight={rem(35)}
                  />
                </SpeechBubble>
              )}

            {cursorEventType[user[0]] &&
              cursorEventType[user[0]].type &&
              cursorEventType[user[0]].type === 'dragging' && (
                <SpeechBubble>
                  <StyledIndicatorAnimation
                    animation='dragging'
                    animationWidth={rem(110)}
                    animationHeight={rem(110)}
                  />
                </SpeechBubble>
              )}

            {cursorEventType[user[0]] &&
              cursorEventType[user[0]].type &&
              cursorEventType[user[0]].type === 'layoutResize' && (
                <SpeechBubble>
                  <StyledIndicatorAnimation
                    animation='layoutResize'
                    animationWidth={rem(35)}
                    animationHeight={rem(35)}
                  />
                </SpeechBubble>
              )}
          </StyledWrapper>
        );
      })
    : null;
};

Cursor.propTypes = {
  /** The List data from Firebase */
  data: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  activeDashboardId: PropTypes.string,
};

Cursor.defaultProps = {
  data: null,
  shouldShowIsActive: true,
  activeDashboardId: null,
};

/**
 * Update click event of the cursor
 * @param {string} activeDashboardId
 * @param {string} cursorEvent
 */
export const updateCursorEvent = (userId, activeDashboardId, cursorEvent) => {
  if (userId && activeDashboardId) {
    userDashboardListner
      .child(userId)
      .child(activeDashboardId)
      .child('activity')
      .child('cursor')
      .update({
        action: cursorEvent,
      });

    if (cursorEvent === 'click') {
      setTimeout(function() {
        userDashboardListner
          .child(userId)
          .child(activeDashboardId)
          .child('activity')
          .child('cursor')
          .update({
            action: 'default',
          });
      }, 800);
    }
  }
};

export default Cursor;
