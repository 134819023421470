/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import Text from '../Text';
import firebase, {
  setColor,
  userDashboardListner,
  userNoteListner,
  userWhiteboardListner,
} from '../../firebase';
import theme, { rem } from '../../theme';

const StyledWrapper = styled.div`
  ${({ hideIndicator }) => `
    display: flex;
    flex-direction: column;
    min-width: ${hideIndicator ? '' : rem(100)};
    margin-bottom: ${rem(15)};
    z-index: ${hideIndicator ? '5' : ''};

    &:last-child {
      margin-bottom: 0;
    }
  `};
`;

const StyledColor = styled.div`
  ${({ headerColor }) => `
    width: 100%;
    height: ${rem(14)};
    border-radius: ${rem(2)};
    background: ${headerColor ? headerColor : 'transparent'};
  `};
`;

const StyledSwatch = styled.div`
  ${({ hideIndicator }) => `
    padding: ${rem(5)};
    background: #fff;
    border-radius: ${rem(1)};
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: flex;
    cursor: pointer;
    height: ${hideIndicator ? '100%' : ''};
    position: ${hideIndicator ? 'absolute' : 'relative'};
    left: ${hideIndicator ? '0' : 'auto'};
    right: ${hideIndicator ? '0' : 'auto'};
    bottom: ${hideIndicator ? '0' : 'auto'};
    top: ${hideIndicator ? '0' : 'auto'};
    border-radius: ${hideIndicator ? '100%' : '0'};
    opacity: ${hideIndicator ? '0' : '1'};
  `}
`;

const StyledPopOver = styled.div`
  ${({ centeredPopOver }) => `
    position: ${centeredPopOver ? 'fixed' : 'absolute'};
    z-index: 99;
    right: ${centeredPopOver ? 'auto' : `-${rem(15)}`};
    top: ${centeredPopOver ? 'auto' : `-${rem(200)}`};
    align-self:  ${centeredPopOver ? 'center' : ''};
  `}
`;

const StyledCover = styled.div`
  position: relative;
  padding: ${rem(20)};
  display: flex;
  width: auto;
  height: auto;
`;

const StyledText = styled(Text)`
  &::first-letter {
    text-transform: capitalize;
  }
`;

/**
 * Return a styled Header component
 * @param {object} children
 * @param {string} type
 * @param {string} activeDashboardId
 * @param {bool} hideIndicator
 * @param {bool} centeredPopOver
 * @param {string} accessible
 * @param {string} lable
 * @param {bool} hideLable
 * @param {func} changeComplete
 * @param {string} color
 */
const ColorPicker = props => {
  const {
    type,
    accessible,
    activeDashboardId,
    hideIndicator,
    centeredPopOver,
    itemId,
    lable,
    hideLable,
    changeComplete,
    color,
  } = props;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [currentColor, setCurrentColor] = useState();
  //UserId
  const uuid = firebase.auth().currentUser.uid;
  const item = type + 'Color';
  useEffect(() => {
    if (type !== 'dynamic') {
      let listner = userDashboardListner.child(uuid).child(activeDashboardId);
      if (accessible === 'public')
        listner = userDashboardListner
          .child(uuid)
          .child(activeDashboardId)
          .child('public')
          .child('colors');

      if (type === 'note' || type === 'noteText') {
        listner = userNoteListner
          .child(uuid)
          .child(itemId)
          .child('colors');
      } else if (type === 'brush') {
        listner = userWhiteboardListner
          .child(uuid)
          .child(itemId)
          .child('brush');
      } else {
        listner = userDashboardListner
          .child(uuid)
          .child(activeDashboardId)
          .child('colors');
      }

      listner.child(item).once('value', snap => {
        setCurrentColor(snap.val());
      });
    } else {
      setCurrentColor(color ? color : theme.color.primary[100]);
    }
  }, []);

  const myRef = useRef();

  /** Handles click outside event */
  const handleOutsideClick = event => {
    if (
      myRef.current &&
      myRef.current.contains(event.target) &&
      myRef.current.contains(
        document.elementFromPoint(event.clientX, event.clientY)
      )
    ) {
      setDisplayColorPicker(true);
    } else {
      setDisplayColorPicker(false);
    }
  };

  document.addEventListener('mousedown', handleOutsideClick);

  /** Handles click event */
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  /** handleChange */
  const handleChange = color => {
    const colors = [color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a];
    const name = type + 'Color';
    let publicColor = false;

    if (type === 'checkbox') publicColor = true;

    setCurrentColor(`rgba(${colors})`);
    if (type !== 'dynamic')
      setColor(name, `rgba(${colors})`, publicColor, activeDashboardId, itemId);
  };

  return currentColor ? (
    <StyledWrapper hideIndicator={hideIndicator} {...props}>
      {!hideIndicator && !hideLable && (
        <StyledText text={lable ? lable : type} textColor="#fff" />
      )}
      <StyledSwatch onClick={handleClick} hideIndicator={hideIndicator}>
        <StyledColor headerColor={currentColor} />
      </StyledSwatch>
      {displayColorPicker ? (
        <StyledPopOver centeredPopOver={centeredPopOver}>
          <StyledCover
            ref={myRef}
            onClick={handleOutsideClick}
            onMouseLeave={handleOutsideClick}
          >
            <ChromePicker
              ref={myRef}
              color={currentColor}
              onChange={handleChange}
              //onChangeComplete={(color, event) => { changeComplete && changeComplete(color)}}
              onChangeComplete={
                changeComplete ? changeComplete(currentColor) : () => {}
              }
            />
          </StyledCover>
        </StyledPopOver>
      ) : null}
    </StyledWrapper>
  ) : (
    <React.Fragment />
  );
};

ColorPicker.propTypes = {
  /** Type of the Color picker */
  type: PropTypes.string,
  /** Current dashboard Id */
  activeDashboardId: PropTypes.string,
  /** Hides the default indicater */
  hideIndicator: PropTypes.bool,
  /** Currently active itemId */
  itemId: PropTypes.string,
  /** Center the Picker */
  centeredPopOver: PropTypes.bool,
  /** Accessible type */
  accessible: PropTypes.string,
  /** Custom lable */
  lable: PropTypes.string,
  /** Hide the text lable */
  hideLable: PropTypes.bool,
  /** Handle for change color complete */
  changeComplete: PropTypes.func,
  /** The optional default color */
  color: PropTypes.string,
};

ColorPicker.defaultProps = {
  type: null,
  activeDashboardId: null,
  hideIndicator: false,
  itemId: null,
  centeredPopOver: false,
  accessible: 'private',
  lable: null,
  hideLable: null,
  changeComplete: null,
  color: null,
};

/** @component */
export default ColorPicker;
