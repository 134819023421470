/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Particles from "react-particles-js";
import Animation from "../LottieControl";
import Text from "../Text";
import EmptyState from "../EmptyState";
import Group from "../Group";
import Grid from "../Grid";
import List from "../List";
import ListItem from "../ListItem";
import theme, { rem } from "../../theme";
import firebase, {
  createDashboard,
  switchDashboard,
  dashboardListner,
  feedbackListner,
  masterListner,
  userListner,
  userRelationsDashboardListner,
} from "../../firebase";

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #26262b;
  height: 100%;
  width: 100%;

  #tsparticles {
    width: 100%;
    height: 100%;
  }
`;

const StyledTextIntro = styled(Text)`
  margin-bottom: ${rem(20)};
`;

const StyledList = styled(List)`
  width: 100%;
  margin: 0;
`;

const StyledGroup = styled(Group)`
  background: rgb(47, 49, 54);
  width: ${rem(600)};
  border-radius: ${rem(15)};
  padding: ${rem(20)};
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
`;

const StyledParticles = styled(Particles)`
  position: fixed;
  z-index: 0;
  width: 100vw;
  height: 100vh;
`;

const StyledGridWrapper = styled(Grid)`
  z-index: 1;
  display: none;
  height: ${rem(330)};
  background: ${theme.color.dark[60]};
  padding: ${rem(18)} 0;
  border-radius: ${rem(10)};

  &.active {
    display: block;
  }
`;

/** invite url route */
const SelectDashboard = props => {
  const { userData } = props;

  const [saveData] = useState([]);
  const [firstName, setFirstName] = useState();
  const [isCreating, setIsCreating] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [childUsers, setChildUsers] = useState({});

  //Translation
  const [t] = useTranslation(["modal", "emptystate"]);
  const uuid = firebase.auth().currentUser
    ? firebase.auth().currentUser.uid
    : false;

  //If the user Id is not defind redirect the user to the homepage
  if (!uuid) return <Redirect to="/checkSignIn" />;

  useEffect(() => {
    if (uuid) {
      userListner.child(uuid).once("value", snap => {
        if (
          (snap.exists() && snap.val().isGhostUser) ||
          !snap.val().id ||
          !snap.val().isSingleSignOnUser
        ) {
          let isMaster = false;

          masterListner.child("members").once("value", snap => {
            snap.forEach(function(snapshot) {
              if (snapshot.key === uuid) {
                isMaster = true;
              }
            });
            if (!isMaster) {
              setRedirect(false);
            }
          });
        }
      });
    }

    if (userData && userData.username) {
      // may need to be a loop
      firebase
        .database()
        .ref()
        .child("userRelations")
        .child(uuid)
        .set(userData.relationInfo);
      let sanitizeName = userData.username.replace(/ .*/, "");
      setFirstName(sanitizeName);
      setChildUsers(userData.relationInfo);
    } else {
      // firebase username
      firebase
        .database()
        .ref()
        .child("users")
        .child(uuid)
        .child("public")
        .child("name")
        .once("value", snap => {
          if (snap.val()) {
            let sanitizeName = snap.val().replace(/ .*/, "");
            setFirstName(sanitizeName);
          }
        });
      firebase
        .database()
        .ref()
        .child("userRelations")
        .child(uuid)
        .once("value", snap => {
          if (snap.val()) {
            setChildUsers(snap.val());
          }
        });
    }
  }, []);

  /** Get the active state of the feedback */
  const getFeedbackStatus = () => {
    let status = false;
    feedbackListner.child("active").once("value", snap => {
      if (snap.exists()) status = snap.val();
    });

    return status;
  };

  /** check for/ create dashboard and go there */
  const GoToOrMakeDashBoard = (targetUser, name) => {
    const UserRelationRef = userRelationsDashboardListner
      .child(uuid)
      .child(targetUser)
      .child("dashboardId");
    UserRelationRef.child(targetUser).once("value", snap => {
      if (snap.val()) {
        switchDashboard(snap.val());
      } else {
        setIsCreating(true);
        let key = UserRelationRef.push().key;
        UserRelationRef.child(targetUser)
          .set(key)
          .then(() => {
            saveData["id"] = key;
            saveData["title"] = name;
            saveData["subtitle"] = "StudentsPlus";
            saveData["sso"] = true;
            saveData["allPermissions"] = false;
            saveData["activeDashboardId"] = null;
            saveData["userId"] = uuid;
            saveData["reload"] = true;
            saveData["feedback"] = getFeedbackStatus();
            saveData["image"] = [];

            masterListner.once("value", snapshot => {
              if (snapshot.exists() && snapshot.val()["dashboard"]) {
                saveData["backgroundimage"] =
                  snapshot.val().backgroundimage &&
                  snapshot.val().backgroundimage;

                dashboardListner
                  .child(snapshot.val()["dashboard"].id)
                  .once("value", snap => {
                    createDashboard(saveData, snap.val());
                  });
              } else {
                createDashboard(saveData);
              }
            });
          });
      }
    });
  };

  return redirect ? (
    <Redirect to="/" />
  ) : (
    <StyledWrapper>
      <StyledParticles
        params={{
          particles: {
            number: {
              value: 60,
              density: {
                enable: true,
                value_area: 1500,
              },
            },
            line_linked: {
              enable: true,
              opacity: 0.02,
            },
            move: {
              direction: "right",
              speed: 0.05,
            },
            size: {
              value: 1,
            },
            opacity: {
              anim: {
                enable: true,
                speed: 1,
                opacity_min: 0.05,
              },
            },
          },
          interactivity: {
            events: {
              onclick: {
                enable: true,
                mode: "push",
              },
            },
            modes: {
              push: {
                particles_nb: 1,
              },
            },
          },
          retina_detect: true,
        }}
      />
      {isCreating ? (
        <StyledGroup fullwidth>
          <Text
            align="center"
            text={t("modal:createdashboard.building.title")}
            textColor="#fff"
            textStyle="h3"
            tag="h1"
          />
          <EmptyState
            textColor="#fff"
            animation="launch"
            width={rem(350)}
            height="auto"
            hasButton={false}
            text={t("modal:createdashboard.building.text")}
          />
        </StyledGroup>
      ) : (
        <StyledGroup fullwidth>
          {childUsers ? (
            <React.Fragment>
              {firstName && (
                <Text
                  align="center"
                  text={t("modal:selectdashboard.welcome") + ", " + firstName}
                  textColor="#fff"
                  textStyle="h3"
                  tag="h1"
                />
              )}
              <React.Fragment>
                <Animation animation="hello" animationHeight={150} />
                <StyledTextIntro
                  align="center"
                  text={t("modal:selectdashboard.text")}
                  textColor="#fff"
                  textStyle="h6"
                  tag="p"
                />
                <StyledGridWrapper
                  columns="1fr"
                  parentId="attendeesList"
                  className="active"
                >
                  <StyledList evenRadius autoWidth>
                    {Object.entries(childUsers).map(bijlesnemer => {
                      return (
                        <ListItem
                          title={bijlesnemer[1]}
                          id={bijlesnemer[0]}
                          key={bijlesnemer[0]}
                          onItemClick={() => {
                            GoToOrMakeDashBoard(bijlesnemer[0], bijlesnemer[1]);
                          }}
                        />
                      );
                    })}
                  </StyledList>
                </StyledGridWrapper>
              </React.Fragment>
            </React.Fragment>
          ) : (
            <EmptyState
              textColor="#fff"
              animation="empty-box"
              width={rem(350)}
              height="auto"
              hasButton={false}
              text={t("emptystate:dashboards.title")}
            />
          )}
        </StyledGroup>
      )}
    </StyledWrapper>
  );
};

export default SelectDashboard;
