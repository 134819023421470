import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  RedditIcon,
  TumblrIcon,
  LivejournalIcon,
  MailruIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  EmailIcon,
  WhatsappShareButton,
  EmailShareButton,
} from 'react-share';
import { getEmailTextList, getEmailSubjectList } from './functions/share/email';
import { getText } from './functions/share/whatsapp';
import Group from '../Group';
import Button from '../Button';
import Text from '../Text';
import Icon from '../Icon';
import Lottie from '../LottieControl';
import firebase, { removeItem } from '../../firebase';
import theme, { rem } from '../../theme';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledSharedWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    margin-right: ${rem(20)};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(10)};
`;

const StyledTextIntro = styled(Text)`
  margin-bottom: 0;
`;

const StyledTextSub = styled(Text)`
  margin-bottom: ${rem(30)};
`;

const StyledContentWrapper = styled(Group)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

const StyledButtonGroup = styled(Group)`
  button {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: ${rem(20)};
    width: ${rem(350)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledLoading = styled(Lottie)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

/** todo */
const shareContent = props => {
  const { type, typeId, activeId, closeModals } = props;

  //The current userId
  const uuid = firebase.auth().currentUser.uid;

  //States
  const [loading, setLoading] = useState(true);
  const [itemData, setItemData] = useState({});

  useEffect(() => {
    getItemData();
  }, []);

  /** Open window */
  const openWindow = location => {
    closeModals();
    return window.open(location + getText(type, typeId));
  };

  /** getAttendees */
  const getItemData = () => {
    const listner = firebase
      .database()
      .ref()
      .child(type)
      .child(typeId);

    listner
      .once('value', snap => {
        if (snap.val()) {
          setItemData(snap.val());
        }
      })
      .then(() => {
        setLoading(false);
      });
  };

  /** Return Chat messages */
  return loading ? (
    <StyledLoading animation="loading" animationWidth={400} />
  ) : (
    <StyledWrapper>
      <StyledContentWrapper fullwidth>
        <StyledText
          align="center"
          text="Share your content"
          textColor="#fff"
          textStyle="h3"
          tag="h1"
        />
        <Lottie animation="share" animationWidth={200} />

        {itemData && Object.keys(itemData).length > 0 && (
          <React.Fragment>
            <StyledTextIntro
              align="center"
              text="Select your platform to share"
              textColor="#fff"
              textStyle="h6"
              tag="p"
            />

            <StyledSharedWrapper>
              <EmailShareButton
                subject={getEmailSubjectList(type, typeId)}
                body={getEmailTextList(type, typeId)}
                url=""
              >
                <Button
                  bgColor="#417880"
                  variant="icon"
                  onClick={() => closeModals()}
                >
                  <Icon icon="email" padding={rem(18)} size={32} color="#fff" />
                </Button>
              </EmailShareButton>
              <Button
                variant="icon"
                onClick={() =>
                  openWindow('https://web.whatsapp.com/send?text=')
                }
              >
                <WhatsappIcon round />
              </Button>
              <Button
                variant="icon"
                onClick={() => openWindow('https://line.me/msg/text/?')}
              >
                <LineIcon round />
              </Button>
            </StyledSharedWrapper>
          </React.Fragment>
        )}
        <StyledTextSub
          align="center"
          text="Please note that you probably have to login to your social sharing platform"
          textColor="#fff"
          textStyle="h6"
          tag="p"
        />

        <StyledButtonGroup fullwidth>
          <Button
            variant="contained"
            text={{
              align: 'center',
              text: 'Cancel',
              textColor: '#fff',
            }}
            onClick={() => {
              closeModals();
            }}
            bgColor={theme.color.cancel}
          />
        </StyledButtonGroup>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

shareContent.propTypes = {
  /** Type */
  type: PropTypes.string,
  /** Selected type Id */
  typeId: PropTypes.string,
  /** Current active Id */
  activeId: PropTypes.string,
  /** Close function for the modal */
  closeModals: PropTypes.func,
};

shareContent.defaultProps = {
  type: null,
  typeId: null,
  activeId: null,
  closeModals: null,
};

//export default connect(mapStateToProps)(Messages);
export default shareContent;
