import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import EmptyState from '../EmptyState';
import { rem } from '../../theme';
import firebase, { userListner, masterListner } from '../../firebase';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d1d1d;
  height: 100%;
  width: 100%;
`;

/** invite url route */
const BuildDashboard = props => {
  const { userId } = props;

  const [redirect, setRedirect] = useState(false);

  //Translation
  const [t] = useTranslation(['emptystate']);

  //Userid
  const uuid = firebase.auth().currentUser
    ? firebase.auth().currentUser.uid
    : userId;

  if (!uuid) return <Redirect to='/' />;

  useEffect(() => {
    userListner.child(uuid).once('value', snap => {
      if ((snap.exists() && snap.val().isGhostUser) || !snap.val().id) {
        setRedirect(true);
      }
    });

    masterListner.once('value', snapshot => {
      if (snapshot.exists() && snapshot.val().members) {
        let notMaster = true;
        Object.keys(snapshot.val().members).map(member => {
          if (member === uuid) {
            notMaster = false;
          }
        });

        if (notMaster) setRedirect(true);
      }
    });
  }, []);

  return redirect ? (
    <Redirect to='/' />
  ) : (
    <StyledWrapper>
      <EmptyState
        textColor='#000'
        animation='launch'
        width={rem(500)}
        height='auto'
        hasButton={false}
        text={t('emptystate:building.title')}
      />
    </StyledWrapper>
  );
};

BuildDashboard.propTypes = {
  /** Current userId */
  userId: PropTypes.string,
};

BuildDashboard.defaultProps = {
  userId: '',
};

export default BuildDashboard;
