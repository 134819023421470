/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { WebsocketProvider } from 'y-websocket';
import { ChromePicker } from 'react-color';
import * as Y from 'yjs';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';
import theme, { rem } from '../../theme';
import Button from '../Button';
import { globalDragInfo, storeWidgetDrag } from '../../redux/actions';
import Icon from '../Icon';
import Grid from '../Grid';
import Help from '../Help';
import { active } from 'glamor';

const StyledMainWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledButton = styled(Button)`
  ${({ active }) => `
   transition: 0.3s;
   z-index: 10;
   display: flex;
   width: 2.5rem;
   height: 2.5rem;
   flex: 1;
   background: ${active ? theme.color.primary[100] : theme.color.dark[8]};
   color: ${active ? '#fff' : 'inherit'};
 
   &:hover,
   &:focus {
     color: ${active ? '#fff' : 'inherit'};
     background: ${active ? theme.color.primary[100] : theme.color.dark[8]};
 
     i {
       color: ${active ? 'inherit' : theme.color.primary[100]};
     }
   }
 `}
`;

const StyledIcon = styled(Icon)`
  background-color: ${props => (props.active ? '#afafaf' : 'inherit')};
  color: ${props => (props.active ? '#0075ff' : 'inherit')};
`;

const StyledCanvas = styled.canvas`
  background-color: white;
  cursor: ${props => (props.crosshairCursor ? 'crosshair' : 'auto')};
  &:hover,
  &:focus {
    border: none;
    outline: none;
  }
`;

const StyledPhantomCanvas = styled.canvas`
  top: 0;
  left: 0;
  visibility: hidden;
  position: absolute;
  z-index: -1;
  background-color: white;
`;

const StyledSelectIsland = styled.div`
  border-radius: 5px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  z-index: 2;
`;

const StyledGarbageIsland = styled(Button)`
  z-index: 10;
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

const StyledColorIsland = styled(Button)`
  z-index: 10;
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: ${props => (props.color ? props.color : 'inherit')};
  padding: 0px;
  border: 5px solid #ecedef;
`;

const StyledColorSwatch = styled.div`
  width: 100%;
  height: 100%;
  background-color: red;
`;

const StyledColorPickerWrapper = styled.div`
  z-index: 10;
  position: absolute;
  bottom: 55px;
  left: 13px;
`;

const StyledDivider = styled.span`
  border-left: 1px solid ${theme.color.dark[8]};
  margin: 0 10px;
  height: 30px;
  margin-top: ${rem(5)};
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const WhiteBoardWrapper = styled.div`
  display: flex;
  position: relative;
  border-radius: ${rem(20)};
  height: calc(100% - ${rem(20)});
  border-radius: ${rem(10)};
  margin: ${rem(15)} ${rem(15)} ${rem(20)} ${rem(15)};
`;

const StyledCanvasWrapper = styled.div`
  background: #fff;
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${rem(10)};
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
`;

/**realtime drawings  */
const WhiteboardContent = props => {
  //states
  const { currentId, activeDashboardId } = props;
  const [activeTool, setActiveTool] = useState('draw');
  const [drawing, setDrawing] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [mouseCoords, setMouseCoords] = useState({});
  const [resizing, setResizing] = useState('');
  const [redraw, setRedraw] = useState(true);
  const [offsets, setOffsets] = useState({});
  const [newObjectRef, setNewObjectRef] = useState();
  const [activeFigure, setActiveFigure] = useState();
  const [colorPickerToggle, setColorPickerToggle] = useState(false);
  const [activeColor, setActiveColor] = useState('#1301FF');
  const [boxSize] = useState(10);
  const [yjs, setYjs] = useState();
  const [errorType, setErrorType] = useState(false);

  //refs
  const canvasRef = useRef(null);
  const phantomCanvasRef = useRef(null);
  const inputRef = useRef(null);
  const myColorRef = useRef(null);

  //Translation
  const [t] = useTranslation(['tooltip', 'help']);

  //other variables
  let coordinates = [];
  let currentMouse = { x: 0, y: 0 };
  const options = {
    select: {
      icon: 'near_me',
      tip: t('tooltip:whiteboard.select'),
    },
    square: {
      icon: 'crop_din',
      tip: t('tooltip:whiteboard.square'),
    },
    triangle: {
      icon: 'details',
      tip: t('tooltip:whiteboard.triangle'),
    },
    circle: {
      icon: 'panorama_fish_eye',
      tip: t('tooltip:whiteboard.circle'),
    },
    image: {
      icon: 'image',
      tip: t('tooltip:whiteboard.image'),
    },
    text: {
      icon: 'text_fields',
      tip: t('tooltip:whiteboard.text'),
    },
    line: {
      icon: 'show_chart',
      tip: t('tooltip:whiteboard.line'),
    },
    arrow: {
      icon: 'trending_up',
      tip: t('tooltip:whiteboard.arrow'),
    },
    draw: {
      icon: 'gesture',
      tip: t('tooltip:whiteboard.draw'),
    },
  };

  //resizeHandles
  const northWest = 'rgb(255,0,0)';
  const north = 'rgb(255,50,0)';
  const northEast = 'rgb(255,80,0)';
  const west = 'rgb(0,255,0)';
  const east = 'rgb(0,255,80)';
  const southWest = 'rgb(0,0,255)';
  const south = 'rgb(0,50,255)';
  const southEast = 'rgb(0,80,255)';
  const reservedColors = [
    'rgb(255,0,0)',
    'rgb(255,50,0)',
    'rgb(255,80,0)',
    'rgb(0,255,0)',
    'rgb(0,255,80)',
    'rgb(0,0,255)',
    'rgb(0,50,255)',
    'rgb(0,80,255)',
  ];

  /** get random color */
  function getRandomColor() {
    const r = Math.round(Math.random() * 255);
    const g = Math.round(Math.random() * 255);
    const b = Math.round(Math.random() * 255);
    return `rgb(${r},${g},${b})`;
  }

  //yjs ref ?
  //const pathArray = new Y.Array();

  //TODO add switch
  /** draw on canvas from yjs */
  const draw = () => {
    if (redraw) {
      setRedraw(false);
      const context = canvasRef.current.getContext('2d');
      const ghostContext = phantomCanvasRef.current.getContext('2d');
      context.clearRect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
      ghostContext.clearRect(
        0,
        0,
        phantomCanvasRef.current.width,
        phantomCanvasRef.current.height
      );
      if (yjs) {
        yjs.forEach(drawElement => {
          const type = drawElement.get('type');
          const path = drawElement.get('path');
          const coordinate = drawElement.get('coordinate');
          const colorHash = drawElement.get('colorHash');
          const activeShape = drawElement.get('active');
          const figureColor = drawElement.get('color');
          if (path) {
            context.beginPath();
            context.lineWidth = 2;
            context.lineJoin = 'round';
            context.lineCap = 'round';
            // context.shadowBlur = 2
            // context.shadowColor = '#30bced'
            context.strokeStyle = figureColor;
            switch (type) {
              case 'image': {
                const img = new Image();
                img.src = drawElement.get('image');
                //temp
                if (path === 'firstTime') {
                  var dims = drawElement.get('ogSize');
                  var height = dims.height;
                  var width = dims.width;
                  ghostContext.fillStyle = colorHash;
                  ghostContext.fillRect(
                    coordinate.x,
                    coordinate.y,
                    width,
                    height
                  );
                  drawElement.set('path', new Y.Map());
                  drawElement.get('path').set('x', width + coordinate.x);
                  drawElement.get('path').set('y', height + coordinate.y);
                  canvasRef.current
                    .getContext('2d')
                    .drawImage(img, coordinate.x, coordinate.y);
                } else {
                  ghostContext.fillStyle = colorHash;
                  ghostContext.fillRect(
                    coordinate.x,
                    coordinate.y,
                    path.get('x') - coordinate.x,
                    path.get('y') - coordinate.y
                  );
                  canvasRef.current
                    .getContext('2d')
                    .drawImage(
                      img,
                      coordinate.x,
                      coordinate.y,
                      path.get('x') - coordinate.x,
                      path.get('y') - coordinate.y
                    );
                }
                ghostContext.stroke();
                break;
              }
              case 'text': {
                //console.log('text trigger', path.get('x'));
                context.beginPath();
                context.fillStyle = 'black';
                context.font = '30px arial';
                context.textBaseline = 'top';
                ghostContext.beginPath();
                ghostContext.fillStyle = colorHash;
                ghostContext.fillRect(
                  coordinate.x,
                  coordinate.y,
                  path.get('x') - coordinate.x,
                  path.get('y') - coordinate.y
                );
                ghostContext.stroke();
                context.fillText(
                  drawElement.get('text'),
                  coordinate.x,
                  coordinate.y
                );
                break;
              }
              case 'draw': {
                context.beginPath();
                context.moveTo(coordinate.x, coordinate.y);
                let lastPoint = coordinate;
                path.forEach(c => {
                  // @todo this can be optimized by considering the previous coordinates too
                  // works for now, should be refined as told in comment above this one
                  const pointBetween = {
                    x: (c.x + lastPoint.x) / 2,
                    y: (c.y + lastPoint.y) / 2,
                  };
                  context.quadraticCurveTo(
                    lastPoint.x,
                    lastPoint.y,
                    pointBetween.x,
                    pointBetween.y
                  );
                  lastPoint = c;
                });
                context.lineTo(lastPoint.x, lastPoint.y);
                context.stroke();
                break;
              }
              case 'square': {
                context.beginPath();
                ghostContext.beginPath();
                context.moveTo(coordinate.x, coordinate.y);
                ghostContext.moveTo(coordinate.x, coordinate.y);
                //let lastPoint = coordinate
                context.strokeStyle = figureColor;
                context.fillStyle = 'blue';
                ghostContext.strokeStyle = 'blue';
                ghostContext.fillStyle = colorHash;
                context.rect(
                  coordinate.x,
                  coordinate.y,
                  path.get('x') - coordinate.x,
                  path.get('y') - coordinate.y
                );
                ghostContext.fillRect(
                  coordinate.x,
                  coordinate.y,
                  path.get('x') - coordinate.x,
                  path.get('y') - coordinate.y
                );
                // context.lineTo(lastPoint.x, lastPoint.y)
                // ghostContext.lineTo(lastPoint.x, lastPoint.y)
                context.stroke();
                ghostContext.stroke();
                break;
              }
              case 'triangle': {
                context.beginPath();
                ghostContext.beginPath();
                context.moveTo(
                  coordinate.x + (path.get('x') - coordinate.x) / 2,
                  coordinate.y
                );
                context.strokeStyle = figureColor;
                context.fillStyle = 'green';
                ghostContext.fillStyle = colorHash;
                context.lineTo(coordinate.x, path.get('y'));
                context.lineTo(path.get('x'), path.get('y'));
                context.closePath();
                context.stroke();
                ghostContext.moveTo(
                  coordinate.x + (path.get('x') - coordinate.x) / 2,
                  coordinate.y
                );
                ghostContext.lineTo(coordinate.x, path.get('y'));
                ghostContext.lineTo(path.get('x'), path.get('y'));
                ghostContext.closePath();
                ghostContext.stroke();
                ghostContext.fill();
                break;
              }
              case 'circle': {
                context.beginPath();
                ghostContext.beginPath();
                context.strokeStyle = figureColor;
                context.fillStyle = 'red';
                ghostContext.fillStyle = colorHash;
                let exp1 = Math.pow(path.get('x') - coordinate.x, 2);
                let exp2 = Math.pow(path.get('y') - coordinate.y, 2);
                let distance = Math.sqrt(exp1 + exp2);
                context.arc(
                  coordinate.x,
                  coordinate.y,
                  distance,
                  0,
                  2 * Math.PI,
                  false
                );
                ghostContext.arc(
                  coordinate.x,
                  coordinate.y,
                  distance,
                  0,
                  2 * Math.PI,
                  false
                );
                context.stroke();
                ghostContext.stroke();
                ghostContext.fill();
                break;
              }
              case 'line': {
                context.beginPath();
                ghostContext.beginPath();
                context.strokeStyle = figureColor;
                ghostContext.strokeStyle = colorHash;
                ghostContext.lineWidth = 5;
                context.moveTo(coordinate.x, coordinate.y);
                context.lineTo(path.get('x'), path.get('y'));
                ghostContext.moveTo(coordinate.x, coordinate.y);
                ghostContext.lineTo(path.get('x'), path.get('y'));
                context.stroke();
                ghostContext.stroke();
                break;
              }
              case 'arrow': {
                context.beginPath();
                ghostContext.beginPath();
                context.strokeStyle = figureColor;
                ghostContext.strokeStyle = colorHash;
                ghostContext.lineWidth = 5;

                var headlen = 10;
                var dx = path.get('x') - coordinate.x;
                var dy = path.get('y') - coordinate.y;
                var angle = Math.atan2(dy, dx);
                context.moveTo(coordinate.x, coordinate.y);
                context.lineTo(path.get('x'), path.get('y'));
                context.lineTo(
                  path.get('x') - headlen * Math.cos(angle - Math.PI / 6),
                  path.get('y') - headlen * Math.sin(angle - Math.PI / 6)
                );
                context.moveTo(path.get('x'), path.get('y'));
                context.lineTo(
                  path.get('x') - headlen * Math.cos(angle + Math.PI / 6),
                  path.get('y') - headlen * Math.sin(angle + Math.PI / 6)
                );
                ghostContext.moveTo(coordinate.x, coordinate.y);
                ghostContext.lineTo(path.get('x'), path.get('y'));
                context.stroke();
                ghostContext.stroke();
                break;
              }
            }

            if (activeShape) {
              const half = boxSize / 2;
              const offSet = 10;
              var x;
              var y;
              var distance;
              var xz;
              var yz;
              var xRegret;
              var yRegret;
              if (type === 'circle') {
                let exp1 = Math.pow(path.get('x') - coordinate.x, 2);
                let exp2 = Math.pow(path.get('y') - coordinate.y, 2);
                distance = Math.sqrt(exp1 + exp2);
                x = coordinate.x - distance;
                y = coordinate.y - distance;
                xz = x + distance - (x - distance);
                yz = y + distance - (y - distance);
                xRegret = coordinate.x + distance;
                yRegret = coordinate.y + distance;
              } else {
                x = coordinate.x;
                y = coordinate.y;
                xz = path.get('x') - x;
                yz = path.get('y') - y;
                xRegret = path.get('x');
                yRegret = path.get('y');
              }
              context.setLineDash([5, 3]);
              context.strokeStyle = '#808080';
              context.lineWidth = 1;
              if (type === 'circle') {
                context.strokeRect(
                  x - half,
                  y - half,
                  xz + boxSize,
                  yz + boxSize
                );
              } else {
                context.strokeRect(
                  x - half,
                  y - half,
                  xz + boxSize,
                  yz + boxSize
                );
              }
              context.setLineDash([]);
              if (type !== 'text') {
                for (var i = 0; i < 8; i++) {
                  let blockX;
                  let blockY;
                  let w;
                  let h;

                  w = xz / 2;
                  h = yz / 2;
                  switch (i) {
                    case 0:
                      blockX = x - half - offSet;
                      blockY = y - half - offSet;
                      break;
                    case 1:
                      blockX = x + w - half;
                      blockY = y - half - offSet;
                      break;
                    case 2:
                      blockX = xRegret - half + offSet;
                      blockY = y - half - offSet;
                      break;
                    case 3:
                      blockX = x - half - offSet;
                      blockY = y + h - half;
                      break;
                    case 4:
                      blockX = xRegret - half + offSet;
                      blockY = y + h - half;
                      break;
                    case 5:
                      blockX = x - half - offSet;
                      blockY = yRegret - half + offSet;
                      break;
                    case 6:
                      blockX = x + w - half;
                      blockY = yRegret - half + offSet;
                      break;
                    case 7:
                      blockX = xRegret - half + offSet;
                      blockY = yRegret - half + offSet;
                      break;
                    default:
                      break;
                  }
                  context.strokeStyle = 'black';
                  context.lineWidth = 2;
                  context.fillStyle = 'white';
                  context.fillRect(blockX, blockY, boxSize, boxSize);
                  context.strokeRect(blockX, blockY, boxSize, boxSize);
                  ghostContext.fillStyle = reservedColors[i];
                  ghostContext.fillRect(blockX, blockY, boxSize, boxSize);
                }
              }
            }
          }
        });
      }
    }
  };
  /**add array of images to board  */
  async function addImageToCanvas() {
    const curFiles = inputRef.current.files;
    for (let file of curFiles) {
      var img = new Image();
      try {
        img.src = URL.createObjectURL(file);
        // eslint-disable-next-line no-loop-func
        await new Promise(resolve => {
          img.onload = function() {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            var height = img.height;
            var width = img.width;
            // Set width and height
            canvas.width = width;
            canvas.height = height;
            // Draw the image
            ctx.drawImage(img, 0, 0);
            const dataUrii = canvas.toDataURL('image/png');
            const drawElement = new Y.Map();
            const randomColor = getRandomColor();
            drawElement.set('type', 'image');
            drawElement.set('active', false);
            drawElement.set('image', dataUrii);
            drawElement.set('colorHash', randomColor);
            drawElement.set('coordinate', { x: 100, y: 100 });
            drawElement.set('ogSize', { width: width, height: height });
            drawElement.set('path', 'firstTime');
            yjs.set(randomColor, drawElement);
            resolve();
          };
        });
      } catch (e) {
        //console.log('err', e);
      }
    }
  }
  useEffect(() => {
    if (yjs) {
      const requestAnimationFrame = window.requestAnimationFrame || setTimeout;
      /** 60FPS  */
      const requestDrawAnimationFrame = () => {
        setRedraw(true);
        requestAnimationFrame(draw);
      };
      yjs.observeDeep(requestDrawAnimationFrame);
      inputRef.current.addEventListener('change', addImageToCanvas);
    }
  }, [yjs]);

  useEffect(() => {
    if (activeFigure) {
      setActiveColor(yjs.get(activeFigure).get('color'));
    }
  }, [activeFigure]);

  useEffect(() => {
    if (activeTool !== 'select') {
      canvasRef.current.style.cursor = 'crosshair';
      if (activeFigure) {
        yjs.get(activeFigure).set('active', false);
      }
    }
  }, [activeTool]);

  // useEffect(() => {
  //   console.log('resizing?', resizing);
  // },[resizing])

  //useEffects
  useEffect(() => {
    //yjs
    const doc = new Y.Doc();
    //TODO change 1234 with actually board Id
    const provider = new WebsocketProvider(
      'wss://pointer.studentsplus.nl/1234/',
      activeDashboardId,
      doc
    );
    const drawingContent = doc.getMap(currentId);
    //checkpoint array to map
    // const requestAnimationFrame = window.requestAnimationFrame || setTimeout
    setYjs(drawingContent);
    setRedraw(true);
    ReactTooltip.rebuild();
  }, []);

  /** whiteboard on mouse down handler */
  function onMouseDownHandler(e) {
    let bounds = canvasRef.current.getBoundingClientRect();
    const context = canvasRef.current.getContext('2d');
    let x =
      ((e.clientX - bounds.left) / (bounds.right - bounds.left)) *
        canvasRef.current.width || e.touches[0].clientX;
    let y =
      ((e.clientY - bounds.top) / (bounds.bottom - bounds.top)) *
        canvasRef.current.height || e.touches[0].clientY;

    setMouseCoords({ x: x, y: y });
    currentMouse = { x: x, y: y };

    if (activeTool !== 'select') {
      const randomColor = getRandomColor();
      setNewObjectRef(randomColor);
      const drawElement = new Y.Map();
      drawElement.set('color', activeColor);
      drawElement.set('colorHash', randomColor);
      drawElement.set('type', activeTool);
      drawElement.set('coordinate', currentMouse);
      drawElement.set('active', false);
      if (activeTool === 'draw') {
        drawElement.set('path', new Y.Array());
      }
      if (
        activeTool === 'square' ||
        activeTool === 'triangle' ||
        activeTool === 'circle' ||
        activeTool === 'line' ||
        activeTool === 'arrow'
      ) {
        drawElement.set('path', new Y.Map());
      }
      yjs.set(randomColor, drawElement);
      setDrawing(true);
    }

    if (activeTool === 'select') {
      const pixel = phantomCanvasRef.current
        .getContext('2d')
        .getImageData(currentMouse.x, currentMouse.y, 1, 1).data;
      if (pixel[0] + pixel[1] + pixel[2] !== 0) {
        const pixelArrayCheck = `rgb(${pixel[0]},${pixel[1]},${pixel[2]})`;
        if (!reservedColors.includes(pixelArrayCheck)) {
          if (activeFigure && activeFigure !== pixelArrayCheck) {
            yjs.get(activeFigure).set('active', false);
          }
          if (yjs.get(pixelArrayCheck)) {
            yjs.get(pixelArrayCheck).set('active', true);
            let currentPath = yjs.get(pixelArrayCheck).get('coordinate');
            let currentPathX = yjs
              .get(pixelArrayCheck)
              .get('path')
              .get('x');
            let currentPathY = yjs
              .get(pixelArrayCheck)
              .get('path')
              .get('y');
            let calcOffsetX =
              (currentPathX + currentPath.x) / 2 - currentMouse.x;
            let calcOffsetY =
              (currentPathY + currentPath.y) / 2 - currentMouse.y;
            setOffsets({ x: calcOffsetX, y: calcOffsetY });
            setActiveFigure(pixelArrayCheck);
            setDragging(true);
          }
        }

        if (reservedColors.includes(pixelArrayCheck)) {
          setResizing(
            reservedColors.find(element => element === pixelArrayCheck)
          );
        }
      } else {
        if (activeFigure) {
          yjs.get(activeFigure).set('active', false);
          setActiveFigure('');
        }
      }
    }
  }

  /** whiteboard on mouse move handler  */
  function onMouseMoveHandler(e) {
    let bounds = canvasRef.current.getBoundingClientRect();
    let x =
      ((e.clientX - bounds.left) / (bounds.right - bounds.left)) *
      canvasRef.current.width;
    let y =
      ((e.clientY - bounds.top) / (bounds.bottom - bounds.top)) *
      canvasRef.current.height;

    coordinates.push({ x: x, y: y });
    currentMouse = { x: x, y: y };
    if (!drawing) {
      if (activeTool === 'select') {
        const pixel = phantomCanvasRef.current
          .getContext('2d')
          .getImageData(currentMouse.x, currentMouse.y, 1, 1).data;

        if (pixel[0] + pixel[1] + pixel[2] !== 0) {
          const newBalance = `rgb(${pixel[0]},${pixel[1]},${pixel[2]})`;
          switch (newBalance) {
            case northWest:
            case southEast:
              canvasRef.current.style.cursor = 'nwse-resize';
              break;
            case north:
            case south:
              canvasRef.current.style.cursor = 'ns-resize';
              break;
            case northEast:
            case southWest:
              canvasRef.current.style.cursor = 'nesw-resize';
              break;
            case west:
            case east:
              canvasRef.current.style.cursor = 'ew-resize';
              break;
            default:
              canvasRef.current.style.cursor = 'move';
              break;
          }
        } else {
          canvasRef.current.style.cursor = 'default';
        }

        if (resizing) {
          const whomstHandle = resizing;
          let currentPath = yjs.get(activeFigure).get('coordinate');
          switch (whomstHandle) {
            case northWest:
              yjs
                .get(activeFigure)
                .set('coordinate', { x: currentMouse.x, y: currentMouse.y });
              break;
            case north:
              yjs
                .get(activeFigure)
                .set('coordinate', { x: currentPath.x, y: currentMouse.y });
              break;
            case northEast:
              yjs
                .get(activeFigure)
                .set('coordinate', { x: currentPath.x, y: currentMouse.y });
              yjs
                .get(activeFigure)
                .get('path')
                .set('x', currentMouse.x);
              break;
            case west:
              yjs
                .get(activeFigure)
                .set('coordinate', { x: currentMouse.x, y: currentPath.y });
              break;
            case east:
              yjs
                .get(activeFigure)
                .get('path')
                .set('x', currentMouse.x);
              break;
            case southWest:
              yjs
                .get(activeFigure)
                .set('coordinate', { x: currentMouse.x, y: currentPath.y });
              yjs
                .get(activeFigure)
                .get('path')
                .set('y', currentMouse.y);
              break;
            case south:
              yjs
                .get(activeFigure)
                .get('path')
                .set('y', currentMouse.y);
              break;
            case southEast:
              yjs
                .get(activeFigure)
                .get('path')
                .set('y', currentMouse.y);
              yjs
                .get(activeFigure)
                .get('path')
                .set('x', currentMouse.x);
              break;
            default:
              break;
          }
        }

        if (dragging && !resizing) {
          let currentPath = yjs.get(activeFigure).get('coordinate');
          let currentPathX = yjs
            .get(activeFigure)
            .get('path')
            .get('x');
          let currentPathY = yjs
            .get(activeFigure)
            .get('path')
            .get('y');
          yjs.get(activeFigure).set('coordinate', {
            x: currentMouse.x + offsets.x - (currentPathX - currentPath.x) / 2,
            y: currentMouse.y + offsets.y - (currentPathY - currentPath.y) / 2,
          });
          yjs
            .get(activeFigure)
            .get('path')
            .set(
              'x',
              currentMouse.x + offsets.x + (currentPathX - currentPath.x) / 2
            );
          yjs
            .get(activeFigure)
            .get('path')
            .set(
              'y',
              currentMouse.y + offsets.y + (currentPathY - currentPath.y) / 2
            );
        }
      }
      return;
    }

    if (yjs && activeTool === 'draw') {
      //works for now, should be reviewed for simultanious use
      //always gets last from array, can cause conflict if two users draw at the same time
      yjs
        .get(newObjectRef)
        .get('path')
        .push([currentMouse]);
    }
    if (
      activeTool === 'square' ||
      activeTool === 'triangle' ||
      activeTool === 'circle' ||
      activeTool === 'line' ||
      activeTool === 'arrow'
    ) {
      //works for now, should be reviewed for simultanious use
      //always gets last from array, can cause conflict if two users draw at the same time

      //JAJAJAJAJJAJA
      yjs
        .get(newObjectRef)
        .get('path')
        .set('x', currentMouse.x);
      yjs
        .get(newObjectRef)
        .get('path')
        .set('y', currentMouse.y);
    }
  }

  /** whiteboard on mouse up handler */
  function onMouseUpHandler() {
    if (
      activeTool === 'square' ||
      activeTool === 'triangle' ||
      activeTool === 'circle'
    ) {
      //dfg
    }
    setDrawing(false);
    setDragging(false);
    setResizing('');
    coordinates = [];
  }

  /** canvas on click handler */
  function onClickHandler() {
    if (activeTool === 'text') {
      const drawElement = new Y.Map();
      const randomColor = getRandomColor();
      drawElement.set('type', 'text');
      drawElement.set('active', false);
      //drawElement.set('image', dataUrii);
      drawElement.set('colorHash', randomColor);
      drawElement.set('text', '');
      drawElement.set('coordinate', { x: mouseCoords.x, y: mouseCoords.y });
      let emptyPath = new Y.Map();
      emptyPath.set('x', 0 + mouseCoords.x);
      emptyPath.set('y', 30 + mouseCoords.y);
      //drawElement.set('ogSize', { width: width, height: height });
      drawElement.set('path', emptyPath);
      yjs.set(randomColor, drawElement);
      setActiveFigure(randomColor);
    }
  }

  /** key down listener for text editor */
  function onKeyDownHandler(e) {
    if (activeTool === 'text') {
      if (e.keyCode == 32) {
        e.preventDefault();
      }
      if (e.keyCode == 8) {
        let currentText = yjs.get(activeFigure).get('text');
        if (currentText) {
          let currentDim = yjs.get(activeFigure).get('coordinate');
          let context = canvasRef.current.getContext('2d');
          let newText = currentText.slice(0, -1);
          let textLength = context.measureText(newText).width;
          yjs.get(activeFigure).set('text', newText);
          yjs
            .get(activeFigure)
            .get('path')
            .set('x', textLength + currentDim.x);
          yjs
            .get(activeFigure)
            .get('path')
            .set('y', 30 + currentDim.y);
        }
      }
      if (
        (e.keyCode >= 48 && e.keyCode <= 90) ||
        e.keyCode == 32 ||
        (e.keyCode >= 96 && e.keyCode <= 111) ||
        (e.keyCode >= 160 && e.keyCode <= 165) ||
        (e.keyCode >= 170 && e.keyCode <= 171) ||
        (e.keyCode >= 186 && e.keyCode <= 223)
      ) {
        let currentText = yjs.get(activeFigure).get('text');
        let currentDim = yjs.get(activeFigure).get('coordinate');
        let context = canvasRef.current.getContext('2d');
        let newText = (currentText += e.key);
        let textLength = context.measureText(newText).width;
        yjs.get(activeFigure).set('text', newText);
        yjs
          .get(activeFigure)
          .get('path')
          .set('x', textLength + currentDim.x);
        yjs
          .get(activeFigure)
          .get('path')
          .set('y', 30 + currentDim.y);
      }
    }
  }

  /** clear the canvas */
  function clearCanvas() {
    yjs.delete('imageTest');
    const context = canvasRef.current.getContext('2d');
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setActiveFigure('');
    yjs.forEach(drawElement => {
      const colorHash = drawElement.get('colorHash');
      yjs.delete(colorHash);
    });
  }

  /** toggles color picker */
  function toggleColorPicker() {
    setColorPickerToggle(!colorPickerToggle);
  }

  /** handle color change */
  const handleChange = color => {
    setActiveColor(color.hex);
    if (activeFigure) {
      yjs.get(activeFigure).set('color', color.hex);
    }
  };
  /**
   * Handle Drag Over
   */
  const handleDragOver = (ev, id) => {
    ev.preventDefault();
    let typeSmall = '';
    let isImage = false;

    if (props.storeDrag) return;

    if (ev.dataTransfer.types && ev.dataTransfer.types[0] == 'Files') {
      //Drag from outside the browser
    } else if (props.dragInfo.name) {
      typeSmall = props.dragInfo.name.split('.').pop();
      isImage = props.dragInfo.datatype.includes('image');

      if (isImage) {
        typeSmall = 'image';
      }
    }

    if (typeSmall === 'image') {
      document.getElementById(id).style.backgroundColor = theme.color.success;
      document.getElementById(id).style.transition = '0.35s';
    }
  };

  /**
   * Handle drop
   */
  const handleDrop = async (ev, id) => {
    ev.preventDefault();
    document.getElementById(id).style.backgroundColor = '';
    document.getElementById(id).style.transition = '';

    //Check if the drag is from the dashboard itself
    if (props.dragInfo.type === 'document') {
      let typeSmall = props.dragInfo.name.split('.').pop();
      const isImage = props.dragInfo.datatype.includes('image');
      const reader = new FileReader();
      let blob = null;
      let xhr = new XMLHttpRequest();

      if (isImage) {
        typeSmall = 'image';
      }

      switch (typeSmall) {
        default:
          return;
        case 'image': {
          xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = async function() {
              var img = new Image();
              img.src = reader.result;
              await new Promise(resolve => {
                img.onload = function() {
                  const canvas = document.createElement('canvas');
                  const ctx = canvas.getContext('2d');
                  var height = img.height;
                  var width = img.width;
                  // Set width and height
                  canvas.width = width;
                  canvas.height = height;
                  // Draw the image
                  ctx.drawImage(img, 0, 0);
                  const dataUrii = canvas.toDataURL('image/png');
                  const drawElement = new Y.Map();
                  const randomColor = getRandomColor();
                  drawElement.set('type', 'image');
                  drawElement.set('active', false);
                  drawElement.set('image', dataUrii);
                  drawElement.set('colorHash', randomColor);
                  drawElement.set('coordinate', { x: 100, y: 100 });
                  drawElement.set('ogSize', { width: width, height: height });
                  drawElement.set('path', 'firstTime');
                  yjs.set(randomColor, drawElement);
                  resolve();
                };
              });
            };
            reader.readAsDataURL(xhr.response);
          };
          xhr.open('GET', props.dragInfo.url);
          xhr.responseType = 'blob';
          xhr.send();
          break;
        }
      }

      props.globalDragInfo({});
    } else if (ev && ev.dataTransfer && ev.dataTransfer.types) {
      //Check if external drop e.g. local files
      for (var i = 0; i < ev.dataTransfer.types.length; i++) {
        if (ev.dataTransfer.types && ev.dataTransfer.types[i] == 'Files') {
          let currentFile = ev.dataTransfer.items[i];
          let type = currentFile.type.slice(0, currentFile.type.indexOf('/'));
          let file = ev.dataTransfer.files[0];
          let typeSmall = file.name.split('.').pop();
          const path = 'documents/' + currentId + '/';
          const name = file.name;
          const acceptedTypes = ['xlsx', 'docx', 'txt', 'pdf', 'ppt'];
          const reader = new FileReader();

          if (type === 'image') typeSmall = 'image';

          if (type !== 'image' && !acceptedTypes.includes(typeSmall)) {
            setErrorType(true);
          } else {
            reader.onloadend = async function(event) {
              var img = new Image();
              img.src = event.target.result;
              await new Promise(resolve => {
                img.onload = function() {
                  const canvas = document.createElement('canvas');
                  const ctx = canvas.getContext('2d');
                  var height = img.height;
                  var width = img.width;
                  // Set width and height
                  canvas.width = width;
                  canvas.height = height;
                  // Draw the image
                  ctx.drawImage(img, 0, 0);
                  const dataUrii = canvas.toDataURL('image/png');
                  const drawElement = new Y.Map();
                  const randomColor = getRandomColor();
                  drawElement.set('type', 'image');
                  drawElement.set('active', false);
                  drawElement.set('image', dataUrii);
                  drawElement.set('colorHash', randomColor);
                  drawElement.set('coordinate', { x: 100, y: 100 });
                  drawElement.set('ogSize', { width: width, height: height });
                  drawElement.set('path', 'firstTime');
                  yjs.set(randomColor, drawElement);
                  resolve();
                };
              });

              setErrorType(false);
            };
            reader.readAsDataURL(file);
          }
        }
      }
    }
  };

  //return render JSX
  return (
    <StyledMainWrapper
      onDrop={e => handleDrop(e, currentId)}
      onDragOver={e => handleDragOver(e, currentId)}
    >
      <Grid rows='1fr' scrollbar={false}>
        <WhiteBoardWrapper className='editable'>
          <Help
            activeDashboardId={activeDashboardId}
            currentId={currentId}
            url='/resources/whiteboardhelp.gif'
            title={t('help:whiteboard.title')}
            text={t('help:whiteboard.text')}
          />
          <StyledSelectIsland>
            <input
              ref={inputRef}
              id='fileid'
              type='file'
              accept='image/*'
              hidden
              multiple
            />
            {Object.entries(options).map(function(value) {
              return (
                // eslint-disable-next-line react/jsx-key
                <StyledButtonWrapper key={value[0] + 'button'}>
                  <StyledButton
                    data-tip=''
                    data-for={value[0]}
                    key={value}
                    variant='icon'
                    onClick={() => {
                      if (value[0] === 'image') {
                        inputRef.current.click();
                      } else {
                        setActiveTool(value[0]);
                      }
                    }}
                    bgColor={theme.color.dark[8]}
                    active={value[0] === activeTool}
                    icon={{
                      padding: rem(12),
                      icon: value[1]['icon'],
                      size: 20,
                    }}
                  />
                  {value[0] === 'select' ||
                  value[0] === 'circle' ||
                  value[0] === 'text' ? (
                    <StyledDivider />
                  ) : null}
                  <Tooltip
                    noSpacing
                    id={value[0]}
                    delayShow={0}
                    backgroundColor='#4057a2'
                    content={{
                      title: {
                        text: value[1]['tip'],
                        textColor: '#fff',
                        align: 'center',
                      },
                    }}
                  />
                </StyledButtonWrapper>
              );
            })}
          </StyledSelectIsland>
          <StyledGarbageIsland
            data-tip=''
            data-for='removewhiteboardcontent'
            variant='icon'
            onClick={() => {
              clearCanvas();
            }}
            bgColor={theme.color.dark[8]}
            icon={{
              padding: rem(12),
              icon: 'delete',
              size: 20,
            }}
          />
          <StyledColorIsland
            //ref={myColorRef}
            //id="colorIsland"
            variant='icon'
            color={activeColor}
            onClick={() => {
              toggleColorPicker();
            }}
            bgColor={theme.color.dark[8]}
          />
          {colorPickerToggle ? (
            <StyledColorPickerWrapper>
              <ChromePicker
                onChange={handleChange}
                color={
                  // yjs.get(activeFigure) && yjs.get(activeFigure).get('color')
                  activeColor
                }
              />
            </StyledColorPickerWrapper>
          ) : null}

          <Tooltip
            noSpacing
            id='removewhiteboardcontent'
            delayShow={800}
            backgroundColor='#4057a2'
            content={{
              title: {
                text: t('tooltip:whiteboard.remove'),
                textColor: '#fff',
                align: 'center',
              },
            }}
          />
          <StyledCanvasWrapper>
            <StyledCanvas
              ref={canvasRef}
              tabIndex='0'
              className='editable'
              width='1280'
              height='720'
              //crosshairCursor={activeTool !== 'select'}
              onMouseDown={e => onMouseDownHandler(e)}
              onMouseUp={() => onMouseUpHandler()}
              onMouseMove={e => onMouseMoveHandler(e)}
              onClick={() => onClickHandler()}
              onKeyDown={e => onKeyDownHandler(e)}
            />
            <StyledPhantomCanvas
              ref={phantomCanvasRef}
              width='1280'
              height='720'
            />
          </StyledCanvasWrapper>
        </WhiteBoardWrapper>
      </Grid>
    </StyledMainWrapper>
  );
};

/** pass currentUser and currentChannel from redux to global props */
const mapStateToProps = state => ({
  dragInfo: state.globaldraginfo.globalDragInfo,
  storeDrag: state.storewidgetdrag.storeWidgetDrag,
});

//export
export default connect(mapStateToProps, {
  globalDragInfo,
  storeWidgetDrag,
})(WhiteboardContent);
