/* eslint-disable prettier/prettier */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Quill from 'quill';
import katex from 'katex';
import QuillCursors from 'quill-cursors';
import { convertToHtml } from 'mammoth';
import { useTranslation } from 'react-i18next';
import Ink from 'react-ink';
import * as Y from 'yjs';
import { WebsocketProvider } from 'y-websocket';
import { QuillBinding } from 'y-quill';
import Grid from '../Grid';
import theme, { rem } from '../../theme';
import firebase, {
  userListner,
  noteListner,
  userNoteListner,
} from '../../firebase';
import Group from '../Group';
import Button from '../Button';
import Icon from '../Icon';
import ColorPicker from '../ColorPicker';
import EmptyState from '../EmptyState';
import Animation from '../LottieControl';
import Help from '../Help';
import Tooltip from '../Tooltip';
import { globalDragInfo, storeWidgetDrag } from '../../redux/actions';
import 'react-quill/dist/quill.snow.css';
import 'katex/dist/katex.min.css';

const StyledWrapper = styled.div`
  ${({ backgroundColor, dbTextColor }) => `
    background: ${backgroundColor ? backgroundColor : 'yellowgreen'};
    display: flex;
    position: relative;
    border-radius: ${rem(20)};
    height: calc(100% - ${rem(20)});
    border-radius: ${rem(10)};
    margin: ${rem(15)} ${rem(15)} ${rem(20)} ${rem(15)};

    .ql-editor {
      color: ${dbTextColor};
    }
  `}
`;

const StyledGroup = styled(Group)`
  position: absolute;
  bottom: ${rem(10)};
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin-right: ${rem(20)};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledWrapperExceeded = styled.div`
  position: absolute;
  left: -${rem(20)};
  top: -${rem(17)};
  z-index: 4;
`;

const StyledAnimation = styled(Animation)`
  cursor: help;
`;

const StyledIndicatorWrapper = styled.div`
  position: absolute;
  right: ${rem(16)};
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIndicator = styled.div`
  background: ${theme.color.light[80]};
  border-radius: ${rem(20)};
  padding: 0 ${rem(6)};
  min-width: ${rem(25)};

  > div {
    text-align: center;
  }
`;

const StyledNote = styled.div``;

const StyledContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .quill {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ql-container {
    border: none !important;
    margin-bottom: ${rem(52)};
    position: absolute;
    right: 0;
    left: 0;
    top: 4rem;
    /* bottom: -8rem; */
    height: calc(100% - 8rem);
  }

  .ql-editor {
    padding-top: 0;
    margin-top: 12px;
  }

  .ql-toolbar {
    width: auto;
    display: flex;
    overflow-x: auto;
    overflow-y: visible;
    max-width: 100%;
    position: absolute;
    right: 0;
    left: 0;
    padding: 1rem 0 !important;
    margin: 0 1rem;
    border-top: none !important;
    border-right: none !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
    border-left: none !important;
    height: 4rem;

    .ql-formats {
      display: inline-flex;
    }

    button {
      .ql-fill {
        transition: 0.3s;
      }

      .ql-stroke {
        transition: 0.3s;
      }

      &.ql-active {
        .ql-stroke {
          stroke: ${theme.color.primary[100]} !important;
        }

        .ql-fill {
          fill: ${theme.color.primary[100]} !important;
        }
      }

      &:hover {
        color: ${theme.color.primary[100]} !important;

        .ql-fill {
          transition: 0.3s;
          fill: ${theme.color.primary[100]} !important;
        }

        .ql-stroke {
          transition: 0.3s;
          stroke: ${theme.color.primary[100]} !important;
        }
      }
    }

    .ql-picker-label {
      transition: 0.3s;
      border: none !important;
      outline: none;

      &:hover {
        color: ${theme.color.primary[100]} !important;

        .ql-fill {
          transition: 0.3s;
          fill: ${theme.color.primary[100]} !important;
        }

        .ql-stroke {
          transition: 0.3s;
          stroke: ${theme.color.primary[100]} !important;
        }
      }
    }
  }

  .ql-picker-options {
    position: fixed !important;
    top: 52px !important;
    min-width: ${rem(150)} !important;
    border: none !important;
    border-radius: ${rem(8)};
  }

  .ql-tooltip {
    position: fixed !important;
    z-index: 4;
    border: none !important;
    border-radius: ${rem(8)};
  }

  .ql-picker {
    &.ql-size {
      &.ql-expanded {
        .ql-picker-options {
          left: ${rem(10)};
          right: auto;
        }
      }
    }

    &.ql-expanded {
      .ql-picker-options {
        right: ${rem(10)};
      }
    }
  }
`;

const StyledText = styled.div`
  color: #000;
  font-size: 0.7rem;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.color.light[80]};
  width: ${rem(45)};
  height: ${rem(45)};
  border-radius: ${rem(40)};
  position: relative;
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${theme.color.primary[100]};
  }

  input {
    display: none;
  }
`;

const StyledMainWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

/** NoteContent */
const NoteContent = props => {
  const { currentId, activeDashboardId, widgetId } = props;
  //Refs
  const noteRef = useRef();
  const counterRef = useRef();
  const exceededRef = useRef();

  //UserId
  const uuid = firebase.auth().currentUser.uid;
  //States
  const [dbNoteColor, setDbNoteColor] = useState();
  const [dbTextColor, setDbTextColor] = useState();
  const [initial, setInitial] = useState({});
  const [loading, setLoading] = useState(true);
  const [maxCharacters, setMaxCharacters] = useState();
  const [totalCharacters, setTotalCharacters] = useState();
  const [numberIndication, setNumberIndication] = useState();
  const [readOnly, setReadOnly] = useState();
  const [userInfo, setUserInfo] = useState({});
  const [maxExceeded, setMaxExceeded] = useState(false);
  const [currentEditor, setCurrentEditor] = useState();
  const [colorPicker, setColorPicker] = useState(false);

  //Translation
  const [t] = useTranslation(['common', 'help', 'tooltip', 'emptystate']);

  useEffect(() => {
    if (currentId) setListners();
  }, []);

  useEffect(() => {
    if (noteRef.current) {
      //Get the toolbar height
      const ydoc = new Y.Doc();
      const provider = new WebsocketProvider(
        'wss://pointer.studentsplus.nl/1234/',
        currentId,
        ydoc
      );
      const type = ydoc.getText('quill');

      Quill.register('modules/cursors', QuillCursors);

      Quill.register('modules/counter', function(QuillCursors, options) {
        var container = document.querySelector(options.container);
        QuillCursors.on('text-change', function() {
          document.getElementById(
            'counter-' + currentId
          ).parentElement.style.opacity = '1';
          var text = QuillCursors.getText();
          if (options.unit === 'word') {
            container.innerText = text.split(/\s+/).length + t('words');
          } else {
            if (text.length > maxCharacters) {
              QuillCursors.deleteText(maxCharacters, QuillCursors.getLength());
              setMaxExceeded(true);
            } else {
              setMaxExceeded(false);
              let number = maxCharacters - (text.length - 1);

              if (number < 0) number = 0;

              container.innerText = number;
            }
          }
        });
      });

      const editorContainer = document.getElementById('note-' + currentId);
      const counterContainer = '#counter-' + currentId;
      const editor = new Quill(editorContainer, {
        modules: {
          counter: {
            container: counterContainer,
            unit: 'characters',
          },
          cursors: true,
          toolbar: [
            // [{ header: [1, 2, false] }],
            [{ size: ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['formula'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            ['image'],
          ],
          history: {
            userOnly: true,
          },
        },
        placeholder: t('collaboration.start'),
        theme: 'snow', // or 'bubble'
      });

      new QuillBinding(type, editor, provider.awareness);
      //attachQuillRefs()

      provider.awareness.setLocalStateField('user', {
        name: userInfo.name,
        color: userInfo.color ? userInfo.color : '#254781',
      });
      window.katex = katex;

      setCurrentEditor(editor);
    }
  }, [noteRef.current]);

  /** Set listners */
  const setListners = () => {
    userListner
      .child(uuid)
      .child('public')
      .on('value', snap => {
        if (snap.val()) {
          setUserInfo(snap.val());
        }
      });

    userNoteListner
      .child(uuid)
      .child(currentId)
      .child('colors')
      .on('value', snap => {
        if (snap.val()) {
          setDbNoteColor(snap.val().noteColor);
          setDbTextColor(snap.val().noteTextColor);
        }
      });

    noteListner
      .child(currentId)
      .child('maxCharacters')
      .on('value', snap => {
        setMaxCharacters(snap.val());
        setNumberIndication(snap.val());
      });

    noteListner
      .child(currentId)
      .child('inUse')
      .on('value', snap => {
        if (snap.val() && snap.val() !== false && snap.val().user !== uuid) {
          userListner
            .child(snap.val().user)
            .child('public')
            .once('value', snap => {
              setReadOnly(snap.val());
            });
        } else {
          setReadOnly(false);
        }
      });

    noteListner
      .child(currentId)
      .child('text')
      .once('value', snap => {
        if (snap.val()) {
          let dataObject = {};
          dataObject[currentId] = {};
          dataObject[currentId] = JSON.parse(snap.val());
          setInitial(JSON.parse(snap.val()));
        } else {
          let dataObject = {};
          dataObject[currentId] = null;
          setInitial(dataObject);
        }
      })
      .then(() => {
        setLoading(false);
      });

    noteListner.child(currentId).on('child_changed', snap => {
      if (snap.key === 'text') {
        if (snap.val()) {
          noteListner
            .child(currentId)
            .child('lastEditedBy')
            .once('value', snapshot => {
              setInitial(JSON.parse(snap.val()));
            });
        }
      }
    });
  };

  /** onFileDownload */
  const onFileDownload = () => {
    const editor_content = currentEditor.root.innerHTML;
    var preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
      "xmlns:w='urn:schemas-microsoft-com:office:word' " +
      "xmlns='http://www.w3.org/TR/REC-html40'>" +
      "<head><meta charset='utf-8'><title></title></head><body>";
    var postHtml = '</body></html>';
    var html = preHtml + editor_content + postHtml;
    var blob = new Blob(['\ufeff', html], {
      type: 'application/msword',
    });

    // Specify file name
    let filename = filename ? filename + '.doc' : t('main.name') + '.doc';

    // Create download link element
    var downloadLink = document.createElement('a');

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Setting the file name
      downloadLink.setAttribute('download', filename);
      downloadLink.setAttribute(
        'href',
        'data:' +
          'msword;charset=utf-8' +
          ';charset=utf-8,' +
          encodeURIComponent(html)
      );

      //triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  };

  /** OnFileChange */
  const onFileChange = async e => {
    if (e && e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const name = file.name;
      const typeSmall = name.split('.').pop();
      const reader = new FileReader();

      switch (typeSmall) {
        default:
        case 'doc':
          reader.responseType = 'text';
          reader.onload = async e => {
            const delta = currentEditor.clipboard.convert(e.target.result);
            currentEditor.setContents(delta, 'silent');
          };
          reader.readAsText(file);
          break;
        case 'docx':
          reader.responseType = 'text';
          reader.onload = async e => {
            const arrayBuffer = reader.result;
            convertToHtml({ arrayBuffer: arrayBuffer })
              .then(function(resultObject) {
                const testHTML = resultObject.value;
                const delta = currentEditor.clipboard.convert(testHTML);
                currentEditor.setContents(delta, 'silent');
              })
              .done();
          };
          reader.readAsArrayBuffer(file);
          break;
        case 'txt':
          reader.responseType = 'text';
          reader.onload = async e => {
            const delta = currentEditor.clipboard.convert(e.target.result);
            currentEditor.setContents(delta, 'silent');
          };
          reader.readAsText(file);
          break;
      }

      document.getElementById(
        'counter-' + currentId
      ).parentElement.style.opacity = '0';
    }
  };

  /**
   * Handle drop
   */
  const handleDrop = (ev, id) => {
    ev.preventDefault();
    if (props.dragInfo.type === 'document') {
      document.getElementById(id).style.backgroundColor = '';
      document.getElementById(id).style.transition = '';

      let typeSmall = props.dragInfo.name.split('.').pop();
      const isImage = props.dragInfo.datatype.includes('image');
      const reader = new FileReader();
      let blob = null;
      let xhr = new XMLHttpRequest();

      if (isImage) {
        typeSmall = 'image';
      }

      switch (typeSmall) {
        default:
          return;
        case 'image': {
          xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
              const selection = currentEditor.getSelection(); // get position of cursor (index of selection)
              currentEditor.insertEmbed(
                selection ? selection.index : 0,
                'image',
                reader.result,
                'silent'
              );
            };
            reader.readAsDataURL(xhr.response);
          };
          xhr.open('GET', props.dragInfo.url);
          xhr.responseType = 'blob';
          xhr.send();
          break;
        }

        case 'doc': {
          xhr.open('GET', props.dragInfo.url);
          xhr.responseType = 'blob';
          xhr.onload = function() {
            blob = xhr.response;
            const file = new File([blob], props.dragInfo.name, {
              type: props.dragInfo.type,
            });
            reader.responseType = 'document';
            reader.onload = async e => {
              const delta = currentEditor.clipboard.convert(e.target.result);
              currentEditor.setContents(delta, 'silent');
            };
            reader.readAsText(file);
          };
          xhr.send();

          break;
        }

        case 'docx': {
          xhr.open('GET', props.dragInfo.url);
          xhr.responseType = 'blob';
          xhr.onload = function() {
            blob = xhr.response;
            const file = new File([blob], props.dragInfo.name, {
              type: props.dragInfo.type,
            });
            reader.responseType = 'text';
            reader.onload = async e => {
              const arrayBuffer = reader.result;
              convertToHtml({ arrayBuffer: arrayBuffer })
                .then(function(resultObject) {
                  const testHTML = resultObject.value;
                  const delta = currentEditor.clipboard.convert(testHTML);
                  currentEditor.setContents(delta, 'silent');
                })
                .done();
            };
            reader.readAsArrayBuffer(file);
          };
          xhr.send();

          break;
        }

        case 'txt': {
          xhr.open('GET', props.dragInfo.url);
          xhr.responseType = 'blob';
          xhr.onload = function() {
            blob = xhr.response;
            const file = new File([blob], props.dragInfo.name, {
              type: props.dragInfo.type,
            });
            reader.responseType = 'text';
            reader.onload = async e => {
              const delta = currentEditor.clipboard.convert(e.target.result);
              currentEditor.setContents(delta, 'silent');
            };
            reader.readAsText(file);
          };
          xhr.send();

          break;
        }
      }
      props.globalDragInfo({});
    } else {
      if (ev.dataTransfer.types) {
        for (var i = 0; i < ev.dataTransfer.types.length; i++) {
          if (ev.dataTransfer.types[i] == 'Files') {
            let currentFile = ev.dataTransfer.items[i];
            let type = currentFile.type.slice(0, currentFile.type.indexOf('/'));
            const selection = currentEditor.getSelection(); // get position of cursor (index of selection)
            let file = ev.dataTransfer.files[0];
            let typeSmall = file.name.split('.').pop();
            let reader = new FileReader();

            if (type === 'image') typeSmall = 'image';

            switch (typeSmall) {
              default:
                return;
              case 'image': {
                reader.onloadend = function(event) {
                  currentEditor.insertEmbed(
                    selection ? selection.index : 0,
                    'image',
                    event.target.result
                  );
                };

                reader.readAsDataURL(file);
                break;
              }

              case 'doc': {
                reader.responseType = 'text';
                reader.onload = async e => {
                  const delta = currentEditor.clipboard.convert(
                    e.target.result
                  );
                  currentEditor.setContents(delta, 'silent');
                };
                reader.readAsText(file);
                break;
              }

              case 'docx': {
                reader.responseType = 'text';
                reader.onload = async () => {
                  const arrayBuffer = reader.result;
                  convertToHtml({ arrayBuffer: arrayBuffer })
                    .then(function(resultObject) {
                      const testHTML = resultObject.value;
                      const delta = currentEditor.clipboard.convert(testHTML);
                      currentEditor.setContents(delta, 'silent');
                    })
                    .done();
                };
                reader.readAsArrayBuffer(file);
                break;
              }

              case 'txt': {
                reader.responseType = 'text';
                reader.onload = async e => {
                  const delta = currentEditor.clipboard.convert(
                    e.target.result
                  );
                  currentEditor.setContents(delta, 'silent');
                };
                reader.readAsText(file);
                break;
              }
            }
          }
        }
      }
    }
  };

  /**
   * Handle Drag Over
   */
  const handleDragOver = (ev, id) => {
    ev.preventDefault();
    let typeSmall = '';
    let isImage = false;
    if (props.storeDrag) return;

    if (ev.dataTransfer.types && ev.dataTransfer.types[0] == 'Files') {
      //Drag from outside the browser
    } else if (props.dragInfo.name) {
      typeSmall = props.dragInfo.name.split('.').pop();
      isImage = props.dragInfo.datatype.includes('image');

      if (isImage) {
        typeSmall = 'image';
      }
    }

    if (
      typeSmall === 'doc' ||
      typeSmall === 'docx' ||
      typeSmall === 'txt' ||
      typeSmall === 'image'
    ) {
      document.getElementById(id).style.backgroundColor = theme.color.success;
      document.getElementById(id).style.transition = '0.35s';
    }
  };

  return dbTextColor &&
    dbNoteColor &&
    initial &&
    Object.keys(initial).length > 0 ? (
    <StyledMainWrapper
      onDrop={e => handleDrop(e, currentId)}
      onDragOver={e => handleDragOver(e, currentId)}
    >
      <Grid rows='1fr' scrollbar={false}>
        <StyledWrapper
          backgroundColor={dbNoteColor}
          className='editable'
          dbTextColor={dbTextColor}
        >
          <Help
            activeDashboardId={activeDashboardId}
            currentId={currentId}
            url='/resources/notehelp.gif'
            title={t('help:notecontent.title')}
            html={
              <span>
                {t('help:notecontent.text')}
                <br />
                <a
                  href='https://kapeli.com/cheat_sheets/LaTeX_Math_Symbols.docset/Contents/Resources/Documents/index'
                  target='_blank'
                >
                  {t('help:notecontent.link')}
                </a>
              </span>
            }
          />
          {initial && Object.keys(initial).length && (
            <StyledContentWrapper ref={exceededRef}>
              <StyledNote id={'note-' + currentId} ref={noteRef} />
            </StyledContentWrapper>
          )}
          <StyledGroup className='editable'>
            <Button
              tooltip={{ text: t('tooltip:noteContent.backgroundcolor') }}
              variant='icon'
              onClick={() => {
                setColorPicker(true);
                ReactTooltip.hide();
              }}
              bgColor={theme.color.light[80]}
            >
              <ColorPicker
                activeDashboardId={activeDashboardId}
                type='note'
                hideIndicator
                itemId={currentId}
                onMouseLeave={() => {
                  setColorPicker(false);
                  ReactTooltip.hide();
                }}
              />
              <Icon padding={rem(12)} icon='color_lens' size={20} />
            </Button>
            <Button
              tooltip={{
                text: colorPicker ? '' : t('tooltip:noteContent.textcolor'),
              }}
              variant='icon'
              onClick={() => {
                setColorPicker(true);
                ReactTooltip.hide();
              }}
              bgColor={theme.color.light[80]}
            >
              <Icon padding={rem(12)} icon='text_format' size={20} />
              <ColorPicker
                activeDashboardId={activeDashboardId}
                type='noteText'
                hideIndicator
                itemId={currentId}
                onMouseLeave={() => {
                  setColorPicker(false);
                  ReactTooltip.hide();
                }}
              />
            </Button>
            <Button
              tooltip={{ text: t('tooltip:noteContent.download') }}
              variant='icon'
              id={`download` + currentId}
              bgColor={theme.color.light[80]}
              onClick={() => onFileDownload()}
              icon={{
                type: 'icon',
                padding: rem(12),
                size: 20,
                icon: 'get_app',
              }}
            />
            <StyledLabel
              data-for={'tooltipNoteUpload-' + currentId}
              data-delay-show='500'
              data-tip=''
            >
              <Ink background={false} />
              <Icon icon='insert_drive_file' iconSize='20' size={20} />
              <input
                type='file'
                id={`test` + currentId}
                onChange={e => onFileChange(e)}
                accept='text/plain, .docx, .doc'
              />
              <Tooltip
                id={'tooltipNoteUpload-' + currentId}
                content={{
                  title: {
                    text: t('tooltip:noteContent.upload'),
                    textStyle: 'h6',
                    textColor: '#fff',
                    align: 'center',
                  },
                  animation: 'warning',
                  text: {
                    text: t('tooltip:noteContent.uploadtext'),
                    textStyle: 'subtitle',
                    textColor: '#fff',
                    align: 'center',
                  },
                }}
              />
            </StyledLabel>
            <StyledIndicatorWrapper>
              <StyledIndicator>
                <StyledText ref={counterRef} id={'counter-' + currentId} />
              </StyledIndicator>
              {maxExceeded && (
                <StyledWrapperExceeded>
                  <StyledAnimation
                    animation='alert'
                    animationWidth={30}
                    animationHeight={30}
                    data-for={`tooltipNoteCharacters-${currentId}`}
                    data-tip=''
                  />
                  <Tooltip
                    id={`tooltipNoteCharacters-${currentId}`}
                    place='left'
                    content={{
                      title: {
                        text: t('tooltip:errors.characters'),
                        textStyle: 'h6',
                        textColor: '#fff',
                        align: 'center',
                      },
                      animation: 'cry',
                    }}
                  />
                </StyledWrapperExceeded>
              )}
            </StyledIndicatorWrapper>
          </StyledGroup>
        </StyledWrapper>
      </Grid>
      {!colorPicker && <Tooltip delayShow={500} backgroundColor='#4057a2' />}
    </StyledMainWrapper>
  ) : (
    <EmptyState widgetId={widgetId} text={t('emptystate:notecontent.title')} />
  );
};

NoteContent.propTypes = {
  /** Active dashboard */
  activeDashboardId: PropTypes.string,
  /** Current Id */
  currentId: PropTypes.string,
  /** Current widget Id */
  widgetId: PropTypes.string,
};

NoteContent.defaultProps = {
  activeDashboardId: null,
  currentId: null,
  widgetId: null,
};

/** pass currentUser and currentChannel from redux to global props */
const mapStateToProps = state => ({
  dragInfo: state.globaldraginfo.globalDragInfo,
  storeDrag: state.storewidgetdrag.storeWidgetDrag,
});

export default connect(mapStateToProps, {
  globalDragInfo,
  storeWidgetDrag,
})(NoteContent);
