import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from '../../theme';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.horAlign};

  button,
  div {
    margin-right: ${props => props.spacing};
    vertical-align: middle;
    &:last-child {
      margin-right: 0;
    }
  }
`;

/**
 * @param {node} children
 * @param {string} spacing
 * @param {string} horAlign
 */
const ButtonGroup = ({ spacing, children, horAlign, ...props }) => (
  <StyledWrapper spacing={spacing} horAlign={horAlign} {...props}>
    {children}
  </StyledWrapper>
);

ButtonGroup.propTypes = {
  /** Children */
  children: PropTypes.node.isRequired,

  /** Alignment */
  horAlign: PropTypes.string,

  /** Padding */
  spacing: PropTypes.string,
};

ButtonGroup.defaultProps = {
  horAlign: 'left',
  spacing: rem(8),
};

/** @component */
export default ButtonGroup;
