import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import firebase from '../../firebase';
import Grid from '../Grid';
import ScrollIndicator from '../ScrollIndicator';
import OlderItems from './OlderItems';
import EmptyState from '../EmptyState';
import { rem } from '../../theme';

const StyledWrapper = styled.div`
  padding: ${rem(12)} 0;
`;

const StyledEmptyState = styled(EmptyState)`
  margin-left: ${rem(50)};
`;

/** LazyLoad component
 * @param {string} activeId
 * @param {string} type
 * @param {node} Node
 * @param {string} activeDashboardId
 * @param {object} friendData
 * @param {bool} chunksFromTop
 * @param {bool} hasSound
 * @param {string} widgetId
 */
const LazyLoad = props => {
  const {
    type,
    activeId,
    Node,
    activeDashboardId,
    friendData,
    chunksFromTop,
    hasSound,
    widgetId,
  } = props;
  /** UserId */
  const uuid = firebase.auth().currentUser.uid;

  //Translation
  const [t] = useTranslation('lazyload');

  /** States */
  const [chunk] = useState(10);
  const [playSound, setPlaySound] = useState(false);
  const [scrollTop, setScrollTop] = useState({});
  const [scrollDown, setScrollDown] = useState({});
  const [newChunk, setNewChunk] = useState(uuid);
  const [messageChunks, setMessageChunks] = useState({});
  const [referenceToOldestKey, setReferenceToOldestKey] = useState('');
  const [mainChunks, setMainChunks] = useState({});
  const [oldMessageChunks, setOldMessageChunks] = useState({});
  const [messageCount, setMessageCount] = useState(0);
  const [currentDate, setCurrentDate] = useState(null);
  const [showIndicator, setShowIndicator] = useState(false);
  const [positionDown, setPositionDown] = useState(
    chunksFromTop ? true : false
  );
  const [firstElement] = useState(true);
  const [firstMessage, setFirstMessage] = useState(false);
  const scrollRef = useRef();
  const preOwnerRef = useRef();

  // Indicator
  const timeIndicator = 'timeIndicator-' + activeId;
  let timer = null;

  /** set message listner */
  const setMessageListner = () => {
    const listner = firebase
      .database()
      .ref()
      .child(type)
      .child(activeId)
      .child('items');

    listner
      .orderByChild('time')
      .startAt(Date.now())
      .on('child_added', snap => {
        setNewChunk(snap.val().owner);
        setCurrentDate(null);
        setPlaySound(false);
        setFirstMessage(false);
        if (uuid === snap.val().owner) {
          setPositionDown(chunksFromTop);
          scrollDown[activeId]['state'] = chunksFromTop;
          if (!chunksFromTop) scrollTop[activeId]['state'] = true;
        } else {
          setMessageCount(messageCount + 1);
          if (chunksFromTop) scrollDown[activeId]['state'] = true;
          if (!chunksFromTop) scrollTop[activeId]['state'] = false;
        }

        setScrollDown(prevState => {
          // Object.assign would also work
          return { ...prevState, ...scrollDown };
        });

        if (!chunksFromTop) {
          setScrollTop(prevState => {
            // Object.assign would also work
            return { ...prevState, ...scrollTop };
          });
        }
        const dateNow = Date.now();
        messageChunks[snap.key] = {};
        messageChunks[snap.key]['owner'] = snap.val().owner;
        messageChunks[snap.key]['id'] = snap.key;
        messageChunks[snap.key]['items'] = {};
        messageChunks[snap.key]['items'][snap.key] = snap.val();

        if (
          messageChunks[snap.key]['owner'] !== uuid &&
          document.visibilityState !== 'visible'
        ) {
          setPlaySound(true);
        }

        setMessageChunks(prevState => {
          return { ...prevState, ...messageChunks };
        });
      });

    listner.on('child_changed', snap => {
      if (chunksFromTop && snap.val().lastEditedBy === uuid) {
        scrollDown[activeId]['state'] = true;
        setTimeout(() => setScrollDown(scrollDown), 500);
      }
    });

    listner.on('child_removed', snapshot => {
      if (snapshot.val()) {
        listner.once('value', function(snap) {
          if (!snap.val()) {
            //console.log('REMOVE 2', messageChunks);
            //setMessageChunks({});
            //setFirstMessage(true);
          }
        });
      }
    });
  };

  useEffect(() => {
    init();
    setMessageListner();
    scrollDown[activeId] = {};
    scrollTop[activeId] = {};
    setScrollDown(scrollDown);
    setScrollTop(scrollTop);
  }, []);

  useEffect(() => {
    if (document.getElementById(activeId)) {
      const element = document
        .getElementById(activeId)
        .getElementsByClassName('wrapperClass')[0];

      if (element) {
        if (firstMessage) {
          element.style.display = 'none';
        } else {
          element.style.display = '';
        }
      }
    }
  }, [firstMessage]);

  /** Init */
  const init = () => {
    firebase
      .database()
      .ref()
      .child(type)
      .child(activeId)
      .child('items')
      .orderByKey()
      .limitToLast(chunk)
      .once('value', function(snap) {
        if (snap.val()) {
          const dateNow = Date.now();
          mainChunks[dateNow] = {};
          mainChunks[dateNow]['items'] = snap.val();
          mainChunks[dateNow]['first'] = firstElement;
          setMainChunks(mainChunks);
        } else {
          setFirstMessage(true);
        }
      })
      .then(snapshot => {
        if (snapshot.val()) {
          if (Object.keys(snapshot.val())[0])
            getNextChunks(Object.keys(snapshot.val())[0]);
        }
      });
  };

  /** get next chunk of message */
  const getNextChunks = referenceOld => {
    let dataObject = {};
    firebase
      .database()
      .ref()
      .child(type)
      .child(activeId)
      .child('items')
      .orderByKey()
      .endAt(referenceOld)
      .limitToLast(chunk + 1)
      .once('value', function(snap) {
        if (snap.val()) {
          dataObject = snap.val();
          dataObject[referenceOld] = null;
          delete dataObject[referenceOld];
        }
      })
      .then(() => {
        setReferenceToOldestKey(Object.keys(dataObject)[0]);
        const dateNow = Date.now();
        oldMessageChunks[activeId] = {};
        oldMessageChunks[activeId]['items'] = dataObject;
        oldMessageChunks[activeId]['first'] = false;
        oldMessageChunks[activeId]['date'] = dateNow;
        setOldMessageChunks(prevState => {
          // Object.assign would also work
          return { ...prevState, ...oldMessageChunks };
        });
      });
  };

  /** handelScroll */
  const handleScroll = () => {
    if (scrollRef.current && scrollRef.current.scrollComponent.container) {
      const container = scrollRef.current.scrollComponent.container.firstChild;
      container.scrollTop = container.scrollHeight - container.clientHeight;
    }
  };

  /** handleName */
  const handleName = item => {
    const uid = firebase.auth().currentUser.uid;

    if (item.owner === uid) return '';

    if (friendData && friendData[item.owner])
      return friendData[item.owner].name;
  };

  /** Handle Callback */
  const handleCallback = useCallback(item => {
    const elementIndicator = document.getElementById(timeIndicator);

    //Handle timer
    if (elementIndicator) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(function() {
        elementIndicator.style.opacity = 0;
      }, 3000);
    }

    if (!oldMessageChunks[activeId]) return;
    const element = document.getElementById(
      'chunk-' + oldMessageChunks[activeId]['date']
    );

    //Behaviour when the new chunks have to appear from the top
    if (item.scrollTop <= 10) {
      scrollDown[activeId]['state'] = false;
      setPositionDown(false);
      //Load the chunck when scrolled near the top
      if (chunksFromTop) {
        element.classList.add('active');
        setCurrentDate(oldMessageChunks[activeId]['date']);
        if (referenceToOldestKey) getNextChunks(referenceToOldestKey);
      }

      if (!chunksFromTop) {
        scrollTop[activeId]['state'] = true;
        setMessageCount(0);
        setShowIndicator(false);
      }
    } else if (item.top >= 0.9) {
      if (elementIndicator) elementIndicator.style.opacity = 0;
      if (chunksFromTop) {
        setMessageCount(0);
        setPositionDown(true);
        scrollDown[activeId]['state'] = true;
        setShowIndicator(false);
      }
      //Load the chunck when scrolled near the bottom
      if (!chunksFromTop) {
        if (element) element.classList.add('active');
        scrollTop[activeId]['state'] = false;
        // setCurrentDate(oldMessageChunks[activeId]['date']);
        if (referenceToOldestKey) getNextChunks(referenceToOldestKey);
      }
    } else {
      if (elementIndicator) elementIndicator.style.opacity = 1;
      setPositionDown(false);
      setCurrentDate(null);
      scrollDown[activeId]['state'] = chunksFromTop ? true : false;
      scrollTop[activeId]['state'] = false;
      setShowIndicator(true);
    }
    if (!chunksFromTop) setScrollTop(scrollTop);
    setScrollDown(scrollDown);

    return item;
  });

  /** displayMessages */
  const displayMessages = displayResults => {
    let direction = [];
    if (chunksFromTop) {
      direction = Object.entries(displayResults);
    } else {
      direction = Object.entries(displayResults).reverse();
    }

    return direction.map((item, index) => {
      if (index === 0 && preOwnerRef.current)
        preOwnerRef.current.value = item[1].owner;
      const newNode = (
        <Node
          key={item[1].update ? item[1].update : item[1].timestamp}
          time={item[1].update ? item[1].update : item[1].timestamp}
          message={item[1].text}
          defaultImages={item[1].images}
          user={item[1].name}
          owner={item[1].owner}
          preOwner={preOwnerRef.current && preOwnerRef.current.value}
          id={item[0]}
          name={handleName(item[1])}
          activeDashboardId={activeDashboardId}
          activeId={activeId}
        />
      );
      if (preOwnerRef.current) preOwnerRef.current.value = item[1].owner;

      return newNode;
    });
  };

  /** Render all the chunks */
  const renderChunks = () => {
    let preRenderChunk = [];
    let mainRenderChunk = [];
    let itemRenderChunk = [];
    let itemInfoChunk = [];

    if (!firstMessage) {
      itemInfoChunk.push(
        <div key="itemInfoChunk">
          <ScrollIndicator
            chunksFromTop={chunksFromTop}
            badge={messageCount}
            spacing={rem(40)}
            position={{ bottom: `${rem(85)}`, right: `${rem(30)}` }}
            show={showIndicator}
            onclick={() => {
              if (chunksFromTop) {
                setPositionDown(true);
              }
              if (!chunksFromTop) {
                setPositionDown(false);
                scrollTop[activeId]['state'] = true;
                setScrollTop(scrollTop);
                setCurrentDate(false);
              }
            }}
          />
        </div>
      );
    }

    if (oldMessageChunks && Object.keys(oldMessageChunks).length > 0) {
      preRenderChunk.push(
        <OlderItems
          key="oldMessageChunks"
          chunksFromTop={chunksFromTop}
          data={oldMessageChunks[activeId]}
          activeId={activeId}
          Node={Node}
          activeDashboardId={activeDashboardId}
          extra={handleName}
          type={type}
          chunk={chunk}
          preOwner={preOwnerRef.current && preOwnerRef.current.value}
        />
      );
    }

    if (mainChunks && Object.keys(mainChunks).length > 0) {
      mainRenderChunk.push(
        <div key="oldMessageChunks">
          {Object.entries(mainChunks).map(item => {
            return (
              <div
                className={item[1]['first'] ? 'lazyload active' : 'lazyload'}
                id={'chunk-' + item[0]}
                key={item[0]}
              >
                {item[1]['items'] && displayMessages(item[1]['items'])}
              </div>
            );
          })}
        </div>
      );
    }

    if (messageChunks && Object.keys(messageChunks).length > 0) {
      let direction = [];
      if (chunksFromTop) {
        direction = Object.entries(messageChunks);
      } else {
        direction = Object.entries(messageChunks).reverse();
      }
      itemRenderChunk.push(
        <div key="itemRenderChunk">
          {direction.map(item => {
            return (
              <div
                className="lazyload active"
                id={'chunk-' + item[0]}
                owner={item[1]['owner']}
                key={item[0]}
              >
                {displayMessages(item[1]['items'])}
              </div>
            );
          })}
        </div>
      );
    }

    if (!chunksFromTop) {
      return [itemInfoChunk, itemRenderChunk, mainRenderChunk, preRenderChunk];
    }

    return [preRenderChunk, mainRenderChunk, itemRenderChunk, itemInfoChunk];
  };

  /** Return Chat messages */
  return !firstMessage && activeId ? (
    <React.Fragment>
      <Grid
        lazyload
        chunksFromTop={chunksFromTop}
        scrollPosition={currentDate}
        callback={handleCallback}
        owner={newChunk}
        scrollTop={
          scrollTop[activeId] && scrollTop[activeId]['state'] && !positionDown
            ? true
            : false
        }
        scrollBottom={scrollDown[activeId] && positionDown ? true : false}
        // ref={scrollRef}
        onScrollUpdate={scrollRef && scrollRef.current ? handleScroll() : null}
        columns="1fr"
        padding={[
          {
            top: 0,
            right: 0,
            bottom: 0,
            left: rem(10),
          },
        ]}
      >
        <StyledWrapper id={'wrapper-' + activeId} ref={preOwnerRef}>
          {renderChunks()}
        </StyledWrapper>
      </Grid>
    </React.Fragment>
  ) : (
    <StyledEmptyState
      widgetId={widgetId}
      animation="funny"
      width={130}
      height={140}
      text={t('empty.title', { type: type })}
      hasButton={false}
    />
  );
};

LazyLoad.propTypes = {
  /** Node type */
  Node: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  /** Type of the items */
  type: PropTypes.string,
  /** This sets the users current channel */
  activeId: PropTypes.string,
  /** The current user */
  friendData: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
  ]),
  /** Current dashboard Id */
  activeDashboardId: PropTypes.string,
  /** Direction the scrollbar */
  chunksFromTop: PropTypes.bool,
  /** Sound notification */
  hasSound: PropTypes.bool,
  /** Current widget Id */
  widgetId: PropTypes.string,
};

LazyLoad.defaultProps = {
  Node: null,
  type: null,
  activeId: null,
  friendData: null,
  activeDashboardId: null,
  chunksFromTop: true,
  hasSound: false,
  widgetId: null,
};

//export default connect(mapStateToProps)(Messages);
export default LazyLoad;
