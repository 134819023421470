/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  storeWidgetInfo,
  storeWidgetDrag,
  cardItemIsDragging,
} from '../../redux/actions';
import withMemo from '../../utils/withMemo';
import theme, { rem } from '../../theme';
import Image from '../Image';
import Group from '../Group';
import Text from '../Text';
import Animation from '../LottieControl';
import firebase, { userListner } from '../../firebase';

const StyledWrapper = styled.div`
  ${({ disable }) => `
    height: auto;
    border-radius: ${rem(10)};
    width: calc(100% / 4);
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem;
    overflow: hidden;
    margin-bottom: 1rem;
    cursor: ${disable ? 'default' : 'grab'};
    opacity: ${disable ? '0.3' : '1'};

    @media (max-width: 780px) {
      width: calc(100% / 3);
    }

    @media (max-width: 530px) {
      width: calc(100% / 2);
    }

    @media (max-width: 390px) {
      width: 100%;
    }
  `}
`;

const StyledInnerWrapper = styled.div`
  border-radius: ${rem(10)};
  overflow: hidden;
`;

const StyledGroup = styled(Group)`
  ${({ studentsplus }) => `
    align-items: flex-start;
    background: #4057a2;
  `};
`;

const StyledTextWrapper = styled(Group)`
  align-items: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  overflow: hidden;
`;

const StyledPrice = styled(Group)`
  width: ${rem(150)};
  margin-left: ${rem(10)};
`;

const StyledImageWrapper = styled.div`
  position: relative;
`;

const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

/**
 * Overview component
 * @param {string} url
 * @param {string} image
 * @param {string} title
 * @param {string} subTitle
 * @param {string} creator
 * @param {bool} active
 * @param {string} type
 * @param {bool} studentsplus
 * @param {bool} disable
 * @param {bool} singleton
 */
const WidgetPreview = props => {
  const {
    url,
    active,
    image,
    title,
    price,
    creator,
    type,
    studentsplus,
    hasOverview,
    hasHeader,
    hasPivot,
    resizeMin,
    resizeMax,
    disable,
    singleton,
  } = props;

  //States
  const [newId] = useState(
    '_' +
      Math.random()
        .toString(36)
        .substr(2, 9)
  );
  const [isTour, setIsTour] = useState();

  const [dataObject] = useState({
    url: url,
    image: image,
    typeId: newId,
    type: type,
    title: title,
    creator: creator,
    hasOverview: hasOverview,
    hasHeader: hasHeader,
    hasPivot: hasPivot,
    resizeMin: resizeMin,
    resizeMax: resizeMax,
    singleton: singleton,
  });

  //UserId
  const uuid = firebase.auth().currentUser.uid;

  useEffect(() => {
    userListner
      .child(uuid)
      .child('isFirstTimeUser')
      .on('value', snapshot => {
        setIsTour(snapshot.val());
      });
  }, []);

  /** Handle the tour */
  const handleTour = () => {
    document.body.classList.remove('tut');
    const element = document.getElementsByClassName('reactour__close')[0];
    element.click();
    document.body.classList.add('progresstut');
    setIsTour(true);
  };

  /** On mouse down */
  const handleDrag = () => {
    const { storeWidgetInfo, storeWidgetDrag, cardItemIsDragging } = props;

    if (document.body.classList.contains('tut')) handleTour();

    storeWidgetDrag(true);
    cardItemIsDragging(false);
    storeWidgetInfo(dataObject);
  };

  /** Handle drag start */
  const handleDragStart = e => {
    e.dataTransfer.setData('text/plain', '');
  };

  return (
    dataObject &&
    Object.keys(dataObject).length > 0 && (
      <StyledWrapper
        draggable={isTour || disable ? false : true}
        type={type}
        disable={disable}
      >
        <StyledInnerWrapper
          onDragLeave={() => handleDrag()}
          unselectable='on'
          className='droppable-element'
          onDragStart={e => handleDragStart(e)}
        >
          <StyledImageWrapper>
            <Image src={image} height={170} width='100%' draggable='false' />
          </StyledImageWrapper>
          <StyledGroup
            studentsplus={studentsplus}
            padding={[
              {
                top: rem(10),
                right: rem(10),
                bottom: rem(10),
                left: rem(10),
              },
            ]}
          >
            <StyledTextWrapper fullwidth>
              <Text
                text={title}
                textStyle='subtitle'
                textColor={theme.color.light[100]}
                ellipsis
              />
              <Text
                text={creator}
                textStyle='subtitle'
                textColor={theme.color.light[60]}
                ellipsis
              />
            </StyledTextWrapper>
            {/* <StyledPrice fullwidth>
              <Text
                align="right"
                text={active && price !== 'free' ? 'Payed' : price}
                textStyle="subtitle"
                textColor={theme.color.light[100]}
              />
            </StyledPrice> */}
          </StyledGroup>
        </StyledInnerWrapper>
      </StyledWrapper>
    )
  );
};

WidgetPreview.propTypes = {
  url: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.string,
  creator: PropTypes.string,
  active: PropTypes.bool,
  type: PropTypes.string,
  studentsplus: PropTypes.bool,
  hasOverview: PropTypes.bool,
  hasHeader: PropTypes.bool,
  disable: PropTypes.bool,
  singleton: PropTypes.bool,
};

WidgetPreview.defaultProps = {
  url: null,
  image: null,
  title: null,
  price: null,
  creator: null,
  active: false,
  type: null,
  studentsplus: false,
  hasOverview: true,
  hasHeader: true,
  disable: null,
  singleton: null,
};

/** @component */
export default connect(null, {
  storeWidgetInfo,
  storeWidgetDrag,
  cardItemIsDragging,
})(WidgetPreview);

//})(withMemo(WidgetPreview, ['disable']));
