/* eslint-disable react/display-name */
/* eslint-disable react/react-in-jsx-scope */
import { convertFromRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { BLOCK_TYPE, ENTITY_TYPE } from 'draftail';
import firebase from '../../../../firebase';

/** exporterConfig */
const exporterConfig = {
  blockToHTML: block => {
    if (block.type === BLOCK_TYPE.BLOCKQUOTE) {
      return <blockquote />;
    }

    // Discard atomic blocks, as they get converted based on their entity.
    if (block.type === BLOCK_TYPE.ATOMIC) {
      return {
        start: '',
        end: '',
      };
    }

    return null;
  },

  entityToHTML: (entity, originalText) => {
    if (entity.type === ENTITY_TYPE.LINK) {
      return <a href={entity.data.url}>{originalText}</a>;
    }

    if (entity.type === ENTITY_TYPE.IMAGE) {
      return <img src={entity.data.src} alt={entity.data.alt} />;
    }

    if (entity.type === ENTITY_TYPE.HORIZONTAL_RULE) {
      return <hr />;
    }

    return originalText;
  },
};

/** To HTML */
const toHTML = raw => {
  return raw ? convertToHTML(exporterConfig)(convertFromRaw(raw)) : '';
};

/** Get the email subject based on a list */
const getHTML = (type, typeId) => {
  let dataObject = {};
  firebase
    .database()
    .ref()
    .child(type)
    .child(typeId)
    .child('text')
    .once('value', snap => {
      if (snap.val()) {
        dataObject = toHTML(JSON.parse(snap.val()));
      }
    });

  return dataObject;
};

/** Default return function */
const basic = () => {};

export { getHTML };

export default basic;
