import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Group from '../Group';
import TextField from '../TextField';
import Grid from '../Grid';
import ListItem from '../ListItem';
import theme, { rem } from '../../theme';
import EmptyState from '../EmptyState';
import firebase, {
  userListner,
  dashboardListner,
  userDashboardListner,
} from '../../firebase';

const StyledWrapper = styled.div`
  ${({ elementDimensions, backgroundColor }) => `
    background: ${backgroundColor};
    display: flex;
    flex-direction: column;
    width: ${elementDimensions.width};
    height: 100%;
    padding: ${rem(20)};
  `}
`;

const StyledGroup = styled(Group)`
  ${({ elementDimensions }) => `
    display: flex;
    position: absolute;
    width: calc(${elementDimensions.width} - ${rem(40)});
    z-index: 1;
  `}
`;

const StyledResultGroup = styled(Group)`
  .resultItem {
    width: 100%;
  }
`;

const StyledGrid = styled(Grid)`
  margin-top: ${rem(50)};
`;

const StyledEmptyWrapper = styled.div`
  ${({ noPadding }) => `
    padding: ${noPadding ? '0' : rem(30)};
  `}
`;

/**
 * Return a styled Note component
 * @param {string} type
 * @param {string} id
 * @param {string} activeDashboardId
 * @param {func} onItemClick
 * @param {object} setActive
 * @param {string} backgroundColor
 * @param {object} elementDimensions
 * @param {object} rightIcon
 * @param {bool} noPadding
 * @param {bool} hasButtons
 */
const FilterSearch = props => {
  const {
    type,
    id,
    activeDashboardId,
    onItemClick,
    setActive,
    backgroundColor,
    elementDimensions,
    rightIcon,
    noPadding,
    hasButtons,
  } = props;

  //UserId
  const uuid = firebase.auth().currentUser.uid;

  //Translation
  const [t] = useTranslation(['emptystate', 'textfield']);

  //States
  const [setClickedPerson] = useState({});
  const [ready, setReady] = useState(false);
  const [objectData, setObjectData] = useState({});
  const [userObjectData, setUserObjectData] = useState({});
  const [friendsObject, setFriendsObject] = useState({});

  //Refs
  const inputRef = useRef();

  useEffect(() => {
    //DASHBOARD LISTNER ON MEMBERS
    dashboardListner
      .child(activeDashboardId)
      .child('members')
      .on('child_added', snap => {
        if (snap.key !== uuid) {
          if (type === 'all') {
            const types = {
              friends: true,
              members: true,
            };
            Object.keys(types).map(item => {
              getData(item);
            });
          } else {
            getData();
          }
        }
      });

    dashboardListner
      .child(activeDashboardId)
      .child('members')
      .on('child_removed', snap => {
        if (snap.key !== uuid) {
          delete objectData[snap.key];
          setObjectData(objectData);
        }
      });
  }, []);

  /** Data of the default object */
  const getData = loopType => {
    let listner = '';
    if (type === 'friends' || loopType === 'friends') {
      listner = userListner.child(uuid).child('friends');
    }

    if (
      type === 'members' ||
      type === 'admin' ||
      loopType === 'members' ||
      loopType === 'admin'
    ) {
      listner = dashboardListner.child(activeDashboardId).child('members');
    }

    listner.once('value', snap => {
      if (snap.val()) {
        if (loopType === 'friends') setFriendsObject(snap.val());
        snap.forEach(function(childSnapshot) {
          if (childSnapshot.val() && childSnapshot.key !== uuid) {
            userListner
              .child(childSnapshot.key)
              .once('value', snapShot => {
                objectData[childSnapshot.key] = {};
                objectData[childSnapshot.key] = {
                  id: childSnapshot.key,
                  name: snapShot.val().public.name,
                  color: snapShot.val().public.color,
                  status: snapShot.val().public.status,
                  avatar: snapShot.val().public.avatar
                    ? snapShot.val().public.avatar.cropped
                    : '',
                };
              })
              .then(() => {
                userDashboardListner
                  .child(childSnapshot.key)
                  .child(activeDashboardId)
                  .child('role')
                  .child('type')
                  .on('value', snapRole => {
                    if (snapRole.val()) {
                      setUserObjectData(snapRole.val());
                      objectData[childSnapshot.key]['role'] = {};
                      snapRole.forEach(function(subsnap) {
                        dashboardListner
                          .child(activeDashboardId)
                          .child('roles')
                          .child(subsnap.key)
                          .child('details')
                          .on('value', snapRoleDetails => {
                            if (snapRoleDetails.val()) {
                              if (
                                !objectData[childSnapshot.key]['role'][
                                  subsnap.key
                                ]
                              ) {
                                userDashboardListner
                                  .child(childSnapshot.key)
                                  .child(activeDashboardId)
                                  .child('role')
                                  .child('type')
                                  .child(subsnap.key)
                                  .once('value', snapCheck => {
                                    if (snapCheck.val()) {
                                      objectData[childSnapshot.key]['role'][
                                        subsnap.key
                                      ] = {};
                                      objectData[childSnapshot.key]['role'][
                                        subsnap.key
                                      ]['color'] = snapRoleDetails.val().color;
                                      setObjectData(prevState => {
                                        // Object.assign would also work
                                        return { ...prevState, ...objectData };
                                      });
                                    }
                                  });
                              } else {
                                objectData[childSnapshot.key]['role'][
                                  subsnap.key
                                ] = {};
                                objectData[childSnapshot.key]['role'][
                                  subsnap.key
                                ]['color'] = snapRoleDetails.val().color;
                                setObjectData(prevState => {
                                  // Object.assign would also work
                                  return { ...prevState, ...objectData };
                                });
                              }
                            } else {
                              delete objectData[childSnapshot.key]['role'][
                                subsnap.key
                              ];
                              setObjectData(prevState => {
                                // Object.assign would also work
                                return { ...prevState, ...objectData };
                              });
                            }
                          });
                      });
                    } else {
                      setUserObjectData({});
                      objectData[childSnapshot.key]['role'] = {};
                      setObjectData(prevState => {
                        // Object.assign would also work
                        return { ...prevState, ...objectData };
                      });
                      //setReady(true);
                    }
                  });
              })
              .then(() => {
                // dashboardListner
                //   .child(activeDashboardId)
                //   .child('roles')
                //   .child('type')
                //   .on('value', snapRole => {
                //     if(snapRole.val()) {
                //       objectData[childSnapshot.key]['role'] = snapRole.val();
                //     }
                //   });
              });
          }
        });
      }
    });
  };

  /** Handle on change event */
  const handleChange = () => {
    const text = inputRef.current.value;
    const result = Object.values(objectData).filter(singleMessage => {
      return singleMessage.name.toLowerCase().search(text) !== -1;
    });

    Object.values(objectData).forEach(function(value) {
      if (document.getElementById(value.id))
        document
          .getElementById('item-' + value.id + '-' + id)
          .classList.add('hide');
    });

    result.forEach(function(value) {
      if (document.getElementById(value.id))
        document
          .getElementById('item-' + value.id + '-' + id)
          .classList.remove('hide');
    });
  };

  /** Indicator handler */
  const indicatorHandler = id => {
    if (
      friendsObject.hasOwnProperty(id) &&
      (type === 'friends' || type === 'all')
    )
      return '/resources/friends.svg';
  };

  /** Handle active */
  const handleActive = id => {
    if (
      setActive &&
      Object.keys(setActive).length > 0 &&
      setActive.hasOwnProperty(id)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return Object.keys(objectData).length > 0 ? (
    <StyledWrapper
      elementDimensions={elementDimensions}
      backgroundColor={backgroundColor}
    >
      <StyledGroup elementDimensions={elementDimensions}>
        <TextField
          hasButtons={hasButtons ? hasButtons : false}
          name='message'
          ref={inputRef}
          inputId={`searchInput` + id}
          backgroundcolor={theme.color.light[100]}
          borders={false}
          placeholder={t('textfield:filtersearch.placeholder')}
          extraOnchange={() => handleChange()}
          value={inputRef.current && inputRef.current.value}
          height={45}
        />
      </StyledGroup>
      <StyledGrid columns='1fr'>
        <StyledResultGroup fullwidth>
          {Object.values(objectData).map(item => {
            return (
              <ListItem
                fullWidth
                isSearch
                active={handleActive(item.id)}
                evenRadius
                onItemClick={
                  onItemClick
                    ? () => onItemClick(item.id)
                    : () => {
                        setClickedPerson(item.id);
                      }
                }
                title={item.name}
                avatar={{
                  source: item.avatar,
                  title: item.name,
                  color: item.color,
                }}
                subTitle={type !== 'admin' && item.status}
                badges={JSON.stringify(item.role)}
                key={item.name + item.id}
                id={'item-' + item.id + '-' + id}
                extraIndicator={indicatorHandler(item.id)}
                activeTabBackgroundColor={theme.color.primary[100]}
                rightIcon={rightIcon}
              />
            );
          })}
        </StyledResultGroup>
      </StyledGrid>
    </StyledWrapper>
  ) : (
    <StyledEmptyWrapper noPadding={noPadding}>
      <EmptyState
        hasButton={false}
        text={t('filtersearch.title')}
        textColor='#fff'
      />
    </StyledEmptyWrapper>
  );
};

FilterSearch.propTypes = {
  /** Type to be filtered */
  type: PropTypes.string,
  /** Custom id for multiple fields */
  id: PropTypes.string,
  /** Current dashboard Id */
  activeDashboardId: PropTypes.string,
  /** Object of selected items */
  setActive: PropTypes.oneOfType([PropTypes.object]),
  /** Function that will run on item click */
  onItemClick: PropTypes.func,
  /** Background color */
  backgroundColor: PropTypes.string,
  /** Element dimensions */
  elementDimensions: PropTypes.oneOfType([PropTypes.object]),
  /** Right icon indicator */
  rightIcon: PropTypes.oneOfType([PropTypes.object]),
  /** Indicating padding around the object */
  noPadding: PropTypes.bool,
  /** Indicates the presents of extra buttons on the right side */
  hasButtons: PropTypes.bool,
};

FilterSearch.defaultProps = {
  type: null,
  id: null,
  activeDashboardId: null,
  setActive: null,
  onItemClick: null,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  elementDimensions: null,
  rightIcon: null,
  noPadding: false,
  hasButtons: false,
};

export default FilterSearch;
