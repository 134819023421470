import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Badge from '../Badge';
import Image from '../Image';
import theme, { rem } from '../../theme';
import withMemo from '../../utils/withMemo';

const StyledWrapper = styled.div`
  display: inline-flex;
  align-self: ${props => (props.alignCenter ? 'center' : '')};
  position: relative;

  &:last-child {
    margin-right: 0;
  }
`;

const styles = {
  small: `
    width: ${rem(32)};
    height: ${rem(32)};
    min-width: ${rem(32)};
  `,
  medium: `
    width: ${rem(48)};
    height: ${rem(48)};
    min-width: ${rem(48)};
  `,

  big: `
    width: ${rem(72)};
    height: ${rem(72)};
    min-width: ${rem(72)};
  `,
};

const BadgeWrapper = styled.div`
  z-index: 1;
  display: flex;
  z-index: 0;
  position: absolute;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
`;

const StyledAvatarWrapper = styled.div`
  ${props => styles[props.size]}
  background-color: ${props => (props.bgColor ? props.bgColor : '')};
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  overflow: hidden;
  border-radius: ${props => (props.shape === 'circle' ? '50%' : 'none')};

  img {
    height: 100%;
    width: auto;
  }
`;

/**
 * @param {string} alt
 * @param {string} id
 * @param {string} source
 * @param {string} title
 * @param {string} status
 * @param {bool} alignCenter
 * @param {node} children
 * @param {string} size
 * @param {string} shape
 * @param {string} color
 */
const Avatar = props => {
  const {
    size,
    alignCenter,
    alt,
    id,
    source,
    shape,
    status,
    title,
    color,
  } = props;

  /** Get initials */
  const getInitials = name => {
    let initials = 'GU';
    if (name) {
      initials = name.match(/\b\w/g) || [];
      initials = (
        (initials.shift() || '') + (initials.pop() || '')
      ).toUpperCase();
    }
    return initials;
  };

  return (
    <StyledWrapper {...props} alignCenter={alignCenter} id={id}>
      <StyledAvatarWrapper size={size} shape={shape} bgColor={color}>
        {!source || source === '' || typeof source === 'object' ? (
          getInitials(title)
        ) : (
          <Image alt={alt} src={source} draggable={false} />
        )}

        {status && (
          <BadgeWrapper>
            <Badge
              variant='status'
              status={status}
              size={size === 'small' ? 12 : 16}
            />
          </BadgeWrapper>
        )}
      </StyledAvatarWrapper>
    </StyledWrapper>
  );
};

Avatar.propTypes = {
  /** Id of the avatar */
  id: PropTypes.string,
  /** Image Title */
  title: PropTypes.string,
  /** User status */
  status: PropTypes.string,
  /** Children */
  children: PropTypes.node,
  /** Source Path */
  source: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** Center the avatar */
  alignCenter: PropTypes.bool,
  /** Avatar Size */
  size: PropTypes.oneOf(['big', 'medium', 'small']),
  /** Alt tag */
  alt: PropTypes.string,
  /** Shape of the avatar */
  shape: PropTypes.string,
  /** Color of the avatar when no image is set */
  color: PropTypes.string,
};

Avatar.defaultProps = {
  children: null,
  title: '',
  id: null,
  source: '/resources/astronaut.svg',
  alignCenter: true,
  status: null,
  size: 'medium',
  alt: 'Alt text',
  shape: 'circle',
  color: theme.color.tertiary[100],
};

/**
 * @component
 */
export default withMemo(Avatar, ['source', 'status', 'size', 'title']);
