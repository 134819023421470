/* eslint-disable no-unused-vars */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ToastContainer,
  toast,
  Zoom,
  Flip,
  Slide,
  Bounce,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled, { css } from 'styled-components';
import {
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  RedditIcon,
  TumblrIcon,
  LivejournalIcon,
  MailruIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  EmailIcon,
  WhatsappShareButton,
  EmailShareButton,
} from 'react-share';
import { notificationType, modalOpen } from '../../redux/actions';
import { getEmailTextList, getEmailSubjectList } from './functions/email';
import { getText } from './functions/whatsapp';
import Button from '../Button';
import Text from '../Text';
import Lottie from '../LottieControl';
import InfoCard from '../InfoCard';
import Icon from '../Icon';
import Group from '../Group';
import Image from '../Image';
import ButtonGroup from '../ButtonGroup';
import theme, { rem } from '../../theme';
import {
  removeImage,
  removeBackgroundImage,
  removeHeaderImage,
  removeItem,
  removeSubItem,
  getItemData,
} from '../../firebase';

/** pass currentUser and currentChannel from redux to global props */
const mapStateToProps = state => ({
  NotificationType: state.notificationtype.notificationType,
});

const small = css`
  &.small {
    width: auto;
    margin-left: 0;
  }
`;
const medium = css`
  &.medium {
    width: auto;
    left: 25%;
    right: 25%;
    top: 0;
    margin-left: 0;
  }
`;
const large = css`
  &.large {
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: 0;
  }
`;

const GlobalParent = styled(ToastContainer)`
  padding: 0 !important;
  ${props => props.size === 'small' && small};
  ${props => props.size === 'medium' && medium};
  ${props => props.size === 'large' && large};

  .Toastify__toast {
    margin: ${props => (props.hasSpacing ? rem(24) : 0)};
    padding: ${props => props.innerSpacing};
    overflow: visible;
  }

  .success {
    background: ${theme.color.success};
  }

  .normal {
    background: #fff;
  }

  .warning {
    background: ${theme.color.warning};
  }

  .error {
    background: ${theme.color.danger};
  }

  &.Toastify__toast-container--bottom-center,
  &.Toastify__toast-container--bottom-left,
  &.Toastify__toast-container--bottom-right {
    bottom: 0;
  }

  &.Toastify__toast-container--bottom-left {
    left: 0;
  }

  &.Toastify__toast-container--bottom-right {
    right: 0;
  }

  &.Toastify__toast-container--top-center,
  &.Toastify__toast-container--top-left,
  &.Toastify__toast-container--top-right {
    top: 0;
  }

  &.Toastify__toast-container--top-left {
    left: 0;
  }

  &.Toastify__toast-container--top-right {
    right: 0;
  }
`;

const StyledImage = styled(Image)`
  max-width: ${rem(100)};
  max-height: ${rem(70)};
  margin-left: ${rem(50)};
`;

const StyledContentWrapper = styled.div`
  transform: translateX(-5rem);
`;

const StyledWrapper = styled.div`
  display: flex;
  align-self: center;
  flex-grow: 1;
  justify-content: center;
`;

const StyledLottieText = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSharedWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    margin-right: ${rem(20)};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledButton = styled(Button)`
  height: 2.5rem;
  width: 2.5rem;
  transform: translateY(-2rem);
`;

const StyledText = styled(Text)`
  margin-left: ${rem(20)};
`;

/**
 * Return a styled notification
 * @param {node} children
 * @param {bool} hideProgressBar
 * @param {bool} hideButton
 * @param {bool} showOnStart
 * @param {bool} hasSpacing
 * @param {bool} closeOnClick
 * @param {string} position
 * @param {string} type
 * @param {string} animation
 * @param {string} size
 * @param {string} innerSpacing
 * @param {string} onClose
 * @param {number} duration
 * @param {oneOfType} closeButton
 * @param {bool} hide
 * @param {string} content
 */
const Notifications = props => {
  const {
    showOnStart,
    hideProgressBar,
    hideButton,
    position,
    type,
    innerSpacing,
    autoClose,
    duration,
    hasSpacing,
    hide,
    closeButton,
    closeOnClick,
    onClose,
    content,
  } = props;
  let toastId = null;
  let children = null;
  let size = null;

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', handleKeydown);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  /** handleKeydown */
  const handleKeydown = event => {
    if (event.keyCode === 27) {
      dismissAll();
    }
  };

  /** Get the corresponding animation */
  const getAnimation = animation => {
    /** First letter have to be uppercase */
    let styleName = animation.charAt(0).toUpperCase() + animation.slice(1);
    if (styleName === 'Flip') {
      styleName = Flip;
    } else if (styleName === 'Zoom') {
      styleName = Zoom;
    } else if (styleName === 'Slide') {
      styleName = Slide;
    } else if (styleName === 'Bounce') {
      styleName = Bounce;
    }

    return styleName;
  };

  /** Update */
  const update = props => {
    const { children, toastId, type } = props;
    toast.update((toastId, children), {
      render: 'New content',
      type,
    });
  };

  /** standard */
  const standard = () => {
    const { children, animation, duration, autoClose, onClose } = props;
    let setAutoclose = duration;

    if (!autoClose) {
      setAutoclose = false;
    }
    toast((toastId, children), {
      transition: getAnimation(animation),
      autoClose: { setAutoclose },
      onClose: [onClose],
    });
  };

  /** success */
  const success = (
    children,
    animation,
    size,
    position,
    duration,
    closeOnClick
  ) => {
    toast.success((toastId, children), {
      transition: getAnimation(animation),
      className: 'normal',
      preventDuplicate: true,
      size: size ? size : 'medium',
      position: position ? position : 'bottom-right',
      autoClose: duration ? duration : 5000,
      closeOnClick: closeOnClick ? closeOnClick : false,
      onClose: () => {
        props.notificationType(null);
        dismissAll();
      },
      closeButton: (
        <StyledButton
          id='closeNotification'
          variant='icon'
          bgColor='#d4d4d4'
          onClick={dismissAll}
        >
          <Icon icon='close' />
        </StyledButton>
      ),
    });
  };

  /** error */
  const error = () => {
    const { children, animation } = props;
    toast.error((toastId, children), {
      transition: getAnimation(animation),
      className: 'error',
    });
  };

  /** warning */
  const warning = () => {
    const { children, animation } = props;
    toast.warn((toastId, children), {
      transition: getAnimation(animation),
      className: 'warn',
    });
  };

  /** info */
  const info = () => {
    const { children, animation } = props;
    toast.info((toastId, children), {
      transition: getAnimation(animation),
      className: 'info',
    });
  };

  /** dismiss */
  const dismiss = () => toast.dismiss(toastId);

  /** dismissAll */
  const dismissAll = () => {
    toast.dismiss();
    const deletion = document.getElementsByClassName('deletion');
    if (deletion && deletion.length > 0) {
      for (var element of deletion) {
        element.classList.remove('deletion');
      }
    }
    //document.getElementsByClassName('deletion')[0].classList.remove('deletion');
  };

  /** Create card share */
  const createShareBetweenLists = data => {
    return (
      <StyledSharedWrapper>
        <EmailShareButton url=''>
          <Button bgColor='#417880' variant='icon' onClick={() => dismissAll()}>
            <Icon icon='note' padding={rem(18)} size={32} color='#fff' />
          </Button>
        </EmailShareButton>
        <EmailShareButton url=''>
          <Button bgColor='#417880' variant='icon' onClick={() => dismissAll()}>
            <Icon icon='chat' padding={rem(18)} size={32} color='#fff' />
          </Button>
        </EmailShareButton>
      </StyledSharedWrapper>
    );
  };

  /** Open window */
  const openWindow = (data, location) => {
    dismissAll();
    return window.open(location + getText(data.table, data.id));
  };

  /** Create the share buttons */
  const createShareButtons = data => {
    return (
      <StyledSharedWrapper>
        {data.showMail ? (
          <EmailShareButton
            subject={getEmailSubjectList(data.table, data.id)}
            body={getEmailTextList(data.table, data.id)}
            url=''
          >
            <Button
              bgColor='#417880'
              variant='icon'
              onClick={() => dismissAll()}
            >
              <Icon icon='email' padding={rem(18)} size={32} color='#fff' />
            </Button>
          </EmailShareButton>
        ) : null}
        {data.showWhatsApp ? (
          <Button
            variant='icon'
            onClick={() =>
              openWindow(data, 'https://web.whatsapp.com/send?text=')
            }
          >
            <WhatsappIcon round />
          </Button>
        ) : null}
        {data.showLine ? (
          <Button
            variant='icon'
            onClick={() => openWindow(data, 'https://line.me/msg/text/?')}
          >
            <LineIcon round />
          </Button>
        ) : null}
      </StyledSharedWrapper>
    );
  };

  /** Get the element of the image by alt tag */
  const removeHandler = data => {
    const name = data.name;
    const path = data.path;

    switch (name) {
      default:
      case 'removeImage': {
        //Remove the selected image from the DB
        removeImage(data);
        props.modalOpen(data);
        break;
      }

      case 'share': {
        //shareItems(data);
        break;
      }

      case 'removeBackgroundImage':
        removeBackgroundImage(data);
        break;

      case 'removeHeaderImage':
        removeHeaderImage(data);
        break;

      case 'removeItem':
        removeItem(data);
        break;
    }

    //Hide the notification
    dismissAll();
  };

  /** Set the animation based on the name */
  const setAnimation = data => {
    switch (data.name) {
      default:
      case 'removeBackgroundImage':
        return (
          <Lottie animation='delete' animationHeight={80} animationWidth={80} />
        );
      case 'removeHeaderImage':
        return (
          <Lottie animation='delete' animationHeight={80} animationWidth={80} />
        );
      case 'removeItem':
        return (
          <Lottie animation='delete' animationHeight={80} animationWidth={80} />
        );
      case 'removeImage':
        return (
          <Lottie animation='delete' animationHeight={80} animationWidth={80} />
        );
      case 'share':
        return (
          <StyledLottieText>
            <Lottie
              animation='share'
              animationHeight={80}
              animationWidth={80}
            />
            <Text textStyle='h6' text='Share it on social media' />
          </StyledLottieText>
        );
      case 'shareBetweenCards': {
        const listData = getItemData(data.table, data.listId, data.itemId);
        return (
          <StyledLottieText>
            <Lottie
              animation='robot'
              animationHeight={80}
              animationWidth={80}
            />
            <StyledText
              textStyle='h6'
              text={'Share "' + listData.text + '" with other cards'}
            />
          </StyledLottieText>
        );
      }
    }
  };

  /** Set the animation based on the name */
  const setContext = (data, name) => {
    switch (name) {
      default:
      case 'shareBetweenCards':
        return createShareBetweenLists(data);
      case 'removeBackgroundImage':
        return (
          <ButtonGroup horAlign='center'>
            <Button variant='text' onClick={dismissAll}>
              <Text text='Cancel' />
            </Button>
            <Button variant='terminate' onClick={() => removeHandler(data)}>
              <Text text='Delete' textColor='#fff' />
            </Button>
          </ButtonGroup>
        );
      case 'removeHeaderImage':
        return (
          <ButtonGroup horAlign='center'>
            <Button variant='text' onClick={dismissAll}>
              <Text text='Cancel' />
            </Button>
            <Button variant='terminate' onClick={() => removeHandler(data)}>
              <Text text='Delete' textColor='#fff' />
            </Button>
          </ButtonGroup>
        );
      case 'removeItem':
        return (
          <ButtonGroup horAlign='center'>
            <Button variant='text' onClick={dismissAll}>
              <Text text='Cancel' />
            </Button>
            <Button variant='terminate' onClick={() => removeHandler(data)}>
              <Text text='Delete' textColor='#fff' />
            </Button>
          </ButtonGroup>
        );
      case 'removeImage':
        return (
          <ButtonGroup horAlign='center'>
            <Button variant='text' onClick={dismissAll}>
              <Text text='Cancel' />
            </Button>
            <Button variant='terminate' onClick={() => removeHandler(data)}>
              <Text text='Delete' textColor='#fff' />
            </Button>
          </ButtonGroup>
        );
      case 'share':
        return createShareButtons(data);
    }
  };

  if (content === 'list' && props.NotificationType) {
    children = <InfoCard title='Your list is successfully created! Woehoe!!' />;
    success(children, 'bounce', 'small', null, 5000, true);
  }

  if (content && JSON.parse(content) && props.NotificationType) {
    size = 'large';
    const data = JSON.parse(content);
    children = (
      <Group>
        {data.name && setAnimation(data)}
        <InfoCard
          title={
            data.text
              ? data.text
              : 'Are you sure you want to delete this image?'
          }
          justify='row'
          onKeyDown={handleKeydown}
        />
        <StyledWrapper>
          <StyledContentWrapper>
            {data.name && setContext(data, data.name)}
          </StyledContentWrapper>
        </StyledWrapper>
      </Group>
    );
    success(children, 'bounce', 'large', 'bottom-center', 500000, false);
  }

  return (
    <div className={'Toastify-' + size}>
      <GlobalParent
        size={size}
        type={type}
        position={position}
        duration={duration}
        hasSpacing={hasSpacing}
        hideProgressBar={hideProgressBar}
        className={size}
        autoClose={!autoClose ? autoClose : duration}
        closeButton={closeButton}
        innerSpacing={innerSpacing}
        closeOnClick={closeOnClick}
        hide={hide}
        onClose={onClose}
        toastId={toastId}
        content={content}
      />
    </div>
  );
};

Notifications.propTypes = {
  /** child components */
  children: PropTypes.node,
  /** Position where the notification will show */
  position: PropTypes.oneOf([
    'top-left',
    'top-right',
    'top-center',
    'bottom-left',
    'bottom-right',
    'bottom-center',
  ]),
  /** Hide or show a progressbar */
  hideProgressBar: PropTypes.bool,
  /** Hide button to trigger notification */
  hideButton: PropTypes.bool,
  /** Type of the notification */
  type: PropTypes.oneOf([
    'info',
    'success',
    'warning',
    'error',
    'standard',
    'update',
    'dismiss',
    'dismissAll',
  ]),
  /** Transition style */
  animation: PropTypes.oneOf(['slide', 'zoom', 'bounce', 'flip']),
  /** Duration of the animation */
  duration: PropTypes.number,
  /** Size of the notification bar */
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  /** Spacing inside of the notification bar */
  innerSpacing: PropTypes.string,
  /** Property to set auto close */
  autoClose: PropTypes.bool,
  /** Show on page ready */
  showOnStart: PropTypes.bool,
  /** Defines if the notification bar has spacing around it */
  hasSpacing: PropTypes.bool,
  /** Hide or show close button */
  closeButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  /** Close notification onclick */
  closeOnClick: PropTypes.bool,
  /** Hide notification bar on standard */
  hide: PropTypes.bool,
  /** Call function after closing the notification bar */
  onClose: PropTypes.func,
  /** Call function on opening the notification bar */
  onOpen: PropTypes.func,
  /** ID of the notification bar */
  toastId: PropTypes.string,
  /** Type of content the notificaton should render */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

Notifications.defaultProps = {
  children: null,
  position: 'top-center',
  hideProgressBar: true,
  hideButton: false,
  type: 'standard',
  animation: 'slide',
  duration: 5000,
  size: 'small',
  innerSpacing: rem(15),
  autoClose: true,
  showOnStart: false,
  hasSpacing: false,
  closeOnClick: true,
  hide: false,
  onClose: null,
  onOpen: null,
  toastId: null,
  content: null,
};

/** @component */
export default connect(mapStateToProps, { notificationType, modalOpen })(
  Notifications
);
