/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from '../Tooltip';
import Image from '../Image';
import theme, { rem } from '../../theme';
import firebase, { userDashboardListner } from '../../firebase';

const StyledImage = styled(Image)`
  ${({ outside }) => `
    position: absolute;
    right: ${outside ? rem(-13) : rem(-30)};
    top:${outside ? rem(-12) : rem(-25)};
    z-index: 10;
  `}
`;

const StyledWrapper = styled.div`
  cursor: help;
  position: absolute;
  right: 0;
  width: auto;
  text-align: center;

  a {
    color: ${theme.color.primary[100]};
    font-weight: bold;

    &:hover,
    &:focus {
      color: ${theme.color.primary[100]};
      text-decoration: underline;
    }
  }
`;

const StyledImageWrapper = styled.figure``;

/** Help component
 * @param {string} currentId
 * @param {string} activeDashboardId
 * @param {string} url
 * @param {string} title
 * @param {string} text
 * @param {bool} outside
 * @param {html} html
 */
const Help = props => {
  const {
    currentId,
    activeDashboardId,
    url,
    title,
    text,
    outside,
    html,
  } = props;

  //UserId
  const uuid = firebase.auth().currentUser.uid;

  //States
  const [showHelp, setShowHelp] = useState();
  const [currentImage, setCurrentImage] = useState(url);

  useEffect(() => {
    if (currentId) setListners();
  }, []);

  /** Set listners */
  const setListners = () => {
    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('settings')
      .child('help')
      .on('value', snap => {
        if (snap.exists()) {
          setShowHelp(snap.val());
        }
      });
  };

  /** Handle mouse enter */
  const handleMouseEnter = () => {
    setCurrentImage(url);
    document.getElementById(currentId).style.zIndex = 999999;
  };

  /** Handle mouse leave */
  const handleMouseLeave = () => {
    setCurrentImage('/resources/logo.svg');
    document.getElementById(currentId).style.zIndex = '';
  };

  return showHelp ? (
    <StyledWrapper
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
    >
      <StyledImageWrapper
        data-for={'help-' + currentId}
        data-delay-show='500'
        data-tip=''
      >
        <StyledImage
          outside={outside}
          src='/resources/question.svg'
          width={30}
          className='editable showHelp'
        />
      </StyledImageWrapper>
      <Tooltip
        id={'help-' + currentId}
        top
        arrowColor='transparent'
        place='left'
        clickable
        html={html}
        content={{
          width: rem(500),
          text: {
            text: text,
            textStyle: 'subtitle',
            textColor: '#fff',
            align: 'center',
          },
          title: {
            text: title,
            textStyle: 'h6',
            textColor: '#fff',
            align: 'center',
          },
          image: {
            id: 'helpiamge-' + currentId,
            src: currentImage,
          },
        }}
      />
    </StyledWrapper>
  ) : (
    <React.Fragment />
  );
};

Help.propTypes = {
  /** Active dashboard */
  activeDashboardId: PropTypes.string,
  currentId: PropTypes.string,
  /** Image url */
  url: PropTypes.string,
  /** Title of the help screen */
  title: PropTypes.string,
  /** Text of the help screen */
  text: PropTypes.string,
  /** Indicates if the help is outside a scroll element */
  outside: PropTypes.bool,
  /** HTML instead of text */
  html: PropTypes.any,
};

Help.defaultProps = {
  activeDashboardId: null,
  currentId: null,
  title: null,
  text: null,
  url: null,
  title: null,
  text: null,
  outside: null,
  html: null,
};

export default Help;
