import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/min/locales';
import { connect } from 'react-redux';
import { ContextMenuTrigger } from 'react-contextmenu';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { ReactTinyLink } from 'react-tiny-link';
import { modalType, modalOpen, cardItemIsDragging } from '../../redux/actions';
import ContextMenuItems from '../ContextMenuItems';
import ChatMessage from '../ChatMessage';
import Image from '../Image';
import Group from '../Group';
import theme, { rem } from '../../theme';
import Text from '../Text';
import Button from '../Button';
import firebase, {
  chatListner,
  userDashboardListner,
  userListner,
} from '../../firebase';
import withMemo from '../../utils/withMemo';
import Animation from '../LottieControl';

/** Get current selected language */
const getCurrentLng = () =>
  i18next.language || window.localStorage.i18nextLng || '';
moment.locale(getCurrentLng());

/** returning isOwnMessage  */
const isOwnMessage = (message, user, owner) => {
  return owner === firebase.auth().currentUser.uid ? true : false;
};

/** returning timeFromNow  */
const timeFromNow = timestamp => moment(timestamp).fromNow();

const StyledText = styled(Text)`
  position: absolute;
  font-style: italic;
  left: ${rem(10)};
  top: ${rem(3)};
  z-index: 1;
  width: auto;
`;

const StyledButton = styled(Button)`
  position: absolute;
  min-width: ${rem(25)};
  min-height: ${rem(25)};
  right: ${rem(5)};
  top: ${rem(3)};
  z-index: 1;
  opacity: 0;
  transition: 0.2s;

  i {
    margin: 0 !important;
  }
`;

const StyledGroup = styled(Group)`
  min-height: auto;
  min-width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledImage = styled(Image)`
  max-width: ${rem(300)};
  max-height: ${rem(250)} !important;
  width: 100% !important;
`;

/**
 * @param {object} message
 * @param {object} user
 * @param {string} owner
 * @param {string} preOwner
 * @param {bool} extraSpacing
 * @param {date} time
 * @param {string} id
 * @param {number} userCount
 * @param {string} name
 * @param {object} images
 * @param {string} activeDashboardId
 */
const Message = props => {
  const {
    id,
    time,
    message,
    user,
    owner,
    preOwner,
    extraSpacing,
    userCount,
    name,
    defaultImages,
    activeDashboardId,
    activeId,
  } = props;

  const [images, setImages] = useState(defaultImages);
  const [isLink, setIsLink] = useState(false);
  const [chatColor, setChatColor] = useState();
  const [isPayedUser, setIsPayedUser] = useState();
  const [text, setText] = useState(message);
  const [removed, setRemoved] = useState(false);
  const [edited, setEdited] = useState(false);

  //User Id
  const uuid = firebase.auth().currentUser.uid;

  //Translation
  const [t] = useTranslation(['text', 'textfield', 'button']);

  useEffect(() => {
    if (activeId) settingsListner();
    setIsLink(isUrl(message));

    i18next.on('languageChanged', () => {
      moment.locale(getCurrentLng());
    });
  }, []);

  /** Handle context */
  const handleContext = type => {
    props.modalOpen(true);
    props.modalType([type, activeId, id]);
    props.cardItemIsDragging(false);
  };

  /** settingsListner */
  const settingsListner = () => {
    //Listners
    const listner = chatListner
      .child(activeId)
      .child('items')
      .child(id)
      .child('images');

    listner.on('value', snap => {
      let imageObject = {};
      if (snap.val() && snap.val() instanceof Object) {
        imageObject = snap.val();
      } else if (snap.val() === 'loading') {
        imageObject['loading'] = true;
      } else {
        imageObject['empty'] = snap.val();
      }
      setImages(imageObject);
    });

    chatListner
      .child(activeId)
      .child('items')
      .on('child_removed', snap => {
        if (snap.key === id) {
          setRemoved(true);
        }
      });

    chatListner
      .child(activeId)
      .child('items')
      .child(id)
      .child('edited')
      .on('value', snap => {
        if (snap.val()) {
          setEdited(snap.val());
        }
      });

    chatListner
      .child(activeId)
      .child('items')
      .child(id)
      .child('text')
      .on('value', snap => {
        setText(snap.val());
      });

    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('colors')
      .child('chatColor')
      .on('value', snapDashboard => {
        setChatColor(snapDashboard.val());
      });

    userListner
      .child(uuid)
      .child('isPayedUser')
      .on('value', snap => {
        setIsPayedUser(snap.val());
      });
  };

  /** Check if text is URL */
  const isUrl = string => {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(string);
  };

  return activeId && !removed ? (
    <ChatMessage
      id={id}
      chatId={activeId}
      timestampClean={time}
      timestamp={timeFromNow(time)}
      messageOut={isOwnMessage(text, user, owner)}
      name={userCount > 2 ? name : ''}
      owner={owner}
      preOwner={preOwner}
      extraSpacing={extraSpacing}
      activeDashboardId={activeDashboardId}
    >
      <React.Fragment>
        <React.Fragment>
          {edited && (
            <StyledText
              text={t('text:message.edited')}
              textColor={theme.color.light[60]}
              textStyle='description'
            />
          )}
          {owner === uuid && (
            <React.Fragment>
              <ContextMenuTrigger
                id={'chatMessage-' + id}
                holdToDisplay={1}
                key={id}
              >
                <StyledButton
                  className='messageMenu'
                  onClick={() => {}}
                  variant='icon'
                  icon={{ icon: 'expand_more', color: '#fff', size: 20 }}
                />
              </ContextMenuTrigger>
              <ContextMenuItems
                bgColor={isPayedUser ? chatColor : '#7e73ac'}
                id={'chatMessage-' + id}
                data={[
                  {
                    id: 'editMessage-' + id,
                    icon: 'edit',
                    text: t('button:edit.text'),
                    click: () => {
                      handleContext('editChatMessage');
                    },
                  },
                  {
                    id: 'remove Message-' + id,
                    icon: 'remove_circle_outline',
                    text: t('button:remove.text'),
                    backgroundColor: theme.color.danger,
                    click: () => {
                      handleContext('removeChatMessage');
                    },
                  },
                ]}
              />
            </React.Fragment>
          )}
        </React.Fragment>

        {images &&
          Object.keys(images).length > 0 &&
          (Object.keys(images)[0] !== 'empty' ? (
            Object.keys(images)[0] === 'loading' ? (
              <StyledGroup fullwidth>
                <Animation
                  animation='imageLoader'
                  animationWidth='14rem'
                  animationHeight='14rem'
                />
              </StyledGroup>
            ) : (
              Object.entries(images).map(image => {
                return (
                  (image[0] === 'cropped' || image[1].url) && (
                    <StyledGroup
                      key={image[0]}
                      fullwidth
                      onClick={() => {
                        props.modalOpen(true);
                        props.modalType([
                          'imagePreview',
                          image[0] === 'cropped' ? image[1] : image[1].url,
                        ]);
                      }}
                    >
                      <StyledImage
                        src={image[0] === 'cropped' ? image[1] : image[1].url}
                        className='message__image'
                      />
                    </StyledGroup>
                  )
                );
              })
            )
          ) : (
            <React.Fragment />
          ))}

        {text &&
          text !== '' &&
          (isLink ? (
            <ReactTinyLink
              cardSize='small'
              showGraphic
              maxLine={2}
              minLine={1}
              url={text}
            />
          ) : (
            <Text
              tag={isUrl(text) ? 'a' : 'p'}
              text={text}
              textColor={isOwnMessage(text, user, owner) ? '#fff' : 'inherit'}
            />
          ))}
      </React.Fragment>
    </ChatMessage>
  ) : (
    <React.Fragment />
  );
};

Message.propTypes = {
  /** Timestamp of the message */
  time: PropTypes.number,
  /** Property that sets the message of the chat */
  message: PropTypes.string,
  /** User information property */
  user: PropTypes.string,
  /** All the data of the message */
  owner: PropTypes.string,
  /** Owner of the message before this one */
  preOwner: PropTypes.string,
  /** The id  */
  id: PropTypes.string,
  /** Total number of users in the chat */
  userCount: PropTypes.number,
  /** The user Name */
  name: PropTypes.string,
  /** Images attached to the message */
  defaultImages: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool,
  ]),
  /** Current active dashboard ID */
  activeDashboardId: PropTypes.string,
  /** Current active node id */
  activeId: PropTypes.string,
};

Message.defaultProps = {
  time: null,
  message: null,
  user: null,
  owner: null,
  preOwner: null,
  id: null,
  userCount: null,
  name: null,
  defaultImages: null,
  activeDashboardId: null,
  activeId: null,
};

/** @component */
export default connect(null, {
  cardItemIsDragging,
  modalType,
  modalOpen,
})(withMemo(Message, ['activeId', 'message', 'name', 'defaultImages']));
