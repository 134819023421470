import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme, { rem } from '../../theme';
import Animation from '../LottieControl';
import Icon from '../Icon';
import Text from '../Text';
import Badge from '../Badge';
import Avatar from '../Avatar';
import Group from '../Group';
import Button from '../Button';
import withMemo from '../../utils/withMemo';

const StyledInfoCard = styled.div`
  ${({ ellipsis }) => `
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-wrap: nowrap;
    flex-shrink: 1;
    justify-content: flex-start;
    width: 100%;

    div {
      > div {
        overflow: hidden;
        text-overflow: ${ellipsis ? 'ellipsis' : 'unset'};
        word-break: ${ellipsis ? 'unset' : 'break-word'};
      }
    }

    * {
      margin: 0;
    }

    .infoCardContent {
      width: calc(100% - ${rem(24)});
      align-self: center;
    }
  `}
`;

const StyledContentWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  flex-grow: 1;
  flex-direction: ${props => (props.justify === 'row' ? 'row' : 'column')};
  overflow: hidden;
  height: ${props => (props.sameHeight ? '100%' : null)};
`;

const IndicationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${theme.textStyle.body};

  div {
    text-align: right;
    margin: 0 !important;
  }

  i {
    align-self: flex-end !important;
    min-height: 1rem;
    min-width: 1rem;
  }
`;

const StyledOuter = styled.div`
  display: flex;
  align-self: flex-start;
  flex-grow: 0;
  margin-top: 0;
  margin-right: ${rem(10)};
  margin-bottom: 0;
  margin-left: 0;

  div {
    margin: 0;
  }

  img {
    align-self: center;
  }
`;

const StyledIcon = styled(StyledContentWrapper)`
  align-self: ${props => (props.alignTop ? 'flex-start' : 'center')};
`;

const StyledIndicator = styled.div`
  display: flex;
`;

const StyledBadgeIndicator = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -${rem(8)};
`;

const StyledRoleBadge = styled(Badge)`
  margin-bottom: ${rem(8)};
`;

const StyledDate = styled(Text)`
  align-self: flex-start;
`;

const StyledBadge = styled(Badge)`
  margin-right: ${rem(5)};

  &:last-child {
    margin-right: 0;
  }
`;

/**
 * Return a styled component
 * @param {string} spacing
 * @param {bool} alignStart
 * @param {bool} sameHeight
 * @param {bool} newCreated
 * @param {bool} firstAnimation
 * @param {func} onClick
 * @param {string} justify
 * @param {bool} ellipsis
 * @param {bool} unRead
 * @param {object} hasButtons
 * @param {object} badge
 * @param {object} badges
 */
const InfoCard = props => {
  const indicator = [];
  const {
    data,
    date,
    newCreated,
    firstAnimation,
    rightIcon,
    spacing,
    ellipsis,
    alignTop,
    animation,
    sameHeight,
    onClick,
    justify,
    title,
    subTitle,
    avatar,
    unRead,
    textColor,
    textStyle,
    subTextStyle,
    hasButtons,
    badge,
    badges,
  } = props;
  /** Set date */
  const timeFromNow = () => {
    const now = Date.now();

    return moment(date).calendar(now, {
      sameDay: 'HH:mm',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      lastDay: '[Yesterday]',
      lastWeek: 'dddd',
      sameElse: 'DD/MM/YYYY',
    });
  };

  /** Onclick handler */
  const clickHandler = () => {
    onClick();
  };

  /** Handle all the buttons */
  const handleButtons = () => {
    return Object.entries(hasButtons).map(button => {
      return <Button {...button[1].button} icon={button[1].icon} />;
    });
  };

  return (
    <StyledInfoCard
      ellipsis={ellipsis ? true : false}
      newCreated={newCreated}
      firstAnimation={firstAnimation}
      onClick={clickHandler}
      className='infoCard'
      data={data}
      {...props}
    >
      <StyledOuter spacing={spacing}>
        {avatar && <Avatar {...avatar} />}
      </StyledOuter>
      <StyledContentWrapper
        justify={justify}
        sameHeight={sameHeight}
        className='infoCardContent'
      >
        {title && (
          <Text text={title} textColor={textColor} textStyle={textStyle} />
        )}
        {subTitle && (
          <Text
            text={subTitle ? subTitle : ''}
            ellipsis={ellipsis ? true : false}
            textColor={textColor}
            textStyle={subTextStyle ? subTextStyle : 'description'}
          />
        )}
        {/* {badges && Object.keys(JSON.parse(badges)).length > 0 && (
          <StyledBadgeIndicator>
            {Object.entries(JSON.parse(badges)).map(item => {
             return <StyledRoleBadge text={item[0]} color={item[1].color} variant="text" />
            })}
          </StyledBadgeIndicator>
        )} */}
      </StyledContentWrapper>
      <IndicationWrapper className='infoCardIndicator'>
        {date && timeFromNow && (
          <StyledDate
            text={timeFromNow()}
            textStyle='description'
            textColor={textColor}
          />
        )}
        {rightIcon && (
          <StyledIndicator>
            <Icon {...rightIcon} />
          </StyledIndicator>
        )}

        {hasButtons && Object.keys(hasButtons).length > 0 && (
          <StyledIndicator>
            <Group>{handleButtons()}</Group>
          </StyledIndicator>
        )}

        {animation && (
          <StyledIndicator>
            <Animation
              animation={animation}
              animationWidth={30}
              animationHeight={30}
            />
          </StyledIndicator>
        )}

        {!animation && unRead > 0 && (
          <StyledIndicator>
            <Badge text={unRead} variant='counter' />
          </StyledIndicator>
        )}

        {badge && badge.text && (
          <StyledIndicator>
            <Badge text={badge.text} color={badge.color} variant='text' />
          </StyledIndicator>
        )}

        {indicator.length ? (
          <StyledIcon alignTop={alignTop}>{indicator}</StyledIcon>
        ) : null}
      </IndicationWrapper>
    </StyledInfoCard>
  );
};

InfoCard.propTypes = {
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rightIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  spacing: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  newCreated: PropTypes.bool,
  firstAnimation: PropTypes.bool,
  /** Force to align all child components to the top */
  alignTop: PropTypes.bool,
  animation: PropTypes.string,
  sameHeight: PropTypes.bool,
  ellipsis: PropTypes.bool,
  onClick: PropTypes.func,
  justify: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** The List data from Firebase */
  data: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  /** Number of unreaded messages */
  unRead: PropTypes.number,
  /** Object of buttons */
  hasButtons: PropTypes.oneOfType([PropTypes.object]),
  /** Badge indication */
  badge: PropTypes.oneOfType([PropTypes.object]),
  /** Indicates multiple badges */
  badges: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object,
  ]),
};

InfoCard.defaultProps = {
  date: null,
  unRead: 0,
  rightIcon: null,
  ellipsis: true,
  spacing: rem(12),
  newCreated: false,
  firstAnimation: false,
  alignTop: false,
  animation: null,
  sameHeight: false,
  onClick: () => {},
  justify: null,
  data: null,
  title: '',
  subTitle: '',
  avatar: '/resources/astronaut.svg',
  hasButtons: null,
  badge: null,
  badges: null,
};
/**
 * @component
 */
export default withMemo(InfoCard, [
  'title',
  'subTitle',
  'avatar',
  'unRead',
  'newCreated',
  'animation',
  'badge',
  'badges',
]);
//export default InfoCard;
