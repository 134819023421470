import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { rem } from '../../theme';
import ColorPicker from '../ColorPicker';
import Grid from '../Grid';
import Text from '../Text';
import withMemo from '../../utils/withMemo';
import firebase, { userDashboardListner } from '../../firebase';
import Animation from '../LottieControl';

const StyledMainWrapper = styled.div`
  ${({ elementDimensions }) => `
    transition: 0.4s;
    position: absolute;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: ${rem(10)};
  `}
`;

const StyledWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: ${rem(10)};
`;

const StyledLoader = styled.div`
  ${({ elementDimensions }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${elementDimensions.width};
    height: 100%;
  `}
`;

const StyledInnerWrapper = styled.div`
  padding: ${rem(10)} ${rem(20)} ${rem(20)} ${rem(20)};
`;

/**
 * Overview component
 *
 */
const ColorSettings = forwardRef((props, ref) => {
  const { activeDashboardId, elementDimensions, menuColor } = props;

  //States
  const [colors, setColors] = useState({});

  //UserId
  const uuid = firebase.auth().currentUser.uid;

  //Translation
  const [t] = useTranslation('text');

  useEffect(() => {
    colors['private'] = {};
    colors['public'] = {};
    listnerSetup();
  }, []);

  /** Setup the listners */
  const listnerSetup = () => {
    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('colors')
      .once('value', snap => {
        colors['private'] = snap.val();
      })
      .then(() => {
        userDashboardListner
          .child(uuid)
          .child(activeDashboardId)
          .child('public')
          .child('colors')
          .once('value', snap => {
            colors['public'] = snap.val();
          })
          .then(() => {
            setColors(prevState => {
              // Object.assign would also work
              return { ...prevState, ...colors };
            });
          });
      });
  };

  return colors ? (
    <StyledMainWrapper
      ref={ref}
      elementDimensions={elementDimensions}
      {...props}
    >
      <StyledWrapper>
        <StyledInnerWrapper menuColor={menuColor}>
          <ColorPicker
            activeDashboardId={activeDashboardId}
            type='background'
            centeredPopOver
          />
          <Text
            text={t('menu.labels.colors.cards')}
            textStyle='description2'
            textColor='#fff'
          />
          <ColorPicker
            activeDashboardId={activeDashboardId}
            type='card'
            centeredPopOver
          />
          <ColorPicker
            activeDashboardId={activeDashboardId}
            type='overview'
            centeredPopOver
          />
          <ColorPicker
            activeDashboardId={activeDashboardId}
            type='menu'
            centeredPopOver
          />
          <Text text='Lists' textStyle='description2' textColor='#fff' />
          <ColorPicker
            activeDashboardId={activeDashboardId}
            type='listItem'
            centeredPopOver
          />
          <ColorPicker
            accessible='public'
            activeDashboardId={activeDashboardId}
            type='checkbox'
            centeredPopOver
          />
          <Text
            text={t('menu.labels.colors.chats')}
            textStyle='description2'
            textColor='#fff'
          />
          <ColorPicker
            activeDashboardId={activeDashboardId}
            type='chat'
            centeredPopOver
          />
        </StyledInnerWrapper>
      </StyledWrapper>
    </StyledMainWrapper>
  ) : (
    <StyledLoader elementDimensions={elementDimensions} menuColor={menuColor}>
      <Animation animation='loadingv2' />
    </StyledLoader>
  );
});

ColorSettings.propTypes = {
  activeDashboardId: PropTypes.string,
  elementDimensions: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  menuColor: PropTypes.string,
};

ColorSettings.defaultProps = {
  activeDashboardId: null,
  elementDimensions: null,
  menuColor: null,
};

/** @component */
export default withMemo(ColorSettings, ['elementDimensions']);
