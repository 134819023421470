/* eslint-disable require-jsdoc */
import * as actionTypes from './actionTypes';

let nextTodoId = 0;

export const addTodo = content => ({
  type: actionTypes.ADD_TODO,
  payload: {
    id: ++nextTodoId,
    content,
  },
});

export const toggleTodo = id => ({
  type: actionTypes.TOGGLE_TODO,
  payload: { id },
});

export const setFilter = filter => ({
  type: actionTypes.SET_FILTER,
  payload: { filter },
});

/**
 * set user action for global state
 */
export const setUser = user => {
  return {
    type: actionTypes.SET_USER,
    payload: {
      currentUser: user,
    },
  };
};

/**
 * set current channel global state
 */
export const setCurrentChannel = channel => {
  return {
    type: actionTypes.SET_CURRENT_CHANNEL,
    payload: {
      currentChannel: channel,
    },
  };
};

/**
 * set current list global state
 */
export const setCurrentList = list => {
  return {
    type: actionTypes.SET_CURRENT_LIST,
    payload: {
      currentList: list,
    },
  };
};

/**
 * header Color actions
 */
export const setColorPrimary = headerColor => {
  return {
    type: actionTypes.SET_COLOR_PRIMARY,
    payload: {
      headerColor,
    },
  };
};

/**
 * background Color actions
 */
export const setBackgroundColor = appBackgroundColor => {
  return {
    type: actionTypes.SET_COLOR_BACKGROUND,
    payload: {
      appBackgroundColor,
    },
  };
};

/**
 * card Color actions
 */
export const setCardColor = cardColor => {
  return {
    type: actionTypes.SET_COLOR_CARD,
    payload: {
      cardColor,
    },
  };
};

/**
 * sidebar Color actions
 */
export const setSidebarColor = sidebarColor => {
  return {
    type: actionTypes.SET_COLOR_SIDEBAR,
    payload: {
      sidebarColor,
    },
  };
};

/**
 * icon Color actions
 */
export const setIconColor = iconColor => {
  return {
    type: actionTypes.SET_COLOR_ICON,
    payload: {
      iconColor,
    },
  };
};

/**
 * listItem Color actions
 */
export const setListItemColor = listItemColor => {
  return {
    type: actionTypes.SET_COLOR_LISTITEM,
    payload: {
      listItemColor,
    },
  };
};

/**
 * listitem  checkboxColor actions
 */
export const setCheckboxColor = checkBoxColor => {
  return {
    type: actionTypes.SET_COLOR_CHECKBOX,
    payload: {
      checkBoxColor,
    },
  };
};

/**
 * listItem OnDrag actions
 */
export const listItemIsDragging = isDragging => {
  return {
    type: actionTypes.LISTITEM_IS_DRAGGING,
    payload: {
      isDragging,
    },
  };
};

/**
 * listItemInfo OnDrag actions
 */
export const listItemDragInfo = listItemDragInfo => {
  return {
    type: actionTypes.LISTITEM_DRAG_INFO,
    payload: {
      listItemDragInfo,
    },
  };
};

/**
 * globalDragInfo OnDrag actions
 */
export const globalDragInfo = globalDragInfo => {
  return {
    type: actionTypes.GLOBAL_DRAG_INFO,
    payload: {
      globalDragInfo,
    },
  };
};

/**
 * listItemInfo OnDrop actions
 */
export const listItemDropInfo = listItemDropInfo => {
  return {
    type: actionTypes.LISTITEM_DROP_INFO,
    payload: {
      listItemDropInfo,
    },
  };
};

/**
 * carditem OnDrag actions
 */
export const cardItemIsDragging = cardisDragging => {
  return {
    type: actionTypes.CARDITEM_IS_DRAGGING,
    payload: {
      cardisDragging,
    },
  };
};

/**
 * modal content actions
 */
export const modalContent = modalContent => {
  return {
    type: actionTypes.MODAL_CONTENT,
    payload: {
      modalContent,
    },
  };
};

/**
 * modal open actions
 */
export const modalOpen = modalOpen => {
  return {
    type: actionTypes.MODAL_OPEN,
    payload: {
      modalOpen,
    },
  };
};

/**
 * modal type actions
 */
export const modalType = modalType => {
  return {
    type: actionTypes.MODAL_TYPE,
    payload: {
      modalType,
    },
  };
};

/**
 * Lists has been updated
 */
export const listUpdated = listUpdated => {
  return {
    type: actionTypes.LIST_UPDATED,
    payload: {
      listUpdated,
    },
  };
};

/**
 * General user selection object
 */
export const userSelection = userSelection => {
  return {
    type: actionTypes.USER_SELECTION,
    payload: {
      userSelection,
    },
  };
};

/**
 * General user created list
 */
export const createdList = createdList => {
  return {
    type: actionTypes.CREATED_LIST,
    payload: {
      createdList,
    },
  };
};

/**
 * General user selected list
 */
export const selectedList = selectedList => {
  return {
    type: actionTypes.SELECTED_LIST,
    payload: {
      selectedList,
    },
  };
};

/**
 * General user selected chat
 */
export const selectedChat = selectedChat => {
  return {
    type: actionTypes.SELECTED_CHAT,
    payload: {
      selectedChat,
    },
  };
};

/**
 * General user selected note
 */
export const selectedNote = selectedNote => {
  return {
    type: actionTypes.SELECTED_NOTE,
    payload: {
      selectedNote,
    },
  };
};

/**
 * General user selected drawing
 */
export const selectedDraw = selectedDraw => {
  return {
    type: actionTypes.SELECTED_DRAW,
    payload: {
      selectedDraw,
    },
  };
};

/**
 * General notification type
 */
export const notificationType = notificationType => {
  return {
    type: actionTypes.NOTIFICATION_TYPE,
    payload: {
      notificationType,
    },
  };
};

/**
 * Store widget info
 */
export const storeWidgetInfo = storeWidgetInfo => {
  return {
    type: actionTypes.STORE_WIDGET_INFO,
    payload: {
      storeWidgetInfo,
    },
  };
};

/**
 * Store widget drag info
 */
export const storeWidgetDrag = storeWidgetDrag => {
  return {
    type: actionTypes.STORE_WIDGET_DRAG,
    payload: {
      storeWidgetDrag,
    },
  };
};

/**
 * Store widget drag info
 */
export const storeWidgetDelete = storeWidgetDelete => {
  return {
    type: actionTypes.STORE_WIDGET_DELETE,
    payload: {
      storeWidgetDelete,
    },
  };
};

/**
 * Store widget drag info
 */
export const activeVideoLocation = activeVideoLocation => {
  return {
    type: actionTypes.ACTIVE_VIDEO_LOCATION,
    payload: {
      activeVideoLocation,
    },
  };
};

/**
 * Store widget drag info
 */
export const transferMyVideo = transferMyVideo => {
  return {
    type: actionTypes.TRANSFER_MY_VIDEO,
    payload: {
      transferMyVideo,
    },
  };
};

/**
 * Store widget drag info
 */
export const transferRemoteVideo = transferRemoteVideo => {
  return {
    type: actionTypes.TRANSFER_REMOTE_VIDEO,
    payload: {
      transferRemoteVideo,
    },
  };
};

/**
 * channel key
 */
export const channelKey = channelKey => {
  return {
    type: actionTypes.CHANNEL_KEY,
    payload: {
      channelKey,
    },
  };
};
