import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { hideVisually } from 'polished';
import styled from 'styled-components';
import Text from '../Text';
import theme, { rem } from '../../theme';

/** Styles for a svg image */
const Icon = styled.svg`
  fill: none;
  width: 2.4rem;
  height: 1.4rem;
  stroke: none;

  &:not(:root) {
    overflow: visible;
  }
`;

const HideSwitch = styled.input.attrs({ type: 'checkbox' })`
  ${hideVisually()}
`;

const SwitchWrapper = styled.div`
  ${({ label }) => `
    vertical-align: middle;
    width: 4.1rem !important;
    cursor: pointer;
    margin-right: ${label ? rem(20) : 0};
  `}
`;

const StyledMainWrapper = styled.div`
  ${({ fullWidth, noMargin }) => `
    display: flex;
    width: ${fullWidth ? '100%' : 'auto'};
    margin-left: ${noMargin ? rem(20) : '2.5rem'};
    min-height: 2rem;
  `}
`;

const StyledLabel = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  span {
    font-size: 0.875rem;
    color: #fff;
    font-family: ${theme.font.family};
    margin-left: 2rem;
    margin-right: 16px;

    &:empty {
      margin-right: 0;
    }
  }
`;

const StyledWrapper = styled.fieldset`
  ${({ sliderBackground, checked }) => `
    padding: 0;
    width: 2.4rem;
    margin: 0;
    height: 0.7rem;
    border: none;
    background: ${checked ? theme.color.success : (sliderBackground ? sliderBackground : theme.color.dark[40])};
    transition: all 200ms;
    border-radius: 5rem;


    input[type='checkbox']:hover + &,
    input[type='checkbox']:focus + & {
      border-color: ${checked ? theme.color.primary[100] : theme.color.dark[40]};
      box-shadow: 0 0 0 ${rem(3)} ${theme.color.dark[10]};
    }
    input[type='checkbox']:disabled + &,
    input[type='checkbox']:disabled:hover + & {
      background-color: ${theme.color.dark[10]};
      box-shadow: none;
      border-color: ${checked ? theme.color.dark[10] : '#d5d5d5'};
    }

    svg {
      circle {
        transition: all 200ms ease-in-out;
      }
    }
  `}
`;

/**
 * @component
 * @param {bool} fullWidth
 * @param {string} sliderBackground
 * @param {bool} noMargin
 */
const Switch = props => {
  const {
    sliderBackground,
    fullWidth,
    label,
    name,
    onClick,
    disabled,
    checked,
    value,
    invertLabelColor,
    noMargin
  } = props;
  const [checkedProp, setCheckedProp] = useState(checked);

  //Refs
  const switchRef = useRef(checked);

  /**
   * Handle toggle
   */
  const handleToggle = () => {
    switchRef.current.value = !checked;
  };

  return (
    <StyledMainWrapper fullWidth={fullWidth} noMargin={noMargin} ref={switchRef}>
      <StyledLabel>
        <SwitchWrapper label={label}>
          <HideSwitch
            name={name}
            onChange={handleToggle}
            onClick={onClick}
            disabled={disabled}
            checked={
              switchRef.current &&
              switchRef.current.value ?
              switchRef.current.value : 
              checked
            }
          />
          <StyledWrapper
            sliderBackground={sliderBackground}
            checked={
              switchRef.current &&
              switchRef.current.value ?
              switchRef.current.value :
              checked
            }
          >
            <Icon viewBox="0 0 8 8">
              <circle
                cx={
                  (switchRef.current &&
                  switchRef.current.value) || checked ? '12' : '-4'
                } cy="2.4" r="5" fill="white" />
            </Icon>
          </StyledWrapper>
        </SwitchWrapper>
        <Text text={label} textStyle="subtitle" textColor={invertLabelColor ? '#000' : '#fff'} />
      </StyledLabel>
    </StyledMainWrapper>
  );
};

Switch.propTypes = {
  /** Value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),

  /** Disabled */
  disabled: PropTypes.bool,

  /** Name */
  name: PropTypes.string.isRequired,

  /** Checked */
  checked: PropTypes.bool,

  /** Label */
  label: PropTypes.string,

  /** OnClick */
  onClick: PropTypes.func,

  /** Define if fullwidth */
  fullWidth: PropTypes.bool,

  /** Slider background color */
  sliderBackground: PropTypes.string,

  /** Indicates smaller margin */
  noMargin: PropTypes.bool,
};

Switch.defaultProps = {
  value: '',
  disabled: false,
  checked: false,
  label: '',
  onClick: null,
  fullWidth: true,
  sliderBackground: null,
  noMargin: null,
};

/** @component */
export default Switch;
