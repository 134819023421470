/* eslint-disable import/no-extraneous-dependencies */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import common_de from './de/common.json';
import common_nl from './nl/common.json';
import common_en from './en/common.json';
import lazyload_de from './de/lazyload.json';
import lazyload_nl from './nl/lazyload.json';
import lazyload_en from './en/lazyload.json';
import messageform_de from './de/messageform.json';
import messageform_nl from './nl/messageform.json';
import messageform_en from './en/messageform.json';
import help_de from './de/help.json';
import help_nl from './nl/help.json';
import help_en from './en/help.json';
import call_de from './de/call.json';
import call_nl from './nl/call.json';
import call_en from './en/call.json';
import cropper_de from './de/cropper.json';
import cropper_nl from './nl/cropper.json';
import cropper_en from './en/cropper.json';
import emptystate_de from './de/emptystate.json';
import emptystate_nl from './nl/emptystate.json';
import emptystate_en from './en/emptystate.json';
import tooltip_de from './de/tooltip.json';
import tooltip_nl from './nl/tooltip.json';
import tooltip_en from './en/tooltip.json';
import text_de from './de/text.json';
import text_nl from './nl/text.json';
import text_en from './en/text.json';
import button_de from './de/button.json';
import button_nl from './nl/button.json';
import button_en from './en/button.json';
import textfield_de from './de/textfield.json';
import textfield_nl from './nl/textfield.json';
import textfield_en from './en/textfield.json';
import firebase_de from './de/fb.json';
import firebase_nl from './nl/fb.json';
import firebase_en from './en/fb.json';
import intro_de from './de/intro.json';
import intro_nl from './nl/intro.json';
import intro_en from './en/intro.json';
import toasts_de from './de/toasts.json';
import toasts_nl from './nl/toasts.json';
import toasts_en from './en/toasts.json';
import modal_de from './de/modal.json';
import modal_nl from './nl/modal.json';
import modal_en from './en/modal.json';
import widgets_de from './de/widgets.json';
import widgets_nl from './nl/widgets.json';
import widgets_en from './en/widgets.json';

const fallbackLng = ['nl'];
const availableLanguages = ['nl', 'en', 'de'];

const options = {
  // order and from where user language should be detected
  order: [
    'querystring',
    'cookie',
    'localStorage',
    'sessionStorage',
    'navigator',
    'htmlTag',
    'path',
    'subdomain',
  ],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: {
      de: {
        common: common_de, // 'common'
        lazyload: lazyload_de, // 'lazyload'
        messageform: messageform_de, // 'Messageform'
        help: help_de, // 'Help'
        call: call_de, // Call
        cropper: cropper_de, // Cropper
        emptystate: emptystate_de, // EmptyState
        tooltip: tooltip_de, // Tooltip
        text: text_de, // Text
        button: button_de, // Button
        textfield: textfield_de, // Textfield
        firebase: firebase_de, // Firebase
        intro: intro_de, // Intro
        toasts: toasts_de, // Toasts
        modal: modal_de, // Modals
        widgets: widgets_de, // Widgets
      },
      en: {
        common: common_en, // 'common' is our custom namespace
        lazyload: lazyload_en, // Lazyload component
        messageform: messageform_en, // Messageform component
        help: help_en, // 'Help'
        call: call_en, // Call
        cropper: cropper_en, // Cropper
        emptystate: emptystate_en, // EmptyState
        tooltip: tooltip_en, // Tooltip
        text: text_en, // Text
        button: button_en, // Button
        textfield: textfield_en, // Textfield
        firebase: firebase_en, // Firebase
        intro: intro_en, // Intro
        toasts: toasts_en, // Toasts
        modal: modal_en, // Modals
        widgets: widgets_en, // Widgets
      },
      nl: {
        common: common_nl,
        lazyload: lazyload_nl, // Lazyload component
        messageform: messageform_nl, // Messageform component
        help: help_nl, // 'Help'
        call: call_nl, // Call
        cropper: cropper_nl, // Cropper
        emptystate: emptystate_nl, // EmptyState
        tooltip: tooltip_nl, // Tooltip
        text: text_nl, // Text
        button: button_nl, // Button
        textfield: textfield_nl, // Textfield
        firebase: firebase_nl, // Firebase
        intro: intro_nl, // Intro
        toasts: toasts_nl, // Toasts
        modal: modal_nl, // Modals
        widgets: widgets_nl, // Widgets
      },
    },
    fallbackLng,
    whitelist: availableLanguages,
    detection: options,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
