/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import withMemo from '../../utils/withMemo';
import { rem } from '../../theme';
import Grid from '../Grid';
import Overview from '../Overview';
import LayoutContent from '../LayoutContent';
import Animation from '../LottieControl';
import Button from '../Button';
import firebase from '../../firebase';

const StyledAnimationWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

/**
 * Overview component
 * @param {bool} hasOverview
 * @param {string} type
 * @param {string} widgetId
 * @param {object} pivot
 * @param {object} main
 * @param {object} userData
 * @param {string} id
 * @param {string} activeDashboardId
 * @param {number} resizeMax
 * @param {number} resizeMin
 */
const LayoutWidget = props => {
  const {
    hasOverview,
    type,
    widgetId,
    pivot,
    main,
    userData,
    data,
    resizeMax,
    resizeMin,
    id,
    activeDashboardId,
  } = props;
  //State
  const [widgetState, setWidgetState] = useState({});
  const [dataObject, setDataObject] = useState({});
  const [pivotObject] = useState(pivot ? pivot : {});
  const [mainObject] = useState(main ? main : {});
  const [overviewItems, setOverviewItems] = useState({});
  const pivotName = 'user' + type.charAt(0).toUpperCase() + type.slice(1);

  //The current userId
  const userId = firebase.auth().currentUser.uid;

  //Listners
  const listner = firebase
    .database()
    .ref()
    .child(type);
  const pivotListner = firebase
    .database()
    .ref()
    .child(pivotName)
    .child(userId);
  /** overviewCallback */
  const setContentCallback = useCallback(
    (id, widgetId) => {
      if (id && widgetId) {
        setWidgetState({
          id: id,
          widgetId: widgetId,
        });
      } else {
        setWidgetState({});
      }
    },
    [setWidgetState]
  );

  // useEffect(() => {
  //   getData();
  // }, []);

  useEffect(() => {
    getData();
  }, []);

  /** getCurrentActive */
  const getCurrentActive = () => {
    let active;
    if (!hasOverview) {
      Object.keys(overviewItems).map(item => {
        active = item;
      });
    }
    Object.entries(pivotObject).map(item => {
      if (item[1].setActive) {
        active = item[0];
        setWidgetState({
          id: item[0],
        });
      } else {
        active = null;
      }
    });

    if (!active && Object.keys(pivotObject).length > 0) {
      Object.entries(overviewItems).map(item => {
        active = item[0];
      });
    }

    return active;
  };

  /** Get the data */
  const getData = () => {
    //Get the overview data
    pivotListner.once('value', snap => {
      snap.forEach(function(result) {
        if (widgetId === result.val().widgetId) {
          overviewItems[result.key] = result.val();

          listner
            .child(result.key)
            .once('value', snapMain => {
              overviewItems[result.key]['title'] = snapMain.val().title;
              overviewItems[result.key]['subTitle'] = snapMain.val().subTitle;
              overviewItems[result.key]['image'] = snapMain.val().image;
              overviewItems[result.key]['itemId'] = snapMain.val().id;
              overviewItems[result.key]['time'] = snapMain.val().time;
              overviewItems[result.key]['attendees'] = snapMain.val().attendees;
              overviewItems[result.key]['avatar'] = snapMain.val().avatar;
            })
            .then(() => {
              setOverviewItems(prevState => {
                return { ...prevState, ...overviewItems };
              });
            });
        }
      });
    });
  };

  return overviewItems ? (
    // eslint-disable-next-line react/jsx-indent
    <Grid
      columns={`${hasOverview ? (resizeMax ? resizeMax : '1fr') : ''} 3fr`}
      scrollbar={false}
      canResize={hasOverview ? true : false}
      resizeMax={resizeMax}
      resizeMin={resizeMin}
      widgetId={widgetId}
      dashboardId={activeDashboardId}
    >
      {hasOverview && (
        <Grid
          rows="1fr"
          padding={[
            {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          ]}
        >
          <Overview
            callback={setContentCallback}
            key={widgetId}
            data={overviewItems}
            pivotData={pivotObject}
            type={type}
            pivotName={type}
            widgetId={id}
            widgetData={mainObject}
            activeDashboardId={activeDashboardId}
          />
        </Grid>
      )}
      <Grid rows="1fr" scrollbar={false}>
        <React.Fragment>
          <Grid
            rows={`${dataObject.hasHeader ? `${rem(70)}` : ''} 1fr ${
              dataObject.hasFooter ? `${rem(75)}` : ''
            }`}
            scrollbar={false}
          >
            {dataObject.hasHeader && <div>header</div>}
            <Grid rows="1fr" scrollbar={false}>
              <LayoutContent
                widgetId={id}
                type={type}
                main={mainObject}
                pivot={pivotObject}
                activeItem={
                  widgetState && widgetState.id
                    ? widgetState.id
                    : getCurrentActive()
                }
                activeDashboardId={activeDashboardId}
                userData={userData}
              />
            </Grid>
            {dataObject.hasFooter && <div>footer</div>}
          </Grid>
        </React.Fragment>
      </Grid>
    </Grid>
  ) : (
    <StyledAnimationWrapper>
      <Animation
        animation="loadingv2"
        animationWidth="14rem"
        animationHeight="14rem"
      />
    </StyledAnimationWrapper>
  );
};

LayoutWidget.propTypes = {
  hasOverview: PropTypes.bool,
  widgetId: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object]),
  pivot: PropTypes.oneOfType([PropTypes.object]),
  main: PropTypes.oneOfType([PropTypes.object]),
  id: PropTypes.string,
};

LayoutWidget.defaultProps = {
  hasOverview: null,
  widgetId: null,
  type: null,
  data: null,
  main: null,
  pivot: null,
  id: '',
};

/** @component */
export default LayoutWidget;
//export default withMemo(LayoutWidget, ['id', 'userData']);
