import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Group from '../Group';
import Button from '../Button';
import Text from '../Text';
import Lottie from '../LottieControl';
import firebase, { removeItem } from '../../firebase';
import theme, { rem } from '../../theme';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(10)};
`;

const StyledTextIntro = styled(Text)`
  margin-bottom: 0;
`;

const StyledTextSub = styled(Text)`
  margin-bottom: ${rem(30)};
`;

const StyledContentWrapper = styled(Group)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

const StyledButtonGroup = styled(Group)`
  button {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: ${rem(20)};
    width: ${rem(350)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledLoading = styled(Lottie)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

/** todo */
const removeContent = props => {
  const { type, typeId, activeId, activeDashboardId, closeModals } = props;

  //The current userId
  const uuid = firebase.auth().currentUser.uid;

  //States
  const [loading, setLoading] = useState(true);
  const [itemData, setItemData] = useState({});

  useEffect(() => {
    getItemData();
  }, []);

  /** getAttendees */
  const getItemData = () => {
    const listner = firebase
      .database()
      .ref()
      .child(type)
      .child(typeId);

    listner
      .once('value', snap => {
        if (snap.val()) {
          setItemData(snap.val());
        }
      })
      .then(() => {
        setLoading(false);
      });
  };

  /** Handle Text */
  const handleText = () => {
    switch (type) {
      default:
      case 'default': {
        return type.slice(0, -1);
      }
      case 'documentsharing': {
        return 'document sharing';
        break;
      }
    };
  };

  /** Return Chat messages */
  return loading ? (
    <StyledLoading animation="loading" animationWidth={400} />
  ) : (
    <StyledWrapper>
      <StyledContentWrapper fullwidth>
        <StyledText
          align="center"
          text={`Do you want to remove this ${handleText()}?`}
          textColor="#fff"
          textStyle="h3"
          tag="h1"
        />
        <Lottie animation="alert" animationWidth={200} />

        {itemData && Object.keys(itemData).length > 0 && (
          <React.Fragment>
            <StyledTextIntro
              align="center"
              text="Are you sure you want to remove:"
              textColor="#fff"
              textStyle="h6"
              tag="p"
            />

            <StyledText
              align="center"
              text={itemData.title}
              textColor="#fff"
              textStyle="h4"
              tag="h2"
            />
          </React.Fragment>
        )}
        <StyledTextSub
          align="center"
          text="Please note that you will not be a participant anymore and all your data will be lost!"
          textColor="#fff"
          textStyle="h6"
          tag="p"
        />

        <StyledButtonGroup fullwidth>
          <Button
            variant="contained"
            text={{
              align: 'center',
              text: 'Cancel',
              textColor: '#fff',
            }}
            onClick={() => {
              closeModals();
            }}
            bgColor={theme.color.cancel}
          />
          <Button
            variant="contained"
            onClick={() => {
              removeItem(type, typeId, activeDashboardId, activeId);
              closeModals();
            }}
            bgColor={theme.color.danger}
            text={{
              align: 'center',
              text: 'Remove this ' + handleText(),
              textColor: '#fff',
            }}
          />
        </StyledButtonGroup>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

removeContent.propTypes = {
  /** Type */
  type: PropTypes.string,
  /** Selected type Id */
  typeId: PropTypes.string,
  /** Current active Id */
  activeId: PropTypes.string,
  /** Close function for the modal */
  closeModals: PropTypes.func,
  /** Current actice dashboard Id */
  activeDashboardId: PropTypes.string,
};

removeContent.defaultProps = {
  type: null,
  typeId: null,
  activeId: null,
  closeModals: null,
  activeDashboardId: null,
};

//export default connect(mapStateToProps)(Messages);
export default removeContent;
