/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import firebase, { userListner, userDashboardListner, dashboardListner } from '../../firebase';
import Avatar from '../Avatar';
import Group from '../Group';
import Badge from '../Badge';
import InfoCard from '../InfoCard';
import { rem } from '../../theme';

const StyledWrapper = styled.div`
  z-index: 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  max-height: calc(100% - ${rem(85)});
  overflow: hidden;
  flex-direction: ${props =>
    props.direction === 'vertical' ? 'column' : 'unset'};
  justify-content: flex-start;
  position: absolute;
  width: 100%;
  top: -${rem(12)};
  right: -${rem(12)};
  width: auto;
`;

const StyledAvatar = styled(Avatar)`
  margin-bottom: ${rem(3)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${rem(10)};
  width: 100%;
  z-index: 999999;
`;

const StyledTooltip = styled(ReactTooltip)`
  border-radius: ${rem(5)} !important;
  padding: ${rem(5)} ${rem(10)} !important;
`;

const StyledAvatarWrapper = styled(Group)`
  z-index: 1;
`;

const StyledGroup = styled(Group)`
  flex-direction: column;
  margin-bottom: ${rem(10)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledBadge = styled(Badge)`
  margin-right: ${rem(5)};

  &:last-child {
    margin-right: 0;
  }
`;

const StyledBadgeWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: ${rem(8)};
`;

/**
 * Return a styled component
 * @param {node} children
 * @param {string} direction
 * @param {string} widgetId
 * @param {string} activeId
 * @param {string} activeDashboardId
 */
const UserIndication = props => {
  const { direction, attendees, widgetId, activeId, activeDashboardId } = props;
  const [newFriendData, setNewFriendData] = useState({});
  const [userRoles, setUserRoles] = useState({});
  const [userRolesDetails, setUserRolesDetails] = useState({});
  const [ready, setReady] = useState(false);

  //UserId
  const uuid = firebase.auth().currentUser.uid;

  useEffect(() => {
    if (attendees) contentDetailListner();
  }, [attendees]);

  /** contentDetailListner */
  const contentDetailListner = () => {
    Object.entries(attendees).map(user => {
      userDashboardListner
        .child(user[0])
        .child(activeDashboardId)
        .child('role')
        .child('type')
        .on('value', snap => {
          if (snap.val()) {
            snap.forEach(function(childSnapType) {
              dashboardListner
                .child(activeDashboardId)
                .child('roles')
                .child(childSnapType.key)
                .child('details')
                .on('value', snapDetails => {
                  if (snapDetails.val()) {
                    if(!userRolesDetails[user[0]]) userRolesDetails[user[0]] = {};
                    userRolesDetails[user[0]][childSnapType.key] = {};
                    userRolesDetails[user[0]][childSnapType.key] = snapDetails.val();
                    setUserRolesDetails(prevState => {
                      return { ...prevState, ...userRolesDetails };
                    });
                  }
                });
            });
          } else {
            setUserRoles({});
          }

          userRoles[user[0]] = snap.val();
          setUserRoles(userRoles);
        });

      userListner
        .child(user[0])
        .child('public')
        .once('value', snap => {
          newFriendData[user[0]] = {};
          newFriendData[user[0]] = snap.val();

          setNewFriendData(prevState => {
            return { ...prevState, ...newFriendData };
          });
        });

      userListner
        .child(user[0])
        .child('public')
        .on('child_changed', snap => {
          if (newFriendData[user[0]]) {
            newFriendData[user[0]][snap.key] = {};
            newFriendData[user[0]][snap.key] = snap.val();

            if (ready) {
              setNewFriendData(prevState => {
                return { ...prevState, ...newFriendData };
              });
            }
          }
        });
    });
    setReady(true);
  };

  return (
    ready && (
      <StyledWrapper direction={direction} {...props}>
        {attendees && newFriendData
          ? Object.entries(attendees).map(user => {
              if (
                newFriendData[user[0]] &&
                firebase.auth().currentUser.uid != user[0]
              ) {
                return (
                  <StyledAvatarWrapper key={user[0]}>
                    <StyledAvatar
                      data-tip=""
                      data-delay-show="500"
                      data-for={`tooltipUserIndication-${activeId}-${user[0]}`}
                      userData={newFriendData[user[0]]}
                      size="small"
                      source={
                        newFriendData[user[0]].avatar &&
                        newFriendData[user[0]].avatar.cropped
                      }
                      alt={newFriendData[user[0]].name}
                    />

                    <StyledTooltip
                      id={`tooltipUserIndication-${activeId}-${user[0]}`}
                      getContent={() => {
                        return (
                          <StyledTooltipWrapper>
                            <InfoCard
                              avatar={{
                                source: newFriendData[user[0]].avatar.cropped,
                                status: newFriendData[user[0]].indicator,
                              }}
                              title={newFriendData[user[0]].name}
                              subTitle={newFriendData[user[0]].status}
                              textColor="#fff"
                            />
                            {userRoles && Object.keys(userRoles).length > 0 && userRoles[user[0]] && (
                              <StyledGroup fullWidth>
                                <StyledBadgeWrapper>
                                  {Object.entries(userRoles[user[0]]).map(role => {
                                    return (
                                      <StyledBadge
                                        text={role[0]}
                                        color={
                                          userRolesDetails[user[0]][role[0]] &&
                                          userRolesDetails[user[0]][role[0]].color
                                        }
                                        variant="text"
                                      />
                                    );
                                  })}
                                </StyledBadgeWrapper>
                              </StyledGroup>
                            )}
                          </StyledTooltipWrapper>
                        );
                      }}
                    />
                  </StyledAvatarWrapper>
                );
              }
            })
          : null}
      </StyledWrapper>
    )
  );
};

UserIndication.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
  ]),
  widgetId: PropTypes.string,
  activeId: PropTypes.string,
  activeDashboardId: PropTypes.string,
};

UserIndication.defaultProps = {
  children: null,
  direction: 'vertical',
  data: null,
  widgetId: null,
  activeId: null,
  activeDashboardId: null,
};

/**
 * @component
 */
export default UserIndication;
