/* eslint-disable react/no-find-dom-node */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Group from '../Group';
import Image from '../Image';
import FileViewer from 'react-file-viewer';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledContentWrapper = styled(Group)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  .pg-viewer-wrapper {
    width: 100%;
    height: 100%;
  }

  canvas {
    width: 100%;
  }
`;

const StyledIframe = styled.iframe`
  ${({ background }) => `
    border: none;
    outline: none;
    background: ${background === 'txt' ? '#fff' : 'transparent'};
  `}
`;

const StyledImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 100%;
  }
`;

/** todo */
const previewFile = props => {
  const { typeSmall, url, type, name, closeModals } = props;

  //States
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.getElementById('globalModal').firstElementChild.style.height =
      '100%';
    document.getElementById('globalModal').firstElementChild.style.width =
      '100%';
    document.getElementById('globalModal').firstElementChild.style.background =
      'transparent';
  }, []);

  /** Error handling */
  const onError = e => {
    //console.log('ERROR', e);
  };

  /** Return Chat messages */
  return (
    <StyledWrapper>
      <StyledContentWrapper fullwidth>
        {typeSmall === 'pdf' && (
          <StyledIframe src={url} height="100%" width="100%" title={name} />
        )}

        {typeSmall === 'txt' && (
          <StyledIframe src={url} background={typeSmall} height="100%" width="100%" title={name} />
        )}

        {type.includes("image") && (
          <StyledImageWrapper>
            <Image src={url} height="auto" width="auto" title={name} />
          </StyledImageWrapper>
        )}
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

previewFile.propTypes = {
  /** Current Id */
  currentId: PropTypes.string,
  /** FIle Id */
  fileId: PropTypes.string,
  /** Close function for the modal */
  closeModals: PropTypes.func,
};

previewFile.defaultProps = {
  currentId: null,
  fileId: null,
  closeModals: null,
};

export default previewFile;
