import React from 'react';
import PropTypes from 'prop-types';
import { mix } from 'polished';
import styled from 'styled-components';
import Ink from 'react-ink';
import Icon from '../Icon';
import Text from '../Text';
import buttonFirebase from './Button.firebase';
import theme, { rem } from '../../theme';

const StyledButton = styled.button`
  ${theme.textStyle.button};
  display: inline-flex;
  min-height: ${props => (props.autoHeight ? 'auto' : rem(40))};
  min-width: ${rem(40)};
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  padding: 0 ${rem(8)};
  transition: all ease-in-out 120ms;
  text-align: center;
  z-index: 1;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 0;
  border-radius: ${rem(2)};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  height: ${props => (props.autoHeight ? 'auto' : rem(40))};
  background-color: ${props =>
    props.bgColor && (props.variant === 'contained' || props.variant === 'icon')
      ? props.bgColor
      : 'transparent'};
  ${buttonFirebase};

  &:hover {
    ${({ bgColor, variant, disabled }) => `
      background-color: ${
        !disabled && bgColor && variant === 'contained'
          ? mix(0.1, theme.color.dark[100], bgColor)
          : 'none'
      };
    `};
    )};
  }

  outline: none;
  &:focus {
    ${props =>
      props.hasShadow ? `0 0 0 ${rem(2)} ${theme.color.primary[20]}` : 'none'};
  }

  a {
    display: flex;
    z-index: 0;
  }
`;

/**
 * @param {object} children
 * @param {bool} disabled
 * @param {string} name
 * @param {func} onClick
 * @param {func} onDrop
 * @param {string} variant
 * @param {string} type
 * @param {bool} ripple
 * @param {bool} fullWidth
 * @param {object} props
 * @param {string} bgColor
 * @param {bool} hasShadow
 * @param {bool} autoHeight
 */
const Button = props => {
  const {
    children,
    disabled,
    name,
    onClick,
    onDrop,
    variant,
    type,
    ripple,
    fullWidth,
    bgColor,
    autoHeight,
    icon,
    text,
    tooltip,
  } = props;

  /** Onclick handler */
  const onClickHandler = () => {
    if (onClick) onClick();
  };

  return (
    <React.Fragment>
      <StyledButton
        data-tip={
          tooltip && Object.keys(tooltip).length > 0 && !tooltip.disable
            ? tooltip.text
            : null
        }
        type={type}
        name={name}
        disabled={disabled}
        variant={variant}
        onClick={onClickHandler}
        onDrop={onDrop}
        role='button'
        ripple={ripple}
        fullWidth={fullWidth}
        bgColor={bgColor}
        autoHeight={autoHeight}
        {...props}
      >
        <React.Fragment>
          {ripple && !disabled ? <Ink background={false} /> : null}
          {icon && <Icon {...icon} />}
          {text && <Text {...text} />}
          {children}
        </React.Fragment>
      </StyledButton>
    </React.Fragment>
  );
};

Button.propTypes = {
  /** Children */
  children: PropTypes.node,

  /** Disable */
  disabled: PropTypes.bool,

  /** Name */
  name: PropTypes.string,

  /** Onclick */
  onClick: PropTypes.func.isRequired,

  /** onDrop */
  onDrop: PropTypes.func,

  /** Type */
  type: PropTypes.string,

  /** Variant */
  variant: PropTypes.string,

  /** Ripple */
  ripple: PropTypes.bool,

  /** Fullwidth */
  fullWidth: PropTypes.bool,

  /** bgColor */
  bgColor: PropTypes.string,

  /** Shadow */
  hasShadow: PropTypes.bool,

  /** AutoHeight */
  autoHeight: PropTypes.bool,

  /** Icon */
  icon: PropTypes.oneOfType([PropTypes.object]),

  /** Text */
  text: PropTypes.oneOfType([PropTypes.object]),

  /** Tooltip */
  tooltip: PropTypes.oneOfType([PropTypes.object]),
};

Button.defaultProps = {
  children: null,
  disabled: false,
  name: null,
  type: 'submit',
  onDrop: null,
  variant: 'contained',
  ripple: true,
  fullWidth: false,
  bgColor: null,
  hasShadow: true,
  autoHeight: false,
  icon: null,
  text: null,
  tooltip: null,
};

/** @component */
export default Button;
