/* eslint-disable react/no-find-dom-node */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Group from '../Group';
import Button from '../Button';
import Text from '../Text';
import Image from '../Image';
import { modalOpen, modalType } from '../../redux/actions';
import Lottie from '../LottieControl';
import firebase, { removeSubItem } from '../../firebase';
import theme, { rem } from '../../theme';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledTextIntro = styled(Text)`
  margin-bottom: ${rem(20)};
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(10)};
`;

const StyledContentWrapper = styled(Group)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

const StyledButtonGroup = styled(Group)`
  margin-top: ${rem(20)};

  button {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: ${rem(20)};
    width: ${rem(350)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledLoading = styled(Lottie)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

/** todo */
const removeImage = props => {
  const {
    typeId,
    itemId,
    imageId,
    table,
    gallery,
    activeDashboardId,
    closeModals,
  } = props;

  //States
  const [loading, setLoading] = useState(true);
  const [imageData, setImageData] = useState({});
  const [images, setImages] = useState({});

  //Translation
  const [t] = useTranslation(['modal', 'button']);

  useEffect(() => {
    imageId && getImageData();
    document.getElementById('globalModal').firstElementChild.style.height =
      'auto';
    document.getElementById('globalModal').firstElementChild.style.width =
      '25rem';
  }, []);

  /** Get all the image data */
  const getImageData = () => {
    firebase
      .database()
      .ref()
      .child(table)
      .child(typeId)
      .child('items')
      .child(itemId)
      .child('images')
      .child(imageId)
      .once('value', snapshot => {
        if (snapshot.val()) {
          setImageData(snapshot.val());
          setLoading(false);
        }
      })
      .then(() => {
        firebase
          .database()
          .ref()
          .child(table)
          .child(typeId)
          .child('items')
          .child(itemId)
          .child('images')
          .once('value', snap => {
            if (snap.exists()) {
              setImages(snap.val());
            }
          });
      });
  };

  /** Handle update */
  const handleRemove = () => {
    firebase
      .database()
      .ref()
      .child(table)
      .child(typeId)
      .child('items')
      .child(itemId)
      .child('images')
      .child(imageId)
      .remove()
      .then(() => {
        firebase
          .storage()
          .ref()
          .child(table)
          .child('items')
          .child('public')
          .child(imageData.storageId)
          .delete()
          .then(() => {
            firebase
              .database()
              .ref()
              .child(table)
              .child(typeId)
              .child('items')
              .child(itemId)
              .child('totalImages')
              .transaction(function(totalImages) {
                return (totalImages || 1) - 1;
              })
              .then(() => {
                firebase
                  .database()
                  .ref()
                  .child(table)
                  .child(typeId)
                  .child('items')
                  .child(itemId)
                  .once('value', snapcheck => {
                    if (
                      snapcheck.val().text === '' &&
                      !snapcheck.val().images
                    ) {
                      removeSubItem(table, typeId, itemId);

                      firebase
                        .database()
                        .ref()
                        .child(table)
                        .child(typeId)
                        .child('itemCount')
                        .transaction(function(itemCount) {
                          return (itemCount || 1) - 1;
                        });
                      closeModals();
                    } else {
                      if (gallery) {
                        imageOnclickHandler(
                          images,
                          itemId,
                          typeId,
                          activeDashboardId
                        );
                      } else {
                        closeModals();
                      }
                    }
                  });
              });
          });
      });
  };

  /** Trigger the preview modal onClick */
  const imageOnclickHandler = (images, itemId, typeId, activeDashboardId) => {
    props.modalOpen(true);
    props.modalType(['gallery', images, itemId, typeId, activeDashboardId]);
  };

  /** Return Chat messages */
  return loading ? (
    <StyledLoading animation='loading' animationWidth={400} />
  ) : (
    <StyledWrapper>
      <StyledContentWrapper fullwidth>
        <StyledText
          align='center'
          text={t('modal:removeimage.title')}
          textColor='#fff'
          textStyle='h3'
          tag='h1'
        />

        <StyledTextIntro
          align='center'
          text={t('modal:removeimage.text')}
          textColor='#fff'
          textStyle='h6'
          tag='p'
        />

        {imageData && imageData.url && <Image src={imageData.url} width={80} />}

        <StyledButtonGroup fullwidth>
          <Button
            variant='contained'
            onClick={() => {
              handleRemove();
            }}
            bgColor={theme.color.danger}
            text={{
              align: 'center',
              text: t('button:remove.image'),
              textColor: '#fff',
            }}
          />
          <Button
            variant='contained'
            text={{
              align: 'center',
              text: t('button:cancel.cancel'),
              textColor: '#fff',
            }}
            onClick={() => {
              if (gallery) {
                imageOnclickHandler(images, itemId, typeId, activeDashboardId);
              } else {
                closeModals();
              }
            }}
            bgColor={theme.color.cancel}
          />
        </StyledButtonGroup>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

removeImage.propTypes = {
  /** typeId Id */
  typeId: PropTypes.string,
  /** Item Id */
  itemId: PropTypes.string,
  /** Image Id */
  imageId: PropTypes.string,
  /** Table name */
  table: PropTypes.string,
  /** Check if gallery ifso return to gallery on cancel */
  gallery: PropTypes.bool,
  /** Currently active dashboard ID */
  activeDashboardId: PropTypes.string,
  /** Close function for the modal */
  closeModals: PropTypes.func,
};

removeImage.defaultProps = {
  typeId: null,
  itemId: null,
  imageId: null,
  table: null,
  gallery: null,
  activeDashboardId: null,
  closeModals: null,
};

/** @component */
export default connect(null, {
  modalOpen,
  modalType,
})(removeImage);
