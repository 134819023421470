import React from 'react';
import PropTypes from 'prop-types';
import Sound from 'react-sound';

/**
 * Audio component
 * @param {func} finished
 * @param {string} status
 */
const Audio = props => {
  const { url, position, finished, status } = props;

  /** handleSongLoading */
  const handleSongLoading = () => {};

  /** handleSongPlaying */
  const handleSongPlaying = () => {};

  /** handleSongFinishedPlaying */
  const handleSongFinishedPlaying = () => {
    finished();
  };

  /** Handle playing status */
  const handleStatus = () => {
    switch (status) {
      case 'PLAYING':
        return Sound.status.PLAYING;
      case 'PAUSED':
        return Sound.status.PAUSED;
      default:
        return Sound.status.STOPPED;
    }
  };

  return (
    <Sound
      url={url}
      playStatus={handleStatus()}
      playFromPosition={position /* in milliseconds */}
      onLoading={handleSongLoading}
      onPlaying={handleSongPlaying}
      onFinishedPlaying={handleSongFinishedPlaying}
    />
  );
};

Audio.propTypes = {
  /** The List data from Firebase */
  url: PropTypes.string,
  /** Position of playing sound */
  position: PropTypes.number,
  /** Handle finished playing */
  finished: PropTypes.func,
  /** Playing status */
  status: PropTypes.string,
};

Audio.defaultProps = {
  url: null,
  position: 0,
  finished: null,
  status: 'STOPPED',
};

export default Audio;
