/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import theme, { rem } from '../../theme';
import Animation from '../LottieControl';
import Button from '../Button';
import Text from '../Text';

const StyledAnimationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(20)};
`;

/**
 * Return a styled EmptyState component
 * @param {string} animation
 * @param {string | number} height
 * @param {string | number} width
 * @param {string} text
 * @param {string} textColor
 * @param {string} widgetId
 * @param {bool} hasButton
 */
const EmptyState = props => {
  const {
    animation,
    text,
    textColor,
    widgetId,
    hasButton,
    height,
    width,
  } = props;

  /** handle empty button click */
  const handleOnclick = () => {
    if (document.getElementById('additem-' + widgetId))
      document.getElementById('additem-' + widgetId).click();
  };

  //Translation
  const [t] = useTranslation('button');

  return (
    <StyledAnimationWrapper className="editable" {...props}>
      <Animation
        animation={animation ? animation : 'empty'}
        animationWidth={width ? width : '14rem'}
        animationHeight={height ? height : '12rem'}
      />
      <React.Fragment>
        <StyledText
          text={text}
          textColor={textColor ? textColor : theme.color.dark[80]}
          textStyle="h6"
          align="center"
        />
        {hasButton && (
          <Button
            onClick={() => handleOnclick()}
            text={{ text: t('emptystate.text'), textColor: '#fff' }}
            bgColor={theme.color.primary[100]}
          />
        )}
      </React.Fragment>
    </StyledAnimationWrapper>
  );
};

EmptyState.propTypes = {
  /** Type of animation */
  animation: PropTypes.string,
  /** Text supplied to the state */
  text: PropTypes.string,
  /** Color of the text */
  textColor: PropTypes.string,
  /** Current widgetId */
  widgetId: PropTypes.string,
  /** Showing a start button */
  hasButton: PropTypes.bool,
  /** Animation height */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Animation width */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

EmptyState.defaultProps = {
  animation: null,
  text: '',
  textColor: '',
  widgetId: null,
  hasButton: true,
  width: null,
  height: null,
};

export default EmptyState;
