import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';
import UploadAvatar from '../UploadAvatar';
import Group from '../Group';
import Button from '../Button';
import Avatar from '../Avatar';
import Grid from '../Grid';
import Text from '../Text';
import Switch from '../Switch';
import TextField from '../TextField';
import Icon from '../Icon';
import Lottie from '../LottieControl';
import FilterSearch from '../FilterSearch';
import firebase, { updateItem } from '../../firebase';
import theme, { rem } from '../../theme';
import { webStyles } from './Modal.styles';

const StyledSideWrapper = styled.div`
  ${({ theme, rem }) => webStyles.sideContent(theme, rem)}
`;

//CSS animations
const JackInTheBox = keyframes`
  from {
    opacity: 0;
    transform: scale(0.1) rotate(30deg);
    transform-origin: center bottom;
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const AnimationJackInTheBox = css`
  animation: ${JackInTheBox} 0.5s ease;
  animation-delay: 0;
  animation-fill-mode: forwards;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledUserSearch = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
`;

const StyledAddedUserWrapper = styled.div`
  ${AnimationJackInTheBox};
  margin-bottom: ${rem(10)}
  
  &:last-child {
    margin-bottom: 0;
  };
`;

const StyledGroup = styled(Group)`
  flex-direction: column;
  margin-bottom: ${rem(10)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledNavGroup = styled(Group)`
  margin-top: ${rem(20)};

  button {
    margin-right: ${rem(20)};

    &:last-child {
      margin-right: 0;
    }

    &.active {
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${theme.color.success};
      }
    }
  }
`;

const StyledContent = styled(Grid)`
  border-radius: ${rem(10)};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  opacity: 0;

  &.active {
    opacity: 1;
  }
`;

const StyledButtonGroup = styled(Group)`
  margin-top: ${rem(15)};
`;

const StyledUploadAvatar = styled(UploadAvatar)`
  width: auto;
  margin: ${rem(15)} !important;
`;

const StyledContentWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledAvatarContent = styled(Grid)`
  width: 100%;
  height: 100%;
`;

const StyledTextWrapper = styled(Group)`
  align-self: flex-start;
  width: auto;

  i {
    margin-left: ${rem(6)};
  }
`;

const StyledAvatarWrapper = styled(Group)`
  display: none;
  z-index: 1;

  &.active {
    display: block;
  }
`;

/** todo */
const updateContent = props => {
  const { data, type, closeModals, activeDashboardId } = props;
  //The current userId
  const userId = firebase.auth().currentUser.uid;

  //States
  const [saveData] = useState([]);
  const [newSelectedUsers, setNewSelectedUsers] = useState({});
  const [loading, setLoading] = useState(true);
  const [publicSwitch, setPublicSwitch] = useState();
  const [allDashboardMembers, setAllDashboardMembers] = useState({});
  const [maxLength] = useState(100);

  //Reft
  const titleRef = useRef();
  const subTitleRef = useRef();
  const childRef = useRef();

  useEffect(() => {
    getAllDashboardMembers();
    getPublicData();
    if (type) getAttendees();
  }, []);

  /** Get the public data
   * See if the content is public or not
   */
  const getPublicData = () => {
    firebase
      .database()
      .ref()
      .child(type)
      .child(data.id)
      .child('isPublic')
      .once('value', snapshot => {
        setPublicSwitch(snapshot.val());
      });
  };

  /** getAttendees */
  const getAttendees = () => {
    const listner = firebase
      .database()
      .ref()
      .child(type)
      .child(data.id)
      .child('attendees');

    listner
      .once('value', snap => {
        if (snap.val()) {
          snap.forEach(function(snapshot) {
            let userInfo = getFriendsData(snapshot.key);
            //newSelectedUsers[snapshot.key] = userInfo;
          });
        }
      })
      .then(() => {
        setLoading(false);
      });
  };

  /** Update the value in the DB */
  const getFriendsData = Uid => {
    /** Firebase get user info */
    let dataObject = {};
    newSelectedUsers[Uid] = {};
    firebase
      .database()
      .ref('users')
      .child(Uid)
      .child('public')
      .once('value', snapshot => {
        dataObject = snapshot.val();
        newSelectedUsers[Uid] = dataObject;
      });
  };

  /** Get all dashboard members */
  const getAllDashboardMembers = () => {
    /** Firebase get user info */
    let dataObject = {};

    firebase
      .database()
      .ref('dashboards')
      .child(activeDashboardId)
      .child('members')
      .once('value', snapshot => {
        dataObject = snapshot.val();
      })
      .then(() => {
        setAllDashboardMembers(dataObject);
      });
  };

  /** Avatar remove handler */
  const onCloseHandler = () => {
    saveData['image'] = 'delete';
  };

  /** Handle click */
  const handleClick = type => {
    document.getElementById('editavatar').classList.remove('active');
    document.getElementById('editfriends').classList.remove('active');
    if (document.getElementById('buttoneditfriends'))
      document.getElementById('buttoneditfriends').classList.remove('active');
    document.getElementById('buttoneditavatar').classList.remove('active');

    //Active
    document.getElementById('edit' + type).classList.add('active');
    document.getElementById('buttonedit' + type).classList.add('active');
  };

  /** onItemClick */
  const onItemClick = itemId => {
    if (!newSelectedUsers.hasOwnProperty(itemId)) {
      getFriendsData(itemId);
      setNewSelectedUsers(prevState => {
        return { ...prevState, ...newSelectedUsers };
      });
    } else {
      delete newSelectedUsers[itemId];

      setNewSelectedUsers(prevState => {
        return { ...prevState, ...newSelectedUsers };
      });
    }
  };

  /** When pressed on update list */
  const handleEditItem = () => {
    saveData['selectedFriends'] = {};

    if (publicSwitch) {
      saveData['selectedFriends'] = allDashboardMembers;
    } else {
      Object.keys(newSelectedUsers).map(user => {
        saveData['selectedFriends'][user] = true;
      });
    }
    saveData['widgetId'] = data.widgetId;
    saveData['excistingImage'] = data.image;
    saveData['listId'] = data.id;
    saveData['attendees'] = data.attendees;
    saveData['title'] = titleRef.current.value.substring(0, maxLength);
    saveData['subtitle'] = subTitleRef.current.value.substring(0, maxLength);
    saveData['isPublic'] = publicSwitch;
    saveData['dashboardId'] = activeDashboardId;
    saveData['image'] = childRef.current.generateImage();
    updateItem(saveData, type);
    closeModals();
  };

  /** Handle Text */
  const handleText = () => {
    switch (type) {
      default:
      case 'default': {
        return type.slice(0, -1);
      }
      case 'documentsharing': {
        return 'document sharing';
        break;
      }
    };
  };

  /** Return Chat messages */
  return loading ? (
    <Lottie animation="loading" animationWidth={400} />
  ) : (
    <StyledWrapper>
      <StyledGroup fullwidth>
        <Text
          align="left"
          text="Title"
          textStyle="description"
          textColor="#fff"
        />
        <TextField
          placeholdercolor="#fff"
          backgroundcolor={theme.color.dark[40]}
          isFocused
          textcolor="#fff"
          inputId="modalTitle"
          placeholder={`What is the name of your ${handleText()}?`}
          borders={false}
          value={data ? data['title'].substring(0, maxLength) : null}
          ref={titleRef}
        />
      </StyledGroup>

      <StyledGroup>
        <Text
          align="left"
          text="Description"
          textStyle="description"
          textColor="#fff"
        />
        <TextField
          textcolor="#fff"
          placeholdercolor="#fff"
          backgroundcolor={theme.color.dark[40]}
          placeholder="Also want a subtitle?"
          inputId="modalSubTitle"
          borders={false}
          value={data ? data['subTitle'].substring(0, maxLength) : null}
          ref={subTitleRef}
        />
      </StyledGroup>

      <StyledGroup>
        <StyledTextWrapper>
          <Text
            align="left"
            text="Set public"
            textStyle="description"
            textColor="#fff"
          />
          <Icon
            icon={publicSwitch ? 'lock_open' : 'lock'}
            color={publicSwitch ? theme.color.success : '#fff'}
          />
        </StyledTextWrapper>
        <Switch
          name="isPublicSwitch"
          value={publicSwitch}
          label=""
          checked={publicSwitch}
          onClick={() => {
            setPublicSwitch(!publicSwitch);
            handleClick('avatar');
          }}
        />
      </StyledGroup>

      <StyledNavGroup>
        <Button
          id="buttoneditavatar"
          variant="text"
          onClick={() => handleClick('avatar')}
          bgColor={theme.color.dark[8]}
          className="active"
          text={{
            text: 'Avatar',
            textColor: '#fff',
            textStyle: 'description',
          }}
        />
        {!publicSwitch && (
          <Button
            id="buttoneditfriends"
            variant="text"
            onClick={() => handleClick('friends')}
            bgColor={theme.color.dark[8]}
            text={{
              text: 'Edit members',
              textColor: '#fff',
              textStyle: 'description',
            }}
          />
        )}
      </StyledNavGroup>

      <StyledContentWrapper>
        <StyledAvatarWrapper id="editavatar" className="active">
          <StyledAvatarContent rows="1fr" bgColor={theme.color.dark[40]}>
            <StyledUploadAvatar
              ref={childRef}
              type="overviewItem"
              itemType={type}
              id={data.id}
              currentImage={data && data.avatar ? data.avatar.original : ''}
              width={100}
              height={100}
              padding={20}
              shadingColor="#000"
              saveData={saveData}
              onClose={preview => onCloseHandler(preview)}
              cropRadius={75}
              backgroundColor={theme.color.light[20]}
              scale={data.avatar && data.avatar.scale}
              rotate={data.avatar && data.avatar.rotate}
              position={data.avatar && data.avatar.position}
              activeDashboardId={activeDashboardId}
              hasButton={false}
            />
          </StyledAvatarContent>
        </StyledAvatarWrapper>
        <StyledContent
          rows="1fr"
          bgColor={theme.color.dark[40]}
          parentId="editfriends"
          scrollbar={false}
        >
          <StyledUserSearch>
            <FilterSearch
              type="members"
              elementDimensions={{ width: rem(592) }}
              backgroundColor="transparent"
              onItemClick={id => onItemClick(id)}
              setActive={newSelectedUsers}
              activeDashboardId={activeDashboardId}
            />
          </StyledUserSearch>
        </StyledContent>
      </StyledContentWrapper>
      <StyledSideWrapper id="modalAside">
        {Object.keys(newSelectedUsers).length > 0 &&
          Object.entries(newSelectedUsers).map(user => {
            if (userId !== user[0]) {
              return (
                <StyledAddedUserWrapper key={user[0]} data-reference={user[0]}>
                  <Avatar
                    size="medium"
                    source={user[1] && user[1].avatar && user[1].avatar.cropped}
                    alt={user[1] && user[1].name}
                    title={user[1] && user[1].name}
                  />
                </StyledAddedUserWrapper>
              );
            }
          })}
      </StyledSideWrapper>
      <StyledButtonGroup justify="flex-end">
        <Button
          onClick={data && handleEditItem}
          id="closeModal"
          bgColor={theme.color.success}
        >
          <Text textColor="#fff" text={data ? 'Update' : 'Create'} />
        </Button>
      </StyledButtonGroup>
    </StyledWrapper>
  );
};

updateContent.propTypes = {
  /** This sets the users current channel */
  type: PropTypes.string,
  /** The List data from Firebase */
  data: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
  ]),
  /** Close function for the modal */
  closeModals: PropTypes.func,
  /** Current actice dashboard */
  activeDashboardId: PropTypes.string,
};

updateContent.defaultProps = {
  type: null,
  data: null,
  closeModals: null,
  activeDashboardId: null,
};

//export default connect(mapStateToProps)(Messages);
export default updateContent;
