import React, { useState, forwardRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme, { rem } from '../../theme';
import textFieldStyles from './TextField.styles';
import { updateCursorEvent } from '../Cursor';
import Icon from '../Icon';
import firebase, { userDashboardListner } from '../../firebase';

/** Styles for the wrapper */
const StyledTextFieldWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  height: ${props => (props.height ? props.height : '100%')};
`;

/** Styles for the label */
const StyledLabel = styled.label`
  height: 100%;
  position: relative;
  display: ${props => (props.orientation === 'vertical' ? 'block' : 'flex')};
  ${theme.textStyle.body};
  input,
  textarea {
    margin-top: ${props => (props.orientation === 'vertical' ? rem(2) : null)};
  }
`;

/**
 * Styles for the textfield or textarea,
 * Depending on multiline prop
 */
const StyledTextArea = styled.textarea`
  position: relative;
  background-color: ${props => (props.backgroundcolor ? 'transparent' : null)};
  box-sizing: border-box;
  resize: none;
  border: none;
  border-radius: ${rem(2)};
  color: ${props => props.textcolor};
  flex-grow: 1;
  padding: ${rem(10)};
  width: ${props => (props.hasButtons ? `calc(100% - ${rem(100)})` : '100%')};
  height: ${props => (props.height ? props.height : '100%')};
  overflow: ${props => (props.overflowType ? 'visible' : 'hidden')};
  line-height: ${rem(20)};
  max-height: ${props => (props.maxHeight ? props.maxHeight : null)};
  overflow-y: auto;
  margin-right: ${props => (props.hasButtons ? rem(55) : '')};

  &:focus {
    border-color: ${textFieldStyles.accentColor};
    outline: none;
    box-shadow: 0 0 0 ${rem(4)} ${textFieldStyles.boxShadow};
    box-shadow: ${props => props.borders || 'none'};
  }
  &:disabled {
    border-color: ${theme.color.dark[10]};
    color: ${theme.color.dark[20]};
  }

  &::-webkit-scrollbar-track {
    box-shadow: none !important;
    border-radius: 50px;
    background-color: #e5e5e6;
  }

  &::-webkit-scrollbar {
    width: 5px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c8c8c8;
  }
`;

const StyledTextField = styled.input`
  ${props => theme.textStyle[props.textsize]};
  text-align: ${props => (props.centertext ? 'center' : 'left')};
  border-width: ${props => (props.borders ? rem(1) : 'none')};
  border-style: ${props => (props.borders ? 'solid' : 'none')};
  border-color: ${props =>
    props.borders ? textFieldStyles.borderColor : 'transparent'};
  border-radius: ${rem(2)};
  box-sizing: border-box;
  color: ${props => props.textcolor};
  background-color: ${props => (props.backgroundcolor ? 'transparent' : null)};
  margin: 0;
  padding: ${rem(10)};
  margin-right: ${props => (props.hasButtons ? rem(100) : '')};
  margin-right: ${props => (props.hasSinlgeButton ? rem(35) : '')};
  height: 100%;
  width: 100%;
  min-height: ${props => (props.multiline ? rem(120) : rem(40))};
  align-self: flex-start;
  min-width: ${rem(40)};
  max-width: 100%;
  resize: vertical;
  &:focus {
    border-color: ${textFieldStyles.accentColor};
    outline: none;
    box-shadow: 0 0 0 ${rem(4)} ${textFieldStyles.boxShadow};
    box-shadow: ${props => props.borders || 'none'};
  }
  &:disabled {
    border-color: ${theme.color.dark[10]};
    color: ${theme.color.dark[20]};
  }
  &:hover:not(:focus):not(:disabled) {
    box-shadow: ${props =>
      props.borders
        ? `${rem(1)} ${rem(1)} ${rem(4)} 0 ${theme.color.dark[10]}`
        : `none`};
  }

  &::placeholder {
    color: ${props => props.placeholdercolor};
  }

  &::selection {
    color: ${props => props.selectionColor};
    background: ${props => props.selectionBackground};
  }
`;

const StyledIcon = styled(Icon)`
  position: relative;
  width: auto;
  height: 100%;
  min-height: ${props => (props.multiline ? '100%' : rem(40))};
  padding-right: ${props => (props.spacing ? props.spacing : null)};
  background-color: ${props => (props.backgroundcolor ? 'transparent' : null)};
  box-sizing: border-box;
`;

/** Styles for the helptext */
const StyledHelptext = styled.div`
  box-sizing: border-box;
  color: ${textFieldStyles.accentColor};
  font-size: 0.775rem;
  font-family: ${theme.font.family};
  padding-left: ${rem(8)};
`;

const StyledFieldset = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: ${props =>
    props.backgroundcolor ? props.backgroundcolor : null};
  padding: 0 ${props => props.labelSpacing};
  border-radius: ${props =>
    props.borderRadius ? rem(props.borderRadius) : rem(10)};
`;

const StyledChildWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  button {
    padding: 0;
  }
`;

/**
 * Render function: return a styled textfield
 * Props are passed to make passing custom data attributes possible
 * @param {boolean} disabled
 * @param {boolean} error
 * @param {string} helptext
 * @param {string} label
 * @param {string} backgroundcolor
 * @param {string} textcolor
 * @param {string} textsize
 * @param {string} placeholdercolor
 * @param {string} icon
 * @param {string} iconColor
 * @param {boolean} multiline
 * @param {string} placeholder
 * @param {bool} borders
 * @param {bool} autoComplete
 * @param {object} props
 * @param {string} height
 * @param {node} children
 * @param {string} labelSpacing
 * @param {bool} centertext
 * @param {string} id
 * @param {string} inputId
 * @param {bool} isFocused
 * @param {string} type
 * @param {bool} hasButtons
 * @param {bool} isTextArea
 * @param {number} maxTextAreaHeight - Max possible height of the text area
 * @param {number} borderRadius
 * @param {bool} overflowType
 * @param {bool} readOnly
 * @param {bool} hasSinlgeButton
 * @param {number | string} maxHeight
 * @param {number | string} maxLength
 * @param {string} selectionColor
 * @param {string} selectionBackground
 */
const TextField = forwardRef((props, ref) => {
  const {
    activeDashboardId,
    disabled,
    error,
    helptext,
    label,
    backgroundcolor,
    textcolor,
    textsize,
    placeholdercolor,
    extraOnchange,
    extraOnblur,
    iconColor,
    icon,
    multiline,
    placeholder,
    borders,
    height,
    children,
    spacing,
    labelSpacing,
    onFocus,
    orientation,
    centertext,
    id,
    inputId,
    autoComplete,
    name,
    isFocused,
    type,
    value,
    hasButtons,
    isTextArea,
    maxTextAreaHeight,
    borderRadius,
    overflowType,
    readOnly,
    hasSinlgeButton,
    maxHeight,
    maxLength,
    selectionColor,
    selectionBackground,
  } = props;

  //UserId
  let uuid = null;
  if (firebase.auth() && firebase.auth().currentUser) {
    uuid = firebase.auth().currentUser.uid;
  }

  const [inputValue, setInputValue] = useState(value);
  const [textAreaWrapperHeight, setTextAreaWrapperHeight] = useState(height);
  const [innerScroll, setInnerScroll] = useState(false);

  const [callbackCount, setCallbackCount] = useState({});

  useEffect(() => {
    if (
      ref &&
      inputValue &&
      ref.current &&
      inputValue.replace(/\n/g, '') === ''
    ) {
      ref.current.value = '';
      setInputValue(ref.current.value.replace(/\n/g, ''));
      setTextAreaWrapperHeight(height);
      updateTextAreaHeight(ref.current);
    }
  }, [value]);

  useEffect(() => {
    if (activeDashboardId) {
      window.addEventListener('keydown', handleUserKeyPress);
    }

    if (isTextArea) {
      if (ref && ref.current && !overflowType) {
        ref.current.style.height = 35 + 'px';
        updateTextAreaHeight(ref.current);
      }
    }
  }, []);

  useEffect(() => {
    if (isTextArea) {
      // window.addEventListener('keydown', handleUserKeyPress);

      // return () => {
      //   window.removeEventListener('keydown', handleUserKeyPress);
      // };
      if (ref && ref.current) updateTextAreaHeight(ref.current);
    }
  }, [inputValue]);

  // Here if we give an empty array of dependencies, the callback function will return the old value of callbackCount
  // because useCallback will return its memoized version
  const handleUserKeyPress = useCallback(
    event => {
      const { key, keyCode } = event;
      // Do something with callbackCount ...
      setCallbackCount({ key, keyCode, event });
      return callbackCount;
    },
    [callbackCount]
  );

  /**
   * Updates the text area's height
   * @param {node} textArea - The text area itself
   */
  function updateTextAreaHeight() {
    if (
      (callbackCount.keyCode === 13 && callbackCount.event.shiftKey) ||
      callbackCount.keyCode === 8
    ) {
      if (ref && ref.current && !overflowType) {
        // eslint-disable-next-line no-param-reassign
        ref.current.style.height = 0;
        let newHeight = ref.current.scrollHeight + 1 + 'px';

        if (maxTextAreaHeight <= ref.current.scrollHeight) {
          newHeight = maxTextAreaHeight + 1 + 'px';
          setInnerScroll(true);
        } else {
          setInnerScroll(false);
        }

        // eslint-disable-next-line no-param-reassign
        ref.current.style.height = newHeight;
      }
    }

    if (callbackCount.keyCode === 13 && !callbackCount.event.shiftKey) {
      if (ref && ref.current && !overflowType) {
        ref.current.style.height = 35 + 'px';
      }
    }
  }

  /** Add a the search results class when the user is typing */
  const onChangeHandler = () => {
    if (ref.current) {
      if (ref.current.value.length <= maxLength) {
        setInputValue(ref.current.value);
      }
      if (uuid) updateCursorEvent(uuid, activeDashboardId, 'typing');
    }

    if (extraOnchange) {
      extraOnchange();
    }
  };

  /** Add a the search results class when the user is typing and leaving the field */
  const onBlurHandler = extraOnblur => {
    if (ref.current) {
      if (ref.current.value.length <= maxLength) {
        setInputValue(ref.current.value);
      }
      if (uuid) updateCursorEvent(uuid, activeDashboardId, 'default');
    }

    if (extraOnblur) {
      extraOnblur();
    }
  };

  return (
    <StyledTextFieldWrapper id={id} height={height}>
      <StyledLabel orientation={orientation}>
        {label}
        <StyledFieldset
          labelSpacing={labelSpacing}
          height={height}
          backgroundcolor={backgroundcolor}
          borderRadius={borderRadius}
        >
          {icon ? (
            <StyledIcon
              multiline={multiline ? 1 : 0}
              backgroundcolor={backgroundcolor}
              spacing={spacing}
              icon={icon}
              color={iconColor}
              size={20}
            />
          ) : null}
          {isTextArea ? (
            <React.Fragment>
              <StyledTextArea
                maxLength={maxLength}
                readOnly={readOnly}
                type={type}
                autoFocus={isFocused ? true : null}
                autoComplete={autoComplete === 'off' ? 'off' : 'on'}
                centertext={centertext}
                disabled={disabled}
                multiline={multiline ? 1 : 0}
                placeholder={placeholder}
                error={error ? 1 : 0}
                {...props}
                onChange={() => onChangeHandler()}
                onBlur={() => onBlurHandler(extraOnblur)}
                onFocus={onFocus}
                backgroundcolor={backgroundcolor}
                textcolor={textcolor}
                textsize={textsize}
                value={
                  ref && ref.current
                    ? ref.current.value
                    : inputValue
                    ? inputValue
                    : ''
                }
                icon={icon}
                borders={borders ? 1 : 0}
                placeholdercolor={placeholdercolor}
                id={inputId}
                name={name}
                ref={ref}
                isTextArea={isTextArea}
                overflowType={overflowType}
                innerScroll={innerScroll}
                maxHeight={maxHeight}
                hasButtons={hasButtons}
                hasSinlgeButton={hasSinlgeButton}
                selectionBackground={selectionBackground}
                selectionColor={selectionColor}
              />
              <br />
            </React.Fragment>
          ) : (
            <StyledTextField
              hasButtons={hasButtons}
              hasSinlgeButton={hasSinlgeButton}
              maxLength={maxLength}
              readOnly={readOnly}
              type={type}
              autoFocus={isFocused ? true : null}
              autoComplete={autoComplete === 'off' ? 'off' : 'on'}
              centertext={centertext}
              disabled={disabled}
              multiline={multiline ? 1 : 0}
              placeholder={placeholder}
              error={error ? 1 : 0}
              {...props}
              onChange={() => onChangeHandler(extraOnchange)}
              onBlur={() => onBlurHandler(extraOnblur)}
              onFocus={onFocus}
              backgroundcolor={backgroundcolor}
              textcolor={textcolor}
              textsize={textsize}
              value={ref && ref.current ? ref.current.value : inputValue}
              icon={icon}
              borders={borders ? 1 : 0}
              placeholdercolor={placeholdercolor}
              id={inputId}
              name={name}
              ref={ref}
              isTextArea={isTextArea}
              selectionBackground={selectionBackground}
              selectionColor={selectionColor}
            />
          )}
          {children ? (
            <StyledChildWrapper>{children}</StyledChildWrapper>
          ) : null}
        </StyledFieldset>
      </StyledLabel>
      <StyledHelptext error={error ? 1 : 0}>{helptext}</StyledHelptext>
    </StyledTextFieldWrapper>
  );
});

TextField.propTypes = {
  /** Gets called when the text changes */
  //onChange: PropTypes.func.isRequired,

  /** Handles the onfocus functionality */
  onFocus: PropTypes.func,

  /** Handles the onblur functionality */
  extraOnblur: PropTypes.func,

  /** Disables the textfield */
  disabled: PropTypes.bool,

  /** Sets the error state of the textfield */
  error: PropTypes.bool,

  /** Add a helptext to the textfield */
  helptext: PropTypes.string,

  /** Add a label to the textfield */
  label: PropTypes.string,

  /** Add a background color to the textfield */
  backgroundcolor: PropTypes.string,

  /** Add a text color to the textfield */
  textcolor: PropTypes.string,

  /** Add a default text size */
  textsize: PropTypes.string,

  /** Color of the placeholder text */
  placeholdercolor: PropTypes.string,

  /** Color of the icon indicater */
  iconColor: PropTypes.string,

  /** Add a icon to the textfield */
  icon: PropTypes.string,

  /** Change the textfield to a multiline textfield */
  multiline: PropTypes.bool,

  /** Add a placeholder to the textfield */
  placeholder: PropTypes.string,

  /** Add a value to the textfield */
  value: PropTypes.string,

  /** Indication if the textfield contains borders */
  borders: PropTypes.bool,

  /** Property to set the height of the fieldset */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /** Child components for creating action buttons */
  children: PropTypes.node,

  /** Spacing between first component and inputfield */
  spacing: PropTypes.string,

  /** Defines spacing around the wrapper */
  labelSpacing: PropTypes.string,

  /** Placement of the label and textfield */
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),

  /** Text alignment */
  centertext: PropTypes.bool,

  /** Add a custom element ID */
  id: PropTypes.string,

  /** Add a custom ID specific for the input field */
  inputId: PropTypes.string,

  /** Add a name for the input field */
  name: PropTypes.string,

  /** Disable the autocomplete functionality */
  autoComplete: PropTypes.oneOf(['on', 'off']),

  /** Sets the inputfield onfocus when render is ended */
  isFocused: PropTypes.bool,

  /** Sets a typical style for the input */
  type: PropTypes.string,

  /** Extra functionality for an onchange */
  extraOnchange: PropTypes.func,

  /** Indicates if the textfield contains buttons */
  hasButtons: PropTypes.bool,

  /** Behave like an textarea */
  isTextArea: PropTypes.bool,

  /** Max possible height of the text area */
  maxTextAreaHeight: PropTypes.number,

  /** Add a border radius */
  borderRadius: PropTypes.number,

  /** Indicating if there should be a scrollbar visible */
  overflowType: PropTypes.bool,

  /** Set input field to read only */
  readOnly: PropTypes.bool,

  /** Current active dashboard Id */
  activeDashboardId: PropTypes.string,

  /** Indicates the there is only one extra button */
  hasSinlgeButton: PropTypes.bool,

  /** Max height of an textarea */
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /** Max length of characters */
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /** Selection Text color */
  selectionColor: PropTypes.string,

  /** Selection background color */
  selectionBackground: PropTypes.string,
};

TextField.defaultProps = {
  onFocus: null,
  extraOnblur: null,
  disabled: false,
  error: false,
  helptext: '',
  label: '',
  backgroundcolor: null,
  textcolor: theme.color.dark[100],
  textsize: 'body',
  placeholdercolor: theme.color.dark[40],
  iconColor: theme.color.dark[40],
  icon: null,
  multiline: false,
  placeholder: '',
  value: '',
  borders: true,
  height: null,
  children: null,
  spacing: rem(12),
  labelSpacing: null,
  orientation: 'horizontal',
  centertext: false,
  id: null,
  inputId: null,
  autoComplete: 'off',
  name: 'input',
  isFocused: false,
  type: '',
  extraOnchange: null,
  hasButtons: false,
  isTextArea: false,
  maxTextAreaHeight: 120,
  borderRadius: 0,
  overflowType: false,
  readOnly: false,
  activeDashboardId: null,
  hasSinlgeButton: false,
  maxHeight: null,
  maxLength: '140',
  selectionColor: null,
  selectionBackground: null,
};

TextField.displayName = 'TextField';

/** @component */
export default TextField;
