/* eslint-disable array-callback-return */
/*eslint array-callback-return: "error"*/

import htmlToText from 'html-to-text';
import { getData } from '../../../firebase';

/** Selecting user in a modal */
const getEmailTextList = (table, listId) => {
  if (!listId) return;

  //Get List info
  const listInfo = getData(table, listId);

  //Create new tables
  const tableWrapper = document.createElement('table');
  const tableChecked = document.createElement('table');
  const tableUnChecked = document.createElement('table');

  //Create new table rows
  const tableRowWrapper = document.createElement('tr');
  const tableRowChecked = document.createElement('tr');
  const tableRowUnChecked = document.createElement('tr');

  //Create new table items
  const tableWrapperItem = document.createElement('td');
  const tableCheckedItem = document.createElement('td');
  const tableUnCheckedItem = document.createElement('td');

  const tableWrapperItemText = document.createElement('p');

  //Create new lists
  const listChecked = document.createElement('ul');
  const listUnChecked = document.createElement('ul');

  //Loop through the list items array
  if (listInfo && listInfo['items']) {
    Object.values(listInfo['items']).map(item => {
      const checked = item.checked;
      const text = item.text + '</br>';

      // Create the list item
      const entry = document.createElement('li');
      entry.appendChild(document.createTextNode(text));
      if (checked) {
        listChecked.appendChild(entry);
      } else {
        listUnChecked.appendChild(entry);
      }
      return;
    });
  }

  //Append all Checked
  tableCheckedItem.appendChild(listChecked);
  tableRowChecked.appendChild(tableCheckedItem);
  tableChecked.appendChild(tableRowChecked);

  //Append all UnChecked
  tableUnCheckedItem.appendChild(listUnChecked);
  tableRowUnChecked.appendChild(tableUnCheckedItem);
  tableUnChecked.appendChild(tableRowUnChecked);

  const textChecked = htmlToText.fromString(tableChecked.outerHTML, {
    unorderedListItemPrefix: '☑ ',
  });
  const textUnChecked = htmlToText.fromString(tableUnChecked.outerHTML, {
    unorderedListItemPrefix: listChecked.hasChildNodes() ? '☐ ' : '',
  });

  tableWrapperItemText.innerHTML = textUnChecked + '<br>' + textChecked;
  tableWrapperItem.appendChild(tableWrapperItemText);
  tableRowWrapper.appendChild(tableWrapperItem);
  tableWrapper.appendChild(tableRowWrapper);

  const final = htmlToText.fromString(tableWrapper.outerHTML);
  //Get the email
  return final;
};

/** Get the email subject based on a list */
const getEmailSubjectList = (table, listId) => {
  if (!listId) return;

  //Get List info
  const listInfo = getData(table, listId);

  //Set the data
  const title = listInfo.title;
  const subTitle = listInfo.subTitle ? ' - ' + listInfo.subTitle : '';
  return title + subTitle;
};

/** Default return function */
const basic = () => {
};

export { getEmailTextList, getEmailSubjectList };

export default basic;
