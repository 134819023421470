/* eslint-disable react/no-find-dom-node */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { modalOpen, modalType } from '../../redux/actions';
import Group from '../Group';
import Button from '../Button';
import Text from '../Text';
import Lottie from '../LottieControl';
import firebase from '../../firebase';
import theme, { rem } from '../../theme';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledTextIntro = styled(Text)`
  margin-bottom: ${rem(20)};
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(10)};
`;

const StyledContentWrapper = styled(Group)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

const StyledButtonGroup = styled(Group)`
  margin-top: ${rem(20)};

  button {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: ${rem(20)};
    width: ${rem(350)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledLoading = styled(Lottie)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

/** todo */
const logoutUser = props => {
  const { activeDashboardId, id, returnModal, closeModals } = props;

  //States
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});

  //Translation
  const [t] = useTranslation(['button', 'modal', 'common']);

  useEffect(() => {
    document.getElementById('globalModal').firstElementChild.style.height =
      'auto';
    document.getElementById('globalModal').firstElementChild.style.width =
      '25rem';
  }, []);

  /** Logout */
  const logOut = () => {
    firebase
      .auth()
      .signOut()
      .then(function() {
        // Sign-out successful.
        const url = new URL(window.location.origin);
        window.history.pushState({}, '', url);
        window.location.reload();
      });
  };

  /** Return Chat messages */
  return loading ? (
    <StyledLoading animation='loading' animationWidth={400} />
  ) : (
    <StyledWrapper>
      <StyledContentWrapper fullwidth>
        <StyledText
          align='center'
          text={t('modal:logoutuser.title')}
          textColor='#fff'
          textStyle='h3'
          tag='h1'
        />

        <StyledTextIntro
          align='center'
          text={t('modal:logoutuser.text')}
          textColor='#fff'
          textStyle='h6'
          tag='p'
        />

        <Lottie
          animation='leave'
          animationWidth={rem(150)}
          animationHeight={rem(150)}
        />

        <StyledButtonGroup fullwidth>
          <Button
            variant='contained'
            onClick={() => {
              if (activeDashboardId) logOut();
              closeModals();
            }}
            bgColor={theme.color.danger}
            text={{
              align: 'center',
              text: t('button:logout.confirm'),
              textColor: '#fff',
            }}
          />
          <Button
            variant='contained'
            text={{
              align: 'center',
              text: t('button:cancel.cancel'),
              textColor: '#fff',
            }}
            onClick={() => {
              if (returnModal) {
                props.modalOpen(true);
                props.modalType(['editRoles', activeDashboardId, id]);
              } else {
                closeModals();
              }
            }}
            bgColor={theme.color.cancel}
          />
        </StyledButtonGroup>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

logoutUser.propTypes = {
  /** Active dashboard Id */
  activeDashboardId: PropTypes.string,
  /** User Id */
  id: PropTypes.string,
  /** Return the previous modal page */
  returnModal: PropTypes.bool,
  /** Close function for the modal */
  closeModals: PropTypes.func,
};

logoutUser.defaultProps = {
  activeDashboardId: null,
  id: null,
  closeModals: null,
  returnModal: true,
};

export default connect(null, {
  modalType,
  modalOpen,
})(logoutUser);
