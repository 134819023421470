/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ReactCursorPosition, { INTERACTIONS } from 'react-cursor-position';
import { useTranslation } from 'react-i18next';
import { WebsocketProvider } from 'y-websocket';
import * as Y from 'yjs';
import { toast } from 'react-toastify';
import Audio from '../Audio';
// import io from 'socket.io-client';
import theme, { rem } from '../../theme';
import firebase, {
  updateCoordinates,
  userListner,
  userDashboardListner,
  dashboardListner,
  masterListner,
} from '../../firebase';
import Layout from '../Layout';
import CustomToast from '../CustomToast';
import Modal from '../Modal';
import TextField from '../TextField';
import Group from '../Group';
import Icon from '../Icon';
import Button from '../Button';
import InfoCard from '../InfoCard';
import Cursor from '../Cursor';
import withMemo from '../../utils/withMemo';

// const socket = io.connect('http://localhost:3001');

const StyledMain = styled.div`
  ${({ compactMenu }) => `
    box-sizing: border-box;
    padding: ${rem(15)} ${rem(25)} ${compactMenu ? rem(70) : rem(25)} ${rem(
    15
  )};
  `}
`;

const StyledGroupMessage = styled(Group)`
  display: flex;
  margin: ${rem(15)} 0 ${rem(10)};

  input {
    margin-right: 2rem;
  }
`;

const StyledSmallButton = styled(Button)`
  min-width: auto;
`;

/**
 * Return a styled component
 * @param {object} children
 */
const Dashboard = props => {
  const { widgetData, activeDashboardId, currentUser, isPayedUser } = props;
  const [name, setName] = useState('');
  const [compactMenuPermission, setCompactMenuPermission] = useState();
  const [yjs, setYjs] = useState();
  const ModalOpen = useSelector(state => state.modalopen.modalOpen);
  const resetCursorPosition = useCallback(() => {}, []);
  const [showCursors, setShowCursors] = useState();
  const [playSound, setPlaySound] = useState(false);
  const uuid = firebase.auth().currentUser.uid;

  //Refs
  const messageRef = useRef();

  //Translation
  const [t] = useTranslation(['textfield']);

  useEffect(() => {
    if (yjs) {
      yjs.observeDeep(event => {
        yjs.forEach(function(cursorElement, key) {
          let yjsCoords = yjs.get(key);
          let d = document.getElementById('cursor' + key);
          if (d) {
            d.style.left = yjsCoords.x + '%';
            d.style.top = yjsCoords.y + '%';
          }
        });
      });
    }
  }, [yjs]);

  useEffect(() => {
    // userListner
    //   .child(uuid)
    //   .child('public')
    //   .child('name')
    //   .once('value', snap => {
    //     setName(snap.val());
    //   });
    const doc = new Y.Doc();
    //TODO change 1234 with actually board Id
    const provider = new WebsocketProvider(
      'wss://pointer.studentsplus.nl/1234/',
      activeDashboardId,
      doc
    );
    const drawingContent = doc.getMap('cursor');
    //checkpoint array to map
    // const requestAnimationFrame = window.requestAnimationFrame || setTimeout
    setYjs(drawingContent);

    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('settings')
      .child('cursors')
      .on('value', snap => {
        setShowCursors(snap.val());
      });

    //SetPermissions
    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('permissions')
      .child('compactmenu')
      .on('value', snap => {
        if (snap.exists()) setCompactMenuPermission(snap.val());
      });

    masterListner
      .child('members')
      .once('value', snapMaster => {
        if (snapMaster.exists()) {
          snapMaster.forEach(function(snapMasterChild) {
            if (snapMasterChild.key === uuid) {
              setCompactMenuPermission(true);
            }
          });
        }
      })
      .then(() => {
        userListner.child(uuid).once('value', snapIsso => {
          if (snapIsso.exists()) {
            if (snapIsso.val().isSingleSignOnUser)
              setCompactMenuPermission(true);
          }
        });
      });

    dashboardListner
      .child(activeDashboardId)
      .child('members')
      .on('child_removed', snap => {
        if (uuid === snap.key) {
          userDashboardListner
            .child(uuid)
            .child(activeDashboardId)
            .remove()
            .then(() => {
              window.location.reload();
            });
        }
      });

    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('temp')
      .child('message')
      .on('value', snapShot => {
        if (snapShot.exists()) {
          CustomToast.default(handleMessage(snapShot.val()));
        }
      });
  }, []);

  /** Hanlde short message */
  const handleMessage = data => {
    let shortmessage = data.text;

    userListner
      .child(data.user)
      .child('public')
      .once('value', snapshot => {
        setPlaySound(true);
        shortmessage = (
          <React.Fragment>
            <InfoCard
              title={snapshot.val().name + ' ' + t('textfield:shorttext.label')}
              subTitle={data.text}
              ellipsis={false}
              textStyle='description'
              subTextStyle='body'
              avatar={{
                title: snapshot.val().name,
                source: snapshot.val().avatar.cropped,
              }}
            />
            <StyledGroupMessage fullwidth>
              <TextField
                placeholdercolor={theme.color.dark[60]}
                hasButtons
                backgroundcolor={theme.color.dark[10]}
                textcolor='#000'
                inputId='modalTitle'
                placeholder={t('button:create.message')}
                borders={false}
                value={messageRef.current && messageRef.current.value}
                ref={messageRef}
                onKeyDown={event => handleKeydown(event, data.user)}
              />
              <Group type='inputOption'>
                <StyledSmallButton
                  variant='icon'
                  onClick={() => handleSend(data.user)}
                >
                  <Icon
                    icon='send'
                    padding={rem(6)}
                    size={16}
                    color={theme.color.dark[80]}
                  />
                </StyledSmallButton>
              </Group>
            </StyledGroupMessage>
          </React.Fragment>
        );
      });

    return shortmessage;
  };


  /** Update coordinates */
  const getCursorData = cursorData => {
    const element = document.getElementById('dashboardContent');
    const dashboardWidth = window.innerWidth;
    const dashboardHeight = window.innerHeight;
    const xPercent = ((cursorData.position.x + 10) / dashboardWidth) * 100;
    const yPercent = ((cursorData.position.y + 10) / dashboardHeight) * 100;
    // const drawElement = new Y.Map();
    // drawElement.set('user', uuid);
    // drawElement.set('coords', {x: xPercent, y: yPercent});
    yjs.set(uuid, { x: xPercent, y: yPercent });
    // socket.emit('cursorChange', {
    //   xPercent,
    //   yPercent,
    //   activeDashboardId,
    //   uuid,
    // });
    // if (!element.classList.contains('removing'))
    //   updateCoordinates(xPercent, yPercent, activeDashboardId);
  };

  /** handleKeydown */
  const handleKeydown = (event, member) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      handleSend(member);
    }
  };

  /** Handle short message */
  const handleSend = member => {
    const message = messageRef.current ? messageRef.current.value : '';
    if (message.trim() === '') return;
    userDashboardListner
      .child(member)
      .child(activeDashboardId)
      .update({ temp: { message: { text: message, user: uuid } } })
      .then(() => {
        toast.dismiss();
        setTimeout(function() {
          userDashboardListner
            .child(member)
            .child(activeDashboardId)
            .child('temp')
            .remove();
        }, 2000);
      });

    messageRef.current.value = '';
  };

  return activeDashboardId ? (
    <React.Fragment>
      <Audio
        url='/resources/sounds/chat.mp3'
        status={playSound ? 'PLAYING' : 'STOPPED'}
        finished={() => {
          setPlaySound(false);
        }}
      />
      <StyledMain
        id='dashboardContent'
        role='main'
        compactMenu={compactMenuPermission}
      >
        <ReactCursorPosition
          activationInteractionMouse={INTERACTIONS.HOVER} //default
          hoverDelayInMs={250} //default: 0
          hoverOffDelayInMs={150} //default: 0
          className='HALLABOLLOO'
          onPositionChanged={getCursorData}
          isPositionOutside
          //isEnabled={false}
        >
          {showCursors && (
            <Cursor
              activeDashboardId={activeDashboardId}
              callback={resetCursorPosition}
            />
          )}
          <Modal
            id='globalModal'
            isOpen={ModalOpen}
            closeModal={!ModalOpen}
            currentUser={currentUser}
            activeDashboardId={activeDashboardId}
          />
          <Layout
            activeDashboardId={activeDashboardId}
            widgetData={widgetData}
            isPayedUser={isPayedUser}
            widgetDataString={JSON.stringify(widgetData)}
          />
        </ReactCursorPosition>
      </StyledMain>
    </React.Fragment>
  ) : (
    <div>Loading dashboard</div>
  );
};

Dashboard.propTypes = {
  /** The current user */
  currentUser: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

Dashboard.defaultProps = {
  currentUser: null,
};

/** @component */
//export default Dashboard;
export default withMemo(Dashboard, ['activeDashboardId']);
