import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { modalOpen, modalType } from '../../redux/actions';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Group from '../Group';
import Button from '../Button';
import Badge from '../Badge';
import TextField from '../TextField';
import Image from '../Image';
import Text from '../Text';
import ColorPicker from '../ColorPicker';
import Switch from '../Switch';
import Lottie from '../LottieControl';
import Grid from '../Grid';
import firebase, {
  addNotification,
  dashboardListner,
  userDashboardListner,
  userListner,
} from '../../firebase';
import Animation from '../LottieControl';
import theme, { rem } from '../../theme';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(10)};
`;

const StyledTitle = styled(Text)`
  width: auto;
`;

const StypedTitleWrapper = styled(Group)`
  min-height: ${rem(35)};
`;

const StyledContentWrapper = styled(Group)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledButtonGroup = styled(Group)`
  justify-content: flex-end;

  button {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: ${rem(20)};
    width: ${rem(350)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledGroup = styled(Group)`
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${rem(25)};
`;

const StyledRoleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`;

const StyledCard = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: left;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: ${rem(20)};
  background: rgba(0, 0, 0, 0.3);
  border-radius: ${rem(10)};
  margin-bottom: ${rem(15)};
`;

const StyledBadgeWrapper = styled.div`
  display: inline-flex;
  min-height: ${rem(30)};
`;

const StyledBadge = styled(Badge)`
  margin-right: ${rem(5)};

  &:last-child {
    margin-right: 0;
  }
`;

const StyledOptionsWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

const StyledEmptyText = styled(Text)`
  font-style: italic;
  opacity: 0.5;
`;

const StyledSwitchWrapper = styled(Group)`
  margin-bottom: ${rem(20)};
  align-items: flex-start;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${rem(20)};
  width: ${rem(250)};
`;

const StyledTooltip = styled(ReactTooltip)`
  border-radius: ${rem(5)} !important;
  padding: ${rem(5)} ${rem(10)} !important;
`;

const StyledToolTipText = styled(Text)`
  margin-bottom: ${rem(15)};
  width: 100%;
`;

const StyledErrorWrapper = styled.div``;

const StyledToolTipAnimation = styled(Lottie)`
  margin-bottom: ${rem(15)};
`;

/** todo */
const createRole = props => {
  const { reference, activeDashboardId, userId, closeModals } = props;

  //The current userId
  let uuid = firebase.auth().currentUser.uid;

  //States
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [dashboardRoleData, setDashboardRoleData] = useState({});
  const [userDashboardRoleData, setUserDashboardRoleData] = useState({});
  const [color, setColor] = useState(theme.color.primary[100]);
  const [resizeRule, setResizeRule] = useState(false);
  const [dragRule, setDragRule] = useState(false);
  const [subItemsRule, setSubItemRule] = useState(false);
  const [menuItemRule, setMenuItemRule] = useState(false);
  const [storeRule, setStoreRule] = useState(false);
  const [compactMenuRule, setCompactMenuRule] = useState(false);
  const [inviteRule, setInviteRule] = useState(false);
  const [dashboardDetailsRule, setDashboardDetailsRule] = useState(false);

  const [noTitle, setNoTitle] = useState(false);

  //Refs
  const titleRef = useRef();

  useEffect(() => {
    getDashboardData();
    document.getElementById('globalModal').firstElementChild.style.height =
      '47rem';
    document.getElementById('globalModal').firstElementChild.style.width =
      '40rem';
  }, []);

  /** Get all the dashboard data */
  const getDashboardData = () => {
    let dataObject = {};
    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('role')
      .child('type')
      .once('value', snapusershot => {
        if (snapusershot.val()) {
          setUserDashboardRoleData(snapusershot.val());
        }
      })
      .then(() => {
        dashboardListner
          .child(activeDashboardId)
          .child('roles')
          .once('value', snapshot => {
            dataObject = snapshot.val();
          })
          .then(() => {
            setDashboardRoleData(dataObject);
          })
          .then(() => {
            userListner
              .child(uuid)
              .child('public')
              .once('value', snap => {
                setUserData(snap.val());
                setLoading(false);
              });
          });
      });
  };

  /** handle close */
  const handleClose = () => {
    if (reference) {
      props.modalOpen(true);
      props.modalType([reference, activeDashboardId, userId]);
    } else {
      closeModals();
    }
  };

  /** Handle the applied changes */
  const handleCreate = () => {
    if (!titleRef.current.value) {
      setNoTitle(true);
    } else {
      dashboardListner
        .child(activeDashboardId)
        .child('roles')
        .update({
          [titleRef.current.value]: {
            id: Date.now(),
            details: {
              color: color,
            },
            permissions: {
              menuitem: menuItemRule,
              compactmenu: compactMenuRule,
              resize: resizeRule,
              drag: dragRule,
              subitems: subItemsRule,
              store: storeRule,
              invite: inviteRule,
              details: dashboardDetailsRule,
            },
          },
        })
        .then(() => {
          if (reference) {
            props.modalOpen(true);
            props.modalType([reference, activeDashboardId, userId]);
          } else {
            closeModals();
          }
        });
    }
  };

  /** Handle on change */
  const handleColorChange = useCallback(id => {
    setColor(id.hex);
  }, []);

  /** Handle on focus check see if there is an error if so remove */
  const handleFocus = () => {
    if (noTitle) setNoTitle(false);
  };

  /** Return Chat messages */
  return loading ? (
    <Lottie animation='loading' animationWidth={400} />
  ) : (
    <StyledWrapper>
      <StyledContentWrapper fullwidth>
        <StyledText
          align='center'
          text={`Create a new dashboard role`}
          textColor='#fff'
          textStyle='h3'
          tag='h1'
        />
        <StyledRoleWrapper>
          <StyledGroup fullwidth>
            <StypedTitleWrapper>
              <StyledTitle
                align='left'
                text='Name'
                textColor='#fff'
                textStyle='h6'
              />
              {noTitle && (
                <StyledErrorWrapper>
                  <Animation
                    animation='alert'
                    animationHeight={30}
                    animationWidth={30}
                    data-for='errorTitle'
                    data-tip=''
                  />
                  <StyledTooltip
                    id='errorTitle'
                    getContent={() => {
                      return (
                        <StyledTooltipWrapper>
                          <StyledToolTipText
                            text='A name is required for adding a new role'
                            textStyle='h6'
                            textColor='#fff'
                            align='center'
                          />
                          <StyledToolTipAnimation
                            animation='cry'
                            animationWidth={rem(100)}
                            animationHeight={rem(100)}
                          />
                        </StyledTooltipWrapper>
                      );
                    }}
                  />
                </StyledErrorWrapper>
              )}
            </StypedTitleWrapper>
            <TextField
              placeholdercolor='gba(255, 255, 255, 0.5)'
              backgroundcolor='rgba(0, 0, 0, 0.3)'
              isFocused
              textcolor='#fff'
              inputId='modalTitle'
              placeholder='What is the name of your role?'
              borders={false}
              value={null}
              ref={titleRef}
              onFocus={() => handleFocus()}
            />
          </StyledGroup>
          <StyledGroup>
            <StyledText
              align='left'
              text='Color'
              textColor='#fff'
              textStyle='h6'
            />
            <ColorPicker
              activeDashboardId={activeDashboardId}
              type='dynamic'
              centeredPopOver
              hideLable
              changeComplete={() => handleColorChange}
            />
          </StyledGroup>
        </StyledRoleWrapper>
        <StyledText
          align='left'
          text='Permissions'
          textColor='#fff'
          textStyle='h6'
        />
        <StyledCard>
          <Grid columns='1fr'>
            <StyledOptionsWrapper>
              <StyledSwitchWrapper>
                <Group fullwidth>
                  <Text
                    align='left'
                    text='Administrator menu'
                    textColor='#fff'
                    textStyle='h6'
                  />
                  <Text
                    align='left'
                    text='Members can manage, assign and create roles for every member'
                    textColor='rgba(255, 255, 255, 0.7)'
                  />
                </Group>
                <Switch
                  name='menuItemRuleSwitch'
                  value='menuItemRuleSwitch'
                  label=''
                  checked={menuItemRule}
                  onClick={() => setMenuItemRule(!menuItemRule)}
                  fullWidth={false}
                  sliderBackground={theme.color.light[40]}
                />
              </StyledSwitchWrapper>
              <StyledSwitchWrapper>
                <Group fullwidth>
                  <Text
                    align='left'
                    text='Compact menu'
                    textColor='#fff'
                    textStyle='h6'
                  />
                  <Text
                    align='left'
                    text='Give the user access to a small menu at the bottom of the page where you can manage members and dashboards.'
                    textColor='rgba(255, 255, 255, 0.7)'
                  />
                </Group>
                <Switch
                  name='compactMenuRuleSwitch'
                  value='compactMenuRuleSwitch'
                  label=''
                  checked={compactMenuRule}
                  onClick={() => setCompactMenuRule(!compactMenuRule)}
                  fullWidth={false}
                  sliderBackground={theme.color.light[40]}
                />
              </StyledSwitchWrapper>
              <StyledSwitchWrapper>
                <Group fullwidth>
                  <Text
                    align='left'
                    text='Widget store'
                    textColor='#fff'
                    textStyle='h6'
                  />
                  <Text
                    align='left'
                    text='Members can add widget from the store. This can effect the layout of the dashboard.'
                    textColor='rgba(255, 255, 255, 0.7)'
                  />
                </Group>
                <Switch
                  name='storeRuleSwitch'
                  value='storeRuleSwitch'
                  label=''
                  checked={storeRule}
                  onClick={() => setStoreRule(!storeRule)}
                  fullWidth={false}
                  sliderBackground={theme.color.light[40]}
                />
              </StyledSwitchWrapper>
              <StyledSwitchWrapper>
                <Group fullwidth>
                  <Text
                    align='left'
                    text='Resize widgets'
                    textColor='#fff'
                    textStyle='h6'
                  />
                  <Text
                    align='left'
                    text='Resizing of the widgets can result in layout changes'
                    textColor='rgba(255, 255, 255, 0.7)'
                  />
                </Group>
                <Switch
                  name='resizeRuleSwitch'
                  value='resizeRuleSwitch'
                  label=''
                  checked={resizeRule}
                  onClick={() => setResizeRule(!resizeRule)}
                  fullWidth={false}
                  sliderBackground={theme.color.light[40]}
                />
              </StyledSwitchWrapper>
              <StyledSwitchWrapper>
                <Group fullwidth>
                  <Text
                    align='left'
                    text='Drag widgets'
                    textColor='#fff'
                    textStyle='h6'
                  />
                  <Text
                    align='left'
                    text='Dragging of widgets is realtime so everybody will see this happen'
                    textColor='rgba(255, 255, 255, 0.7)'
                  />
                </Group>
                <Switch
                  name='dragRuleSwitch'
                  value='dragRuleSwitch'
                  label=''
                  checked={dragRule}
                  onClick={() => setDragRule(!dragRule)}
                  fullWidth={false}
                  sliderBackground={theme.color.light[40]}
                />
              </StyledSwitchWrapper>
              <StyledSwitchWrapper>
                <Group fullwidth>
                  <Text
                    align='left'
                    text='Create widget subitems'
                    textColor='#fff'
                    textStyle='h6'
                  />
                  <Text
                    align='left'
                    text='Widgets can have multipe subitems, so members with this role can also create private items in the widget'
                    textColor='rgba(255, 255, 255, 0.7)'
                  />
                </Group>
                <Switch
                  name='inviteRuleSwitch'
                  value='inviteRuleSwitch'
                  label=''
                  checked={subItemsRule}
                  onClick={() => setSubItemRule(!subItemsRule)}
                  fullWidth={false}
                  sliderBackground={theme.color.light[40]}
                />
              </StyledSwitchWrapper>
              <StyledSwitchWrapper>
                <Group fullwidth>
                  <Text
                    align='left'
                    text='Dashboard details'
                    textColor='#fff'
                    textStyle='h6'
                  />
                  <Text
                    align='left'
                    text='Members can edit the name, description and image of the dashboard'
                    textColor='rgba(255, 255, 255, 0.7)'
                  />
                </Group>
                <Switch
                  name='dashboardDetailsRuleSwitch'
                  value='dashboardDetailsRuleSwitch'
                  label=''
                  checked={dashboardDetailsRule}
                  onClick={() => setDashboardDetailsRule(!dashboardDetailsRule)}
                  fullWidth={false}
                  sliderBackground={theme.color.light[40]}
                />
              </StyledSwitchWrapper>
              <StyledSwitchWrapper>
                <Group fullwidth>
                  <Text
                    align='left'
                    text='Send dashboard invites'
                    textColor='#fff'
                    textStyle='h6'
                  />
                  <Text
                    align='left'
                    text='When activated members can invite other users to this dashboard'
                    textColor='rgba(255, 255, 255, 0.7)'
                  />
                </Group>
                <Switch
                  name='inviteRuleSwitch'
                  value='inviteRuleSwitch'
                  label=''
                  checked={inviteRule}
                  onClick={() => setInviteRule(!inviteRule)}
                  fullWidth={false}
                  sliderBackground={theme.color.light[40]}
                />
              </StyledSwitchWrapper>
            </StyledOptionsWrapper>
          </Grid>
        </StyledCard>

        <StyledButtonGroup fullwidth>
          <Button
            variant='contained'
            text={{
              align: 'center',
              text: 'Create',
              textColor: '#fff',
            }}
            onClick={() => {
              handleCreate();
            }}
            bgColor={theme.color.success}
          />
          <Button
            variant='contained'
            onClick={() => {
              handleClose();
            }}
            bgColor={theme.color.cancel}
            text={{
              align: 'center',
              text: 'Cancel',
              textColor: '#fff',
            }}
          />
        </StyledButtonGroup>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

createRole.propTypes = {
  /** Reference from another modal */
  reference: PropTypes.string,
  /** Optional userId from open modal reference */
  userId: PropTypes.string,
  /** Selected dashboard */
  dashboardId: PropTypes.string,
  /** Close function for the modal */
  closeModals: PropTypes.func,
};

createRole.defaultProps = {
  reference: null,
  userId: null,
  dashboardId: null,
  closeModals: null,
};

export default connect(null, {
  modalType,
  modalOpen,
})(createRole);
