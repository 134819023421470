import { getData } from '../../../firebase';

/** Selecting user in a modal */
const getText = (table, listId) => {
  if (!listId) return;
  if (!listInfo) return;

  //Get List info
  const listInfo = getData(table, listId);
  let checkedText = '';
  let unCheckedText = '';

  //Loop through the list items array
  Object.values(listInfo['items']).map(item => {
    const checked = item.checked;

    if (checked) {
      checkedText += '%5B%58%5D%20' + item.text + '%0A';
    } else {
      unCheckedText += '%5B%20%5D%20' + item.text + '%0A';
    }
    return true;
  });
  return unCheckedText + '%0A' + checkedText;
};

/** Default return function */
const basic = () => {
};

export { getText };

export default basic;
