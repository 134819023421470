/* eslint-disable array-callback-return */
/*eslint array-callback-return: "error"*/

import htmlToText from 'html-to-text';
import { getData, getAllData } from '../../../../firebase';
import { getHTML } from './getHTMLFromNote';

/** Selecting user in a modal */
const getEmailTextList = (type, typeId) => {
  if (!typeId) return;

  //Get List info
  const listInfo = getData(type, typeId);

  //Create new tables
  const tableWrapper = document.createElement('table');
  const tableChecked = document.createElement('table');
  const tableUnChecked = document.createElement('table');

  //Create new table rows
  const tableRowWrapper = document.createElement('tr');
  const tableRowChecked = document.createElement('tr');
  const tableRowUnChecked = document.createElement('tr');

  //Create new table items
  const tableWrapperItem = document.createElement('td');
  const tableCheckedItem = document.createElement('td');
  const tableUnCheckedItem = document.createElement('td');

  const tableWrapperItemText = document.createElement('p');

  if (type === 'lists') {
    //Create new lists
    const listChecked = document.createElement('ul');
    const listUnChecked = document.createElement('ul');

    //Loop through the list items array
    if (listInfo && listInfo['items']) {
      Object.values(listInfo['items']).map(item => {
        const checked = item.checked;
        const text = item.text + '</br>';

        // Create the list item
        const entry = document.createElement('li');
        entry.appendChild(document.createTextNode(text));
        if (checked) {
          listChecked.appendChild(entry);
        } else {
          listUnChecked.appendChild(entry);
        }
        return;
      });
    }

    //Append all Checked
    tableCheckedItem.appendChild(listChecked);
    tableRowChecked.appendChild(tableCheckedItem);
    tableChecked.appendChild(tableRowChecked);

    //Append all UnChecked
    tableUnCheckedItem.appendChild(listUnChecked);
    tableRowUnChecked.appendChild(tableUnCheckedItem);
    tableUnChecked.appendChild(tableRowUnChecked);

    const textChecked = htmlToText.fromString(tableChecked.outerHTML, {
      unorderedListItemPrefix: '☑ ',
    });
    const textUnChecked = htmlToText.fromString(tableUnChecked.outerHTML, {
      unorderedListItemPrefix: listChecked.hasChildNodes() ? '☐ ' : '',
    });

    tableWrapperItemText.innerHTML = textUnChecked + '<br>' + textChecked;
  }

  if (type === 'notes') {
    tableWrapperItemText.innerHTML = getHTML(type, typeId);
  }

  tableWrapperItem.appendChild(tableWrapperItemText);
  tableRowWrapper.appendChild(tableWrapperItem);
  tableWrapper.appendChild(tableRowWrapper);

  const final = htmlToText.fromString(tableWrapper.outerHTML);
  //Get the email
  return final;
};

/** Get the email subject based on a list */
const getEmailSubjectList = (type, typeId) => {
  if (!typeId) return;

  //Get List info
  const listInfo = getAllData(type, typeId);

  //Set the data
  let title = listInfo.title;

  if (!title || title === '')
    title = 'Hi there! StudentsPlus online dashboard wants to share with you!';
  return title;
};

/** Default return function */
const basic = () => {};

export { getEmailTextList, getEmailSubjectList };

export default basic;
