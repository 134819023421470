/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import theme, { rem } from '../theme';
import Dashboard from './Dashboard';
import Tour from './Intro';
import firebase, {
  userListner,
  dashboardListner,
  userDashboardListner,
  masterListner,
} from '../firebase';
import {
  storeWidgetDrag,
  storeWidgetDelete,
  cardItemIsDragging,
} from '../redux/actions';
import { updateCursorEvent } from './Cursor';
import Button from './Button';
import Group from './Group';
import Icon from './Icon';
import Menu from './Menu';
// import Content from './Content';
import Notifications from './Notifications';
import Resizable from './Resizable';
import Bubble from './Bubble';
import Tooltip from './Tooltip';

const Content = lazy(() => import('./Content'));

const StyledWrapper = styled.div`
  display: grid;
  min-height: 100%;
  grid-template-areas: 'header' 'main';
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  grid-row-gap: 0;
`;

const StyledGroup = styled(Group)`
  display: block;
  width: 100vw;
  display: flex;
  justify-content: center;
  height: ${rem(10)};
  position: fixed;
  bottom: 0;
`;

const StyledBackgroundImage = styled.div`
  background-color: ${props => props.appBackgroundColor};
  background-image: url(${props => props.isPayedUser});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -1;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  opacity: 1;

  &.loading {
    opacity: 0;
  }
`;

const StyledResizeGroup = styled(Group)`
  display: ${props => (props.show ? 'none' : 'block')};
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${props => (props.showUp ? rem(50) : '0')};
  width: ${rem(60)};
  height: ${rem(57)};

  &:hover {
    margin-bottom: ${rem(50)};
  }
`;

const BubbleWrapper = styled.div``;

/**
 * Return default App
 */
const App = props => {
  const { widgetData, activeDashboardId } = props;
  const [notification, setNotification] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState();
  const [appBackground, setAppBackground] = useState();
  const [isPayedUser, setIsPayedUser] = useState();
  const [isGhostUser, setIsGhostUser] = useState();
  const [isMaster, setIsMaster] = useState(false);
  const [showUp, setShowUp] = useState(false);
  const [firstTime, setFirstTime] = useState();
  const [startTour, setStartTour] = useState();
  const [timeoutHandle, setTimeoutHandle] = useState();
  const [forcedIndicator, setForcedIndicator] = useState(false);
  const userId = firebase.auth().currentUser.uid;
  const NotificationType = useSelector(
    state => state.notificationtype.notificationType
  );

  //Refs
  const timeOutRef = useRef();

  //Translation
  const [t] = useTranslation(['modal']);

  /**
   * componentDidMount
   */
  //check component did unmount
  useEffect(() => {
    settingsListner();
  }, []);

  useEffect(() => {
    if (firstTime) {
      setTimeout(function() {
        setStartTour(true);
      }, 1000);
    }
  }, [firstTime]);

  /** settingsListner */
  const settingsListner = () => {
    const userIndicatorRef = userListner
      .child(userId)
      .child('public')
      .child('indicator');
    const userIsGhostRef = userListner.child(userId).child('isGhostUser');
    const userForcedIndicatorRef = userListner
      .child(userId)
      .child('public')
      .child('forcedIndicator');
    const connectedRef = firebase.database().ref('.info/connected');
    const dashboardColorlistner = userDashboardListner
      .child(userId)
      .child(activeDashboardId)
      .child('colors')
      .child('backgroundColor');
    const isPayedUserListner = userListner.child(userId).child('isPayedUser');
    const firstLogin = userListner.child(userId).child('isFirstTimeUser');
    const appBackgroundListner = userDashboardListner
      .child(userId)
      .child(activeDashboardId)
      .child('backgroundimage')
      .child('cropped');

    userForcedIndicatorRef.on('value', snap => {
      if (snap.val() && snap.val() !== 'online') {
        setForcedIndicator(true);
      } else {
        setForcedIndicator(false);
      }
    });

    userIsGhostRef.on('value', snap => {
      if (snap.val() && snap.val() !== false) {
        setIsGhostUser(true);
      } else {
        setIsGhostUser(false);
      }
    });

    connectedRef.on('value', snap => {
      if (snap.val() === true) {
        userIndicatorRef.set('online');
      } else {
        userIndicatorRef.set('offline');
      }
    });
    userIndicatorRef
      .onDisconnect()
      .set('offline')
      .then(() => {
        userForcedIndicatorRef.remove();
        setForcedIndicator(false);
      });

    dashboardColorlistner.on('value', snap => {
      setBackgroundColor(snap.val());
    });

    isPayedUserListner.on('value', snap => {
      setIsPayedUser(snap.val());
    });

    firstLogin.on('value', snap => {
      setFirstTime(snap.val());
    });

    masterListner.once('value', snapshot => {
      if (snapshot.exists() && snapshot.val().members) {
        Object.keys(snapshot.val().members).map(member => {
          if (member === userId) {
            setIsMaster(true);
          }
        });
      }

      if (
        snapshot.exists() &&
        snapshot.val().dashboard &&
        snapshot.val().backgroundimage &&
        snapshot.val().dashboard.id === activeDashboardId
      ) {
        setAppBackground(snapshot.val().backgroundimage.cropped);
      } else {
        appBackgroundListner.on('value', snap => {
          if (snap.exists() && snap.val() !== '') {
            setAppBackground(snap.val());
          } else {
            masterListner
              .child('backgroundimage')
              .child('cropped')
              .once('value', snapshotMaster => {
                if (snapshotMaster.exists()) {
                  setAppBackground(snapshotMaster.val());
                } else {
                  setAppBackground('');
                }
              });
          }
        });
      }
    });
  };

  /** Handle click */
  const handleClick = () => {
    if (!props.storeDrag) updateCursorEvent(userId, activeDashboardId, 'click');
  };

  /** Timer function */
  let timeoutHandleFunction = () => {
    timeOutRef.current.value = setTimeout(function() {
      userListner
        .child(userId)
        .child('public')
        .child('forcedIndicator')
        .once('value', snapshot => {
          if (!snapshot.exists() || snapshot.val() === 'online') {
            userListner
              .child(userId)
              .child('public')
              .update({ indicator: 'away' });
          }
        });
    }, 300000);
  };

  /** Activity handler */
  const handleActivity = () => {
    if (!forcedIndicator) {
      //Handle timer
      if (timeOutRef.current.value) {
        clearTimeout(timeOutRef.current.value);
        timeOutRef.current.value = null;

        userListner
          .child(userId)
          .child('public')
          .update({ indicator: 'online' });
      }
      timeoutHandleFunction();
    }
  };

  /** mouse ennter handler */
  const mouseEnterHandler = () => {
    const { storeWidgetDelete, widgetDrag } = props;
    setShowUp(true);
    if (widgetDrag) {
      storeWidgetDelete(true);
    }
  };

  /** mouse leave handler */
  const mouseLeaveHandler = () => {
    const { storeWidgetDelete } = props;
    setShowUp(false);
    storeWidgetDelete(false);
  };

  //forced Indicator change
  useEffect(() => {
    if (!forcedIndicator) handleActivity();
  }, [forcedIndicator]);

  //Nofification trigger
  useEffect(() => {
    /** handleWidgets */
    setNotification(NotificationType);
  }, [NotificationType]);

  //Nofification trigger
  useEffect(() => {
    /** handleWidgets */
    if (!props.storeDelete) {
      setShowUp(false);
    }
  }, [props.storeDelete]);

  /** Render loader */
  const renderLoader = () => <React.Fragment />;

  /** Handle on element drop */
  const handleDrop = () => {
    const { storeDrag, storeWidgetDrag } = props;

    if (storeDrag) {
      storeWidgetDrag(false);
    }
  };

  /**
   * Returns the App
   */
  return (
    <StyledWrapper
      ref={timeOutRef}
      onMouseUp={handleClick}
      onMouseMove={handleActivity}
      ontouchstart={handleActivity}
      onMouseEnter={handleActivity}
      onKeyPress={handleActivity}
      onscroll={handleActivity}
    >
      <Helmet>
        <meta charSet='utf-8' />
        <meta property='og:image' content='/resources/logo-1200x630.jpeg' />
        <meta property='og:title' content="Let's learn Today" />
        <meta
          property='og:description'
          content='Check out my awesome StudentsPlus online dashboard'
        />
        <meta property='og:url' content='https://www.studentsplus.com/' />
        <title>StudentsPlus Online Dashboard</title>
        <link
          rel='shortcut icon'
          href='/resources/favicon.ico'
          type='image/x-icon'
        />
        <link rel='canonical' href='https://www.studentsplus.com' />
      </Helmet>
      <React.Fragment>
        <Suspense fallback={renderLoader()}>
          <StyledBackgroundImage
            appBackgroundColor={isPayedUser ? backgroundColor : '#fff'}
            isPayedUser={isPayedUser && appBackground ? appBackground : null}
            id='preview-previewBackgroundImage'
          />
          <Notifications content={notification} />
          {isMaster && <Menu activeDashboardId={activeDashboardId} />}
          <Content bgImage='' opacity={0.7}>
            <Dashboard
              widgetData={widgetData}
              activeDashboardId={activeDashboardId}
              isPayedUser={isPayedUser}
            />
          </Content>
        </Suspense>

        {(props.widgetDrag || props.storeDrag) && (
          <StyledGroup>
            <StyledButton
              onDrop={() => handleDrop()}
              showUp={showUp || props.storeDrag ? true : false}
              variant='icon'
              bgColor={
                props.storeDrag ? theme.color.dark[80] : theme.color.danger
              }
              onMouseEnter={() =>
                mouseEnterHandler(props.storeDrag ? 'cancel' : 'delete')
              }
              onMouseLeave={() =>
                mouseLeaveHandler(props.storeDrag ? 'cancel' : 'delete')
              }
              onClick={() => {}}
            >
              <Icon
                icon={props.storeDrag ? 'cancel' : 'delete'}
                color='#fff'
                size={32}
                id='dragActionButton'
              />
            </StyledButton>
          </StyledGroup>
        )}
        <StyledResizeGroup
          show={props.widgetDrag || props.storeDrag ? true : false}
        >
          <Resizable
            activeDashboardId={activeDashboardId}
            widgetData={widgetData}
          />
        </StyledResizeGroup>
      </React.Fragment>
      {firstTime && startTour && (
        <Tour
          start={startTour}
          tutNumber={firstTime && 0}
          activeDashboardId={activeDashboardId}
        />
      )}
    </StyledWrapper>
  );
};

App.propTypes = {
  /** HeaderColor */
  headerColor: PropTypes.string,
  /** Indicates if the sidebar should be collapsble */
  extendedSidebar: PropTypes.bool,
};

App.defaultProps = {
  headerColor: null,
  extendedSidebar: false,
};

/** pass currentUser and currentChannel from redux to global props */
const mapStateToProps = state => ({
  widgetDrag: state.cardisdragging.cardisDragging,
  storeDrag: state.storewidgetdrag.storeWidgetDrag,
  storeDelete: state.storewidgetdelete.storeWidgetDelete,
});

/** @component */
export default connect(mapStateToProps, {
  cardItemIsDragging,
  storeWidgetDrag,
  storeWidgetDelete,
})(App);
