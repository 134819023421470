import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { modalType, modalOpen } from '../../redux/actions';
import Group from '../Group';
import Button from '../Button';
import Animation from '../LottieControl';
import Image from '../Image';
import Text from '../Text';
import TextField from '../TextField';
import firebase, { signInWithGoogle } from '../../firebase';
import theme, { rem } from '../../theme';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(10)};
`;

const StyledContentWrapper = styled(Group)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledGroup = styled(Group)`
  flex-direction: column;
  margin-bottom: ${rem(10)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledButton = styled(Button)`
  margin: ${rem(20)} 0;
`;

const StyledGoogleButton = styled(Button)`
  margin: ${rem(20)} 0;
  font-weight: bold;
  font-size: 0.7rem;
`;

const StyledInlineGroup = styled(Group)`
  display: inline-flex;
`;

const StyledErrorText = styled(Text)`
  background-color: salmon;
  color: darkred;
  padding: 8px;
  border-radius: 2px;
  border: 1px solid darkred;
  margin-top: 7px;
`;

const StyledGoogleIcon = styled(Image)`
  position: absolute;
  width: 1.7rem;
  left: 4.5rem;
`;

/** todo */
const login = props => {
  //refs
  const emailRef = useRef();
  const passwordRef = useRef();

  //states
  const [errorMessage, setErrorMessage] = useState('');

  /**
   * onSubmit login
   */
  const onSubmit = (email, password) => {
    setErrorMessage('');
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        props.modalOpen(false);
      })
      .catch(function(error) {
        setErrorMessage(error.message);
      });
  };

  /** sign in with google */
  const loginWithGoogle = () => {
    signInWithGoogle();
  };

  /** Return Chat messages */
  return (
    <StyledWrapper>
      <StyledContentWrapper fullwidth>
        <StyledText
          align="center"
          text="Welcome back!"
          textColor="#fff"
          textStyle="h3"
          tag="h1"
        />

        <StyledText
          align="center"
          text="Nice to see you again!"
          textColor={theme.color.light[80]}
          tag="p"
        />

        <Animation
          animation="login"
          animationHeight={rem(150)}
          animationWidth={rem(150)}
        />

        <StyledGroup fullwidth>
          <Text
            align="left"
            text="Email"
            textStyle="description"
            textColor="#fff"
          />
          <TextField
            placeholdercolor="#fff"
            backgroundcolor={theme.color.dark[40]}
            isFocused
            textcolor="#fff"
            inputId="modalEmail"
            placeholder=""
            borders={false}
            value=""
            ref={emailRef}
            type="email"
          />
        </StyledGroup>
        <StyledGroup fullwidth>
          <Text
            align="left"
            text="Password"
            textStyle="description"
            textColor="#fff"
          />
          <TextField
            placeholdercolor="#fff"
            backgroundcolor={theme.color.dark[40]}
            textcolor="#fff"
            inputId="modalPassword"
            placeholder=""
            borders={false}
            value=""
            ref={passwordRef}
            type="password"
          />
        </StyledGroup>
        {errorMessage && <StyledErrorText align="left" text={errorMessage} />}
        <StyledButton
          fullWidth
          text={{
            text: 'Login',
            textColor: '#fff',
            align: 'center',
            textStyle: 'overline',
          }}
          bgColor={theme.color.primary[100]}
          onClick={() => {
            onSubmit(emailRef.current.value, passwordRef.current.value);
          }}
        />
        <Text align="center" text="Or:" textColor="#fff" />
        <StyledGoogleButton
          fullWidth
          text={{
            text: 'Sign in with Google',
            textColor: '#7E8285',
            align: 'center',
            textStyle: 'overline',
          }}
          bgColor="#fff"
          onClick={() => {
            loginWithGoogle();
          }}
        >
          <StyledGoogleIcon src="/resources/GoogleIcon.svg" width={rem(150)} />
        </StyledGoogleButton>
        <StyledInlineGroup>
          <Text
            align="left"
            text="Don't have an account?"
            textColor="#fff"
            inline
          />
          <Text
            onClick={() => {
              props.modalOpen(true);
              props.modalType(['registration']);
            }}
            align="left"
            text="Register"
            textColor="#fff"
            tag="a"
            inline
          />
        </StyledInlineGroup>
        <StyledInlineGroup>
          <Text
            align="left"
            text="Forgot your password?"
            textColor="#fff"
            inline
          />
          <Text
            onClick={() => {
              props.modalOpen(true);
              props.modalType(['resetPassword']);
            }}
            align="left"
            text="Click here!"
            textColor="#fff"
            tag="a"
            inline
          />
        </StyledInlineGroup>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

login.propTypes = {};

login.defaultProps = {};

export default connect(null, {
  modalType,
  modalOpen,
})(login);
