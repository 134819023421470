import theme, { rem } from '../../theme';

/** Set the variable for the active color */
const ActiveColor = theme.color.secondary[80];

/** Styles for the arrow */
export const webStyles = {
  arrow: {
    left: () => `
      transform: rotate(45deg);
      box-shadow: -${rem(3)} ${rem(3)} ${rem(6)} rgba(0, 0, 0, 0.1);
      border-color: transparent transparent white white;
      top: ${rem(12)};
      left: 0;
    `,
    right: chatColor => `
      transform: rotate(-135deg);
      box-shadow: -${rem(3)} ${rem(3)} ${rem(6)} rgba(0, 0, 0, 0.1);
      border-color: transparent transparent ${chatColor} ${chatColor};
      top: ${rem(24)};
      right: -${rem(8)};
    `,
  },
};

export { ActiveColor };
