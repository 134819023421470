import React, { useEffect, useState, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18next from 'i18next';
import theme, { rem } from '../../theme';
import Text from '../Text';
import TextField from '../TextField';
import Group from '../Group';
import Button from '../Button';
import Image from '../Image';
import Badge from '../Badge';
import Animation from '../LottieControl';
import UploadAvatar from '../UploadAvatar';
import Switch from '../Switch';
import Grid from '../Grid';
import ScrollSpyContent from '../ScrollSpyContent';
import ScrollSpyNav from '../ScrollSpyNav';
import withMemo from '../../utils/withMemo';
import firebase, {
  flipSwitch,
  userListner,
  userDashboardListner,
  dashboardListner,
  masterListner,
  feedbackListner,
} from '../../firebase';

const StyledMainWrapper = styled.div`
  ${({ elementDimensions }) => `
    transition: 0.4s;
    position: absolute;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: ${rem(10)};
  `}
`;

const StyledWrapper = styled.div`
  ${({ elementDimensions }) => `
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: ${rem(10)};
  `}
`;

const StyledButton = styled(Button)`
  margin-top: ${rem(25)};
  width: 100%;
`;

const StyledTourButton = styled(Button)`
  margin-top: ${rem(10)};
  width: 100%;
`;

const StyledGroup = styled(Group)`
  flex-direction: column;
  margin-bottom: ${rem(10)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledInlineGroup = styled(Group)`
  display: inline-flex;
  margin-bottom: ${rem(10)};
`;

const StyledInnerWrapper = styled.div`
  padding: 0 ${rem(20)} ${rem(20)} ${rem(20)};
  margin-top: -0.5rem;
`;

const StyledLoader = styled.div`
  ${({ elementDimensions }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${elementDimensions.width};
    height: 100%;
  `}
`;

const StyledCard = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: ${rem(20)};
  background: rgba(0, 0, 0, 0.3);
  border-radius: ${rem(10)};
  margin-bottom: ${rem(15)};
  margin-top: ${rem(5)};
`;

const StyledLogoutWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledInlineText = styled(Text)`
  width: auto;
  margin-right: ${rem(5)};
`;

const StyledBadge = styled(Badge)`
  margin-right: ${rem(5)};

  &:last-child {
    margin-right: 0;
  }
`;

const StyledImageGroup = styled(Group)`
  button {
    margin-right: ${rem(5)};
    border-radius: 50%;
    transition: 0.3s;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background: ${theme.color.success};
    }
  }
`;

const StyledBadgeWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`;

const StyledSwitchWrapper = styled.div`
  margin-bottom: ${rem(5)};
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;

/**
 * Overview component
 *
 */
const UserSettings = forwardRef((props, ref) => {
  const { history, activeDashboardId, elementDimensions, menuColor } = props;

  //States
  const [userInfo, setUserInfo] = useState({});
  const [inviteNotify, setInviteNotify] = useState(true);
  const [mailNotify, setMailNotify] = useState(true);
  const [userRoles, setUserRoles] = useState({});
  const [userRolesDetails, setUserRolesDetails] = useState({});
  const [showCursors, setShowCursors] = useState();
  const [showFeedback, setShowFeedback] = useState();
  const [showHelp, setShowHelp] = useState();
  const [payedUserNotify, setPayedUserNotify] = useState(true);
  const [activeLang, setActiveLang] = useState();
  const [maxLength] = useState(100);
  //UserId
  const uuid = firebase.auth().currentUser.uid;
  //Refs
  const nameRef = useRef();
  const statusRef = useRef();
  const emailRef = useRef();
  // Flags
  const flagRef = useRef();

  /**
   * Change language
   * @param {string} lang
   */
  const handleClickLang = lang => {
    i18next.changeLanguage(lang);

    if (!flagRef.current) return;

    userListner.child(uuid).update({ language: lang });
    const element = flagRef.current;
    // var elems = element;
    // if (elems !== null) {
    //   elems.children.classList.remove('active');
    // }
  };

  const appBackgroundListner = userDashboardListner
    .child(uuid)
    .child(activeDashboardId)
    .child('backgroundimage');

  useEffect(() => {
    listnerSetup();
  }, []);

  /** Setup the listners */
  const listnerSetup = () => {
    userListner
      .child(uuid)
      .child('language')
      .on('value', snap => {
        if (snap.val()) {
          setActiveLang(snap.val());
        }
      });

    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('role')
      .child('type')
      .on('value', snap => {
        if (snap.val()) {
          snap.forEach(function(childSnapType) {
            dashboardListner
              .child(activeDashboardId)
              .child('roles')
              .child(childSnapType.key)
              .child('details')
              .on('value', snapDetails => {
                userRolesDetails[childSnapType.key] = {};
                userRolesDetails[childSnapType.key] = snapDetails.val();
                setUserRolesDetails(prevState => {
                  return { ...prevState, ...userRolesDetails };
                });
              });
          });
        } else {
          setUserRoles({});
        }

        setUserRoles(snap.val());
      });

    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('settings')
      .child('cursors')
      .on('value', snap => {
        setShowCursors(snap.val());
      });

    feedbackListner.child('active').on('value', snap => {
      setShowFeedback(snap.val());
    });

    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('settings')
      .child('help')
      .on('value', snap => {
        setShowHelp(snap.val());
      });

    masterListner.once('value', snapshot => {
      if (
        snapshot.exists() &&
        snapshot.val().dashboard &&
        snapshot.val().backgroundimage &&
        snapshot.val().dashboard.id === activeDashboardId
      ) {
        userInfo['background'] = snapshot.val().backgroundimage;
      } else {
        appBackgroundListner.on('value', snap => {
          if (snap.exists() && snap.val() !== '') {
            userInfo['background'] = snap.val();
          } else {
            masterListner
              .child('backgroundimage')
              .once('value', snapshotMaster => {
                if (snapshotMaster.exists()) {
                  userInfo['background'] = snapshotMaster.val();
                } else {
                  userInfo['background'] = '';
                }
              });
          }
        });
      }
    });

    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .once('value', snap => {
        userInfo['settings'] = snap.val().settings;
        setMailNotify(userInfo['settings'].mail);
      })
      .then(() => {
        userListner
          .child(uuid)
          .once('value', snap => {
            userInfo['ghost'] = snap.val().isGhostUser;
            userInfo['payedUser'] = snap.val().isPayedUser;
            userInfo['avatar'] = snap.val().public.avatar;
            userInfo['email'] = snap.val().email;
            userInfo['name'] = snap.val().public.name;
            userInfo['status'] = snap.val().public.status;
          })
          .then(() => {
            dashboardListner
              .child(activeDashboardId)
              .child('maxWidgets')
              .on('value', snap => {
                userInfo['maxWidgets'] = snap.val();
              });

            dashboardListner
              .child(activeDashboardId)
              .child('maxMembers')
              .on('value', snap => {
                userInfo['maxMembers'] = snap.val();
              });
          })
          .then(() => {
            userDashboardListner
              .child(uuid)
              .child(activeDashboardId)
              .child('notifications')
              .once('value', snap => {
                if (snap.val()) {
                  userInfo['notifications'] = snap.val();
                  setInviteNotify(
                    snap.val() &&
                      snap.val().invites &&
                      snap.val().invites.notify
                  );
                  setMailNotify(
                    snap.val() && snap.val().mail && snap.val().mail.notify
                  );
                  if (snap.val() && snap.val().payedUser)
                    setPayedUserNotify(
                      snap.val() && snap.val().payedUser.notify
                    );
                }
              })
              .then(() => {
                setUserInfo(prevState => {
                  // Object.assign would also work
                  return { ...prevState, ...userInfo };
                });
              });
          });
      });
  };

  /** Handle public change */
  const handlePublicChange = type => {
    if (nameRef.current || statusRef.current) {
      let value = nameRef.current.value.substring(0, maxLength);
      if (type === 'status')
        value = statusRef.current.value.substring(0, maxLength);
      if (type === 'email')
        value = emailRef.current.value.substring(0, maxLength);

      if (type !== 'email') {
        userListner
          .child(uuid)
          .child('public')
          .update({ [type]: value });
      } else {
        userListner.child(uuid).update({ [type]: value });
      }
    }
  };

  /** Logout */
  const logOut = () => {
    firebase
      .auth()
      .signOut()
      .then(function() {
        // Sign-out successful.
        const url = new URL(window.location.origin);
        window.history.pushState({}, '', url);
        window.location.reload();
      });
  };

  /** Start the tour */
  const handleTour = () => {
    userListner.child(uuid).update({
      isFirstTimeUser: true,
    });
  };

  return (
    <StyledMainWrapper
      ref={ref}
      elementDimensions={elementDimensions}
      {...props}
    >
      <Grid>
        <StyledWrapper>
          {userInfo && userInfo['background'] && userInfo['avatar'] ? (
            <StyledInnerWrapper menuColor={menuColor}>
              <ScrollSpyNav
                fixed
                itemId='user'
                iconColor='#fff'
                icons='person camera image tune power_settings_new'
                indicators='person camera image tune power_settings_new'
                scrollTos='scrollBasics scrollAvatar scrollBackground scrollSettings scrollLogout'
                toolTips='Basics Avatar Background Settings Logout'
              />
              <ScrollSpyContent scrollIds='scrollBasics scrollAvatar scrollBackground scrollSettings scrollLogout'>
                <div>
                  <Text
                    align='left'
                    text='Tour'
                    textStyle='h6'
                    textColor='#fff'
                  />
                  <StyledCard>
                    <StyledGroup fullWidth>
                      <Animation
                        animation='tutorial'
                        animationHeight={rem(110)}
                      />
                      <StyledTourButton
                        text={{
                          text: 'Start the tour',
                          align: 'center',
                          textColor: '#fff',
                        }}
                        onClick={() => handleTour()}
                        bgColor={theme.color.primary[100]}
                      />
                    </StyledGroup>
                  </StyledCard>

                  <Text
                    align='left'
                    text='Basics'
                    textStyle='h6'
                    textColor='#fff'
                  />
                  <StyledCard>
                    <StyledGroup fullWidth>
                      <Text
                        align='left'
                        text='Language'
                        textStyle='description'
                        textColor='#fff'
                      />
                      <StyledImageGroup ref={flagRef}>
                        <Button
                          className={activeLang === 'nl' ? 'active' : ''}
                          onClick={() => handleClickLang('nl')}
                        >
                          <Image src='/resources/dutch.svg' width={20} />
                        </Button>
                        <Button
                          className={activeLang === 'en' ? 'active' : ''}
                          onClick={() => handleClickLang('en')}
                        >
                          <Image src='/resources/usa.svg' width={20} />
                        </Button>
                        <Button
                          className={activeLang === 'de' ? 'active' : ''}
                          onClick={() => handleClickLang('de')}
                        >
                          <Image src='/resources/german.svg' width={20} />
                        </Button>
                      </StyledImageGroup>
                    </StyledGroup>

                    <StyledGroup fullWidth>
                      {/* {userInfo['ghost'] && (
                        <Text
                          align="left"
                          text="Free user"
                          textStyle="h6"
                          textColor="#fff"
                        />
                      )} */}

                      {userInfo['payedUser'] && (
                        <React.Fragment>
                          <Text
                            align='left'
                            text='Account'
                            textStyle='h6'
                            textColor='#fff'
                          />
                          <StyledInlineGroup>
                            <StyledInlineText
                              align='left'
                              text='Bugs? Mail us at '
                              textStyle='description'
                              textColor='#fff'
                            />
                            <Text
                              align='left'
                              link='mailto:'
                              text='sp_bugs@gmail.com'
                              textStyle='description'
                              textColor='#fff'
                              tag='a'
                              inline
                            />
                          </StyledInlineGroup>
                        </React.Fragment>
                      )}
                      {userRoles && Object.keys(userRoles).length > 0 && (
                        <StyledGroup fullWidth>
                          <Text
                            align='left'
                            text='Roles'
                            textStyle='description'
                            textColor='#fff'
                          />
                          <StyledBadgeWrapper>
                            {Object.entries(userRoles).map(role => {
                              return (
                                <StyledBadge
                                  text={role[0]}
                                  color={userRolesDetails[role[0]].color}
                                  variant='text'
                                />
                              );
                            })}
                          </StyledBadgeWrapper>
                        </StyledGroup>
                      )}
                      {userInfo['maxWidgets'] && (
                        <Text
                          align='left'
                          text={`Max widgets: ${userInfo['maxWidgets']}`}
                          textStyle='description2'
                          textColor='#fff'
                        />
                      )}
                    </StyledGroup>
                    <StyledGroup fullWidth>
                      <Text
                        align='left'
                        text='Name'
                        textStyle='description'
                        textColor='#fff'
                      />
                      <TextField
                        value={
                          userInfo['name']
                            ? userInfo['name'].substring(0, maxLength)
                            : ''
                        }
                        textcolor='#fff'
                        placeholder='What is your name?'
                        backgroundcolor={theme.color.dark[40]}
                        placeholdercolor={theme.color.light[40]}
                        ref={nameRef}
                        borders={false}
                        extraOnblur={() => handlePublicChange('name')}
                      />
                    </StyledGroup>
                    {userInfo['status'] && (
                      <StyledGroup fullWidth>
                        <Text
                          align='left'
                          text='Status'
                          textStyle='description'
                          textColor='#fff'
                        />
                        <TextField
                          value={userInfo['status'].substring(0, maxLength)}
                          textcolor='#fff'
                          placeholder='Any status today?'
                          backgroundcolor={theme.color.dark[40]}
                          placeholdercolor={theme.color.light[40]}
                          ref={statusRef}
                          borders={false}
                          extraOnblur={() => handlePublicChange('status')}
                        />
                      </StyledGroup>
                    )}
                    {userInfo['email'] && (
                      <StyledGroup fullWidth>
                        <Text
                          align='left'
                          text='Email'
                          textStyle='description'
                          textColor='#fff'
                        />
                        <TextField
                          value={userInfo['email'].substring(0, maxLength)}
                          textcolor='#fff'
                          placeholder='What is your email?'
                          backgroundcolor={theme.color.dark[40]}
                          placeholdercolor={theme.color.light[40]}
                          ref={emailRef}
                          borders={false}
                          extraOnblur={() => handlePublicChange('email')}
                          type='email'
                        />
                      </StyledGroup>
                    )}
                    {/* <StyledContentEditable content="test" input='test' /> */}
                    {/* <TextField /> */}
                  </StyledCard>
                </div>
                <div>
                  <Text
                    align='left'
                    text='Avatar'
                    textStyle='h6'
                    textColor='#fff'
                  />
                  <UploadAvatar
                    type='avatar'
                    fullWidth
                    currentImage={
                      userInfo.avatar
                        ? userInfo.avatar.original
                        : '/resources/placeholder-logo.png'
                    }
                    width={100}
                    height={100}
                    padding={20}
                    scale={userInfo.avatar && userInfo.avatar.scale}
                    rotate={userInfo.avatar && userInfo.avatar.rotate}
                    position={userInfo.avatar && userInfo.avatar.position}
                    activeDashboardId={activeDashboardId}
                  />
                </div>
                <div>
                  <Text
                    align='left'
                    text='Background'
                    textStyle='h6'
                    textColor='#fff'
                  />
                  <UploadAvatar
                    fullWidth
                    rounded={false}
                    type='background'
                    currentImage={
                      userInfo.background
                        ? userInfo.background.original
                        : '/resources/placeholder-logo.png'
                    }
                    width={160}
                    height={90}
                    padding={20}
                    scale={userInfo.background && userInfo.background.scale}
                    rotate={userInfo.background && userInfo.background.rotate}
                    position={
                      userInfo.background && userInfo.background.position
                    }
                    activeDashboardId={activeDashboardId}
                  />
                </div>
                <div>
                  <Text
                    align='left'
                    text='Settings'
                    textStyle='h6'
                    textColor='#fff'
                  />
                  <StyledCard>
                    {userInfo.notifications &&
                      userInfo.notifications.payedUser && (
                        <StyledGroup fullWidth>
                          <Text
                            align='left'
                            text='StudentsPlus admin'
                            textStyle='description'
                            textColor='#fff'
                          />
                          <Switch
                            name='isPayedUserSwitch'
                            value='isPayedUserSwitch'
                            label='Toggle payed user status'
                            checked={payedUserNotify}
                            onClick={() => {
                              userInfo.notifications.payedUser.notify = !userInfo
                                .notifications.payedUser.notify;
                              setPayedUserNotify(!payedUserNotify);
                              flipSwitch(
                                'payedUser',
                                { notify: !payedUserNotify },
                                activeDashboardId
                              );
                            }}
                          />
                        </StyledGroup>
                      )}
                    <StyledGroup fullWidth>
                      <Text
                        align='left'
                        text='Global'
                        textStyle='description'
                        textColor='#fff'
                      />
                      {userInfo.settings && (
                        <StyledSwitchWrapper>
                          <Switch
                            name='mailNofity'
                            value='mailNofity'
                            label='Mail'
                            noMargin
                            checked={mailNotify}
                            onClick={() => {
                              setMailNotify(!mailNotify);
                              flipSwitch(
                                { mail: !mailNotify },
                                activeDashboardId
                              );
                            }}
                          />
                        </StyledSwitchWrapper>
                      )}
                      <StyledSwitchWrapper>
                        <Switch
                          name='showCursors'
                          value='showCursors'
                          label='Cursors'
                          noMargin
                          checked={showCursors}
                          onClick={() => {
                            setShowCursors(!showCursors);
                            flipSwitch(
                              { cursors: !showCursors },
                              activeDashboardId
                            );
                          }}
                        />
                      </StyledSwitchWrapper>
                      <StyledSwitchWrapper>
                        <Switch
                          name='showHelp'
                          value='showHelp'
                          label='Help indicators'
                          noMargin
                          checked={showHelp}
                          onClick={() => {
                            setShowHelp(!showHelp);
                            flipSwitch({ help: !showHelp }, activeDashboardId);
                          }}
                        />
                      </StyledSwitchWrapper>
                      {userInfo.notifications &&
                        userInfo.notifications.invites && (
                          <Switch
                            name='inviteNofity'
                            value='inviteNofity'
                            label='Invites'
                            checked={inviteNotify}
                            onClick={() => {
                              userInfo.notifications.invites.notify = !userInfo
                                .notifications.invites.notify;
                              setInviteNotify(!inviteNotify);
                              flipSwitch(
                                'invites',
                                { notify: !inviteNotify },
                                activeDashboardId
                              );
                            }}
                          />
                        )}

                      <StyledSwitchWrapper>
                        <Switch
                          name='showFeedback'
                          value='showFeedback'
                          label='Feedback'
                          noMargin
                          checked={showFeedback}
                          onClick={() => {
                            setShowFeedback(!showFeedback);
                            feedbackListner.update({ active: !showFeedback });
                          }}
                        />
                      </StyledSwitchWrapper>
                    </StyledGroup>
                  </StyledCard>
                </div>
                <div>
                  <Text
                    align='left'
                    text='Logout'
                    textStyle='h6'
                    textColor='#fff'
                  />
                  <StyledCard>
                    <StyledLogoutWrapper>
                      {/* <Avatar source={name.avatar.cropped} size="big" /> */}
                      <Animation
                        animation='leave'
                        animationWidth={rem(150)}
                        animationHeight={rem(150)}
                      />
                      <Text
                        text='Are you sure you want to fly away?'
                        textColor='#fff'
                        align='center'
                        textStyle='h6'
                      />
                      <StyledButton
                        text={{
                          text: 'logout',
                          align: 'center',
                          textColor: '#fff',
                        }}
                        onClick={() => logOut()}
                        bgColor={theme.color.primary[100]}
                      />
                    </StyledLogoutWrapper>
                  </StyledCard>
                </div>
              </ScrollSpyContent>
            </StyledInnerWrapper>
          ) : (
            <StyledLoader
              elementDimensions={elementDimensions}
              menuColor={menuColor}
            >
              <Animation animation='loadingv2' />
            </StyledLoader>
          )}
        </StyledWrapper>
      </Grid>
    </StyledMainWrapper>
  );
});

UserSettings.propTypes = {
  activeDashboardId: PropTypes.string,
  elementDimensions: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  menuColor: PropTypes.string,
};

UserSettings.defaultProps = {
  activeDashboardId: null,
  elementDimensions: null,
  menuColor: null,
};

/** @component */
//export default UserSettings;
export default withMemo(UserSettings, ['elementDimensions']);
