import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Particles from 'react-particles-js';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Button from '../Button';
import Text from '../Text';
import TextField from '../TextField';
import Image from '../Image';
import Group from '../Group';
import Lottie from '../LottieControl';
import theme, { rem } from '../../theme';
import firebase, {
  dashboardListner,
  createGhostUser,
  userListner,
  addUserByLink,
} from '../../firebase';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #26262b;
  height: 100%;
  width: 100%;

  #tsparticles {
    width: 100%;
    height: 100%;
  }
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(10)};
`;

const StyledTextSub = styled(Text)`
  margin-bottom: ${rem(30)};
`;

const StyledImage = styled(Image)`
  margin-bottom: ${rem(20)};
`;

const StyledTextIntro = styled(Text)`
  margin-bottom: 0;
`;

const StyledNameInput = styled.input`
  width: 22rem;
  line-height: 1.5rem;
  border-width: none;
  border-radius: 1.125rem;
  box-sizing: border-box;
  color: white;
  background-color: transparent;
  padding: 0.625rem;
  height: 100%;
  width: 22rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
  max-width: 100%;
`;

const StyledGroup = styled(Group)`
  background: rgb(47, 49, 54);
  width: ${rem(500)};
  border-radius: ${rem(15)};
  padding: ${rem(20)};
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
`;

const StyledInputGroup = styled(Group)`
  margin: ${rem(35)} 0 ${rem(20)};

  p {
    padding-bottom: ${rem(8)};
  }

  label {
    width: ${rem(250)};
    margin: 0 auto;
    border: 1px solid ${theme.color.primary[100]};
    border-radius: ${rem(10)};
  }

  &:last-child {
    margin: 0;
  }
`;

const StyledGroupExceeded = styled(Group)`
  display: flex;
  justify-content: space-between;
  background: rgb(47, 49, 54);
  width: ${rem(500)};
  min-height: ${rem(500)};
  border-radius: ${rem(15)};
  padding: ${rem(20)};
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
`;

const StyledParticles = styled(Particles)`
  position: fixed;
  z-index: 0;
  width: 100vw;
  height: 100vh;
`;

const StyledButtonGroup = styled(Group)`
  margin-top: ${rem(20)};
  button {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: ${rem(20)};
    width: ${rem(350)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

/** invite url route */
const Welcome = props => {
  const { inviteKey } = props;

  const [dashboardData, setDashboardData] = useState({});
  const [maxDashboards, setMaxDashboards] = useState(false);
  const [ready, setReady] = useState(false);
  const [disabled, setDisabled] = useState(true);

  //Translation
  const [t] = useTranslation(['modal', 'tooltip', 'common']);

  //Refs
  const nameRef = useRef();

  //Colors
  const listOColors = {
    lightBlue: '#84A6D6',
    blue: '#4382BB',
    purple: '#A15D98',
    pink: '#DC828F',
    turquiose: '#90CDC3',
    brown: '#AF8C72',
    oliveGreen: '#938F43',
    orange: '#F27348',
    salmon: '#F9968B',
    darkGrey: '#26474E',
    green: '#256940',
    red: '#C54B6C',
  };

  /** pick random color from list of colors */
  const randomProperty = function(obj) {
    var keys = Object.keys(obj);
    return obj[keys[(keys.length * Math.random()) << 0]];
  };

  useEffect(() => {
    if (inviteKey && Object.keys(inviteKey).length > 0) {
      let dataObject = {};
      dashboardListner
        .child(inviteKey.dashboardId)
        .once('value', snap => {
          dataObject = {
            image: snap.val().image,
            name: snap.val().name,
          };
        })
        .then(() => {
          userListner
            .child(inviteKey.friendId)
            .child('public')
            .once('value', snapUser => {
              let userData = {};
              userData = {
                user: snapUser.val().name,
              };

              const objectFinal = { ...dataObject, ...userData };
              setDashboardData(objectFinal);
            })
            .then(() => {
              if (firebase.auth() && firebase.auth().currentUser) {
                const uuid = firebase.auth().currentUser.uid;
                userListner.child(uuid).once('value', snap => {
                  if (snap.val()) {
                    const totalDashboards = snap.val().totalDashboards;
                    const maxDashboards = snap.val().maxDashboards;

                    if (totalDashboards >= maxDashboards) {
                      setMaxDashboards(true);
                    }
                  }
                });
              }
            })
            .then(() => {
              setReady(true);
            });
        });
    }
  }, [inviteKey]);

  /** set disabled state */
  const handleChange = () => {
    if (nameRef.current && nameRef.current.value !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  /** handle keydown */
  const handleKeydown = event => {
    if (event.keyCode === 13 && !event.shiftKey && !disabled) {
      proceedByLink(nameRef.current.value);
    }
  };

  /** create ghost user and proceed to invited dashboard */
  const proceedByLink = name => {
    if (firebase.auth() && firebase.auth().currentUser) {
      addUserByLink(
        inviteKey.friendId,
        inviteKey.dashboardId,
        firebase.auth().currentUser.uid,
        false
      );
    } else {
      firebase
        .auth()
        .signInAnonymously()
        .then(result => {
          let color = randomProperty(listOColors);
          createGhostUser(
            result.user.uid,
            inviteKey.dashboardId,
            inviteKey.friendId,
            true,
            name,
            color
          );
        });
    }
  };

  return (
    ready &&
    Object.keys(dashboardData).length > 0 && (
      <StyledWrapper>
        <Helmet>
          <meta charSet='utf-8' />
          <meta property='og:image' content={dashboardData.image} />
          <meta property='og:title' content='Dashboard invite' />
          <meta
            property='og:description'
            content={t('modal:welcome.invited', { user: dashboardData.user })}
          />
          <meta property='og:url' content={t('common:main.url')} />
          <title>Online Dashboard</title>
          <link
            rel='shortcut icon'
            href='/resources/favicon.ico'
            type='image/x-icon'
          />
          <link rel='canonical' href={t('common:main.url')} />
        </Helmet>
        <StyledParticles
          params={{
            particles: {
              number: {
                value: 60,
                density: {
                  enable: true,
                  value_area: 1500,
                },
              },
              line_linked: {
                enable: true,
                opacity: 0.02,
              },
              move: {
                direction: 'right',
                speed: 0.05,
              },
              size: {
                value: 1,
              },
              opacity: {
                anim: {
                  enable: true,
                  speed: 1,
                  opacity_min: 0.05,
                },
              },
            },
            interactivity: {
              events: {
                onclick: {
                  enable: true,
                  mode: 'push',
                },
              },
              modes: {
                push: {
                  particles_nb: 1,
                },
              },
            },
            retina_detect: true,
          }}
        />
        {!maxDashboards ? (
          <StyledGroup fullwidth>
            <StyledText
              align='center'
              text={t('modal:welcome.title')}
              textColor='#fff'
              textStyle='h3'
              tag='h1'
            />
            {dashboardData.image ? (
              <StyledImage src={dashboardData.image} />
            ) : (
              <Lottie animation='starsuccess' animationWidth={200} />
            )}

            {dashboardData.name ? (
              // <React.Fragment>
              //   <StyledTextIntro
              //     align="center"
              //     text={t('modal:welcome.invited', {
              //       user: dashboardData.user,
              //     })}
              //     textColor="#fff"
              //     textStyle="h6"
              //     tag="p"
              //   />

              //   <StyledText
              //     align="center"
              //     text={dashboardData.name}
              //     textColor="#fff"
              //     textStyle="h4"
              //     tag="h2"
              //   />
              // </React.Fragment>
              <StyledTextIntro
                align='center'
                text={t('modal:welcome.invitednoname', {
                  user: dashboardData.user,
                })}
                textColor='#fff'
                textStyle='h6'
                tag='p'
              />
            ) : (
              <StyledTextIntro
                align='center'
                text={t('modal:welcome.invitednoname', {
                  user: dashboardData.user,
                })}
                textColor='#fff'
                textStyle='h6'
                tag='p'
              />
            )}
            {firebase.auth() && firebase.auth().currentUser ? null : (
              <StyledInputGroup fullwidth>
                <Text
                  tag='p'
                  align='center'
                  text={t('modal:welcome.namelabel')}
                  textStyle='h6'
                  textColor='#fff'
                />
                <TextField
                  extraOnchange={() => handleChange()}
                  textcolor='#fff'
                  placeholdercolor='#afafaf'
                  backgroundcolor={theme.color.light[8]}
                  placeholder={t('modal:welcome.placeholder')}
                  inputId='modalWelcomeName'
                  borders={false}
                  onKeyDown={handleKeydown}
                  value={
                    nameRef.current && nameRef.current.value
                      ? nameRef.current.value
                      : ''
                  }
                  ref={nameRef}
                />
              </StyledInputGroup>
            )}
            <StyledButtonGroup fullwidth>
              {firebase.auth() && firebase.auth().currentUser ? (
                <Button
                  variant='contained'
                  text={{
                    align: 'center',
                    text: t('modal:welcome.button'),
                    textColor: '#fff',
                  }}
                  onClick={() => {
                    proceedByLink();
                  }}
                  bgColor={theme.color.primary[100]}
                />
              ) : (
                <Button
                  disabled={disabled}
                  variant='contained'
                  text={{
                    align: 'center',
                    text: t('modal:welcome.button'),
                    textColor: '#fff',
                  }}
                  onClick={() => {
                    proceedByLink(nameRef.current.value);
                  }}
                  bgColor={theme.color.primary[100]}
                />
              )}

              {/* <Button
                variant="contained"
                onClick={() => {}}
                bgColor={theme.color.dark[60]}
                text={{
                  align: 'center',
                  text: 'Login with your account',
                  textColor: '#fff',
                }}
              /> */}
            </StyledButtonGroup>
          </StyledGroup>
        ) : (
          <StyledGroupExceeded fullwidth>
            <StyledText
              align='center'
              text={t('tooltip:errors.dashboard.title')}
              textColor='#fff'
              textStyle='h3'
              tag='h1'
            />
            <Lottie animation='cry' animationWidth={200} />
            <StyledTextIntro
              align='center'
              text={t('tooltip:errors.dashboard.text')}
              textColor='#fff'
              textStyle='h6'
              tag='p'
            />
            <StyledButtonGroup fullwidth>
              <Link to='/login'>
                <Button
                  variant='contained'
                  onClick={() => {}}
                  bgColor={theme.color.cancel}
                  text={{
                    align: 'center',
                    text: 'Cancel',
                    textColor: '#fff',
                  }}
                />
              </Link>
            </StyledButtonGroup>
          </StyledGroupExceeded>
        )}
      </StyledWrapper>
    )
  );
};

Welcome.propTypes = {
  /** InviteKey */
  inviteKey: PropTypes.oneOfType([PropTypes.object]),
};

Welcome.defaultProps = {
  inviteKey: null,
};

export default Welcome;
