import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme, { rem } from '../../theme';

const StyledWrapper = styled.div`
  ${({
    align,
    ellipsis,
    tag,
    theme,
    textColor,
    inline,
    textStyle,
    direction,
  }) => `
    ${theme.textStyle[textStyle]};
    width: ${inline ? 'auto' : '100%'};
    margin-right: ${inline ? rem(5) : ''};
    cursor: ${tag === 'a' ? 'pointer' : ''};
    user-select: text;
    color: ${textColor};
    overflow: ${ellipsis ? 'hidden' : 'visible'};
    white-space: ${ellipsis ? 'nowrap' : 'normal'};
    text-align: ${align};
    text-overflow: ${ellipsis ? 'ellipsis' : 'unset'};
    direction: ${direction ? 'rtl' : 'ltr'};

    &:hover {
      color: ${textColor};
      text-decoration: ${tag === 'a' ? 'underline' : ''};
    }
  `}
`;

/**
 * @param {string} link
 * @param {string} textColor
 * @param {string} text
 * @param {bool} ellipsis
 * @param {bool} inline
 * @param {string} textStyle
 * @param {string} align
 * @param {string} tag
 * @param {bool} direction
 */
const Text = props => {
  const {
    link,
    inline,
    textColor,
    tag,
    align,
    ellipsis,
    text,
    textStyle,
    direction,
  } = props;
  const StyledText = tag;

  return (
    <StyledWrapper
      theme={theme}
      textStyle={textStyle}
      link={link}
      inline={inline}
      textColor={textColor}
      align={align}
      tag={tag}
      target={tag === 'a' ? '_blank' : undefined}
      direction={direction}
      ellipsis={ellipsis}
      {...props}
    >
      <StyledText href={tag === 'a' ? (link ? link + text : text) : undefined}>
        {text}
      </StyledText>
    </StyledWrapper>
  );
};

Text.propTypes = {
  /** HTML element tag */
  tag: PropTypes.string,

  /**Inline */
  inline: PropTypes.bool,

  /** Text to display */
  text: PropTypes.string,

  /** Text color */
  textColor: PropTypes.string,

  /** Has ellipsis */
  ellipsis: PropTypes.bool,

  /** link target */
  link: PropTypes.string,

  /** Alignment */
  align: PropTypes.oneOf(['left', 'center', 'right']),

  /** Style */
  textStyle: PropTypes.string,

  /** Text direction */
  direction: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

Text.defaultProps = {
  textColor: '#000',
  align: 'left',
  text: '',
  tag: 'div',
  textStyle: 'body',
  ellipsis: false,
  inline: false,
  link: null,
  direction: null,
};

/**
 * @component
 */
export default Text;
