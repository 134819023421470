import React, { useState } from 'react';
import styled from 'styled-components';
import theme, { rem } from '../../theme';
import Text from '../Text';
import { propTypes, defaultProps } from './Slider.props';
import { webStyles } from './Slider.styles';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  width: 100%;
`;

const StyledText = styled(Text)`
  margin: ${rem(10)} 0;
`;

const StyledSlider = styled.input`
  ${props => `
    background: linear-gradient(90deg, ${
      props.isDisabled ? theme.color.dark[40] : theme.color.primary[100]
    } ${(100 * (props.value - props.min)) / (props.max - props.min)}%, ${
    theme.color.dark[20]
  } ${(100 * (props.value - props.min)) / (props.max - props.min) + 0.1}%);
    ${webStyles.StyledSliderBase(theme)};
    &::-webkit-slider-thumb {
      ${webStyles.styledSliderThumb(theme)};
      ${!props.isDisabled &&
        `
      &:hover {
        ${webStyles.styledSliderHoverThumb(theme)};
      }`}
    }
    ${!props.isDisabled &&
      `
      &:focus::-webkit-slider-thumb {
        ${webStyles.styledSliderHoverThumb(theme)};
      }
      &:active::-webkit-slider-thumb {
        ${webStyles.styledSliderClickThumb(theme)};
      }
      
    `}
  `}

  ${props =>
    props.orient === 'vertical' && `${webStyles.styledSliderVertical(theme)}`}
`;

/**
 * @param {string} label
 * @param {string} labelColor
 * @param {strin} labelPosition
 * @param {bool} isDisabled
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @param {number} step
 * @param {function} onChange
 * @param {string} orient
 */
const Slider = props => {
  const {
    orient,
    label,
    labelColor,
    labelPosition,
    isDisabled,
    onChange,
    min,
    max,
    step,
  } = props;

  const { value: valueProps } = props;
  const [value, setValue] = useState(valueProps);

  /**
   *
   * @param {*} event
   */
  function handleChange(event) {
    if (isDisabled) return;
    setValue(event.target.value);
    onChange(event);
  }

  return (
    <StyledWrapper {...props}>
      {label && (
        <StyledText
          text={label}
          textColor={labelColor}
          tag="span"
          align={labelPosition}
          textStyle="description2"
        />
      )}
      <StyledSlider
        aria-label="slider"
        orient={orient}
        value={value}
        onChange={handleChange}
        step={step}
        isDisabled={isDisabled}
        type="range"
        min={min}
        max={max}
      />
    </StyledWrapper>
  );
};

Slider.propTypes = propTypes;
Slider.defaultProps = defaultProps;

/** @component */
export default Slider;
