import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';
import Particles from 'react-particles-js';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import TextField from '../TextField';
import EmptyState from '../EmptyState';
import Text from '../Text';
import Switch from '../Switch';
import UploadAvatar from '../UploadAvatar';
import Avatar from '../Avatar';
import Group from '../Group';
import Searchbar from '../SearchBar';
import Grid from '../Grid';
import theme, { rem } from '../../theme';
import firebase, {
  userListner,
  createDashboard,
  dashboardListner,
  masterListner,
  feedbackListner,
} from '../../firebase';

const StyledSideWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  right: ${rem(-60)};
  top: ${rem(30)};
  width: ${rem(100)};
  height: 100%;
`;

//CSS animations
const JackInTheBox = keyframes`
  from {
    opacity: 0;
    transform: scale(0.1) rotate(30deg);
    transform-origin: center bottom;
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const AnimationJackInTheBox = css`
  animation: ${JackInTheBox} 0.5s ease;
  animation-delay: 0;
  animation-fill-mode: forwards;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #26262b;
  height: 100%;
  width: 100%;

  #tsparticles {
    width: 100%;
    height: 100%;
  }
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(10)};
`;

const StyledTextSub = styled(Text)`
  margin-bottom: ${rem(30)};
`;

const StyledGroup = styled(Group)`
  background: rgb(47, 49, 54);
  width: ${rem(500)};
  min-height: ${rem(500)};
  border-radius: ${rem(15)};
  padding: ${rem(20)};
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
`;

const StyledGroupSwitch = styled(Group)`
  flex-direction: column;
  margin-bottom: ${rem(10)};
  margin-top: ${rem(10)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledParticles = styled(Particles)`
  position: fixed;
  z-index: 0;
  width: 100vw;
  height: 100vh;
`;

const StyledTextGroup = styled(Group)`
  flex-direction: column;
  margin-bottom: ${rem(10)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledUploadAvatar = styled(UploadAvatar)`
  width: auto;
  margin: ${rem(15)} !important;
`;

const StyledNavGroup = styled(Group)`
  margin-top: ${rem(20)};

  button {
    margin-right: ${rem(20)};

    &:last-child {
      margin-right: 0;
    }

    &.active {
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${theme.color.success};
      }
    }
  }
`;

const StyledContentWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: ${rem(300)};
`;

const StyledAvatarWrapper = styled(Group)`
  display: none;
  z-index: 1;

  &.active {
    display: block;
  }
`;

const StyledAddedUserWrapper = styled.div`
  ${AnimationJackInTheBox};
  margin-bottom: ${rem(10)}
  
  &:last-child {
    margin-bottom: 0;
  };
`;

const StyledAvatarContent = styled(Grid)`
  width: 100%;
  height: 100%;
`;

const StyledContent = styled(Grid)`
  border-radius: ${rem(10)};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  opacity: 0;

  &.active {
    opacity: 1;
  }
`;

const StyledUserSearch = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
`;

const StyledButtonGroup = styled(Group)`
  margin-top: ${rem(15)};
`;

/** invite url route */
const CreateDashboard = props => {
  const { userId } = props;

  //States
  const [userData, setUserData] = useState({});
  const [saveData] = useState([]);
  const [allPermissions, setAllPermissions] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [newSelectedUsers, setNewSelectedUsers] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [maxLength] = useState(100);

  //Reft
  const titleRef = useRef();
  const subTitleRef = useRef();
  const childRef = useRef();

  //Translation
  const [t] = useTranslation([
    'text',
    'modal',
    'textfield',
    'common',
    'button',
  ]);

  if (!userId) return <Redirect to='/' />;
  useEffect(() => {
    let dataObject = {};
    if (userId) {
      userListner.child(userId).once('value', snap => {
        if ((snap.exists() && snap.val().isGhostUser) || !snap.val().id) {
          setRedirect(true);
        }
      });

      masterListner.once('value', snapshot => {
        if (snapshot.exists() && snapshot.val().members) {
          let notMaster = true;
          Object.keys(snapshot.val().members).map(member => {
            if (member === userId) {
              notMaster = false;
            }
          });

          if (notMaster) setRedirect(true);
        }
      });

      userListner.child(userId).once('value', snap => {
        if (!snap.val().isGhostUser) {
          dataObject = snap.val().public;
          setUserData(dataObject);
        }
      });
    }
  }, []);

  /** Get the active state of the feedback */
  const getFeedbackStatus = () => {
    let status = false;
    feedbackListner.child('active').once('value', snap => {
      if (snap.exists()) status = snap.val();
    });

    return status;
  };

  /** When pressed on create */
  const handleCreateItem = () => {
    //Show the building indicator
    setIsCreating(true);
    saveData['title'] =
      titleRef.current && titleRef.current.value.substring(0, maxLength);
    saveData['subtitle'] =
      subTitleRef.current && subTitleRef.current.value.substring(0, maxLength);
    saveData['selectedFriends'] = newSelectedUsers;
    saveData['selectedFriends'][userId] = true;
    saveData['allPermissions'] = allPermissions;
    saveData['activeDashboardId'] = null;
    saveData['userId'] = userId;
    saveData['feedback'] = getFeedbackStatus();
    saveData['reload'] = true;
    saveData['image'] = childRef.current
      ? childRef.current.generateImage()
      : [];

    masterListner.once('value', snapshot => {
      if (snapshot.exists() && snapshot.val().dashboard) {
        saveData['backgroundimage'] =
          snapshot.val().backgroundimage && snapshot.val().backgroundimage;

        dashboardListner
          .child(snapshot.val()['dashboard'].id)
          .once('value', snap => {
            createDashboard(saveData, snap.val());
          });
      } else {
        createDashboard(saveData);
      }
    });
  };

  /** Handle click */
  const handleClick = type => {
    document.getElementById('editavatar').classList.remove('active');
    document.getElementById('editfriends').classList.remove('active');
    if (document.getElementById('buttoneditfriends'))
      document.getElementById('buttoneditfriends').classList.remove('active');
    document.getElementById('buttoneditavatar').classList.remove('active');

    //Active
    document.getElementById('edit' + type).classList.add('active');
    document.getElementById('buttonedit' + type).classList.add('active');
  };

  /** Avatar remove handler */
  const onCloseHandler = () => {
    saveData['image'] = 'delete';
  };

  /** Update the value in the DB */
  const getFriendsData = Uid => {
    /** Firebase get user info */
    let dataObject = {};

    firebase
      .database()
      .ref('users')
      .child(Uid)
      .child('public')
      .once('value', snapshot => {
        dataObject = snapshot.val();
      });

    return dataObject;
  };

  /** onItemClick */
  const onItemClick = itemId => {
    if (!newSelectedUsers.hasOwnProperty(itemId)) {
      newSelectedUsers[itemId] = getFriendsData(itemId);
    } else {
      delete newSelectedUsers[itemId];
    }

    setNewSelectedUsers(prevState => {
      return { ...prevState, ...newSelectedUsers };
    });
  };

  /** Handle text */
  const handleText = () => {
    let intro = t('common:welcome.hello');

    if (userData.name) {
      intro = t('common:welcome.hello') + ' ' + userData.name;
    }

    return intro + ', ' + t('modal:createdashboard.emptylong');
  };

  return redirect ? (
    <Redirect to='/' />
  ) : (
    userData && Object.keys(userData).length > 0 && (
      <StyledWrapper>
        <StyledParticles
          params={{
            particles: {
              number: {
                value: 60,
                density: {
                  enable: true,
                  value_area: 1500,
                },
              },
              line_linked: {
                enable: true,
                opacity: 0.02,
              },
              move: {
                direction: 'right',
                speed: 0.05,
              },
              size: {
                value: 1,
              },
              opacity: {
                anim: {
                  enable: true,
                  speed: 1,
                  opacity_min: 0.05,
                },
              },
            },
            interactivity: {
              events: {
                onclick: {
                  enable: true,
                  mode: 'push',
                },
              },
              modes: {
                push: {
                  particles_nb: 1,
                },
              },
            },
            retina_detect: true,
          }}
        />
        {isCreating ? (
          <StyledGroup fullwidth>
            <Text
              align='center'
              text={t('modal:createdashboard.building.title')}
              textColor='#fff'
              textStyle='h3'
              tag='h1'
            />
            <EmptyState
              textColor='#fff'
              animation='launch'
              width={rem(350)}
              height='auto'
              hasButton={false}
              text={t('modal:createdashboard.building.text')}
            />
          </StyledGroup>
        ) : (
          <StyledGroup fullwidth>
            <StyledText
              align='center'
              text={t('modal:createdashboard.empty')}
              textColor='#fff'
              textStyle='h3'
              tag='h1'
            />

            <StyledTextSub
              align='center'
              text={handleText()}
              textColor='#fff'
              textStyle='h6'
              tag='p'
            />

            <StyledTextGroup fullwidth>
              <Text
                align='left'
                text={t('textfield:createdashboard.title.label')}
                textStyle='description'
                textColor='#fff'
              />
              <TextField
                placeholdercolor='#fff'
                backgroundcolor={theme.color.dark[40]}
                isFocused
                textcolor='#fff'
                inputId='modalTitle'
                placeholder={t('textfield:createdashboard.title.placeholder')}
                borders={false}
                value=''
                ref={titleRef}
              />
            </StyledTextGroup>
            <StyledTextGroup>
              <Text
                align='left'
                text={t('textfield:createdashboard.description.label')}
                textStyle='description'
                textColor='#fff'
              />
              <TextField
                textcolor='#fff'
                placeholdercolor='#fff'
                backgroundcolor={theme.color.dark[40]}
                placeholder={t(
                  'textfield:createdashboard.description.placeholder'
                )}
                inputId='modalSubTitle'
                borders={false}
                value=''
                ref={subTitleRef}
              />
            </StyledTextGroup>

            <StyledGroupSwitch fullWidth>
              <Text
                align='left'
                text={t('textfield:createdashboard.permissions.label')}
                textStyle='description'
                textColor='#fff'
              />
              <Switch
                name='allPermissonsSwitch'
                value='allPermissonsSwitch'
                label=''
                checked={allPermissions}
                onClick={() => setAllPermissions(!allPermissions)}
              />
            </StyledGroupSwitch>

            <StyledNavGroup>
              <Button
                id='buttoneditavatar'
                variant='text'
                onClick={() => handleClick('avatar')}
                className='active'
                bgColor={theme.color.dark[8]}
                text={{
                  text: t('button:image.text'),
                  textColor: '#fff',
                  textStyle: 'description',
                }}
              />
              <Button
                id='buttoneditfriends'
                variant='text'
                onClick={() => handleClick('friends')}
                bgColor={theme.color.dark[8]}
                text={{
                  text: t('button:attendees.edit'),
                  textColor: '#fff',
                  textStyle: 'description',
                }}
              />
            </StyledNavGroup>
            <StyledContentWrapper>
              <StyledAvatarWrapper id='editavatar' className='active'>
                <StyledAvatarContent rows='1fr' bgColor={theme.color.dark[40]}>
                  <StyledUploadAvatar
                    rounded={false}
                    type='overviewItem'
                    itemType='dashboard'
                    id='newDashboard'
                    width={100}
                    height={100}
                    padding={20}
                    ref={childRef}
                    shadingColor='#000'
                    saveData={saveData}
                    onClose={preview => onCloseHandler(preview)}
                    cropRadius={75}
                    backgroundColor={theme.color.light[20]}
                    activeDashboardId={null}
                    hasButton={false}
                  />
                </StyledAvatarContent>
              </StyledAvatarWrapper>

              <StyledContent
                rows='1fr'
                bgColor={theme.color.dark[40]}
                scrollbar={false}
                parentId='editfriends'
              >
                <StyledUserSearch>
                  <Searchbar
                    showMenu={false}
                    elementDimensions={{ width: rem(460) }}
                    backgroundColor='transparent'
                    onItemClick={id => onItemClick(id)}
                    setActive={newSelectedUsers}
                  />
                </StyledUserSearch>
              </StyledContent>
            </StyledContentWrapper>
            <StyledSideWrapper id='modalAside'>
              {Object.keys(newSelectedUsers).length > 0 &&
                Object.entries(newSelectedUsers).map(user => {
                  if (userId !== user[0]) {
                    return (
                      <StyledAddedUserWrapper
                        key={user[0]}
                        data-reference={user[0]}
                      >
                        <Avatar
                          size='medium'
                          source={
                            user[1] && user[1].avatar && user[1].avatar.cropped
                          }
                          color={user[1] && user[1].color && user[1].color}
                          alt={user[1] && user[1].name}
                          title={user[1] && user[1].name}
                        />
                      </StyledAddedUserWrapper>
                    );
                  }
                })}
            </StyledSideWrapper>
            <StyledButtonGroup justify='flex-end'>
              <Button
                onClick={handleCreateItem}
                id='closeModal'
                bgColor={theme.color.success}
              >
                <Text textColor='#fff' text={t('button:create.text')} />
              </Button>
            </StyledButtonGroup>
          </StyledGroup>
        )}
      </StyledWrapper>
    )
  );
};

CreateDashboard.propTypes = {
  /** Current userId */
  userId: PropTypes.string,
};

CreateDashboard.defaultProps = {
  userId: '',
};

export default CreateDashboard;
