import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Iframe from 'react-iframe';
import firebase from '../../../firebase';
import { rem } from '../../../theme';

const StyledIframe = styled(Iframe)`
  padding: ${rem(15)};
  border-radius: ${rem(25)};
`;

/**
 * Layout component of a chat widget
 * @param {object} main
 * @param {string} activeId
 * @param {string} activeDashboardId
 * @param {string} widgetId
 */
const ThirdParty = props => {
  const { widgetId, activeId } = props;

  const [url, setUrl] = useState('');

  useEffect(() => {
    const listner = firebase
      .database()
      .ref()
      .child('thirdParties')
      .child(activeId)
      .child('url');

    listner.once('value', snap => {
      setUrl(snap.val());
    });
  }, []);

  return (
    url !== '' && (
      <StyledIframe
        widgetId={widgetId}
        width="100%"
        height="100%"
        url={url}
        frameBorder="0"
      />
    )
  );
};

ThirdParty.propTypes = {
  /** Current active Id */
  widgetId: PropTypes.string,
  /** Current widgetId */
  activeId: PropTypes.string,
};

ThirdParty.defaultProps = {
  widgetId: null,
  activeId: null,
};

export default ThirdParty;
