import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Group from '../Group';
import Button from '../Button';
import Image from '../Image';
import Text from '../Text';
import FilterSearch from '../FilterSearch';
import Lottie from '../LottieControl';
import Grid from '../Grid';
import firebase, {
  removeCompleteDashboard,
  dashboardListner,
} from '../../firebase';
import theme, { rem } from '../../theme';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(10)};
`;

const StyledTextIntro = styled(Text)`
  margin-bottom: 0;
`;

const StyledTextSub = styled(Text)`
  margin-bottom: ${rem(30)};
`;

const StyledImage = styled(Image)`
  margin-bottom: ${rem(20)};
  width: 100%;
  height: ${rem(250)};
`;

const StyledContentWrapper = styled(Group)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

const StyledButtonGroup = styled(Group)`
  button {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: ${rem(20)};
    width: ${rem(350)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledCard = styled.div`
  ${({ noPadding, customPadding }) => `
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: ${noPadding ? 0 : customPadding ? customPadding : rem(20)};
    background: rgba(0, 0, 0, 0.3);
    border-radius: ${rem(10)};
    margin-bottom: ${rem(15)};
    margin-top: ${rem(5)};
  `}
`;

const StyledGridWrapper = styled(Grid)`
  height: ${rem(250)};
`;

const StyledUserSearch = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 -${rem(20)} -${rem(20)} -${rem(20)};
`;

/** todo */
const leaveContent = props => {
  const { userId, dashboardId, closeModals } = props;

  //The current userId
  let uuid = userId;
  if (!uuid) uuid = firebase.auth().currentUser.uid;

  //States
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({});
  const [newSelectedUsers, setNewSelectedUsers] = useState({});

  //Translation
  const [t] = useTranslation(['modal', 'button']);

  useEffect(() => {
    getDashboardData();

    document.getElementById('globalModal').firstElementChild.style.height =
      'auto';
    document.getElementById('globalModal').firstElementChild.style.width =
      '40rem';
  }, []);

  /** Get all the dashboard data */
  const getDashboardData = () => {
    let dataObject = {};
    dashboardListner
      .child(dashboardId)
      .once('value', snapshot => {
        dataObject = snapshot.val();
      })
      .then(() => {
        setDashboardData(dataObject);
        setLoading(false);
      });
  };

  /** Return Chat messages */
  return loading ? (
    <Lottie animation='loading' animationWidth={400} />
  ) : (
    <StyledWrapper>
      <StyledContentWrapper fullwidth>
        <StyledText
          align='center'
          text={t('modal:removedashboard.title')}
          textColor='#fff'
          textStyle='h3'
          tag='h1'
        />
        {dashboardData['avatar'] && dashboardData['avatar'].original ? (
          <StyledImage src={dashboardData['avatar'].original} />
        ) : (
          <Lottie
            animation='launch'
            animationWidth={100}
            animationHeight={100}
          />
        )}

        {dashboardData.name ? (
          <React.Fragment>
            <StyledTextIntro
              align='center'
              text={t('modal:removedashboard.textname')}
              textColor='#fff'
              textStyle='h6'
              tag='p'
            />

            <StyledText
              align='center'
              text={dashboardData.name}
              textColor='#fff'
              textStyle='h4'
              tag='h2'
            />
          </React.Fragment>
        ) : (
          <StyledTextIntro
            align='center'
            text={t('modal:removedashboard.text')}
            textColor='#fff'
            textStyle='h6'
            tag='p'
          />
        )}

        <StyledTextSub
          align='center'
          text={t('modal:leavedashboard.note')}
          textColor='#fff'
          textStyle='h6'
          tag='p'
        />

        <StyledButtonGroup fullwidth>
          <Button
            variant='contained'
            onClick={() => {
              document
                .getElementById('dashboardContent')
                .classList.add('removing');
              removeCompleteDashboard(dashboardId);
              closeModals();
            }}
            bgColor={theme.color.danger}
            text={{
              align: 'center',
              text: t('button:dashboard.remove'),
              textColor: '#fff',
            }}
          />
          <Button
            variant='contained'
            text={{
              align: 'center',
              text: t('button:cancel.cancel'),
              textColor: '#fff',
            }}
            onClick={() => {
              closeModals();
            }}
            bgColor={theme.color.cancel}
          />
        </StyledButtonGroup>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

leaveContent.propTypes = {
  /** User Id */
  userId: PropTypes.string,
  /** Selected dashboard */
  dashboardId: PropTypes.string,
  /** Close function for the modal */
  closeModals: PropTypes.func,
};

leaveContent.defaultProps = {
  userId: null,
  dashboardId: null,
  closeModals: null,
};

//export default connect(mapStateToProps)(Messages);
export default leaveContent;
