import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";
import uuidv4 from "uuid/v4";
import isBlobURL from "is-blob-url";
import screenshot from "html2canvas";
import CustomToast from "./components/CustomToast";

/** TLDR important functions
 * @param {func} createNewItem - This function will create a new list
 * @param {func} updateItem - This function will update an excisting list
 */

/**
 * Get the correct config
 */
const getConfig = () => {
  let projectConfig;
  // eslint-disable-next-line prettier/prettier
  console.log("process.env.NODE_ENV ", process.env.NODE_ENV);
  if (process.env.NODE_ENV !== "production") {
    projectConfig = {
      apiKey: "AIzaSyDj1pDKpbrJowvowGVfhsfWsG0HPIaUbuM",
      authDomain: "onlinedashboard-3982e.firebaseapp.com",
      databaseURL: "https://onlinedashboard-3982e.firebaseio.com",
      projectId: "onlinedashboard-3982e",
      storageBucket: "onlinedashboard-3982e.appspot.com",
      messagingSenderId: "395233542124",
      appId: "1:395233542124:web:d6815fe6d2fdc6b5080ac9",
      measurementId: "G-655VN0PNRW",
    };
    // config = {
    //   apiKey: 'AIzaSyDj1pDKpbrJowvowGVfhsfWsG0HPIaUbuM',
    //   authDomain: 'onlinedashboard-3982e.firebaseapp.com',
    //   databaseURL: 'https://onlinedashboard-3982e.firebaseio.com',
    //   projectId: 'onlinedashboard-3982e',
    //   storageBucket: 'onlinedashboard-3982e.appspot.com',
    //   messagingSenderId: '395233542124',
    //   appId: '1:395233542124:web:d6815fe6d2fdc6b5080ac9',
    //   measurementId: 'G-655VN0PNRW',
    // };
  } else {
    projectConfig = {
      apiKey: "AIzaSyDj1pDKpbrJowvowGVfhsfWsG0HPIaUbuM",
      authDomain: "onlinedashboard-3982e.firebaseapp.com",
      databaseURL: "https://onlinedashboard-3982e.firebaseio.com",
      projectId: "onlinedashboard-3982e",
      storageBucket: "onlinedashboard-3982e.appspot.com",
      messagingSenderId: "395233542124",
      appId: "1:395233542124:web:d6815fe6d2fdc6b5080ac9",
      measurementId: "G-655VN0PNRW",
    };
  }

  return projectConfig;
};

// Initialize Firebase
var config = getConfig();

firebase.initializeApp(config);

var googleProvider = new firebase.auth.GoogleAuthProvider();

/**
 *
 * @param {*} dashboardId
 */
const removeCompleteDashboard = dashboardId => {
  //Get all the dashboard members
  dashboardListner
    .child(dashboardId)
    .child("members")
    .once("value", function(snap) {
      if (snap.exists()) {
        //Loop through every dashboard member
        snap.forEach(function(snapshot) {
          //Remove User from dashboard
          removeUserFromDashboard(dashboardId, snapshot.key, false, false);
        });
      }
    });
};

/**
 * Remove dashboard data
 * @param {*} dashboardId
 * @param {*} snap
 * @param {*} removeDashboard
 * @param {*} uuid
 */
function removeDashboardData(dashboardId, snap, removeDashboard, uuid) {
  snap.forEach(function(childSnapshot) {
    if (childSnapshot.val()) {
      const widgetId = childSnapshot.val().id;
      const type = childSnapshot.val().type;
      if (!type) return;
      const typeId = childSnapshot.val().typeId;
      const pivotName = "user" + type.charAt(0).toUpperCase() + type.slice(1);

      //Check if you need to remove the complete dashboard
      //If so remove the document/images from the storage to
      if (removeDashboard) {
        //REMOVE DOCUMENTS IN THE STORAGE
        firebase
          .database()
          .ref()
          .child(type)
          .child(typeId)
          .once("value", function(snapshotCheck) {
            //Check for documents
            if (snapshotCheck.val() && snapshotCheck.val().documents) {
              Object.values(snapshotCheck.val()["documents"]).map(item => {
                firebase
                  .storage()
                  .ref()
                  .child("documents")
                  .child(typeId)
                  .child(item.name)
                  .delete();
              });
            }

            //Check for images TODO
          })
          .then(() => {
            //Remove the table E.G. Notes with the certain ID
            firebase
              .database()
              .ref()
              .child(type)
              .child(typeId)
              .remove();
          });

        firebase
          .database()
          .ref()
          .child(type)
          .child(typeId)
          .remove();
      } else {
        //Remove user from the attendees table base on the type E.G. Note
        firebase
          .database()
          .ref()
          .child(type)
          .child(typeId)
          .child("attendees")
          .child(uuid)
          .remove();

        firebase
          .database()
          .ref()
          .child(type)
          .child(typeId)
          .child("userCount")
          .transaction(function(userCount) {
            return (userCount || 1) - 1;
          });
      }

      //Remove the pivot table of the type
      firebase
        .database()
        .ref()
        .child(pivotName)
        .child(uuid)
        .child(typeId)
        .remove();
    }
  });
}

/** Remove User from dash */
const removeUserFromDashboard = (
  dashboardId,
  selectedUserId,
  permission,
  owners,
  toastSuccesText,
  newOwnerText
) => {
  //User Id
  let uuid = selectedUserId;
  if (!selectedUserId) uuid = firebase.auth().currentUser.uid;

  //If there is not a dashboardId return
  if (!dashboardId || !uuid) return;

  //Currently active
  let active = false;

  //Remove user
  let removeUser = false;

  //Remove complete dashboard
  let removeDashboard = false;

  //User dashboard listner
  const listner = userDashboardListner.child(uuid).child(dashboardId);

  //Check if the user is the owner of dashboard
  if (owners && Object.keys(owners).length > 0) {
    const currentUser = firebase.auth().currentUser.uid;
    //Remove the user from the creators table of the dashboard
    dashboardListner
      .child("creators")
      .child(currentUser)
      .remove()
      .then(() => {
        Object.keys(owners).map(owner => {
          dashboardListner
            .child("creators")
            .update({
              [owner]: true,
            })
            .then(() => {
              addNotification(
                owner,
                "userRoles",
                dashboardId,
                newOwnerText,
                "success",
                "/resources/friend.svg",
                {},
                "empty"
              );
            });
        });
      });
  }

  //Handle the userDashboard table
  //Remove optional roles
  listner
    .child("role")
    .child("type")
    .once("value", snapType => {
      if (snapType.val()) {
        snapType.forEach(function(childSnapType) {
          dashboardListner
            .child(dashboardId)
            .child("roles")
            .child(childSnapType.key)
            .child("members")
            .child(uuid)
            .remove();
        });
      }
    });
  listner.update({ removing: true });

  //Check if the dashboard contains more then the user alone, if so remove the complete dashboard
  dashboardListner.child(dashboardId).once("value", snapTotalMembers => {
    //Check if the total number of members is smaller than or equal to 1 in that case
    //the dashboard is empty or you are the only one
    if (
      (!snapTotalMembers.val().master &&
        snapTotalMembers.val().totalMembers <= 1) ||
      !snapTotalMembers.exists()
    ) {
      removeDashboard = true;
    }

    //Handle the numbers
    dashboardListner
      .child(dashboardId)
      .child("totalMembers")
      .transaction(function(totalMembers) {
        return (totalMembers || 1) - 1;
      });

    //Remove the user if the user is a ghost user else update the number of total dashboards of the user
    userListner
      .child(uuid)
      .child("isGhostUser")
      .once("value", snapGhost => {
        if (snapGhost.val() && snapGhost.val() === true) {
          removeUser = true;
          userListner.child(uuid).remove();
        } else {
          userListner
            .child(uuid)
            .child("totalDashboards")
            .transaction(function(totalDashboards) {
              return (totalDashboards || 1) - 1;
            });
        }
      });

    //Go through all widget and remove user if he is a member
    dashboardListner
      .child(dashboardId)
      .child("widgets")
      .once("value", snap => {
        let promises = [];
        //Go through each widget inside the dashboard table
        promises.push(
          removeDashboardData(dashboardId, snap, removeDashboard, uuid)
        );

        // Wait for all promises to resolve
        Promise.all(promises).then(function(res) {
          //Check if the user is in the current dashboard that the user want to leave
          //If active is true the user is inside the dashboard

          listner.child("active").once("value", snapActive => {
            active = snapActive.val();
            if (snapActive.val() && snapActive.val() === true) {
              //Get all the user dashboards
              userDashboardListner.child(uuid).once("value", snapDash => {
                //All the user dashboards
                const allDashboardsObject = snapDash.val();
                //If there are more dashboard than the one the user want to remove
                //set the next one to active and switch dashboard
                if (
                  allDashboardsObject &&
                  Object.keys(allDashboardsObject).length > 0
                ) {
                  delete allDashboardsObject[dashboardId];
                  //Get the first dashboard
                  const firstDashboardId = Object.keys(allDashboardsObject)[0];
                  //Go to this dashboard
                  switchDashboard(firstDashboardId);
                } else {
                  if (permission) {
                    userListner
                      .child(firebase.auth().currentUser.uid)
                      .child("public")
                      .child("name")
                      .once("value", snapName => {
                        //Send notif
                        CustomToast.success(toastSuccesText);
                      });
                  }
                }
              });
            }
          });

          if (removeDashboard) {
            dashboardListner
              .child(dashboardId)
              .remove()
              .then(() => {
                listner.remove().then(() => {
                  if (removeUser) {
                    userListner.child(uuid).remove();
                  }
                });
              });
          } else {
            //RERENDER TRIGGER
            dashboardListner
              .child(dashboardId)
              .child("members")
              .child(uuid)
              .remove()
              .then(() => {
                listner.remove().then(() => {
                  if (removeUser) {
                    userListner.child(uuid).remove();
                  }
                });
              });
          }
        });
      });
  });
};

/** Database population for new users */
const newUserDatabaseTemplate = (
  userId,
  isGhostUser,
  name,
  dbId,
  friendId,
  reload,
  color
) => {
  let dashboardPushId = "";
  let idParamUtil = userId;
  const randomId =
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15);
  userListner
    .child(userId)
    .set({
      id: userId,
      isPayedUser: true, //TODO: set this back on release
      isGhostUser: isGhostUser,
      isFirstTimeUser:
        (dbId && dbId !== "createUser") || isGhostUser ? false : true,
      isSingleSignOnUser: false,
      totalDashboards: dbId !== "createUser" ? 1 : 0, //TODO: set this number for payed users eventually
      maxDashboards: 10, //TODO: set this number for payed users eventually
      public: {
        name: name ? name : "",
        indicator: "online",
        status: "let's learn today!",
        color: color ? color : "",
        avatar: {
          cropped: "",
          original: "",
          position: {
            x: 0,
            y: 0,
          },
          rotate: 0,
          scale: 1,
          userId: userId,
        },
      },
    })
    .then(function() {
      if (dbId && dbId !== "createUser") {
        dashboardPushId = dbId;
      } else if (!dbId) {
        dashboardPushId = dashboardListner.push();
        dashboardListner.child(dashboardPushId.key).set({
          avatar: {
            cropped: "",
            original: "",
            position: {
              x: 0,
              y: 0,
            },
            rotate: 0,
            scale: 1,
            userId: userId,
          },
          name: "",
          description: "",
          permissions: {
            everybody: isGhostUser ? false : true,
          },
          creators: {
            [userId]: true,
          },
          id: dashboardPushId.key,
          lastEditedBy: userId,
          members: {
            [userId]: true,
          },
          time: Date.now(),
          totalMembers: 1,
          maxRoles: 20,
          maxMembers: 5, //TODO: Fixed this for payed and normal users
          maxWidgets: 6, //TODO: Fixed this for payed and normal users
          link: {
            id: randomId,
            time: Date.now(),
          },
        });
      }
    })
    .then(() => {
      inviteKeyListner.update({
        [randomId]: {
          dashboardId: dashboardPushId.key
            ? dashboardPushId.key
            : dashboardPushId,
          friendId: userId,
          id: randomId,
          time: Date.now(),
        },
      });
    })
    .then(function() {
      if (!dbId) {
        /** Get the active state of the feedback */
        const getFeedbackStatus = () => {
          let status = false;
          feedbackListner.child("active").once("value", snap => {
            if (snap.exists()) status = snap.val();
          });

          return status;
        };

        userDashboardListner
          .child(idParamUtil)
          .child(dashboardPushId.key ? dashboardPushId.key : dashboardPushId)
          .set({
            settings: {
              sound: true,
              mail: true,
              notification: true,
              cursors: true,
              help: true,
              feedback: getFeedbackStatus(),
            },
            time: Date.now(),
            seen: true,
            removing: false,
            totalWidgets: 0,
            permissions: {
              drag: isGhostUser ? false : true,
              resize: isGhostUser ? false : true,
              subitems: isGhostUser ? false : true,
              menuitem: isGhostUser ? false : true,
              compactmenu: isGhostUser ? false : true,
              store: isGhostUser ? false : true,
              invite: isGhostUser ? false : true,
              details: isGhostUser ? false : true,
            },
            active: true,
            activity: {
              cursor: {
                id: "cursor" + userId,
                forcedHidden: false,
                visible: true,
                action: "default",
                x: 0,
                y: 0,
              },
              menu: {
                aside: false,
                visible: isGhostUser ? false : true,
                xPosition: "center",
                yPosition: "top",
                x: 540,
                y: 20,
              },
            },
            backgroundimage: {
              cropped: "",
              original: "",
              position: {
                x: 0,
                y: 0,
              },
              rotate: 0,
              scale: 1,
              userId: userId,
            },
            id: dashboardPushId.key ? dashboardPushId.key : dashboardPushId,
            colors: {
              backgroundColor: "#d1d1d1",
              cardColor: "rgba(255, 255, 255, 0.6)",
              chatColor: "#0075ff",
              checkboxColor: "#f95738",
              headerColor: "#7e73ac",
              iconColor: "#fff",
              listItemColor: "#0075ff",
              menuColor: "rgba(0,0,0,.76)",
              overviewColor: "#244781",
              sidebarColor: "#333",
            },
            public: {
              colors: {
                checkboxColor: "#f95738",
              },
            },
          })
          .then(function() {
            if (!isGhostUser) {
              window.location.reload();
            }
          });
      }
    })
    .then(() => {
      if (dbId && dbId !== "createUser") {
        addUserByLink(friendId, dbId, userId, reload);
      }
    });
};

/** Accept shareable link and add to dashboard */
const addUserByLink = (friendId, dashboardId, uuid, reload) => {
  //Add the user to the dashboard member
  dashboardListner
    .child(dashboardId)
    .child("totalMembers")
    .transaction(function(totalMembers) {
      return (totalMembers || 0) + 1;
    });
  dashboardListner
    .child(dashboardId)
    .child("members")
    .update({
      [uuid]: true,
    })
    .then(() => {
      if (friendId) {
        userListner
          .child(friendId)
          .child("public")
          .child("name")
          .once("value", snap => {
            CustomToast.success(
              "Succesfully accepted dashboard request from " + snap.val()
            );
          })
          .then(() => {
            if (reload) {
              document.location.reload();
            } else {
              switchDashboard(dashboardId);
            }
          });
      }
    })
    .then(() => {
      userListner
        .child(uuid)
        .child("totalDashboards")
        .transaction(function(totalDashboards) {
          return (totalDashboards || 0) + 1;
        });
    });

  //Add new userDashboard item
  addNewUserDashboardItem(dashboardId, uuid, true);

  //Go through all widget and add the invited user to all the public ones
  dashboardListner
    .child(dashboardId)
    .child("widgets")
    .once("value", snap => {
      snap.forEach(function(childSnapshot) {
        userDashboardListner
          .child(uuid)
          .child(dashboardId)
          .child("totalWidgets")
          .transaction(function(totalWidgets) {
            return (totalWidgets || 0) + 1;
          });

        userDashboardListner
          .child(uuid)
          .child(dashboardId)
          .child("widgets")
          .child(childSnapshot.key)
          .update({
            maxItems: 10,
          });

        if (childSnapshot.val() && childSnapshot.val().public) {
          const type = childSnapshot.val().type;
          const publicItems = childSnapshot.val().public;

          Object.keys(publicItems).map((itemId, index) => {
            firebase
              .database()
              .ref()
              .child(type)
              .child(itemId)
              .child("userCount")
              .transaction(function(userCount) {
                return (userCount || 0) + 1;
              });

            firebase
              .database()
              .ref()
              .child(type)
              .child(itemId)
              .child("attendees")
              .update({
                [uuid]: true,
              })
              .then(() => {
                createItemPivot(type, itemId, childSnapshot.key, index);
              })
              .then(() => {
                userDashboardListner
                  .child(uuid)
                  .child(dashboardId)
                  .child("widgets")
                  .child(childSnapshot.key)
                  .child("totalItems")
                  .transaction(function(totalItems) {
                    return (totalItems || 0) + 1;
                  });
              });
          });
        }
      });
    })
    .then(dashSnap => {
      userListner
        .child(friendId)
        .child("public")
        .child("name")
        .once("value", snap => {
          const friendText = "Your invite has been accepted.";
          addNotification(
            friendId,
            "dashboardInvites",
            dashboardId,
            friendText,
            "success",
            "/resources/dashboard.svg",
            {},
            "empty"
          );
        });
    });
};

/** Sign in with gooogle auth  */
const signInWithGoogle = () => {
  firebase
    .auth()
    .signInWithPopup(googleProvider)
    .then(async function(socialAuthUser) {
      await newUserDatabaseTemplate(
        socialAuthUser.user.uid,
        false,
        socialAuthUser.user.displayName,
        null,
        null,
        true
      );
    });
};

/** Create temporary user with anonymous authentication */
const createGhostUser = (idParam, dbId, friendId, reload, name, color) => {
  newUserDatabaseTemplate(idParam, true, name, dbId, friendId, reload, color);
};

/** Create random color */
const createRandomColor = () => {
  /** pick random color from list of colors */
  const listOColors = {
    lightBlue: "#84A6D6",
    blue: "#4382BB",
    purple: "#A15D98",
    pink: "#DC828F",
    turquiose: "#90CDC3",
    brown: "#AF8C72",
    oliveGreen: "#938F43",
    orange: "#F27348",
    salmon: "#F9968B",
    darkGrey: "#26474E",
    green: "#256940",
    red: "#C54B6C",
  };

  var keys = Object.keys(listOColors);
  return listOColors[keys[(keys.length * Math.random()) << 0]];
};

/** Create new User */
const createExternalUser = (userId, name) => {
  userListner.child(userId).set({
    id: userId,
    isPayedUser: true, //TODO: set this back on release
    isGhostUser: false,
    isFirstTimeUser: true,
    isSingleSignOnUser: true,
    totalDashboards: 0,
    maxDashboards: 10,
    public: {
      name: name ? name : "",
      indicator: "online",
      status: "let's learn today!",
      color: createRandomColor(),
      avatar: {
        cropped: "",
        original: "",
        position: {
          x: 0,
          y: 0,
        },
        rotate: 0,
        scale: 1,
        userId: userId,
      },
    },
  });
};

/** Create normal user */
const registerUser = createdUser => {
  let saveData = [];
  let promises = [];
  saveData["title"] = "";
  saveData["subtitle"] = "";
  saveData["selectedFriends"] = {};
  saveData["selectedFriends"][createdUser.user.uid] = true;
  saveData["allPermissions"] = false;
  saveData["activeDashboardId"] = null;
  saveData["userId"] = createdUser.user.uid;
  saveData["reload"] = false;
  saveData["createUser"] = true;
  saveData["image"] = [];

  //Go through each widget inside the dashboard table
  promises.push(
    newUserDatabaseTemplate(
      createdUser.user.uid,
      false,
      createdUser.user.displayName,
      "createUser",
      null,
      false
    )
  );

  Promise.all(promises).then(function() {
    masterListner.once("value", snapshot => {
      if (snapshot.exists()) {
        saveData["backgroundimage"] =
          snapshot.val().backgroundimage && snapshot.val().backgroundimage;

        dashboardListner
          .child(snapshot.val()["dashboard"].id)
          .once("value", snap => {
            let promisesInside = [];
            promisesInside.push(createDashboard(saveData, snap.val()));

            Promise.all(promises).then(function() {
              setTimeout(function() {
                window.location.reload();
              }, 1500);
            });
          });
      } else {
        createDashboard(saveData);
      }
    });
  });
};

/** invite a user as friend */
const inviteUser = (id, type, dashboardId, extendedData) => {
  const uuid = firebase.auth().currentUser.uid;
  const listnerIn = userListner
    .child(id)
    .child("invites")
    .child(type)
    .child("invitesIn");
  let extraData = null;

  if (type === "dashboards") {
    if (extendedData) {
      extraData = {
        name: extendedData.name ? extendedData.name : "",
        dashboardId: extendedData.dashboardId ? extendedData.dashboardId : "",
        image: extendedData.image ? extendedData.image : "",
        admin: extendedData.admin ? extendedData.admin : "",
      };
    } else {
      dashboardListner.child(dashboardId).once("value", snap => {
        extraData = {
          name: snap.val().name,
          dashboardId: snap.val().id,
          image: snap.val().avatar ? snap.val().avatar.cropped : "",
        };
      });
    }
  }

  // Get a key for a new Post.
  const newPostKey = listnerIn.push().key;

  let postData = {
    type: type,
    seen: false,
    from: uuid,
    time: Date.now(),
  };

  if (extraData) postData = { ...extraData, ...postData };

  listnerIn
    .child(newPostKey)
    .update(postData)
    .then(() => {
      userListner
        .child(id)
        .child("public")
        .child("name")
        .once("value", snapName => {
          let extraInviteData = null;

          let postDataInvite = {
            type: type,
            to: id,
            time: Date.now(),
            friend: snapName.val(),
            dashboardId: dashboardId,
          };

          if (type === "dashboard" && extraData) {
            extraInviteData = {
              name: extraData.name,
            };
          }

          if (extraInviteData)
            postDataInvite = { ...extraInviteData, ...postDataInvite };

          userListner
            .child(uuid)
            .child("invites")
            .child(type)
            .child("invitesOut")
            .child(newPostKey)
            .update(postDataInvite)
            .then(function() {
              userListner
                .child(id)
                .child("public")
                .child("name")
                .once("value", snap => {
                  const text = type.slice(0, -1);
                  const textCapitalized =
                    text.charAt(0).toUpperCase() + text.slice(1);
                  CustomToast.success(
                    textCapitalized + " request sent to " + snap.val()
                  );
                });
            });
        });
    });
};

/** update Chat Message */
const updateChatMessage = (chatId, messageId, data) => {
  const uuid = firebase.auth().currentUser.uid;
  const path = "chats" + "/" + chatId + "/items/" + messageId + "/images/";
  let imageStatus = {};

  if (data.images && data.images !== "" && data["images"].imageData) {
    imageStatus = "loading";
    uploadEditorImages(data.images.imageData, null, data.images, path);
  }

  let messageData = {
    changedBy: uuid,
    edited: true,
    images: imageStatus,
    lastEditedBy: uuid,
    text: data.text ? data.text : "",
  };

  chatListner
    .child(chatId)
    .child("items")
    .child(messageId)
    .update(messageData);
};

/** undo Request */
const undoRequest = (invite, activeDashboardId, type) => {
  const uuid = firebase.auth().currentUser.uid;

  const listnerIn = userListner
    .child(invite.userId)
    .child("invites")
    .child(type)
    .child("invitesIn")
    .child(invite.id);

  const listnerOut = userListner
    .child(uuid)
    .child("invites")
    .child(type)
    .child("invitesOut")
    .child(invite.id);

  listnerIn.remove().then(() => {
    listnerOut.remove().then(() => {
      userListner
        .child(invite.userId)
        .child("public")
        .child("name")
        .once("value", snap => {
          const text = type.slice(0, -1);
          const textCapitalized = text.charAt(0).toUpperCase() + text.slice(1);
          CustomToast.success(
            textCapitalized + " request to " + snap.val() + " has been removed"
          );
        });
    });
  });
};

/** Remove friend */
const removeFriend = user => {
  const uuid = firebase.auth().currentUser.uid;

  userListner
    .child(uuid)
    .child("friends")
    .child(user.id)
    .remove()
    .then(function() {
      CustomToast.success(user.name + " is no longer your friend");
    });
};

/** Accept friends handler */
const acceptFriendsHandler = (friendId, type, activeDashboardId, inviteId) => {
  const uuid = firebase.auth().currentUser.uid;

  userListner
    .child(friendId)
    .child("public")
    .child("name")
    .once("value", snap => {
      const text = type.slice(0, -1);
      const textSender = "Your are now friends with " + snap.val();

      CustomToast.success(
        "Succesfully accepted " + text + " request from " + snap.val()
      );

      addNotification(
        uuid,
        "userInvites",
        activeDashboardId,
        textSender,
        "success",
        "/resources/friends.svg",
        {},
        inviteId
      );
    })
    .then(() => {
      userListner
        .child(uuid)
        .child("public")
        .child("name")
        .once("value", snap => {
          const text = "Your are now friends with " + snap.val();
          addNotification(
            friendId,
            "userInvites",
            activeDashboardId,
            text,
            "success",
            "/resources/friends.svg",
            {},
            inviteId
          );
        });
    })
    .then(() => {
      userListner
        .child(uuid)
        .child("friends")
        .update({
          [friendId]: true,
        });

      userListner
        .child(friendId)
        .child("friends")
        .update({
          [uuid]: true,
        });
    })
    .then(() => {
      userListner
        .child(uuid)
        .child("users")
        .child("declined")
        .child(friendId)
        .once("value", snap => {
          if (snap.exists()) {
            userListner
              .child(uuid)
              .child("users")
              .child("declined")
              .child(friendId)
              .remove();
          }
        });
    });
};

/** Accept dashboards handler */
const acceptDashboardsHandler = (
  friendId,
  type,
  activeDashboardId,
  data,
  inviteId
) => {
  const uuid = firebase.auth().currentUser.uid;

  //Check if the dashboard is already full
  dashboardListner
    .child(data[inviteId]["dashboard"].dashboardId)
    .once("value", checkSnap => {
      if (checkSnap.val().totalMembers >= checkSnap.val().maxMembers) {
        //Dashboard is full
        userListner
          .child(friendId)
          .child("public")
          .child("name")
          .once("value", snap => {
            const text =
              "Could not add " +
              snap.val() +
              " because the dashboard exceeded the members limitations";
            addNotification(
              uuid,
              "userInvites",
              data.activeDashboardId,
              text,
              "error",
              "/resources/friend.svg",
              {},
              "empty"
            );
          });

        userListner
          .child(uuid)
          .child("public")
          .child("name")
          .once("value", snap => {
            const Friendtext =
              snap.val() +
              " invited you for a dashboard, but this dashboard exceeded the members limitations!";
            addNotification(
              friendId,
              "userInvites",
              data.activeDashboardId,
              Friendtext,
              "error",
              "/resources/friend.svg",
              {},
              "empty"
            );
          });
      } else {
        //Add the user to the dashboard member
        dashboardListner
          .child(data[inviteId]["dashboard"].dashboardId)
          .child("members")
          .update({
            [uuid]: true,
          })
          .then(() => {
            if (data[inviteId]["dashboard"].admin) {
              dashboardListner
                .child(data[inviteId]["dashboard"].dashboardId)
                .child("roles")
                .child("admin")
                .child("members")
                .update({
                  [uuid]: true,
                });
            }
          })
          .then(() => {
            dashboardListner
              .child(data[inviteId]["dashboard"].dashboardId)
              .child("totalMembers")
              .transaction(function(totalMembers) {
                return (totalMembers || 0) + 1;
              });
          })
          .then(() => {
            if (friendId) {
              userListner
                .child(friendId)
                .child("public")
                .child("name")
                .once("value", snap => {
                  const text = type.slice(0, -1);
                  CustomToast.success(
                    "Succesfully accepted " +
                      text +
                      " request from " +
                      snap.val()
                  );
                });
            }
          });

        //Add new userDashboard item
        addNewUserDashboardItem(
          data[inviteId]["dashboard"].dashboardId,
          null,
          false
        );

        //Go through all widget and add the invited user to all the public ones
        dashboardListner
          .child(data[inviteId]["dashboard"].dashboardId)
          .child("widgets")
          .once("value", snap => {
            snap.forEach(function(childSnapshot) {
              userDashboardListner
                .child(uuid)
                .child(data[inviteId]["dashboard"].dashboardId)
                .child("totalWidgets")
                .transaction(function(totalWidgets) {
                  return (totalWidgets || 0) + 1;
                });

              userListner
                .child(uuid)
                .child("totalDashboards")
                .transaction(function(totalDashboards) {
                  return (totalDashboards || 0) + 1;
                });

              userDashboardListner
                .child(uuid)
                .child(data[inviteId]["dashboard"].dashboardId)
                .child("widgets")
                .child(childSnapshot.key)
                .update({
                  maxItems: 10,
                });

              if (childSnapshot.val() && childSnapshot.val().public) {
                const type = childSnapshot.val().type;
                const publicItems = childSnapshot.val().public;

                Object.keys(publicItems).map((itemId, index) => {
                  firebase
                    .database()
                    .ref()
                    .child(type)
                    .child(itemId)
                    .child("attendees")
                    .update({
                      [uuid]: true,
                    })
                    .then(() => {
                      firebase
                        .database()
                        .ref()
                        .child(type)
                        .child(itemId)
                        .child("userCount")
                        .transaction(function(userCount) {
                          return (userCount || 0) + 1;
                        });
                    })
                    .then(() => {
                      createItemPivot(type, itemId, childSnapshot.key, index);
                    })
                    .then(() => {
                      userDashboardListner
                        .child(uuid)
                        .child(data[inviteId]["dashboard"].dashboardId)
                        .child("widgets")
                        .child(childSnapshot.key)
                        .child("totalItems")
                        .transaction(function(totalItems) {
                          return (totalItems || 0) + 1;
                        });
                    });
                });
              }
            });
          })
          .then(() => {
            dashboardListner
              .child(data[inviteId]["dashboard"].dashboardId)
              .child("name")
              .once("value", snap => {
                const text = "You are added to dashboard " + snap.val();
                addNotification(
                  uuid,
                  "dashboardInvites",
                  activeDashboardId,
                  text,
                  "success",
                  "/resources/dashboard.svg",
                  {},
                  inviteId
                );
              })
              .then(dashSnap => {
                userListner
                  .child(friendId)
                  .child("public")
                  .child("name")
                  .once("value", snap => {
                    const friendText =
                      snap.val() +
                      " is now joining dashboard " +
                      dashSnap.val();
                    addNotification(
                      friendId,
                      "dashboardInvites",
                      activeDashboardId,
                      friendText,
                      "success",
                      "/resources/dashboard.svg",
                      {},
                      inviteId
                    );
                  });
              });
          })
          .then(() => {
            userListner
              .child(uuid)
              .child("users")
              .child("declined")
              .child(friendId)
              .once("value", snap => {
                if (snap.exists()) {
                  userListner
                    .child(uuid)
                    .child("users")
                    .child("declined")
                    .child(friendId)
                    .remove();
                }
              });
          });
      }
    });
};

/** Create item pivot */
const createItemPivot = (type, itemId, widgetId, index) => {
  let pivotDetails = null;
  const uuid = firebase.auth().currentUser.uid;
  const pivotName = "user" + type.charAt(0).toUpperCase() + type.slice(1);

  switch (type) {
    default:
    case "notes":
      pivotDetails = {
        colors: { noteColor: "#E7F6FD", noteTextColor: "#444444" },
      };
      break;
    case "whiteboards":
      pivotDetails = {
        brush: { brushColor: "#444", brushSize: "2" },
      };
      break;
    case "calls":
      pivotDetails = {
        indicator: { status: "online" },
      };
      break;
    case "documentsharing":
      pivotDetails = {
        indicator: { status: "online" },
      };
      break;
  }

  let postPivotData = {
    new: false,
    created: false,
    unRead: 0,
    setActive: index === 0 ? true : false,
    firstanimation: false,
    sortTime: -Date.now(),
    time: Date.now(),
    update: Date.now(),
    widgetId: widgetId,
    id: itemId,
  };

  if (pivotDetails) postPivotData = { ...pivotDetails, ...postPivotData };

  firebase
    .database()
    .ref()
    .child(pivotName)
    .child(uuid)
    .child(itemId)
    .update(postPivotData);
};

/** Add new User Dashboard item */
const addNewUserDashboardItem = (dashboardId, newUserId, active) => {
  dashboardListner
    .child(dashboardId)
    .child("permissions")
    .child("everybody")
    .once("value", snap => {
      //User Id
      let uuid = newUserId;
      if (!newUserId) uuid = firebase.auth().currentUser.uid;
      //Default listner
      const listner = userDashboardListner.child(uuid).child(dashboardId);
      /** Get the active state of the feedback */
      const getFeedbackStatus = () => {
        let status = false;
        feedbackListner.child("active").once("value", snap => {
          if (snap.exists()) status = snap.val();
        });

        return status;
      };
      //Default Data
      const postData = {
        settings: {
          sound: true,
          mail: true,
          notification: true,
          cursors: true,
          help: true,
          feedback: getFeedbackStatus(),
        },
        time: Date.now(),
        seen: false,
        removing: false,
        totalWidgets: 0,
        permissions: {
          drag: snap.val(),
          resize: snap.val(),
          subitems: snap.val(),
          menuitem: snap.val(),
          compactmenu: snap.val(),
          store: snap.val(),
          invite: snap.val(),
          details: snap.val(),
        },
        active: active ? active : false,
        activity: {
          cursor: {
            id: "cursor" + uuid,
            forcedHidden: false,
            visible: true,
            action: "default",
            x: 0,
            y: 0,
          },
          menu: {
            aside: false,
            visible: true,
            xPosition: "center",
            yPosition: "top",
            x: 540,
            y: 20,
          },
        },
        backgroundimage: {
          cropped: "",
          original: "",
          position: {
            x: 0,
            y: 0,
          },
          rotate: 0,
          scale: 1,
          userId: uuid,
        },
        id: dashboardId,
        colors: {
          backgroundColor: "#d1d1d1",
          cardColor: "rgba(255, 255, 255, 0.6)",
          chatColor: "#0075ff",
          checkboxColor: "#f95738",
          headerColor: "#7e73ac",
          iconColor: "#fff",
          listItemColor: "#0075ff",
          menuColor: "rgba(0,0,0,.76)",
          overviewColor: "#244781",
          sidebarColor: "#333",
        },
        public: {
          colors: {
            checkboxColor: "#f95738",
          },
        },
      };

      //Added the data to a new item in the DB
      listner.update(postData);
    });
};

/** Add a friend */
const acceptRequest = (friendId, inviteId, activeDashboardId, type, data) => {
  const uuid = firebase.auth().currentUser.uid;

  userListner
    .child(uuid)
    .child("invites")
    .child(type)
    .child("invitesIn")
    .child(inviteId)
    .remove()
    .then(() => {
      userListner
        .child(friendId)
        .child("invites")
        .child(type)
        .child("invitesOut")
        .child(inviteId)
        .remove()
        .then(() => {
          if (type === "friends")
            acceptFriendsHandler(friendId, type, activeDashboardId, inviteId);
          if (type === "dashboards")
            acceptDashboardsHandler(
              friendId,
              type,
              activeDashboardId,
              data,
              inviteId
            );
        })
        .then(() => {
          userListner
            .child(uuid)
            .child("notifications")
            .once("value", snap => {
              snap.forEach(function(childSnapshot) {
                if (childSnapshot.val().inviteId === inviteId) {
                  userListner
                    .child(uuid)
                    .child("notifications")
                    .child(childSnapshot.key)
                    .child("extraButtons")
                    .set(null);
                }
              });
            });

          userListner
            .child(friendId)
            .child("notifications")
            .once("value", snap => {
              snap.forEach(function(childSnapshot) {
                if (childSnapshot.val().inviteId === inviteId) {
                  userListner
                    .child(friendId)
                    .child("notifications")
                    .child(childSnapshot.key)
                    .child("extraButtons")
                    .set(null);
                }
              });
            });
        });
    });
};

/** unsend friend request to target user */
const declineRequest = (friendId, inviteId, activeDashboardId, type) => {
  const uuid = firebase.auth().currentUser.uid;
  const typeSingle = type.slice(0, -1);
  let listnerOut = null;

  if (type === "dashboards") {
    listnerOut = dashboardListner.child(activeDashboardId);
  }

  if (type === "friends") {
    listnerOut = userListner.child(friendId);
  }

  const listnerIn = firebase
    .database()
    .ref()
    .child("users")
    .child(uuid)
    .child("invites")
    .child(type)
    .child("invitesIn")
    .child(inviteId);

  listnerIn.remove().then(() => {
    listnerOut
      .child("invites")
      .child(type)
      .child("invitesOut")
      .child(inviteId)
      .remove()
      .then(() => {
        userListner
          .child(friendId)
          .child("public")
          .child("name")
          .once("value", snap => {
            CustomToast.success(
              "Succesfully declined " +
                typeSingle +
                " request from " +
                snap.val()
            );
          })
          .then(() => {
            userListner
              .child(uuid)
              .child("public")
              .child("name")
              .once("value", snap => {
                const text =
                  "Your " +
                  typeSingle +
                  " invite to " +
                  snap.val() +
                  " has been declined";
                addNotification(
                  friendId,
                  "userInvites",
                  activeDashboardId,
                  text,
                  "error",
                  "/resources/friend.svg",
                  {},
                  inviteId
                );
              })
              .then(() => {
                userListner
                  .child(friendId)
                  .child("users")
                  .child("declined")
                  .update({
                    [uuid]: true,
                  });
              });
          });
      });
  });
};

/** Add a notification */
const addNotification = (
  userId,
  type,
  activeDashboardId,
  text,
  level,
  avatar,
  extraButtons,
  inviteId
) => {
  const listner = userListner.child(userId).child("notifications");

  // Get a key for a new Post.
  const newPostKey = listner.push().key;

  //Update
  listner.child(newPostKey).update({
    id: newPostKey,
    time: Date.now(),
    text: text,
    level: level,
    type: type,
    avatar: avatar,
    seen: false,
    extraButtons: extraButtons ? extraButtons : {},
    inviteId: inviteId,
  });
};

/** Remove notification */
const removeNotification = (id, activeDashboardId) => {
  const uuid = firebase.auth().currentUser.uid;

  userListner
    .child(uuid)
    .child("notifications")
    .child(id)
    .remove()
    .then(() => {
      CustomToast.success("Succesfully removed notification");
    });
};

/** Remove ALL notification */
const removeAllNotification = () => {
  const uuid = firebase.auth().currentUser.uid;

  userListner
    .child(uuid)
    .child("notifications")
    .remove()
    .then(() => {
      CustomToast.success("Succesfully removed all notifications");
    });
};

/** invite a user as friend */
const inviteUserDashboard = (uuid, friendUuid) => {
  firebase
    .database()
    .ref()
    .child("users")
    .child(uuid)
    .child("invites")
    .child("invitesOutDashboard")
    .child(friendUuid)
    .set(true);

  firebase
    .database()
    .ref()
    .child("users")
    .child(friendUuid)
    .child("invites")
    .child("invitesInDashboard")
    .child(uuid)
    .set(true);
};

/** remove person from invite table and adds to friend */
const addToFriends = (uuid, friendUuid) => {
  firebase
    .database()
    .ref()
    .child("users")
    .child(uuid)
    .child("invites")
    .child("invitesIn")
    .child(friendUuid)
    .remove();

  firebase
    .database()
    .ref()
    .child("users")
    .child(friendUuid)
    .child("invites")
    .child("invitesOut")
    .child(uuid)
    .remove();

  firebase
    .database()
    .ref()
    .child("users")
    .child(uuid)
    .child("friends")
    .child(friendUuid)
    .set(true);

  firebase
    .database()
    .ref()
    .child("users")
    .child(friendUuid)
    .child("friends")
    .child(uuid)
    .set(true);
};

/** remove person from invite table and adds to friend */
const addToDashboard = (uuid, friendUuid) => {
  firebase
    .database()
    .ref()
    .child("users")
    .child(uuid)
    .child("invites")
    .child("invitesInDashboard")
    .child(friendUuid)
    .remove();

  firebase
    .database()
    .ref()
    .child("users")
    .child(friendUuid)
    .child("invites")
    .child("invitesOutDashboard")
    .child(uuid)
    .remove();

  firebase
    .database()
    .ref()
    .child("users")
    .child(friendUuid)
    .child("friends")
    .child(uuid)
    .set(true);
};

/** Create a screenshot and upload the image */
const createScreenshot = () => {
  //const uid = firebase.auth().currentUser.uid;
  const element = document.body;
  //Screenshot function
  screenshot(element, {
    logging: true,
    letterRendering: 1,
    allowTaint: false,
    useCORS: true,
    foreignObjectRendering: true,
    /**god mag het weten */
    onrendered: function() {},
  }).then(function(canvas) {
    // Export the canvas to its data URI representation
    const base64image = canvas.toDataURL("image/png");
    // User ID
    const uid = firebase.auth().currentUser.uid;
    // Upload the screenshot to the database
    uploadSingleImage(
      base64image, //the image
      uid + "/dashboard/screenshots", //the path to the database
      null, //the extra data
      "screenshot", //the type
      "userDashboards",
      null
    );
  });
};

/** Get single list item data */
const getItemData = (table, listId, itemId) => {
  let dataObject = {};

  firebase
    .database()
    .ref()
    .child(table)
    .child(listId)
    .child("items")
    .child(itemId)
    .once("value", snap => {
      dataObject = snap.val();
    });

  return dataObject;
};

/** Get all the list data */
const getAllData = (table, itemId) => {
  let dataObject = {};
  let listner = firebase
    .database()
    .ref()
    .child(table)
    .child(itemId);

  listner.once("value", snap => {
    dataObject = snap.val();
  });

  return dataObject;
};

/** Get all the list data */
const getData = (table, itemId) => {
  let dataObject = {};
  let listner;

  switch (table) {
    default:
    case "lists":
      listner = firebase
        .database()
        .ref()
        .child(table)
        .child(itemId);
      break;
    case "whiteboards":
      listner = firebase
        .database()
        .ref()
        .child(table)
        .child(itemId)
        .child("drawing")
        .child("draw");
      break;
    case "notes":
      listner = firebase
        .database()
        .ref()
        .child(table)
        .child(itemId)
        .child("text");
      break;
    case "documentsharing":
      listner = firebase
        .database()
        .ref()
        .child(table)
        .child(itemId);
      break;
  }

  listner.once("value", snap => {
    dataObject = snap.val();
  });

  return dataObject;
};

/** Get the data of your friends */
const getFriendsData = () => {
  const uid = firebase.auth().currentUser.uid;
  let dataObject = {};

  firebase
    .database()
    .ref()
    .child("users")
    .child(uid)
    .child("friends")
    .once("value", snap => {
      snap.forEach(function(childSnapshot) {
        const key = childSnapshot.key;

        firebase
          .database()
          .ref()
          .child("users")
          .child(key)
          .once("value", snap => {
            dataObject[key] = snap.val();
          });
      });
    });

  return dataObject;
};

/** Get all the user data */
const getUserData = uid => {
  let dataObject = {};

  firebase
    .database()
    .ref()
    .child("users")
    .child(uid)
    .once("value", snap => {
      dataObject[uid] = snap.val();
    });

  return dataObject;
};

/** Update the sub title of the overview component */
const updateTempSubTitle = (table, itemId, subTitle) => {
  const uuid = firebase.auth().currentUser.uid;
  // Database Reference
  const listner = firebase
    .database()
    .ref()
    .child(table)
    .child(itemId);

  let postData = {
    tempCreator: subTitle ? { [uuid]: true } : null,
    active: subTitle ? true : false,
  };

  listner.update({
    tempSubTitle: postData,
  });
};

/** Update the sub title of the overview component */
const updateSubTitle = (table, itemId, subTitle) => {
  const uuid = firebase.auth().currentUser.uid;
  const pivotName = "user" + table.charAt(0).toUpperCase() + table.slice(1);
  // Database Reference
  const listner = firebase
    .database()
    .ref()
    .child(table)
    .child(itemId);

  const pivotListner = firebase
    .database()
    .ref()
    .child(pivotName)
    .child(uuid)
    .child(itemId);

  listner
    .child("attendees")
    .once("value", snap => {
      snap.forEach(function(snapshot) {
        const pivotListnerSecond = firebase
          .database()
          .ref()
          .child(pivotName)
          .child(snapshot.key)
          .child(itemId);

        pivotListnerSecond.once("value", snapPivot => {
          if (snapPivot.val() && !snapPivot.val().setActive) {
            pivotListnerSecond.update({
              unRead: snapPivot.val().unRead + 1,
            });
          }
        });
      });
    })
    .then(() => {
      listner.update({
        subTitle: subTitle,
        lastEditedBy: uuid,
        changedBy: uuid,
        time: Date.now(),
      });

      pivotListner.update({
        time: Date.now(),
        update: Date.now(),
        sortTime: -Date.now(),
      });

      updateTempSubTitle(table, itemId, null);
    });
};

/** Creates a new list ITEM in the DB
 * @param {string} uid - Current user id
 * @param {number} itemId - The current itemId
 * @param {string} value - The value of the input field
 */
const createNewSubItem = (
  uid,
  itemId,
  value,
  files,
  table,
  itemTypes,
  editor
) => {
  let currentPositions = [];
  // Database Reference
  const ref = firebase
    .database()
    .ref()
    .child(table)
    .child(itemId);

  // Get a key for a new Post.
  const newPostKey = ref.child(itemTypes).push().key;

  //Add the new key to the positions array
  ref.child("positions").once("value", snap => {
    if (snap.exists()) {
      currentPositions = JSON.parse(snap.val());
    }
    currentPositions.unshift(newPostKey);
    ref.update({ positions: JSON.stringify(currentPositions) });
  });

  // Indicates a loader
  let loading = false;

  // Check if there are files attached
  if (files && files.length > 0) {
    //Set the loader to true
    loading = true;
    // ref
    //   .child(itemTypes)
    //   .child(newPostKey)
    //   .update({ loading: true });

    if (editor) {
      const path =
        table + "/" + itemId + "/" + itemTypes + "/" + newPostKey + "/images/";
      uploadEditorImages(files[0].imageData, null, files, path);
    } else {
      uploadMultipleImage(
        files,
        uid,
        itemId,
        table + "/" + itemId + "/" + itemTypes + "/public",
        newPostKey,
        table,
        itemTypes
      );
    }
  }

  // Get the data ready to upload
  let postData = {
    changedBy: uid,
    lastEditedBy: uid,
    checked: false,
    detail: "empty",
    id: newPostKey,
    text: value,
    update: Date.now(),
    time: Date.now(),
    sortTime: -Date.now(),
    loading: loading,
    maxImages: 10,
    totalImages: 0,
    images: {},
    owner: uid,
    tempSubTitle: {
      active: false,
    },
  };
  // Write the new post's data simultaneously in the posts list and the user's post list.
  var updates = {};
  updates[
    "/" + table + "/" + itemId + "/" + itemTypes + "/" + newPostKey
  ] = postData;
  // Put the data into the database
  firebase
    .database()
    .ref()
    .update(updates)
    .then(() => {
      ref.child("itemCount").transaction(function(itemCount) {
        return (itemCount || 0) + 1;
      });
    });

  return newPostKey;
};

/** Upload multiple images */
const uploadMultipleImage = (
  files,
  uid,
  id,
  path,
  newPostKey,
  table,
  itemTypes
) => {
  for (const file of files) {
    const storageRef = firebase.storage().ref();
    const storageId = uuidv4();
    const filePath = `${path}/${storageId}`;
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      if (reader.result) {
        // Create the file metadata
        const metadata = {
          contentType: "image/jpg",
        };
        // Upload file and metadata to the object 'images/mountains.jpg'
        const uploadTask = storageRef
          .child(filePath)
          .putString(reader.result, "data_url", metadata);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          function(snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // const progress =
            //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            switch (snapshot.state) {
              default:
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                break;
            }
          },
          function(error) {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              default:
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          function() {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function(downloadURL) {
                //insertNewItemData(downloadURL, data);
                //Upload the images
                const listRef = firebase
                  .database()
                  .ref()
                  .child(table)
                  .child(id)
                  .child(itemTypes)
                  .child(newPostKey);

                const ref = listRef.child("images");

                // Get a key for a new Post.
                const newId = ref.push().key;
                // Put the data into the database
                ref
                  .child(newId)
                  .update({
                    url: downloadURL,
                    update: Date.now(),
                    id: newId,
                    storageId: storageId,
                  })
                  .then(() => {
                    listRef
                      .child("totalImages")
                      .transaction(function(totalImages) {
                        return (totalImages || 0) + 1;
                      });
                  });
                listRef.update({ loading: false });
              });
          }
        );
      }
    };
  }
};

/** Update the text of an item in the DB
 * @param {string} uid - Current user id
 * @param {number} listId - The current listID
 * @param {string} value - The value of the input field
 */
const updateItemText = (uid, listId, value, itemId, table) => {
  const ref = firebase
    .database()
    .ref()
    .child(table)
    .child(listId)
    .child("items")
    .child(itemId);

  ref.update({ text: value });
};

/** Update dashboard creator items */
const updateDashboard = data => {
  const uuid = firebase.auth().currentUser.uid;
  const listner = dashboardListner.child(data.dashboardId);

  listner
    .update({
      name: data.title && data.title,
      description: data.subtitle && data.subtitle,
      lastEditedBy: firebase.auth().currentUser.uid,
    })
    .then(() => {
      if (!data.image || !data["image"]["imageData"]) {
        listner.update({
          avatar: {
            cropped: "",
            original: "",
            position: "",
            rotate: 0,
            scale: 1,
            userId: uuid,
          },
        });
      } else {
        uploadEditorImages(
          data["image"].imageData,
          null,
          data,
          "dashboards/" + data.dashboardId + "/avatar/"
        );
      }
    })
    .then(() => {
      if (data.image && data["image"]["handleData"]) {
        listner.child("avatar").update({
          position: data["image"]["handleData"][0]["position"]
            ? data["image"]["handleData"][0]["position"]
            : "",
          rotate: data["image"]["handleData"][0]["rotate"],
          scale: data["image"]["handleData"][0]["scale"],
          userId: data["image"]["handleData"][0]["userId"],
        });
      }
    });
};

/**
 * Create Dashboard Data
 * @param {*} newLayout
 * @param {*} widgetData
 * @param {*} newPostKey
 * @param {*} oldLayout
 * @param {*} uuid
 */
function createDashboardData(
  newLayout,
  widgetData,
  newPostKey,
  oldLayout,
  uuid
) {
  const promises = [];
  promises.push(
    Object.values(widgetData).map(widget => {
      const newWidgetKey = dashboardListner
        .child(newPostKey)
        .child("widgets")
        .push().key;

      dashboardListner
        .child(newPostKey)
        .child("widgets")
        .update({
          [newWidgetKey]: {
            creator: widget.creator,
            hasHeader: widget.hasHeader,
            // hasOverview: widget.hasOverview,
            // hasPivot:  widget.hasPivot,
            // id: newWidgetKey,
            // owner: uuid,
            // resizeMax: widget.resizeMax,
            // time: Date.now(),
            // type: widget.type,
          },
        })
        .then(() => {
          Object.values(oldLayout).map(layout => {
            if (layout.i === widget.id) {
              layout["i"] = newWidgetKey;
              newLayout.push(layout);

              //Update the layout string
              dashboardListner
                .child(newPostKey)
                .child("layout")
                .set(JSON.stringify(newLayout));
            }
          });
          //Create the widget
          createNewWidget(
            {
              creator: widget.creator,
              hasHeader: widget.hasHeader,
              hasOverview: widget.hasOverview,
              hasPivot: widget.hasPivot,
              singleton: widget.singleton ? true : false,
              id: newWidgetKey,
              owner: uuid,
              resizeMax: widget.resizeMax,
              time: Date.now(),
              type: widget.type,
              typeId:
                "_" +
                Math.random()
                  .toString(36)
                  .substr(2, 9),
              url: null,
            },
            newPostKey,
            newPostKey,
            newWidgetKey
          );
        });
    })
  );

  // Wait for all requests, and then setState
  Promise.all(promises).then(function(res) {
    return;
  });
}
/** Creates a new list in the DB
 * @param {object} data - All the data that is necessary for creating a new dashboard
 */
const createDashboard = (data, templateData) => {
  let newPostKey;
  if (data.id) {
    newPostKey = data.id;
  } else {
    newPostKey = dashboardListner.push().key;
  }
  const uuid = firebase.auth().currentUser.uid;
  let extraData = null;
  const randomId =
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15);

  //Add new Dashboard
  dashboardListner
    .child(newPostKey)
    .update({
      creators: {
        [uuid]: true,
      },
      members: {
        [uuid]: true,
      },
      permissions:
        templateData && templateData.permissions
          ? templateData.permissions
          : {
              everybody: data.allPermissions,
            },
      totalMembers: 1,
      time: Date.now(),
      maxRoles:
        templateData && templateData.maxRoles ? templateData.maxRoles : 20,
      maxMembers:
        templateData && templateData.maxMembers ? templateData.maxMembers : 5, //TODO: Fixed this for payed and normal users
      maxWidgets:
        templateData && templateData.maxWidgets ? templateData.maxWidgets : 6, //TODO: Fixed this for payed and normal users
      roles: templateData && templateData.roles ? templateData.roles : null,
      id: newPostKey,
      lastEditedBy: uuid,
      name: data.title ? data.title : "",
      description: data.subtitle ? data.subtitle : "",
      sso: data.sso ? true : false,
      link: {
        id: randomId,
        time: Date.now(),
      },
    })
    .then(() => {
      if (templateData && templateData.widgets) {
        const widgetData = templateData.widgets;
        const oldLayout = JSON.parse(templateData.layout);
        let newLayout = [];
        let promises = [];
        //Go through each widget inside the dashboard table
        promises.push(
          createDashboardData(
            newLayout,
            widgetData,
            newPostKey,
            oldLayout,
            uuid
          )
        );

        // Wait for all promises to resolve
        Promise.all(promises).then(function(res) {
          userListner
            .child(uuid)
            .child("totalDashboards")
            .transaction(function(totalDashboards) {
              return (totalDashboards || 0) + 1;
            })
            .then(() => {
              if (data.reload) {
                userDashboardListner.child(uuid).once("value", function(snap) {
                  snap.forEach(function(childSnapshot) {
                    if (childSnapshot.key === newPostKey) {
                      childSnapshot.ref.update({ active: true, seen: true });
                    } else {
                      childSnapshot.ref.update({ active: false });
                    }
                  });
                });

                setTimeout(function() {
                  const url = new URL(window.location.origin);
                  window.history.pushState({}, "", url);
                  window.location.reload();
                }, 1500);
              }
            });
        });
      } else {
        userListner
          .child(uuid)
          .child("totalDashboards")
          .transaction(function(totalDashboards) {
            return (totalDashboards || 0) + 1;
          })
          .then(() => {
            if (data.reload) {
              const url = new URL(window.location.origin);
              window.history.pushState({}, "", url);
              window.location.reload();
            }
          });
      }
      /**
     * 
      creator: "studentsplus"
      hasHeader: false
      hasOverview: true
      hasPivot: true
      image: "https://i.imgur.com/3PxpKDI.png"
      resizeMax: 200
      resizeMin: undefined
      title: "Notes"
      type: "notes"
      typeId: "_udrzsy2w0"
      url: null
   */
    })
    .then(() => {
      inviteKeyListner.update({
        [randomId]: {
          dashboardId: newPostKey,
          friendId: uuid,
          id: randomId,
          time: Date.now(),
        },
      });
    })
    .then(() => {
      //Add new pivot Table
      userDashboardListner
        .child(uuid)
        .child(newPostKey)
        .set({
          settings: {
            sound: true,
            mail: true,
            notification: true,
            cursors: true,
            help: true,
            feedback: data ? data.feedback : false,
          },
          time: Date.now(),
          seen: false,
          removing: false,
          totalWidgets: 0,
          permissions: {
            drag: true,
            resize: true,
            subitems: true,
            menuitem: true,
            compactmenu: true,
            store: true,
            invite: true,
            details: true,
          },
          active: data.reload || data.createUser ? true : false,
          activity: {
            cursor: {
              id: "cursor" + uuid,
              forcedHidden: false,
              visible: true,
              action: "default",
              x: 0,
              y: 0,
            },
            menu: {
              aside: false,
              visible: true,
              xPosition: "center",
              yPosition: "top",
              x: 540,
              y: 20,
            },
          },
          backgroundimage: data.backgroundimage
            ? data.backgroundimage
            : {
                cropped: "",
                original: "",
                position: {
                  x: 0,
                  y: 0,
                },
                rotate: 0,
                scale: 1,
                userId: uuid,
              },
          id: newPostKey,
          colors: {
            backgroundColor: "#d1d1d1",
            cardColor: "rgba(255, 255, 255, 0.6)",
            chatColor: "#0075ff",
            checkboxColor: "#f95738",
            headerColor: "#7e73ac",
            iconColor: "#fff",
            listItemColor: "#0075ff",
            menuColor: "rgba(0,0,0,.76)",
            overviewColor: "#244781",
            sidebarColor: "#333",
          },
          public: {
            colors: {
              checkboxColor: "#f95738",
            },
          },
        })
        .then(function() {
          //Check if there are image handlers included
          if (data["image"] && data["image"]["handleData"]) {
            extraData = {
              avatar: {
                position: data["image"]["handleData"][0]["position"]
                  ? data["image"]["handleData"][0]["position"]
                  : "",
                rotate: data["image"]["handleData"][0]["rotate"],
                scale: data["image"]["handleData"][0]["scale"],
                userId: data["image"]["handleData"][0]["userId"],
              },
            };
          }

          if (!data["image"] || !data["image"]["imageData"]) {
            extraData = {
              avatar: {
                cropped: "",
                original: "",
                position: "",
                rotate: 0,
                scale: 1,
                userId: uuid,
              },
            };
          } else {
            uploadEditorImages(
              data["image"].imageData,
              null,
              data,
              "dashboards/" + newPostKey + "/avatar/"
            );
          }

          dashboardListner.child(newPostKey).update(extraData);
          // if (!isGhostUser) {
          //   window.location.reload();
          // }
        })
        .then(() => {
          //sent invites
          if (
            data.selectedFriends &&
            Object.keys(data.selectedFriends).length > 0
          ) {
            let imageData = "";
            dashboardListner
              .child(newPostKey)
              .child("avatar")
              .once("value", snapshot => {
                if (snapshot.val()) {
                  imageData = snapshot.val().cropped;
                }
              });
            Object.keys(data.selectedFriends).map(friendId => {
              if (friendId !== uuid) {
                inviteUser(friendId, "dashboards", newPostKey, {
                  name: data.title,
                  description: data.subtitle,
                  dashboardId: newPostKey,
                  image: imageData,
                  admin: data.isAdmin,
                });
              }
            });
          }
        });
    });
};

/** Creates a new list in the DB
 * @param {object} data - All the data that is necessary for creating a new list
 */
const createNewItem = (data, table, pivot, imageType) => {
  if (!data.image) {
    insertNewItemData("/resources/astronaut.svg", data, table, pivot);
  } else {
    if (imageType === "overviewAvatar") {
      insertNewItemData("/resources/astronaut.svg", data, table, pivot);
    } else {
      uploadSingleImage(
        data.image,
        table + "/public",
        data,
        "itemImage",
        table,
        pivot,
        imageType
      );
    }
  }
};

/** Update an excisting list in the DB
 * @param {object} data - All the data that is necessary for creating a new list
 */
const updateItem = (data, table) => {
  const path = table + "/public";
  const uuid = firebase.auth().currentUser.uid;
  const storageRef = firebase.storage().ref();
  const filePath = `${path}/${uuidv4()}`;
  const listRef = firebase
    .database()
    .ref()
    .child(table)
    .child(data.listId);

  const pivot =
    table && "user" + table.charAt(0).toUpperCase() + table.slice(1);

  // Add yourself to the selectedFriends because hey.. you are still in the list..
  data["selectedFriends"][uuid] = true;

  // Check if an user have to be removed
  removeUserFromUserList(
    data.listId,
    data["selectedFriends"],
    data["attendees"],
    pivot,
    table
  );

  // If a user is new to a list add the user to the userLists pivot table and let him/her know!
  addUserToUserList(
    data.listId,
    data["selectedFriends"],
    table,
    data["widgetId"],
    false
  );

  listRef
    .update({
      title: data.title && data.title,
      subTitle: data.subtitle && data.subtitle,
      attendees: data.selectedFriends && data.selectedFriends,
      lastEditedBy: firebase.auth().currentUser.uid,
      isPublic: data.isPublic && data.isPublic,
    })
    .then(() => {
      const publicListner = dashboardListner
        .child(data.dashboardId)
        .child("widgets")
        .child(data.widgetId)
        .child("public");

      if (data.isPublic) {
        publicListner.update({
          [data.listId]: data.isPublic ? true : false,
        });
      } else {
        publicListner.update({
          [data.listId]: null,
        });
      }
    })
    .then(() => {
      if (!data.image || !data["image"]["imageData"]) {
        listRef.update({
          avatar: {
            cropped: "",
            original: "",
            position: "",
            rotate: 0,
            scale: 1,
            userId: uuid,
          },
        });
      } else {
        uploadEditorImages(
          data["image"].imageData,
          null,
          data,
          table + "/" + data.listId + "/avatar/"
        );
      }
    })
    .then(() => {
      if (data.image && data["image"]["handleData"]) {
        listRef.child("avatar").update({
          position: data["image"]["handleData"][0]["position"]
            ? data["image"]["handleData"][0]["position"]
            : "",
          rotate: data["image"]["handleData"][0]["rotate"],
          scale: data["image"]["handleData"][0]["scale"],
          userId: data["image"]["handleData"][0]["userId"],
        });
      }
    });
};

/** Add a user to the userlists table
 * This function is called when a list is been updated and a new user is added the a list
 *
 * @param {object} data - All the data that is necessary for creating a new list
 */
const addUserToUserList = (listId, data, table, widgetId) => {
  if (!listId || !data || !table) return;
  const refPivot = firebase
    .database()
    .ref()
    .child("user" + table.charAt(0).toUpperCase() + table.slice(1));

  const ref = firebase
    .database()
    .ref()
    .child(table)
    .child(listId)
    .child("attendees");

  //Loop through the selected user and check if the user is not in the lists
  Object.entries(data).map(user => {
    refPivot
      .child(user[0])
      .child(listId)
      .once("value", snapshot => {
        if (!snapshot.val()) {
          // So the user is not in the list ey.. add the shit out of it
          refPivot
            .child(user[0])
            .child(listId)
            .update({
              new: true,
              unRead: 0,
              created: false,
              setActive: false,
              firstanimation: true,
              sortTime: -Date.now(),
              time: Date.now(),
              update: Date.now(),
              widgetId: widgetId,
              id: listId,
            })
            .then(() => {
              ref.update({
                [user[0]]: true,
              });
            });
        }
      });

    return true;
  });
};

/** Remove an user from a list
 * This function is called when a list is been updated and a user has been de-selected
 * Remove user from Lists-attendees and userLists-userId-list
 *
 * @param {object} data - All the data that is necessary for creating a new list
 */
const removeUserFromUserList = (listId, data, attendees, pivot, table) => {
  const pivotRef = firebase
    .database()
    .ref()
    .child(pivot);

  firebase
    .database()
    .ref()
    .child(table)
    .child(listId)
    .child("attendees")
    .once("value", snap => {
      //Check the attendees in the list and compare them with the users in the data object
      if (snap.val())
        Object.entries(snap.val()).map(user => {
          if (!(user[0] in data)) {
            // So the user has been deselected right... remove the !@#%#@#$
            pivotRef
              .child(user[0])
              .child(listId)
              .remove();
          }

          return true;
        });
    });
};

/** updates user avatar
 * @param {object} data - All the data that is necessary for creating a new list
 */
const setUserImage = (downloadURL, data) => {
  const path = firebase
    .database()
    .ref()
    .child("users")
    .child(data.userId)
    .child("public")
    .child("avatar");

  path.update({
    [data.type]: downloadURL,
    scale: data.scale,
    rotate: data.rotate,
    position: data.position,
  });
};

//Update documents
const setUserDocument = (
  downloadURL,
  activeId,
  type,
  name,
  typeSmall,
  fileName
) => {
  const path = firebase
    .database()
    .ref()
    .child("documentsharing")
    .child(activeId);

  // Get a key for a new Post.
  const newPostKey = path.child("documents").push().key;

  path
    .child("documents")
    .update({
      [newPostKey]: {
        url: downloadURL,
        type: type,
        name: name,
        fileName: fileName,
        typeSmall: typeSmall,
        time: Date.now(),
      },
    })
    .then(() => {
      path.child("totalItems").transaction(function(totalItems) {
        return (totalItems || 0) + 1;
      });
    });
};

/** updates user avatar
 * @param {object} data - All the data that is necessary for creating a new background image
 */
const setUserBackgroundImage = (downloadURL, data) => {
  // User ID
  const uid = firebase.auth().currentUser.uid;
  let activeId = data;
  if (!data) activeId = getActiveDashboardId();
  firebase
    .database()
    .ref()
    .child("userDashboards")
    .child(uid)
    .child(activeId)
    .child("backgroundimage")
    .set({
      url: downloadURL ? downloadURL : "",
      settings: JSON.stringify({ width: 1920, height: 1080, x: 0, y: 0 }),
    });
};

/** updates user avatar
 * @param {object} data - All the data that is necessary for creating a new header image
 */
const setUserHeaderImage = (downloadURL, data) => {
  // User ID
  const uid = firebase.auth().currentUser.uid;
  let activeId = data;
  if (!data) activeId = getActiveDashboardId();
  firebase
    .database()
    .ref()
    .child("userDashboards")
    .child(uid)
    .child(activeId)
    .child("headerimage")
    .set({
      url: downloadURL ? downloadURL : "",
      settings: JSON.stringify({ width: 1920, height: 100, x: 0, y: 0 }),
    });
};

/** Update the user screenshot or create one
 * @param {object} data - All the data that is necessary for create a screenshot
 */
const setUserDashboardScreenshot = downloadURL => {
  // User ID
  const uid = firebase.auth().currentUser.uid;
  // Active dashboard
  userDashboardListner.child(uid).once("value", snap => {
    snap.forEach(function(snapshot) {
      if (snapshot.val() && snapshot.val().active === true) {
        firebase
          .database()
          .ref()
          .child("userDashboards")
          .child(uid)
          .child(snapshot.key)
          .update({
            image: downloadURL ? downloadURL : "",
          });
      }
    });
  });
};

/** Puts the new list data into the DB
 * @param {object} data - All the data that is necessary for creating a new list
 */
const insertNewItemData = (downloadURL, data, table, pivot) => {
  const uuid = firebase.auth().currentUser.uid;
  let name = "";
  let extraData = {};
  let pivotDetails = null;

  data["selectedFriends"][uuid] = true;
  // Database Reference
  const ref = firebase
    .database()
    .ref()
    .child(table);

  // Get a key for a new Post.
  const newPostKey = ref.push().key;

  //Get the name of the creator
  userListner
    .child(uuid)
    .child("public")
    .once("value", snap => {
      name = snap.val().name;
    });

  switch (table) {
    default:
    case "notes":
      extraData = {
        maxCharacters: 10000,
      };
      pivotDetails = {
        colors: { noteColor: "#E7F6FD", noteTextColor: "#444444" },
      };
      break;
    case "whiteboards":
      pivotDetails = {
        brush: { brushColor: "#444", brushSize: "2" },
      };
      break;

    case "lists":
      extraData = {
        maxItems: 100,
      };
      break;

    case "calls":
      pivotDetails = {
        indicator: { status: "online" },
      };
      break;

    case "documentsharing":
      extraData = {
        maxItems: 12,
      };
      break;
  }

  //Check if there are image handlers included
  if (data["image"] && data["image"]["handleData"]) {
    extraData["avatar"] = {};

    extraData["avatar"] = {
      position: data["image"]["handleData"][0]["position"]
        ? data["image"]["handleData"][0]["position"]
        : "",
      rotate: data["image"]["handleData"][0]["rotate"],
      scale: data["image"]["handleData"][0]["scale"],
      userId: data["image"]["handleData"][0]["userId"],
    };
  }

  if (!data["image"] || !data["image"]["imageData"]) {
    extraData["avatar"] = {};

    extraData["avatar"] = {
      cropped: "",
      original: "",
      position: "",
      rotate: 0,
      scale: 1,
      userId: uuid,
    };
  }

  // Get the data ready to upload
  let postData = {
    //attendees: data['selectedFriends'],
    id: newPostKey,
    itemsColor: "#000",
    itemCount: 0,
    changedBy: uuid,
    lastEditedBy: uuid,
    owner: uuid,
    subTitle:
      data.subtitle !== ""
        ? data.subtitle
        : name
        ? name + " created this " + table.slice(0, -1)
        : "New item created",
    time: Date.now(),
    title: data.title,
    //userCount: Object.keys(data['selectedFriends']).length,
    positions: JSON.stringify([]),
    tempSubTitle: {
      active: false,
    },
    isPublic: data.isPublic,
  };

  if (extraData) postData = { ...extraData, ...postData };

  // Write the new post's data simultaneously in the posts list and the user's post list.
  var updates = {};
  updates["/" + table + "/" + newPostKey] = postData;
  // Put the data into the database
  firebase
    .database()
    .ref()
    .update(updates)
    .then(() => {
      // Looping through arrays the selected users and check if they can be added
      Object.keys(data["selectedFriends"]).map(friend => {
        //Check the number of subitems the user have created
        userDashboardListner
          .child(friend)
          .child(data.activeDashboardId)
          .child("widgets")
          .child(data.widgetId)
          .once("value", snapWidgetCheck => {
            if (
              snapWidgetCheck.val() &&
              snapWidgetCheck.val().totalItems < snapWidgetCheck.val().maxItems
            ) {
              let updates = {};
              let showNew = true;
              let created = false;
              let setActive = false;

              if (friend === firebase.auth().currentUser.uid) {
                showNew = false;
                created = true;
                setActive = true;
              }

              let postPivotData = {
                new: showNew,
                created: created,
                unRead: 0,
                setActive: setActive,
                firstanimation: true,
                sortTime: -Date.now(),
                time: Date.now(),
                update: Date.now(),
                widgetId: data.widgetId,
                id: newPostKey,
              };

              if (pivotDetails)
                postPivotData = { ...pivotDetails, ...postPivotData };

              updates[pivot + "/" + friend + "/" + newPostKey] = postPivotData;

              firebase
                .database()
                .ref()
                .update(updates);

              userDashboardListner
                .child(friend)
                .child(data.activeDashboardId)
                .child("widgets")
                .child(data.widgetId)
                .child("totalItems")
                .transaction(function(totalItems) {
                  return (totalItems || 0) + 1;
                });

              firebase
                .database()
                .ref()
                .child(table)
                .child(newPostKey)
                .child("attendees")
                .update({
                  [friend]: true,
                })
                .then(() => {
                  firebase
                    .database()
                    .ref()
                    .child(table)
                    .child(newPostKey)
                    .child("userCount")
                    .transaction(function(userCount) {
                      return (userCount || 0) + 1;
                    });
                });
            } else {
              userListner
                .child(friend)
                .child("public")
                .child("name")
                .once("value", snap => {
                  const text =
                    "Could not add " +
                    snap.val() +
                    " because the user exceeded the widget item limitations";
                  addNotification(
                    uuid,
                    "userInvites",
                    data.activeDashboardId,
                    text,
                    "error",
                    "/resources/friend.svg",
                    {},
                    "empty"
                  );
                });

              userListner
                .child(uuid)
                .child("public")
                .child("name")
                .once("value", snap => {
                  const Friendtext =
                    snap.val() +
                    " invited you for a widget, but you exceeded your widget item limitations!";
                  addNotification(
                    friend,
                    "userInvites",
                    data.activeDashboardId,
                    Friendtext,
                    "error",
                    "/resources/friend.svg",
                    {},
                    "empty"
                  );
                });
            }
          });
      });
    })
    .then(() => {
      let evt = new KeyboardEvent("keydown", { keyCode: 27, which: 27 });
      document.dispatchEvent(evt);
    })
    .then(() => {
      if (data["image"] && data["image"].imageData) {
        //UPLOAD POSSIBLE IMAGES ON NEW ITEMS
        uploadEditorImages(
          data["image"].imageData,
          null,
          data,
          table + "/" + newPostKey + "/avatar/"
        );
      }
    })
    .then(() => {
      if (data.isPublic) {
        dashboardListner
          .child(data.activeDashboardId)
          .child("widgets")
          .child(data.widgetId)
          .child("public")
          .update({
            [newPostKey]: true,
          });
      }
    });
};

/** URL check */
const validURL = str => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

/** setEditableImage */
const setEditableImage = (downloadURL, data, path, fileType, fileName) => {
  // let imageData;

  // if (data['image']['imageData']) {
  //   imageData = {
  //     [fileType]: downloadURL,
  //   };
  //   data['image'] = {};
  //   data['image'] = imageData;
  // } else {
  //   data[fileType] = downloadURL;
  //   imageData = data;
  // }
  //data[fileType] = downloadURL;
  //Upload the images
  const ref = firebase
    .database()
    .ref()
    .child(path);
  // Put the data into the database
  ref
    .update({
      [fileType]: downloadURL,
    })
    .then(() => {
      ref.child("fileNames").update({
        [fileType]: fileName,
      });
    });
};

/** uploadEditorImages */
const uploadEditorImages = (files, type, data, dbPath) => {
  for (const file of files) {
    if (!validURL(file.image)) {
      if (!isBlobURL(file.image)) {
        uploadSingleImage(
          file.image,
          dbPath,
          data,
          "editableImage",
          null,
          null,
          file.type
        );
      }
    }
  }
};

/** Upload single document */
const uploadSingleDocument = (file, path, activeId, name, typeSmall) => {
  const uuid = firebase.auth().currentUser.uid;
  const fileName = uuidv4();
  const storageRef = firebase.storage().ref();
  const filePath = `${path}/${name}`;
  const type = file.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)[1];
  // Create the file metadata
  const metadata = {
    contentType: type,
  };

  // Upload file and metadata to the object 'images/mountains.jpg'
  const uploadTask = storageRef
    .child(filePath)
    .putString(file, "data_url", metadata);

  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on(
    firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
    function(snapshot) {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      //const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      switch (snapshot.state) {
        default:
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          break;
      }
    },
    function(error) {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        default:
        case "storage/unauthorized":
          // User doesn't have permission to access the object
          break;

        case "storage/canceled":
          // User canceled the upload
          break;

        case "storage/unknown":
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    },
    function() {
      // Upload completed successfully, now we can get the download URL
      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
        setUserDocument(downloadURL, activeId, type, name, typeSmall, fileName);
      });
    }
  );
};

/** Upload single image */
const uploadSingleImage = (file, path, data, type, table, pivot, fileType) => {
  const storageRef = firebase.storage().ref();
  const fileName = uuidv4();
  const filePath = `${path}/${fileName}`;
  // Create the file metadata
  const metadata = {
    contentType: "image/jpg",
  };

  // Upload file and metadata to the object 'images/mountains.jpg'
  const uploadTask = storageRef
    .child(filePath)
    .putString(file, "data_url", metadata);

  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on(
    firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
    function(snapshot) {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      //const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      switch (snapshot.state) {
        default:
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          break;
      }
    },
    function(error) {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        default:
        case "storage/unauthorized":
          // User doesn't have permission to access the object
          break;

        case "storage/canceled":
          // User canceled the upload
          break;

        case "storage/unknown":
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    },
    function() {
      // Upload completed successfully, now we can get the download URL
      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
        switch (type) {
          default:
          case "editableImage":
            setEditableImage(downloadURL, data, path, fileType, fileName);
            break;

          case "document":
            setUserDocument(downloadURL, data);
            break;

          case "avatar":
            setUserImage(downloadURL, data);
            break;

          case "itemImage":
            insertNewItemData(downloadURL, data, table, pivot);
            break;

          case "backgroundimage":
            setUserBackgroundImage(downloadURL, data);
            break;

          case "headerimage":
            setUserHeaderImage(downloadURL, data);
            break;

          case "screenshot":
            setUserDashboardScreenshot(downloadURL, data);
        }
      });
    }
  );
};

/** Get nested object content by Key */
const getNestedContent = (ref, key, callback) => {
  ref.child(key).on("value", snap => {
    callback(snap);
  });
};

/** Get FireBase single ref data */
const getSingleRefFunction = (ref, callback) => {
  const dataArray = [];

  ref.on("value", snap => {
    snap.forEach(function(result) {
      dataArray.push(result.val());
      callback(dataArray);
    });
  });
};

/** Get FireBase data based on a pivot table */
const getPivotFunction = (ref, target, callback) => {
  const dataArray = [];

  ref
    .once("value", snap => {
      snap.forEach(function(result) {
        firebase
          .database()
          .ref(target)
          .child(result.key)
          .once("value", snapshot => {
            dataArray.push(snapshot.val());
          });
      });
    })
    .then(function() {
      callback(dataArray);
    });
};

/** Dennis */
const dennisFunction = () => {
  return "Sup Dennis";
};

/** FlipSwitch */
const flipSwitch = (switchValue, dashboardId, global) => {
  const uuid = firebase.auth().currentUser.uid;

  if (!global) {
    userDashboardListner
      .child(uuid)
      .child(dashboardId)
      .child("settings")
      .update(switchValue);
  } else {
    dashboardListner.child(dashboardId).update(switchValue);
  }
};

/** Search the chat */
const searchContent = (table, id, items, text) => {
  let results = {};
  firebase
    .database()
    .ref()
    .child(table)
    .child(id)
    .child(items)
    .orderByChild("text")
    .equalTo(text)
    .once("value", function(snapshot) {
      results = snapshot.val();
    });

  return results;
};

const userRelationsDashboardListner = firebase
  .database()
  .ref()
  .child("userRelationsDashboard");

const userRelationsListner = firebase
  .database()
  .ref()
  .child("userRelations");

const masterListner = firebase
  .database()
  .ref()
  .child("master");

/** dashboard listner */
const inviteKeyListner = firebase
  .database()
  .ref()
  .child("inviteKey");

/** dashboard listner */
const userDashboardListner = firebase
  .database()
  .ref()
  .child("userDashboards");

/** userDashboard listner */
const dashboardListner = firebase
  .database()
  .ref()
  .child("dashboards");

/** user listener */
const userListner = firebase
  .database()
  .ref()
  .child("users");

/** List listener */
const listListner = firebase
  .database()
  .ref()
  .child("lists");

/** Whiteboard listener */
const whiteboardListner = firebase
  .database()
  .ref()
  .child("whiteboards");

/** Document sharing */
const documentSharingListner = firebase
  .database()
  .ref()
  .child("documentsharing");

/** Call listener */
const callListner = firebase
  .database()
  .ref()
  .child("calls");

/** Chat listener */
const chatListner = firebase
  .database()
  .ref()
  .child("chats");

/** userThirdpartyListner */
const userThirdpartyListner = firebase
  .database()
  .ref()
  .child("userThirdParties");

/** Chat listener */
const thirdpartyListner = firebase
  .database()
  .ref()
  .child("thirdParties");

/** Friend listener */
const friendListner = firebase
  .database()
  .ref()
  .child("users");

/** Note listener */
const noteListner = firebase
  .database()
  .ref()
  .child("notes");

/** Drawings listener */
const drawListner = firebase
  .database()
  .ref()
  .child("drawings");

/** feedbackListner */
const feedbackListner = firebase
  .database()
  .ref()
  .child("feedback");

/** UserList listener */
const userListListner = firebase
  .database()
  .ref()
  .child("userLists");

/** UserWhiteboard listener */
const userWhiteboardListner = firebase
  .database()
  .ref()
  .child("userWhiteboards");

/** UserDocumentsharing listener */
const userDocumentSharingListner = firebase
  .database()
  .ref()
  .child("userDocumentsharing");

/** UserCalls listener */
const userCallListner = firebase
  .database()
  .ref()
  .child("userCalls");

/** UserChat listener */
const userChatListner = firebase
  .database()
  .ref()
  .child("userChats");

/** UserFriend listener */
const userFriendListner = firebase
  .database()
  .ref()
  .child("userFriends");

/** UserNote listener */
const userNoteListner = firebase
  .database()
  .ref()
  .child("userNotes");

/** UserNote listener */
const userDrawListner = firebase
  .database()
  .ref()
  .child("userDraws");

/** Remove image from list */
const removeImage = data => {
  firebase
    .database()
    .ref()
    .child(data.path)
    .remove()
    .then(() => {
      firebase
        .database()
        .ref()
        .child(data.table)
        .child(data.typeId)
        .child(data.itemTypes)
        .child(data.itemId)
        .child("totalImages")
        .transaction(function(totalImages) {
          return (totalImages || 1) - 1;
        });
    });
};

/** Remove a sub item */
const removeSubItem = (type, typeId, itemId) => {
  // Remove from position string
  removeFromPositionString(type, typeId, itemId);

  firebase
    .database()
    .ref()
    .child(type)
    .child(typeId)
    .child("items")
    .child(itemId)
    .child("images")
    .once("value", snap => {
      if (snap.exists()) {
        let promises = [];
        //Go through each widget inside the dashboard table
        promises.push(
          snap.forEach(function(childSnapshot) {
            if (childSnapshot.val() && childSnapshot.val().storageId) {
              firebase
                .storage()
                .ref()
                .child(type)
                .child(typeId)
                .child("items")
                .child("public")
                .child(childSnapshot.val().storageId)
                .delete();
            }
          })
        );

        // Wait for all promises to resolve
        Promise.all(promises).then(function(res) {
          firebase
            .database()
            .ref()
            .child(type)
            .child(typeId)
            .child("items")
            .child(itemId)
            .remove()
            .then(() => {
              firebase
                .database()
                .ref()
                .child(type)
                .child(typeId)
                .child("itemCount")
                .transaction(function(itemCount) {
                  return (itemCount || 1) - 1;
                });
            });
        });
      } else {
        firebase
          .database()
          .ref()
          .child(type)
          .child(typeId)
          .child("items")
          .child(itemId)
          .remove()
          .then(() => {
            firebase
              .database()
              .ref()
              .child(type)
              .child(typeId)
              .child("itemCount")
              .transaction(function(itemCount) {
                return (itemCount || 1) - 1;
              });
          });
      }
    });
};

/** Switch from dashboard */
const switchDashboard = id => {
  //Get the user ID
  const uid = firebase.auth().currentUser.uid;
  userDashboardListner
    .child(uid)
    .once("value", function(snap) {
      snap.forEach(function(childSnapshot) {
        if (childSnapshot.key === id) {
          childSnapshot.ref.update({ active: true });
        } else {
          childSnapshot.ref.update({ active: false });
        }
      });
    })
    .then(() => {
      userDashboardListner
        .child(uid)
        .child(id)
        .update({
          seen: true,
        });
      const url = new URL(window.location.origin);
      window.history.pushState({}, "", url);
      window.location.reload();
    });
};

/** removeFromPositionString */
const removeFromPositionString = (type, typeId, itemId) => {
  const ref = firebase
    .database()
    .ref()
    .child(type)
    .child(typeId);
  ref.child("positions").once("value", function(snapshot) {
    if (snapshot.exists()) {
      const filteredItems = JSON.parse(snapshot.val()).filter(
        item => item !== itemId
      );
      ref.update({ positions: JSON.stringify(filteredItems) });
    }
  });
};

/** Remove a item */
const removeItem = (type, typeId, dashboardId, activeId) => {
  //Get the user ID
  const uuid = firebase.auth().currentUser.uid;
  const pivot = "user" + type.charAt(0).toUpperCase() + type.slice(1);

  // Remove from the pivot table also
  firebase
    .database()
    .ref()
    .child(pivot)
    .child(uuid)
    .child(typeId)
    .remove();

  //Remove the userDashboard widget and handle the number
  userDashboardListner
    .child(uuid)
    .child(dashboardId)
    .child("widgets")
    .child(activeId)
    .child("totalItems")
    .transaction(function(totalItems) {
      return (totalItems || 1) - 1;
    });

  // Remove the user from the List attendees
  removeFromListAttendees(type, typeId, activeId, dashboardId);
};

/** Remove User from list attendees */
const removeFromListAttendees = (type, typeId, activeId, dashboardId) => {
  //Get the user ID
  const uid = firebase.auth().currentUser.uid;

  //Listner
  const listner = firebase
    .database()
    .ref()
    .child(type)
    .child(typeId)
    .child("attendees");

  // Remove from the pivot table also
  listner
    .child(uid)
    .remove()
    .then(() => {
      // If there are no attendees left remove the complete list
      listner.once("value", function(snapshot) {
        if (!snapshot.exists()) {
          removeCompleteList(type, typeId, activeId, dashboardId);
        }
      });
    });
};

/** Remove the complete list */
const removeCompleteList = (type, typeId, activeId, dashboardId) => {
  firebase
    .database()
    .ref()
    .child(type)
    .child(typeId)
    .once("value", function(snapshot) {
      //Check for documents
      if (snapshot.val() && snapshot.val().documents) {
        Object.values(snapshot.val()["documents"]).map(item => {
          firebase
            .storage()
            .ref()
            .child("documents")
            .child(typeId)
            .child(item.name)
            .delete();
        });
      }

      //Check for images TODO
    })
    .then(() => {
      firebase
        .database()
        .ref()
        .child(type)
        .child(typeId)
        .remove()
        .then(() => {
          dashboardListner
            .child(dashboardId)
            .child("widgets")
            .child(activeId)
            .child("public")
            .child(typeId)
            .remove();
        });
    });
};

/** Set image settings */
const setImageSettings = (type, settings, activeDashboardId) => {
  const uid = firebase.auth().currentUser.uid;
  let activeId = activeDashboardId;
  if (!activeDashboardId) activeId = getActiveDashboardId();
  firebase
    .database()
    .ref()
    .child("userDashboards")
    .child(uid)
    .child(activeId)
    .child(type)
    .update({ settings: settings });
};

/** Remove the background image */
const removeBackgroundImage = data => {
  const uid = firebase.auth().currentUser.uid;
  let activeId = data.activeDashboardId;
  if (!data.activeDashboardId) activeId = getActiveDashboardId();
  firebase
    .database()
    .ref()
    .child("userDashboards")
    .child(uid)
    .child(activeId)
    .child("backgroundimage")
    .update({ url: "" });

  document.getElementById("preview-" + data.previewId).style.cssText = "";
  document.getElementById("preview-" + data.previewId).style.backgroundImage =
    "url()";
};

/** Get pivot Data */
const getWidgetDetails = (activeDashboardId, widgetId) => {
  let dataObject = {};
  dashboardListner
    .child(activeDashboardId)
    .child("widgets")
    .once("value", snap => {
      snap.forEach(function(childSnapshot) {
        if (childSnapshot.val() && childSnapshot.key === widgetId) {
          dataObject["type"] = childSnapshot.val().type;
          dataObject["widgetId"] = widgetId;
          dataObject["pivot"] = childSnapshot.val().pivot;
        }
      });
    });

  return dataObject;
};

/** Remove the background image */
const removeHeaderImage = data => {
  const uid = firebase.auth().currentUser.uid;
  let activeId = data.activeDashboardId;
  if (!data.activeDashboardId) activeId = getActiveDashboardId();
  firebase
    .database()
    .ref()
    .child("userDashboards")
    .child(uid)
    .child(activeId)
    .child("headerimage")
    .update({ url: "" });

  document.getElementById("preview-" + data.previewId).style.cssText = "";
  document.getElementById("preview-" + data.previewId).style.backgroundImage =
    "url()";
};

/** Set a custom color to the application */
const setColor = (type, color, publicColor, dashboardId, itemId) => {
  const uid = firebase.auth().currentUser.uid;
  let path = "userDashboards/" + uid + "/" + dashboardId + "/colors/" + type;

  if (publicColor)
    path =
      "userDashboards/" + uid + "/" + dashboardId + "/public/colors/" + type;

  if (type === "noteColor" && itemId)
    path = "userNotes/" + uid + "/" + itemId + "/colors/" + type;

  if (type === "noteTextColor" && itemId)
    path = "userNotes/" + uid + "/" + itemId + "/colors/" + type;

  if (type === "brushColor" && itemId)
    path = "userWhiteboards/" + uid + "/" + itemId + "/brush/" + type;

  firebase
    .database()
    .ref()
    .child(path)
    .set(color);
};

/** Reset the list item positions
 * Can be used when removing a list item and the list have to reorder
 */
const resetListItemsPositions = (listId, orderedList, table, itemId) => {
  if (orderedList.length === 0) return;
  firebase
    .database()
    .ref(table)
    .child(listId)
    .update({ positions: JSON.stringify(orderedList) })
    .then(() => {
      if (itemId && table) updateItemByTimestamp(table, listId, itemId);
    });
};

/** Update an itemfield by changing the timestamp */
const updateItemByTimestamp = (table, id, itemId) => {
  firebase
    .database()
    .ref(table)
    .child(id)
    .child("items")
    .child(itemId)
    .update({ update: Date.now() });
};

/** Get all the user dashboards */
const getUserdashboards = () => {
  let dataObject = {};
  //User id
  const uid = firebase.auth().currentUser.uid;
  userDashboardListner.child(uid).once("value", snap => {
    snap.forEach(function(childSnapshot) {
      dashboardListner.child(childSnapshot.key).once("value", snap => {
        dataObject[childSnapshot.key] = {
          active: childSnapshot.val().active,
          id: snap.key,
          screenshot: snap.val().screenshot,
        };
      });
    });
  });

  return dataObject;
};

/** checks for new friends */
const newFriendsWatcher = activeDashboardId => {
  let dataObject = {};
  const uid = firebase.auth().currentUser.uid;
  dashboardListner
    .child(activeDashboardId)
    .child("members")
    .once("value", snap => {
      snap.forEach(function(snapshot) {
        if (snapshot.key() !== uid) {
          userListner
            .child(snapshot.key())
            .child("public")
            .once("value", userData => {
              dataObject[snapshot.key] = userData.val();
            });
        }
      });
    });
  return dataObject;
};

/** Get the current active dashboard ID */
const getActiveDashboardId = () => {
  let dataObject = {};
  //User id
  const uid = firebase.auth().currentUser.uid;

  userDashboardListner.child(uid).once("value", snap => {
    snap.forEach(function(snapshot) {
      if (snapshot.val() && snapshot.val().active === true) {
        dataObject["active"] = snapshot.key;
      }
    });
  });
  return dataObject["active"];
};

/** Update the user coordinates */
const updateCoordinates = (x, y, dashboardId) => {
  const uid = firebase.auth().currentUser.uid;
  let path = "userDashboards/" + uid + "/" + dashboardId + "/activity/cursor/";

  firebase
    .database()
    .ref()
    .child(path)
    .update({ x: x, y: y });
};

/** Update the user coordinates bubble component */
const updateBubbleCoordinates = (
  x,
  y,
  aside,
  dashboardId,
  xPosition,
  yPosition,
  type
) => {
  const uid = firebase.auth().currentUser.uid;
  let path =
    "userDashboards/" + uid + "/" + dashboardId + "/activity/bubble/" + type;

  firebase
    .database()
    .ref()
    .child(path)
    .update({
      x: x,
      y: y,
      aside: aside,
      xPosition: xPosition,
      yPosition: yPosition,
    });
};

/** Update the user coordinates */
const updateMenuCoordinates = (
  x,
  y,
  aside,
  dashboardId,
  xPosition,
  yPosition
) => {
  const uid = firebase.auth().currentUser.uid;
  let path = "userDashboards/" + uid + "/" + dashboardId + "/activity/menu/";

  firebase
    .database()
    .ref()
    .child(path)
    .update({
      x: x,
      y: y,
      aside: aside,
      xPosition: xPosition,
      yPosition: yPosition,
    });
};

/** Update the user coordinates */
const updateMenuVisibility = (visible, dashboardId) => {
  const uid = firebase.auth().currentUser.uid;
  let path = "userDashboards/" + uid + "/" + dashboardId + "/activity/menu/";

  firebase
    .database()
    .ref()
    .child(path)
    .update({ visible: visible });
};

/** Update the user status */
const updateUserStatus = status => {
  const uuid = firebase.auth().currentUser.uid;
  userListner
    .child(uuid)
    .child("public")
    .update({ indicator: status, forcedIndicator: status });
};

/** Update the Card after a drag and drop action */
const updateCardByDragAndDrop = (dragInfo, dropInfo) => {
  switch (dragInfo.type) {
    case "users": // or 'running'
      // If a user is new to a list add the user to the userLists pivot table and let him/her know!
      addUserToUserList(
        dropInfo.activeItemId,
        { [dragInfo.itemId]: dragInfo.itemId },
        dropInfo.type,
        dropInfo.widgetId,
        true
      );
      break;
    case "overview": // or 'running'
      break;
    default:
      break;
  }
};

/** createNewWidget */
const createNewWidget = (
  widgetInfo,
  dashboardId,
  activeDashboardId,
  newPostKey
) => {
  if (Object.keys(widgetInfo).length <= 0) return;
  const uid = firebase.auth().currentUser.uid;
  let path = "dashboards/" + dashboardId + "/widgets/";

  // Database Reference
  const widgetRef = firebase
    .database()
    .ref()
    .child(path);

  let promises = [];
  //Go through each widget inside the dashboard table
  promises.push(
    createNewStudentsplusWidget(
      newPostKey,
      uid,
      widgetInfo,
      dashboardId,
      activeDashboardId,
      widgetRef
    )
  );

  // Wait for all promises to resolve
  Promise.all(promises).then(function(res) {
    setTimeout(function() {
      return;
    }, 1500);
  });
};

/** Create a new chat widget */
const createNewStudentsplusWidget = (
  widgetId,
  uid,
  widgetInfo,
  dashboardId,
  activeDashboardId,
  widgetRef
) => {
  const uuid = firebase.auth().currentUser.uid;
  let listner = null;
  let pivotListner = null;
  let title = "";
  let subtitle = "";
  let name = "";
  let url = "";
  let newResult = false;
  let pivotDetails = null;
  let mainDetails = null;

  userListner
    .child(uuid)
    .child("public")
    .once("value", snap => {
      name = snap.val().name;
    });
  switch (widgetInfo.type) {
    default:
    case "thirdParties":
      listner = thirdpartyListner;
      pivotListner = userThirdpartyListner;
      title = "My new " + widgetInfo.title;
      subtitle = name ? "Created by " + name : "";
      url = widgetInfo.url;
      break;
    case "chats":
      listner = chatListner;
      pivotListner = userChatListner;
      title = "My new chat";
      subtitle = name ? "Created by " + name : "";
      break;
    case "lists":
      listner = listListner;
      pivotListner = userListListner;
      title = "My new list";
      subtitle = name ? "Created by " + name : "";
      mainDetails = { maxItems: 100 };
      break;
    case "notes":
      listner = noteListner;
      pivotListner = userNoteListner;
      title = "My new note";
      subtitle = name ? "Created by " + name : "";
      mainDetails = { text: "", maxCharacters: 10000 };
      pivotDetails = {
        colors: { noteColor: "#E7F6FD", noteTextColor: "#444444" },
      };
      break;
    case "whiteboards":
      listner = whiteboardListner;
      pivotListner = userWhiteboardListner;
      title = "My new whiteboard";
      subtitle = name ? "Created by " + name : "";
      pivotDetails = {
        brush: { brushColor: "#444", brushSize: "2" },
      };
      break;
    case "documentsharing":
      listner = documentSharingListner;
      pivotListner = userDocumentSharingListner;
      title = "My new documents";
      subtitle = name ? "Created by " + name : "";
      mainDetails = { maxItems: 12 };
      break;
    case "calls":
      listner = callListner;
      pivotListner = userCallListner;
      title = "My new call";
      subtitle = name ? "Created by " + name : "";
      pivotDetails = {
        indicator: { status: "online" },
      };
      break;
  }

  //Get the members of the active dashboard
  const allMembers = getMembersDashboard(dashboardId);

  //Insert new key based on listner
  const newKey = listner.push().key;
  //Create the object for the default object
  let data = {
    attendees: allMembers,
    owner: uid,
    itemCount: 0,
    id: newKey,
    image: "",
    lastEditedBy: uid,
    isPublic: true,
    time: Date.now(),
    title: title,
    subTitle: subtitle,
    userCount: Object.keys(allMembers).length,
    positions: JSON.stringify([]),
    tempSubTitle: {
      active: false,
    },
    url: url ? url : null,
  };
  if (mainDetails) data = { ...mainDetails, ...data };

  //Create the object for the pivot table
  let pivotData = {
    created: true,
    unRead: 0,
    firstanimation: false,
    new: false,
    setActive: true,
    sortTime: -Date.now(),
    time: Date.now(),
    update: Date.now(),
    widgetId: widgetId,
    id: newKey,
  };
  if (pivotDetails) pivotData = { ...pivotDetails, ...pivotData };

  //BVetter promise
  return new Promise(function(resolve, reject) {
    dashboardListner
      .child(dashboardId)
      .child("members")
      .once("value", snap => {
        data.attendees = snap.val();
      })
      .then(() => {
        //Put the new object in the DB
        listner
          .child(newKey)
          .update(data)
          .then(() => {
            if (data.attendees) {
              dashboardListner
                .child(dashboardId)
                .child("maxWidgets")
                .once("value", snap => {
                  const maxWidgets = snap.val();
                  //Put the new object in the pivot table
                  Object.entries(data.attendees).forEach(member => {
                    userDashboardListner
                      .child(member[0])
                      .child(dashboardId)
                      .child("totalWidgets")
                      .once("value", totalSnap => {
                        //Check if the total count of the user widgets is smaller than the max number
                        if (totalSnap.val() < maxWidgets) {
                          pivotListner
                            .child(member[0])
                            .child(newKey)
                            .update(pivotData)
                            .then(() => {
                              userDashboardListner
                                .child(member[0])
                                .child(dashboardId)
                                .child("widgets")
                                .child(widgetId)
                                .update({
                                  totalItems: 1,
                                  maxItems: 10,
                                })
                                .then(() => {
                                  userDashboardListner
                                    .child(member[0])
                                    .child(dashboardId)
                                    .child("totalWidgets")
                                    .transaction(function(totalWidgets) {
                                      return (totalWidgets || 0) + 1;
                                    });
                                })
                                .then(() => {
                                  widgetRef
                                    .child(widgetId)
                                    .update({
                                      id: widgetId,
                                      time: Date.now(),
                                      typeId: newKey,
                                      type: widgetInfo.type,
                                      owner: uid,
                                      creator: widgetInfo.creator,
                                      hasHeader: widgetInfo.hasHeader,
                                      hasOverview: widgetInfo.hasOverview,
                                      hasPivot: widgetInfo.hasPivot,
                                      singleton: widgetInfo.singleton
                                        ? true
                                        : false,
                                      resizeMin: widgetInfo.resizeMin
                                        ? widgetInfo.resizeMin
                                        : null,
                                      resizeMax: widgetInfo.resizeMax
                                        ? widgetInfo.resizeMax
                                        : null,
                                    })
                                    .then(() => {
                                      return resolve({
                                        widgetId: widgetId,
                                        newKey: newKey,
                                        pivotData: {
                                          [newKey]: pivotData,
                                        },
                                        data: {
                                          [newKey]: data,
                                        },
                                      });
                                    })
                                    .then(() => {
                                      dashboardListner
                                        .child(dashboardId)
                                        .child("widgets")
                                        .child(widgetId)
                                        .child("public")
                                        .update({
                                          [newKey]: true,
                                        });
                                    });
                                });
                            });
                        }
                      });
                  });
                });
            }
          });
      });
  });
};

/** Get all the members of the active dashboard */
const getMembersDashboard = dashboardId => {
  let dataObject = {};
  dashboardListner
    .child(dashboardId)
    .child("members")
    .once("value", snap => {
      dataObject = snap.val();
    });
  return dataObject;
};

/** Get widget data */
const getPivotData = (pivotName, widgetId) => {
  let dataObject = {};
  const uuid = firebase.auth().currentUser.uid;

  firebase
    .database()
    .ref()
    .child(pivotName)
    .child(uuid)
    .once("value", snap => {
      snap.forEach(function(childSnapshot) {
        if (childSnapshot.val() && childSnapshot.val().widgetId === widgetId) {
          dataObject[childSnapshot.key] = childSnapshot.val();
        }
      });
    });
  return dataObject;
};

/** get Pivot Data */
const getWidgetData = (type, pivotData) => {
  const uuid = firebase.auth().currentUser.uid;
  let dataObject = {};
  firebase
    .database()
    .ref()
    .child(type)
    .once("value", snap => {
      return snap.forEach(function(childSnapshot) {
        if (childSnapshot.key in pivotData) {
          childSnapshot.forEach(function(user) {
            if (user.key !== uuid) {
              dataObject[user.key] = getUserData(user.key);
            }
          });
        }
      });
    });
  return dataObject;
};

/** Handle user permissions */
const handleUserPermissions = (activeDashboardId, userId) => {
  dashboardListner
    .child(activeDashboardId)
    .child("creators")
    .once("value", snapCreator => {
      if (
        snapCreator.val() &&
        !Object.keys(snapCreator.val()).some(v => v == userId)
      ) {
        //Get the current user permissions
        userDashboardListner
          .child(userId)
          .child(activeDashboardId)
          .child("role")
          .child("type")
          .once("value", snap => {
            if (snap.val()) {
              let permissionObject = {};
              //Loop through every role that is assigned to the user
              snap.forEach(function(childSnapshot) {
                dashboardListner
                  .child(activeDashboardId)
                  .child("roles")
                  .child(childSnapshot.key)
                  .child("permissions")
                  .once("value", snapPermis => {
                    if (snapPermis.val()) {
                      //Compare the permissions
                      if (Object.keys(permissionObject).length <= 0) {
                        permissionObject = snapPermis.val();
                      } else {
                        Object.entries(permissionObject).map(value => {
                          if (!permissionObject[value[0]]) {
                            permissionObject[value[0]] = snapPermis.val()[
                              value[0]
                            ];
                          }
                        });
                      }

                      userDashboardListner
                        .child(userId)
                        .child(activeDashboardId)
                        .child("permissions")
                        .update(permissionObject);
                    }
                  });
              });
            } else {
              //Update to the default value
              dashboardListner
                .child(activeDashboardId)
                .child("permissions")
                .child("everybody")
                .once("value", snapshot => {
                  userDashboardListner
                    .child(userId)
                    .child(activeDashboardId)
                    .child("permissions")
                    .update({
                      drag: snapshot.val(),
                      resize: snapshot.val(),
                      subitems: snapshot.val(),
                      menuitem: snapshot.val(),
                      compactmenu: snapshot.val(),
                      store: snapshot.val(),
                      invite: snapshot.val(),
                      details: snapshot.val(),
                    });
                });
            }
          });
      }
    });
};

/**
 * Handle the user feedback
 */
const feedbackHandler = (activeDashboardId, type, message) => {
  const feedbackPushId = feedbackListner.child(type).push();
  feedbackListner
    .child(type)
    .child(feedbackPushId.key)
    .set({
      message: message,
      time: Date.now(),
      dashboardId: activeDashboardId,
    });
};

export {
  createScreenshot,
  getNestedContent,
  getPivotFunction,
  getSingleRefFunction,
  dennisFunction,
  signInWithGoogle,
  uploadSingleDocument,
  uploadSingleImage,
  createNewItem,
  updateItem,
  uploadMultipleImage,
  createNewSubItem,
  updateSubTitle,
  updateTempSubTitle,
  removeFriend,
  listListner,
  whiteboardListner,
  documentSharingListner,
  callListner,
  masterListner,
  userRelationsDashboardListner,
  userRelationsListner,
  inviteKeyListner,
  chatListner,
  thirdpartyListner,
  friendListner,
  noteListner,
  drawListner,
  feedbackListner,
  userListListner,
  undoRequest,
  userWhiteboardListner,
  userDocumentSharingListner,
  userCallListner,
  userThirdpartyListner,
  userChatListner,
  userFriendListner,
  userNoteListner,
  userDrawListner,
  userListner,
  dashboardListner,
  userDashboardListner,
  removeImage,
  removeNotification,
  removeAllNotification,
  removeBackgroundImage,
  removeHeaderImage,
  flipSwitch,
  removeItem,
  removeSubItem,
  getActiveDashboardId,
  setImageSettings,
  resetListItemsPositions,
  getUserData,
  getData,
  getItemData,
  getFriendsData,
  updateItemText,
  setColor,
  searchContent,
  getUserdashboards,
  switchDashboard,
  getWidgetDetails,
  updateCardByDragAndDrop,
  updateCoordinates,
  updateMenuCoordinates,
  updateBubbleCoordinates,
  updateMenuVisibility,
  updateUserStatus,
  addNotification,
  createNewWidget,
  getWidgetData,
  getPivotData,
  createGhostUser,
  registerUser,
  newFriendsWatcher,
  inviteUser,
  declineRequest,
  acceptRequest,
  addToFriends,
  inviteUserDashboard,
  addToDashboard,
  uploadEditorImages,
  updateDashboard,
  createDashboard,
  addUserByLink,
  removeCompleteDashboard,
  removeUserFromDashboard,
  updateChatMessage,
  getAllData,
  handleUserPermissions,
  feedbackHandler,
  createExternalUser,
  createRandomColor,
  getConfig,
};

export default firebase;
