import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import firebase, {
  userListner,
  chatListner,
  createNewSubItem,
  updateSubTitle,
} from '../../firebase';
import { updateCursorEvent } from '../Cursor';
import MessagesHeader from './MessagesHeader';
import MessageForm from './MessageForm';
import Message from './Message';
import Grid from '../Grid';
import LazyLoad from '../LazyLoad';
import Avatar from '../Avatar';
import Group from '../Group';
import { globalDragInfo, storeWidgetDrag } from '../../redux/actions';
import Animation from '../LottieControl';
import EmptyState from '../EmptyState';
import Audio from '../Audio';
import Help from '../Help';
import theme, { rem } from '../../theme';

const StyledMainWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: ${rem(17)} ${rem(17)} 0 ${rem(17)};
`;

const StyledContentWrapper = styled.div`
  background: #fff;
  display: flex;
  width: 100%;
  border-radius: 15px;
  background: whitesmoke;
  padding-bottom: ${rem(8)};
  margin-bottom: 0.2rem;
`;

const StyleTypeIndicator = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100% - ${rem(50)});
`;

const StyledTypingWrapper = styled(Group)`
  margin-bottom: ${rem(10)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledBadge = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: auto;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
`;

const StyledTimeIndicator = styled.div`
  transition: 0.2s;
  background: rgba(229, 229, 230, 0.8);
  color: ${theme.color.dark[80]};
  margin-top: ${rem(5)};
  padding: ${rem(5)} ${rem(10)};
  border-radius: ${rem(8)};
  z-index: 10;
  text-transform: uppercase;
  font-size: 0.65rem;
  font-weight: 600;
  opacity: 0;
`;

const StyledGroup = styled(Group)`
  flex-direction: column;
`;

const StyledMain = styled.div`
  display: flex;
  height: 100%;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

/** todo */
const ChatContent = props => {
  const {
    currentChatId,
    currentUser,
    friendData,
    activeDashboardId,
    widgetId,
  } = props;

  const uuid = firebase.auth().currentUser.uid;
  const messagesRef = firebase.database().ref('chats');
  const [user] = useState(currentUser);
  const [allMessages, setAllMessages] = useState({});
  const [typingUsers, setTypingUsers] = useState({});
  const [playSound, setPlaySound] = useState(false);
  const scrollRef = useRef();
  const searchRef = useRef();
  const typeInfoRef = useRef();
  const [errorType, setErrorType] = useState(false);

  const chatMessageRef = useRef();

  //Translation
  const [t] = useTranslation(['help', 'emptystate']);

  useEffect(() => {
    if (currentChatId) settingsListner();
  }, []);

  /** settingsListner */
  const settingsListner = () => {
    const listner = chatListner.child(currentChatId);
    listner.child('tempSubTitle').on('value', snap => {
      if (snap.exists()) {
        if (snap.val().tempCreator) {
          const key = Object.keys(snap.val().tempCreator)[0];
          if (typeInfoRef.current) {
            setTypingUsers(snap.val().tempCreator);
            typeInfoRef.current.value = snap.val().tempCreator;
            if (uuid === key)
              updateCursorEvent(uuid, activeDashboardId, 'typing');
          } else {
            typeInfoRef.current.value = null;
            setTypingUsers({});
            if (uuid === key)
              updateCursorEvent(uuid, activeDashboardId, 'default');
          }
        } else {
          typeInfoRef.current.value = null;
          setTypingUsers({});
          if (uuid) updateCursorEvent(uuid, activeDashboardId, 'default');
        }
      }
    });

    listner.child('items').once('value', snap => {
      setAllMessages(snap.val());
    });

    listner
      .child('items')
      .orderByChild('update')
      .startAt(Date.now())
      .on('child_added', snap => {
        if (document.visibilityState !== 'visible' && snap.val().owner !== uuid)
          setPlaySound(true);
        allMessages[snap.key] = snap.val();
        setAllMessages(allMessages);
      });
  };

  /** handleSearchChange  */
  const handleSearchChange = text => {
    if (allMessages) handleInputChangeFilter(allMessages, text);
  };

  /** handelScroll */
  const handleScroll = () => {
    if (scrollRef.current && scrollRef.current.scrollComponent.container) {
      const container = scrollRef.current.scrollComponent.container.firstChild;
      container.scrollTop = container.scrollHeight - container.clientHeight;
    }
  };

  /** Handle type indicator */
  const handleTypeIndicator = userId => {
    let dataObject = {};
    userListner
      .child(userId)
      .child('public')
      .once('value', snap => {
        dataObject = snap.val();
      });

    return (
      <StyledGroup>
        <Avatar
          source={dataObject.avatar && dataObject.avatar.cropped}
          title={dataObject.name && dataObject.name}
          color={dataObject.color && dataObject.color && dataObject.color}
        />
        <Animation
          animation='typing'
          animationWidth={80}
          animationHeight={20}
        />
      </StyledGroup>
    );
  };

  /** Handles the filtering of the data */
  const handleInputChangeFilter = (chatsMessages, text) => {
    const result = Object.values(chatsMessages).filter(singleMessage => {
      return singleMessage.text.toLowerCase().search(text) !== -1;
    });

    Object.values(chatsMessages).forEach(function(value) {
      if (document.getElementById(value.id))
        document.getElementById(value.id).classList.add('hide');
    });

    result.forEach(function(value) {
      if (document.getElementById(value.id))
        document.getElementById(value.id).classList.remove('hide');
    });
    handleScroll();
  };

  /**
   * Handle Drag Over
   */
  const handleDragOver = (ev, id) => {
    ev.preventDefault();
    let typeSmall = '';
    let isImage = false;

    if (props.storeDrag) return;

    if (ev.dataTransfer.types && ev.dataTransfer.types[0] == 'Files') {
      //Drag from outside the browser
    } else if (props.dragInfo.name) {
      typeSmall = props.dragInfo.name.split('.').pop();
      isImage = props.dragInfo.datatype.includes('image');

      if (isImage) {
        typeSmall = 'image';
      }
    }

    if (typeSmall === 'image') {
      document.getElementById(id).style.backgroundColor = theme.color.success;
      document.getElementById(id).style.transition = '0.35s';
    }
  };

  /**
   * Handle drop
   */
  const handleDrop = async (ev, id) => {
    ev.preventDefault();
    document.getElementById(id).style.backgroundColor = '';
    document.getElementById(id).style.transition = '';

    //Check if the drag is from the dashboard itself
    if (props.dragInfo.type === 'document') {
      let typeSmall = props.dragInfo.name.split('.').pop();
      const isImage = props.dragInfo.datatype.includes('image');
      const reader = new FileReader();
      let blob = null;
      let xhr = new XMLHttpRequest();

      if (isImage) {
        typeSmall = 'image';
      }

      switch (typeSmall) {
        default:
          return;
        case 'image': {
          xhr.onload = function() {
            createNewSubItem(
              firebase.auth().currentUser.uid,
              currentChatId,
              '',
              [xhr.response],
              'chats',
              'items'
            );

            updateSubTitle('chats', currentChatId, '📷 Image');
          };
          xhr.open('GET', props.dragInfo.url);
          xhr.responseType = 'blob';
          xhr.send();
          break;
        }
      }

      props.globalDragInfo({});
    } else if (ev && ev.dataTransfer && ev.dataTransfer.types) {
      //Check if external drop e.g. local files
      for (var i = 0; i < ev.dataTransfer.types.length; i++) {
        if (ev.dataTransfer.types && ev.dataTransfer.types[i] == 'Files') {
          let currentFile = ev.dataTransfer.items[i];
          let type = currentFile.type.slice(0, currentFile.type.indexOf('/'));
          let file = ev.dataTransfer.files[0];
          let typeSmall = file.name.split('.').pop();
          const path = 'documents/' + currentChatId + '/';
          const name = file.name;
          const acceptedTypes = ['xlsx', 'docx', 'txt', 'pdf', 'ppt'];
          const reader = new FileReader();

          if (type === 'image') typeSmall = 'image';

          if (type !== 'image' && !acceptedTypes.includes(typeSmall)) {
            setErrorType(true);
          } else {
            createNewSubItem(
              firebase.auth().currentUser.uid,
              currentChatId,
              '',
              [file],
              'chats',
              'items'
            );

            updateSubTitle('chats', currentChatId, '📷 Image');
          }
        }
      }
    }
  };

  /** Return Chat messages */
  return currentChatId ? (
    <StyledMain>
      <Help
        outside
        activeDashboardId={activeDashboardId}
        currentId={currentChatId}
        url='/resources/chathelp.gif'
        title={t('help:chat.title')}
        text={t('help:chat.text')}
      />
      <StyledMainWrapper
        onDrop={e => handleDrop(e, currentChatId)}
        onDragOver={e => handleDragOver(e, currentChatId)}
      >
        {playSound && (
          <Audio
            url='/resources/sounds/chat.mp3'
            status={playSound ? 'PLAYING' : 'STOPPED'}
            finished={() => {
              setPlaySound(false);
            }}
          />
        )}
        <StyledContentWrapper>
          <Grid rows='4.5rem 1fr' scrollbar={false}>
            <MessagesHeader
              handleSearchChange={handleSearchChange}
              activeDashboardId={activeDashboardId}
              currentChatId={currentChatId}
            />
            <StyledWrapper>
              <React.Fragment>
                <StyledBadge>
                  <StyledTimeIndicator id={'timeIndicator-' + currentChatId} />
                </StyledBadge>
                <Grid columns='1fr 4.5rem' scrollbar={false}>
                  <LazyLoad
                    type='chats'
                    chunksFromTop
                    activeId={currentChatId}
                    Node={Message}
                    activeDashboardId={activeDashboardId}
                    friendData={friendData}
                    widgetId={widgetId}
                  />
                  <StyleTypeIndicator>
                    <Grid columns='1fr'>
                      <StyledTypingWrapper justify='center' ref={typeInfoRef}>
                        {typingUsers &&
                          Object.keys(typingUsers).map(user => {
                            if (uuid !== user) return handleTypeIndicator(user);
                          })}
                      </StyledTypingWrapper>
                    </Grid>
                  </StyleTypeIndicator>
                </Grid>
              </React.Fragment>
              <MessageForm
                ref={chatMessageRef}
                currentChannel={currentChatId}
                activeDashboardId={activeDashboardId}
              />
            </StyledWrapper>
          </Grid>
        </StyledContentWrapper>
      </StyledMainWrapper>
    </StyledMain>
  ) : (
    <EmptyState widgetId={widgetId} text={t('emptystate:chatcontent.title')} />
  );
};

ChatContent.propTypes = {
  /** This sets the users current channel */
  currentChatId: PropTypes.string,
  /** The current user */
  currentUser: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /** The List data from Firebase */
  data: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
  ]),
  friendData: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
  ]),
  /** Current dashboard Id */
  activeDashboardId: PropTypes.string,
  /** Current widget Id */
  widgetId: PropTypes.string,
};

ChatContent.defaultProps = {
  currentChatId: null,
  currentUser: null,
  data: null,
  friendData: null,
  activeDashboardId: null,
  widgetId: null,
};

//export default connect(mapStateToProps)(Messages);
/** pass currentUser and currentChannel from redux to global props */
const mapStateToProps = state => ({
  dragInfo: state.globaldraginfo.globalDragInfo,
  storeDrag: state.storewidgetdrag.storeWidgetDrag,
});

//export
export default connect(mapStateToProps, {
  globalDragInfo,
  storeWidgetDrag,
})(ChatContent);
