import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/** Render the chunks
 * @param {bool} chunksFromTop
 */
const OlderItems = props => {
  const {
    data,
    Node,
    activeDashboardId,
    extra,
    chunksFromTop,
    preOwner,
    activeId,
  } = props;

  //States
  const [items, setItems] = useState({});

  useEffect(() => {
    items[data['date']] = {};
    items[data['date']] = data;

    setItems(prevState => {
      return { ...prevState, ...items };
    });
  }, [data]);

  /** Render the chunk */
  const renderChunks = () => {
    let preOldOwner = '';
    let extraSpacing = false;
    if (chunksFromTop) {
      return Object.entries(items)
        .reverse()
        .map(value => {
          const totNumber = Object.values(value[1]['items']).length;
          return (
            <div className="lazyload" id={'chunk-' + value[0]} key={value[0]}>
              {Object.values(value[1]['items']).map((item, index) => {
                if (
                  index === 0 &&
                  preOldOwner !== '' &&
                  preOwner !== item.owner &&
                  preOwner !== preOldOwner
                ) {
                  extraSpacing = true;
                  preOldOwner = item.owner;
                }

                const newNode = (
                  <Node
                    key={item.update ? item.update : item.timestamp}
                    time={item.update ? item.update : item.timestamp}
                    message={item.text}
                    defaultImages={item.images}
                    user={item.name}
                    owner={item.owner}
                    preOwner={preOldOwner}
                    extraSpacing={extraSpacing}
                    id={item.id}
                    name={extra(item)}
                    activeDashboardId={activeDashboardId}
                    activeId={activeId}
                  />
                );
                preOldOwner = item.owner;
                extraSpacing = false;

                return newNode;
              })}
            </div>
          );
        });
    } else {
      return Object.entries(items).map(value => {
        return (
          <div className="lazyload" id={'chunk-' + value[0]} key={value[0]}>
            {Object.values(value[1]['items'])
              .reverse()
              .map(item => {
                return (
                  <Node
                    key={item.update ? item.update : item.timestamp}
                    time={item.update ? item.update : item.timestamp}
                    message={item.text}
                    images={item.images}
                    user={item.name}
                    owner={item.owner}
                    id={item.id}
                    name={extra(item)}
                    activeDashboardId={activeDashboardId}
                  />
                );
              })}
          </div>
        );
      });
    }
  };

  /** Return Chat messages */
  return items && Object.keys(items).length > 0 && renderChunks();
};

OlderItems.propTypes = {
  /** The List data from Firebase */
  data: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
  ]),
  /** Scroll direction */
  chunksFromTop: PropTypes.bool,
};

OlderItems.defaultProps = {
  data: null,
  chunksFromTop: true,
};

export default OlderItems;
