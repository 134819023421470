/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ContextMenuTrigger } from 'react-contextmenu';
import { useTranslation } from 'react-i18next';
import DraggableBubble from 'react-draggable';
import Icon from '../Icon';
import Image from '../Image';
import { modalOpen, modalType } from '../../redux/actions';
import ContextMenuItems from '../ContextMenuItems';
import Button from '../Button';
import TextField from '../TextField';
import EmptyState from '../EmptyState';
import Text from '../Text';
import Group from '../Group';
import List from '../List';
import Grid from '../Grid';
import ListItem from '../ListItem';
import firebase, {
  dashboardListner,
  inviteKeyListner,
  userDashboardListner,
  userListner,
  updateBubbleCoordinates,
} from '../../firebase';
import theme, { rem } from '../../theme';

const StyledWrapper = styled.div`
  ${({ fixed, position }) => `
    display: flex;
    z-index: 100;
    position: absolute;

    .handle {
      display: ${fixed ? 'none' : 'block'};
    }
  `}
  ${props => props.fixed && props.position && positionStyle[props.position]};
`;

const positionStyle = {
  br: {
    top: rem(-55),
    right: rem(10),
  },
  brGhost: {
    bottom: rem(10),
    right: rem(10),
  },
};

const StyledIcon = styled(Icon)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -${rem(20)};
  z-index: 4;

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
`;

const StyledImage = styled(Image)`
  cursor: pointer;
  margin-left: 1px;
`;

const StyledImageWrapper = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem(5)};
  margin: 0;
  background: rgba(255, 255, 255, 1);
  border-radius: 50%;
  overflow: hidden;
`;

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
`;

/** Bottom content */
const bottomContent = () => `
  top: auto;
  bottom: 4px;
  right: -30px;
  left: ${rem(35)};
  transform-origin: bottom left;
`;

/** Right content */
const rightContent = () => `
  top: 4px;
  right: ${rem(35)};
  left: auto;
  transform-origin: top right;
`;

/** rightBottomContent */
const rightBottomContent = () => `
  bottom: 4px;
  top: auto;
  transform-origin: bottom right;
`;

const StyledContentWrapper = styled.div`
  ${({ show, xPosition, yPosition, menuColor }) => `
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 4px;
    right: -30px;
    left: ${rem(35)};
    height: auto;
    background-color: ${menuColor ? menuColor : 'rgba(0, 0, 0, 0.5)'};
    z-index: -1;
    transition: transform 0.1s linear;
    transition-delay: 0.1s;
    transform-origin: top left;
    transform-style: preserve-3D;
    transform: scale(${show ? 1 : 0});
    min-width: ${rem(300)};
    border-top-right-radius: ${rem(20)};
    border-top-left-radius: ${rem(20)};
    border-bottom-right-radius: ${rem(20)};
    border-bottom-left-radius: ${rem(20)};
    padding: ${rem(20)} ${rem(20)} ${rem(10)} ${rem(30)};

    ${yPosition === 'bottom' ? bottomContent() : null};

    ${xPosition === 'right' ? rightContent() : null};

    ${
      xPosition === 'right' && yPosition === 'bottom'
        ? rightBottomContent()
        : null
    };
  `}
`;

const StyledGroup = styled(Group)`
  flex-direction: column;
  margin-bottom: ${rem(10)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledTextField = styled(TextField)`
  margin-right: ${rem(50)};
  text-overflow: ellipsis;
`;

const StyledInputGroup = styled(Group)`
  button {
    min-width: 1.5rem;
    min-height: 1.5rem;
    margin 0 !important;
  }
`;

const StyledCard = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: ${rem(20)} ${rem(10)} ${rem(20)} ${rem(10)};
  background: rgba(0, 0, 0, 0.3);
  border-radius: ${rem(10)};
  margin-bottom: ${rem(15)};
  margin-top: ${rem(5)};
`;

const StyledList = styled(List)`
  width: 100%;
  margin: 0;
`;

const StyledGrid = styled(Grid)`
  height: ${rem(120)};
`;

/**
 * Return a styled Bubble component
 * @param {object} image
 * @param {icon} icon
 * @param {bool} compact
 * @param {string} activeDashboardId
 * @param {string} type
 * @param {bool} fixed
 * @param {string} position
 * @param {string} action
 */
const Bubble = props => {
  const {
    image,
    icon,
    compact,
    activeDashboardId,
    type,
    fixed,
    position,
    action,
  } = props;

  //Reft
  const linkRef = useRef();
  const myRef = useRef();

  //UserId
  const uuid = firebase.auth().currentUser.uid;

  //Translation
  const [t] = useTranslation([
    'common',
    'help',
    'tooltip',
    'emptystate',
    'button',
  ]);

  //States
  const [showContent, setShowContent] = useState(false);
  const [attendees, setAttendees] = useState({});
  const [handleAttendee, setHandleAttendee] = useState();
  const [bubbleData, setBubbleData] = useState();
  const [xPosition, setXPosition] = useState(0);
  const [yPosition, setYPosition] = useState(0);
  const [aside, setAside] = useState();
  const [shortLink, setShortLink] = useState();
  const [menuColor, setMenuColor] = useState();

  useEffect(() => {
    if (linkRef.current) {
      dashboardListner
        .child(activeDashboardId)
        .child('link')
        .child('id')
        .once('value', snap => {
          if (snap.val() && linkRef.current) {
            linkRef.current.value =
              'onlinedashboard.studentsplus.nl/' + snap.val();
          }
        });
    }
  }, [linkRef.current]);

  useEffect(() => {
    if (activeDashboardId) {
      userDashboardListner
        .child(uuid)
        .child(activeDashboardId)
        .child('colors')
        .child('menuColor')
        .on('value', snapDashboard => {
          setMenuColor(snapDashboard.val());
        });

      dashboardListner
        .child(activeDashboardId)
        .child('link')
        .child('id')
        .once('value', snap => {
          setShortLink(snap.val());
        });

      /** User permissions */
      userDashboardListner
        .child(uuid)
        .child(activeDashboardId)
        .child('activity')
        .child('bubble')
        .child(type ? type : 'default')
        .once('value', snap => {
          if (snap.val()) {
            setBubbleData(snap.val());
            setAside(snap.val().aside);
            setXPosition(snap.val().xPosition);
            setYPosition(snap.val().yPosition);
          } else {
            updateBubbleCoordinates(
              20,
              20,
              true,
              activeDashboardId,
              'left',
              'top',
              type
            );
            setBubbleData({
              aside: true,
              x: 20,
              xPosition: 'left',
              y: 20,
              yPosition: 'top',
            });
          }
        });

      //DASHBOARD LISTNER ON MEMBERS
      dashboardListner
        .child(activeDashboardId)
        .child('members')
        .on('child_added', snap => {
          if (snap.key !== uuid) {
            userListner
              .child(snap.key)
              .child('public')
              .once('value', snapShot => {
                attendees[snap.key] = snapShot.val();

                setAttendees(prevState => {
                  return { ...prevState, ...attendees };
                });
              });
          }
        });

      dashboardListner
        .child(activeDashboardId)
        .child('members')
        .on('child_removed', snap => {
          if (uuid !== snap.key) {
            delete attendees[snap.key];
            setAttendees(attendees);
          }
        });
    }
  }, []);

  /** Handle drag */
  const handleDrag = (e, data) => {
    const elementWidth = document.getElementById('root').offsetWidth;
    const elementHeight = window.innerHeight;
    setShowContent(false);
    if (data.x < 100 || data.x > elementWidth - 325) {
      setAside(true);
      if (data.x < 100) {
        setXPosition('left');
      } else if (data.x > elementWidth - 325) {
        setXPosition('right');
      }
    }

    if (data.x > 100 && data.x < elementWidth - 325) {
      setAside(false);
      setXPosition('center');
    }

    if (data.y < 50) {
      setYPosition('top');
    }

    if (data.y > elementHeight - 325) {
      setYPosition('bottom');
    }

    if (data.y > 50 && data.y < elementHeight - 326) {
      setYPosition('center');
    }
  };

  /** Handle Stop */
  const handleStop = (e, data) => {
    getCursorData(data);
  };

  /** Update coordinates */
  const getCursorData = positionData => {
    updateBubbleCoordinates(
      positionData.x,
      positionData.y,
      aside,
      activeDashboardId,
      xPosition,
      yPosition,
      type
    );
  };

  /** Handle onclick */
  const handleOnClick = () => {
    if (action !== 'modal') {
      setShowContent(!showContent);
    } else {
      props.modalOpen(true);
      props.modalType(['feedback', uuid]);
    }
  };

  /** Handles click outside event */
  const handleOutsideClick = event => {
    const bubbleWrapper = document.getElementById('bubble-' + type);

    if (
      (bubbleWrapper && bubbleWrapper.contains(event.target)) ||
      (myRef.current &&
        myRef.current.contains(event.target) &&
        myRef.current.contains(
          document.elementFromPoint(event.clientX, event.clientY)
        ))
    ) {
      return;
    } else {
      setShowContent(false);
    }
  };
  document.addEventListener('mousedown', handleOutsideClick);

  /** Generate a custom link */
  const generateLink = () => {
    dashboardListner
      .child(activeDashboardId)
      .child('link')
      .once('value', snap => {
        //20 minutes were passed from start
        if (Date.now() - snap.val().time >= 20000) {
          const randomId =
            Math.random()
              .toString(36)
              .substring(2, 15) +
            Math.random()
              .toString(36)
              .substring(2, 15);

          dashboardListner
            .child(activeDashboardId)
            .child('link')
            .update({
              id: randomId,
              time: Date.now(),
            })
            .then(() => {
              linkRef.current.value =
                'onlinedashboard.studentsplus.nl/' + randomId;
              copyText();
            })
            .then(() => {
              const oldKey = snap.val().id;
              inviteKeyListner
                .child(oldKey)
                .remove()
                .then(() => {
                  inviteKeyListner.update({
                    [randomId]: {
                      id: randomId,
                      dashboardId: activeDashboardId,
                      friendId: uuid,
                      time: Date.now(),
                    },
                  });
                });
            });
        }
      });
  };

  /** Copy the text */
  const copyText = () => {
    if (linkRef.current) {
      /* Get the text field */
      var copyText = linkRef.current;

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand('copy');
    }
  };

  return (
    <DraggableBubble
      axis='both'
      handle='.handle'
      bounds='#root'
      defaultPosition={
        !fixed && {
          x: bubbleData ? bubbleData.x : 0,
          y: bubbleData ? bubbleData.y : 0,
        }
      }
      position={null}
      scale={1}
      onDrag={handleDrag}
      onStop={handleStop}
      disabled={fixed ? true : false}
    >
      <StyledWrapper
        className='editable'
        xPosition={xPosition}
        yPosition={yPosition}
        position={position}
        fixed={fixed}
      >
        <StyledIcon className='handle' icon='drag_handle' size={20} />
        {image && (
          <StyledImageWrapper onClick={() => handleOnClick()}>
            <StyledImage {...image} />
          </StyledImageWrapper>
        )}
        {icon && (
          <StyledIconWrapper
            onClick={() => handleOnClick()}
            id={'bubble-' + type}
          >
            <Icon {...icon} />
          </StyledIconWrapper>
        )}
        <StyledContentWrapper
          show={showContent}
          xPosition={xPosition}
          yPosition={yPosition}
          menuColor={menuColor}
          onClick={handleOutsideClick}
          ref={myRef}
          className='bubbleContent'
        >
          {action === 'menu' ? (
            <React.Fragment>
              <StyledGroup fullWidth>
                <Text
                  align='left'
                  text={t('common:invite.link')}
                  textStyle='description'
                  textColor='#fff'
                />
                <Group>
                  <StyledTextField
                    selectionBackground='yellow'
                    readOnly
                    hasSinlgeButton
                    textcolor='#fff'
                    placeholdercolor='#fff'
                    backgroundcolor={theme.color.dark[40]}
                    inputId='bubbleLink'
                    borders={false}
                    value={
                      shortLink
                        ? 'https://onlinedashboard.studentsplus.nl/' + shortLink
                        : null
                    }
                    ref={linkRef}
                  />
                  <StyledInputGroup type='inputOption'>
                    <Button
                      variant='icon'
                      style={{ marginRight: rem(4) }}
                      title={t('common:invite.copy')}
                      onClick={() => copyText()}
                    >
                      <Icon
                        icon='content_copy'
                        padding={rem(6)}
                        size={16}
                        color={theme.color.light[100]}
                      />
                    </Button>

                    <Button
                      variant='icon'
                      style={{ marginRight: rem(4) }}
                      title={t('common:invite.refresh')}
                      onClick={() => generateLink()}
                    >
                      <Icon
                        icon='refresh'
                        padding={rem(6)}
                        size={16}
                        color={theme.color.light[100]}
                      />
                    </Button>
                  </StyledInputGroup>
                </Group>
              </StyledGroup>
              <StyledGroup fullWidth>
                <Text
                  align='left'
                  text={t('common:attendees')}
                  textStyle='description'
                  textColor='#fff'
                />
                <StyledCard>
                  <StyledGrid column='1fr'>
                    {attendees && Object.keys(attendees).length > 0 ? (
                      <StyledList evenRadius autoWidth>
                        {Object.entries(attendees).map(attendee => {
                          return (
                            <ContextMenuTrigger
                              id='dashboardBubbleAttendee'
                              holdToDisplay={1}
                              key={attendee[0]}
                            >
                              <ListItem
                                evenRadius
                                onItemClick={() => {
                                  setHandleAttendee(attendee[0]);
                                }}
                                title={attendee[1].name ? attendee[1].name : ''}
                                avatar={{
                                  status: attendee[1].indicator,
                                  source:
                                    attendee[1].avatar &&
                                    attendee[1]['avatar'].cropped
                                      ? attendee[1].avatar.cropped
                                      : attendee[1].avatar,
                                }}
                                subTitle={attendee[1].status}
                                id={attendee[0]}
                                rightIcon={{ icon: 'more_vert', size: 24 }}
                              />
                            </ContextMenuTrigger>
                          );
                        })}
                        <ContextMenuItems
                          id='dashboardBubbleAttendee'
                          data={[
                            {
                              id: 'dashboardBubbleAttendee',
                              icon: 'remove_circle_outline',
                              text: t('button:remove.user'),
                              backgroundColor: theme.color.danger,
                              click: () => {
                                props.modalOpen(true);
                                props.modalType([
                                  'removeUser',
                                  activeDashboardId,
                                  handleAttendee,
                                  false,
                                ]);
                              },
                            },
                          ]}
                        />
                      </StyledList>
                    ) : (
                      <EmptyState
                        animation='empty'
                        width='6rem'
                        height='auto'
                        text={t('emptystate:attendees.noattendees')}
                        textColor='#fff'
                        hasButton={false}
                      />
                    )}
                  </StyledGrid>
                </StyledCard>
              </StyledGroup>
            </React.Fragment>
          ) : (
            <div />
          )}
        </StyledContentWrapper>
      </StyledWrapper>
    </DraggableBubble>
  );
};

Bubble.propTypes = {
  /** Image properties */
  image: PropTypes.oneOfType([PropTypes.object]),

  /** Icon properties */
  icon: PropTypes.oneOfType([PropTypes.object]),

  /** Indicates the type of the bubble */
  compact: PropTypes.bool,

  /** Current dashboard ID */
  activeDashboardId: PropTypes.string,

  /** The type of the bubble */
  type: PropTypes.string,

  /** Keep bubble on the same spot */
  fixed: PropTypes.bool,

  /** Default position of the bubble */
  position: PropTypes.string,

  /** The action when clicked on the bubble could be an small menu or modal */
  action: PropTypes.string,
};

Bubble.defaultProps = {
  image: null,
  icon: null,
  compact: false,
  activeDashboardId: null,
  type: null,
  fixed: false,
  position: null,
  action: 'menu',
};

export default connect(null, {
  modalOpen,
  modalType,
})(Bubble);
