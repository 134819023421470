/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';
import { connect, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';
import firebase, {
  resetListItemsPositions,
  updateCardByDragAndDrop,
  updateSubTitle,
  userListner,
  userDashboardListner,
} from '../../firebase';
import {
  listItemIsDragging,
  listUpdated,
  modalOpen,
  modalType,
} from '../../redux/actions';
import theme, { rem } from '../../theme';
import Image from '../Image';
import Group from '../Group';
import { updateCursorEvent } from '../Cursor';
import OptionGroup from '../OptionGroup';
import Checkbox from '../Checkbox';
import Lottie from '../LottieControl';
import EmptyState from '../EmptyState';
import ContentEditable from '../ContentEditable';

const portal = document.createElement('div');
portal.className = 'portal';
document.body.appendChild(portal);

const animation = keyframes`
  0%{
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
`;

const bounce = keyframes`
  from,
  20%,
  53%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
`;

const MixinFadeIn = css`
  animation: ${animation} 0.4s ease;
  animation-delay: 1s;
  animation-fill-mode: forwards;
`;

const MixinBounce = css`
  animation: ${bounce} 1s ease infinite;
  animation-delay: 0.5s;
`;

const StyledImageWrapper = styled.div`
  ${({ imageUrl, itemColor }) => `
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    widht: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${rem(10)};
    background:
      linear-gradient(to left, rgba(255, 255, 255, 0) 0,
      ${itemColor} 50%, ${itemColor} 100%),
      url(${imageUrl}) no-repeat;
    background-size: cover;
    background-position: center center;
  `};
  &.animate {
    opacity: 1;
    visibility: hidden;
    ${MixinFadeIn};
  }
`;

const StyledImage = styled(Image)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  cursor: pointer;
  filter: drop-shadow(0px 2px 2px ${theme.color.dark[80]});
`;

const StyledGroup = styled(Group)`
  position: relative;
  justify-content: space-between;
`;

const StyledContentEditable = styled(ContentEditable)`
  flex-grow: 1;
  margin-bottom: 0;
`;

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
`;

const StyledListItem = styled.li`
  ${({ itemColor }) => `
    display: flex;
    padding: 1rem;
    min-height: 5rem;
    height: auto;
    position: relative;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin-bottom: ${rem(5)};
    background: ${itemColor};
    color: #fff;
    opacity: 1;
    border-radius: ${rem(10)};
  `}

  .optionGroup {
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }

  &.deletion {
    ${MixinBounce};
  }

  &:focus {
    outline: none;
  }

  &:hover {
    .optionGroup {
      opacity: 1;
    }
  }
`;

const ImagePreviewWraper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -${rem(3)};

  img {
    padding: ${rem(3)};
    width: 25%;
    &:last-child {
      margin-right: 0;
    }
  }
`;

/**
 * Overview component
 * @param {object} main
 * @param {object} userData
 * @param {string} widgetId
 */
const DragList = props => {
  const { dragType, data, listId, activeDashboardId, widgetId } = props;

  //States
  const [listData, setListData] = useState(data);
  const [listPositions, setListPositions] = useState(
    JSON.parse(listData.positions)
  );
  //const [hasDragType, setHasDragType] = useState(dragType);
  const [details, setDetails] = useState();
  const [itemImages, setItemImages] = useState({});
  const [itemBackgroundColor, setItemBackgroundColor] = useState();
  const [isPayedUser, setIsPayedUser] = useState();
  const drop = useSelector(state => state.listitemdropinfo.listItemDropInfo);
  const uuid = firebase.auth().currentUser.uid;
  const [maxItems, setMaxItems] = useState();
  const [totalItems, setTotalItems] = useState();
  const [maxExceeded, setMaxExceeded] = useState(false);

  //Translation
  const [t] = useTranslation(['emptystate', 'tooltip']);

  useEffect(() => {
    settingsListner();
    setImageListner();
  }, []);

  /** Set Image listner */
  const setImageListner = () => {
    const listner = firebase
      .database()
      .ref()
      .child('lists')
      .child(listId)
      .child('items');

    listner.once('value', snap => {
      snap.forEach(function(childSnapshot) {
        itemImages[childSnapshot.key] = {};
        itemImages[childSnapshot.key] = childSnapshot.val().images;
        setItemImages(itemImages);

        childSnapshot.ref
          .child('images')
          .orderByChild('update')
          .startAt(Date.now())
          .on('child_added', snapImageItem => {
            if (snapImageItem.key) {
              const parentKey = childSnapshot.ref.key;
              if (!itemImages[parentKey]) itemImages[parentKey] = {};
              itemImages[parentKey][snapImageItem.key] = snapImageItem.val();

              setItemImages(prevState => {
                // Object.assign would also work
                return { ...prevState, ...itemImages };
              });
            }
          });

        childSnapshot.ref.child('images').on('child_removed', snapRemoved => {
          const parentKey = childSnapshot.ref.key;
          delete itemImages[parentKey][snapRemoved.key];
          setItemImages(prevState => {
            // Object.assign would also work
            return { ...prevState, ...itemImages };
          });
        });
      });
    });
  };

  /** settingsListner */
  const settingsListner = () => {
    //Listners
    const listner = firebase
      .database()
      .ref()
      .child('lists')
      .child(listId);

    listner
      .child('items')
      .orderByChild('update')
      .startAt(Date.now())
      .on('child_added', snap => {
        if (!listData['items']) listData['items'] = {};
        listData['items'][snap.key] = snap.val();

        listner
          .child('items')
          .child(snap.key)
          .child('images')
          .orderByChild('update')
          .startAt(Date.now())
          .on('child_added', snapImageItem => {
            if (snapImageItem.key) {
              const parentKey = snap.key;
              if (!itemImages[parentKey]) itemImages[parentKey] = {};
              itemImages[parentKey][snapImageItem.key] = snapImageItem.val();

              setItemImages(prevState => {
                // Object.assign would also work
                return { ...prevState, ...itemImages };
              });
            }
          });

        listner
          .child('items')
          .child(snap.key)
          .child('images')
          .on('child_removed', snapRemoved => {
            const parentKey = snap.key;
            delete itemImages[parentKey][snapRemoved.key];
            setItemImages(prevState => {
              // Object.assign would also work
              return { ...prevState, ...itemImages };
            });
          });

        setListData(prevState => {
          // Object.assign would also work
          return { ...prevState, ...listData };
        });
      });

    listner.child('items').on('child_removed', snap => {
      delete listData['items'][snap.key];
      setListData(prevState => {
        // Object.assign would also work
        return { ...prevState, ...listData };
      });
    });

    listner.child('positions').on('value', snap => {
      setListPositions(JSON.parse(snap.val()));
    });

    listner.child('maxItems').on('value', snap => {
      setMaxItems(snap.val());
    });

    listner.child('itemCount').on('value', snapshot => {
      if (snapshot.val()) {
        listner.child('maxItems').once('value', snap => {
          if (snapshot.val() >= snap.val()) {
            setMaxExceeded(true);
          } else {
            setMaxExceeded(false);
          }
        });
        setTotalItems(snapshot.val());
      }
    });

    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('colors')
      .child('listItemColor')
      .on('value', snapDashboard => {
        setItemBackgroundColor(snapDashboard.val());
      });

    userListner
      .child(uuid)
      .child('isPayedUser')
      .on('value', snap => {
        setIsPayedUser(snap.val());
      });
  };

  /**
   * Reorders the array the maintaince the order of the list
   * @param {number} startIndex
   * @param {number} endIndex
   * @param {string} sourceListId
   * @param {string} draggedItemId
   */
  const reorder = (startIndex, endIndex, sourceListId, draggedItemId) => {
    //const orderedList = Array.from(list);
    const orderedList = listPositions.filter(function(el) {
      return el != null;
    });

    // Check if there is an start and end index indicating there has been an drag and drop
    if (startIndex != null && endIndex != null) {
      const [removed] = orderedList.splice(startIndex, 1);
      orderedList.splice(endIndex, 0, removed);
    }
    resetListItemsPositions(listId, orderedList, 'lists', draggedItemId);
    setListPositions(orderedList);
  };

  /** onDragStart */
  const onDragStart = result => {
    updateCursorEvent(uuid, activeDashboardId, 'dragging');
    setDetails({
      type: dragType,
      listId: result.source.droppableId,
      itemId: result.draggableId,
      cardId: document
        .getElementById('list-' + result.source.droppableId)
        .closest('main').parentElement.id,
    });
  };

  /**
   * Drag ends function
   */
  const onDragEnd = result => {
    updateCursorEvent(uuid, activeDashboardId, 'default');
    listItemIsDragging(false);
    if (
      !result.destination ||
      (!listData &&
        Object.keys(drop).length > 0 &&
        Object.keys(details).length > 0)
    ) {
      if (drop.type) updateCardByDragAndDrop(details, drop);
      return;
    }

    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index === result.source.index
    ) {
      return;
    }

    reorder(
      result.source.index,
      result.destination.index,
      result.source.droppableId,
      result.draggableId
    );

    if (result.source.index < result.destination.index) {
      updateSubTitle(
        'lists',
        result.source.droppableId,
        '⬇️ ' + listData['items'][result.draggableId]['text']
      );
    } else if (result.source.index > result.destination.index) {
      updateSubTitle(
        'lists',
        result.source.droppableId,
        '⬆️ ' + listData['items'][result.draggableId]['text']
      );
    }
  };

  /**
   * onDragUpdate
   * Checking if all drags are going OK
   */
  const onDragUpdate = result => {
    if (!result.destination) {
      return;
    }

    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index === result.source.index
    ) {
      return;
    }

    let dragged = null;

    if (listData) dragged = listData[result.source.index];

    if (!dragged) return;
  };

  /**
   * onBeforeDragStart
   */
  const onBeforeDragStart = () => {
    const { listItemIsDragging } = props;
    listItemIsDragging(true);
  };

  /** Shows the option trigger */
  const showOptionTrigger = () => {};

  /** Trigger the preview modal onClick */
  const imageOnclickHandler = (images, itemId, typeId, activeDashboardId) => {
    props.modalOpen(true);
    props.modalType(['gallery', images, itemId, typeId, activeDashboardId]);
  };

  /** Create the preview images */
  const createImages = (itemId, listId, images, image, totalLength) => {
    if (image) {
      const timeDiff = (Date.now() - image.update) / 1000;

      return (
        <StyledImageWrapper
          key={image.key}
          className={timeDiff < 8 ? 'animate' : null}
          imageUrl={image.url}
          itemColor={isPayedUser ? itemBackgroundColor : '#7e73ac'}
        >
          {totalLength >= 1 ? (
            <React.Fragment>
              <StyledImage
                src='/resources/multipleImages.svg'
                onClick={() =>
                  imageOnclickHandler(images, itemId, listId, activeDashboardId)
                }
                width={rem(23)}
                data-for={`tooltipListItem-${itemId}`}
                data-tip=''
              />
              <Tooltip
                top={totalItems <= 1 ? true : false}
                place='left'
                noSpacing
                arrowColor='transparent'
                id={`tooltipListItem-${itemId}`}
                html={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <ImagePreviewWraper>
                    {Object.values(images).map(image => {
                      return (
                        <Image
                          key={image.id}
                          src={image.url}
                          width={40}
                          height={40}
                        />
                      );
                    })}
                  </ImagePreviewWraper>
                }
                content={{
                  text: {
                    text:
                      totalLength === 1
                        ? t('tooltip:draglist.singleitem')
                        : t('tooltip:draglist.multiitem', {
                            number: totalLength,
                          }),
                    textStyle: 'subtitle',
                    textColor: '#fff',
                    align: 'center',
                  },
                }}
              />
            </React.Fragment>
          ) : null}
        </StyledImageWrapper>
      );
    }
  };

  // eslint-disable-next-line prettier/prettier
  return listData &&
    listData.items &&
    Object.keys(listData.items).length > 0 ? (
    <DragDropContext
      className='editable'
      onBeforeDragStart={onBeforeDragStart}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onDragUpdate={onDragUpdate}
    >
      <Droppable droppableId={listId}>
        {provided => (
          <StyledList
            id={'list-' + listId}
            data-list={listId}
            ref={provided.innerRef}
            className='editable'
            {...provided.droppableProps}
          >
            {Object.values(listPositions).map((key, index) => {
              if (!listData['items'][key]) return null;
              return (
                <Draggable key={key} draggableId={key} index={index}>
                  {(provided, snapshot) => {
                    const element = (
                      <StyledListItem
                        onhover={showOptionTrigger()}
                        ref={provided.innerRef}
                        itemColor={
                          isPayedUser ? itemBackgroundColor : '#7e73ac'
                        }
                        className={key}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {itemImages[key] &&
                          (!itemImages[key].loading ? (
                            createImages(
                              listData['items'][key].id,
                              listId,
                              Object.values(itemImages[key]),
                              Object.values(itemImages[key])[0],
                              Object.values(itemImages[key]).length
                            )
                          ) : (
                            <Lottie
                              animation='data'
                              animationWidth={45}
                              animationHeight={45}
                            />
                          ))}
                        <StyledGroup
                          displayPosition={index + 1}
                          content={listData.items}
                        >
                          <StyledContentEditable
                            activeId={listId}
                            data={listData['items'] && listData['items'][key]}
                            id={
                              listData['items'][key] &&
                              listData['items'][key].id
                            }
                            content={
                              listData['items'][key] &&
                              listData['items'][key].text
                            }
                            input={
                              listData['items'][key] &&
                              listData['items'][key].text
                            }
                            table='lists'
                            itemId={key}
                          />
                          <Checkbox
                            listData={listData && listData}
                            itemData={
                              listData['items'][key] && listData['items'][key]
                            }
                            size='big'
                            itemId={key}
                            typeId={listId}
                            name='checkbox'
                            type='lists'
                            value={
                              listData['items'][key] &&
                              listData['items'][key].checked
                            }
                            isChecked={
                              listData['items'][key] &&
                              listData['items'][key].checked
                            }
                            activeDashboardId={activeDashboardId}
                          />
                        </StyledGroup>
                        <OptionGroup
                          data={
                            listData['items'][key] && listData['items'][key]
                          }
                          parentId={listId}
                          itemId={
                            listData['items'][key] && listData['items'][key].id
                          }
                          table='lists'
                          showDelete
                          showImage
                          itemTypes='items'
                          styleType='overlay'
                        />
                      </StyledListItem>
                    );

                    return snapshot.isDragging
                      ? createPortal(element, portal)
                      : element;
                  }}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </StyledList>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <EmptyState
      animation='funny'
      widgetId={widgetId}
      text={t('draglist.title')}
      hasButton={false}
    />
  );
};

DragList.propTypes = {
  /** User Data */
  userData: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  /** Active Dashboard Id */
  activeDashboardId: PropTypes.string,
  /** Current widget Id */
  widgetId: PropTypes.string,
};

DragList.defaultProps = {
  userData: null,
  activeDashboardId: null,
  widgetId: null,
};

/** @component */
export default connect(null, {
  listItemIsDragging,
  listUpdated,
  modalOpen,
  modalType,
})(DragList);
