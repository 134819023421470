/* eslint-disable react/no-find-dom-node */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Group from '../Group';
import Image from '../Image';
import Lottie from '../LottieControl';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledContentWrapper = styled(Group)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

const StyledLoading = styled(Lottie)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

/** todo */
const imagePreview = props => {
  const { image } = props;

  //States
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.getElementById('globalModal').firstElementChild.style.height =
      'auto';
    document.getElementById('globalModal').firstElementChild.style.width =
      'auto';
    document.getElementById('globalModal').firstElementChild.style.background =
      'transparent';
    setLoading(false);
  }, []);

  /** Return Chat messages */
  return loading ? (
    <StyledLoading animation="loading" animationWidth={400} />
  ) : (
    <StyledWrapper>
      <StyledContentWrapper fullwidth>
        <Image src={image} />
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

imagePreview.propTypes = {
  /** Image URL */
  image: PropTypes.string,
};

imagePreview.defaultProps = {
  image: null,
};

export default imagePreview;
