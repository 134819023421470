/* eslint-disable react/no-find-dom-node */
import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Gluejar } from '@charliewilco/gluejar';
import { Picker, emojiIndex } from 'emoji-mart';
import firebase, {
  createNewSubItem,
  updateSubTitle,
  updateTempSubTitle,
} from '../../firebase';
import TextField from '../TextField';
import { modalType, modalOpen } from '../../redux/actions';
import Button from '../Button';
import Icon from '../Icon';
import Group from '../Group';
import Upload from '../Dropzone';
import theme, { rem } from '../../theme';
import 'emoji-mart/css/emoji-mart.css';

const StyledGroup = styled(Group)`
  flex-direction: column;
`;

const StyledSmallButton = styled(Button)`
  min-width: auto;
`;

const StyledUpload = styled(Upload)`
  min-width: auto;
`;

/**
 * MessageForm class
 */
const MessageForm = props => {
  const { currentChannel, activeDashboardId } = props;

  const [message, setMessage] = useState('');
  // const [loading, setLoading] = useState(false);
  // const [errors, setErrors] = useState([]);
  const [emojiPicker, setEmojiPicker] = useState(false);
  const [files, setFiles] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [timeoutHandle, setTimeoutHandle] = useState();
  const [pasting, setPasting] = useState(true);
  const [maxLength] = useState(500);

  //Ref
  const chatMessageRef = useRef();
  const pasteRef = useRef();
  const emojiRef = useRef();

  //Translation
  const [t] = useTranslation(['messageform', 'text']);

  /** Timer function */
  let timeoutHandleFunction = () => {
    setTimeoutHandle(
      setTimeout(function() {
        updateTempSubTitle('chats', currentChannel, null);
      }, 1000)
    );
  };

  /** handleChange */
  const handleChange = () => {
    //Handle timer
    if (timeoutHandle) {
      clearTimeout(timeoutHandle);
      setTimeoutHandle(null);
    }
    timeoutHandleFunction();

    if (
      chatMessageRef.current.value &&
      chatMessageRef.current.value.length <= 0
    ) {
      updateTempSubTitle('chats', currentChannel, null);
    } else {
      updateTempSubTitle('chats', currentChannel, t('text:chats.typing'));
    }
    //if (chatMessageRef.current) setMessage(chatMessageRef.current.value);
    //this.setState({ [event.target.name]: event.target.value });
  };

  /** Handles click outside event */
  const handleOutsideClick = event => {
    try {
      let node = ReactDOM.findDOMNode(emojiRef.current);
      if (!node.contains(event.target)) {
        // Handle outside click here
        setEmojiPicker(false);
      }
    } catch (error) {
      return null;
    }
  };
  document.addEventListener('mousedown', handleOutsideClick);

  /** handleKeydown */
  const handleKeydown = event => {
    if (event.keyCode === 13 && !event.shiftKey) {
      sendMessage();
    }
    // if (message) {
    //   typingRef
    //     .child(channel.id)
    //     .child(user.uid)
    //     .set(user.displayName);
    // }else {
    //   typingRef
    //     .child(channel.id)
    //     .child(user.uid)
    //     .remove();
    // }
  };

  /** handleTogglePicker */
  const handleTogglePicker = () => {
    setEmojiPicker(!emojiPicker);
  };

  /** handleAddEmoji */
  const handleAddEmoji = emoji => {
    const oldMessage = chatMessageRef.current.value;
    const newMessage = colonToUnicode(` ${oldMessage} ${emoji.colons} `);

    // setMessage(newMessage);
    setEmojiPicker(false);
    chatMessageRef.current.focus();
    chatMessageRef.current.value = newMessage;
  };

  /** colonToUnicode */
  const colonToUnicode = message => {
    return message.replace(/:[A-Za-z0-9_+-]+:/g, x => {
      x = x.replace(/:/g, '');
      let emoji = emojiIndex.emojis[x];
      if (typeof emoji !== 'undefined') {
        let unicode = emoji.native;
        if (typeof unicode !== 'undefined') {
          return unicode;
        }
      }
      x = ':' + x + ':';
      return x;
    });
  };

  /** sendMessage */
  const sendMessage = () => {
    const str = chatMessageRef.current.value.substring(0, maxLength).trim();

    if (
      chatMessageRef.current.value.trim() !== '' ||
      (files && files.length > 0)
    ) {
      createNewSubItem(
        firebase.auth().currentUser.uid,
        currentChannel,
        str,
        files,
        'chats',
        'items'
      );

      updateSubTitle(
        'chats',
        currentChannel,
        chatMessageRef.current.value !== '' ? str : '📷 Image'
      );

      chatMessageRef.current.value = '';
      setEmpty(true);
      setFiles([]);
      setMessage('');

      chatMessageRef.current.style.height = 34 + 'px';
      chatMessageRef.current.focus();
      // //Scroll to top
      // setScroll(true);
      // //Set state back to false
      // setTimeout(() => setScroll(false), 200);
    }
  };

  /** Keep track of the selected files */
  const handleFiles = data => {
    setFiles(data);
  };

  /** Handle paste */
  const handlePaste = images => {
    props.modalOpen(true);
    props.modalType([
      'pasteImage',
      images[0],
      currentChannel,
      chatMessageRef.current.value,
    ]);
    setPasting(false);
  };

  /**
   * render JSX for MessageForm
   */
  return (
    <StyledGroup
      padding={[
        { top: rem(12), bottom: rem(5), right: rem(12), left: rem(12) },
      ]}
      className='message__form, editable'
    >
      {pasting && (
        <Gluejar
          ref={pasteRef}
          onPaste={files => {
            if (files['images'].length > 0) {
              handlePaste(files['images']);
            }
          }}
          onError={err => console.error(err)}
          container={chatMessageRef.current}
        />
      )}
      {emojiPicker && (
        <Picker
          ref={emojiRef}
          set='apple'
          onClick={handleOutsideClick}
          onMouseLeave={handleOutsideClick}
          onSelect={handleAddEmoji}
          className='emojipicker'
          title="Emoji's"
          style={{
            position: 'absolute',
            top: '-19rem',
            left: '0',
            zIndex: '3',
            bottom: '4.2rem',
            width: '20rem',
            height: '20rem',
            overflow: 'hidden',
          }}
          emoji='point_up'
          // perLine={20}
        />
      )}
      <Group>
        <StyledSmallButton
          variant='icon'
          style={{ marginRight: rem(4) }}
          onClick={handleTogglePicker}
        >
          <Icon
            icon='insert_emoticon'
            padding={rem(6)}
            size={16}
            color={theme.color.dark[40]}
          />
        </StyledSmallButton>
        <TextField
          maxLength={maxLength}
          onPaste={() => setPasting(true)}
          hasButtons
          name='message'
          ref={chatMessageRef}
          inputId={'chatInput' + currentChannel}
          isTextArea
          maxHeight={rem(70)}
          backgroundcolor={theme.color.dark[4]}
          activeDashboardId={activeDashboardId}
          borders={false}
          placeholder={t('messageform:placeholder')}
          extraOnchange={handleChange}
          value={chatMessageRef.current && chatMessageRef.current.value}
          onKeyDown={handleKeydown}
          // className={
          //   errors.some(error => error.message.includes('message'))
          //     ? 'error'
          //     : ''
          // }
        />
        <Group type='inputOption'>
          <StyledUpload
            type='button'
            empty={empty}
            preview
            previewId='previewChatAttachment'
            onFileSelect={handleFiles}
            newFiles={files}
          />
          <StyledSmallButton variant='icon' onClick={sendMessage}>
            <Icon
              icon='send'
              padding={rem(6)}
              size={16}
              color={theme.color.dark[40]}
            />
          </StyledSmallButton>
        </Group>
      </Group>
    </StyledGroup>
  );
};

MessageForm.propTypes = {
  /** This sets the users current channel */
  currentChannel: PropTypes.string,
  /** ~Current dashboard Id */
  activeDashboardId: PropTypes.string,
};

MessageForm.defaultProps = {
  currentChannel: null,
  activeDashboardId: null,
};

export default connect(null, {
  modalType,
  modalOpen,
})(MessageForm);
