/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ContentEditable from 'react-sane-contenteditable';
import firebase, { updateSubTitle, userDashboardListner } from '../../firebase';
import { updateCursorEvent } from '../Cursor';

const StyledContent = styled(ContentEditable)`
  display: flex;
  min-height: 100%;

  &:hover,
  &:focus {
    cursor: text;
  }

  &:focus {
    border: none;
    outline: none;
  }
`;

/**
 * Return a styled component
 * @param {object} children
 * @param {string} tag
 */
const ContentEditableComp = props => {
  const {
    activeId,
    table,
    id,
    input,
    data,
    tag,
    multiLine,
    maxLength,
    itemId,
    content,
    ...rest
  } = props;
  const [text, setText] = useState(content);
  const [newFocus, setNewFocus] = useState(false);
  const [activeDashboardId, setActiveDashboardId] = useState(activeDashboardId);
  let nameRef = useRef();

  //UserId
  const uuid = firebase.auth().currentUser.uid;

  useEffect(() => {
    settingsListner();
    document.getElementById(nameRef.current.props.id).innerHTML = data.text;

    userDashboardListner.child(uuid).once('value', snap => {
      snap.forEach(function(snapshot) {
        if (snapshot.val() && snapshot.val().active === true) {
          setActiveDashboardId(snapshot.key);
        }
      });
    });
  }, []);

  /** settingsListner */
  const settingsListner = () => {
    const listner = firebase
      .database()
      .ref()
      .child(table)
      .child(activeId)
      .child('items')
      .child(itemId)
      .child('text');

    listner.on('value', snap => {
      setText(snap.val());
    });
  };

  /**
   * Handles a change
   */
  const handleChange = (ev, value) => {
    setText(value);
    document.getElementById(nameRef.current.props.id).innerHTML = value;
    updateCursorEvent(uuid, activeDashboardId, 'typing');
  };

  /** Hanlde for onBlur function */
  const handleBlur = () => {
    setText(text);
    firebaseUpdate(text, table);
    setNewFocus(false);
    updateCursorEvent(uuid, activeDashboardId, 'default');
  };

  /** Update the value in the DB */
  const firebaseUpdate = (value, table) => {
    firebase
      .database()
      .ref(table)
      .child(activeId)
      .child('items')
      .child(itemId)
      .update({
        text: value,
      });
    updateSubTitle(table, activeId, '✎ ' + value);
  };

  return (
    <StyledContent
      id={id}
      focus={newFocus}
      ref={nameRef}
      tagName={tag}
      className="editable"
      content={text}
      editable
      maxLength={maxLength}
      multiLine={multiLine}
      onChange={handleChange}
      onBlur={handleBlur}
      {...rest}
    />
  );
};

ContentEditableComp.propTypes = {
  /** Parent list Id */
  activeId: PropTypes.string,
  /** All the data combined */
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /** Variant of the divider */
  tag: PropTypes.string,
  /** Property to set multiline */
  multiLine: PropTypes.bool,
  /** Set the max length of the element */
  maxLength: PropTypes.number,
  /** Set the text */
  input: PropTypes.string,
  /** Item ID */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** Table name */
  table: PropTypes.string,
  /** Current Item id */
  itemId: PropTypes.string,
};

ContentEditableComp.defaultProps = {
  activeId: null,
  data: null,
  tag: 'p',
  multiLine: false,
  maxLength: 140,
  input: '',
  table: null,
  itemId: null,
};

/** @component */
export default ContentEditableComp;
