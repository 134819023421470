import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import firebase, {
  switchDashboard,
  userDashboardListner,
  dashboardListner,
} from '../../firebase';
import Text from '../Text';
import Icon from '../Icon';
import theme, { rem } from '../../theme';

const StyledWrapper = styled.div`
  ${({ backgroundColor, disable }) => `
    position: relative;
    line-height: normal;
    align-items: center;
    justify-content: center;
    display: flex;
    background: transparent;
    border: 1px solid ${backgroundColor};
    border-radius: 1rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    margin-right: ${rem(5)};
    height: ${rem(30)};

    filter: ${disable ? 'grayscale(1)' : 'grayscale(0)'};
    opacity: ${disable ? '0.8' : '1'};

    &:last-child {
      margin-right: 0;
    }
  `}
`;

const StyledIcon = styled(Icon)`
  ${({ disable }) => `
    position: absolute;
    right: -4px;
    cursor: ${disable ? 'default' : 'pointer'};
    transition: 0.2s;
    transform-origin: center center;
    transform: scale(1) translate3d(0, 0, 0);

    &:hover,
    &:focus {
      backface-visibility: hidden;
      transform: ${disable ? 'scale(1)' : 'scale(1.15)'} translate3d(0, 0, 0);
    }
  `}
`;

/**
 * Chip component
 * @param {string} id
 * @param {string} activeDashboardId
 * @param {string} text
 * @param {string} backgroundColor
 * @param {string} type
 * @param {func} onClick
 * @param {bool} disable
 */
const Chip = props => {
  const {
    id,
    activeDashboardId,
    text,
    backgroundColor,
    type,
    onClick,
    disable,
  } = props;

  //UserId
  const uuid = firebase.auth().currentUser.uid;

  //States
  const [ChipInfo, setChipInfo] = useState({});

  useEffect(() => {
    // listnerSetup();
  }, []);

  /** Handle color */
  const handleColor = () => {
    switch (type) {
      default:
      case 'remove':
        return theme.color.danger;
      case 'add':
        return theme.color.success;
      case 'info':
        return theme.color.warning;
    }
  };

  /** Handle title */
  const handleTitle = () => {
    switch (type) {
      default:
      case 'remove':
        return 'remove';
      case 'add':
        return 'add';
      case 'info':
        return 'info';
    }
  };

  /** Handle icon */
  const handleIcon = () => {
    switch (type) {
      default:
      case 'remove':
        return 'cancel';
      case 'add':
        return 'add_circle';
      case 'info':
        return 'info';
    }
  };

  return (
    <StyledWrapper
      {...props}
      id={id}
      backgroundColor={backgroundColor}
      disable={disable}
    >
      <Text textStyle='overline' text={text} textColor='#fff' />
      <StyledIcon
        disable={disable}
        padding={rem(5)}
        icon={handleIcon()}
        color={handleColor()}
        title={handleTitle()}
        size={24}
        onClick={onClick && !disable ? onClick : null}
      />
    </StyledWrapper>
  );
};

Chip.propTypes = {
  id: PropTypes.string,
  activeDashboardId: PropTypes.string,
  text: PropTypes.string,
  backgroundColor: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disable: PropTypes.bool,
};

Chip.defaultProps = {
  id: null,
  activeDashboardId: '',
  text: '',
  backgroundColor: null,
  type: null,
  onClick: null,
  disable: false,
};

/** @component */
export default Chip;
