import { mix } from 'polished';
import theme, { rem } from '../../theme';

const disabledContainedStyle = `
  background-color: ${theme.color.dark[10]};
  box-shadow: ${props =>
    props.hasShadow ? '0px 1px 1px rgba(0, 0, 0, 0.2)' : 'none'};
  color: ${theme.color.dark[40]};
`;

const styles = {
  default: {
    contained: `
      box-shadow: ${props =>
        props.hasShadow ? '0px 1px 1px rgba(0, 0, 0, 0.2)' : 'none'};
      color: #fff;
    `,
    outlined: `
      border: 2px solid white;
      border-radius: 18px;
      padding: 5px 20px;
    `,
    text: `
      background-color: transparent;
      color: ${theme.color.dark[100]};
      &:hover {
        color: ${theme.color.primary[100]};
      }
    `,
    icon: `
      color: ${theme.color.dark[100]};
      outline: none;
      padding: 0;
      border-radius: 50%;
      height: auto;
      text-transform: initial;
      &:hover {
        color: ${theme.color.primary[100]};
        background: ${props =>
          props.bgColor
            ? mix(0.1, theme.color.dark[100], props.bgColor)
            : theme.color.dark[8]};
      }

      i {
        margin: 0;
      }
   `,
    options: `
      color: ${theme.color.dark[100]};
      outline: none;
      padding: 0;
      border-radius: 50%;
      height: auto;
      text-transform: initial;
      &:hover {
        color: ${theme.color.primary[100]};
        background: ${props =>
          props.bgColor
            ? mix(0.1, theme.color.dark[100], props.bgColor)
            : theme.color.dark[8]};
      }

      i {
        margin: 0;
      }
    `,
    iconlabel: `
      flex-direction: column;
      background-color: transparent;
      color: ${theme.color.dark[60]};
      outline: none;
      padding: 0 ${rem(12)};
      text-transform: initial;
      ${theme.textStyle.description};
      &:hover {
        color: ${theme.color.dark[100]};
      }

      i {
        padding: 0;
        margin: 0;
      }
   `,
    terminate: `
      background-color: ${theme.color.danger};
      box-shadow: ${props =>
        props.hasShadow ? '0px 1px 1px rgba(0, 0, 0, 0.2)' : 'none'};
      color: #fff;
      &:hover {
        background-color: ${mix(
          0.1,
          theme.color.dark[100],
          theme.color.danger
        )};
      }
    `,
  },
  disabled: {
    contained: disabledContainedStyle,
    outlined: `
      background-color: #fff;
      box-shadow: ${props =>
        props.hasShadow ? '0px 0px 2px rgba(0, 0, 0, 0.5)' : 'none'};
      color: ${theme.color.dark[40]};
    `,
    icon: `
        border-radius: 50%;
        width: auto;
        height: auto;
        margin: 0;
        padding: 0;
    `,
    text: `
      background-color: transparent;
      color: ${theme.color.dark[40]};
    `,
    terminate: disabledContainedStyle,
  },
};

/**
 * Return styles based on properties
 * @param {bool} disabled
 * @param {string} variant
 */
const buttonStyles = ({ disabled, variant }) =>
  styles[disabled ? 'disabled' : 'default'][variant];

export default buttonStyles;
