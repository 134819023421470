import PropTypes from 'prop-types';

export const propTypes = {
  /** Disabled slider */
  isDisabled: PropTypes.bool,
  /** Slider value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Step of the slider */
  step: PropTypes.number,
  /** Change function */
  onChange: PropTypes.func,
  /** Orientation of the slider */
  orient: PropTypes.oneOf(['horizontal', 'vertical']),

  /** Custom label */
  label: PropTypes.string,
  /** Label color */
  labelColor: PropTypes.string,
  /** Label position */
  labelPosition: PropTypes.string,

  /** Min value */
  min: PropTypes.number,
  /** Max value */
  max: PropTypes.number,
};

export const defaultProps = {
  onChange: () => {},
  orient: 'horizontal',
  label: null,
  isDisabled: false,
  value: 0,
  step: 1,
  labelColor: '#fff',
  labelPosition: 'left',
  min: 0,
  max: 100,
};
