/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ContextMenuTrigger } from 'react-contextmenu';
import DraggableMenu from 'react-draggable';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReactTooltip from 'react-tooltip';
import Tooltip from '../Tooltip';
import ContextMenuItems from '../ContextMenuItems';
import firebase, {
  dashboardListner,
  userDashboardListner,
  updateMenuCoordinates,
  updateMenuVisibility,
  userListner,
  updateUserStatus,
  masterListner,
} from '../../firebase';
import theme, { rem } from '../../theme';
import Avatar from '../Avatar';
import Group from '../Group';
import Button from '../Button';
import Icon from '../Icon';
import Text from '../Text';
import Badge from '../Badge';
import Image from '../Image';
import ColorSettings from './ColorSettings';
import UserSettings from './UserSettings';
import DashboardSettings from './DashboardSettings';
import AdminSettings from './AdminSettings';
import Animation from '../LottieControl';
import TextField from '../TextField';
import CustomToast from '../CustomToast';

const portal = document.createElement('div');
portal.className = 'portal';
document.body.appendChild(portal);

/** Bottom drag */
const BottomDrag = rem => `
  bottom: ${rem(70)};
`;

/** Bottom drag */
const TopDrag = rem => `
  bottom: ${rem(70)};
`;

const StyledIcon = styled(Icon)`
  ${({ yPosition, aside }) => `
    position: absolute;
    left: 0;
    right: 0;
    bottom: -${rem(20)};
    z-index: 4;

    ${!aside && yPosition === 'bottom' ? BottomDrag(rem) : null};
    ${aside && yPosition !== 'bottom' ? TopDrag(rem) : null};

    &:hover {
      cursor: grab;
    }

    &:active {
      cursor: grabbing;
    }
  `}
`;

const leftBottomButtons = css`
  transform: rotate(90deg);
`;

const rightBottomButtons = css`
  transform: rotate(90deg);
`;

const StyledAnimation = styled(Animation)`
  ${({ aside, xPosition, yPosition }) => `
    transform: rotate(${aside ? -90 : 0}deg);

    ${
      xPosition === 'left' && yPosition === 'bottom' ? leftBottomButtons : null
    };
    ${
      xPosition === 'right' && yPosition === 'bottom'
        ? rightBottomButtons
        : null
    };
  `}

  position: absolute;
  z-index: -1;
`;

/** leftMain */
const leftMain = (rem, elementDimensions) => `
  padding: ${rem(20)} 0 ${rem(20)} 0;
  left: ${rem(65)};
  right: auto;
  border-top-right-radius: ${rem(20)};

  #js-SpyNavuser,
  #js-SpyNavdashboard {
    top: ${rem(100)};
    left: ${'calc(' +
      `${elementDimensions.width}` +
      ' + ' +
      `${rem(52)}` +
      ')'};
    right: auto;
    height: auto;
  }
`;

/** bottomMain */
const bottomMain = (rem, elementDimensions) => `
  top: ${'calc(calc(' +
    `${elementDimensions.height}` +
    ' /-1) + ' +
    `${rem(6)}` +
    ')'};
  bottom: ${'calc(calc(' +
    `${elementDimensions.width}` +
    ' /-1) + ' +
    `${rem(386)}` +
    ')'};
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: ${rem(20)};
  border-top-right-radius: ${rem(20)};
  padding: ${rem(20)} 0 0 0;

  #js-SpyNavuser,
  #js-SpyNavdashboard {
    top: ${'calc(calc(' +
      `${elementDimensions.width}` +
      ' /-1) - ' +
      `${rem(123)}` +
      ')'};
  }
`;

/** bottomRightMain */
const bottomRightMain = (rem, elementDimensions) => `
  padding: ${rem(20)} 0 ${rem(20)} 0;
  top: ${'calc(calc(' +
    `${elementDimensions.height}` +
    ' /-1) + ' +
    `${rem(6)}` +
    ')'};
  bottom: ${'calc(calc(' +
    `${elementDimensions.width}` +
    ' /-1) + ' +
    `${rem(386)}` +
    ')'};
  border-bottom-right-radius: 0;
  border-bottom-left-radius: ${rem(20)};
  border-top-left-radius: ${rem(20)};
  border-top-right-radius: ${rem(20)};

  #js-SpyNavuser,
  #js-SpyNavdashboard {
    top: ${'calc(calc(' +
      `${elementDimensions.width}` +
      ' /-1) - ' +
      `${rem(123)}` +
      ')'};
  }
`;

/** bottomLeftMain */
const bottomLeftMain = (rem, elementDimensions) => `
  padding: ${rem(20)} 0 ${rem(20)} 0;
  top: ${'calc(calc(' +
    `${elementDimensions.height}` +
    ' /-1) + ' +
    `${rem(6)}` +
    ')'};
  bottom: ${'calc(calc(' +
    `${elementDimensions.width}` +
    ' /-1) + ' +
    `${rem(386)}` +
    ')'};
  border-bottom-right-radius: ${rem(20)};
  border-bottom-left-radius: 0;
  border-top-left-radius: ${rem(20)};
  border-top-right-radius: ${rem(20)};

  #js-SpyNavuser,
  #js-SpyNavdashboard {
    top: ${'calc(calc(' +
      `${elementDimensions.width}` +
      ' /-1) - ' +
      `${rem(123)}` +
      ')'};
  }
`;

/** rightMain */
const rightMain = (rem, elementDimensions) => `
  padding: ${rem(20)} 0 ${rem(20)} 0;
  left: auto;
  right: ${rem(67)};
  border-top-left-radius: ${rem(20)};

  #js-SpyNavuser,
  #js-SpyNavdashboard {
    top: ${rem(100)};
    left: ${'calc(calc(' +
      `${elementDimensions.width}` +
      ' /-1) - ' +
      `${rem(35)}` +
      ')'};
    right: auto;
    height: auto;
  }
`;

const StyledModalContent = styled.div`
  ${({ elementDimensions, menuColor, aside, xPosition, yPosition }) => `
    padding: 0 0 ${rem(20)} 0;
    position: fixed;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: ${rem(20)};
    border-bottom-left-radius: ${rem(20)};
    transition: 0.25s;
    display: flex;
    opacity: 0;
    z-index: 0;
    width: ${elementDimensions.width};
    height: ${elementDimensions.height};
    z-index: -1;
    top: ${rem(66)};
    bottom: auto;
    left: ${rem(40)};
    right: auto;
    background-color: ${menuColor ? menuColor : 'rgba(0, 0, 0, 0.5)'};

    ${xPosition === 'left' && aside ? leftMain(rem, elementDimensions) : null};
    ${
      xPosition === 'right' && aside ? rightMain(rem, elementDimensions) : null
    };

    ${
      yPosition === 'bottom' && xPosition === 'center'
        ? bottomMain(rem, elementDimensions)
        : null
    };
    ${
      xPosition === 'right' && aside && yPosition === 'bottom'
        ? bottomRightMain(rem, elementDimensions)
        : null
    };
    ${
      xPosition === 'left' && aside && yPosition === 'bottom'
        ? bottomLeftMain(rem, elementDimensions)
        : null
    };
  `}
`;

const StyledButtonIcon = styled(Icon)`
  ${({ aside }) => `
    transform: rotate(${aside ? -90 : 0}deg);
  `}
`;

const StyledWrapper = styled(Group)`
  position: absolute;
  z-index: 3;
  width: auto;
`;

const StyledAvatar = styled(Avatar)`
  cursor: pointer;

  // figure {
  //   box-shadow: -1px 4px 10px 2px rgba(0, 0, 0, 0.5);
  // }
`;

/** leftBottomContent */
const leftBottomContent = hideOptions => `
  transform: rotate(-90deg) ${hideOptions ? 'scale(1)' : 'scale(0)'};
  top: ${rem(40)};

`;

/** rightBottomContent */
const rightBottomContent = hideOptions => `
  transform: rotate(-90deg) ${hideOptions ? 'scale(1)' : 'scale(0)'};
  top: ${rem(40)};
`;

const StyledMainMenuWrapper = styled.div`
  ${({ width }) => `
    position: absolute;
    width: ${width}px;
    display: flex;
    align-items: center;

    [data-react-beautiful-dnd-drag-handle="0"] {
      height: 100%;
      position: relative;

      &:before {
        content: "";
        transition: 0.2s;
        opacity: 0;
        display: block;
        position: absolute;
        top: -15px;
        background: url(resources/equal.svg) no-repeat;
        width: 15px;
        height: 20px;
        float: left;
        margin: 0 0 0 22px;
      }

      &:hover,
      &:focus {
        &:before {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        height: calc(100% + 10px);
        width: 60px;
        bottom: 0;
        top: -10px;
        position: absolute;
      }
    }
  `}
`;

const StyledContentWrapper = styled.div`
  ${({ width, aside, hideOptions, xPosition, yPosition }) => `
    width: ${width}px;
    height: ${rem(60)};
    position: absolute;
    transition: transform 0.1s linear;
    transform-origin: left;
    transform-style: preserve-3D;
    transform: rotate(${aside ? 90 : 0}deg) ${
    hideOptions ? 'scale(1)' : 'scale(0)'
  };
    left: ${aside ? rem(35) : '0'};
    top: ${aside ? rem(-40) : 'auto'};

    ${
      xPosition === 'left' && yPosition === 'bottom'
        ? leftBottomContent(hideOptions)
        : null
    };

    ${
      xPosition === 'right' && yPosition === 'bottom'
        ? rightBottomContent(hideOptions)
        : null
    };
  `}
`;

const StyledGroupWrapper = styled.div`
  ${({ hideOptions, menuColor }) => `
    background: ${menuColor ? menuColor : 'rgba(0, 0, 0, 0.75)'};
    display: flex;
    align-items: center;
    position: relative;
    border-top-right-radius: ${rem(30)};
    border-bottom-right-radius: ${rem(30)};
    padding-left: ${rem(43)};
    margin-left: -${rem(10)};
    height: ${rem(60)};
    // box-shadow: -1px 4px 10px 2px rgba(0, 0, 0, 0.5);
    transition: transform 0.1s linear;
    transform-origin: left;
    transform-style: preserve-3D;
    transform: ${hideOptions ? 'scale(1)' : 'scale(0)'};
    left: ${rem(45)};
    width: auto;
    justify-content: space-evenly;

    &:after {
      content: '';
      opacity: 0;
      z-index: -1;
      position: absolute;
      background: ${menuColor ? menuColor : 'rgba(0, 0, 0, 0.75)'};
      width: 0px;
      height: 100%;
      right: 0;
      top: 0;
      border-top-right-radius: ${rem(30)};
      border-bottom-right-radius: ${rem(30)};
    }

    &.interactive {
      border-top-right-radius: 0!important;
      border-bottom-right-radius: 0!important;
  
      &:after {
        transition: 0.1s;
        opacity: 1;
        width: 58px;
        right: -58px;
      }
    }
  `}
`;

const StyleAvatarWrapper = styled.div`
  display: flex;
  z-index: 1;
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
`;

const ButtonWrapper = styled.div`
  width: calc(233px / 3);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  ${({ aside }) => `
    position: relative;
    transition: 0.2s;

    button {
      transition: 0.2s;
      filter: grayscale(1);
      width: 100%;
      height: 100%;
      overflow: hidden;

      svg {
        z-index: -1;
      }
    }

    &:hover {
      button {
        height: 100%;
        width: 100%;
        transition: 0.2s;
        transition-delay: 0.3s;
        backface-visibility: hidden;
        perspective: 1000;
        overflow: visible;
        transform: translate3d(${aside ? rem(-5) : 0}, ${
    aside ? 0 : rem(-5)
  }, 0);
        filter: grayscale(0);
      }
    }

    &.active {
      transition: 0.2s;

      button {
        transition: 0.2s;
        filter: grayscale(0);
      }

      i {
        transition: 0.2s;
        transform: scale(1.8);
      }
    }
  `}
`;

const StyledBadgeIndicator = styled(Badge)`
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
`;

const StyledShareLink = styled(Animation)`
  z-index: 10;
  position: absolute;
`;

const StyledAnimationWrapper = styled.div`
  cursor: pointer;
  position: relative;
  min-width: 1.5rem;
  min-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #47a827;
  border-radius: 50%;
`;

const StyledImage = styled(Image)`
  ${({ xPosition, yPosition }) => `
    position: absolute;
    z-index: 1;
    right: 0;
    width: 100%;
    height: auto;
    filter: drop-shadow(1px 1px 1px rgba(54, 54, 54, 0.5));

    transform : ${
      xPosition === 'left' && yPosition === 'top' ? 'rotate(-180deg)' : ''
    };
    transform : ${
      xPosition === 'left' && yPosition === 'bottom' ? 'rotate(0deg)' : ''
    };
    transform : ${
      xPosition === 'left' && yPosition === 'center' ? 'rotate(180deg)' : ''
    };
    transform : ${
      xPosition === 'right' && yPosition === 'top' ? 'rotate(-180deg)' : ''
    };
    transform : ${
      xPosition === 'right' && yPosition === 'bottom' ? 'rotate(0deg)' : ''
    };
    transform : ${
      xPosition === 'right' && yPosition === 'center' ? 'rotate(180deg)' : ''
    };
  `}
`;
const StyledGroup = styled(Group)`
  overflow: hidden;
`;

const StyledGroupLink = styled(Group)`
  ${({ hideOptions, aside, xPosition, yPosition }) => `
    position: absolute;
    top: ${aside ? 'auto' : '-8px'};
    bottom: ${aside ? '-8px' : 'auto'};
    right: -2px;
    width: auto;
    transition: ${hideOptions ? '0' : '0'};
    transition-delay: ${hideOptions ? '0.1s' : '0'};
    visability: ${hideOptions ? 'show' : 'hidden'};
    opacity: ${hideOptions ? '1' : '0'};
    transform: rotate(${aside ? -90 : 0}deg);
    z-index: 1;

    ${
      xPosition === 'left' && yPosition === 'bottom'
        ? 'transform: rotate(90deg)'
        : null
    };

    ${
      xPosition === 'right' && yPosition === 'bottom'
        ? 'transform: rotate(90deg)'
        : null
    };
  `}
`;

const StyledTextField = styled(TextField)`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

/**
 * Overview component
 * @param {string} activeDashboardId
 */
const Menu = props => {
  const { activeDashboardId } = props;

  //States
  const [menuData, setMenuData] = useState({});
  const [aside, setAside] = useState();
  const [userInfo, setUserInfo] = useState({});
  const [positions, setPositions] = useState();

  const [inviteInfo, setInviteInfo] = useState({});
  const [inviteDashInfo, setInviteDashInfo] = useState();
  const [notificationInfo, setNotificationInfo] = useState({});
  const [dashboardInviteInfo, setDashboardInviteInfo] = useState({});
  const [totalNumber, setTotalNumber] = useState();
  const [menuColor, setMenuColor] = useState();

  const [userSettingsAnimation, setUserSettingsAnimation] = useState(true);
  const [colorSettingsAnimation, setColorSettingsAnimation] = useState(true);
  const [adminSettingsAnimation, setAdminSettingsAnimation] = useState(true);
  const [friendSettingsAnimation, setFriendSettingsAnimation] = useState(true);
  const [
    notificationSettingsAnimation,
    setNotificationSettingsAnimation,
  ] = useState(true);
  const [dashboardSettingsAnimation, setDashboardSettingsAnimation] = useState(
    true
  );
  const [searchSettingsAnimation, setSearchSettingsAnimation] = useState(true);
  const [xPosition, setXPosition] = useState();
  const [yPosition, setYPosition] = useState();
  const [indicator, setIndicator] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const [isMaster, setIsMaster] = useState(false);
  const [permissions, setPermissions] = useState({});

  //UserId
  const uuid = firebase.auth().currentUser.uid;

  //Main Ref
  const myRef = useRef();

  //Content Refs
  const userSettingsRef = useRef();
  const colorSettingsRef = useRef();
  const friendSettingsRef = useRef();
  const notificationSettingsRef = useRef();
  const dashboardSettingsRef = useRef();
  const searchSettingsRef = useRef();
  const adminSettingsRef = useRef();
  const linkRef = useRef();

  //Button Refs
  const mainButtonRef = useRef();
  const userButtonRef = useRef();
  const colorButtonRef = useRef();
  const friendButtonRef = useRef();
  const notificationButtonRef = useRef();
  const dashboardButtonRef = useRef();
  const searchButtonRef = useRef();
  const adminButtonRef = useRef();

  useEffect(() => {
    listnerSetup();
    getTotalNumber();
  }, []);

  useEffect(() => {
    if (myRef.current) {
      myRef.current.style.height = '0';
      myRef.current.style.opacity = '0';
      myRef.current.style.visibility = 'hidden';
    }
  }, [myRef.current]);

  useEffect(() => {
    if (linkRef.current) {
      dashboardListner
        .child(activeDashboardId)
        .child('link')
        .child('id')
        .once('value', snap => {
          if (snap.val()) {
            linkRef.current.value =
              'onlinedashboard.studentsplus.nl/' + snap.val();
          }
        });
    }
  }, [linkRef.current]);

  useEffect(() => {
    getTotalNumber();
  }, [inviteInfo, dashboardInviteInfo, notificationInfo, inviteDashInfo]);

  /** Get the total number */
  const getTotalNumber = () => {
    let count = 0;
    if (
      !inviteDashInfo &&
      dashboardInviteInfo &&
      Object.keys(dashboardInviteInfo).length > 0
    ) {
      Object.values(dashboardInviteInfo).map(invite => {
        if (!invite.seen) count++;
      });
    }

    if (notificationInfo && Object.keys(notificationInfo).length > 0) {
      Object.values(notificationInfo).map(invite => {
        if (!invite.seen) count++;
      });
    }

    if (inviteInfo && Object.keys(inviteInfo).length > 0) {
      Object.values(inviteInfo).map(invite => {
        if (!invite.seen) count++;
      });
    }
    setTotalNumber(count);
  };

  /**
   * Reorder the main menu items
   * @param {*} startIndex
   * @param {*} endIndex
   * @param {*} sourceListId
   * @param {*} draggedItemId
   */
  const reorder = (startIndex, endIndex, sourceListId, draggedItemId) => {
    let newArray = [];
    let idArray = [];

    const buttonWrapper = document.getElementById('menuButtons').children;
    newArray = Array.from(buttonWrapper);
    newArray.forEach(item => {
      const id = item.firstChild.dataset.type;
      idArray.push(id);
    });

    idArray.splice(endIndex, 0, draggedItemId);

    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('activity')
      .child('menu')
      .update({ positions: JSON.stringify(idArray) })
      .then(() => {
        setPositions(idArray);
        ReactTooltip.rebuild();
      });
  };

  /** Hide all menu items */
  const hideAllItems = () => {
    if (myRef.current && mainButtonRef.current) {
      myRef.current.style.height = '0';
      myRef.current.style.opacity = '0';
      myRef.current.style.visibility = 'hidden';
      mainButtonRef.current.style.borderBottomRightRadius = '';
      mainButtonRef.current.style.borderTopRightRadius = rem(30);
    }
    if (userSettingsRef.current && userButtonRef.current) {
      userButtonRef.current.classList.remove('active');
      userSettingsRef.current.style.opacity = '0';
      userSettingsRef.current.style.visibility = 'hidden';
      // userSettingsRef.current.style.height = '0';
      // userSettingsRef.current.style.width = '0';
      userSettingsRef.current.style.transition = '0s';
      // userSettingsRef.current.style.display = 'none';
    }

    if (colorSettingsRef.current && colorButtonRef.current) {
      colorButtonRef.current.classList.remove('active');
      colorSettingsRef.current.style.opacity = '0';
      colorSettingsRef.current.style.visibility = 'hidden';
      // colorSettingsRef.current.style.height = '0';
      // colorSettingsRef.current.style.width = '0';
      colorSettingsRef.current.style.transition = '0s';
      // colorSettingsRef.current.style.display = 'none';
    }

    if (adminSettingsRef.current && adminButtonRef.current) {
      adminButtonRef.current.classList.remove('active');
      adminSettingsRef.current.style.opacity = '0';
      adminSettingsRef.current.style.visibility = 'hidden';
      // colorSettingsRef.current.style.height = '0';
      // colorSettingsRef.current.style.width = '0';
      adminSettingsRef.current.style.transition = '0s';
      // colorSettingsRef.current.style.display = 'none';
    }

    if (friendSettingsRef.current && friendButtonRef.current) {
      friendButtonRef.current.classList.remove('active');
      friendSettingsRef.current.style.opacity = '0';
      friendSettingsRef.current.style.visibility = 'hidden';
      // friendSettingsRef.current.style.height = '0';
      // friendSettingsRef.current.style.width = '0';
      friendSettingsRef.current.style.transition = '0s';
      // friendSettingsRef.current.style.display = 'none';
    }

    if (notificationSettingsRef.current && notificationButtonRef.current) {
      notificationButtonRef.current.classList.remove('active');
      notificationSettingsRef.current.style.opacity = '0';
      notificationSettingsRef.current.style.visibility = 'hidden';
      // notificationSettingsRef.current.style.height = '0';
      // notificationSettingsRef.current.style.width = '0';
      notificationSettingsRef.current.style.transition = '0s';
      // notificationSettingsRef.current.style.display = 'none';
    }

    if (dashboardSettingsRef.current && dashboardButtonRef.current) {
      dashboardButtonRef.current.classList.remove('active');
      dashboardSettingsRef.current.style.opacity = '0';
      dashboardSettingsRef.current.style.visibility = 'hidden';
      // dashboardSettingsRef.current.style.height = '0';
      // dashboardSettingsRef.current.style.width = '0';
      dashboardSettingsRef.current.style.transition = '0s';
      // dashboardSettingsRef.current.style.display = 'none';
    }

    if (searchSettingsRef.current && searchButtonRef.current) {
      searchButtonRef.current.classList.remove('active');
      searchSettingsRef.current.style.opacity = '0';
      searchSettingsRef.current.style.visibility = 'hidden';
      // searchSettingsRef.current.style.height = '0';
      // searchSettingsRef.current.style.width = '0';
      searchSettingsRef.current.style.transition = '0s';
      // searchSettingsRef.current.style.display = 'none';
    }
  };

  /** Handle button click */
  const handleButtonClick = (event, type) => {
    hideAllItems();
    myRef.current.style.height = '';
    myRef.current.style.opacity = '1';
    myRef.current.style.visibility = '';

    if (
      xPosition === 'right' ||
      (xPosition === 'center' && yPosition !== 'bottom')
    ) {
      mainButtonRef.current.style.borderBottomRightRadius = '0';
    }

    if (xPosition === 'left') {
      mainButtonRef.current.style.borderTopRightRadius = '0';
    }

    if (yPosition === 'bottom' && xPosition === 'center') {
      mainButtonRef.current.style.borderTopRightRadius = '0';
    }

    if (aside && yPosition === 'bottom' && xPosition === 'right') {
      mainButtonRef.current.style.borderTopRightRadius = '0';
      mainButtonRef.current.style.borderBottomRightRadius = '';
    }

    if (aside && yPosition === 'bottom' && xPosition === 'left') {
      mainButtonRef.current.style.borderTopRightRadius = '';
      mainButtonRef.current.style.borderBottomRightRadius = '0';
    }

    switch (type) {
      default:
      case 'userSettings':
        if (userSettingsRef.current) {
          userSettingsRef.current.style.opacity = '1';
          userSettingsRef.current.style.visibility = '';
          // userSettingsRef.current.style.height = '';
          // userSettingsRef.current.style.width = '';
          userSettingsRef.current.style.transition = '0.35s';
          // userSettingsRef.current.style.display = '';
          userButtonRef.current.classList.add('active');
        }
        break;

      case 'colorSettings':
        if (colorSettingsRef.current) {
          colorSettingsRef.current.style.opacity = '1';
          colorSettingsRef.current.style.visibility = '';
          // colorSettingsRef.current.style.height = '';
          // colorSettingsRef.current.style.width = '';
          colorSettingsRef.current.style.transition = '0.35s';
          // colorSettingsRef.current.style.display = '';
          colorButtonRef.current.classList.add('active');
        }
        break;

      case 'adminSettings':
        if (adminSettingsRef.current) {
          adminSettingsRef.current.style.opacity = '1';
          adminSettingsRef.current.style.visibility = '';
          // adminSettingsRef.current.style.height = '';
          // adminSettingsRef.current.style.width = '';
          adminSettingsRef.current.style.transition = '0.35s';
          // adminSettingsRef.current.style.display = '';
          adminButtonRef.current.classList.add('active');
        }
        break;

      case 'friendSettings':
        if (friendSettingsRef.current) {
          friendSettingsRef.current.style.opacity = '1';
          friendSettingsRef.current.style.visibility = '';
          // friendSettingsRef.current.style.height = '';
          // friendSettingsRef.current.style.width = '';
          friendSettingsRef.current.style.transition = '0.35s';
          // friendSettingsRef.current.style.display = '';
          friendButtonRef.current.classList.add('active');
        }
        break;

      case 'notificationSettings':
        if (notificationSettingsRef.current) {
          notificationSettingsRef.current.style.opacity = '1';
          notificationSettingsRef.current.style.visibility = '';
          // notificationSettingsRef.current.style.height = '';
          // notificationSettingsRef.current.style.width = '';
          notificationSettingsRef.current.style.transition = '0.35s';
          // notificationSettingsRef.current.style.display = '';
          notificationButtonRef.current.classList.add('active');

          userListner
            .child(uuid)
            .child('notifications')
            .once('value', snap => {
              snap.forEach(function(childSnapshot) {
                if (!childSnapshot.seen) {
                  childSnapshot.ref.update({ seen: true });
                }
              });
            });
        }
        break;

      case 'dashboardSettings':
        if (dashboardSettingsRef.current) {
          dashboardSettingsRef.current.style.opacity = '1';
          dashboardSettingsRef.current.style.visibility = '';
          // dashboardSettingsRef.current.style.height = '';
          // dashboardSettingsRef.current.style.width = '';
          dashboardSettingsRef.current.style.transition = '0.35s';
          // dashboardSettingsRef.current.style.display = '';
          dashboardButtonRef.current.classList.add('active');
        }
        break;

      case 'searchSettings':
        if (searchSettingsRef.current) {
          searchSettingsRef.current.style.opacity = '1';
          searchSettingsRef.current.style.visibility = '';
          // searchSettingsRef.current.style.height = '';
          // searchSettingsRef.current.style.width = '';
          searchSettingsRef.current.style.transition = '0.35s';
          // searchSettingsRef.current.style.display = '';
          searchButtonRef.current.classList.add('active');
        }
        break;
    }
  };

  /** Setup the listners */
  const listnerSetup = () => {
    userListner
      .child(uuid)
      .child('invites')
      .child('dashboards')
      .child('seen')
      .on('value', snap => {
        setInviteDashInfo(snap.val());
      });

    masterListner.once('value', snapshot => {
      if (snapshot.exists() && snapshot.val().members) {
        Object.keys(snapshot.val().members).map(member => {
          if (member === uuid) {
            setIsMaster(true);
          }
        });
      }
    });

    /** User permissions */
    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('permissions')
      .on('value', snap => {
        if (snap.val()) {
          userDashboardListner
            .child(uuid)
            .child(activeDashboardId)
            .child('activity')
            .child('menu')
            .child('positions')
            .once('value', snapshot => {
              if (snapshot.val()) {
                let positions = JSON.parse(snapshot.val());
                let newArray = [];

                if (
                  !positions.includes('Administrator') &&
                  snap.val().menuitem
                ) {
                  positions.splice(3, 0, 'Administrator');
                  newArray = positions;
                  console.log('KOM HIER 1');
                } else {
                  positions.map(item => {
                    if (item === 'Administrator') {
                      masterListner.once('value', snapshotMaster => {
                        if (
                          snapshotMaster.exists() &&
                          snapshotMaster.val().members
                        ) {
                          Object.keys(snapshotMaster.val().members).map(
                            member => {
                              if (member === uuid || snap.val().menuitem) {
                                //CHeck if item is already in the array
                                if (!newArray.includes(item))
                                  newArray.push(item);
                              }
                            }
                          );
                        }
                      });
                    } else {
                      console.log('KOM HIER 3', item);
                      newArray.push(item);
                    }
                  });
                }

                userDashboardListner
                  .child(uuid)
                  .child(activeDashboardId)
                  .child('activity')
                  .child('menu')
                  .update({
                    positions: JSON.stringify(newArray),
                  });
              }
            })
            .then(() => {
              setPermissions(snap.val());
              ReactTooltip.rebuild();
            });
        } else {
          if (
            mainButtonRef.current &&
            !mainButtonRef.current.closest('.active')
          ) {
            hideAllItems();
          }
        }
      });

    dashboardListner
      .child(activeDashboardId)
      .child('creators')
      .child(uuid)
      .on('value', snap => {
        if (snap.val()) {
          setIsOwner(true);
        }
      });

    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('activity')
      .child('menu')
      .child('positions')
      .on('value', snap => {
        if (snap.val() && snap.val() !== '') {
          setPositions(JSON.parse(snap.val()));
        } else {
          setPositions(['Global', 'Colors', 'Administrator']);
          //setPositions(["Global", "Colors", "Administrator", "Friends", "Dashboard", "Notifications", "Search"]);
        }
      });

    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('activity')
      .child('menu')
      .once('value', snap => {
        if (snap.val()) {
          setMenuData(snap.val());
          setAside(snap.val().aside);
          setXPosition(snap.val().xPosition);
          setYPosition(snap.val().yPosition);
        }
      })
      .then(() => {
        userListner
          .child(uuid)
          .child('public')
          .on('value', snap => {
            setUserInfo(snap.val());
            setIndicator(snap.val().indicator);
          });
      });

    userListner
      .child(uuid)
      .child('invites')
      .child('friends')
      .child('invitesIn')
      .on('value', snap => {
        setInviteInfo(snap.val());
      });

    userListner
      .child(uuid)
      .child('notifications')
      .on('value', snapDashboard => {
        setNotificationInfo(snapDashboard.val());
      });

    userListner
      .child(uuid)
      .child('invites')
      .child('dashboards')
      .child('invitesIn')
      .orderByChild('time')
      .startAt(Date.now())
      .on('child_added', snap => {
        inviteInfo[snap.key] = snap.val();
        setDashboardInviteInfo(inviteInfo);
      });

    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('colors')
      .child('menuColor')
      .on('value', snapDashboard => {
        setMenuColor(snapDashboard.val());
      });
  };

  /** Update coordinates */
  const getCursorData = positionData => {
    updateMenuCoordinates(
      positionData.x,
      positionData.y,
      aside,
      activeDashboardId,
      xPosition,
      yPosition
    );
  };

  /** Handle Drag */
  const handleDrag = (e, data) => {
    const elementWidth = document.getElementById('root').offsetWidth;
    const elementHeight = window.innerHeight;
    if (data.x < 100 || data.x > elementWidth - 325) {
      setAside(true);
      if (data.x < 100) {
        setXPosition('left');
      } else if (data.x > elementWidth - 325) {
        setXPosition('right');
      }
    }

    if (data.x > 100 && data.x < elementWidth - 325) {
      setAside(false);
      setXPosition('center');
    }

    if (data.y < 50) {
      setYPosition('top');
    }

    if (data.y > elementHeight - 325) {
      setYPosition('bottom');
    }

    if (data.y > 50 && data.y < elementHeight - 326) {
      setYPosition('center');
    }
  };

  /** Handle Stop */
  const handleStop = (e, data) => {
    getCursorData(data);
  };

  /** Click handler */
  const handleClick = () => {
    menuData['visible'] = !menuData['visible'];
    updateMenuVisibility(menuData['visible'], activeDashboardId);

    setMenuData(prevState => {
      return { ...prevState, ...menuData };
    });
  };

  /** Click handler status */
  const handleStatus = status => {
    setIndicator(status);
    updateUserStatus(status);
  };

  /** Handle the width of the content */
  const handleWidth = () => {
    let contentWidth = rem(257);

    if (permissions || isMaster) {
      contentWidth = `calc(${contentWidth + ' + ' + rem(47)})`;
    } else if (isOwner && !permissions.menuitem) {
      contentWidth = `calc(${contentWidth})`;
    } else if (!permissions.menuitem) {
      contentWidth = `calc(${contentWidth})`;
    } else {
      contentWidth = `calc(${contentWidth + ' + ' + rem(47)})`;
    }
    return contentWidth;
  };

  /** Handles click outside event */
  const handleOutsideClick = event => {
    const elements = document.getElementsByClassName('uniqueClassName');
    const buttonWrapper = document.getElementById('menuButtons');
    const modal = document.getElementById('globalModal');
    let hide = true;
    for (var value of elements) {
      if (value && value.contains(event.target)) {
        hide = false;
      }
    }

    if (
      (buttonWrapper && buttonWrapper.contains(event.target)) ||
      (modal && modal.contains(event.target)) ||
      (myRef.current &&
        myRef.current.contains(event.target) &&
        myRef.current.contains(
          document.elementFromPoint(event.clientX, event.clientY)
        ))
    ) {
      return;
    } else {
      if (elements && !hide) {
        return;
      } else {
        hideAllItems();
      }
    }
  };
  document.addEventListener('mousedown', handleOutsideClick);

  /** Get width on item drag */
  const getWidthOnDrag = type => {
    if (document.getElementById('menuButtons')) {
      let childNum = document.getElementById('menuButtons').childElementCount;

      if (type === 'update') childNum++;
      let parent = childNum * 60;
      document.getElementById('menuButtons').style.width = 'auto';
      //document.getElementById('menuButtons').style.width = "325px";
      return parent;
    }
  };

  /** onDragStart */
  const onDragStart = () => {
    if (mainButtonRef.current) {
      mainButtonRef.current.classList.add('interactive');
      ReactTooltip.hide();
      hideAllItems();
    }
  };

  /** onDragUpdate */
  const onDragUpdate = () => {
    //Calc width
    //console.log('result update', getWidthOnDrag('update'));
  };

  /** onDragEnd */
  const onDragEnd = result => {
    if (mainButtonRef.current) {
      if (result.destination) {
        reorder(
          result.source.index,
          result.destination.index,
          result.source.droppableId,
          result.draggableId
        );
      } else {
        //log('DROP OUTSIDE MENU', result);
        //CHECK IF PART OF menuButtons
      }

      document.getElementById('menuButtons').style.width = 'auto';
      mainButtonRef.current.classList.remove('interactive');
    }
  };

  /**
   * Handle mouse enter
   * @param {*} item
   */
  const handleMouseEnter = item => {
    switch (item) {
      default:
      case 'Global':
        setUserSettingsAnimation(false);
        break;
      case 'Colors':
        setColorSettingsAnimation(false);
        break;
      case 'Administrator':
        setAdminSettingsAnimation(false);
        break;
      case 'Friends':
        setFriendSettingsAnimation(false);
        break;
      case 'Notifications':
        setNotificationSettingsAnimation(false);
        break;
      case 'Dashboard':
        setDashboardSettingsAnimation(false);
        break;
      case 'Search':
        setSearchSettingsAnimation(false);
        break;
    }
  };

  /**
   * handleMouseLeave
   * @param {*} item
   */
  const handleMouseLeave = item => {
    switch (item) {
      default:
      case 'Global':
        setUserSettingsAnimation(true);
        break;
      case 'Colors':
        setColorSettingsAnimation(true);
        break;
      case 'Administrator':
        setAdminSettingsAnimation(true);
        break;
      case 'Friends':
        setFriendSettingsAnimation(true);
        break;
      case 'Notifications':
        setNotificationSettingsAnimation(true);
        break;
      case 'Dashboard':
        setDashboardSettingsAnimation(true);
        break;
      case 'Search':
        setSearchSettingsAnimation(true);
        break;
    }
  };

  /**
   * handleIsStopped
   * @param {*} item
   */
  const handleIsStopped = item => {
    switch (item) {
      default:
      case 'Global':
        return userSettingsAnimation;
      case 'Colors':
        return colorSettingsAnimation;
      case 'Administrator':
        return adminSettingsAnimation;
      case 'Friends':
        return friendSettingsAnimation;
      case 'Notifications':
        return notificationSettingsAnimation;
      case 'Dashboard':
        return dashboardSettingsAnimation;
      case 'Search':
        return searchSettingsAnimation;
    }
  };

  /**
   * Create draggable item
   * @param {*} item
   */
  const createDraggableItem = item => {
    let dataObject = {};
    switch (item) {
      case 'Global':
        dataObject = {
          animation: {
            icon: 'gears',
            width: rem(35),
            height: rem(35),
            state: userSettingsAnimation,
          },
          ref: userButtonRef,
          click: 'userSettings',
        };
        break;

      case 'Colors':
        dataObject = {
          animation: {
            icon: 'colors',
            width: rem(70),
            height: rem(50),
            state: colorSettingsAnimation,
          },
          ref: colorButtonRef,
          click: 'colorSettings',
        };
        break;

      case 'Administrator':
        {
          if (permissions.menuitem || isOwner || isMaster) {
            dataObject = {
              animation: {
                icon: 'sliders',
                width: rem(70),
                height: rem(50),
                state: adminSettingsAnimation,
              },
              ref: adminButtonRef,
              click: 'adminSettings',
            };
          }
        }
        break;

      // case 'Friends':
      //   dataObject = {
      //     animation: {
      //       icon: 'profile',
      //       width: rem(80),
      //       height: rem(40),
      //       state: friendSettingsAnimation,
      //     },
      //     ref: friendButtonRef,
      //     click: 'friendSettings',
      //   };
      //   break;

      // case 'Notifications':
      //   dataObject = {
      //     animation: {
      //       icon: 'bell',
      //       width: rem(45),
      //       height: rem(45),
      //       state: notificationSettingsAnimation,
      //     },
      //     ref: notificationButtonRef,
      //     click: 'notificationSettings',
      //   };
      //   break;

      // case 'Dashboard':
      //   dataObject = {
      //     animation: {
      //       icon: 'dashboard',
      //       width: rem(25),
      //       height: rem(25),
      //       state: dashboardSettingsAnimation,
      //     },
      //     ref: dashboardButtonRef,
      //     click: 'dashboardSettings',
      //   };
      //   break;

      // case 'Search':
      //   dataObject = {
      //     ref: searchButtonRef,
      //     click: 'searchSettings',
      //     animation: {
      //       icon: 'search',
      //       width: rem(40),
      //       height: rem(40),
      //       state: searchSettingsAnimation,
      //     },
      //     extraStyle: { left: '-4px', top: '3px' }
      //   };
      //   break;

      default:
        return;
    }

    if (Object.keys(dataObject).length > 0) {
      return (
        <ButtonWrapper
          menuColor={menuColor}
          ref={dataObject.ref}
          aside={aside}
          xPosition={xPosition}
          yPosition={yPosition}
          data-type={item}
        >
          <Button
            variant='icon'
            onClick={e => {
              handleButtonClick(e, dataObject.click);
            }}
            bgColor='transparent'
            onMouseEnter={() => handleMouseEnter(item)}
            onMouseLeave={() => handleMouseLeave(item)}
            data-for={'tip' + item}
            data-delay-show='0'
            data-tip=''
          >
            <Tooltip
              id={'tip' + item}
              content={{
                title: {
                  text: item,
                  textStyle: 'body',
                  textColor: '#fff',
                  align: 'center',
                },
              }}
              noSpacing
            />
            <StyledAnimation
              aside={aside}
              isStopped={handleIsStopped(item)}
              animation={dataObject.animation.icon}
              animationWidth={dataObject.animation.width}
              animationHeight={dataObject.animation.height}
              style={dataObject.extraStyle}
              loop={false}
              xPosition={xPosition}
              yPosition={yPosition}
            />
          </Button>
        </ButtonWrapper>
      );
    }
  };

  return (
    userInfo &&
    menuData &&
    Object.keys(menuData).length > 0 && (
      <DraggableMenu
        axis='both'
        handle='.handle'
        bounds='#root'
        defaultPosition={{
          x: menuData.x,
          y: menuData.y,
        }}
        position={null}
        scale={1}
        onDrag={handleDrag}
        onStop={handleStop}
      >
        <StyledWrapper>
          <StyledIcon
            xPosition={xPosition}
            yPosition={yPosition}
            aside={aside}
            className='handle'
            icon='drag_handle'
            size={20}
          />
          <StyleAvatarWrapper>
            {/* {!menuData['visible'] && <StyledBadge text={totalNumber} />} */}
            <StyledAvatar
              source={userInfo.avatar && userInfo.avatar.cropped}
              size='big'
              onClick={() => handleClick()}
              title={userInfo.name}
              color={userInfo.color}
            />
            <ContextMenuTrigger
              id={'menuMenu' + uuid}
              holdToDisplay={1}
              key={uuid}
            >
              <StyledBadgeIndicator
                variant='status'
                status={userInfo.indicator && userInfo.indicator}
              />
            </ContextMenuTrigger>
          </StyleAvatarWrapper>
          <DragDropContext
            onDragStart={onDragStart}
            onDragUpdate={onDragUpdate}
            onDragEnd={onDragEnd}
          >
            <ContextMenuItems
              id={'menuMenu' + uuid}
              hasBackgroundHover={false}
              data={[
                {
                  id: 'statusOnline',
                  icon: 'lens',
                  iconColor: theme.color.success,
                  text: 'Online',
                  click: () => handleStatus('online'),
                  backgroundColor:
                    indicator && indicator === 'online'
                      ? theme.color.tertiary[100]
                      : '',
                },
                {
                  id: 'statusOffline',
                  icon: 'lens',
                  iconColor: theme.color.light[60],
                  text: 'Offline',
                  click: () => handleStatus('offline'),
                  backgroundColor:
                    indicator && indicator === 'offline'
                      ? theme.color.tertiary[100]
                      : '',
                },
                {
                  id: 'statusAway',
                  icon: 'lens',
                  iconColor: theme.color.warning,
                  text: 'Away',
                  click: () => handleStatus('away'),
                  backgroundColor:
                    indicator && indicator === 'away'
                      ? theme.color.tertiary[100]
                      : '',
                },
                {
                  id: 'statusBusy',
                  icon: 'lens',
                  iconColor: theme.color.danger,
                  text: 'Busy',
                  click: () => handleStatus('busy'),
                  backgroundColor:
                    indicator && indicator === 'busy'
                      ? theme.color.tertiary[100]
                      : '',
                },
              ]}
            />
            <StyledContentWrapper
              width={getWidthOnDrag('update')}
              aside={aside}
              hideOptions={menuData.visible}
              xPosition={xPosition}
              yPosition={yPosition}
            >
              <StyledGroupLink
                hideOptions={menuData.visible}
                aside={aside}
                xPosition={xPosition}
                yPosition={yPosition}
              >
                <StyledTextField
                  selectionBackground='transparent'
                  readOnly
                  textcolor='transparent'
                  placeholdercolor='transparent'
                  backgroundcolor='transparent'
                  inputId='shortcutLink'
                  borders={false}
                  value=''
                  ref={linkRef}
                />
                {/* <React.Fragment>
                  <StyledAnimationWrapper
                    hideOptions={menuData.visible}
                  >
                    <StyledImage
                      onClick={() => copyUrl()}
                      data-for={'tooltipShareUrl'}
                      data-delay-show='200'
                      data-tip=""
                      src="/resources/whatsapp.svg"
                      xPosition={xPosition}
                      yPosition={yPosition}
                    />
                  </StyledAnimationWrapper>
                  <StyledTooltipLink
                    id={'tooltipShareUrl'}
                    getContent={() => {
                      return (
                        <StyledTooltipWrapper>
                          <StyledToolTipText
                            text="Click here to copy the invite url"
                            textStyle="h6"
                            textColor="#fff"
                            align="center"
                          />
                          <StyledToolTipAnimation
                            animation="invite"
                            animationWidth={rem(150)}
                            animationHeight={rem(150)}
                          />
                          <Text
                            text="Give this url to the person that you want to temporarily give access to your dashboard"
                            textStyle="subtitle"
                            textColor="#fff"
                            align="center"
                          />
                        </StyledTooltipWrapper>
                      );
                    }}
                  />
                </React.Fragment> */}
              </StyledGroupLink>
              <Droppable
                droppableId='droppableMainMenu'
                direction={aside ? 'vertical' : 'horizontal'}
              >
                {provided => (
                  <StyledMainMenuWrapper
                    aside={aside}
                    width={getWidthOnDrag('update')}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <StyledGroupWrapper
                      ref={mainButtonRef}
                      aside={aside}
                      xPosition={xPosition}
                      yPosition={yPosition}
                      hideOptions={menuData.visible}
                      menuColor={menuColor}
                      id='menuButtons'
                    >
                      {positions &&
                        positions.map((item, index) => {
                          return (
                            <Draggable
                              key={item}
                              draggableId={item}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                const element = (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {createDraggableItem(item)}
                                  </div>
                                );
                                return snapshot.isDragging
                                  ? createPortal(element, portal)
                                  : element;
                              }}
                            </Draggable>
                          );
                        })}
                    </StyledGroupWrapper>
                  </StyledMainMenuWrapper>
                )}
              </Droppable>
            </StyledContentWrapper>
          </DragDropContext>
          <StyledModalContent
            elementDimensions={{ width: handleWidth(), height: rem(550) }}
            id='menuContent'
            onClick={handleOutsideClick}
            ref={myRef}
            menuColor={menuColor}
            aside={aside}
            xPosition={xPosition}
            yPosition={yPosition}
          >
            <StyledGroup>
              <UserSettings
                ref={userSettingsRef}
                activeDashboardId={activeDashboardId}
                elementDimensions={{ width: handleWidth(), height: rem(450) }}
                menuColor={menuColor}
              />
              <ColorSettings
                ref={colorSettingsRef}
                activeDashboardId={activeDashboardId}
                elementDimensions={{ width: handleWidth(), height: rem(450) }}
                menuColor={menuColor}
              />
              {(permissions.menuitem || isOwner || isMaster) && (
                <AdminSettings
                  ref={adminSettingsRef}
                  activeDashboardId={activeDashboardId}
                  elementDimensions={{ width: handleWidth(), height: rem(450) }}
                  menuColor={menuColor}
                />
              )}
              {/* <FriendsSettings
                ref={friendSettingsRef}
                activeDashboardId={activeDashboardId}
                elementDimensions={{ width: handleWidth(), height: rem(450) }}
                menuColor={menuColor}
              /> */}
              {/* <NotificationsSettings
                ref={notificationSettingsRef}
                activeDashboardId={activeDashboardId}
                elementDimensions={{ width: handleWidth(), height: rem(450) }}
                menuColor={menuColor}
              /> */}
              {/* <DashboardSettings
                ref={dashboardSettingsRef}
                activeDashboardId={activeDashboardId}
                elementDimensions={{ width: handleWidth(), height: rem(450) }}
                menuColor={menuColor}
              /> */}
              {/* <SearchSettings
                ref={searchSettingsRef}
                activeDashboardId={activeDashboardId}
                elementDimensions={{ width: handleWidth(), height: rem(450) }}
                menuColor={menuColor}
              /> */}
            </StyledGroup>
          </StyledModalContent>
        </StyledWrapper>
      </DraggableMenu>
    )
  );
};

Menu.propTypes = {
  activeDashboardId: PropTypes.string,
};

Menu.defaultProps = {
  activeDashboardId: null,
};

/** @component */
export default Menu;
