import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Resizable } from 're-resizable';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import firebase, { userDashboardListner } from '../../firebase';
import theme, { rem } from '../../theme';
import Icon from '../Icon';

const StyledGrid = styled.div`
  display: ${props => (props.columns ? 'inline-grid' : 'grid')};
  grid-template-rows: ${props => (props.rows ? props.rows : null)};
  grid-template-columns: ${props =>
    !props.canResize
      ? props.columns
      : `minmax(0, ${props.width ? props.width : '200'}px) 1fr`};
  grid-template-areas: ${props =>
    props.areasString ? props.areasString : null};
  width: 100%;
  height: 100%;
  background-color: ${props => (props.bgColor ? props.bgColor : 'transparent')};

  ${({ lazyload, chunksFromTop }) => `
    .lazyload {
      display: ${lazyload ? 'none' : ''};

      &:last-child {
        display: ${chunksFromTop ? 'block !Important' : ''};
      }
    }

    .active {
      display: ${lazyload ? 'block' : ''};
    }
  `}

  .wrapperClass {
    > div:first-of-type {
      right: -12px !important;
      z-index: 1;
    }
  }

  .infoCardContent {
    display: ${props => (props.width < 110 ? 'none' : 'block')};
    width: ${props => props.width < 180 && '100% !important'};
    margin-top: ${props => (props.width < 180 ? rem(5) : 0)};

    > div {
      text-align: ${props => (props.width < 180 ? 'center' : 'left')};
    }
  }

  .widgetOverview {
    .infoCard {
      justify-content: ${props => (props.width < 180 ? 'center' : 'inherit')};
      flex-direction: ${props => (props.width < 180 ? 'column' : 'unset')};

      > div:first-of-type {
        margin-right: ${props => (props.width < 180 ? '0' : rem(10))};
        align-self: ${props => (props.width < 180 ? 'center' : 'flex-start')};
      }
    }
  }

  .overviewButtons {
    flex-wrap: ${props => props.width < 180 && 'wrap'};
    padding: ${props => props.width < 180 && '0 20%'};
    padding: ${props => props.width < 150 && '0 10%'};
    padding: ${props => props.width < 110 && `0 0 0 ${rem(8)}`};

    button,
    > div:first-of-type {
      width: ${props => props.width < 110 && '2.45rem'};
      height: ${props => props.width < 110 && '2.45rem'};
      min-height: ${props => props.width < 110 && '2.45rem'};
    }

    i {
      padding: ${props => props.width < 110 && '0'};
    }
  }

  .widgetOverview {
    grid-template-rows: ${props => props.width < 180 && `1fr ${rem(110)}`};
    grid-template-rows: ${props => props.width < 110 && `1fr ${rem(90)}`};

    .infoCardIndicator {
      display: ${props => (props.width < 230 ? 'none' : 'block')};
    }
  }
`;

const StyledChild = styled.div`
  grid-area: ${props => (props.childarea ? props.childarea : null)};
  max-height: ${props => (props.childarea ? '100%' : null)};
  height: ${props => (props.childarea ? '100%' : null)};
  padding-top: ${props => (props.topPadding ? props.topPadding : 0)};
  padding-right: ${props => (props.rightPadding ? props.rightPadding : 0)};
  padding-bottom: ${props => (props.bottomPadding ? props.bottomPadding : 0)};
  padding-left: ${props => (props.leftPadding ? props.leftPadding : 0)};
  border-top-width: ${props => (props.topWidth ? props.topWidth : 0)};
  border-right-width: ${props => (props.rightWidth ? props.rightWidth : 0)};
  border-bottom-width: ${props => (props.bottomWidth ? props.bottomWidth : 0)};
  border-left-width: ${props => (props.leftWidth ? props.leftWidth : 0)};
  border-style: solid;
  position: relative;

  // &:after {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   background: ${props => (props.bgColor ? props.bgColor : null)};
  //   background-image: url(${props =>
    props.bgImage ? props.bgImage : 'none'});
  //   background-repeat: repeat;
  //   background-size: contain;
  //   z-index: -1;
  // }

  .track-vertical {
    position: absolute;
    width: ${rem(6)};
    transition: opacity 200ms ease 0s;
    opacity: 0;
    right: ${rem(5)};
    bottom: ${rem(24)};
    top: ${rem(24)};
    border-radius: ${rem(3)};
    visibility: hidden;
  }
`;

const StyledDragBar = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: ${rem(60)};
  border-radius: 50%;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  transform: rotate(90deg);
  left: auto;
`;

/**
 * Return a styled Grid component
 * @param {object} children
 * @param {string} customId
 * @param {string} parentId
 * @param {string} rows
 * @param {string} columns
 * @param {string} bgColor
 * @param {string} bgImage
 * @param {string} padding
 * @param {string} border
 * @param {string} className
 * @param {bool} scrollbar
 * @param {bool} scrollBottom
 * @param {string} owner
 * @param {bool} lazyload
 * @param {bool} canResize
 * @param {string} widgetId
 * @param {string} dashboardId
 * @param {number} ResizeMin
 * @param {number} ResizeMax
 */
const Grid = props => {
  const {
    children,
    customId,
    parentId,
    rows,
    columns,
    bgColor,
    bgImage,
    padding,
    border,
    scrollbar,
    className,
    scrollBottom,
    scrollTop,
    onScrollUpdate,
    scrollPosition,
    chunksFromTop,
    owner,
    canResize,
    lazyload,
    widgetId,
    dashboardId,
    resizeMin,
    resizeMax,
  } = props;
  const scrollRef = useRef();
  const resizeRef = useRef();
  const uuid = firebase.auth().currentUser
    ? firebase.auth().currentUser.uid
    : null;

  //States
  const [width, setWidth] = useState(resizeMax ? resizeMax : 220);
  const [height, setHeight] = useState();

  useEffect(() => {
    if (canResize && uuid) {
      userDashboardListner
        .child(uuid)
        .child(dashboardId)
        .child('widgets')
        .child(widgetId)
        .once('value', function(snapshot) {
          if (snapshot.val()) setWidth(snapshot.val().overviewWidth);
        });
    }

    handleId();
    // if (scrollRef.current) scrollRef.current.scrollTop(0);
    if (scrollBottom) {
      setTimeout(function() {
        if (scrollRef.current) scrollRef.current.scrollToBottom();
      }, 100);
    }
  }, []);

  // SCROLL UP
  if (scrollTop && !scrollPosition) {
    setTimeout(function() {
      if (scrollRef.current) scrollRef.current.scrollToTop();
    }, 0);
  }

  // SCROLL DOWN
  if (scrollBottom && !scrollPosition) {
    setTimeout(function() {
      if (scrollRef.current) scrollRef.current.scrollToBottom();
    }, 300);
  }

  // SCROLL BASED ON HEIGHT
  if (scrollPosition && !scrollBottom && chunksFromTop) {
    if (
      scrollRef.current &&
      document.getElementById('chunk-' + scrollPosition)
    ) {
      const height = document.getElementById('chunk-' + scrollPosition)
        .offsetHeight;
      scrollRef.current.scrollTop(height);
    }
  }

  /** TrackV */
  const trackV = ({ style, ...props }) => (
    <div className='track track-vertical' style={style} {...props} />
  );

  /** trackH */
  const trackH = ({ style, ...props }) => (
    <div className='track track-horizontal' style={style} {...props} />
  );

  /** Put the id on the scroll element */
  const handleId = () => {
    if (scrollRef && scrollRef.current) {
      const element = scrollRef.current.container;
      element.firstElementChild.setAttribute('id', customId);
    }
  };

  /** On Scroll Handler */
  const onScrollHandler = values => {
    if (props.callback) {
      props.callback(values);
    }
    return values;
  };

  /** Update the widget overview width */
  const updateOverviewWidth = newWidth => {
    userDashboardListner
      .child(uuid)
      .child(dashboardId)
      .child('widgets')
      .child(widgetId)
      .update({ overviewWidth: newWidth });
  };

  const handle = (
    <StyledDragBar>
      <StyledIcon icon='drag_handle' color='#000' size={24} />
    </StyledDragBar>
  );

  // /** overviewCallback */
  // const setContentCallback = useCallback(() => {
  //   return onScrollHandler();
  // }, []);

  /**
   * Checking if the grid is vertical or horizontal
   */
  const componentCheck = () => {
    let areasString = '';
    const numberOfChildren = React.Children.count(children);
    const nodes = React.Children.map(children, (child, i) => {
      if (!React.isValidElement(child)) return;

      const renderItem = (
        <StyledChild
          callback={onScrollHandler}
          bgColor={bgColor}
          bgImage={bgImage}
          topPadding={padding && padding[i].top}
          rightPadding={padding && padding[i].right}
          bottomPadding={padding && padding[i].bottom}
          leftPadding={padding && padding[i].left}
          childarea={`col-${[i]}`}
          topWidth={border && border[i].top}
          rightWidth={border && border[i].right}
          bottomWidth={border && border[i].bottom}
          leftWidth={border && border[i].left}
          scrollBottom={scrollBottom}
          scrollTop={scrollTop}
        >
          {scrollbar && (
            <Scrollbars
              ref={scrollRef}
              hideTracksWhenNotNeeded
              universal
              autoHide
              onScrollFrame={onScrollHandler}
              onUpdate={onScrollUpdate}
              className='scrollbar-tracks'
              renderTrackVertical={trackV}
              renderTrackHorizontal={trackH}
            >
              {child}
            </Scrollbars>
          )}

          {!scrollbar && child}
        </StyledChild>
      );

      const element =
        canResize && numberOfChildren > 1 && i === 0 ? (
          <Resizable
            ref={resizeRef}
            maxWidth={resizeMax ? resizeMax : 300}
            minWidth={resizeMin ? resizeMin : 80}
            enable={{
              top: false,
              right: true,
              bottom: false,
              left: false,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false,
            }}
            onResize={(e, direction, ref, d) => {
              if (resizeRef && resizeRef.current && uuid) {
                setWidth(resizeRef.current.size.width);
                updateOverviewWidth(resizeRef.current.size.width);
              }
            }}
            handleWrapperClass='wrapperClass'
            handleComponent={{ right: handle }}
            handleStyles={{
              right: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}
            className='editable'
          >
            {renderItem}
          </Resizable>
        ) : (
          <React.Fragment>{renderItem}</React.Fragment>
        );

      if (columns) {
        areasString = `${areasString} col-${[i]}`;
      } else {
        areasString = `${areasString}'col-${[i]}' `;
      }

      return element;
    });

    if (columns) {
      areasString = `'${areasString.trim()}'`;
    }

    return (
      nodes && (
        <StyledGrid
          id={parentId}
          width={width}
          canResize={canResize}
          className={className}
          rows={rows}
          columns={columns}
          areasString={areasString}
          lazyload={lazyload}
          chunksFromTop={chunksFromTop}
          bgColor={bgColor}
        >
          {nodes}
        </StyledGrid>
      )
    );
  };
  /**
   * Returns a styled Layout component with child components
   */
  return componentCheck();
};

Grid.propTypes = {
  /** Parent Id */
  parentId: PropTypes.string,
  /** Custom Id */
  customId: PropTypes.string,
  /** Can have all kind of child nodes */
  children: PropTypes.node,
  /** Defines the rows of the grid with a space-separated list of values. */
  rows: PropTypes.string,
  /** Defines the columns of the grid with a space-separated list of values. */
  columns: PropTypes.string,
  /** Background color */
  bgColor: PropTypes.string,
  /** Background Image */
  bgImage: PropTypes.string,
  /** Defines padding around the component */
  padding: PropTypes.arrayOf(
    PropTypes.shape({
      top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  /** Sets border around the component */
  border: PropTypes.arrayOf(
    PropTypes.shape({
      top: PropTypes.string,
      right: PropTypes.string,
      bottom: PropTypes.string,
      left: PropTypes.string,
    })
  ),
  /** Set scrollbars */
  scrollbar: PropTypes.bool,
  /** Add a custom classname(s) */
  className: PropTypes.string,
  /** Orientation of the scrollbar is to scroll down every time it updates */
  scrollBottom: PropTypes.bool,
  /** Scroll to top from default */
  scrollTop: PropTypes.bool,
  /** On scroll update control */
  onScrollUpdate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /** Scroll to position */
  scrollPosition: PropTypes.number,
  /** Owner check */
  owner: PropTypes.string,
  /** Define if the grid should act like an lazyload */
  lazyload: PropTypes.bool,
  /** Define if you can resize the grid */
  canResize: PropTypes.bool,
  /** If the grid is located inside of a widget this provides the ID */
  widgetId: PropTypes.string,
  /** Current dashboardId */
  dashboardId: PropTypes.string,
  /** Define a max width */
  resizeMax: PropTypes.number,
  /** Define a min width */
  resizeMin: PropTypes.number,
};

Grid.defaultProps = {
  children: null,
  parentId: null,
  customId: null,
  rows: null,
  columns: null,
  bgColor: null,
  bgImage: null,
  padding: null,
  border: null,
  scrollbar: true,
  className: null,
  scrollBottom: false,
  scrollTop: false,
  onScrollUpdate: null,
  scrollPosition: null,
  owner: null,
  lazyload: false,
  canResize: false,
  widgetId: null,
  dashboardId: null,
  resizeMax: null,
  resizeMin: null,
};

/** @component */
export default Grid;
