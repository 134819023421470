import PropTypes from 'prop-types';
import theme from '../../theme';

export const propTypes = {
  /** Toggles modal open and close state */
  isOpen: PropTypes.bool,
  /** Callback fired when the modal is requested to be closed */
  closeModal: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  /** Sets the background-color of the modal */
  backgroundColor: PropTypes.string,
  /** Sets the height of the modal */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Sets the width of the modal */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Sets the padding of the modal */
  padding: PropTypes.number,
  /** Nested modal elements */
  children: PropTypes.node,
  /** An array of strings which includs the extra prop keys */
  extraProps: PropTypes.arrayOf(PropTypes.string),
  /** Content of the modal */
  content: PropTypes.string,
  /** Current User */
  currentUser: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /** Id of the modal */
  id: PropTypes.string,
};

export const defaultProps = {
  extraProps: [],
  backgroundColor: `${theme.color.light}`,
  closeModal: () => {},
  currentUser: null,

  height: null,
  width: null,
  padding: 24,
  children: null,
  id: null,
};
