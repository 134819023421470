import { mix } from 'polished';
import theme from '../../theme';

/**
 * Styles for colors in web and native code
 */
const styles = {
  error: {
    borderColor: theme.color.danger,
    accentColor: theme.color.danger,
    boxShadow: mix(0.25, theme.color.danger, '#fff'),
  },
  default: {
    borderColor: theme.color.dark[20],
    accentColor: theme.color.secondary[100],
    boxShadow: theme.color.secondary[10],
  },
};

export default {
  borderColor: props =>
    props.error ? styles.error.borderColor : styles.default.borderColor,

  accentColor: props =>
    props.error ? styles.error.accentColor : styles.default.accentColor,

  boxShadow: props =>
    props.error ? styles.error.boxShadow : styles.default.boxShadow,

  /**
   * Function to return a set of styles
   * Depending on boolean parameter
   * @param {bool} error
   */
  getStyle(error) {
    return styles[error ? 'error' : 'default'];
  },
};
