/* eslint-disable react/display-name */
/* eslint-disable react/react-in-jsx-scope */

/** Get the email subject based on a list */
const getImage = (type, typeId) => {
  let dataObject = '';
  dataObject = encodeURI(
    document
      .getElementsByClassName('whiteboard-' + typeId)[0]
      .children[1].toDataURL('image/jpg')
  );
  return dataObject;
};

/** Default return function */
const basic = () => {};

export { getImage };

export default basic;
