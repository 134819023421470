import { transparentize } from 'polished';
import defaultImage from './assets/drag-image.svg';
import { rem } from '../../theme';

const baseStyles = {};

export const webStyles = {
  ...baseStyles,
  styledSliderHoverThumb: theme => `
    box-shadow: 0 0.1rem 0.1rem ${theme.color.dark[10]}, 0 0 0 ${rem(
    6
  )} ${transparentize(0.8, theme.color.primary[100])};
  `,
  styledSliderClickThumb: theme => `
    box-shadow: 0 0.1rem 0.1rem ${theme.color.dark[10]}, 0 0 0 ${rem(
    10
  )} ${transparentize(0.8, theme.color.primary[100])};
    cursor: grabbing;
  `,
  styledSliderVertical: () => `
    appearance: slider-vertical;
    width: 8px;
    height: 175px;
    padding: 0 5px;
  `,
  styledSliderThumb: theme => `
    transition-duration: 0.3s;
    border-radius: ${rem(12)};
    appearance: none;
    width: ${rem(24)};
    height: ${rem(24)};
    background: url('${defaultImage}') ${theme.color.light[100]};
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center; 
    box-shadow: 0 0.1rem 0.1rem ${theme.color.dark[10]};
  `,
  StyledSliderBase: () => `
    appearance: none;
    cursor: pointer;
    width: 100%;
    height: ${rem(10)};
    outline: none;
    border-radius: ${rem(12)};
    &:hover {
        opacity: 1;
    }
  `,
};

export const nativeStyles = {
  ...baseStyles,
};
