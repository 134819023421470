import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import Room from "janus-room";
import { useTranslation } from "react-i18next";
import { mix } from "polished";
import Grid from "../Grid";
import Group from "../Group";
import EmptyState from "../EmptyState";
import Button from "../Button";
import Avatar from "../Avatar";
import Icon from "../Icon";
import theme, { rem } from "../../theme";
import firebase, {
  dashboardListner,
  userListner,
  callListner,
} from "../../firebase";
import { joinRoom } from "./joinVideoChat";

const StyledIcon = styled(Icon)`
  position: absolute;
  left: calc(50% - 1.3rem);
  top: 1rem;
  z-index: 1;
`;

const StyledIconFullscreen = styled(Button)`
  position: absolute;
  left: auto;
  right: 1rem;
  top: 1rem;
  z-index: 1;
  opacity: 0;
  transition: 0s;

  .fullscreen && {
    opacity: 1;
  }
`;

const StyledWrapper = styled.div`
  background: ${theme.color.dark[60]};
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: ${rem(20)};
  height: calc(100% - ${rem(20)});
  border-radius: ${rem(10)};
  margin: ${rem(15)} ${rem(15)} ${rem(20)} ${rem(15)};
`;

const StyledCall = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: ${rem(10)};
  padding: 0.1rem;
`;

const StyledGridWrapper = styled(Grid)`
  height: auto;
`;

const StyledUsers = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const StyledGrid = styled(Grid)`
  height: 80px;
`;

const StyledVideo = styled.video`
  object-fit: cover;
  width: 400px;
  height: 300px;
  background-color: salmon;
  position: relative;
`;

const StyledAvatar = styled(Avatar)`
  margin-right: ${rem(8)};

  &:last-child {
    margin-right: 0;
  }
`;

const StyledVideoAvatar = styled(Avatar)`
  position: absolute;
  border-radius: 50%;
`;

const StyledRemoteVideo = styled.video`
  object-fit: cover;
  width: 400px;
  height: 300px;
  background-color: lightskyblue;
  position: relative;
`;

const StyledButton = styled(Button)`
  margin-left: 50%;
  transform: translate(-50%);
  width: 100px;
`;

const StyledVideoWrapper = styled.div`
  ${({ number }) => `
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: ${rem(10)};
    overflow: hidden;
    flex-wrap: wrap;

    .speedWifiIndi {
      position: absolute;
      transition: 0.2s;
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }

    &.fullscreen {
      .videoremotewrapper,
      .videowrapper {

        &:not(.fullView) {
          position: absolute;
          width: 4rem;
          height: 4rem;
          z-index: 2;
          right: -0.6rem;
          border-radius: 50%;
          position: fixed;
          top: 0;
          padding: 0.4rem;
          opacity: 0;
        }

        &:nth-child(${number}) {
          &:not(.fullView) {
            top:  ${number > 2 ? `calc((4rem * (${number} - 2)))` : "0"};
          }
        }

        &.fullView {
          width: 100%;
          height: 100%;
          object-fit: contain;
          z-index: 1;
        }
      }
    }

    .avatarWrapper {
      position: absolute;
      left: 0.5rem;
      right: 0.5rem;
      bottom: 0.5rem;
      top: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .muteWrapper {
      i {
        transition: 0.25s;
        opacity: 0;
      }
    }

    .videoremotewrapper,
    .videowrapper {
      position: relative;
      flex-grow: ${number > 2 ? "0" : "1"};

      width: ${
        number > 2 ? `calc(100%/${Math.ceil(number / 2)})` : `calc(50% - 2rem)`
      };

      height: ${number > 2 ? `50%` : `calc(100%)`};

      padding: 0.5rem;
    }

    video {
      transition: 0.35s;
      cursor: pointer;
      object-fit: cover;
      box-sizing: border-box;
      border-radius: ${rem(0.5)};
      width: 100%;
      height: 100%;
    }
  `}
`;

const StyledGroup = styled(Group)`
  position: absolute;
  bottom: ${rem(10)};
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const StyledOptionButton = styled(Button)`
  margin-right: ${rem(8)};

  &:last-child {
    margin-right: 0;
  }
`;

const StyledAnimationWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: ${rem(12)};
  top: ${rem(12)};
  z-index: 1;
  width: 1.5rem;
  height: 1.5rem;
`;

/** DrawContent */
const CallContent = props => {
  // eslint-disable-next-line react/prop-types
  const { currentId, activeDashboardId, widgetId, activeVideoLocation } = props;

  const [members, setMembers] = useState({});
  const [roomIdTimeStamp, setRoomIdTimeStamp] = useState();
  const [joinedRoom, setJoinedRoom] = useState(false);
  const [roomState, setRoomState] = useState();
  const [number, setNumber] = useState(1);
  const [toggleAudio, setToggleAudio] = useState("");
  const [toggleVideo, setToggleVideo] = useState("");
  const [AudioIcon, setAudioIcon] = useState(true);
  const [videoIcon, setVideoIcon] = useState(true);
  const [toggleScreenShare, setToggleScreenShare] = useState(false);

  //Refs
  const wrapperRef = useRef();

  const database = firebase.database();
  const uuid = firebase.auth().currentUser.uid;
  const memberRef = dashboardListner.child(activeDashboardId).child("members");
  const dashboardRef = dashboardListner.child(activeDashboardId);

  //Translation
  const [t] = useTranslation("call");

  useEffect(() => {
    if (currentId) setListners();
  }, []);

  useEffect(() => {
    if (toggleAudio.length !== 0) {
      roomState.toggleMuteAudio().then(muted => {
        //var el = document.getElementById('local-toggle-mute-audio');
        if (muted) {
          setAudioIcon(false);
          callListner
            .child(currentId)
            .child("muted")
            .child(uuid)
            .set(true);
        } else {
          setAudioIcon(true);
          callListner
            .child(currentId)
            .child("muted")
            .child(uuid)
            .remove();
        }
      });
    }
  }, [toggleAudio]);

  useEffect(() => {
    if (toggleVideo.length !== 0) {
      roomState.toggleMuteVideo().then(muted => {
        //var el = document.getElementById('local-toggle-mute-audio');
        if (muted) {
          setVideoIcon(false);
          callListner
            .child(currentId)
            .child("blinded")
            .child(uuid)
            .set(true);
        } else {
          setVideoIcon(true);
          callListner
            .child(currentId)
            .child("blinded")
            .child(uuid)
            .remove();
        }
      });
    }
  }, [toggleVideo]);

  useEffect(() => {
    if (joinedRoom) {
      /** error handler */
      var onError = function(err) {
        if (err && err.indexOf("The room is unavailable") > -1) {
          // console.log(
          //   'Room ' + roomIdTimeStamp + " is unavailable. Let's create one."
          // );
          room
            .createRoom({
              room: roomIdTimeStamp,
              publishers: 6,
            })
            .then(() => {
              setTimeout(function() {
                room.register({
                  username: uuid,
                  room: roomIdTimeStamp,
                });
              }, 1000);
            })
            .catch(err => {
              // console.log(err);
            });
        } else {
          //console.log(err);
        }
      };

      /** emit warning */
      var onWarning = function(msg) {
        // console.log(msg);
      };

      /** handle volume change */
      var onVolumeMeterUpdate = function(streamIndex, volume) {};

      /** localfeed */
      var onLocalJoin = function() {
        if (!document.getElementById("myVideo")) {
          let name = "";
          let avatar = "";
          let color = "";

          userListner
            .child(uuid)
            .child("public")
            .once("value", snapUser => {
              if (snapUser.exists()) {
                name = snapUser.val().name;
                avatar = snapUser.val().avatar && snapUser.val().avatar.cropped;
                color = snapUser.val().color;
              }
            });

          var videoWrapper = document.createElement("div");
          videoWrapper.className = "videowrapper";
          videoWrapper.setAttribute("data-id", uuid);

          const emptyWrapper = document.createElement("div");
          emptyWrapper.setAttribute("id", "avatar" + uuid);
          emptyWrapper.className = "avatarWrapper";

          const emptyWrapperMute = document.createElement("div");
          emptyWrapperMute.setAttribute("id", "mute" + uuid);
          emptyWrapperMute.className = "muteWrapper";

          const emptyWrapperFullScreen = document.createElement("div");
          emptyWrapperFullScreen.setAttribute("id", "fullscreen" + uuid);
          emptyWrapperFullScreen.className = "fullscreenWrapper";

          const emptyWrapperSpeed = document.createElement("div");
          emptyWrapperSpeed.setAttribute("id", "speedindicator" + uuid);
          emptyWrapperSpeed.className = "speedWrapper";

          if (color && color !== "")
            emptyWrapper.style.background = mix("0.6", color, "#fff");

          videoWrapper.appendChild(emptyWrapper);
          videoWrapper.appendChild(emptyWrapperMute);
          videoWrapper.appendChild(emptyWrapperFullScreen);
          videoWrapper.appendChild(emptyWrapperSpeed);
          var myVideo = document.createElement("video");
          myVideo.className = "myVideo";
          myVideo.autoplay = true;
          myVideo.muted = true;
          myVideo.setAttribute("data-id", uuid);
          myVideo.setAttribute("id", "myVideo");
          myVideo.onclick = function(e) {
            handleFullWidth(e, uuid);
          };
          let parentDocument = document.getElementById("parentVideoWrapper");

          videoWrapper.appendChild(myVideo);
          parentDocument.appendChild(videoWrapper);

          ReactDOM.render(
            <StyledVideoAvatar
              id="myVideoAvatar"
              source={avatar}
              title={name}
              color={color}
              size="big"
            />,
            document.getElementById("avatar" + uuid)
          );

          ReactDOM.render(
            <StyledIcon
              id="myVideoMute"
              icon="volume_off"
              background={theme.color.dark[40]}
              color="#fff"
              size={32}
              shape="round"
            />,
            document.getElementById("mute" + uuid)
          );

          ReactDOM.render(
            <StyledIconFullscreen
              id="myVideoFullscreen"
              variant="icon"
              icon={{
                icon: "fullscreen_exit",
                size: 32,
                color: "#fff",
              }}
              bgColor={theme.color.dark[40]}
              onClick={() => document.getElementById("myVideo").click()}
            />,
            document.getElementById("fullscreen" + uuid)
          );

          /**
           * Range
           * Start: 60
           * End  : 120
           */
          ReactDOM.render(
            <StyledAnimationWrapper>
              <Icon
                color={theme.color.danger}
                id="speedWIFIoff"
                className="speedWifiIndi"
                icon="signal_wifi_off"
                size={20}
              />
              <Icon
                color={theme.color.dark[80]}
                id="speedWIFI0"
                className="speedWifiIndi active"
                icon="signal_wifi_0_bar"
                size={20}
              />
              <Icon
                color={theme.color.danger}
                id="speedWIFI1"
                className="speedWifiIndi"
                icon="signal_wifi_1_bar"
                size={20}
              />
              <Icon
                color={theme.color.warning}
                id="speedWIFI2"
                className="speedWifiIndi"
                icon="signal_wifi_2_bar"
                size={20}
              />
              <Icon
                color={theme.color.success}
                id="speedWIFI3"
                className="speedWifiIndi"
                icon="signal_wifi_3_bar"
                size={20}
              />
              <Icon
                color={theme.color.success}
                id="speedWIFI4"
                className="speedWifiIndi"
                icon="signal_wifi_4_bar"
                size={20}
              />
            </StyledAnimationWrapper>,
            document.getElementById("speedindicator" + uuid)
          );
        }
        let target = document.getElementById("myVideo");
        room.attachStream(target, 0).then(() => {
          room.isShareScreenStream(0).then(response => {
            if (response) {
              document.getElementById("myVideo").style.transform = "inherit";
            } else {
              document.getElementById("myVideo").style.transform =
                "rotateY(180deg)";
            }
          });
        });

        handleNumberAndUser();
      };

      /** remoteFeed */
      var onRemoteJoin = function(index, remoteUsername, feedId) {
        if (
          remoteUsername === uuid ||
          !document.getElementById("parentVideoWrapper")
        )
          return;

        if (!document.getElementById("remoteVideo" + remoteUsername)) {
          let name = "";
          let avatar = "";
          let color = "";

          userListner
            .child(remoteUsername)
            .child("public")
            .once("value", snapUser => {
              if (snapUser.exists()) {
                name = snapUser.val().name;
                avatar = snapUser.val().avatar.cropped;
                color = snapUser.val().color;
              }
            });

          var videoRemoteWrapper = document.createElement("div");
          videoRemoteWrapper.className = "videoremotewrapper";
          videoRemoteWrapper.setAttribute(
            "id",
            "videoremotewrapper" + remoteUsername
          );
          videoRemoteWrapper.setAttribute("data-id", remoteUsername);

          var newRemoteVideo = document.createElement("video");
          newRemoteVideo.className = "remoteVideo";
          newRemoteVideo.autoplay = true;
          newRemoteVideo.setAttribute("data-id", remoteUsername);
          newRemoteVideo.setAttribute("id", "remoteVideo" + remoteUsername);
          newRemoteVideo.onclick = function(e) {
            handleFullWidth(e, remoteUsername);
          };
          newRemoteVideo.title = name;
          // var audioLevel = new AudioContext();
          // var source = audioLevel.createMediaElementSource(newRemoteVideo);
          // var analyser = audioLevel.createAnalyser();
          // source.connect(analyser);
          // analyser.fftSize = 256;
          // var bufferLength = analyser.frequencyBinCount;
          // var dataArray = new Uint8Array(bufferLength);
          // analyser.getByteTimeDomainData(dataArray);
          // console.log('buffer', dataArray);
          const emptyWrapper = document.createElement("div");
          emptyWrapper.setAttribute("id", "avatar" + remoteUsername);
          emptyWrapper.className = "avatarWrapper";

          const emptyWrapperMute = document.createElement("div");
          emptyWrapperMute.setAttribute("id", "mute" + remoteUsername);
          emptyWrapperMute.className = "muteWrapper";

          const emptyWrapperFullscreen = document.createElement("div");
          emptyWrapperFullscreen.setAttribute(
            "id",
            "fullscreen" + remoteUsername
          );
          emptyWrapperFullscreen.className = "fullscreenWrapper";

          if (color && color !== "")
            emptyWrapper.style.background = mix("0.6", color, "#fff");

          videoRemoteWrapper.appendChild(emptyWrapper);
          videoRemoteWrapper.appendChild(emptyWrapperMute);
          videoRemoteWrapper.appendChild(emptyWrapperFullscreen);
          videoRemoteWrapper.appendChild(newRemoteVideo);
          document
            .getElementById("parentVideoWrapper")
            .appendChild(videoRemoteWrapper);

          ReactDOM.render(
            <StyledVideoAvatar
              id={"videoAvatar" + remoteUsername}
              source={avatar}
              title={name}
              color={color}
              size="big"
            />,
            document.getElementById("avatar" + remoteUsername)
          );

          ReactDOM.render(
            <StyledIcon
              id={"videoMute" + remoteUsername}
              icon="volume_off"
              background={theme.color.dark[40]}
              color="#fff"
              size={32}
              shape="round"
            />,
            document.getElementById("mute" + remoteUsername)
          );

          ReactDOM.render(
            <StyledIconFullscreen
              id={"videoFullScreen" + remoteUsername}
              variant="icon"
              icon={{
                icon: "fullscreen_exit",
                size: 32,
                color: "#fff",
              }}
              onClick={() =>
                document.getElementById("remoteVideo" + remoteUsername).click()
              }
              bgColor={theme.color.dark[40]}
            />,
            document.getElementById("fullscreen" + remoteUsername)
          );

          let target = document.getElementById("remoteVideo" + remoteUsername);
          room.attachStream(target, index).then(() => {
            callListner
              .child(currentId)
              .child("muted")
              .child(remoteUsername)
              .once("value", snap => {
                if (snap.val()) {
                  if (document.getElementById("videoMute" + snap.key))
                    document.getElementById(
                      "videoMute" + snap.key
                    ).style.opacity = "1";
                }
              });

            callListner
              .child(currentId)
              .child("blinded")
              .child(remoteUsername)
              .once("value", snap => {
                if (snap.val()) {
                  if (document.getElementById("remoteVideo" + snap.key))
                    document.getElementById(
                      "remoteVideo" + snap.key
                    ).style.opacity = 0;
                }
              });
          });

          handleNumberAndUser();
        }
      };

      /** remove remote video on leave */
      var onRemoteUnjoin = function(index, remoteUsername) {
        if (document.getElementById("remoteVideo" + remoteUsername))
          document
            .getElementById("remoteVideo" + remoteUsername)
            .parentElement.remove();

        handleNumberAndUser();
      };

      var onRecordedPlay = function() {};

      var onMessage = function(data) {
        //console.log('message from da hub', data);
      };
      var room;
      var options = {
        server: "wss://pointer.studentsplus.nl/janus-ws/",
        room: roomIdTimeStamp,
        token: "a1b2c3d4",
        publishers: 6,
        extensionId: "bkkjmbohcfkfemepmepailpamnppmjkk",
        publishOwnFeed: true,
        iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
        useRecordPlugin: true,
        volumeMeterSkip: 10,
        onLocalJoin: onLocalJoin,
        onRemoteJoin: onRemoteJoin,
        onRemoteUnjoin: onRemoteUnjoin,
        onRecordedPlay: onRecordedPlay,
        onMessage: onMessage,
        onError: onError,
        onWarning: onWarning,
        onVolumeMeterUpdate: onVolumeMeterUpdate,
      };

      room = new Room(options);
      room
        .init()
        .then(function() {
          setTimeout(function() {
            room.register({
              username: uuid,
              room: roomIdTimeStamp,
            });
            setRoomState(room);
          }, 1000);
        })
        .catch(err => {
          //console.log('err', err);
        });
    }
  }, [joinedRoom]);

  /**
   * Handle the number of child elements
   */
  const handleNumberAndUser = () => {
    //Objectt with member IDs
    let memberIds = {};
    //Element
    const element = document.getElementById("parentVideoWrapper");
    if (!element) return;
    //Get the parent wrapper
    const numberChildElements = element.childElementCount;
    //Child nodes
    var children = element.childNodes;
    //Put the child notes in an array
    var array = Array.from(children);
    //Loop through notes
    if (array.length > 0) {
      array.map(child => {
        //Get the user id by targetting the dataset
        const userId = child.dataset.id;
        //Get the public user data
        if (userId) {
          userListner
            .child(userId)
            .child("public")
            .once("value", snapUser => {
              if (snapUser.exists()) {
                memberIds[userId] = {};
                memberIds[userId]["indicator"] = snapUser.val().indicator
                  ? snapUser.val().indicator
                  : "";
                memberIds[userId]["name"] = snapUser.val().name
                  ? snapUser.val().name
                  : "";
                memberIds[userId]["avatar"] = snapUser.val()["avatar"]
                  ? snapUser.val()["avatar"].cropped
                  : "";
                memberIds[userId]["color"] = snapUser.val().color
                  ? snapUser.val().color
                  : "blue";
              }
            });
        }
      });

      if (currentId) {
        callListner.child(currentId).update({
          inCall: memberIds,
        });
      }
    }
    //Set the number state
    setNumber(numberChildElements);
  };

  /** Set listners */
  const setListners = () => {
    callListner
      .child(currentId)
      .child("inCall")
      .onDisconnect()
      .update({
        [uuid]: {},
      });

    callListner
      .child(currentId)
      .child("muted")
      .onDisconnect()
      .update({
        [uuid]: {},
      });

    callListner
      .child(currentId)
      .child("blinded")
      .onDisconnect()
      .update({
        [uuid]: {},
      });

    callListner
      .child(currentId)
      .child("inCall")
      .on("value", snap => {
        if (snap.val()) {
          setMembers(snap.val());
        } else {
          setMembers({});
        }
      });

    callListner
      .child(currentId)
      .child("muted")
      .on("child_added", snap => {
        if (snap.val()) {
          if (snap.key === uuid) {
            if (document.getElementById("myVideoMute"))
              document.getElementById("myVideoMute").style.opacity = "1";
          } else {
            if (document.getElementById("videoMute" + snap.key))
              document.getElementById("videoMute" + snap.key).style.opacity =
                "1";
          }
        }
      });

    callListner
      .child(currentId)
      .child("muted")
      .on("child_removed", snap => {
        if (snap.val()) {
          if (snap.key === uuid) {
            if (document.getElementById("myVideoMute"))
              document.getElementById("myVideoMute").style.opacity = "0";
          } else {
            if (document.getElementById("videoMute" + snap.key))
              document.getElementById("videoMute" + snap.key).style.opacity =
                "0";
          }
        }
      });

    callListner
      .child(currentId)
      .child("blinded")
      .on("child_added", snap => {
        if (snap.val()) {
          if (snap.key === uuid) {
            if (document.getElementById("myVideo"))
              document.getElementById("myVideo").style.opacity = 0;
          } else {
            if (document.getElementById("remoteVideo" + snap.key))
              document.getElementById(
                "remoteVideo" + snap.key
              ).style.opacity = 0;
          }
        }
      });

    callListner
      .child(currentId)
      .child("blinded")
      .on("child_removed", snap => {
        if (snap.val()) {
          if (snap.key === uuid) {
            if (document.getElementById("myVideo"))
              document.getElementById("myVideo").style.opacity = 1;
          } else {
            if (document.getElementById("remoteVideo" + snap.key))
              document.getElementById(
                "remoteVideo" + snap.key
              ).style.opacity = 1;
          }
        }
      });

    dashboardRef.child("time").once("value", snap => {
      if (snap.val()) {
        setRoomIdTimeStamp(snap.val() + 1);
      }
    });

    //Get all dashboard Members
    database
      .ref()
      .child("chatRoom")
      .child(activeDashboardId)
      .child("attendees")
      .on("child_added", snap => {
        if (uuid !== snap.key) {
          userListner
            .child(snap.key)
            .child("public")
            .once("value", snapUser => {
              members[snap.key] = {};
              members[snap.key]["indicator"] = snapUser.val().indicator;
              members[snap.key]["name"] = snapUser.val().name;
              members[snap.key]["avatar"] =
                snapUser.val()["avatar"] && snapUser.val()["avatar"].cropped;
              setMembers(prevState => {
                // Object.assign would also work
                return { ...prevState, ...members };
              });
            });
        }
      });

    memberRef.on("child_removed", snap => {
      delete members[snap.key];
      setMembers(members);
    });
  };
  /** black magic */
  const joinRoomHandler = () => {
    setJoinedRoom(true);
  };

  // <StyledVideo id="myVideo" autoPlay muted playsInline />
  // <StyledRemoteVideo id="remoteVideo" autoPlay playsInline />
  // <StyledButton onClick={() => joinRoomHandler()}>
  //   start video
  // </StyledButton>

  /** hang up call */
  const hangUp = () => {
    roomState.unpublishOwnFeed();

    callListner
      .child(currentId)
      .child("inCall")
      .child(uuid)
      .remove();

    callListner
      .child(currentId)
      .child("muted")
      .child(uuid)
      .remove();

    callListner
      .child(currentId)
      .child("blinded")
      .child(uuid)
      .remove();

    setJoinedRoom(false);
  };

  /** Handle full width */
  const handleFullWidth = (e, userId) => {
    if (!e.target) return;
    const element = e.target.parentElement;
    const parentElement = document.getElementById("parentVideoWrapper");
    //Child nodes
    var children = parentElement.childNodes;
    if (children.length <= 1) return;
    //Put the child notes in an array
    var array = Array.from(children);
    //Loop through notes and remove the fullview class
    if (array.length > 0) {
      array.map(child => {
        if (child.classList.contains("fullView")) {
          child.classList.remove("fullView");
        }
      });
      element.classList.add("fullView");
    }
    parentElement.classList.toggle("fullscreen");
  };

  /** Switch to screen */
  const SwitchToScreenShare = sharing => {
    if (!sharing) {
      setToggleScreenShare(true);
    } else {
      setToggleScreenShare(false);
    }

    if (!sharing) {
      roomState
        .shareScreen()
        .then(() => {
          //setToggleScreenShare(true);
        })
        .catch(err => {
          //console.log('screenshare err', err);
        });
    } else {
      roomState
        .stopShareScreen()
        .then(() => {
          //setToggleScreenShare(false);
        })
        .catch(err => {
          alert(err);
        });
    }
  };

  /** Mute Call */
  const muteCall = () => {
    if (toggleAudio && toggleAudio.length !== 0) {
      setToggleAudio(false);
    } else {
      setToggleAudio(!toggleAudio);
    }
  };

  /** Disable video */
  const disableVideo = () => {
    if (toggleVideo && toggleVideo.length !== 0) {
      setToggleVideo(false);
    } else {
      setToggleVideo(!toggleVideo);
    }
  };

  return (
    <React.Fragment>
      <Grid rows="1fr" scrollbar={false}>
        <StyledWrapper>
          <StyledCall>
            {joinedRoom ? (
              <React.Fragment>
                <StyledVideoWrapper
                  number={number}
                  id="parentVideoWrapper"
                  ref={wrapperRef}
                />
                <StyledGroup className="editable">
                  <StyledOptionButton
                    variant="icon"
                    onClick={() => {
                      hangUp();
                    }}
                    bgColor={theme.color.danger}
                  >
                    <Icon
                      padding={rem(12)}
                      icon="call_end"
                      size={20}
                      color="#fff"
                    />
                  </StyledOptionButton>

                  <StyledOptionButton
                    variant="icon"
                    onClick={() => {
                      muteCall();
                    }}
                    bgColor={theme.color.light[80]}
                  >
                    <Icon
                      padding={rem(12)}
                      icon={AudioIcon ? "mic" : "mic_off"}
                      size={20}
                    />
                  </StyledOptionButton>

                  <StyledOptionButton
                    variant="icon"
                    onClick={() => {
                      disableVideo();
                    }}
                    bgColor={theme.color.light[80]}
                  >
                    <Icon
                      padding={rem(12)}
                      icon={videoIcon ? "videocam" : "videocam_off"}
                      size={20}
                    />
                  </StyledOptionButton>
                  <StyledOptionButton
                    variant="icon"
                    onClick={() => {
                      SwitchToScreenShare(toggleScreenShare);
                    }}
                    bgColor={theme.color.light[80]}
                  >
                    <Icon padding={rem(12)} icon="screen_share" size={20} />
                  </StyledOptionButton>
                </StyledGroup>
              </React.Fragment>
            ) : (
              <StyledGridWrapper
                rows={`${rem(220)} 1fr 1fr`}
                scrollbar={false}
                className="editable"
              >
                <EmptyState
                  widgetId={widgetId}
                  textColor="#fff"
                  animation="join"
                  height={rem(120)}
                  width={rem(180)}
                  hasButton={false}
                  text={t("empty.title")}
                />
                {members && Object.keys(members).length > 0 && (
                  <StyledGrid rows="1fr">
                    <StyledUsers>
                      {Object.entries(members).map(member => {
                        return (
                          <StyledAvatar
                            key={member[0]}
                            id={"callUser-" + member[0]}
                            status={member[1].indicator}
                            size="big"
                            source={member[1].avatar}
                            title={member[1].name}
                            color={member[1].color}
                          />
                        );
                      })}
                    </StyledUsers>
                  </StyledGrid>
                )}
                <StyledButton
                  text={{
                    text: t("button.title"),
                    align: "center",
                    textColor: "#fff",
                  }}
                  onClick={() => joinRoomHandler()}
                  bgColor={theme.color.primary[100]}
                />
              </StyledGridWrapper>
            )}
          </StyledCall>
        </StyledWrapper>
      </Grid>
    </React.Fragment>
  );
};

export default CallContent;
