const baseStyles = {};

export const webStyles = {
  ...baseStyles,
  text: (theme, rem, color) => `
    border-radius: ${rem(4)};
    padding: ${rem(4)} ${rem(8)};
    color: #fff;
    line-height: normal;
    background: ${color ? color : theme.color.primary[100]};
    max-width: ${rem(120)};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    border: 2px solid ${color ? color : theme.color.primary[100]};
    border-radius: ${rem(30)};

    .badgetext {
      font-weight: bold;
    }
  `,

  status: (rem, size, status) => `
    border-radius: 100%;
    border: ${size ? rem(size / 6) : rem(2)} solid #fff;
    min-width: ${size ? rem(size) : rem(16)};
    min-height: ${size ? rem(size) : rem(16)};
    padding: 0;
    background: ${status && status};
  `,

  toggle: rem => `
    border: ${rem(3)} solid #fff;
    min-height: ${rem(16)};
    min-width: ${rem(16)};
  `,

  badge: (theme, rem) => `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    min-height: ${rem(16)};
    min-width: ${rem(16)};
    border-radius: ${rem(8)};
    box-sizing: border-box;
    padding: 0 ${rem(4)};
  `,
};
