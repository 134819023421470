import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import {
  setCurrentList,
  modalContent,
  modalOpen,
  notificationType,
  createdList,
  modalType,
} from '../../redux/actions';
import List from '../List';
import Group from '../Group';
import Button from '../Button';
import Icon from '../Icon';
import Text from '../Text';
import Grid from '../Grid';
import Image from '../Image';
import ListItem from '../ListItem';
import theme, { rem } from '../../theme';
import firebase, {
  userDashboardListner,
  dashboardListner,
} from '../../firebase';
import Animation from '../LottieControl';

const StyledAnimation = styled(Animation)`
  height: 100%;
`;

const StyledGrid = styled(Grid)`
  padding-top: ${rem(16)};
`;

const StyledTooltip = styled(ReactTooltip)`
  border-radius: ${rem(5)} !important;
  padding: ${rem(5)} ${rem(10)} !important;
`;

const StyledList = styled(List)`
  ${({ empty }) => `
    padding: ${empty && '0'};
    margin: ${empty && '0'};
    height: ${empty && '100%'};
    justify-content: ${empty && 'center'};
    margin-top: 0;
  `}
`;

const StyledTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${rem(20)};
  max-width: ${rem(300)};
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
`;

const StyleImage = styled(Image)`
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: help;
`;

const StyledIndicator = styled.div`
  position: absolute;
  top: ${rem(1)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${theme.color.dark[60]};
  width: ${rem(30)};
  height: ${rem(30)};
  z-index: 5;
  left: ${rem(8)};
  right: 0;
  margin-left: auto;
  margin-right: auto;
  transition: 0.2s;
  opacity: 0;
`;

const StyledTextIndicator = styled(Text)`
  font-weight: 600;
  font-size: ${rem(10)};
`;

const StyledToolTipText = styled(Text)`
  margin-bottom: ${rem(15)};
`;

const StyledToolTipAnimation = styled(Animation)`
  margin-bottom: ${rem(15)};
`;

/**
 * Overview component
 */
const Overview = props => {
  const {
    data,
    type,
    pivotName,
    callback,
    activeDashboardId,
    dummyData,
    backgroundColor,
    widgetId,
    tempText,
    id,
  } = props;
  const [dataObject, setDataObject] = useState(data);
  const [currentActive, setCurrentActive] = useState();
  const [removeData] = useState({});
  const [ready, setReady] = useState(false);
  const [totalItems, setTotalItems] = useState();
  const [maxItems, setMaxItems] = useState();
  const [maxExceeded, setMaxExceeded] = useState(false);
  const [overviewColor, setOverviewColor] = useState();
  const [permission, setPermission] = useState();

  //The current userId
  const userId = firebase.auth().currentUser.uid;

  //Refs
  const indicatorRef = useRef();

  //check component did unmount
  useEffect(() => {
    createNewObject();
    contentDetailListner();
    getLatest();
  }, []);

  useEffect(() => {
    if (Object.keys(removeData).length > 0) {
      getLatest();
    }
  }, [removeData]);

  /** Get Latest item */
  const getLatest = () => {
    const pivotName = 'user' + type.charAt(0).toUpperCase() + type.slice(1);
    const listner = firebase
      .database()
      .ref()
      .child(pivotName)
      .child(userId);

    if (dataObject.hasOwnProperty(removeData.key)) {
      delete dataObject[removeData.key];
      const newArray = orderItems();
      if (newArray[0]) {
        resetActiveTab(listner, newArray[0][0]);
      }
    } else {
      let active = false;
      active = Object.entries(dataObject).map(item => {
        if (item[1].setActive) {
          resetActiveTab(listner, item[0]);
          return true;
        }
      });

      if (!active) {
        const newArray = orderItems();
        if (newArray[0]) {
          resetActiveTab(listner, newArray[0][0]);
        }
      }
    }

    setDataObject(prevState => {
      return { ...prevState, ...dataObject };
    });
  };

  /** memberListner */
  const contentListner = id => {
    const listner = firebase
      .database()
      .ref()
      .child(type)
      .child(id);

    listner.child('tempSubTitle').on('child_changed', snap => {
      const activeId = snap.ref.parent.parent.key;
      if (activeId && dataObject[activeId]) {
        dataObject[activeId]['tempSubTitle'] = {};
        dataObject[activeId]['tempSubTitle']['active'] = snap.val();
        setDataObject(prevState => {
          return { ...prevState, ...dataObject };
        });
      }
    });

    listner.on('child_changed', snap => {
      if (snap.key === 'attendees') return;
      const activeId = snap.ref.parent.key;
      if (dataObject && dataObject[activeId]) {
        dataObject[activeId][snap.key] = snap.val();
        setDataObject(prevState => {
          return { ...prevState, ...dataObject };
        });
      }
    });
  };

  /** contentDetailListner */
  const contentDetailListner = () => {
    const pivotName = 'user' + type.charAt(0).toUpperCase() + type.slice(1);
    const listner = firebase
      .database()
      .ref()
      .child(pivotName)
      .child(userId);

    userDashboardListner
      .child(userId)
      .child(activeDashboardId)
      .child('permissions')
      .child('subitems')
      .on('value', snap => {
        setPermission(snap.val());
      });

    //Get the max number of sub items for this widget
    userDashboardListner
      .child(userId)
      .child(activeDashboardId)
      .child('widgets')
      .child(widgetId)
      .on('value', snapshot => {
        if (snapshot.val()) {
          if (snapshot.val().totalItems >= snapshot.val().maxItems) {
            setMaxExceeded(true);
          }
          setTotalItems(snapshot.val().totalItems ? snapshot.val().totalItems : '0');
          setMaxItems(snapshot.val().maxItems);
        }
      });

    userDashboardListner
      .child(userId)
      .child(activeDashboardId)
      .child('widgets')
      .child(widgetId)
      .on('child_changed', snap => {
        if (snap.key === 'totalItems') {
          setTotalItems(snap.val());
          userDashboardListner
            .child(userId)
            .child(activeDashboardId)
            .child('widgets')
            .child(widgetId)
            .child('maxItems')
            .once('value', snapshot => {
              if (snap.val() < snapshot.val()) {
                setMaxExceeded(false);
              } else {
                setMaxExceeded(true);
              }
            });
        }
      });

    listner.once('value', snap => {
      snap.forEach(function(snapshot) {
        contentListner(snapshot.key);
      });
    });

    userDashboardListner.child(userId).once('value', snap => {
      snap.forEach(function(snapshot) {
        if (snapshot.val().active) {
          userDashboardListner
            .child(userId)
            .child(snapshot.key)
            .child('colors')
            .child('overviewColor')
            .on('value', snapDashboard => {
              setOverviewColor(snapDashboard.val());
            });
        }
      });
    });

    listner.on('child_changed', snap => {
      snap.forEach(function(snapshot) {
        if (dataObject[snap.key]) {
          if (snapshot.key === 'time') {
            dataObject[snap.key]['pivottime'] = snapshot.val();
          } else {
            dataObject[snap.key][snapshot.key] = snapshot.val();
          }
        }
      });

      setDataObject(prevState => {
        return { ...prevState, ...dataObject };
      });
    });

    listner.on('child_removed', snap => {
      delete dataObject[snap.key];

      if (snap.val().setActive) {
        const active = Object.keys(dataObject)[0];
        resetActiveTab(listner, active);
      }

      setDataObject(dataObject);
    });

    listner
      .orderByChild('time')
      .startAt(Date.now())
      .on('child_added', snap => {
        if (snap.val().widgetId === widgetId) {
          contentListner(snap.key);
          dataObject[snap.key] = snap.val();

          firebase
            .database()
            .ref()
            .child(type)
            .child(snap.key)
            .once('value', snapshot => {
              dataObject[snap.key]['title'] = snapshot.val().title;
              dataObject[snap.key]['subTitle'] = snapshot.val().subTitle;

              if (snapshot.val().avatar) {
                dataObject[snap.key]['avatar'] = snapshot.val().avatar;
              }

              if (!dataObject[snap.key]['attendees']) {
                dataObject[snap.key]['attendees'] = {};
                dataObject[snap.key]['attendees'] = snapshot.val().attendees;
              }
            })
            .then(() => {
              if (snap.val().created || Object.keys(dataObject).length === 1) {
                resetActiveTab(listner, snap.key, true);
              }

              setDataObject(prevState => {
                return { ...prevState, ...dataObject };
              });
            });
        }
      });
  };

  /** Create new data object based on DB values */
  const createNewObject = () => {
    let newDataObject = {};
    //Create the pivotname based on the type
    const pivotName = 'user' + type.charAt(0).toUpperCase() + type.slice(1);
    //This is the main listner
    const lister = firebase
      .database()
      .ref()
      .child(type);
    //This is the default pivot listner
    const pivotListner = firebase
      .database()
      .ref()
      .child(pivotName)
      .child(userId);

    pivotListner
      .once('value', snap => {
        snap.forEach(function(snapshot) {
          if (widgetId === snapshot.val().widgetId) {
            newDataObject[snapshot.key] = snapshot.val();
            // Get the main data
            lister.child(snapshot.key).once('value', mainSnap => {
              newDataObject[snapshot.key]['title'] = mainSnap.val().title;
              newDataObject[snapshot.key]['subTitle'] = mainSnap.val().subTitle;

              if (mainSnap.val().avatar) {
                newDataObject[snapshot.key]['avatar'] = mainSnap.val().avatar;
              }

              if (!newDataObject[snapshot.key]['attendees']) {
                newDataObject[snapshot.key]['attendees'] = {};
                newDataObject[snapshot.key][
                  'attendees'
                ] = mainSnap.val().attendees;
              }
            });
          }
        });
      })
      .then(() => {
        setDataObject(prevState => {
          return { ...prevState, ...newDataObject };
        });
        getLatest();
        setReady(true);
      });
  };

  /** Reset the active tab */
  const resetActiveTab = (listner, activeKey, delay) => {
    listner
      .once('value', snap => {
        snap.forEach(function(snapshot) {
          if (
            widgetId === snapshot.val().widgetId &&
            snapshot.val().setActive === true
          ) {
            listner.child(snapshot.key).update({ setActive: false });
          }
        });
      })
      .then(() => {
        if (delay) {
          listner
            .child(activeKey)
            .update({ new: false, setActive: true, unRead: 0 });
          //Set the data in the widget based on the active tab
          callback(activeKey, widgetId);
          setCurrentActive(activeKey);
        } else {
          if (activeKey) {
            listner
              .child(activeKey)
              .update({ new: false, setActive: true, unRead: 0 });
            //Set the data in the widget based on the active tab
            callback(activeKey, widgetId);
            setCurrentActive(activeKey);
          } else {
            callback(activeKey, widgetId);
          }
        }
      });
  };

  /** changeChannel */
  const changeItem = activeKey => {
    const pivotName = 'user' + type.charAt(0).toUpperCase() + type.slice(1);
    const listner = firebase
      .database()
      .ref()
      .child(pivotName)
      .child(userId);
    resetActiveTab(listner, activeKey);
    return activeKey;
  };

  /** Re order the and put the last added on the first position */
  const orderItems = () => {
    let sortable = [];
    for (var item in dataObject) {
      if (dataObject[item]) sortable.push([item, dataObject[item]]);
    }

    sortable.sort(function(a, b) {
      return b[1]['time'] - a[1]['time'];
    });
    return sortable;
  };

  /** Mouse Enter */
  const handleMouseEnter = () => {
    if (indicatorRef && indicatorRef.current) {
      indicatorRef.current.style.opacity = 1;
    }
  };

  /** Mouse Leave */
  const hanleMouseLeave = () => {
    if (indicatorRef && indicatorRef.current) {
      indicatorRef.current.style.opacity = 0;
    }
  };

  /** Handle the subtitle */
  const handleSubTitle = (activeKey, text) => {
    if (
      currentActive &&
      dataObject &&
      dataObject[currentActive] &&
      dataObject[currentActive]['tempSubTitle'] &&
      dataObject[currentActive]['tempSubTitle']['active'] === true &&
      dataObject[currentActive]['tempSubTitle']['tempCreator'] !== userId &&
      activeKey === currentActive
    ) {
      return tempText;
    } else {
      return text;
    }
  };

  /** displayLists */
  const displayLists = () => {
    if (dataObject && Object.keys(dataObject).length <= 0) {
      return (
        <li>
          <StyledAnimation animation="empty-overview" animationHeight="100%" />
        </li>
      );
    } else {
      return orderItems().map(item => {
        if (dataObject && dataObject[item[0]] && item[1]) {
          return (
            <ListItem
              padding={[
                {
                  top: 0,
                  right: rem(12),
                  bottom: 0,
                  left: rem(12),
                },
              ]}
              clickable
              activeTabBackgroundColor={overviewColor}
              isSingleSelect
              active={item && item[1].setActive}
              data={dataObject[item[0]]}
              newCreated={item && item[1].new}
              animation={item && item[1].new ? 'new' : ''}
              firstAnimation={item && item[1].firstanimation}
              key={item && item[0]}
              onItemClick={() => changeItem(item[0])}
              title={item && item[1].title}
              subTitle={handleSubTitle(item[0], item[1].subTitle)}
              // date={item && item[1].time}
              unRead={item && item[1].unRead}
              id={item && item[0]}
              avatar={{
                source:
                  item && item[1].avatar && item[1].avatar.cropped
                    ? item[1].avatar.cropped
                    : '/resources/astronaut.svg',
              }}
            />
          );
        }
      });
    }
  };

  return (
    ready && (
      <StyledMainWrapper
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => hanleMouseLeave()}
      >
        {totalItems && maxItems && (
          <StyledIndicator ref={indicatorRef}>
            <StyledTextIndicator
              text={`${totalItems}/${maxItems}`}
              textColor="#fff"
              align="center"
              textStyle="description2"
            />
          </StyledIndicator>
        )}
        <StyledGrid
          rows={permission ? `1fr ${rem(60)}` : '1fr !important'}
          bgColor={backgroundColor}
          id={id}
          className="widgetOverview"
          scrollTop
        >
          <StyledList
            data={dataObject}
            clickable="single"
            listId={type + '-overview-' + currentActive}
            hasDividers
            empty={dataObject && Object.keys(dataObject).length <= 0}
          >
            {dataObject && displayLists()}
          </StyledList>
          {permission && (
            <Group
              align="center"
              fullHeight
              padding={[{ left: rem(12), right: rem(12), top: rem(12) }]}
              className="editable overviewButtons"
              justify="space-evenly"
            >
              <Button
                id={'additem-' + widgetId}
                disabled={maxExceeded ? maxExceeded : false}
                variant="icon"
                onClick={() => {
                  !dummyData && props.modalOpen(true);
                  !dummyData && props.modalType(['additem', type, widgetId]);
                }}
                bgColor={theme.color.dark[8]}
              >
                {maxExceeded && (
                  <React.Fragment>
                    <StyleImage
                      src="/resources/information.svg"
                      width={rem(20)}
                      height={rem(20)}
                      data-for={'tooltip-' + currentActive}
                      data-tip=""
                    />
                    <StyledTooltip
                      id={'tooltip-' + currentActive}
                      getContent={() => {
                        return (
                          <StyledTooltipWrapper>
                            <StyledToolTipText
                              text="Oops.. you exceeded the number of subitems"
                              textStyle="h6"
                              textColor="#fff"
                              align="center"
                            />
                            <StyledToolTipAnimation
                              animation="cry"
                              animationWidth={rem(100)}
                              animationHeight={rem(100)}
                            />
                            <Text
                              text="You exceeded the max number of items for this widget. Try removing one first or let it go.."
                              textStyle="subtitle"
                              textColor="#fff"
                              align="center"
                            />
                          </StyledTooltipWrapper>
                        );
                      }}
                    />
                  </React.Fragment>
                )}
                <Icon padding={rem(12)} icon="add" size={20} />
              </Button>
              {dataObject && Object.keys(dataObject).length > 0 && (
                <React.Fragment>
                  <Button
                    variant="icon"
                    onClick={() => {
                      !dummyData && props.modalOpen(true);
                      !dummyData &&
                        props.modalType([
                          'edititem',
                          type,
                          type,
                          pivotName,
                          dataObject[currentActive],
                          widgetId,
                          currentActive,
                          activeDashboardId,
                        ]);
                    }}
                    bgColor={theme.color.dark[8]}
                  >
                    <Icon padding={rem(12)} icon="edit" size={20} />
                  </Button>

                  {type !== 'chats' && type !== 'whiteboards' && type !== 'documentsharing' && (
                    <Button
                      variant="icon"
                      onClick={() => {
                        props.modalOpen(true);
                        props.modalType([
                          'shareItem',
                          type,
                          widgetId,
                          currentActive,
                        ]);
                      }}
                      bgColor={theme.color.dark[8]}
                    >
                      <Icon padding={rem(12)} icon="share" size={20} />
                    </Button>
                  )}

                  <Button
                    variant="icon"
                    onClick={() => {
                      props.modalOpen(true);
                      props.modalType([
                        'removeItem',
                        type,
                        widgetId,
                        currentActive,
                      ]);
                    }}
                    bgColor={theme.color.danger}
                  >
                    <Icon
                      padding={rem(12)}
                      icon="delete"
                      color="#fff"
                      size={20}
                    />
                  </Button>
                </React.Fragment>
              )}
            </Group>
          )}
        </StyledGrid>
      </StyledMainWrapper>
    )
  );
};

Overview.propTypes = {
  /** The List data from Firebase */
  data: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  /** Current selected list ID */
  activeItem: PropTypes.string,
  /** Data from the pivot table */
  pivotData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /** Pivot table name */
  pivotName: PropTypes.string,
  /** Type of the card */
  type: PropTypes.string,
  /** Indicates if the overview contains dummy data */
  dummyData: PropTypes.bool,
  /** Optional background color */
  backgroundColor: PropTypes.string,
  /** Custom ID */
  id: PropTypes.string,
  /** Widget ID */
  widgetId: PropTypes.string,
  /** Temp text when user is typing for example */
  tempText: PropTypes.string,
  /** Current actice dashboard Id */
  activeDashboardId: PropTypes.string,
};

Overview.defaultProps = {
  data: null,
  activeItem: null,
  pivotData: null,
  pivotName: null,
  type: null,
  dummyData: null,
  backgroundColor: null,
  id: null,
  widgetId: null,
  tempText: 'Is typing...',
  activeDashboardId: null,
};

export default connect(null, {
  setCurrentList,
  modalContent,
  modalOpen,
  modalType,
  createdList,
  notificationType,
})(Overview);
