import { css, keyframes } from 'styled-components';
import theme, { rem } from '../../theme';

const baseStyles = {};
/**
 * Animations
 */
const SlideElementDown = keyframes`
  0%{
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100%{
    opacity: 1;
    visibility: visible;
    transform: translate3d(0);
  }
`;

export const webStyles = {
  childHolder: () => css`
    height: 100%;
  `,

  wrapper: () => css`
    animation: ${SlideElementDown} 0.4s ease;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;

    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    height: 100%;
    overflow: hidden;
  `,

  mainContent: () => css`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    height: 100%;
  `,

  sideContent: () => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: absolute;
    right: ${rem(-60)};
    top: ${rem(30)};
    width: ${rem(100)};
    height: 100%;
  `,

  listSTyle: () => css`
    margin: 0 ${rem(-10)};
    flex-wrap: wrap;
    flex-grow: 1;

    li {
      width: calc(100% / 5);
      padding: 0 ${rem(10)};

      &:before {
        background: ${theme.color.light[20]};
        left: ${rem(10)};
        right: ${rem(10)};
      }

      &:hover {
        &:before {
          left: ${rem(10)};
          right: ${rem(10)};
        }
      }

      > div:first-of-type {
        padding: 0;

        > div:first-of-type {
          padding: ${rem(10)};
        }
      }
    }
  `,
};

export const nativeStyles = {
  ...baseStyles,
};
