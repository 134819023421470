/* eslint-disable react/no-find-dom-node */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Group from '../Group';
import Button from '../Button';
import Text from '../Text';
import Image from '../Image';
import Lottie from '../LottieControl';
import { chatListner, removeSubItem } from '../../firebase';
import theme, { rem } from '../../theme';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledTextIntro = styled(Text)`
  margin-bottom: ${rem(20)};
`;

const StyledImage = styled(Image)`
  margin-bottom: ${rem(20)};
  width: 100%;
  height: ${rem(250)};
  object-fit: contain;
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(10)};
`;

const StyledContentWrapper = styled(Group)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

const StyledButtonGroup = styled(Group)`
  margin-top: ${rem(20)};

  button {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: ${rem(20)};
    width: ${rem(350)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledMessage = styled(Text)`
  background: ${theme.color.dark[40]};
  padding: ${rem(8)} ${rem(10)};
  border-radius: ${rem(5)};
}
`;

const StyledLoading = styled(Lottie)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledGroup = styled(Group)`
  flex-direction: column;
  margin-bottom: ${rem(10)};

  &:last-child {
    margin-bottom: 0;
  }
`;

/** todo */
const removeChatMessage = props => {
  const { chatId, messageId, closeModals } = props;

  //States
  const [loading, setLoading] = useState(true);
  const [chatData, setChatData] = useState({});
  const [image, setImage] = useState();

  //Translation
  const [t] = useTranslation(['modal', 'textfield', 'button']);

  useEffect(() => {
    //getDashboardData();
    chatId && getChatData();
    document.getElementById('globalModal').firstElementChild.style.height =
      'auto';
    document.getElementById('globalModal').firstElementChild.style.width =
      '25rem';
  }, []);

  /** Get all the dashboard data */
  const getChatData = () => {
    chatListner
      .child(chatId)
      .child('items')
      .child(messageId)
      .once('value', snapshot => {
        if (snapshot.val()) {
          setChatData(snapshot.val());
          if (snapshot.val().images) handleImage(snapshot.val().images);
          setLoading(false);
        }
      });
  };

  /** Handle image */
  const handleImage = images => {
    if (images.cropped) {
      setImage(images.cropped);
    } else {
      Object.entries(images).map(item => {
        setImage(item[1].url);
      });
    }
  };

  /** Handle update */
  const handleRemove = () => {
    removeSubItem('chats', chatId, messageId);
    closeModals();
  };

  /** Return Chat messages */
  return loading ? (
    <StyledLoading animation='loading' animationWidth={400} />
  ) : (
    <StyledWrapper>
      <StyledContentWrapper fullwidth>
        <StyledText
          align='center'
          text={t('modal:removechatmessage.title')}
          textColor='#fff'
          textStyle='h3'
          tag='h1'
        />

        <StyledTextIntro
          align='center'
          text={t('modal:removechatmessage.text')}
          textColor='#fff'
          textStyle='h6'
          tag='p'
        />

        {image && <StyledImage src={image} />}

        {chatData.text && chatData.text !== '' && (
          <StyledGroup>
            <Text
              align='left'
              text={t('textfield:message.label')}
              textStyle='description'
              textColor='#fff'
            />
            <StyledMessage textColor='#fff' text={chatData.text} />
          </StyledGroup>
        )}

        <StyledButtonGroup fullwidth>
          <Button
            variant='contained'
            onClick={() => {
              handleRemove();
            }}
            bgColor={theme.color.danger}
            text={{
              align: 'center',
              text: t('button:remove.message'),
              textColor: '#fff',
            }}
          />
          <Button
            variant='contained'
            text={{
              align: 'center',
              text: t('button:cancel.cancel'),
              textColor: '#fff',
            }}
            onClick={() => {
              closeModals();
            }}
            bgColor={theme.color.cancel}
          />
        </StyledButtonGroup>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

removeChatMessage.propTypes = {
  /** Chat Id */
  chatId: PropTypes.string,
  /** Message Id */
  messageId: PropTypes.string,
  /** Close function for the modal */
  closeModals: PropTypes.func,
};

removeChatMessage.defaultProps = {
  chatId: null,
  messageId: null,
  closeModals: null,
};

export default removeChatMessage;
