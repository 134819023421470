import { memo } from 'react';

/** With memo check */
const withMemo = (Component, checkedProps) => {
  /** areEqual */
  function areEqual(prevProps, nextProps) {
    let isEqual = true;
    if (typeof prevProps === 'string' && typeof nextProps === 'string') {
      if (prevProps !== nextProps) isEqual = false;
    } else {
      for (let i = 0; i < checkedProps.length; i++) {
        const checkedProp = checkedProps[i];
        if (
          JSON.stringify(prevProps[checkedProp]) !==
          JSON.stringify(nextProps[checkedProp])
        ) {
          isEqual = false;
          break;
        }
      }
    }

    return isEqual;
  }

  return memo(Component, areEqual);
};

export default withMemo;
