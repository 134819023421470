import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import handleViewport from 'react-in-viewport';
import moment from 'moment';
import Card from '../Card';
import CardContent from '../CardContent';
import Button from '../Button';
import Avatar from '../Avatar';
import theme, { rem } from '../../theme';
import { webStyles } from './ChatMessage.styles';
import firebase, { userDashboardListner, userListner } from '../../firebase';

const StyledButton = styled(Button)`
  position: absolute;
  min-width: ${rem(25)};
  min-height: ${rem(25)};
  right: ${rem(5)};
  top: ${rem(3)};
  z-index: 1;
  opacity: 0;
  transition: 0.2s;

  i {
    margin: 0 !important;
  }
`;

/** Styles for wrapper div */
const StyledChatMessage = styled.div`
  margin: 0 ${rem(6)};
  display: flex;
  flex-direction: column;

  i {
    vertical-align: text-bottom;
    margin-left: ${rem(6)};
  }

  &.hide {
    display: none;
  }

  &.last {
    margin-top: ${rem(10)};
  }

  .dragHandler,
  .fullscreenHandler {
    display: none;
  }
`;

const StyledInnerWrapper = styled.div`
  max-width: 100%;
  ${({ messageOut }) => `
    display: flex;
    align-self: ${messageOut ? 'flex-end' : 'flex-start'};
  `}
`;

const StyledAvatar = styled(Avatar)`
  margin-top: ${rem(5)};
`;

/** Styled for card */
const StyledCard = styled(Card)`
  ${({ messageOut, chatColor }) => `
    position: relative;
    border-radius: ${rem(10)};
    align-self: ${messageOut ? 'flex-end' : 'flex-start'};
    box-sizing: border-box;
    background-color: ${messageOut ? chatColor : '#fff'};
    color: ${messageOut ? 'white' : 'black'};
    margin-left: ${messageOut ? 0 : rem(10)};
    text-align: left;
    flex: 0 1 auto;
    min-width: ${rem(175)};

    &::after {
      content: '';
      position: absolute;
      border: ${rem(4)} solid transparent;
      width: 0;
      height: 0;
      box-sizing: border-box;
      pointer-events: none;
      transform-origin: 0 0;
      ${messageOut ? webStyles.arrow.right(chatColor) : webStyles.arrow.left()};
    }

    img {
      padding: ${rem(8)} 0;
    }
  `}
`;

const StyledDetails = styled.div`
  display: flex;
  justify-content: space-between;

  img {
    padding-top: ${rem(5)};
    max-height: ${rem(20)};
  }
`;

const StyledCardContent = styled(CardContent)`
  padding-top: ${rem(25)};
  padding-right: ${props => props.padding};
  padding-bottom: ${props => props.padding};
  padding-left: ${props => props.padding};

  img {
    width: auto;
    max-height: ${rem(300)};
  }

  &:hover {
    .messageMenu {
      opacity: 1;
    }
  }
`;

const StyledIndicatorTime = styled.span`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  text-align: ${props => (props.dateAlign ? props.dateAlign : 'left')};
  ${theme.textStyle.description};
  margin-top: ${rem(4)};
  color: ${props =>
    props.messageOut ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)'};
`;

/**
 * Return a styled ChatMessage
 * @param {object} children
 * @param {string} arrow
 * @param {bool} messageOut
 * @param {bool} evenSpacing
 * @param {string} status
 * @param {string} timestamp
 * @param {string} dateAlign
 * @param {string} padding
 * @param {string} image
 * @param {string} animation
 * @param {string} id
 * @param {string} name
 * @param {string} owner
 * @param {string} preOwner
 * @param {string} activeDashboardId
 * @param {string} chatId
 */
const ChatMessage = props => {
  const {
    arrow,
    children,
    messageOut,
    timestamp,
    timestampClean,
    dateAlign,
    image,
    animation,
    owner,
    preOwner,
    extraSpacing,
    id,
    name,
    chatId,
    activeDashboardId,
  } = props;

  const uuid = firebase.auth().currentUser.uid;

  //States
  const [chatColor, setChatColor] = useState();
  const [userInfo, setUserInfo] = useState();
  const [userName, setUserName] = useState();
  const [userColor, setUserColor] = useState();
  const [isPayedUser, setIsPayedUser] = useState();

  useEffect(() => {
    settingsListner();
  }, []);

  /** settingsListner */
  const settingsListner = () => {
    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('colors')
      .child('chatColor')
      .on('value', snapDashboard => {
        if (snapDashboard.exists()) {
          setChatColor(snapDashboard.val());
        }
      });

    userListner
      .child(uuid)
      .child('isPayedUser')
      .on('value', snap => {
        setIsPayedUser(snap.val());
      });

    userListner
      .child(owner)
      .child('public')
      .child('avatar')
      .child('cropped')
      .on('value', snap => {
        if (snap.exists()) {
          setUserInfo(snap.val());
        }
      });

    userListner
      .child(owner)
      .child('public')
      .child('name')
      .on('value', snap => {
        if (snap.exists()) {
          setUserName(snap.val());
        }
      });

    userListner
      .child(owner)
      .child('public')
      .child('color')
      .on('value', snap => {
        if (snap.exists()) {
          setUserColor(snap.val());
        }
      });
  };

  /** handleInViewPort */
  const handleInViewPort = () => {
    /** Time handler */
    const timeFromNow = timestampClean =>
      moment(timestampClean).calendar(null, {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: 'dddd',
        nextWeek: 'dddd',
        sameElse: 'L',
      });

    const element = document.getElementById('timeIndicator-' + chatId);
    if (element) element.innerHTML = timeFromNow(timestampClean);
  };

  /** Block */
  const Block = (props: { inViewport: boolean }) => {
    // eslint-disable-next-line react/prop-types
    const { inViewport, forwardedRef } = props;
    const color = inViewport ? '#217ac0' : '#ff9800';
    const text = inViewport ? 'In viewport' : 'Not in viewport';
    return (
      <StyledChatMessage
        ref={forwardedRef}
        id={id}
        className={`${
          (preOwner && preOwner !== owner) || extraSpacing
            ? 'editable last'
            : 'editable'
        }`}
      >
        <StyledInnerWrapper messageOut={messageOut}>
          {inViewport && handleInViewPort(timestamp)}
          {!messageOut && (
            <StyledAvatar
              alignCenter={false}
              source={userInfo}
              size='small'
              title={userName}
              color={userColor}
            />
          )}
          <StyledCard
            messageOut={messageOut}
            arrow={arrow}
            height='auto'
            borderRadius={10}
            dp={2}
            chatColor={isPayedUser ? chatColor : '#7e73ac'}
          >
            <StyledCardContent scrollbar={false} padding={rem(12)}>
              {children}
              <StyledDetails>
                <StyledIndicatorTime
                  messageOut={messageOut}
                  dateAlign={dateAlign}
                >
                  {timestamp}
                </StyledIndicatorTime>
              </StyledDetails>
            </StyledCardContent>
          </StyledCard>
        </StyledInnerWrapper>
      </StyledChatMessage>
    );
  };

  const ViewportBlock = handleViewport(Block /** options: {}, config: {} **/);

  return <ViewportBlock />;
};

StyledCard.propTypes = {
  messageOut: PropTypes.bool.isRequired,
  arrow: PropTypes.string.isRequired,
};

StyledIndicatorTime.propTypes = {
  messageOut: PropTypes.bool.isRequired,
};

ChatMessage.propTypes = {
  /** The direction of the arrow */
  arrow: PropTypes.oneOf(['right', 'left']),

  /** Children */
  children: PropTypes.node.isRequired,

  /** Message out */
  messageOut: PropTypes.bool,

  /** Status of message */
  status: PropTypes.oneOf(['sent', 'delivered', 'read']),

  /** Timestamp */
  timestamp: PropTypes.string.isRequired,

  /** Clean timestamp */
  timestampClean: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** Date alignment */
  dateAlign: PropTypes.oneOf(['left', 'right']),

  /** Set an image indicator */
  image: PropTypes.string,

  /** Defines an animation */
  animation: PropTypes.string,

  /** Id of the chat item */
  id: PropTypes.string,

  /** Name of the chat owner */
  name: PropTypes.string,

  /** Current active dashboard ID */
  activeDashboardId: PropTypes.string,

  /** The owner of the message */
  owner: PropTypes.string,

  /** The PRE owner of the message */
  preOwner: PropTypes.string,

  /** Indicates extra spacing */
  extraSpacing: PropTypes.bool,

  /** Current chat Id */
  chatId: PropTypes.string,
};

ChatMessage.defaultProps = {
  arrow: 'left',
  dateAlign: 'left',
  messageOut: false,
  status: 'sent',
  image: null,
  animation: null,
  id: null,
  name: null,
  activeDashboardId: null,
  owner: null,
  preOwner: null,
  extraSpacing: null,
  chatId: null,
  timestampClean: null,
};

/** @component */
export default ChatMessage;
