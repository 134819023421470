import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ContextMenuTrigger } from 'react-contextmenu';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { modalOpen, modalType } from '../../redux/actions';
import theme, { rem } from '../../theme';
import Image from '../Image';
import { updateCursorEvent } from '../Cursor';
import Text from '../Text';
import ContextMenuItems from '../ContextMenuItems';
import Group from '../Group';
import Animation from '../LottieControl';
import firebase, {
  createDashboard,
  switchDashboard,
  userDashboardListner,
  dashboardListner,
  masterListner,
  feedbackListner,
  userRelationsDashboardListner,
  userRelationsListner,
} from '../../firebase';

const StyledLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const StyledGroup = styled(Group)`
  cursor: pointer;

  ${({ compact }) => `
    width:  ${compact ? 'auto' : '50%'};
    padding: ${compact ? '0' : rem(15)};
    height: ${compact ? 'auto' : '50%'};
  `}
`;

const StyledImagesWrapper = styled.div`
  ${({ compact }) => `
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: ${compact ? '0' : `0 -${rem(15 / 2)} ${rem(10)} -${rem(15 / 2)}`};
    justify-content: ${compact ? 'flex-end' : 'flex-start'};

    > div {
      margin-right: ${compact ? rem(10) : ''};
      &:last-child {
        margin-right: ${compact ? '0.3rem' : ''};
      }
    }
  `}

  .react-contextmenu-wrapper {
    width: 100%;
  }
`;

const StyledAnimation = styled(Animation)`
  ${({ compact }) => `
    position: absolute;
    right: -${rem(15)};
    top: -${rem(15)};
    z-index: 1;

    &.isNew {
      width: ${compact ? rem(15) : ''};
      height: ${compact ? rem(15) : ''};
      right: ${compact ? '-0.2rem' : ''};
      top: ${compact ? '-0.4rem' : ''};

      > div {
        width: ${compact ? rem(20) : ''}!important;
        height: ${compact ? rem(20) : ''}!important;

      }
    }
  `}
`;

const StyledImage = styled(Image)`
  ${({ active, compact }) => `
    position: absolute;
    transition: 0.2s;
    z-index: 1;
    right: -${rem(10)};
    bottom: ${active ? 'auto' : rem(-10)};
    top: ${active ? rem(-10) : 'auto'};

    &.currentActive,
    &.canEdit {
      width: ${compact ? rem(15) : ''};
      height: ${compact ? rem(15) : ''};
      right: ${compact ? '-0.3rem' : ''};
    }

    &.currentActive {
      top: ${compact ? '-0.3rem' : ''};
    }

    &.canEdit {
      bottom: ${compact ? '-0.1rem' : ''};
    }
  `}
`;

const StyledCenterImage = styled(Image)`
  ${({ active, compact }) => `
    position: absolute;
    transition: 0.2s;
    z-index: 1;
    right: ${rem(38)};
    bottom: auto;
    top: ${active ? rem(-25) : 'auto'};

    &.isMaster {
      width: ${compact ? rem(15) : ''};
      height: ${compact ? rem(15) : ''};
      top: ${compact ? '-0.4rem' : ''};
      left: ${compact ? '-0.4rem' : ''};
    }
  `}
`;

const StyledDashboardImage = styled(Image)`
  ${({ active, compact }) => `
    width: ${compact ? '3.5rem' : 'inherit'};
    height:${compact ? 'auto' : 'inherit'};
    max-height: ${compact ? '2rem' : 'none'};
    opacity: ${active ? '1' : '0.5'};
    border-radius: ${compact ? rem(5) : ''};
    transition: 0.2s;
    background: #fff;

    &:hover {
      opacity: 1;
    }
  `}
`;

const StyledTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${rem(10)};
  min-width: ${rem(150)};
  width: auto;
  z-index: 999999;
`;

const StyledTooltip = styled(ReactTooltip)`
  border-radius: ${rem(5)} !important;
  padding: ${rem(5)} ${rem(10)} !important;
`;

/**
 * OverviewSelector component
 * @param {string} activeDashboardId
 * @param {bool} compact
 */
const OverviewSelector = props => {
  const { activeDashboardId, compact } = props;

  //UserId
  const uuid = firebase.auth().currentUser.uid;

  //Translation
  const [t] = useTranslation(['button']);

  //States
  const [dashboardInfo, setDashboardInfo] = useState({});
  const [clickedDashboard, setClickedDashboard] = useState();
  const [relationDashboards, setRelationDashboards] = useState({});
  const [hideDash, setHideDash] = useState(false);
  const [master, setMaster] = useState();
  const [disableHover, setDisableHover] = useState(false);

  useEffect(() => {
    listnerSetup();
  }, []);

  useEffect(() => {
    if (disableHover) ReactTooltip.hide();
  }, [disableHover]);

  useEffect(() => {
    if (!clickedDashboard)
      setClickedDashboard(dashboardInfo[activeDashboardId]);
  }, [dashboardInfo]);

  /** Handle mouse down */
  const handleMouseDown = () => {
    for (let el of document.querySelectorAll('.react-contextmenu'))
      el.style.visibility = 'hidden';
  };

  /** Handle mouse down */
  const handleMouseUp = () => {
    for (let el of document.querySelectorAll('.react-contextmenu'))
      el.style.visibility = 'visible';
  };

  /** Handle Items */
  const handleItems = () => {
    if (!clickedDashboard) return [];

    let currentMaster = false;
    Object.keys(master.members).map(member => {
      if (member === uuid) {
        currentMaster = true;
      }
    });

    const infoArray = [];
    const defaultObject = {
      id: 'dashboard',
      icon: 'dashboard',
      text: t('button:dashboard.goto'),
      click: () =>
        handleOnclick(
          clickedDashboard[0],
          activeDashboardId === clickedDashboard[0] ? true : false
        ),
    };

    // if (currentMaster && clickedDashboard[1]['id'] !== master.dashboard.id) {
    //   infoArray.push({
    //     id: 'leaveDashboard',
    //     icon: 'remove_circle_outline',
    //     text: t('button:dashboard.leave'),
    //     special: true,
    //     backgroundColor: theme.color.danger,
    //     click: () => {
    //       props.modalOpen(true);
    //       props.modalType(['leaveDashboard', clickedDashboard[0], uuid]);
    //     },
    //   });
    // }

    if (
      currentMaster &&
      clickedDashboard[1] &&
      master.dashboard &&
      clickedDashboard[1]['id'] !== master.dashboard.id
    ) {
      infoArray.push({
        id: 'removeDashboard',
        icon: 'remove_circle_outline',
        text: t('button:dashboard.remove'),
        special: true,
        backgroundColor: theme.color.danger,
        click: () => {
          props.modalOpen(true);
          props.modalType(['removeDashboard', clickedDashboard[0], uuid]);
        },
      });
    }

    if (
      clickedDashboard &&
      clickedDashboard[1] &&
      clickedDashboard[1]['id'] &&
      master &&
      master.members
    ) {
      Object.keys(master.members).map(member => {
        if (member === uuid) {
          if (
            master.dashboard &&
            clickedDashboard[1]['id'] !== master.dashboard.id
          ) {
            infoArray.push({
              id: 'setMaster',
              image: '/resources/crown.svg',
              text: t('button:dashboard.master'),
              click: () => {
                props.modalOpen(true);
                props.modalType(['setMaster', clickedDashboard[0]]);
              },
            });
          } else if (!master.dashboard && master.members) {
            Object.keys(master.members).map(member => {
              if (member === uuid) {
                infoArray.push({
                  id: 'setMaster',
                  image: '/resources/crown.svg',
                  text: t('button:dashboard.master'),
                  click: () => {
                    props.modalOpen(true);
                    props.modalType(['setMaster', clickedDashboard[0]]);
                  },
                });
              }
            });
          }
        }
      });
    }

    if (
      clickedDashboard &&
      clickedDashboard[1] &&
      master.dashboard &&
      clickedDashboard[1]['id'] === master.dashboard.id &&
      master &&
      master.members
    ) {
      Object.keys(master.members).map(member => {
        if (member === uuid) {
          infoArray.push({
            id: 'removeMaster',
            icon: 'cancel',
            text: t('button:dashboard.removemaster'),
            special: true,
            backgroundColor: theme.color.danger,
            click: () => {
              props.modalOpen(true);
              props.modalType(['removeMaster', clickedDashboard[0]]);
            },
          });
        }
      });
    }

    if (
      clickedDashboard &&
      clickedDashboard[1] &&
      clickedDashboard[1]['creators'].hasOwnProperty(uuid) &&
      !clickedDashboard[1]['ssoCreate']
    ) {
      infoArray.push({
        id: 'dashboardSettings',
        icon: 'settings',
        text: t('button:dashboard.edit'),
        click: () => {
          props.modalOpen(true);
          props.modalType(['editdashboard', clickedDashboard[0]]);
        },
      });
    }

    infoArray.push(defaultObject);
    if (infoArray.length > 1) infoArray.reverse();

    return infoArray;
  };

  /** Setup the listners */
  const listnerSetup = () => {
    //Get the user relations info
    userRelationsListner.child(uuid).on('value', snap => {
      if (snap.exists()) {
        snap.forEach(function(childSnapshot) {
          userRelationsDashboardListner
            .child(uuid)
            .child(childSnapshot.key)
            .once('value', snapDash => {
              if (!snapDash.exists()) {
                dashboardInfo[uuid + snap.key + childSnapshot.key] = {};
                dashboardInfo[uuid + snap.key + childSnapshot.key]['id'] =
                  uuid + snap.key + childSnapshot.key;
                dashboardInfo[uuid + snap.key + childSnapshot.key][
                  'targetUser'
                ] = childSnapshot.key;
                dashboardInfo[uuid + snap.key + childSnapshot.key][
                  'seen'
                ] = true;
                dashboardInfo[uuid + snap.key + childSnapshot.key][
                  'ssoCreate'
                ] = true;
                dashboardInfo[uuid + snap.key + childSnapshot.key][
                  'name'
                ] = childSnapshot.val();
                dashboardInfo[uuid + snap.key + childSnapshot.key][
                  'creators'
                ] = { [uuid]: true };
                dashboardInfo[uuid + snap.key + childSnapshot.key][
                  'screenshot'
                ] = '/resources/dashlogo.png';
              }
            });
        });
        setDashboardInfo(prevState => {
          return { ...prevState, ...dashboardInfo };
        });
      }
    });

    //Check if master
    masterListner.on('value', snapMaster => {
      if (snapMaster.exists()) {
        setMaster(snapMaster.val());
      } else {
        setMaster('');
      }
    });

    userDashboardListner
      .child(uuid)
      .orderByChild('time')
      .startAt(Date.now())
      .on('child_added', snap => {
        let reload = true;
        dashboardInfo[snap.key] = {};
        dashboardInfo[snap.key]['id'] = snap.val().id;
        dashboardInfo[snap.key]['seen'] = snap.val().seen;

        dashboardListner
          .child(snap.key)
          .once('value', snapShot => {
            dashboardInfo[snap.key]['name'] = snapShot.val().name;
            dashboardInfo[snap.key]['creators'] = snapShot.val().creators;

            if (snapShot.val().avatar && snapShot.val().avatar.cropped) {
              dashboardInfo[snap.key][
                'screenshot'
              ] = snapShot.val().avatar.cropped;
            } else {
              dashboardInfo[snap.key]['screenshot'] = '/resources/dashlogo.png';
            }

            if (snapShot.val().sso) reload = false;
          })
          .then(() => {
            if (reload) {
              setDashboardInfo(prevState => {
                return { ...prevState, ...dashboardInfo };
              });
            }
          });

        dashboardListner.child(snap.key).on('child_changed', snap => {
          const parentKey = snap.ref.parent.key;
          if (snap.key === 'avatar' && snap.val().cropped) {
            dashboardInfo[parentKey]['screenshot'] = snap.val().cropped;
            setDashboardInfo(prevState => {
              return { ...prevState, ...dashboardInfo };
            });
          }
        });
      });

    userDashboardListner.child(uuid).on('child_removed', snap => {
      delete dashboardInfo[snap.key];
      setDashboardInfo(dashboardInfo);
      setDashboardInfo(prevState => {
        return { ...prevState, ...dashboardInfo };
      });
    });

    userDashboardListner.child(uuid).once('value', snap => {
      snap.forEach(function(childSnapshot) {
        dashboardInfo[childSnapshot.key] = {};
        dashboardInfo[childSnapshot.key]['id'] = childSnapshot.val().id;
        dashboardInfo[childSnapshot.key]['seen'] = childSnapshot.val().seen;
        dashboardInfo[childSnapshot.key]['active'] = childSnapshot.val().active;

        dashboardListner
          .child(childSnapshot.key)
          .once('value', snapShot => {
            if (snapShot.val()) {
              dashboardInfo[childSnapshot.key]['name'] = snapShot.val().name;
              dashboardInfo[childSnapshot.key][
                'creators'
              ] = snapShot.val().creators;

              if (snapShot.val().avatar && snapShot.val().avatar.cropped) {
                dashboardInfo[childSnapshot.key][
                  'screenshot'
                ] = snapShot.val().avatar.cropped;
              } else {
                dashboardInfo[childSnapshot.key]['screenshot'] =
                  '/resources/dashlogo.png';
              }
            }
          })
          .then(() => {
            setDashboardInfo(prevState => {
              return { ...prevState, ...dashboardInfo };
            });
          });

        dashboardListner
          .child(childSnapshot.key)
          .on('child_changed', snapShot => {
            const parentKey = snapShot.ref.parent.key;
            if (snapShot.key === 'name') {
              dashboardInfo[parentKey]['name'] = snapShot.val();
              setDashboardInfo(prevState => {
                return { ...prevState, ...dashboardInfo };
              });
            }

            if (snapShot.key === 'avatar' && snapShot.val().cropped) {
              dashboardInfo[parentKey]['screenshot'] = snapShot.val().cropped;
              setDashboardInfo(prevState => {
                return { ...prevState, ...dashboardInfo };
              });
            } else if (snapShot.key === 'avatar' && !snapShot.val().cropped) {
              dashboardInfo[parentKey]['screenshot'] =
                '/resources/dashlogo.png';
              setDashboardInfo(prevState => {
                return { ...prevState, ...dashboardInfo };
              });
            }
          });
      });
    });
  };

  /** Get the active state of the feedback */
  const getFeedbackStatus = () => {
    let status = false;
    feedbackListner.child('active').once('value', snap => {
      if (snap.exists()) status = snap.val();
    });

    return status;
  };

  /** check for/ create dashboard and go there */
  const GoToOrMakeDashBoard = (id, targetUser, name) => {
    let saveData = [];

    if (!targetUser) {
      switchDashboard(id);
    } else {
      setHideDash(true);
    }

    const UserRelationRef = userRelationsDashboardListner
      .child(uuid)
      .child(targetUser)
      .child('dashboardId');
    UserRelationRef.child(targetUser).once('value', snap => {
      if (snap.val()) {
        switchDashboard(snap.val());
      } else {
        let key = UserRelationRef.push().key;
        UserRelationRef.child(targetUser)
          .set(key)
          .then(() => {
            saveData['id'] = key;
            saveData['title'] = name;
            saveData['subtitle'] = 'StudentsPlus';
            saveData['sso'] = true;
            saveData['allPermissions'] = false;
            saveData['activeDashboardId'] = activeDashboardId;
            saveData['userId'] = uuid;
            saveData['reload'] = true;
            saveData['feedback'] = getFeedbackStatus();
            saveData['image'] = [];

            masterListner.once('value', snapshot => {
              if (snapshot.exists()) {
                saveData['backgroundimage'] =
                  snapshot.val().backgroundimage &&
                  snapshot.val().backgroundimage;

                dashboardListner
                  .child(snapshot.val()['dashboard'].id)
                  .once('value', snap => {
                    createDashboard(saveData, snap.val());
                  });
              } else {
                createDashboard(saveData);
              }
            });
          });
      }
    });
  };

  /** Handle onClick */
  const handleOnclick = (id, active) => {
    if (!active && dashboardInfo[id])
      GoToOrMakeDashBoard(
        id,
        dashboardInfo[id].targetUser,
        dashboardInfo[id].name
      );
    if (uuid) updateCursorEvent(uuid, activeDashboardId, 'default');
  };

  return dashboardInfo ? (
    <StyledImagesWrapper compact={compact}>
      {Object.entries(dashboardInfo).map(dashboard => {
        return (
          <StyledGroup key={dashboard[0]} compact={compact} id={dashboard[0]}>
            <ContextMenuTrigger
              id='dashboard'
              holdToDisplay={1}
              data={dashboard[1]}
            >
              <Group>
                {!dashboard[1].seen && !dashboard[1]['active'] && (
                  <StyledAnimation
                    animation='new'
                    animationWidth={rem(40)}
                    animationHeight={rem(40)}
                    compact={compact}
                    className='isNew'
                  />
                )}
                {dashboard[1]['creators'] &&
                  dashboard[1]['creators'].hasOwnProperty(uuid) && (
                    <StyledImage
                      src='/resources/edit.svg'
                      width={rem(30)}
                      height={rem(30)}
                      compact={compact}
                      className='canEdit'
                    />
                  )}

                {master &&
                  master.dashboard &&
                  master.dashboard.id === dashboard[1]['id'] && (
                    <StyledCenterImage
                      active
                      src='/resources/crown.svg'
                      width={rem(30)}
                      height={rem(30)}
                      compact={compact}
                      className='isMaster'
                    />
                  )}

                {dashboard[1]['active'] && (
                  <StyledImage
                    active
                    src='/resources/checked.svg'
                    width={rem(30)}
                    height={rem(30)}
                    className='currentActive'
                    compact={compact}
                  />
                )}
                {dashboard[1].screenshot && (
                  <React.Fragment>
                    <StyledDashboardImage
                      compact={compact}
                      data-for={`tooltipDashboardSwitch-${dashboard[1].id}`}
                      data-tip=''
                      active={dashboard[1]['active']}
                      hasBorderRadius
                      src={dashboard[1].screenshot}
                      height={rem(100)}
                      width={rem(200)}
                      onClick={() => {
                        setClickedDashboard(dashboard);
                      }}
                      onMouseDown={() => handleMouseDown()}
                      onMouseUp={() => handleMouseUp()}
                    />
                    <StyledTooltip
                      id={`tooltipDashboardSwitch-${dashboard[1].id}`}
                      disable={disableHover ? true : false}
                      getContent={() => {
                        return (
                          dashboard[1].name && (
                            <StyledTooltipWrapper>
                              <Text
                                text={dashboard[1].name}
                                textColor='#fff'
                                align='center'
                              />
                            </StyledTooltipWrapper>
                          )
                        );
                      }}
                    />
                  </React.Fragment>
                )}
              </Group>
            </ContextMenuTrigger>
          </StyledGroup>
        );
      })}
      <ContextMenuItems
        id='dashboard'
        data={handleItems()}
        onShow={() => {
          setDisableHover(true);
        }}
        onHide={() => {
          setDisableHover(false);
        }}
      />
    </StyledImagesWrapper>
  ) : (
    <StyledLoader>
      <Animation animation='loadingv2' />
    </StyledLoader>
  );
};

OverviewSelector.propTypes = {
  activeDashboardId: PropTypes.string,
  compact: PropTypes.bool,
};

OverviewSelector.defaultProps = {
  activeDashboardId: '',
  compact: false,
};

/** @component */
export default connect(null, {
  modalOpen,
  modalType,
})(OverviewSelector);
