/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { lighten } from 'polished';
import { connect } from 'react-redux';
import { userSelection, selectedList } from '../../redux/actions';
import InfoCard from '../InfoCard';
import Image from '../Image';
import theme, { rem } from '../../theme';

// creating a portal for the draggable
const portal = document.createElement('div');
document.body.appendChild(portal);

const StyledWrapper = styled.div`
  ${({
    evenRadius,
    itemsColor,
    active,
    activeTabBackgroundColor,
    fullWidth,
  }) => `
    width: ${fullWidth ? '100%' : ''};
    transition: 0.25s;
    display: flex;
    padding: 1rem;
    min-height: 5rem;
    height: auto;
    position: relative;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin-bottom: ${rem(5)};
    background: ${itemsColor ? itemsColor : 'rgba(255, 255, 255, 0.8)'};
    background-color: ${
      active ? activeTabBackgroundColor : 'rgba(255, 255, 255, 0.8)'
    };
    opacity: 1;
    border-top-left-radius: ${rem(10)};
    border-top-right-radius: ${rem(10)};
    border-bottom-right-radius: ${rem(10)};
    border-bottom-left-radius: ${rem(10)};
    box-shadow: ${active ? '-2px 2px 3px 1px rgba(0, 0, 0, 0.19)' : ''};
    z-index: ${active ? '3' : ''};
    transform: scale(${active ? '0.95' : '0.9'});
    color: ${active ? '#fff' : theme.color.dark[60]};

    .contentWrapper {
      width: 100%;
    }

    .optionGroup {
      opacity: 0;

      &.active {
        opacity: 1;
        transform: scale(1);
      }
    }

    &.active {
      .infoCardContent,
      .infoCardIndicator {
        div {
          color: #fff !important;
        }
      }

      &:hover {
        background: ${itemsColor};
      }
    }

    &.hide {
      display: none;
    }

    &:first-child {
      border-top-left-radius: ${!evenRadius ? rem(30) : rem(10)};
    }

    &:hover {
      background: ${
        active ? itemsColor : lighten(0.2, activeTabBackgroundColor)
      };
      cursor: pointer;
      .optionGroup {
        opacity: 1;
      }
    }
  `}
`;

const StyledImage = styled(Image)`
  position: absolute;
  right: -${rem(8)};
  top: -${rem(2)};
  width: ${rem(25)};
  height: ${rem(25)};
`;

/**
 * Return a styled ListItem component
 * @param {object} child
 * @param {string} rightIcon
 * @param {string} clickable
 * @param {bool} newCreated
 * @param {bool} firstAnimation
 * @param {string} activeTab
 * @param {func} onItemClick
 * @param {string} activeTabBackgroundColor
 * @param {string} activeTabColor
 * @param {string} hoverBackgroundColor
 * @param {bool} hasOpacity
 * @param {arrayOf} hasButtons
 * @param {string} itemsColor
 * @param {bool} hasDividers
 * @param {string} textColor
 * @param {bool} evenRadius
 * @param {bool} isSingleSelect
 * @param {string} extraIndicator
 * @param {bool} isSearch
 * @param {bool} fullWidth
 * @param {object} badge
 * @param {object} badges
 */
const ListItem = props => {
  const {
    listId,
    newCreated,
    firstAnimation,
    id,
    active,
    avatar,
    title,
    date,
    subTitle,
    clickable,
    animation,
    unRead,
    onItemClick,
    rightIcon,
    activeTabBackgroundColor,
    hoverBackgroundColor,
    activeTabColor,
    isSingleSelect,
    disabled,
    hasOpacity,
    itemsColor,
    hasButtons,
    evenRadius,
    extraIndicator,
    isSearch,
    fullWidth,
    badge,
    badges,
  } = props;
  const StyledItem = isSearch ? 'div' : 'li';

  /**
   * On Click item callback
   *
   * @param {object} e
   */
  const handleClick = e => {
    if (!disabled) {
      if (!disabled) {
        e.stopPropagation();
      }

      if (onItemClick) {
        if (isSingleSelect) {
          const clickedId = onItemClick(id);
          const parent = document.getElementById(clickedId).parentElement;
          const children = parent.childNodes;

          //setActiveItem(false);
          for (var child of children) {
            if (child.classList.contains('active')) {
              child.classList.remove('active');
            }
          }
          if (id === clickedId) {
            document.getElementById(clickedId).classList.add('active');
          }
        } else {
          const str =  id.split("-");
          onItemClick(str[1]);
        }
      }
    } else {
      e.preventDefault();
    }
  };

  return (
    <StyledWrapper
      fullWidth={fullWidth}
      itemsColor={itemsColor}
      newCreated={newCreated && newCreated}
      firstAnimation={firstAnimation ? firstAnimation : false}
      active={active}
      listId={listId}
      id={id}
      clickable={clickable}
      onClick={e => handleClick(e)}
      activeTabBackgroundColor={
        activeTabBackgroundColor
          ? activeTabBackgroundColor
          : theme.color.primary[100]
      }
      hoverBackgroundColor={hoverBackgroundColor}
      activeTabColor={activeTabColor}
      disabled={disabled}
      evenRadius={evenRadius}
      hasOpacity={hasOpacity}
      selection
      className={`editable ${active ? 'active' : ''}`}
    >
      <StyledItem className="contentWrapper">
        {extraIndicator && <StyledImage src={extraIndicator} />}
        <InfoCard
          avatar={avatar}
          title={title}
          subTitle={subTitle instanceof String ? subTitle : ''}
          animation={animation}
          date={date}
          hasButtons={hasButtons}
          unRead={unRead}
          rightIcon={rightIcon}
          badge={badge}
          badges={badges}
        />
      </StyledItem>
    </StyledWrapper>
  );
};

/** mapStateToProps */
const mapStateToProps = state => ({
  selection: state.userselection.userSelection,
});

ListItem.propTypes = {
  /** Icon on the right side */
  rightIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** If set the list item will be clickable */
  clickable: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** Handler for clicking on a list item */
  onItemClick: PropTypes.func,
  /** List item ID */
  id: PropTypes.string,
  /** The ID of the parent list */
  listId: PropTypes.string,
  /** The background color when the item is set to active */
  activeTabBackgroundColor: PropTypes.string,
  /** The color when the item is set to active */
  activeTabColor: PropTypes.string,
  /** The background color on hover */
  hoverBackgroundColor: PropTypes.string,
  /** Indicates if the list is disabled so no futher interaction is possible */
  disabled: PropTypes.bool,
  /** Indicates if the inactive state should have an opacity of 0.5 */
  hasOpacity: PropTypes.bool,
  /** Date indication */
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Color of the text */
  textColor: PropTypes.string,
  /** Set if you want to have even border radius */
  evenRadius: PropTypes.bool,
  /** Boolean for single select */
  isSingleSelect: PropTypes.bool,
  /** Extra indicator */
  extraIndicator: PropTypes.string,
  /** Indicates if it is used for search */
  isSearch: PropTypes.bool,
  /** Define a full width item */
  fullWidth: PropTypes.bool,
  /** Contains a badge */
  badge: PropTypes.oneOfType([PropTypes.object]),
  /** Contains multiple badges */
  badges: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

ListItem.defaultProps = {
  rightIcon: null,
  clickable: null,
  onItemClick: null,
  id: null,
  listId: null,
  activeTabBackgroundColor: theme.color.primary[8],
  activeTabColor: theme.color.primary[100],
  hoverBackgroundColor: theme.color.primary[8],
  disabled: false,
  hasOpacity: false,
  date: null,
  textColor: theme.color.dark[60],
  evenRadius: false,
  isSingleSelect: false,
  extraIndicator: null,
  isSearch: false,
  fullWidth: false,
  badge: null,
  badges: null,
};

/** @component */
export default connect(mapStateToProps, { userSelection, selectedList })(
  ListItem
);
