import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Resizable } from 're-resizable';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import withMemo from '../../utils/withMemo';
import CompactMenu from '../CompactMenu';
import WidgetStore from '../WidgetStore';
import { storeWidgetDrag, storeWidgetDelete } from '../../redux/actions';
import theme, { rem } from '../../theme';
import Icon from '../Icon';
import Animation from '../LottieControl';
import Tooltip from '../Tooltip';
import Bubble from '../Bubble';
import firebase, {
  userListner,
  userDashboardListner,
  dashboardListner,
  feedbackListner,
  masterListner,
} from '../../firebase';

const StyledResizable = styled(Resizable)`
  display: flex;
  align-tems: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  position: fixed !important;
  padding: 0 !important;
  bottom: 0;
  right: 0;
  left: 0;
  padding: ${rem(14)} ${rem(14)} 0 ${rem(14)};
  box-sizing: border-box;
  z-index: 0;
  width: 100% !important;
  max-height: calc(100% - ${rem(30)});
  border-top-left-radius: ${rem(20)};
  border-top-right-radius: ${rem(20)};

  .tut & {
    transition: 0.4s;
    height: ${rem(500)} !important;
  }
`;

const StyledDragBar = styled.div`
  position: absolute;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem(60)};
  height: ${rem(60)};
  border-radius: 50%;
  overflow: hidden;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: ${rem(5)};
`;

const StyledAnimation = styled(Animation)`
  margin-bottom: ${rem(25)};
`;

const StyledWrapper = styled.div`
  ${({ hide }) => `
    width: 100%;
    height: 100%;
    background: #2f3136;
    border-top-left-radius: ${rem(20)};
    border-top-right-radius: ${rem(20)};
    display: flex;
    overflow: ${hide ? 'hidden' : ''};
  `}
`;

const StyledMain = styled.div`
  position: absolute;
  top: -${rem(14)};
  width: ${rem(60)};
  height: ${rem(60)};
  border-radius: 50%;
  z-index: 9999;
`;

const BubbleWrapper = styled.div``;

/**
 * Overview component
 * @param {object} children
 * @param {string} activeDashboardId
 */
const ReSizable = props => {
  // eslint-disable-next-line react/prop-types
  const {
    children,
    storeDrag,
    widgetDrag,
    storeWidgetDrag,
    activeDashboardId,
    widgetData,
  } = props;
  const [width, setWidth] = useState('100vw');
  const [height, setHeight] = useState(0);
  const [hide, setHide] = useState(true);
  const [menuHeight, setMenuHeight] = useState(50);
  const [showFeedback, setShowFeedback] = useState();
  const [permissionStore, setPermissionStore] = useState();
  const [permissionCompactMenu, setPermissionCompactMenu] = useState();
  const [animation, setAnimation] = useState(true);
  const [isso, setIsso] = useState(false);
  const [isMaster, setIsMaster] = useState(false);
  const handle = (
    <StyledMain onClick={() => handleCLick()}>
      <StyledDragBar
        data-tut='reactour__draghandle'
        id='draghandle'
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}
      >
        <StyledAnimation
          animation='arrowup'
          animationWidth={rem(20)}
          animationHeight={rem(80)}
          loop
          isStopped={animation}
          isPaused={animation}
          initStart={10}
          initEnd={68}
        />
        {/* <StyledIcon icon="extension" color="#fff" size={24} /> */}
      </StyledDragBar>
    </StyledMain>
  );

  //UserId
  const uuid = firebase.auth().currentUser.uid;

  const permissionStoreListner = userDashboardListner
    .child(uuid)
    .child(activeDashboardId)
    .child('permissions')
    .child('store');

  const permissionCompactMenuListner = userDashboardListner
    .child(uuid)
    .child(activeDashboardId)
    .child('permissions')
    .child('compactmenu');

  //Refs
  const scrollRef = useRef();

  //Translation
  const [t] = useTranslation(['modal']);

  //Handle mouse click
  const handleCLick = () => {
    //console.log('clicker');
  };

  //Handle mouse enter
  const handleMouseEnter = () => {
    setAnimation(false);
  };

  //Handle mouse leave
  const handleMouseLeave = () => {
    setAnimation(true);
  };

  /** trackV */
  const trackV = ({ style, padding, ...props }) => (
    <div className='track track-vertical' style={style} {...props} />
  );

  /** trackH */
  const trackH = ({ style, ...props }) => (
    <div className='track track-horizontal' style={style} {...props} />
  );

  /** Handle menu */
  const handleMenu = type => {
    const element = document.getElementById('smallMenu');
    if (element) {
      if (type) {
        element.classList.add('fullMenu');
      } else {
        element.classList.remove('fullMenu');
      }
    }
  };

  useEffect(() => {
    permissionCompactMenuListner.on('value', snap => {
      if (snap.exists()) {
        if (snap.val()) {
          setHeight(50);
          handleMenu(false);
          setHide(true);
        } else {
          permissionStoreListner.once('value', snapshot => {
            if (snapshot.exists()) {
              if (!snapshot.val()) {
                setHeight(0);
                handleMenu(false);
              } else {
                setHeight(10);
                handleMenu(true);
              }
            }
          });
        }
        setPermissionCompactMenu(snap.val());
      } //else {
      // userListner
      //   .child(uuid)
      //   .child('isGhostUser')
      //   .once('value', snapshotCheck => {
      //     if (snapshotCheck.exists()) {
      //       if (!snapshotCheck.val()) {
      //         userDashboardListner
      //           .child(uuid)
      //           .child(activeDashboardId)
      //           .child('permissions')
      //           .update({ compactmenu: true })
      //           .then(() => {
      //             setPermissionCompactMenu(true);
      //             setHeight(50);
      //           });
      //       }
      //     }
      //   });
      // setPermissionCompactMenu(false);
      // }

      userListner
        .child(uuid)
        .once('value', snapIsso => {
          if (snapIsso.exists() && snapIsso.val().isSingleSignOnUser) {
            setIsso(true);
            setHeight(50);
          }
        })
        .then(() => {
          masterListner.child('members').once('value', snapMaster => {
            if (snapMaster.exists()) {
              snapMaster.forEach(function(snapMasterChild) {
                if (snapMasterChild.key === uuid) {
                  setIsMaster(true);
                  setHeight(50);
                }
              });
            }
          });
        });
    });

    permissionStoreListner.on('value', snap => {
      if (snap.exists()) {
        if (!snap.val()) {
          handleMenu(false);
          setHide(true);
          permissionCompactMenuListner.once('value', snapshot => {
            if (snapshot.exists()) {
              if (!snapshot.val()) {
                setHeight(0);
              } else {
                setHeight(50);
              }
            }
          });
        } else {
          permissionCompactMenuListner.once('value', snapshot => {
            if (snapshot.exists()) {
              if (snapshot.val()) {
                setHeight(50);
              } else {
                setHeight(10);
              }
            } else {
              setHeight(10);
              handleMenu(true);
            }
          });
        }
        setPermissionStore(snap.val());
      }

      userListner
        .child(uuid)
        .once('value', snapIsso => {
          if (snapIsso.exists() && snapIsso.val().isSingleSignOnUser) {
            setIsso(true);
            setHeight(50);
          }
        })
        .then(() => {
          masterListner.child('members').once('value', snapMaster => {
            if (snapMaster.exists()) {
              snapMaster.forEach(function(snapMasterChild) {
                if (snapMasterChild.key === uuid) {
                  setIsMaster(true);
                  setHeight(50);
                }
              });
            }
          });
        });
    });
  }, []);

  useEffect(() => {
    if (storeDrag || widgetDrag) {
      if (height >= menuHeight) {
        setHeight(permissionCompactMenu || isso || isMaster ? menuHeight : 10);
        handleMenu(false);
        setHide(true);
      }
    }

    feedbackListner.child('active').on('value', snap => {
      if (snap.exists()) setShowFeedback(snap.val());
    });
  }, [storeDrag, widgetDrag]);

  // //Nofification trigger
  // useEffect(() => {
  //   /** handleWidgets */
  // }, [cardIsDragging]);

  /** Handle update */
  const handleUpdate = e => {
    if (scrollRef.current && height <= menuHeight)
      scrollRef.current.scrollToTop();
  };

  return (
    <React.Fragment>
      <StyledResizable
        data-tut='reactour__draghandle'
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}
        size={
          storeDrag || widgetDrag ? { width, height: 10 } : { width, height }
        }
        onResize={(e, direction, ref, d) => {
          if (height + d.height <= menuHeight) {
            handleMenu(false);
          } else {
            handleMenu(true);
          }
        }}
        onResizeStop={(e, direction, ref, d) => {
          setWidth(width + d.width);
          setHeight(height + d.height);
          storeWidgetDrag(false);
          if (height + d.height <= menuHeight) {
            setHide(true);
          } else {
            setHide(false);
          }
        }}
        handleStyles={{
          top: {
            display: 'flex',
            justifyContent: 'center',
          },
        }}
        handleComponent={{ top: handle }}
        enable={{
          top: permissionStore || isMaster ? true : false,
          right: false,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        snap={{ y: [menuHeight] }}
        snapGap={(permissionCompactMenu || isMaster) && 30}
        // snapGap={100}
      >
        <StyledWrapper hide={hide}>
          {showFeedback && (
            <React.Fragment>
              <BubbleWrapper
                data-for='feedbackBubble'
                data-tip=''
                data-delay-show='500'
              >
                <Bubble
                  fixed
                  icon={{
                    icon: 'feedback',
                    background: theme.color.primary[100],
                    color: '#fff',
                    size: 36,
                    padding: rem(10),
                    shape: 'round',
                  }}
                  type='feedback'
                  action='modal'
                  position='br'
                />
              </BubbleWrapper>
              <Tooltip
                id='feedbackBubble'
                noSpacing
                content={{
                  text: {
                    text: t('modal:feedback.title'),
                    textColor: '#fff',
                    align: 'center',
                  },
                }}
              />
            </React.Fragment>
          )}

          <Scrollbars
            hideTracksWhenNotNeeded
            universal
            autoHide
            className='scrollbar-tracks'
            onUpdate={e => handleUpdate(e)}
            renderTrackVertical={trackV}
            renderTrackHorizontal={trackH}
            ref={scrollRef}
          >
            {(permissionCompactMenu || isso || isMaster) && (
              <CompactMenu activeDashboardId={activeDashboardId} />
            )}
            {(permissionStore || isMaster) && (
              <WidgetStore
                widgetData={widgetData}
                activeDashboardId={activeDashboardId}
              />
            )}
          </Scrollbars>
        </StyledWrapper>
      </StyledResizable>
    </React.Fragment>
  );
};

ReSizable.propTypes = {
  children: PropTypes.node,
  activeDashboardId: PropTypes.string,
};

ReSizable.defaultProps = {
  children: null,
  activeDashboardId: null,
};

/** pass currentUser and currentChannel from redux to global props */
const mapStateToProps = state => ({
  widgetDrag: state.cardisdragging.cardisDragging,
  storeDrag: state.storewidgetdrag.storeWidgetDrag,
});

/** @component */
export default connect(mapStateToProps, { storeWidgetDrag })(
  withMemo(ReSizable, ['storeDrag', 'widgetDrag'])
);
