import PropTypes from 'prop-types';

export const propTypes = {
  /** Show a preview when a item is dropped into the area or selected from files */
  preview: PropTypes.bool,
  type: PropTypes.string,
  subType: PropTypes.string,
  name: PropTypes.string,
  previewId: PropTypes.string,
  hasCrop: PropTypes.bool,
  isGallery: PropTypes.bool,
  currentId: PropTypes.string,
};

export const defaultProps = {
  preview: false,
  type: null,
  subType: null,
  name: '',
  previewId: '',
  hasCrip: false,
  isGallery: false,
  currentId: null,
};
