import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import ReactAvatarEdit from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import theme, { rem } from '../../theme';
import firebase, { uploadEditorImages, masterListner } from '../../firebase';
import Button from '../Button';
import ButtonGroup from '../ButtonGroup';
import Slider from '../Slider';
import Animation from '../LottieControl';
import Text from '../Text';
import Group from '../Group';

const StyledAvatar = styled(ReactAvatarEdit)`
  ${theme.textStyle.body};
  border-radius: ${rem(10)};
`;

const StyledGroupAvatar = styled(Group)`
  ${({ emptyImage }) => `
    display: ${emptyImage ? 'none' : 'flex'};
    width: auto;
  `}
`;

const StyledAnimationEmpty = styled(Animation)`
  position: absolute;
`;

const StyledButtonGroupAvatar = styled(ButtonGroup)`
  position: absolute;
  z-index: 1;
  top: -0.5rem;
  right: -0.5rem;
`;

const StyledWrapper = styled.div`
  ${({ backgroundColor, hasBackground }) => `
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: ${hasBackground ? rem(20) : 0};
    background: ${hasBackground ? backgroundColor : 'transparent'};
    border-radius: ${rem(10)};
    margin-bottom: ${rem(15)};
    margin-top: ${rem(5)};

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `}
`;

const StyledAnimation = styled(Animation)`
  position: absolute;
  z-index: 1;
  display: none;
`;

const StyledEmptyWrapper = styled.div`
  ${({ width, height, emptyImage }) => `
    position: relative;
    justify-content: center;
    align-items: center;
    width: ${width}px;
    height: ${height}px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: ${rem(10)};
    display: ${emptyImage ? 'flex' : 'none'};

    input {
      opacity: 0;
      width: ${width}px;
      height: ${height}px;
      z-index: 1;
      cursor: pointer;
    }
  `}
`;

const StyledControlGroup = styled(Group)`
  ${({ height }) => `
    min-height: ${height}px;
  `}
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
  margin-top: ${rem(20)};
`;

const StyledRemoveButton = styled(Button)`
  min-height: ${rem(20)};
  min-width: ${rem(20)};
`;

const StyledError = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
`;

const StyledTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${rem(10)};
  width: ${rem(300)};
  z-index: 999999;
`;

const StyledTooltip = styled(ReactTooltip)`
  border-radius: ${rem(5)} !important;
  padding: ${rem(5)} ${rem(10)} !important;
`;

const StyledToolTipText = styled(Text)`
  margin-bottom: ${rem(15)};
  width: 100%;
`;

const StyledAnimationError = styled(Animation)`
  cursor: help;
`;

const StyledToolTipAnimation = styled(Animation)`
  margin-bottom: ${rem(15)};
`;

/**
 * Returns a Upload avatar
 * @param {string} activeDashboardId
 * @param {string} currentImage
 * @param {string} id
 * @param {number} width
 * @param {number} height
 * @param {number} scale
 * @param {rotate} rotate
 * @param {position} position
 * @param {bool} rounded
 * @param {string} type
 * @param {number} padding
 * @param {func} onImageReady
 * @param {bool} hasButton
 * @param {bool} hasBackground
 * @param {string} buttonText
 * @param {string} fullWidth
 * @param {bool} hasRemoveButton
 */
const UploadAvatar = forwardRef((props, ref) => {
  const {
    activeDashboardId,
    currentImage,
    itemType,
    type,
    id,
    width,
    height,
    scale,
    rotate,
    position,
    rounded,
    padding,
    backgroundColor,
    onImageReady,
    onLoadSuccess,
    hasButton,
    hasBackground,
    buttonText,
    fullWidth,
    hasRemoveButton,
    ...other
  } = props;
  //Refs
  const animateRef = useRef(null);
  const editorRef = useRef(null);
  //UserId
  const uuid = firebase.auth().currentUser.uid;
  const profileImage = new Image();
  //States
  const [imageAvatar] = useState();
  const [animate, setAnimate] = useState(true);
  const [image, setImage] = useState(currentImage);
  const [rect, setRect] = useState();
  const [newScale, setNewScale] = useState(scale);
  const [newImage, setNewImage] = useState(currentImage);
  const [newRotate, setNewRotate] = useState(rotate);
  const [showError, setShowError] = useState(false);
  const [newPosition, setNewPosition] = useState(
    position === '' ? { x: 0, y: 0 } : position
  );
  const [borderRadius, setBorderRadius] = useState(0);
  const [emptyImage, setEmptyImage] = useState(false);
  const [master, setMaster] = useState();

  //Translation
  const [t] = useTranslation(['tooltip', 'textfield', 'button']);

  useEffect(() => {
    masterListner.child('dashboard').once('value', snapshot => {
      if (snapshot.exists()) {
        setMaster(snapshot.val().id);
      }
    });

    if (!image) {
      setEmptyImage(true);
      isString(position);
    }
  }, []);

  /** Returns if a value is a string */
  const isString = value => {
    if (typeof value === 'string' || value instanceof String) {
      setNewPosition({ x: 0, y: 0 });
    }
  };

  /** Handle Drop */
  const handleDrop = dropped => {
    setImage(dropped[0]);
  };

  /** Handle postion change */
  const handlePositionChange = data => {
    setNewPosition(data);
  };

  /** Remove Image */
  const handleRemove = data => {
    setEmptyImage(true);
  };

  /** Handle path */
  const handlePath = () => {
    //Check if master
    if (
      type === 'background' &&
      master &&
      master !== '' &&
      master === activeDashboardId
    ) {
      return 'master/backgroundimage/';
    }

    // if (type === 'dashboard' && master && master !== '') {
    //   return 'master/avatar/';
    // };

    if (type === 'avatar') return 'users/' + uuid + '/public/avatar/';
    if (type === 'background') {
      return (
        'userDashboards/' + uuid + '/' + activeDashboardId + '/backgroundimage/'
      );
    }
    if (type === 'overviewItem') return itemType + '/' + id + '/avatar/';
    if (type === 'dashboard') return 'dashboards/' + id + '/avatar/';
  };

  useImperativeHandle(ref, () => ({
    /** GenerateImage */
    generateImage() {
      if (!editorRef.current) return;
      const images = [];
      const croppedImage = editorRef.current.getImage().toDataURL();
      setRect(editorRef.current.getCroppingRect());

      if (image && croppedImage && !emptyImage) {
        images['imageData'] = [];
        images['handleData'] = [];

        images['imageData'].push({ image: image, type: 'original' });
        images['imageData'].push({ image: croppedImage, type: 'cropped' });
        images['handleData'].push({
          userId: uuid,
          scale: newScale ? newScale : scale,
          rotate: newRotate ? newRotate : rotate,
          position: newPosition,
        });
      }

      return images;
    },
  }));

  /** Remove image from storage */
  const removeStorageImage = dbPath => {
    firebase
      .database()
      .ref()
      .child(dbPath)
      .child('fileNames')
      .once('value', snap => {
        if (snap.val()) {
          snap.forEach(function(result) {
            firebase
              .storage()
              .ref()
              .child(dbPath)
              .child(result.val())
              .delete();
          });
        }
      });
  };

  /** GenerateImage */
  const generateImage = () => {
    const images = [];
    const croppedImage = editorRef.current.getImage().toDataURL();
    setRect(editorRef.current.getCroppingRect());

    if (image && croppedImage && !emptyImage) {
      images.push({ image: image, type: 'original' });
      images.push({ image: croppedImage, type: 'cropped' });
    }

    return images;
  };

  /** Save Image */
  const handleSave = data => {
    const images = generateImage();

    /** Hide the animation */
    function activateAnimation() {
      if (document.getElementById('succesUploadAvatar'))
        document.getElementById('succesUploadAvatar').style.display = 'block';
      setAnimate(false);
    }

    /** Hide the animation */
    function deActivateAnimation() {
      if (document.getElementById('succesUploadAvatar'))
        document.getElementById('succesUploadAvatar').style.display = 'none';
      setAnimate(true);
    }

    if (!images || images.length <= 0) {
      const update = {
        original: '',
        cropped: '',
        fileNames: {},
        userId: uuid,
        scale: 1,
        rotate: 0,
        position: { x: 0, y: 0 },
      };

      //Path to database
      const dbPath = handlePath();

      //Remove image from storage
      removeStorageImage(dbPath);

      firebase
        .database()
        .ref()
        .child(dbPath)
        .update(update);

      setNewScale(1);
      setNewRotate(0);
      setNewPosition({ x: 0, y: 0 });
    }

    if (images && images.length > 0) {
      const update = {
        userId: uuid,
        scale: newScale ? newScale : scale,
        rotate: newRotate ? newRotate : rotate,
        position: newPosition,
        fileNames: {},
      };
      //Path to database
      const dbPath = handlePath();

      //Remove image from storage
      removeStorageImage(dbPath);

      uploadEditorImages(images, type, update, dbPath);

      firebase
        .database()
        .ref()
        .child(dbPath)
        .update(update);

      setTimeout(() => activateAnimation(), 500);
      setTimeout(() => deActivateAnimation(), 3000);
    }
  };

  /** File to Base64 */
  const readFile = file => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    setImage(newImage);
    if (!currentImage) setNewScale(1);
    if (!currentImage) setNewRotate('0');
  }, [newImage]);

  /** Handle new image */
  const handleNewImage = async e => {
    setShowError(false);
    if (e && e.target && e.target.files && e.target.files.length > 0) {
      setNewImage(await readFile(e.target.files[0]));
      if (emptyImage) setEmptyImage(false);
    }
  };

  /** Handle Scale */
  const handleScale = e => {
    setNewScale(parseFloat(e.target.value));
  };

  /** Handle Rotate */
  const handleRotate = e => {
    setNewRotate(parseFloat(e.target.value));
  };

  /** handleLoadFailure */
  const handleLoadFailure = e => {
    if (e.type === 'error') {
      setShowError(true);
      handleRemove();
    }
  };

  //Set the crossOrigin attribute to anonymous.
  profileImage.crossOrigin = 'anonymous';

  //Get the user's image and set it as the src attribute.
  profileImage.src = currentImage;

  return (
    <StyledWrapper
      id={id}
      backgroundColor={backgroundColor}
      {...other}
      callback={generateImage}
      hasBackground={hasBackground}
    >
      <Group fullwidth={fullWidth ? true : false} justify='space-between'>
        <StyledEmptyWrapper
          width={width + padding}
          height={height + padding}
          emptyImage={emptyImage}
        >
          {showError && (
            <StyledError>
              <StyledAnimationError
                animation='alert'
                animationWidth={30}
                animationHeight={30}
                data-for='createDashboardImage'
                data-tip=''
              />
              <StyledTooltip
                id='createDashboardImage'
                place='left'
                getContent={() => {
                  return (
                    <StyledTooltipWrapper>
                      <StyledToolTipText
                        text={t('tooltip:errors.upload.title')}
                        textStyle='h6'
                        textColor='#fff'
                        align='center'
                      />
                      <StyledToolTipAnimation
                        animation='cry'
                        animationWidth={rem(70)}
                        animationHeight={rem(70)}
                      />
                      <Text
                        text={t('tooltip:errors.upload.text')}
                        textStyle='subtitle'
                        textColor='#fff'
                        align='center'
                      />
                    </StyledTooltipWrapper>
                  );
                }}
              />
            </StyledError>
          )}
          <input name='newImage' type='file' onChange={handleNewImage} />
          <StyledAnimationEmpty
            animation='imageEmpty'
            animationWidth='5rem'
            animationHeight='5rem'
            loop
            isStopped={emptyImage ? false : true}
          />
        </StyledEmptyWrapper>
        <StyledGroupAvatar justify='center' emptyImage={emptyImage}>
          {/* <Dropzone
            onDrop={handleDrop}
            disableClick
            style={{ width: width + 'px', height: height + 'px' }}
          > */}
          {image ? (
            <StyledAvatar
              ref={editorRef}
              id='sidebarAvatar'
              image={image ? image : currentImage}
              position={newPosition}
              onPositionChange={handlePositionChange}
              onLoadFailure={handleLoadFailure}
              width={width}
              height={height}
              border={10}
              color={[255, 255, 255, 0.6]}
              scale={newScale ? newScale : scale}
              rotate={newRotate ? parseInt(newRotate) : parseInt(rotate)}
              borderRadius={rounded ? 100 : 0}
              crossOrigin='anonymous'
              onImageReady={onImageReady}
              onLoadSuccess={onLoadSuccess}
            />
          ) : (
            <StyledAnimation
              animation='confetti'
              animationWidth='14rem'
              animationHeight='14rem'
              loop={false}
              isStopped={animate}
              id='succesUploadAvatar'
            />
          )}
          {/* </Dropzone> */}
          {hasRemoveButton && (
            <StyledButtonGroupAvatar
              padding={[{ top: rem(12), right: 0, bottom: rem(60), left: 0 }]}
              horAlign='center'
            >
              <StyledRemoveButton
                autoHeight
                variant='icon'
                bgColor={theme.color.danger}
                onClick={() => handleRemove()}
                icon={{
                  icon: 'close',
                  padding: rem(5),
                  size: 12,
                  color: '#fff',
                }}
              />
            </StyledButtonGroupAvatar>
          )}
        </StyledGroupAvatar>

        <StyledControlGroup
          fullwidth
          padding={[{ top: 0, right: 0, bottom: 0, left: rem(12) }]}
          height={height + padding}
        >
          {newScale && (
            <React.Fragment>
              <Slider
                name='scale'
                onChange={handleScale}
                min={0.1}
                max={2}
                step={0.01}
                value={newScale ? newScale : scale}
                orient='horizontal'
                label={t('textfield:uploadimage.scale.label')}
              />
              <Slider
                name='rotate'
                onChange={handleRotate}
                min={0}
                max={360}
                step={1}
                value={newRotate ? newRotate : rotate}
                orient='horizontal'
                label={t('textfield:uploadimage.rotate.label')}
              />
            </React.Fragment>
          )}

          {hasButton && (
            <StyledButton
              text={{
                text: buttonText ? buttonText : t('button:save.saveit'),
                textColor: '#fff',
              }}
              bgColor={theme.color.success}
              onClick={() => handleSave()}
            />
          )}
        </StyledControlGroup>
      </Group>
    </StyledWrapper>
  );
});

//Set displayname
UploadAvatar.displayName = 'UploadAvatar';

UploadAvatar.propTypes = {
  /** Current active dashboard Id */
  activeDashboardId: PropTypes.string,
  /** The url of the current image */
  currentImage: PropTypes.string,
  /** Custom Id */
  id: PropTypes.string,
  /** Element width */
  width: PropTypes.number,
  /** Element height */
  height: PropTypes.number,
  /** default scaled value */
  scale: PropTypes.number,
  /** default rotate value */
  rotate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Position of the image */
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Indicates if the cropper should be rounded */
  rounded: PropTypes.bool,
  /** Image type */
  type: PropTypes.string,
  /** Number of padding around the uploader */
  padding: PropTypes.number,
  /** Background color */
  backgroundColor: PropTypes.string,
  /** Type of the item refering to the widget type e.g. note, chat, whiteboard etc. */
  itemType: PropTypes.string,
  /** onImageReady */
  onImageReady: PropTypes.func,
  /** onLoadSuccess */
  onLoadSuccess: PropTypes.func,
  /** Showing the save button */
  hasButton: PropTypes.bool,
  /** Text on the save button */
  buttonText: PropTypes.string,
  /** Showing a backgring with padding */
  hasBackground: PropTypes.bool,
  /** Indicates that the controls should be under the upload image */
  fullWidth: PropTypes.bool,
  /** Having a remove image button or not */
  hasRemoveButton: PropTypes.bool,
};

UploadAvatar.defaultProps = {
  activeDashboardId: null,
  currentImage: null,
  id: null,
  width: null,
  height: null,
  scale: null,
  rotate: null,
  position: null,
  rounded: true,
  type: null,
  padding: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  itemType: null,
  onImageReady: null,
  onLoadSuccess: null,
  hasButton: true,
  hasBackground: true,
  buttonText: null,
  fullWidth: false,
  hasRemoveButton: true,
};

/** Get the image */
const getImage = () => {
  UploadAvatar.generateImage();
};

export { getImage };

export default UploadAvatar;
