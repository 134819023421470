import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
  //max-height: 100vh;
  overflow: hidden;
`;

const StyledContent = styled.section`
  position: relative;
  // min-height: 100vh;
  padding-top: 1rem;
`;

/**
 * Return a styled component
 * @param {object} child
 * @param {object | array} scrollIds
 */
const ScrollSpyContent = props => {
  const { children, scrollIds, ...other } = props;
  const spyRef = useRef();
  let scrollIdArray = scrollIds.split(' ');
  let list;

  useEffect(() => {
    if (spyRef.current) {
      list = spyRef.current.parentElement.parentElement.parentElement;
      list.addEventListener('scroll', handleScroll);
    }
  }, [spyRef.current]);

  /** Handle Scroll */
  const handleScroll = event => {
    var parentOffset = list.scrollTop;
    var children = spyRef.current.children;
    var array = [];
    for (var i = 0; i < children.length; i++) {
      if (children[i].tagName === 'SECTION') {
        if (children[i].offsetTop - parentOffset - 170 < 0) {
          let element = children[i].getAttribute('data-scrollid');

          if (element) {
            array.push(element);
          }
        } else if (list.scrollHeight - list.clientHeight - parentOffset === 0) {
          let element = children[children.length - 1].getAttribute(
            'data-scrollid'
          );

          if (element) {
            array.push(element);
          }
        }
      }
    }

    let tab = document.querySelectorAll(
      '[data-scrollto=' + array.pop() + ']'
    )[0];

    if (tab) {
      children = tab.parentNode.children;
      for (i = 0; i < children.length; i++) {
        children[i].classList.remove('active');
      }

      tab.classList.add('active');
    }
  };

  return (
    <StyledList
      id="js-SpyContent"
      ref={spyRef}
      onScroll={list && handleScroll}
      {...other}
    >
      {React.Children.map(children, (child, i) => {
        return (
          <StyledContent data-scrollid={scrollIdArray[i]}>
            {child}
          </StyledContent>
        );
      })}
    </StyledList>
  );
};

ScrollSpyContent.propTypes = {
  /** Can have all kind of child nodes */
  children: PropTypes.node,
  /** All the id's that are scrollable */
  scrollIds: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
};

ScrollSpyContent.defaultProps = {
  children: null,
  scrollIds: null,
};

/** @component */
export default ScrollSpyContent;
