/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Hits,
  VoiceSearch,
  connectSearchBox,
  connectStateResults,
  Configure,
} from 'react-instantsearch-dom';
import { ContextMenuTrigger } from 'react-contextmenu';
import theme, { rem } from '../../theme';
import Group from '../Group';
import TextField from '../TextField';
import Text from '../Text';
import CustomToast from '../CustomToast';
import Grid from '../Grid';
import EmptyState from '../EmptyState';
import ListItem from '../ListItem';
import Animation from '../LottieControl';
import firebase, {
  userListner,
  inviteUser,
  dashboardListner,
  userDashboardListner,
} from '../../firebase';
import ContextMenuItems from '../ContextMenuItems';

const StyledWrapper = styled.div`
  ${({ elementDimensions, backgroundColor, innerSearch }) => `
    background: ${backgroundColor};
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: ${innerSearch ? 0 : rem(20)};
  `}
  .ais-Hits-list {
    list-style: none;
    padding: 0;
  }
`;

const StyledGrid = styled(Grid)`
  margin-top: ${rem(32)};
`;

const StyledOptionSearch = styled(Group)`
  .ais-VoiceSearch-button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    padding-bottom: 0;
  }

  .ais-VoiceSearch-status {
    display: none;
  }
`;

const StyledGroup = styled(Group)`
  ${({ elementDimensions, innerSearch }) => `
    display: flex;
    position: ${innerSearch ? 'absolute' : 'fixed'};
    width: ${
      innerSearch ? '100%' : `calc(${elementDimensions.width} - ${rem(40)})`
    };
    z-index: 1;
  `}
`;

const StyledAnimationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background-color: transparent;
`;

const StyledAnimation = styled(Animation)`
  margin-bottom: ${rem(20)};
`;

/** Search bar in header */
const SearchBar = props => {
  const {
    showMenu,
    isActive,
    innerSearch,
    cleanSearch,
    elementDimensions,
    backgroundColor,
    onItemClick,
    setActive,
    activeDashboardId,
    hasButtons,
    friendInviteOnly,
    dashboardInviteOnly,
    id,
  } = props;
  const [input, setInput] = useState({});
  const [searchCardIsActive, setSearchCardIsActive] = useState(false);
  const [clickedPerson, setClickedPerson] = useState({});
  const [blocked, setBlocked] = useState({});
  const [declined, setDeclined] = useState({});
  const [friends, setFriends] = useState({});
  const [dashboard, setDashboard] = useState({});
  const [maxMembers, setMaxMembers] = useState();
  const [totalMembers, setTotalMembers] = useState();
  const [maxExceeded, setMaxExceeded] = useState(false);
  const [activeSearch, setActiveSearch] = useState(false);
  const [startSearch, setStartSearch] = useState(true);
  const [invitePermission, setInvitePermission] = useState();
  //const [searchData, setSearchData] = useState(null);

  //Refs
  const ref = useRef();
  const inputRef = useRef();
  const TargetPersonRef = useRef();

  //User Id
  const uuid = firebase.auth().currentUser.uid;

  const algoliaClient = algoliasearch(
    'L08MKR68QM',
    'bda8180e4b950de2e41d820c7b0c3475',
    {
      _useRequestCache: true,
    }
  );

  /** handleChange */
  const handleChange = (voice, refine, inputRef) => {
    if (voice) {
      refine(voice);
    } else {
      refine(inputRef.current.value);
    }
  };

  /** send invite to target user */
  const inviteHandler = (invite, type) => {
    const listner = userListner.child(uuid);
    let found = false;
    let extendedData = null;

    if (type === 'dashboards') {
      listner.once('value', snapshot => {
        extendedData = {
          dashboardId: snapshot.val().id,
          name: snapshot.val().name,
          image: snapshot.val().image,
        };
      });
    }

    //Check if the user is already invited
    listner
      .child('invites')
      .child(type)
      .child('invitesOut')
      .once('value', snapshot => {
        snapshot.forEach(function(snap) {
          if (snap.val().to === invite) {
            found = true;
          }
        });
        if (!found) {
          inviteUser(invite, type, activeDashboardId, extendedData);
        } else {
          userListner
            .child(invite)
            .child('public')
            .child('name')
            .once('value', snapshot => {
              CustomToast.error(
                'There is already an invite pending to ' + snapshot.val()
              );
            });
        }
      });
  };

  /** Custom Searchbox */
  const SearchBox = props => {
    const { currentRefinement, refine, isListening } = props;
    setTimeout(function() {
      if (inputRef.current) {
        inputRef.current.value = currentRefinement;
        inputRef.current.focus();
      }
    }, 0);

    if (
      document.getElementsByClassName('ais-VoiceSearch-status')[0] &&
      document.getElementsByClassName('ais-VoiceSearch-status')[0].firstChild
        .innerHTML !== ''
    ) {
      const voiceInput = document.getElementsByClassName(
        'ais-VoiceSearch-status'
      )[0].firstChild.innerHTML;
      handleChange(voiceInput, refine, inputRef);
      document.getElementsByClassName(
        'ais-VoiceSearch-status'
      )[0].firstChild.innerHTML = '';
    }
    //setSearchState(voiceSearchHelper.getState());
    // return the DOM output
    return (
      <StyledGroup
        innerSearch={innerSearch}
        elementDimensions={elementDimensions}
      >
        <TextField
          hasButtons={hasButtons}
          hasSinlgeButton
          name="message"
          ref={inputRef}
          inputId={'searchInput-' + id}
          backgroundcolor={theme.color.light[100]}
          borders={false}
          placeholder="Search and you will find..."
          extraOnchange={() => handleChange(false, refine, inputRef)}
          value={currentRefinement}
          height={45}
        />
        <StyledOptionSearch type="inputOption">
          {/* <Animation
            id="searchListing"
            animation="voice"
            animationHeight={rem(30)}
            animationWidth={rem(30)}
          /> */}
          <VoiceSearch />
        </StyledOptionSearch>
      </StyledGroup>
    );
  };

  // 2. Connect the component using the connector
  const CustomSearchBox = connectSearchBox(SearchBox);

  useEffect(() => {
    if (cleanSearch) {
      setInput({});
      if (ref.current) ref.current.value = '';
      props.cleanSearchCallback(false);
    }
  }, [cleanSearch]);

  useEffect(() => {
    if (!isActive) {
      setSearchCardIsActive(false);
    }
  }, [isActive]);

  useEffect(() => {
    if (activeDashboardId) {
      /** User permissions */
      userDashboardListner
        .child(uuid)
        .child(activeDashboardId)
        .child('permissions')
        .child('invite')
        .on('value', snap => {
          setInvitePermission(snap.val());
        });

      userListner
        .child(uuid)
        .child('users')
        .child('blocked')
        .once('value', snap => {
          if (snap.val()) setBlocked(snap.val());
        });

      userListner
        .child(uuid)
        .child('users')
        .child('declined')
        .once('value', snap => {
          if (snap.val()) setDeclined(snap.val());
        });

      userListner
        .child(uuid)
        .child('friends')
        .once('value', snap => {
          if (snap.val()) setFriends(snap.val());
        });

      dashboardListner
        .child(activeDashboardId)
        .child('members')
        .once('value', snap => {
          if (snap.val()) setDashboard(snap.val());
        });

      dashboardListner
        .child(activeDashboardId)
        .child('maxMembers')
        .on('value', snap => {
          if (snap.val()) {
            setMaxMembers(snap.val());
          }
        });

      dashboardListner
        .child(activeDashboardId)
        .child('totalMembers')
        .on('value', snap => {
          if (snap.val()) {
            setTotalMembers(snap.val());
          }
        });

      //Get the max and total member count and see if it exceeded
      dashboardListner
        .child(activeDashboardId)
        .child('totalMembers')
        .on('value', snapshot => {
          if (snapshot.val()) {
            dashboardListner
              .child(activeDashboardId)
              .child('maxMembers')
              .once('value', snap => {
                if (snapshot.val() >= snap.val()) {
                  setMaxExceeded(true);
                }
              });
            setTotalMembers(snapshot.val().totalItems);
          }
        });

      if (!activeSearch) setActiveSearch(true);
    }
  }, []);

  /** setTargetPersonHandler  */
  // const setTargetPersonHandler = targetPerson => {
  //   setClickedPerson({ id: targetPerson });
  // };

  /** Indicator handler */
  const indicatorHandler = id => {
    if (friends.hasOwnProperty(id)) return '/resources/friends.svg';
  };

  /** Hit results */
  const Hit = props => {
    const { hit } = props;
    return (
      dashboard &&
      declined &&
      friends &&
      blocked &&
      !declined.hasOwnProperty(hit.record.id) &&
      !blocked.hasOwnProperty(hit.record.id) &&
      hit.record.id !== uuid &&
      hit.record.public.name !== 'Bijlesnemer' && (
        <React.Fragment>
          <ContextMenuTrigger
            id={'searchUser-' + id}
            holdToDisplay={1}
            key={hit.record.public.name}
          >
            <ListItem
              isSearch
              active={setActive && setActive.hasOwnProperty(hit.record.id)}
              evenRadius
              onItemClick={
                onItemClick
                  ? onItemClick
                  : () => {
                      setClickedPerson(hit.record.id);
                    }
              }
              title={hit.record.public.name}
              avatar={{ source: hit.record.public.avatar.cropped }}
              subTitle={hit.record.public.status}
              key={hit.record.public.name}
              id={'item-' + hit.record.id}
              rightIcon={showMenu && { icon: 'more_vert', size: 24 }}
              extraIndicator={indicatorHandler(hit.record.id)}
              activeTabBackgroundColor={theme.color.primary[100]}
            />
          </ContextMenuTrigger>
        </React.Fragment>
      )
    );
  };

  const Results = connectStateResults(
    ({ searchState, searchResults, children }) => {
      if (
        searchResults &&
        searchResults.hits.length > 0 &&
        searchResults.nbHits !== 0
      ) {
        return children;
      } else if (
        searchResults &&
        searchResults.hits.length <= 0 &&
        typeof searchResults.nbHits === 'undefined'
      ) {
        return (
          <StyledAnimationWrapper>
            <StyledAnimation animation="funny" animationHeight={rem(150)} />
            <Text
              text="We are ready to search!"
              align="center"
              textColor="#fff"
              textStyle="h6"
            />
          </StyledAnimationWrapper>
        );
      } else {
        return (
          <StyledAnimationWrapper>
            <Animation animation="empty" animationHeight={rem(170)} />
            <Text
              text="No results have been found, so you are stuck with this whale again.."
              align="center"
              textColor="#fff"
              textStyle="h6"
            />
          </StyledAnimationWrapper>
        );
      }
    }
  );

  /** handleBgColor */
  const handleBgColor = total => {
    if (total >= maxMembers) {
      return theme.color.danger;
    }

    if (clickedPerson && dashboard.hasOwnProperty(clickedPerson)) {
      return theme.color.success;
    }

    return null;
  };

  /** Handle invite text */
  const handleInviteText = total => {
    if (total >= maxMembers) {
      return 'Dasboard is full';
    }

    if (clickedPerson && dashboard.hasOwnProperty(clickedPerson)) {
      return 'Already dashboard member';
    }

    return 'Send dashboard invite';
  };

  /** Handle invite text */
  const handleInviteIcon = total => {
    if (total >= maxMembers) {
      return 'block';
    }

    if (clickedPerson && dashboard.hasOwnProperty(clickedPerson)) {
      return 'check';
    }

    return 'add';
  };

  /** Handle context buttons */
  const handleItems = () => {
    const infoArray = [];
    let totalMembers;

    dashboardListner
      .child(activeDashboardId)
      .child('totalMembers')
      .once('value', snapshot => {
        totalMembers = snapshot.val();
      });

    {
      !dashboardInviteOnly &&
        infoArray.push({
          id: 'searchUserAlreadyFriend',
          icon:
            clickedPerson && !friends.hasOwnProperty(clickedPerson)
              ? 'add'
              : 'check',
          backgroundColor:
            clickedPerson && !friends.hasOwnProperty(clickedPerson)
              ? theme.color.dark[100]
              : theme.color.success,
          text:
            clickedPerson && !friends.hasOwnProperty(clickedPerson)
              ? 'Send friend invite'
              : 'Already your friend',
          click: () => {
            inviteHandler(clickedPerson, 'friends');
          },
          disabled:
            clickedPerson && !friends.hasOwnProperty(clickedPerson)
              ? false
              : true,
        });
    }

    !friendInviteOnly &&
    invitePermission &&
      infoArray.push({
        id: 'searchUserAdd',
        icon: handleInviteIcon(totalMembers),
        text: handleInviteText(totalMembers),
        click: () => {
          inviteHandler(clickedPerson, 'dashboards');
        },
        backgroundColor: handleBgColor(totalMembers),
        disabled:
          totalMembers >= maxExceeded &&
          clickedPerson &&
          !dashboard.hasOwnProperty(clickedPerson)
            ? false
            : true,
      });
    return infoArray;
  };

  /** searchClient */
  const searchClient = {
    /** Search */
    search(requests) {
      const shouldSearch = requests.some(
        ({ params: { query } }) => query !== ''
      );
      if (startSearch) setStartSearch(false);

      if (shouldSearch) {
        return algoliaClient.search(requests);
      } else {
        return Promise.resolve({
          results: [{ hits: [] }],
        });
      }
    },
    searchForFacetValues: algoliaClient.searchForFacetValues,
  };

  // /** controlled search input */
  // const handleInputChange = e => {
  //   setInput({
  //     ...input,
  //     [e.currentTarget.name]: e.currentTarget.value,
  //   });
  //   appbarSearch(...input, e.currentTarget.value);
  // };

  return activeDashboardId ? (
    <StyledWrapper
      innerSearch={innerSearch}
      elementDimensions={elementDimensions}
      backgroundColor={backgroundColor}
    >
      {/* <Text text="Search" textColor="#fff" align="center" textStyle="h6" /> */}
      <InstantSearch searchClient={searchClient} indexName="studentsplus_USERS">
        <CustomSearchBox
          autoFocus={false}
          defaultRefinement={
            inputRef && inputRef.current ? inputRef.current.value : null
          }
        />
        <Configure hitsPerPage={8} />
        <StyledGrid columns="1fr">
          <Results>
            <Hits hitComponent={Hit} />
          </Results>
        </StyledGrid>
      </InstantSearch>
      <ContextMenuItems id={'searchUser-' + id} data={handleItems()} />
    </StyledWrapper>
  ) : (
    <EmptyState
      hasButton={false}
      text="Can't find any friends around here.. blub.."
      textColor="#fff"
    />
  );
};

SearchBar.propTypes = {
  /** Custom background color */
  showMenu: PropTypes.bool,
  backgroundColor: PropTypes.string,
  setActive: PropTypes.oneOfType([PropTypes.object]),
  activeDashboardId: PropTypes.string,
  onItemClick: PropTypes.func,
  innerSearch: PropTypes.bool,
  id: PropTypes.string,
  friendInviteOnly: PropTypes.bool,
  dashboardInviteOnly: PropTypes.bool,
};

SearchBar.defaultProps = {
  showMenu: true,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  setActive: null,
  activeDashboardId: null,
  onItemClick: null,
  innerSearch: null,
  friendInviteOnly: null,
  dashboardInviteOnly: null,
  id: null,
};

export default SearchBar;
