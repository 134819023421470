/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';
import theme, { rem } from '../../theme';
import Icon from '../Icon';
import Image from '../Image';
import Button from '../Button';
import {
  listItemDropInfo,
  cardItemIsDragging,
  storeWidgetDelete,
} from '../../redux/actions';
import firebase, {
  getWidgetDetails,
  userDashboardListner,
  masterListner,
} from '../../firebase';

export const CardDetails = React.createContext(false);

const StyledDragHandler = styled.div`
  position: absolute;
  z-index: 2;
  bottom: -0.5rem;
  left: calc(50% - 0.6rem);
  right: 50%;
  margin: 0 auto;
  top: auto;
`;

const StyledButton = styled(Button)`
  position: absolute;
  z-index: 2;
  top: auto;
  bottom: 0;
  height: 10px;
  right: 1.6rem;
  min-height: 1.2rem;
`;

const StyledIcon = styled(Icon)`
  color: black;
  z-index: 2;
  cursor: grab;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  height: ${props => (props.height ? props.height : rem(250))};
  border: none;
  border-radius: ${rem(2)};
  box-shadow: ${`0 ${rem(1)} ${rem(3)} rgba(0, 0, 0, 0.15)`};
  ${theme.textStyle.body};
  margin-top: ${props => rem(2)};
  margin-bottom: ${rem(24)};
  margin-left: ${props => rem(2)};
  margin-right: ${props => rem(2)};
  border-top-left-radius: ${props => rem(props.borderRadius)};
  border-top-right-radius: ${props => rem(props.borderRadius)};
  border-bottom-left-radius: ${props => rem(props.borderRadius)};
  border-bottom-right-radius: ${props => rem(props.borderRadius / 4)};

  &:last-child {
    margin-bottom: 0;
  }

  .showHelp {
    transition: 0.3s;
    opacity: 0;
  }

  &:hover,
  &:focus {
    .showHelp {
      opacity: 1;
    }
  }
`;

const StyledOverlay = styled.div`
  transition: 0.35s;
  opacity: ${props =>
    props.isDragging === true && props.hoverState === true ? 1 : 0};
  visibility: ${props =>
    props.isDragging && props.hoverState === true ? 'visible' : 'hidden'};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${theme.color.dark[80]};
  z-index: 1;
`;

/**
 * @param {object} children
 * @param {string} type
 * @param {string} cardDraggedOnId
 */
const Card = props => {
  const {
    children,
    id,
    dragInfo,
    callback,
    activeDashboardId,
    cardDraggedOnId,
  } = props;
  const [activeCard] = useState();
  const [permissionDrag, setPermissionDrag] = useState();
  const [isMaster, setIsMaster] = useState(false);
  const cardRef = useRef();
  const isDragging = useSelector(state => state.isdragging.isDragging);
  const cardIsDragging = useSelector(
    state => state.cardisdragging.cardisDragging
  );

  //UserID
  const uuid = firebase.auth().currentUser.uid;

  useEffect(() => {
    if (activeDashboardId) {
      userDashboardListner
        .child(uuid)
        .child(activeDashboardId)
        .child('permissions')
        .child('drag')
        .on('value', snap => {
          if (snap.val()) setPermissionDrag(snap.val());
        });

      masterListner.child('members').once('value', snapMaster => {
        if (snapMaster.exists()) {
          snapMaster.forEach(function(snapMasterChild) {
            if (snapMasterChild.key === uuid) {
              setIsMaster(true);
            }
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!isDragging && callback) callback(false);
  }, [isDragging]);

  /** When the openFullscreen() function is executed, open the video in fullscreen.
   * Note that we must include prefixes for different browsers, as they don't support the requestFullscreen method yet
   */
  const openFullscreen = () => {
    /* Get the element you want displayed in fullscreen mode (a video in this example): */
    var elem = document.getElementById(id);
    if (
      document.fullscreenElement /* Standard syntax */ ||
      document.webkitFullscreenElement /* Safari and Opera syntax */ ||
      document.msFullscreenElement /* IE11 syntax */
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    } else {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    }
  };

  /** handleMouseEnterEvent */
  const handleMouseEnterEvent = () => {
    const { listItemDropInfo } = props;

    if (
      isDragging &&
      dragInfo &&
      dragInfo.cardId &&
      cardRef.current.id === dragInfo.cardId &&
      !cardIsDragging
    ) {
      //callback(false); @Todo still need this if?
    } else if (isDragging && cardRef.current.id && !cardIsDragging) {
      //Get the info of pivot
      const widgetData = getWidgetDetails(
        activeDashboardId,
        cardRef.current.id
      );

      if (widgetData) {
        //Get the current active item ID
        const activeItem = document.querySelector(
          '[id^="' + widgetData.type + '-overview-"]'
        );
        listItemDropInfo({
          id: cardRef.current.id,
          activeDashboardId: activeDashboardId,
          type: widgetData.type,
          pivot: widgetData.pivot,
          widgetId: widgetData.widgetId,
          activeItemId: activeItem
            ? activeItem.id.replace(widgetData.type + '-overview-', '')
            : null,
        });
      }
    }
  };

  /** handleMouseEvent */
  const handleMouseLeaveEvent = () => {
    if (
      isDragging &&
      dragInfo &&
      dragInfo.cardId &&
      cardRef.current.id === dragInfo.cardId
    ) {
      //callback(true); @todo still need this if?
    }
  };

  /** Handle mouse up */
  const handleMouseUp = () => {
    const { storeWidgetDelete, cardItemIsDragging } = props;
    storeWidgetDelete(false);
    cardItemIsDragging(false);
  };

  return (
    <StyledWrapper
      ref={cardRef}
      id={id}
      onMouseLeave={event => handleMouseLeaveEvent(event)}
      onMouseEnter={event => handleMouseEnterEvent(event)}
      isDragging={isDragging}
      dragInfo={dragInfo}
      activeCard={activeCard}
      cardDraggedOnId={cardDraggedOnId}
      {...props}
    >
      <StyledOverlay
        isDragging={isDragging}
        activeCard={activeCard}
        className='cardOverlay'
      />
      {children}
      <StyledButton
        className='editable fullscreenHandler'
        onClick={() => openFullscreen()}
      >
        <Icon icon='fullscreen' color='#000' />
      </StyledButton>
      {(permissionDrag || isMaster) && (
        <StyledDragHandler
          className='dragHandler'
          onMouseUp={() => handleMouseUp()}
        >
          <StyledIcon size={20} icon='drag_handle' />
        </StyledDragHandler>
      )}
    </StyledWrapper>
  );
};

Card.propTypes = {
  /** Card Id */
  id: PropTypes.string,

  /** Child elements */
  children: PropTypes.node,

  /** Type of droppable items that are allowed */
  droppableItems: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),

  /** dragInfo */
  dragInfo: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),

  /** cardDraggedOnId */
  cardDraggedOnId: PropTypes.bool,

  /** Border radius */
  borderRadius: PropTypes.number,
};

Card.defaultProps = {
  id: null,
  children: null,
  borderRadius: 30,
  droppableItems: null,
  dragInfo: null,
  cardDraggedOnId: false,
};

/** @component */
export default connect(null, {
  cardItemIsDragging,
  storeWidgetDelete,
  listItemDropInfo,
})(Card);
