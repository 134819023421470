import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledInputOption = `
  position: absolute;
  width: auto;
  right: 0;
`;

const StyledDefault = `
  position: relative;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.fullwidth ? 'column' : 'unset')};
  box-sizing: border-box;
  align-items: center;
  height: ${props => (props.fullHeight ? '100%' : null)};
  padding-top: ${props => (props.topPadding ? props.topPadding : 0)};
  padding-right: ${props => (props.rightPadding ? props.rightPadding : 0)};
  padding-bottom: ${props => (props.bottomPadding ? props.bottomPadding : 0)};
  padding-left: ${props => (props.leftPadding ? props.leftPadding : 0)};
  width: ${props => (props.autoWidth ? 'auto' : '100%')};
  ${props => props.type === 'inputOption' && StyledInputOption};
  ${props => props.type === 'normal' && StyledDefault};
`;

const StyledLeft = styled(StyledWrapper)`
  justify-content: ${props => (props.justify ? props.justify : 'flex-start')};
`;

const StyledCenter = styled(StyledWrapper)`
  justify-content: ${props => (props.justify ? props.justify : 'center')};
  align-items: center;
`;

const StyledRight = styled(StyledWrapper)`
  justify-content: ${props => (props.justify ? props.justify : 'flex-end')};
  align-items: flex-end;
`;

/**
 * Return a styled component
 * @param {node} children
 * @param {string} padding
 * @param {bool} mirrored
 * @param {string} type
 */
class Group extends React.Component {
  /**
   * Component check
   */
  componentCheck() {
    const {
      fullwidth,
      mirrored,
      fullHeight,
      justify,
      align,
      children,
      padding,
      type,
      autoWidth,
      ...props
    } = this.props;

    const components = {
      left: StyledLeft,
      center: StyledCenter,
      right: StyledRight,
    };

    const ComponentName = components[align || components.left];
    return (
      <ComponentName
        topPadding={padding && padding[0] && padding[0].top}
        rightPadding={padding && padding[0] && padding[0].right}
        bottomPadding={padding && padding[0] && padding[0].bottom}
        leftPadding={padding && padding[0] && padding[0].left}
        autoWidth={autoWidth}
        fullwidth={fullwidth}
        fullHeight={fullHeight}
        mirrored={mirrored}
        type={type}
        justify={justify}
        align={align}
        {...props}
      >
        {children}
      </ComponentName>
    );
  }

  /**
   * Return a badge component
   */
  render() {
    return this.componentCheck();
  }
}

Group.propTypes = {
  /** Children */
  children: PropTypes.node,

  /** Defines padding */
  padding: PropTypes.arrayOf(
    PropTypes.shape({
      top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),

  /** Autowidth */
  autoWidth: PropTypes.bool,

  /** Alignment */
  align: PropTypes.oneOf(['left', 'center', 'right']),

  /** Type */
  type: PropTypes.oneOf(['normal', 'inputOption']),

  /** Mirrored */
  mirrored: PropTypes.bool,

  /** FullWidth */
  fullwidth: PropTypes.bool,

  /** FullHeight */
  fullHeight: PropTypes.bool,

  /** Justify flex */
  justify: PropTypes.string,
};

Group.defaultProps = {
  children: null,
  padding: null,
  autoWidth: false,
  align: 'left',
  mirrored: false,
  fullwidth: false,
  fullHeight: false,
  justify: null,
  type: 'normal',
};

/** @component */
export default Group;
