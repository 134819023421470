import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Group from '../Group';
import InfoCard from '../InfoCard';
import Button from '../Button';
import Icon from '../Icon';
import TextField from '../TextField';
import theme, { rem } from '../../theme';
import { chatListner } from '../../firebase';

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
    height: 0;
    overflow: hidden;
  }
`;

const fadeOutTop = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeInTop = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const MixinFadeIn = css`
  animation: ${fadeIn} 0.4s ease;
  animation-fill-mode: forwards;
`;

const MixinFadeInTop = css`
  animation: ${fadeInTop} 0.4s ease;
  animation-fill-mode: forwards;
`;

const MixinFadeOut = css`
  animation: ${fadeOut} 0.4s ease;
  animation-fill-mode: forwards;
`;

const MixinFadeOutTop = css`
  animation: ${fadeOutTop} 0.4s ease;
  animation-fill-mode: forwards;
`;

const StyledGroup = styled(Group)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  height: 100%;

  &.animateUp {
    #mainHeader {
      ${MixinFadeOut};
    }

    #searchfield {
      height: 100%;
      ${MixinFadeIn}
      opacity: 0;

      > div {
        height: 100%;
        ${MixinFadeIn};
      }
    }
  }

  &.animateDown {
    #mainHeader {
      ${MixinFadeInTop};
    }

    #searchfield {
      height: 100%;
      ${MixinFadeOutTop};
      opacity: 1;
    }
  }
`;

const StyledButton = styled(Button)`
  padding: 0.5rem;

  &:focus {
    box-shadow: none;
  }
`;

const StyledSearchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const StyledTexFieldWrapper = styled.div`
  overflow: hidden;
  width: 100%;

  input {
    padding-right: 2.5rem;
  }

  > div {
    opacity: 0;
  }
`;

/**
 * MessageHeader
 */
const MessagesHeader = props => {
  const { handleSearchChange, currentChatId } = props;
  const searchRef = useRef();

  //States
  const [dataObject, setDataObject] = useState({});

  //check component did unmount
  useEffect(() => {
    setListners();
  }, []);

  //Translation
  const [t] = useTranslation(['textfield']);

  /** setListners */
  const setListners = () => {
    chatListner.child(currentChatId).once('value', snap => {
      const items = {};
      items['id'] = snap.val().id;
      items['title'] = snap.val().title;
      items['subTitle'] = snap.val().subTitle;
      items['image'] = snap.val().image;
      items['itemsCount'] = snap.val().itemsCount;
      setDataObject(items);
    });

    chatListner.child(currentChatId).on('child_changed', snap => {
      if (dataObject) {
        dataObject[snap.key] = snap.val();
        setDataObject(prevState => {
          return { ...prevState, ...dataObject };
        });
      }
    });
  };

  //Nofification trigger
  useEffect(() => {
    /** handleWidgets */
    if (searchRef && searchRef.current) {
      searchRef.current.value = '';
    }
  }, [dataObject]);

  /** handleSearchClick */
  const handleSearchClick = () => {
    const element = document.getElementById(dataObject.id + 'headerWrapper');
    if (element.classList.contains('animateUp')) {
      element.classList.remove('animateUp');
      element.classList.add('animateDown');
      handleSearchChange('');
      searchRef.current.value = '';
    } else {
      element.classList.remove('animateDown');
      element.classList.add('animateUp');
      // Set the focus
      if (searchRef.current) searchRef.current.focus();
    }
  };

  return (
    dataObject && (
      <StyledGroup
        id={dataObject.id + 'headerWrapper'}
        align='center'
        padding={[
          { top: rem(12), right: rem(12), bottom: rem(12), left: rem(12) },
        ]}
      >
        <StyledSearchWrapper id='mainHeader' className='editable'>
          <InfoCard
            title={dataObject.title}
            avatar={{
              source: dataObject.image
                ? dataObject.image
                : '/resources/astronaut.svg',
            }}
            data={dataObject}
            fullWidth={false}
          />
          <StyledButton variant='icon' onClick={() => handleSearchClick()}>
            <Icon icon='search' size={24} />
          </StyledButton>
        </StyledSearchWrapper>
        <StyledTexFieldWrapper id='searchfield' className='editable'>
          <Group>
            <TextField
              borders={false}
              extraOnchange={() =>
                handleSearchChange(
                  searchRef && searchRef.current ? searchRef.current.value : ''
                )
              }
              icon='search'
              iconColor={theme.color.dark[40]}
              placeholder={t('textfield:search.messages')}
              backgroundcolor={theme.color.dark[4]}
              textcolor={theme.color.dark[100]}
              onClick={() => {}}
              labelSpacing={rem(12)}
              ref={searchRef}
            />
            <Group type='inputOption'>
              <StyledButton variant='icon' onClick={() => handleSearchClick()}>
                <Icon
                  icon='close'
                  padding={rem(6)}
                  size={20}
                  color={theme.color.dark[40]}
                />
              </StyledButton>
            </Group>
          </Group>
        </StyledTexFieldWrapper>
      </StyledGroup>
    )
  );
};

MessagesHeader.propTypes = {
  handleSearchChange: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),

  /** Current active dashboard ID */
  activeDashboardId: PropTypes.string,

  /** Current active chat Id */
  currentChatId: PropTypes.string,
};

MessagesHeader.defaultProps = {
  handleSearchChange: [],
  activeDashboardId: null,
  currentChatId: null,
};

export default MessagesHeader;
