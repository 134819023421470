import React, { useState, useRef } from "react";
import { useSelector, connect } from "react-redux";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { modalType, modalOpen } from "../../redux/actions";
import Modal from "../Modal";
import Button from "../Button";
import EmptyState from "../EmptyState";
import theme, { rem } from "../../theme";
import Text from "../Text";
import TextField from "../TextField";
import Image from "../Image";
import Group from "../Group";
import firebase, { createGhostUser, registerUser } from "../../firebase";

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
  height: 100%;
  width: 100%;

  #tsparticles {
    width: 100%;
    height: 100%;
  }
`;

const StyledGroup = styled(Group)`
  margin-bottom: ${rem(10)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledText = styled(Text)`
  max-width: ${rem(800)};
  margin-bottom: ${rem(15)};
`;

const StyledIntroText = styled(Text)`
  font-weight: 600;
`;

const StyleFormWrapper = styled.div`
  display: flex;
  border-radius: ${rem(20)};
  background: #e7f6fd;
  min-height: ${rem(100)};
  min-width: ${rem(350)};
  padding: ${rem(30)};
`;

const StyledContentWrapper = styled(Group)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledErrorText = styled(Text)`
  background-color: salmon;
  color: darkred;
  padding: 8px;
  border-radius: 2px;
  border: 1px solid darkred;
  margin-top: 7px;
`;

const StyledSuccessText = styled(Text)`
  background-color: darkseagreen;
  color: darkgreen;
  padding: 8px;
  border-radius: 2px;
  border: darkgreen;
  margin-top: 7px;
`;

const StyledButton = styled(Button)`
  margin: ${rem(20)} 0 0 0;
`;

const StyledImage = styled(Image)`
  margin-bottom: ${rem(10)};
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

/**
 * Login module
 */
const Login = props => {
  const ModalOpen = useSelector(state => state.modalopen.modalOpen);
  //States
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  //Translation
  const [t] = useTranslation(["textfield", "text", "button", "common"]);

  //refs
  const emailRef = useRef();
  const passwordRef = useRef();

  //firebase auth
  const auth = firebase.auth();

  /**
   * login as ghost user
   */
  const loginAsGhostUser = () => {
    firebase
      .auth()
      .signInAnonymously()
      .then(function(result) {
        createGhostUser(result.user.uid);
      });
  };

  /**
   * On submit
   * @param {*} email
   * @param {*} password
   * @param {*} e
   */
  const onSubmit = (email, password, e) => {
    if (e) e.preventDefault();
    setErrorMessage("");
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        //console.log('auth succesfull');
      })
      .catch(function(error) {
        setErrorMessage(error.message);
      });
  };

  /**
   * Login handler
   */
  const loginHandler = (email, password, e) => {
    if (e) e.preventDefault();

    if (email && password) {
      if (password) {
        firebase
          .auth()
          .createUserWithEmailAndPassword(email, password)
          .then(createdUser => {
            if (createdUser) {
              registerUser(createdUser);
            }
            // createdUser.user
            //   .updateProfile({
            //     displayName: '',
            //   })
            //   .then(() => {
            //     registerUser(createdUser);
            //   });
          })
          .catch(function(error) {
            setErrorMessage(error.message);
          });
      } else {
        setErrorMessage(t("text:error.password.match"));
      }
    } else {
      setErrorMessage(t("text:error.fields.empty"));
    }
  };

  /**
   * render Login jsx
   */
  return (
    <StyledWrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:image" content="/resources/logo-1200x630.jpeg" />
        <meta property="og:title" content={t("common:main.slag")} />
        <meta property="og:description" content={t("common:main.secslag")} />
        <meta property="og:url" content={t("common:main.url")} />
        <title>Online Dashboard</title>
        <link rel="canonical" href={t("common:main.url")} />
      </Helmet>
      <Modal
        id="globalModal"
        isOpen={ModalOpen}
        closeModal={!ModalOpen}
        width={rem(400)}
        height="auto"
      />

      <StyleFormWrapper>
        <StyledContentWrapper fullwidth>
          <StyledImage src="/resources/mainlogo.webp" width={170} />
          <StyledIntroText
            text={t("text:login.text")}
            textColor="#0f3058"
            textStyle="description2"
            align="center"
          />
          <StyledForm
            onSubmit={e =>
              onSubmit(emailRef.current.value, passwordRef.current.value, e)
            }
          >
            <StyledGroup fullwidth>
              <Text
                align="left"
                text={t("textfield:email.label")}
                textStyle="description"
                textColor="#0f3058"
              />
              <TextField
                placeholdercolor="#fff"
                backgroundcolor={theme.color.dark[20]}
                isFocused
                borderRadius={2}
                textcolor="#fff"
                inputId="email"
                placeholder={t("textfield:email.placeholder")}
                borders={false}
                value=""
                ref={emailRef}
                type="email"
              />
            </StyledGroup>
            <StyledGroup fullwidth>
              <Text
                align="left"
                text={t("textfield:password.label")}
                textStyle="description"
                textColor="#0f3058"
              />
              <TextField
                placeholdercolor="#fff"
                backgroundcolor={theme.color.dark[20]}
                borderRadius={2}
                textcolor="#fff"
                inputId="password"
                placeholder={t("textfield:password.placeholder")}
                borders={false}
                value=""
                ref={passwordRef}
                type="password"
              />
            </StyledGroup>
            {errorMessage && (
              <StyledErrorText align="left" text={errorMessage} />
            )}
            {successMessage && (
              <StyledSuccessText align="left" text={successMessage} />
            )}
            <StyledButton
              fullWidth
              type="submit"
              text={{
                text: t("button:login.text"),
                textColor: "#fff",
                align: "center",
                textStyle: "overline",
              }}
              bgColor="#f95738"
              onClick={
                () => {
                  // console.log("click");
                }
                //onSubmit(emailRef.current.value, passwordRef.current.value)
              }
            />
          </StyledForm>
        </StyledContentWrapper>
      </StyleFormWrapper>
    </StyledWrapper>
  );
};

export default connect(null, {
  modalType,
  modalOpen,
})(Login);
