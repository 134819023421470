import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import Text from '../Text';
import { webStyles } from './Badge.styles';
import theme, { rem } from '../../theme';

const StyledText = styled(Text)`
  width: auto !important;
`;

const StyledBadge = styled.div`
  ${({ theme }) => webStyles.badge(theme, rem)}
  ${({ color }) => `
    background: ${color};
  `}
`;

const StyledStatus = styled(StyledBadge)`
  ${({ size, status }) => webStyles.status(rem, size, status)}
`;

const StyledToggle = styled(StyledStatus)`
  ${webStyles.toggle(rem)};
  ${({ isFilled, color }) => `
    background-color: ${isFilled ? color : '#fff'};
  `}
`;

const StyledTextBadge = styled(StyledBadge)`
  ${({ theme, color }) => webStyles.text(theme, rem, color)}
`;

const StyledIcon = styled(Icon)`
  ${({ theme }) => `
    margin-right: ${theme.spacing.small};
  `};
`;

const StyledWrapper = styled.div`
  display: inline-flex;
  justify-content: center;

  .badgetext {
    line-height: normal;
  }
`;

/**
 * Badge component
 * @param {array} extraProps
 * @param {node} children
 * @param {string} color
 * @param {string} variant
 * @param {bool} isFilled
 * @param {number} size
 * @param {string} status
 * @param {bool} mention
 * @param {string} text
 */
const Badge = props => {
  const {
    children,
    color,
    variant,
    isFilled,
    size,
    status,
    mention,
    text,
  } = props;
  const components = {
    status: StyledStatus,
    toggle: StyledToggle,
    text: StyledTextBadge,
    counter: StyledBadge,
  };
  const ComponentName = components[variant || components.counter];

  /** Create the online indicator */
  const handleIndicator = () => {
    switch (status) {
      default:
      case 'away':
        return theme.color.warning;
      case 'busy':
        return theme.color.danger;
      case 'sleeping':
        return theme.color.warning;
      case 'online':
        return theme.color.success;
      case 'offline':
        return '#d0d0d0';
    }
  };

  return (
    <StyledWrapper {...props}>
      {mention && (
        <StyledIcon
          size={10}
          isRounded
          background={theme.color.primary}
          color="#fff"
          iconName="ReadEmailAt"
          spacing={1}
        />
      )}
      {text !== 0 && text !== '0' && (
        <ComponentName
          color={color}
          variant={variant}
          isFilled={isFilled}
          size={size}
          status={handleIndicator()}
        >
          {text && (
            <StyledText
              text={text}
              textColor={theme.color.light[80]}
              textStyle="overline"
              className="badgetext"
            />
          )}
        </ComponentName>
      )}
    </StyledWrapper>
  );
};

Badge.propTypes = {
  /** Children */
  children: PropTypes.node,

  /** Background color */
  color: PropTypes.string,

  /** Filled badge */
  filled: PropTypes.bool,

  /** Badge variant */
  variant: PropTypes.string,

  /** Content */
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Badge.defaultProps = {
  children: null,
  color: '#e9725c',
  variant: 'counter',
  filled: false,
  text: null,
};

/** Display Name */
Badge.displayName = 'Badge';

/** @component */
export default Badge;
