import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';
import styled, { css, keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import Icon from '../Icon';
import ButtonGroup from '../ButtonGroup';
import ImageComp from '../Image';
import Text from '../Text';
import theme, { rem } from '../../theme';
import { notificationType } from '../../redux/actions';
import { propTypes, defaultProps } from './DropArea.props';
import firebase, {
  uploadSingleImage,
  setImageSettings,
  uploadSingleDocument,
} from '../../firebase';
import Lottie from '../LottieControl';
import getCroppedImg from './cropImage';

/**
 * Animations
 */
const bounce = keyframes`
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
`;

const animation = css`
  animation: ${bounce} 1s ease;
  animation-delay: 0s;
  animation-fill-mode: forwards;
`;

const StyledButton = styled(Button)`
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
  min-width: ${rem(20)};
  min-height: ${rem(20)};
`;

const StyledSendButton = styled(Button)`
  min-width: auto;
`;

const StyledThumbsContainer = styled.div`
  display: flex;
  position: fixed;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  bottom: ${rem(65)};
  right: ${rem(12)};
  width: auto;
  padding: ${rem(1)};
  background: rgba(255, 255, 255, 0.9);
`;

const StyledThumb = styled.div`
  display: inline-flex;
  position: relative;
  padding: ${rem(4)};
  box-sizing: border-box;
  animation-name: bounce;
  transform-origin: center bottom;

  &:last-child {
    margin-right: 0;
  }
`;

const StyledThumbInner = styled.div`
  ${animation}
  display: flex;
  min-width: 0;
  overflow: hidden;
  width: auto;
  max-width: ${rem(40)};
  height: ${rem(40)};
  background: #fff;
`;

const StyledImage = styled(ImageComp)`
  display: block;
  width: 100%;
  height: 100%;
`;

const StyledContainer = styled.div`
  //margin-bottom: ${rem(30)};
`;

const StyledGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: grey;
  height: ${rem(10)};
  width: ${rem(10)};
`;

const StyledGroupSingle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.color.dark[20]};
  height: auto;
  width: 100%;
  position: relative;
  cursor: pointer;
`;

const StyledGroupButton = styled.div`
  display: inline-flex;

  &.dropzone {
    -webkit-appearance: none;
  }
`;

const StyledSingleImage = styled(ImageComp)`
  display: flex;
  width: 100%;
  max-height: ${rem(300)};
`;

const StyledCropWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-height: ${rem(300)};
  min-height: ${rem(200)};
`;

const StyledButtonGroup = styled(ButtonGroup)`
  position: absolute;
  bottom: ${rem(5)};
  z-index: 10;
`;

const StyledWrapper = styled.div`
  width: 100%;
`;

const EmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 2px dashed rgb(151, 151, 151);
  height: ${rem(250)};
  padding: ${rem(20)};
  box-sizing: border-box;
`;

/**
 * DropArea component
 * @param {bool} preview - When true the component will show an preview
 * @param {string} currentId - Active item ID
 */
const DropArea = props => {
  const {
    type,
    subType,
    name,
    onFileSelect,
    newFiles,
    bgColor,
    iconColor,
    iconPadding,
    currentImage,
    iconSize,
    iconName,
    previewId,
    hasCrop,
    settings,
    isGallery,
    currentId,
  } = props;
  const [dbImage, setDbImage] = useState(currentImage);
  const [imageSrc, setImageSrc] = useState(dbImage);
  const [files, setFiles] = useState([]);
  const [emptyImages, setEmptyImages] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept:
      subType !== 'documents'
        ? 'image/*'
        : 'image/*, .pdf, .csv, .xlsx, .docx, .ppt',
    onDrop: acceptedFiles => {
      //console.log('acceptedFiles', acceptedFiles);
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  //Translation
  const [t] = useTranslation('text');

  const [crop, setCrop] = useState({
    x: settings && settings.x ? settings.x : 0,
    y: settings && settings.y ? settings.y : 0,
  });
  // const [cropSize, setCropSize] = useState({
  //   width: settings && settings.width ? settings.width : 0,
  //   height: settings && settings.heigth ? settings.heigth : 0,
  // });
  const [zoom, setZoom] = useState(1);
  const [initialCroppedAreaPixels, setInitialCroppedAreaPixels] = useState(
    undefined
  );
  const [typingTimeout, setTypingTimeout] = useState(0);
  /** On crop change */
  const onCropChange = crop => {
    setCrop(crop);
    clearTimeout(typingTimeout);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setTypingTimeout(
      setTimeout(function() {
        setImageSettings(subType, JSON.stringify(croppedAreaPixels));
      }, 2000)
    );
    showCroppedImage(croppedArea, croppedAreaPixels);
  }, []);

  /** showCroppedImage */
  const showCroppedImage = async (croppedArea, croppedAreaPixels) => {
    setImageSrc(
      await getCroppedImg(dbImage ? dbImage : imageSrc, croppedAreaPixels)
    );
  };

  /** Test */
  const getDataUri = (url, callback) => {
    var image = new Image();

    image.onload = function() {
      var canvas = document.createElement('canvas');
      canvas.width = image.naturalWidth; // or 'width' if you want a special/scaled size
      canvas.height = image.naturalHeight; // or 'height' if you want a special/scaled size

      canvas.getContext('2d').drawImage(this, 0, 0);

      // Get raw image data
      // callback(
      //   canvas
      //     .toDataURL('image/png')
      //     .replace(/^data:image\/(png|jpg);base64,/, '')
      // );

      // ... or get as Data URI
      callback(canvas.toDataURL('image/png'));
    };

    image.src = url;
  };

  /** Onclick handler */
  const onClickHandler = event => {
    if (event.target.dataset.testid) event.stopPropagation();
  };

  /** sending files back to parent */
  const handleFileChange = files => {
    setFiles([]);
    setEmptyImages(false);
    if (newFiles && newFiles.length <= 0) setFiles([]);
    if (onFileSelect) onFileSelect(files);

    switch (subType) {
      default:
        break;
      case 'backgroundimage': {
        document.getElementById('pageWrapper').classList.add('loading');
        //Get image from cache for better UX
        document.getElementById('cachedBgImage').style.backgroundImage =
          'url(' + files[0].preview + ')';

        setTimeout(() => {
          document.getElementById('pageWrapper').classList.remove('loading');
          document.getElementById('cachedBgImage').removeAttribute('style');
        }, 10000);

        //Create the Data URI because it is a blob url and we need a base64
        getDataUri(files[0].preview, function(dataUri) {
          // Do whatever you'd like with the Data URI!
          uploadSingleImage(
            dataUri,
            'users/' + firebase.auth().currentUser.uid + '/bgImage',
            firebase.auth().currentUser.uid,
            'backgroundImage',
            null,
            null
          );
          setDbImage(files[0].preview);
          setFiles([]);
        });
        break;
      }

      case 'documents': {
        const path = 'documents/' + currentId + '/document';
        if (files[0]['type'].includes('image')) {
          getDataUri(files[0].preview, function(dataUri) {
            uploadSingleImage(
              dataUri,
              path,
              firebase.auth().currentUser.uid,
              'document',
              null,
              null
            );
            setDbImage(files[0].preview);
            setFiles([]);
          });
        } else {
          uploadSingleDocument(files[0].name, path);
          setFiles([]);
        }

        break;
      }

      case 'headerimage': {
        //Create the Data URI because it is a blob url and we need a base64
        getDataUri(files[0].preview, function(dataUri) {
          // Do whatever you'd like with the Data URI!
          uploadSingleImage(
            dataUri,
            'users/' + firebase.auth().currentUser.uid + '/appbarbg',
            firebase.auth().currentUser.uid,
            'headerImage',
            null,
            null
          );
          setImageSrc(files[0].preview);
          setDbImage(files[0].preview);
          setFiles([]);
        });
      }
    }
  };

  /** Handles click on button to upload image */
  const handleUpload = () => {
    document.getElementById(previewId).click();
  };

  /** Handle empty images */
  const handleEmptyImages = () => {
    setEmptyImages(true);
  };

  /** Handles removing a image from cache or DB */
  const handleRemoveImage = () => {
    if (dbImage) {
      let name = 'removeBackgroundImage';
      let path = 'bgImage';

      if (subType === 'headerimage') {
        name = 'removeHeaderImage';
        path = 'appbarbg';
      }

      props.notificationType(
        JSON.stringify({
          name: name,
          image: currentImage ? currentImage : null,
          path: 'users/' + firebase.auth().currentUser.uid + '/' + path + '/',
        })
      );
    }
    //Check if there are files seleted
    if (files.length > 0) setFiles([]);
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    //Empty the user selection
    if (!currentImage) {
      // setFiles([]);
      // setDbImage([]);
      setDbImage(null);
    }
  }, [currentImage]);

  useEffect(() => {
    setInitialCroppedAreaPixels(settings);
  }, []);

  if (files.length > 0) handleFileChange(files);

  return (
    <StyledContainer className="container">
      {(() => {
        switch (type) {
          case 'button':
            return (
              <StyledGroupButton
                type={type}
                {...getRootProps({ className: 'dropzone' })}
              >
                <input {...getInputProps()} />
                <StyledSendButton
                  className="editable"
                  variant="icon"
                  bgColor={bgColor}
                  onClick={() => {}}
                >
                  <Icon
                    icon={iconName ? iconName : 'attach_file'}
                    padding={iconPadding ? iconPadding : rem(6)}
                    size={iconSize ? iconSize : 16}
                    color={iconColor ? iconColor : theme.color.dark[40]}
                  />
                </StyledSendButton>
              </StyledGroupButton>
            );
          case 'image':
            return (
              <StyledGroupSingle type={type}>
                <StyledWrapper
                  {...getRootProps({
                    className: 'dropzone',
                    onClick: event => onClickHandler(event),
                  })}
                >
                  <React.Fragment>
                    {files.length > 0 || dbImage ? (
                      hasCrop ? (
                        <StyledCropWrapper>
                          <input id={previewId} {...getInputProps()} />
                          <Cropper
                            image={dbImage ? dbImage : files[0].preview}
                            crop={crop}
                            zoom={zoom}
                            aspect={5 / 1}
                            onCropChange={onCropChange}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            initialCroppedAreaPixels={initialCroppedAreaPixels}
                            showGrid={false}
                            crossOrigin
                          />
                        </StyledCropWrapper>
                      ) : (
                        <React.Fragment>
                          <input id={previewId} {...getInputProps()} />
                          <StyledSingleImage
                            src={dbImage ? dbImage : files[0].preview}
                          />
                        </React.Fragment>
                      )
                    ) : (
                      <EmptyWrapper>
                        <input id={previewId} {...getInputProps()} />
                        <Lottie
                          animation="empty"
                          animationWidth={150}
                          animationHeight={150}
                        />
                        <Text
                          text={t('droparea', name)}
                          textColor="#fff"
                          textStyle="button"
                          align="center"
                        />
                      </EmptyWrapper>
                    )}
                  </React.Fragment>
                </StyledWrapper>
                {files.length > 0 || dbImage ? (
                  <StyledButtonGroup horAlign="center">
                    <Button
                      variant="icon"
                      onClick={() => handleRemoveImage()}
                      bgColor={theme.color.danger}
                    >
                      <Icon
                        color="#fff"
                        padding={rem(12)}
                        size={20}
                        icon="delete"
                      />
                    </Button>
                    <Button
                      variant="icon"
                      bgColor={theme.color.light[80]}
                      onClick={() => {
                        handleUpload();
                      }}
                    >
                      <Icon
                        color={theme.color.dark[80]}
                        padding={rem(12)}
                        size={20}
                        icon="image"
                      />
                    </Button>
                  </StyledButtonGroup>
                ) : null}
              </StyledGroupSingle>
            );
          case 'blue':
            return '#0000FF';
          default:
            return (
              <StyledGroup
                type={type}
                {...getRootProps({ className: 'dropzone' })}
              >
                <input {...getInputProps()} />
                <Button type="icon" iconName="MdCameraAlt" />
              </StyledGroup>
            );
        }
      })()}
      {!emptyImages && !isGallery && newFiles && newFiles.length > 0 ? (
        <StyledThumbsContainer id="thumbsContainer">
          <StyledButton
            className="editable"
            variant="icon"
            onClick={() => handleEmptyImages()}
            bgColor={theme.color.danger}
            icon={{ icon: 'clear', color: '#fff' }}
          />
          {newFiles.map(
            file =>
              file.preview && (
                <StyledThumb key={newFiles.name}>
                  <StyledThumbInner>
                    <StyledImage
                      src={file.preview}
                      alt="Preview"
                      width="100"
                      height="100"
                    />
                  </StyledThumbInner>
                </StyledThumb>
              )
          )}
        </StyledThumbsContainer>
      ) : null}
    </StyledContainer>
  );
};

DropArea.propTypes = propTypes;
DropArea.defaultProps = defaultProps;

/** @component */
export default connect(null, { notificationType })(DropArea);
