import { combineReducers } from 'redux';
import * as actionTypes from '../actionTypes';

const initialUserState = {
  currentUser: null,
  isLoading: true,
};

/**
 * user reducer for global state
 */
const user_reducer = (state = initialUserState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        currentUser: action.payload.currentUser,
        isLoading: false,
      };
    default:
      return state;
  }
};

const initialChannelState = {
  currentChannel: null,
};

/**
 * channel reducer for global state
 */
const channel_reducer = (state = initialChannelState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_CHANNEL:
      return {
        ...state,
        currentChannel: action.payload.currentChannel,
      };
    default:
      return state;
  }
};

const initialListState = {
  currentList: 0,
};

/**
 * list reducer for global state
 */
const list_reducer = (state = initialListState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_LIST:
      return {
        ...state,
        currentList: action.payload.currentList,
      };
    default:
      return state;
  }
};

const initialColorState = {
  headerColor: '#5F5198',
};

/**
 * color reducer for header color state
 */
const colors_reducer = (state = initialColorState, action) => {
  switch (action.type) {
    case actionTypes.SET_COLOR_PRIMARY:
      return {
        headerColor: action.payload.headerColor,
      };
    default:
      return state;
  }
};

const initialSidebarColor = {
  sidebarColor: '#0D1F2E',
};
/**
 * color reducer for sidebar color state
 */
const sidebar_colors_reducer = (state = initialSidebarColor, action) => {
  switch (action.type) {
    case actionTypes.SET_COLOR_SIDEBAR:
      return {
        sidebarColor: action.payload.sidebarColor,
      };
    default:
      return state;
  }
};
const initialBackgroundColor = {
  appBackgroundColor: '#FFFFFF',
};
/**
 * color reducer for background color state
 */
const background_colors_reducer = (state = initialBackgroundColor, action) => {
  switch (action.type) {
    case actionTypes.SET_COLOR_BACKGROUND:
      return {
        appBackgroundColor: action.payload.appBackgroundColor,
      };
    default:
      return state;
  }
};

const initialCardColor = {
  cardColor: '#FFFFFF',
};
/**
 * color reducer for sidebar color state
 */
const card_colors_reducer = (state = initialCardColor, action) => {
  switch (action.type) {
    case actionTypes.SET_COLOR_CARD:
      return {
        cardColor: action.payload.cardColor,
      };
    default:
      return state;
  }
};

const initalIconColor = {
  sidebarColor: '#FFFFFF',
};
/**
 * color reducer for icon color state
 */
const icon_colors_reducer = (state = initalIconColor, action) => {
  switch (action.type) {
    case actionTypes.SET_COLOR_ICON:
      return {
        iconColor: action.payload.iconColor,
      };
    default:
      return state;
  }
};

const initalListItemColor = {
  sidebarColor: '#FFFFFF',
};
/**
 * color reducer for icon color state
 */
const listitem_colors_reducer = (state = initalListItemColor, action) => {
  switch (action.type) {
    case actionTypes.SET_COLOR_LISTITEM:
      return {
        listItemColor: action.payload.listItemColor,
      };
    default:
      return state;
  }
};

const initalCheckboxColor = {
  sidebarColor: '#90EE90',
};
/**
 * color reducer for icon color state
 */
const checkbox_colors_reducer = (state = initalCheckboxColor, action) => {
  switch (action.type) {
    case actionTypes.SET_COLOR_CHECKBOX:
      return {
        checkboxColor: action.payload.checkboxColor,
      };
    default:
      return state;
  }
};

const initialDraggingState = {
  isDragging: false,
};

/**
 * isdragging reducer for global state
 */
const isdragging_reducer = (state = initialDraggingState, action) => {
  switch (action.type) {
    case actionTypes.LISTITEM_IS_DRAGGING:
      return {
        isDragging: action.payload.isDragging,
      };
    default:
      return state;
  }
};

const initialcardDraggingState = {
  cardisDragging: false,
};

/**
 * card isdragging reducer for global state
 */
const cardisdragging_reducer = (state = initialcardDraggingState, action) => {
  switch (action.type) {
    case actionTypes.CARDITEM_IS_DRAGGING:
      return {
        cardisDragging: action.payload.cardisDragging,
      };
    default:
      return state;
  }
};

//MODAL CONTENT
const initialModalContent = {
  modalContent: '',
};

/**
 * modalcontent reducer for global state
 */
const modalcontent_reducer = (state = initialModalContent, action) => {
  switch (action.type) {
    case actionTypes.MODAL_CONTENT:
      return {
        modalContent: action.payload.modalContent,
      };
    default:
      return state;
  }
};

//MODAL OPEN
const initialModalOpen = {
  modalOpen: false,
};

/**
 * modalopen_reducer reducer for global state
 */
const modalopen_reducer = (state = initialModalOpen, action) => {
  switch (action.type) {
    case actionTypes.MODAL_OPEN:
      return {
        modalOpen: action.payload.modalOpen,
      };
    default:
      return state;
  }
};

//MODAL TYPE
const initialModalType = {
  modalType: '',
};

/**
 * modaltype_reducer reducer for global state
 */
const modaltype_reducer = (state = initialModalType, action) => {
  switch (action.type) {
    case actionTypes.MODAL_TYPE:
      return {
        modalType: action.payload.modalType,
      };
    default:
      return state;
  }
};

//LIST UPDATED
const initialListUpdated = {
  listUpdated: Date.now(),
};

/**
 * listupdated_reducer reducer for global state
 */
const listupdated_reducer = (state = initialListUpdated, action) => {
  switch (action.type) {
    case actionTypes.LIST_UPDATED:
      return {
        listUpdated: action.payload.listUpdated,
      };
    default:
      return state;
  }
};

//USER SELECTION
const initialUserSelection = {
  userSelection: {},
};

/**
 * userselection_reducer reducer for global state
 */
const userselection_reducer = (state = initialUserSelection, action) => {
  switch (action.type) {
    case actionTypes.USER_SELECTION:
      return {
        userSelection: action.payload.userSelection,
      };
    default:
      return state;
  }
};

//CHECK IF THE USER CREATED A LIST
const initialCreatedList = {
  createdList: false,
};

/**
 * createdlist reducer for global state
 */
const createdlist_reducer = (state = initialCreatedList, action) => {
  switch (action.type) {
    case actionTypes.CREATED_LIST:
      return {
        createdList: action.payload.createdList,
      };
    default:
      return state;
  }
};

//CHECK IF THE USER SELECTED A LIST
const initialSelectedList = {
  selectedList: false,
};

/**
 * selectedlist reducer for global state
 */
const selectedlist_reducer = (state = initialSelectedList, action) => {
  switch (action.type) {
    case actionTypes.SELECTED_LIST:
      return {
        selectedList: action.payload.selectedList,
      };
    default:
      return state;
  }
};

//CHECK IF THE USER SELECTED A CHAT
const initialSelectedChat = {
  selectedChat: false,
};

/**
 * selectedchat reducer for global state
 */
const selectedchat_reducer = (state = initialSelectedChat, action) => {
  switch (action.type) {
    case actionTypes.SELECTED_CHAT:
      return {
        selectedChat: action.payload.selectedChat,
      };
    default:
      return state;
  }
};

//CHECK IF THE USER SELECTED A NOTE
const initialSelectedNote = {
  selectedNote: false,
};

/**
 * selectednote reducer for global state
 */
const selectednote_reducer = (state = initialSelectedNote, action) => {
  switch (action.type) {
    case actionTypes.SELECTED_NOTE:
      return {
        selectedNote: action.payload.selectedNote,
      };
    default:
      return state;
  }
};

//CHECK IF THE USER SELECTED A DRAWING
const initialSelectedDraw = {
  selectedDraw: false,
};

/**
 * selecteddraw reducer for global state
 */
const selecteddraw_reducer = (state = initialSelectedDraw, action) => {
  switch (action.type) {
    case actionTypes.SELECTED_DRAW:
      return {
        selectedDraw: action.payload.selectedDraw,
      };
    default:
      return state;
  }
};

//CHECK IF THE USER SELECTED A LIST
const initialNotificationType = {
  notificationType: null,
};

/**
 * notificationtype reducer for global state
 */
const notificationType_reducer = (state = initialNotificationType, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_TYPE:
      return {
        notificationType: action.payload.notificationType,
      };
    default:
      return state;
  }
};

//CHECK IF THE USER SELECTED A LIST
const initialListItemDragInfo = {
  listItemDragInfo: null,
};

/**
 * listItemDragInfo reducer for global state
 */
const listItemDragInfo_reducer = (state = initialListItemDragInfo, action) => {
  switch (action.type) {
    case actionTypes.LISTITEM_DRAG_INFO:
      return {
        listItemDragInfo: action.payload.listItemDragInfo,
      };
    default:
      return state;
  }
};

//Init state
const initialListItemDropInfo = {
  listItemDropInfo: {},
};

/**
 * listItemDragInfo reducer for global state
 */
const listItemDropInfo_reducer = (state = initialListItemDropInfo, action) => {
  switch (action.type) {
    case actionTypes.LISTITEM_DROP_INFO:
      return {
        listItemDropInfo: action.payload.listItemDropInfo,
      };
    default:
      return state;
  }
};

//Init state
const initialGlobalDragInfo = {
  globalDragInfo: {},
};

/**
 * dragInfo reducer for global state
 */
const globalDragInfo_reducer = (state = initialGlobalDragInfo, action) => {
  switch (action.type) {
    case actionTypes.GLOBAL_DRAG_INFO:
      return {
        globalDragInfo: action.payload.globalDragInfo,
      };
    default:
      return state;
  }
};

//Init state
const initialStoreWidgetInfo = {
  storeWidgetInfo: {},
};

/**
 * listItemDragInfo reducer for global state
 */
const storeWidgetInfo_reducer = (state = initialStoreWidgetInfo, action) => {
  switch (action.type) {
    case actionTypes.STORE_WIDGET_INFO:
      return {
        storeWidgetInfo: action.payload.storeWidgetInfo,
      };
    default:
      return state;
  }
};

//Init state
const initialStoreDragInfo = {
  storeWidgetDrag: false,
};

/**
 * listItemDragInfo reducer for global state
 */
const storeWidgetDrag_reducer = (state = initialStoreDragInfo, action) => {
  switch (action.type) {
    case actionTypes.STORE_WIDGET_DRAG:
      return {
        storeWidgetDrag: action.payload.storeWidgetDrag,
      };
    default:
      return state;
  }
};

//Init state
const initialStoreDeleteInfo = {
  storeWidgetDelete: false,
};

/**
 * listItemDragInfo reducer for global state
 */
const storeWidgetDelete_reducer = (state = initialStoreDeleteInfo, action) => {
  switch (action.type) {
    case actionTypes.STORE_WIDGET_DELETE:
      return {
        storeWidgetDelete: action.payload.storeWidgetDelete,
      };
    default:
      return state;
  }
};

//Init state
const initialActiveVideoLocation = {
  activeVideoLocation: null,
};

/**
 * listItemDragInfo reducer for global state
 */
const activeVideoLocation_reducer = (
  state = initialActiveVideoLocation,
  action
) => {
  switch (action.type) {
    case actionTypes.ACTIVE_VIDEO_LOCATION:
      return {
        activeVideoLocation: action.payload.activeVideoLocation,
      };
    default:
      return state;
  }
};

//Init state
const initialTransferMyVideo = {
  transferMyVideo: null,
};

/**
 * listItemDragInfo reducer for global state
 */
const transferMyVideo_reducer = (state = initialTransferMyVideo, action) => {
  switch (action.type) {
    case actionTypes.TRANSFER_MY_VIDEO:
      return {
        transferMyVideo: action.payload.transferMyVideo,
      };
    default:
      return state;
  }
};

//Init state
const initialChannelKey = {
  channelKey: null,
};

/**
 * listItemDragInfo reducer for global state
 */
const channelKey_reducer = (state = initialChannelKey, action) => {
  switch (action.type) {
    case actionTypes.CHANNEL_KEY:
      return {
        channelKey: action.payload.channelKey,
      };
    default:
      return state;
  }
};

//Init state
const initialTransferRemoteVideo = {
  transferRemoteVideo: null,
};

/**
 * listItemDragInfo reducer for global state
 */
const transferRemoteVideo_reducer = (
  state = initialTransferRemoteVideo,
  action
) => {
  switch (action.type) {
    case actionTypes.TRANSFER_REMOTE_VIDEO:
      return {
        transferRemoteVideo: action.payload.transferRemoteVideo,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  user: user_reducer,
  channel: channel_reducer,
  list: list_reducer,
  colors: colors_reducer,
  sidebarcolors: sidebar_colors_reducer,
  iconcolors: icon_colors_reducer,
  listitemcolors: listitem_colors_reducer,
  cardcolors: card_colors_reducer,
  backgroundcolors: background_colors_reducer,
  checkboxcolors: checkbox_colors_reducer,
  isdragging: isdragging_reducer,
  cardisdragging: cardisdragging_reducer,
  modalcontent: modalcontent_reducer,
  modalopen: modalopen_reducer,
  modaltype: modaltype_reducer,
  listupdated: listupdated_reducer,
  createdlist: createdlist_reducer,
  userselection: userselection_reducer,
  selectedlist: selectedlist_reducer,
  selectedchat: selectedchat_reducer,
  selectednote: selectednote_reducer,
  selecteddraw: selecteddraw_reducer,
  notificationtype: notificationType_reducer,
  listitemdraginfo: listItemDragInfo_reducer,
  listitemdropinfo: listItemDropInfo_reducer,
  globaldraginfo: globalDragInfo_reducer,
  storewidgetinfo: storeWidgetInfo_reducer,
  storewidgetdrag: storeWidgetDrag_reducer,
  storewidgetdelete: storeWidgetDelete_reducer,
  activevideolocation: activeVideoLocation_reducer,
  transfermyvideo: transferMyVideo_reducer,
  transferremotevideo: transferRemoteVideo_reducer,
  channelkey: channelKey_reducer,
});

export default rootReducer;
