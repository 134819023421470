import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import theme, { rem } from '../../theme';

const StyledContent = styled.main`
  padding-top: ${props => (props.padding ? props.padding : 0)};
  padding-right: ${props => (props.padding ? props.padding : 0)};
  padding-bottom: ${props => (props.padding ? props.padding : 0)};
  padding-left: ${props => (props.padding ? props.padding : 0)};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  background: transparent;
  box-sizing: border-box;
  ${theme.textStyle.body};

  .track-vertical {
    opacity: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius: ${rem(3)};
    visibility: hidden;
    position: absolute;
    width: ${rem(6)};
    transition: opacity 200ms ease 0s;
  }
`;

/**
 * @param {object} children
 * @param {bool} padding
 */
const CardContent = props => {
  const { children, scrollbar, padding } = props;

  /** trackV */
  const trackV = ({ style, padding, ...props }) => (
    <div className="track track-vertical" style={style} {...props} />
  );

  /** trackH */
  const trackH = ({ style, ...props }) => (
    <div className="track track-horizontal" style={style} {...props} />
  );

  return (
    <StyledContent scrollbar={scrollbar} padding={padding} {...props}>
      {scrollbar && (
        <Scrollbars
          hideTracksWhenNotNeeded
          universal
          autoHide
          className="scrollbar-tracks"
          renderTrackVertical={trackV}
          renderTrackHorizontal={trackH}
        >
          {children}
        </Scrollbars>
      )}

      {!scrollbar && children}
    </StyledContent>
  );
};

CardContent.propTypes = {
  /** Children */
  children: PropTypes.node,
  /** Set scrollbars */
  scrollbar: PropTypes.bool,
  /** Defines padding around the component */
  padding: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

CardContent.defaultProps = {
  children: null,
  scrollbar: true,
  padding: null,
};

/** @component */
export default CardContent;
