import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from '../ImageGallery';

/** todo */
const galleryContent = props => {
  const { images, itemId, typeId } = props;

  /** Return Chat messages */
  return (
    <ImageGallery
      images={images && images}
      itemId={itemId}
      typeId={typeId}
      table='lists'
      itemTypes='items'
    />
  );
};

galleryContent.propTypes = {
  /** Current itemId */
  itemId: PropTypes.string,
  /** The current list id */
  typeId: PropTypes.string,
  /** The List data from Firebase */
  images: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
  ]),
};

galleryContent.defaultProps = {
  itemId: null,
  typeId: null,
  images: null,
};

export default galleryContent;
