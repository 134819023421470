import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import firebase, {
  userListner,
  updateSubTitle,
  userDashboardListner,
} from '../../firebase';
import { rem } from '../../theme';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
`;

const StyledLabel = styled.label`
  ${({ color, initials }) => `
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    position: relative;
    width: ${rem(35)};
    min-width: ${rem(35)};
    height: ${rem(35)};
    margin-right: ${rem(5)};

    &:hover input ~ .checkmark {
      background-color: #ccc;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark {
        background-color: ${color};

        &:after {
          display: flex;
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 30px;
      width: 30px;
      background-color: #eee;

      &:after {
        content: ${initials ? `'${initials}'` : `'AV'`};
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        display: none;
        width: 100%;
        height: 100%;
      }
    }
  `}
`;

const StyledSpan = styled.span``;

/**
 * Return a styled component
 * @param {bool} checked
 * @param {object} props
 * @param {string} size
 * @param {string} initials
 * @param {string} type
 * @param {string} itemId
 * @param {string} typeId
 * @param {string} activeDashboardId
 */

/**
 * @class Checkbox
 */
const Checkbox = props => {
  const {
    listData,
    typeId,
    isChecked,
    isDisabled,
    name,
    type,
    value,
    itemId,
    activeDashboardId,
  } = props;

  const uuid = firebase.auth().currentUser.uid;
  const [checked, setChecked] = useState(isChecked);
  const [dashboardId] = useState(activeDashboardId);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    settingsListner();
  }, []);

  /** settingsListner */
  const settingsListner = () => {
    const listner = firebase
      .database()
      .ref()
      .child(type)
      .child(typeId)
      .child('items')
      .child(itemId);

    listner.child('checked').on('value', snap => {
      setChecked(snap.val());
    });

    Object.keys(listData.attendees).forEach(user => {
      userData[user] = {};
      userListner
        .child(user)
        .child('public')
        .child('name')
        .on('value', snap => {
          userData[user]['initials'] = getInitials(snap.val());

          setUserData(prevState => {
            // Object.assign would also work
            return { ...prevState, ...userData };
          });
        });

      userListner
        .child(user)
        .child('public')
        .child('color')
        .on('value', snap => {
          if (snap.exists()) {
            userData[user]['color'] = snap.val();
          } else {
            userDashboardListner
              .child(user)
              .child(dashboardId)
              .child('public')
              .child('colors')
              .child('checkboxColor')
              .on('value', snapDashboard => {
                userData[user]['color'] = snapDashboard.val();

                setUserData(prevState => {
                  // Object.assign would also work
                  return { ...prevState, ...userData };
                });
              });
          }
        });
    });

    setUserData(prevState => {
      // Object.assign would also work
      return { ...prevState, ...userData };
    });
  };

  /** Get initials */
  const getInitials = name => {
    let initials = 'S';
    if (name) {
      initials = name.match(/\b\w/g) || [];
      initials = (
        (initials.shift() || '') + (initials.pop() || '')
      ).toUpperCase();
    }
    return initials;
  };

  /** On Click Handler */
  const onClickHandler = () => {
    const listner = firebase
      .database()
      .ref()
      .child(type)
      .child(typeId)
      .child('items')
      .child(itemId);

    listner
      .update({
        checked: !checked,
        lastEditedBy: uuid,
        changedBy: uuid,
        time: Date.now(),
        update: Date.now(),
      })
      .then(() => {
        listner.once('value', snapshot => {
          let value = snapshot.val().text;
          let newChecked = snapshot.val().checked;
          setChecked(newChecked);
          if (newChecked) {
            value = '✅ ' + value;
          } else {
            value = '⬜ ' + value;
          }
          updateSubTitle(type, typeId, value);
        });
      });
  };

  /** Handle the color of the checkbox */
  const handleColor = () => {
    let color = '';

    firebase
      .database()
      .ref()
      .child(type)
      .child(typeId)
      .child('items')
      .child(itemId)
      .once('value', snap => {
        if (snap.val() && userData[snap.val().lastEditedBy]) {
          color = userData[snap.val().lastEditedBy]['color'];
        }
      });
    return color;
  };

  /** Initials Handler */
  const handleInitial = () => {
    let lastEdited = '';
    firebase
      .database()
      .ref()
      .child(type)
      .child(typeId)
      .child('items')
      .child(itemId)
      .once('value', snap => {
        if (
          snap.val() &&
          userData[snap.val().lastEditedBy] &&
          Object.keys(userData).length > 0
        ) {
          lastEdited = userData[snap.val().lastEditedBy]['initials'];
        }
      });

    // if (itemData.lastEditedBy === uuid) return userData.initials;
    return lastEdited;
  };

  return (
    Object.keys(userData).length > 0 && (
      <StyledLabel color={handleColor()} initials={handleInitial()}>
        <HiddenCheckbox
          checked={checked}
          isChecked={checked}
          name={name}
          value={value}
          disabled={isDisabled}
          onClick={onClickHandler}
          readOnly
        />

        <StyledSpan className='checkmark' />
      </StyledLabel>
    )
  );
};

Checkbox.propTypes = {
  /** Set the checkbox as active */
  isChecked: PropTypes.bool,

  /** Disable the checkbox */
  isDisabled: PropTypes.bool,

  /** Set the name of the checkbox, checkboxes with the same name belong to the same group */
  name: PropTypes.string.isRequired,

  /** Set the value of the checkbox */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,

  /** Type where the value of the checkbox is located */
  type: PropTypes.string,

  /** Item id */
  itemId: PropTypes.string,

  /** Type Id */
  typeId: PropTypes.string,

  /** Active dashboard Id */
  activeDashboardId: PropTypes.string,
};

Checkbox.defaultProps = {
  isChecked: false,
  isDisabled: false,
  type: null,
  itemId: null,
  typeId: null,
  activeDashboardId: null,
};

Checkbox.displayName = 'Checkbox';

/** @component */
export default Checkbox;
