/* eslint-disable react/no-find-dom-node */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { modalOpen, modalType } from '../../redux/actions';
import { useTranslation } from 'react-i18next';
import Group from '../Group';
import TextField from '../TextField';
import Button from '../Button';
import Image from '../Image';
import Text from '../Text';
import Lottie from '../LottieControl';
import { userListner, feedbackHandler } from '../../firebase';
import theme, { rem } from '../../theme';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledTextIntro = styled(Text)`
  margin-bottom: ${rem(20)};
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(10)};
`;

const StyledGroup = styled(Group)`
  flex-direction: column;
  margin-bottom: ${rem(10)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledOptionGroup = styled(Group)`
  button {
    font-weight: bold;
    text-transform: uppercase;
    margin-right: ${rem(5)};
    transition: 0.3s;
    width: calc(100% / 3);

    div {
      line-height: normal;
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background: ${theme.color.success};
    }
  }
`;

const StyledContentWrapper = styled(Group)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

const StyledButtonGroup = styled(Group)`
  margin-top: ${rem(20)};

  button {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: ${rem(20)};
    width: ${rem(350)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledLottie = styled(Lottie)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const StyledSubmitWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
`;

/** todo */
const feedback = props => {
  const { activeDashboardId, id, closeModals } = props;

  //States
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [finished, setFinished] = useState(false);
  const [userData, setUserData] = useState({});
  const [activeType, setActiveType] = useState('general');

  //Reft
  const messageRef = useRef();
  const typeRef = useRef();

  //Translation
  const [t] = useTranslation(['common', 'modal', 'textfield', 'button']);

  useEffect(() => {
    if (id) {
      getData();
    }
    document.getElementById('globalModal').firstElementChild.style.height =
      '35rem';
    document.getElementById('globalModal').firstElementChild.style.width =
      '50rem';
  }, []);

  /** Get all the dashboard data */
  const getData = () => {
    userListner
      .child(id)
      .child('public')
      .child('name')
      .once('value', snapshot => {
        if (snapshot.val()) {
          setUserData(snapshot.val());
          setLoading(false);
        } else {
          setUserData(t('common:tutor'));
          setLoading(false);
        }
      });
  };

  /**
   * Change language
   * @param {string} lang
   */
  const handleClickType = type => {
    if (!typeRef.current) return;
    setActiveType(type);
  };

  /**
   * Handle the user feedback input
   */
  const handleFeedback = () => {
    if (messageRef.current)
      feedbackHandler(activeDashboardId, activeType, messageRef.current.value);
    setFinished(true);

    setTimeout(function() {
      closeModals();
    }, 2500);
  };

  /**
   * Handle on change
   */
  const handleChange = () => {
    if (messageRef.current && messageRef.current.value !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  /** Return Chat messages */
  return loading ? (
    <StyledLottie animation='loading' animationWidth={400} />
  ) : (
    <StyledWrapper>
      <StyledContentWrapper fullwidth>
        {!finished ? (
          <React.Fragment>
            <StyledText
              align='center'
              text={t('modal:feedback.title')}
              textColor='#fff'
              textStyle='h3'
              tag='h1'
            />

            <StyledTextIntro
              align='center'
              text={t('modal:feedback.text')}
              textColor='#fff'
              textStyle='h6'
              tag='p'
            />
            <StyledGroup fullwidth>
              <Text
                align='left'
                text={t('textfield:feedback.type.label')}
                textStyle='description'
                textColor='#fff'
              />
              <StyledOptionGroup ref={typeRef}>
                <Button
                  variant='contained'
                  className={activeType === 'general' ? 'active' : ''}
                  onClick={() => handleClickType('general')}
                  bgColor={theme.color.dark[40]}
                  text={{
                    text: t('button:feedback.general'),
                    textColor: '#fff',
                    inline: true,
                  }}
                  icon={{
                    icon: 'report',
                    color: '#fff',
                    size: 24,
                  }}
                />
                <Button
                  variant='contained'
                  className={activeType === 'bug' ? 'active' : ''}
                  onClick={() => handleClickType('bug')}
                  bgColor={theme.color.dark[40]}
                  text={{
                    text: t('button:feedback.bug'),
                    textColor: '#fff',
                    inline: true,
                  }}
                  icon={{
                    icon: 'bug_report',
                    color: '#fff',
                    size: 24,
                  }}
                />
                <Button
                  variant='contained'
                  className={activeType === 'idea' ? 'active' : ''}
                  onClick={() => handleClickType('idea')}
                  bgColor={theme.color.dark[40]}
                  text={{
                    text: t('button:feedback.idea'),
                    textColor: '#fff',
                    inline: true,
                  }}
                  icon={{
                    icon: 'highlight',
                    color: '#fff',
                    size: 24,
                  }}
                />
              </StyledOptionGroup>
            </StyledGroup>

            <StyledGroup fullwidth>
              <Text
                align='left'
                text={t('textfield:feedback.message.label')}
                textStyle='description'
                textColor='#fff'
              />
              <TextField
                extraOnchange={() => handleChange()}
                placeholdercolor='#fff'
                backgroundcolor={theme.color.dark[40]}
                isFocused
                textcolor='#fff'
                inputId='modalFeedbackText'
                placeholder={t('textfield:feedback.message.placeholder')}
                borders={false}
                value=''
                ref={messageRef}
                maxHeight={rem(150)}
                height={rem(150)}
                isTextArea
                overflowType
              />
            </StyledGroup>

            <StyledButtonGroup fullwidth>
              <Button
                variant='contained'
                onClick={() => {
                  if (activeDashboardId) handleFeedback();
                }}
                disabled={disabled}
                bgColor={theme.color.success}
                text={{
                  align: 'center',
                  text: t('button:send.send'),
                  textColor: '#fff',
                }}
              />
              <Button
                variant='contained'
                text={{
                  align: 'center',
                  text: t('button:cancel.cancel'),
                  textColor: '#fff',
                }}
                onClick={() => closeModals()}
                bgColor={theme.color.cancel}
              />
            </StyledButtonGroup>
          </React.Fragment>
        ) : (
          <StyledSubmitWrapper>
            <StyledText
              align='center'
              text={t('modal:feedback.thankyou')}
              textColor='#fff'
              textStyle='h3'
              tag='h1'
            />
            <StyledLottie animation='starsuccess' animationWidth={400} />
          </StyledSubmitWrapper>
        )}
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

feedback.propTypes = {
  /** Active dashboard Id */
  activeDashboardId: PropTypes.string,
  /** User Id */
  id: PropTypes.string,
  /** Close function for the modal */
  closeModals: PropTypes.func,
};

feedback.defaultProps = {
  activeDashboardId: null,
  id: null,
  closeModals: null,
};

export default connect(null, {
  modalType,
  modalOpen,
})(feedback);
