import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { modalType, modalOpen } from '../../redux/actions';
import PropTypes from 'prop-types';
import Group from '../Group';
import Button from '../Button';
import Badge from '../Badge';
import Avatar from '../Avatar';
import Image from '../Image';
import Grid from '../Grid';
import Text from '../Text';
import Chip from '../Chip';
import Lottie from '../LottieControl';
import firebase, {
  addNotification,
  dashboardListner,
  userDashboardListner,
  userListner,
  handleUserPermissions,
} from '../../firebase';
import CustomToast from '../CustomToast';
import theme, { rem } from '../../theme';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(10)};
`;

const StyledImage = styled(Image)`
  margin-bottom: ${rem(20)};
  width: 100%;
  height: ${rem(250)};
`;

const StyledContentWrapper = styled(Group)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledButtonGroup = styled(Group)`
  justify-content: flex-end;

  button {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: ${rem(20)};
    width: ${rem(350)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledAvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${rem(20)};
`;

const StyledRoleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`;

const StyledCard = styled.div`
    display: flex;
    height: ${rem(150)};
    justify-content: left;
    width: 100%;
    align-items: center;
    flex-direction: column;
    padding: ${rem(20)};
    background: rgba(0, 0, 0, 0.3);
    border-radius: ${rem(10)};
    margin-bottom: ${rem(15)};
    margin-top: ${rem(20)};
`;

const StyledBadgeWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  min-height: ${rem(30)};
`;

const StyledOptionsWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

const StyledEmptyText = styled(Text)`
  font-style: italic;
  opacity: 0.5;
`;

const StyledButton = styled(Button)`
  min-width: ${rem(30)};
  min-height: ${rem(30)};
  height: ${rem(20)};
  margin-bottom: ${rem(8)};
`;

const StyledRemoveUserButton = styled(Button)`
  margin: 0 0 ${rem(30)};
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledChip = styled(Chip)`
  margin-bottom: ${rem(8)};
`;

const GridWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const StyledEmptyRoleText = styled(Text)`
  width: auto;
  margin-right: ${rem(10)};
  font-style: italic;
`;

const StyledDashboardGroup = styled(Group)`
  margin-top: ${rem(20)};
`;

/** todo */
const editRole = props => {
  const { userId, activeDashboardId, closeModals } = props;

  //The current userId
  let uuid = userId;

  //States
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [dashboardRoleData, setDashboardRoleData] = useState({});
  const [userDashboardRoleData, setUserDashboardRoleData] = useState({});
  const [roleDataLog, setRoleDataLog] = useState({});
  const [totalRoles, setTotalRoles] = useState();
  const [maxRoles, setMaxRoles] = useState();
  const [maxExceeded, setMaxExceeded] = useState(false);

  useEffect(() => {
    if (uuid) getDashboardData();

    document.getElementById('globalModal').firstElementChild.style.height =
    'auto';
    document.getElementById('globalModal').firstElementChild.style.width =
      '27rem';
  }, []);

  /** Get all the dashboard data */
  const getDashboardData = () => {
    let dataObject = {};
    dashboardListner.child(activeDashboardId).once('value', snap => {
      if (snap.val()) {
        setMaxRoles(snap.val().maxRoles);
      }
    })
    .then(parentData => {
      userDashboardListner
        .child(uuid)
        .child(activeDashboardId)
        .child('role')
        .child('type')
        .once('value', snapusershot => {
          if (snapusershot.val()) {
            setUserDashboardRoleData(snapusershot.val());
            setTotalRoles(Object.keys(snapusershot.val()).length);
          } else {
            setTotalRoles('0');
          }
        })
        .then(() => {
          dashboardListner
            .child(activeDashboardId)
            .child('roles')
            .once('value', snapshot => {
              if(snapshot.val()) {
                if (Object.keys(snapshot.val()).length >= (maxRoles ? maxRoles : parentData.val().maxRoles)) {
                  setMaxExceeded(true);
                } else {
                  setMaxExceeded(false);
                }
              }
              dataObject = snapshot.val();
            })
            .then(() => {
              setDashboardRoleData(dataObject);
            })
            .then(() => {
              userListner
                .child(uuid)
                .child('public')
                .once('value', snap => {
                  if (snap.val()) {
                    setUserData(snap.val());
                    setLoading(false);
                  }
                })
            });
        });
    }); 
  };

  /** Handle the applied changes */
  const handleChange = () => {
    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('role')
      .child('type')
      .set(
        userDashboardRoleData
      )
      .then(() => {
        Object.entries(roleDataLog).map(role => {
          dashboardListner
            .child(activeDashboardId)
            .child('roles')
            .child(role[0])
            .child('members')
            .update({ [uuid]: role[1].action === 'add' ? true : null })
            .then(() => {
              //HANDLE PERMISSIONS
              handleUserPermissions(activeDashboardId, uuid);
            });
        });
      })
      .then(() => {
        //SEND NOTIFICATION
        let text = 'Your role has been changed to ';
        let roles = '';
        const length = Object.keys(userDashboardRoleData).length;
        if (Object.keys(userDashboardRoleData).length === 1) {
          Object.keys(userDashboardRoleData).map(role => {
            text = 'Your role has been changed to ' + role;
          });
        }
        else if (Object.keys(userDashboardRoleData).length <= 0) {
          Object.keys(roleDataLog).map(role => {
            text = 'Your role as ' + role + ' has been removed';
          });
        } else if (Object.keys(userDashboardRoleData).length === 2) {
          Object.keys(userDashboardRoleData).map((role, index) => {
            if (length - 1 === index) {
              roles = roles + role;
            } else {
              roles = roles + role + ' and ';
            }
          });
          text = 'Your role has been changed to ' + roles;
        } else {
          text = 'You have been assigned to multiple roles';
        }

        addNotification(
          uuid,
          'userRoles',
          activeDashboardId,
          text,
          'success',
          '/resources/friend.svg',
          {},
          'empty'
        );
        CustomToast.success('Successfully update the role of ' + userData.name);
      })
      .then(() => {
        closeModals();
      });
  };

  /** Create clidk handler */
  const createClickHandler = () => {
    props.modalOpen(true);
    props.modalType(['createRole', activeDashboardId, 'editRoles', userId]);
  };

  /** Handle add role */
  const handleAddRole = role => {
    userDashboardRoleData[role] = true;
    roleDataLog[role] = {};
    roleDataLog[role].action = 'add';
    setRoleDataLog(roleDataLog);
    setUserDashboardRoleData(prevState => {
      return { ...prevState, ...userDashboardRoleData };
    });
  };

  /** Handle remove role */
  const handleRemoveRole = role => {
    userDashboardRoleData[role] = null;
    roleDataLog[role] = {};
    roleDataLog[role].action = 'remove';
    setRoleDataLog(roleDataLog);
    delete userDashboardRoleData[role];
    setUserDashboardRoleData(prevState => {
      return { ...prevState, ...userDashboardRoleData };
    });
  };

  /** Handle remove user from dashboard */
  const handleRemoveUser = () => {
    props.modalOpen(true);
    props.modalType(['removeUser', activeDashboardId, userId, true]);
  };

  /** Return Chat messages */
  return loading ? (
    <Lottie animation="loading" animationWidth={400} />
  ) : (
    <StyledWrapper>
      <StyledContentWrapper fullwidth>
        <StyledText
          align="center"
          text={`Manage ${userData.name}`}
          textColor="#fff"
          textStyle="h3"
          tag="h1"
        />
        {userData['avatar'] && userData['avatar'].original ? (
          <StyledAvatarWrapper>
            <Avatar shape="circle" size="big" source={userData['avatar'].original} status="online" />
          </StyledAvatarWrapper>
        ) : (
          <StyledAvatarWrapper>
            <Avatar size="big" source="/resources/astronaut.svg" status="online" />
          </StyledAvatarWrapper>
        )}

        <StyledDashboardGroup fullwidth>
          <StyledText
            align="left"
            text="Dashboard"
            textColor="#fff"
            textStyle="h6"
          />
          <StyledRemoveUserButton
            variant="contained"
            fullWidth
            onClick={() => {
              handleRemoveUser();
            }}
            bgColor={theme.color.danger}
            text={{
              align: 'center',
              text: 'Remove user',
              textColor: '#fff',
            }}
          />
        </StyledDashboardGroup>
        <StyledRoleWrapper>
          <StyledText
            align="left"
            text="Roles"
            textColor="#fff"
            textStyle="h6"
          />
          <StyledBadgeWrapper>
            {dashboardRoleData && userDashboardRoleData && Object.keys(userDashboardRoleData).length > 0 ? (
              Object.keys(userDashboardRoleData).map(role => {
                return (<Chip
                  id={`chip-remove-${role}`}
                  text={role}
                  backgroundColor={dashboardRoleData[role] && dashboardRoleData[role].details.color}
                  type="remove"
                  onClick={() => handleRemoveRole(role)}
                />)
              })
            ) : (
              <StyledEmptyText text="No role being assigned yet" textColor="rgba(255, 255, 255, 0.7)" />
            )}
          </StyledBadgeWrapper>
        </StyledRoleWrapper>

        <StyledCard>
          <Grid columns="1fr">
            <GridWrapper>
              <StyledText
                align="left"
                text="Optional"
                textColor="#fff"
                textStyle="h6"
              />
              <StyledOptionsWrapper>
                {dashboardRoleData &&
                  Object.keys(dashboardRoleData).length > 0 ?
                    Object.entries(dashboardRoleData).map(data => {
                      return (
                        <StyledChip
                          id={`chip-add-${data[0]}`}
                          text={data[0]}
                          backgroundColor={dashboardRoleData[data[0]].details.color}
                          type="add"
                          onClick={() => handleAddRole(data[0])}
                          disable={userDashboardRoleData && Object.keys(userDashboardRoleData).some(v => v == data[0])}
                        />
                      );
                    })
                  : <StyledEmptyRoleText text="No roles created yet.."  textColor="rgba(255, 255, 255, 0.7)" textStyle="description" />
                }
                {!maxExceeded && (
                  <StyledButton
                    id={'addNewRole'}
                    variant="icon"
                    onClick={() => createClickHandler()}
                    bgColor={theme.color.light[40]}
                    icon={{size: 20, padding: rem(0), icon: "add", color: "#fff"}}
                  />
                )}
              </StyledOptionsWrapper>
            </GridWrapper>
          </Grid>
        </StyledCard>

        <StyledButtonGroup fullwidth>
          <Button
            variant="contained"
            text={{
              align: 'center',
              text: 'Apply',
              textColor: !dashboardRoleData ? 'rgba(255, 255, 255, 0.7)' : '#fff',
            }}
            disabled={!dashboardRoleData ? true : false}
            onClick={() => {
              dashboardRoleData && Object.keys(dashboardRoleData).length > 0 && handleChange();
            }}
            bgColor={theme.color.success}
          />
          <Button
            variant="contained"
            onClick={() => {
              closeModals();
            }}
            bgColor={theme.color.cancel}
            text={{
              align: 'center',
              text: 'Cancel',
              textColor: '#fff',
            }}
          />
        </StyledButtonGroup>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

editRole.propTypes = {
  /** User Id */
  userId: PropTypes.string,
  /** Selected dashboard */
  dashboardId: PropTypes.string,
  /** Close function for the modal */
  closeModals: PropTypes.func,
};

editRole.defaultProps = {
  userId: null,
  dashboardId: null,
  closeModals: null,
};

//export default connect(mapStateToProps)(Messages);
//export default editRole;

export default connect(null, {
  modalType,
  modalOpen,
})(editRole);
