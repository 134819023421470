/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Gallery from 'react-image-gallery';
import { useTranslation } from 'react-i18next';
import styled, { keyframes, css } from 'styled-components';
import theme, { rem } from '../../theme';
import { modalOpen, modalType } from '../../redux/actions';
import ButtonGroup from '../ButtonGroup';
import Button from '../Button';
import Icon from '../Icon';
import Text from '../Text';
import 'react-image-gallery/styles/css/image-gallery.css';
import Upload from '../Dropzone';
import firebase, { uploadMultipleImage, updateSubTitle } from '../../firebase';
import Lottie from '../LottieControl';

const StyledButtonGroup = styled(ButtonGroup)`
  ${({ maxExceeded }) => `
    position: absolute;
    left: calc(50% - ${maxExceeded ? rem(50) : rem(100)});
    right: 0;
    bottom: 0;
    z-index: 1;
    width: ${rem(140)};
    margin: ${rem(15)} ${rem(20)};
  `}
`;

const StyledButton = styled(Button)`
  min-width: ${rem(48)};
  height: ${rem(48)};
`;

const StyledEmptyWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: -1;
`;

const StyledIndicatorWrapper = styled.div`
  position: absolute;
  top: -${rem(37)};
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIndicator = styled.div`
  background: ${theme.color.light[40]};
  border-radius: 50%;
  padding: ${rem(10)};
`;

const StyledWrapperExceeded = styled.div`
  position: absolute;
  bottom: -${rem(15)};
  margin-left: ${rem(20)};
`;

const StyledTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${rem(20)};
  width: ${rem(250)};
`;

const StyledTooltip = styled(ReactTooltip)`
  border-radius: ${rem(5)} !important;
  padding: ${rem(5)} ${rem(10)} !important;
`;

const StyledToolTipText = styled(Text)`
  margin-bottom: ${rem(15)};
  width: 100%;
`;

const StyledAnimation = styled(Lottie)`
  cursor: help;
`;

const StyledToolTipAnimation = styled(Lottie)`
  margin-bottom: ${rem(15)};
`;

/**
 * Return a styled component
 */
const ImageGallery = props => {
  const { images, itemId, typeId, table, itemTypes } = props;
  const [imageData, setImageData] = useState({});
  const [opacityStyling, setOpacityStyling] = useState(false);
  const [files, setFiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [galleryItems, setGalleryItems] = useState([]);
  const [maxImages, setMaxImages] = useState();
  const [totalImages, setTotalImages] = useState();
  const [maxExceeded, setMaxExceeded] = useState(false);

  //Translation
  const [t] = useTranslation(['text', 'tooltip', 'fb']);

  const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

  const MixinFadeIn = css`
    animation: ${fadeIn} 0.4s ease;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
  `;

  const emptyStyling = css`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;

    button {
      opacity: 0;
    }
  `;

  const StyledWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: ${loading ? 'center' : 'unset'};
    align-items: ${loading ? 'center' : 'unset'};
    ${MixinFadeIn};
    opacity: 0;

    .mainGallery {
      display: flex;
      flex-grow: 1;
      overflow: hidden;

      .image-gallery-content {
        display: flex;
        justify-content: center;
        flex-grow: 1;
        transition: 1s;
        opacity: ${opacityStyling ? 1 : 0};
      }

      .dropzone {
        ${galleryItems.length <= 0 && emptyStyling}
      }

      .image-gallery-thumbnails-wrapper {
        z-index: 1;
        position: fixed;
        right: ${rem(25)};
      }

      .image-gallery-slide-wrapper {
        &.left,
        &.right {
          width: 100%;
        }
      }

      .image-gallery-image {
        overflow: hidden;
        margin: 0 10px;
        box-sizing: border-box;
        text-align: center;
        position: relative;
        height: 80vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .image-gallery-slide {
        img {
          position: absolute;
          left: auto;
          right: auto;
          margin-left: 0;
          margin-right: 0;
          max-height: 100%;
          height: auto;
          width: auto;
        }
      }

      .image-gallery-slide {
        background: transparent;
        height: 100%;
      }
    }
  `;

  useEffect(() => {
    settingsListner();
    handleItems();
    document.getElementById('globalModal').firstElementChild.style.height =
      'auto';
    document.getElementById('globalModal').firstElementChild.style.width =
      '80%';
    if (
      document.getElementsByClassName(
        'image-gallery-thumbnails-container'
      )[0] &&
      document.getElementsByClassName('image-gallery-swipe')[0]
    ) {
      document
        .getElementsByClassName('image-gallery-thumbnails-container')[0]
        .firstChild.click();
    } else {
      setTimeout(() => setOpacityStyling(true), 100);
    }
  }, []);

  /** settingsListner */
  const settingsListner = () => {
    const listner = firebase
      .database()
      .ref()
      .child(table)
      .child(typeId);

    listner
      .child(itemTypes)
      .child(itemId)
      .child('maxImages')
      .on('value', snap => {
        setMaxImages(snap.val());
      });

    listner
      .child(itemTypes)
      .child(itemId)
      .child('totalImages')
      .on('value', snapshot => {
        if (snapshot.val()) {
          listner
            .child(itemTypes)
            .child(itemId)
            .child('maxImages')
            .once('value', snap => {
              if (snapshot.val() >= snap.val()) {
                setMaxExceeded(true);
              } else {
                setMaxExceeded(false);
              }
            });
          setTotalImages(snapshot.val());
        }
      });

    listner
      .child('items')
      .child(itemId)
      .child('images')
      .once('value', snap => {
        if (snap.val()) {
          snap.forEach(function(snapshot) {
            imageData[snapshot.key] = {
              original: snapshot.val().url,
              thumbnail: snapshot.val().url,
              originalAlt: snapshot.val().id,
              thumbnailAlt: snapshot.val().id,
              key: snapshot.val().id,
            };
          });
        }
      });

    listner
      .child('items')
      .child(itemId)
      .child('images')
      .orderByChild('update')
      .startAt(Date.now())
      .on('child_added', snap => {
        if (snap.val()) {
          imageData[snap.key] = {
            original: snap.val().url,
            thumbnail: snap.val().url,
            originalAlt: snap.val().id,
            thumbnailAlt: snap.val().id,
            key: snap.val().id,
          };
          setImageData(prevState => {
            // Object.assign would also work
            return { ...prevState, ...imageData };
          });
          handleItems();

          listner.once('value', snapMain => {
            updateSubTitle(
              table,
              typeId,
              t('fb:image.added') + snapMain.val().title
            );
          });
        }
      });

    listner
      .child('items')
      .child(itemId)
      .child('images')
      .on('child_removed', snap => {
        if (snap.val()) {
          delete imageData[snap.key];

          setImageData(prevState => {
            // Object.assign would also work
            return { ...prevState, ...imageData };
          });
          handleItems();

          listner.once('value', snapMain => {
            updateSubTitle(
              table,
              typeId,
              t('fb:image.removed') + snapMain.val().title
            );
          });
        }
      });
  };

  /** Keep track of the selected files */
  const handleFiles = data => {
    setFiles(data);
    setLoading(true);
    uploadMultipleImage(
      data,
      firebase.auth().currentUser.uid,
      typeId,
      table + '/items/public',
      itemId,
      table,
      itemTypes
    );
    setTimeout(() => setLoading(false), 2000);
  };

  /** Handle the gallery item, must be in [{}, {}, {}] */
  const handleItems = () => {
    const dataArray = [];

    Object.values(imageData).forEach(item => {
      dataArray.push(item);
    });

    if (dataArray.length <= 0) {
      setGalleryItems([]);
    } else {
      setGalleryItems(dataArray);
    }
  };

  /** Custom controls */
  const customControls = () => {
    return (
      <StyledButtonGroup spacing={rem(40)} maxExceeded={maxExceeded}>
        {!maxExceeded && (
          <Upload
            type='button'
            bgColor={theme.color.light[80]}
            iconColor={theme.color.dark[80]}
            iconPadding={rem(12)}
            iconSize={20}
            iconName='add'
            preview
            previewId='previewListAttachment'
            onFileSelect={handleFiles}
            newFiles={files}
            isGallery
          />
        )}
        {Object.keys(imageData).length > 0 && (
          <StyledButton
            variant='icon'
            onClick={() => {
              const imageId = document
                .getElementsByClassName('image-gallery-thumbnail active')[0]
                .getElementsByTagName('img')[0].alt;
              const imageName = '';
              props.modalOpen(true);
              props.modalType([
                'removeImage',
                typeId,
                itemId,
                imageId,
                table,
                true,
              ]);
            }}
            bgColor={theme.color.danger}
            className='image-gallery-custom-action'
          >
            <Icon padding={rem(12)} icon='delete' size={20} color='#fff' />
          </StyledButton>
        )}
      </StyledButtonGroup>
    );
  };

  return (
    imageData && (
      <StyledWrapper>
        {!loading && galleryItems.length <= 0 && (
          <StyledEmptyWrapper>
            <Text
              align='center'
              textStyle='h3'
              text={t('imagegallery.empty')}
              textColor='white'
            />
            <Lottie
              animation='imageEmpty'
              animationWidth={400}
              animationHeight={420}
            />
          </StyledEmptyWrapper>
        )}
        {!loading ? (
          <React.Fragment>
            <StyledIndicatorWrapper>
              <StyledIndicator>
                <Text text={`${totalImages}/${maxImages}`} textColor='#fff' />
              </StyledIndicator>
              {maxExceeded && (
                <StyledWrapperExceeded>
                  <StyledAnimation
                    animation='alert'
                    animationWidth={30}
                    animationHeight={30}
                    data-for={`tooltipListItemImages-${itemId}`}
                    data-tip=''
                  />
                  <StyledTooltip
                    id={`tooltipListItemImages-${itemId}`}
                    getContent={() => {
                      return (
                        <StyledTooltipWrapper>
                          <StyledToolTipText
                            text={t('tooltip:error.errornumbertitle')}
                            textStyle='h6'
                            textColor='#fff'
                            align='center'
                          />
                          <StyledToolTipAnimation
                            animation='cry'
                            animationWidth={rem(100)}
                            animationHeight={rem(100)}
                          />
                          <Text
                            text={t('tooltip:error.errornumbertext')}
                            textStyle='subtitle'
                            textColor='#fff'
                            align='center'
                          />
                        </StyledTooltipWrapper>
                      );
                    }}
                  />
                </StyledWrapperExceeded>
              )}
            </StyledIndicatorWrapper>
            <Gallery
              showFullscreenButton={false}
              showPlayButton={false}
              additionalClass='mainGallery'
              items={galleryItems}
              thumbnailPosition='right'
              renderCustomControls={() => customControls()}
            />
          </React.Fragment>
        ) : loading ? (
          <Lottie animation='loading' animationWidth={400} />
        ) : null}
      </StyledWrapper>
    )
  );
};

ImageGallery.propTypes = {
  images: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  itemId: PropTypes.string,
  typeId: PropTypes.string,
  table: PropTypes.string,
  itemTypes: PropTypes.string,
};

ImageGallery.defaultProps = {
  images: null,
  itemId: null,
  typeId: null,
  table: null,
  itemTypes: null,
};

ImageGallery.displayName = 'ImageGallery';

/** @component */
export default connect(null, {
  modalOpen,
  modalType,
})(ImageGallery);
