import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Particles from 'react-particles-js';
import Text from '../Text';
import Image from '../Image';
import Animation from '../LottieControl';
import Group from '../Group';
import { rem } from '../../theme';
import { dashboardListner, userListner } from '../../firebase';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #26262b;
  height: 100%;
  width: 100%;

  #tsparticles {
    width: 100%;
    height: 100%;
  }
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(10)};
`;

const StyledTextSub = styled(Text)`
  margin-bottom: ${rem(30)};
`;

const StyledImage = styled(Image)`
  margin-bottom: ${rem(20)};
`;

const StyledTextIntro = styled(Text)`
  margin-bottom: 0;
`;

const StyledGroup = styled(Group)`
  background: rgb(47, 49, 54);
  width: ${rem(500)};
  min-height: ${rem(500)};
  border-radius: ${rem(15)};
  padding: ${rem(20)};
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
`;

const StyledParticles = styled(Particles)`
  position: fixed;
  z-index: 0;
  width: 100vw;
  height: 100vh;
`;

const StyledAnimation = styled(Animation)`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

/** invite url route */
const Full = props => {
  const { inviteKey } = props;

  const [dashboardData, setDashboardData] = useState({});

  //Translation
  const [t] = useTranslation(['text']);

  useEffect(() => {
    if (inviteKey && Object.keys(inviteKey).length > 0) {
      let dataObject = {};
      dashboardListner
        .child(inviteKey.dashboardId)
        .once('value', snap => {
          dataObject = {
            image: snap.val().image,
            name: snap.val().name,
          };
        })
        .then(() => {
          userListner
            .child(inviteKey.friendId)
            .child('public')
            .once('value', snapUser => {
              let userData = {};
              userData = {
                user: snapUser.val().name,
              };

              const objectFinal = { ...dataObject, ...userData };
              setDashboardData(objectFinal);
            });
        });
    }
  }, [inviteKey]);

  return (
    dashboardData &&
    Object.keys(dashboardData).length > 0 && (
      <StyledWrapper>
        <StyledParticles
          params={{
            particles: {
              number: {
                value: 60,
                density: {
                  enable: true,
                  value_area: 1500,
                },
              },
              line_linked: {
                enable: true,
                opacity: 0.02,
              },
              move: {
                direction: 'right',
                speed: 0.05,
              },
              size: {
                value: 1,
              },
              opacity: {
                anim: {
                  enable: true,
                  speed: 1,
                  opacity_min: 0.05,
                },
              },
            },
            interactivity: {
              events: {
                onclick: {
                  enable: true,
                  mode: 'push',
                },
              },
              modes: {
                push: {
                  particles_nb: 1,
                },
              },
            },
            retina_detect: true,
          }}
        />
        <StyledGroup fullwidth>
          <StyledText
            align='center'
            text={t('text:error.dashboard.full.title')}
            textColor='#fff'
            textStyle='h3'
            tag='h1'
          />
          {dashboardData.image ? (
            <StyledImage src={dashboardData.image} />
          ) : (
            <StyledAnimation
              animation='cry'
              animationWidth={rem(150)}
              animationHeight={rem(150)}
            />
          )}

          {dashboardData.name ? (
            <React.Fragment>
              <StyledTextIntro
                align='center'
                text={t('text:error.dashboard.full.text', {
                  name: dashboardData.user,
                })}
                textColor='#fff'
                textStyle='h6'
                tag='p'
              />

              <StyledText
                align='center'
                text={dashboardData.name}
                textColor='#fff'
                textStyle='h4'
                tag='h2'
              />

              <StyledTextSub
                align='center'
                text={t('text:error.dashboard.full.subtext')}
                textColor='#fff'
                textStyle='h6'
                tag='p'
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <StyledTextIntro
                align='center'
                text={t('text:error.dashboard.full.sectext', {
                  name: dashboardData.users,
                })}
                textColor='#fff'
                textStyle='h6'
                tag='p'
              />
            </React.Fragment>
          )}
        </StyledGroup>
      </StyledWrapper>
    )
  );
};

Full.propTypes = {
  /** InviteKey */
  inviteKey: PropTypes.oneOfType([PropTypes.object]),
};

Full.defaultProps = {
  inviteKey: null,
};

export default Full;
