import React from 'react';
import PropTypes from 'prop-types';
import CallsContent from '../../Call/CallContent';

/**
 * Layout component of a chat widget
 * @param {object} main
 * @param {string} activeId
 * @param {string} activeDashboardId
 * @param {string} widgetId
 */
const Calls = props => {
  const { main, activeId, activeDashboardId, widgetId } = props;

  return (
    <CallsContent
      key={activeId}
      currentId={activeId}
      data={main}
      items={main && main['items'] && main['items']}
      attendees={main && main.attendees && main.attendees}
      activeDashboardId={activeDashboardId}
      widgetId={widgetId}
    />
  );
};

Calls.propTypes = {
  /** The main data */
  main: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  /** Current active ID */
  activeId: PropTypes.string,
  /** Dashboard ID */
  activeDashboardId: PropTypes.string,
  /** Current widgetId */
  widgetId: PropTypes.string,
};

Calls.defaultProps = {
  main: null,
  activeId: '',
  activeDashboardId: '',
  widgetId: null,
};

export default Calls;
