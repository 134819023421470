/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ContextMenuTrigger } from 'react-contextmenu';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';
import { modalOpen, modalType } from '../../redux/actions';
import Button from '../Button';
import Group from '../Group';
import Text from '../Text';
import Icon from '../Icon';
import Animation from '../LottieControl';
import TextField from '../TextField';
import OverviewSelector from '../OverviewSelector';
import Avatar from '../Avatar';
import theme, { rem } from '../../theme';
import ContextMenuItems from '../ContextMenuItems';
import firebase, {
  userDashboardListner,
  dashboardListner,
  userListner,
  inviteKeyListner,
  masterListner,
} from '../../firebase';

const StyledWrapper = styled.div`
  display: flex;
  height: auto;
  padding: ${rem(15)};
  transition: 0.2s;

  &.fullMenu {
    background: rgba(0, 0, 0, 0.3);
    margin: ${rem(20)};
    border-radius: ${rem(15)};
    padding: ${rem(18)};

    .title {
      opacity: 1;
      height: auto;
      margin-bottom: ${rem(10)};
    }
  }

  .title {
    opacity: 0;
    height: 0;
  }
`;

const StyledCenter = styled.div`
  flex-grow: 1;
`;

const StyledLink = styled.div`
  width: ${rem(100)};
  margin-left: ${rem(20)};
`;

const StyledDash = styled.div`
  width: auto;
  margin-right: ${rem(20)};
`;

const StyledOption = styled.span``;

const StyledItemWrapper = styled.div`
  width: auto;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledAvatar = styled(Avatar)``;

const StyledButton = styled(Button)`
  min-height: ${rem(32)};
  min-width: ${rem(32)};
`;

const StyledButtonDash = styled(Button)`
  border-radius: ${rem(5)};
  min-height: 2rem;
  min-width: 3.5rem;
  margin-right: ${rem(10)};
`;

const StyledTextField = styled(TextField)`
  margin-right: ${rem(50)};
  text-overflow: ellipsis;
`;

const StyledInputGroup = styled(Group)`
  button {
    min-width: 1.5rem;
    min-height: 1.5rem;
    margin 0 !important;
  }
`;

const StyledAnimation = styled(Animation)`
  cursor: help;
  position: absolute;
  right: -0.8rem;
  top: -0.5rem;
`;

const StyledAvatarWrapper = styled.div`
  display: inline-flex;
  cursor: pointer;
  margin-right: ${rem(10)};
  height: 2rem;

  &:last-child {
    margin-right: 0;
  }
`;

const StyledGroup = styled(Group)`
  display: flex;
  flex-direction: row;
`;

const StyledGroupMessage = styled(Group)`
  display: flex;
  margin-bottom: ${rem(15)};

  input {
    margin-right: 2rem;
  }
`;

const StyledSmallButton = styled(Button)`
  min-width: auto;
`;

const StyledTextGroup = styled(Group)``;

/** CompactMenu component
 * @param {string} activeDashboardId
 */
const CompactMenu = props => {
  const { activeDashboardId } = props;

  //UserId
  const uuid = firebase.auth().currentUser.uid;

  //States
  const [members, setMembers] = useState({});
  const [currentImage, setCurrentImage] = useState();
  const [handleMember, setHandleMember] = useState();
  const [shortLink, setShortLink] = useState();
  const [maxExceededDashboards, setMaxExceededDashboards] = useState(false);
  const [totalDashboards, setTotalDashboards] = useState();
  const [totalMembers, setTotalMembers] = useState();
  const [maxExceededMembers, setMaxExceededMembers] = useState();
  const [isMaster, setIsMaster] = useState(false);
  const [disableHover, setDisableHover] = useState(false);

  //Translation
  const [t] = useTranslation(['tooltip', 'common']);

  //Refs
  const linkRef = useRef();
  const messageRef = useRef();

  //Input Link HTML
  const inputLink = (
    <Group>
      <StyledTextField
        selectionBackground='yellow'
        readOnly
        hasSinlgeButton
        textcolor='#fff'
        placeholdercolor='#fff'
        backgroundcolor={theme.color.dark[40]}
        inputId='bubbleLink'
        borders={false}
        value={
          shortLink
            ? 'https://onlinedashboard.studentsplus.nl/' + shortLink
            : ''
        }
        ref={linkRef}
      />
      <StyledInputGroup type='inputOption'>
        <StyledOption
          title={t('common:invite.copy')}
          onClick={() => copyText()}
        >
          <Icon
            icon='content_copy'
            padding={rem(6)}
            size={16}
            color={theme.color.light[100]}
          />
        </StyledOption>

        <StyledOption
          title={t('common:invite.refresh')}
          onClick={() => generateLink()}
        >
          <Icon
            icon='refresh'
            padding={rem(6)}
            size={16}
            color={theme.color.light[100]}
          />
        </StyledOption>
      </StyledInputGroup>
    </Group>
  );

  useEffect(() => {
    if (linkRef.current) {
      dashboardListner
        .child(activeDashboardId)
        .child('link')
        .child('id')
        .once('value', snap => {
          if (snap.val() && linkRef.current) {
            if (window.location.pathname.split('/')[1] !== snap.val()) {
              var newurl =
                window.location.protocol +
                '//' +
                window.location.host +
                window.location.pathname +
                snap.val();
              window.history.replaceState({ path: newurl }, '', newurl);
            }

            linkRef.current.value =
              'onlinedashboard.studentsplus.nl/' + snap.val();
          }
        });
    }
  }, [linkRef.current]);

  useEffect(() => {
    if (activeDashboardId) setListners();
  }, []);

  useEffect(() => {
    if (disableHover) ReactTooltip.hide();
  }, [disableHover]);

  /** Generate a custom link */
  const generateLink = () => {
    dashboardListner
      .child(activeDashboardId)
      .child('link')
      .once('value', snap => {
        //20 minutes were passed from start
        if (Date.now() - snap.val().time >= 20000) {
          const randomId =
            Math.random()
              .toString(36)
              .substring(2, 15) +
            Math.random()
              .toString(36)
              .substring(2, 15);

          dashboardListner
            .child(activeDashboardId)
            .child('link')
            .update({
              id: randomId,
              time: Date.now(),
            })
            .then(() => {
              linkRef.current.value =
                'onlinedashboard.studentsplus.nl/' + randomId;
              copyText();
            })
            .then(() => {
              const oldKey = snap.val().id;
              inviteKeyListner
                .child(oldKey)
                .remove()
                .then(() => {
                  inviteKeyListner.update({
                    [randomId]: {
                      id: randomId,
                      dashboardId: activeDashboardId,
                      friendId: uuid,
                      time: Date.now(),
                    },
                  });
                });
            });
        }
      });
  };

  /** Copy the text */
  const copyText = () => {
    if (linkRef.current) {
      /* Get the text field */
      var copyText = linkRef.current;

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand('copy');
    }
  };

  /** Set listners */
  const setListners = () => {
    masterListner.once('value', snapshot => {
      if (snapshot.exists() && snapshot.val().members) {
        Object.keys(snapshot.val().members).map(member => {
          if (member === uuid) {
            setIsMaster(true);
          }
        });
      }
    });

    //DASHBOARD LISTNER ON MEMBERS
    dashboardListner
      .child(activeDashboardId)
      .child('members')
      .on('child_added', snap => {
        userListner
          .child(snap.key)
          .child('public')
          .on('value', snapShot => {
            members[snap.key] = snapShot.val();

            setMembers(prevState => {
              return { ...prevState, ...members };
            });
          });
      });

    //Get the max and total dasboard count and see if it exceeded
    userListner
      .child(uuid)
      .child('totalDashboards')
      .on('value', snapshot => {
        if (snapshot.val()) {
          userListner
            .child(uuid)
            .child('maxDashboards')
            .once('value', snap => {
              if (snapshot.val() >= snap.val()) {
                setMaxExceededDashboards(true);
              } else {
                setMaxExceededDashboards(false);
              }
            });
          setTotalDashboards(snapshot.val());
        }
      });

    //Get the max and total members count and see if it exceeded
    dashboardListner
      .child(activeDashboardId)
      .child('totalMembers')
      .on('value', snapshot => {
        if (snapshot.val()) {
          dashboardListner
            .child(activeDashboardId)
            .child('maxMembers')
            .on('value', snap => {
              if (snapshot.val() >= snap.val()) {
                setMaxExceededMembers(true);
              } else {
                setMaxExceededMembers(false);
              }
            });
          setTotalMembers(snapshot.val());
        }
      });

    dashboardListner
      .child(activeDashboardId)
      .child('members')
      .on('child_removed', snap => {
        delete members[snap.key];
        setMembers(members);
      });

    dashboardListner
      .child(activeDashboardId)
      .child('link')
      .child('id')
      .once('value', snap => {
        setShortLink(snap.val());
      });
  };

  /** Handle short message */
  const handleSend = member => {
    const message = messageRef.current.value;
    if (message.trim() === '') return;
    userDashboardListner
      .child(member)
      .child(activeDashboardId)
      .update({ temp: { message: { text: message, user: uuid } } })
      .then(() => {
        setTimeout(function() {
          userDashboardListner
            .child(member)
            .child(activeDashboardId)
            .child('temp')
            .remove();
        }, 2000);
      });

    messageRef.current.value = '';
  };

  /** handleKeydown */
  const handleKeydown = (event, member) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      handleSend(member);
    }
  };

  /** Get the html */
  const handleHTML = member => {
    return (
      <StyledGroupMessage fullwidth>
        <TextField
          placeholdercolor='#fff'
          hasButtons
          backgroundcolor={theme.color.light[10]}
          isFocused
          textcolor='#fff'
          inputId='modalTitle'
          placeholder={t('button:create.message')}
          borders={false}
          value={messageRef.current && messageRef.current.value}
          ref={messageRef}
          onKeyDown={event => handleKeydown(event, member)}
        />
        <Group type='inputOption'>
          <StyledSmallButton variant='icon' onClick={() => handleSend(member)}>
            <Icon
              icon='send'
              padding={rem(6)}
              size={16}
              color={theme.color.light[80]}
            />
          </StyledSmallButton>
        </Group>
      </StyledGroupMessage>
    );
  };

  return members ? (
    <StyledWrapper id='smallMenu'>
      <StyledItemWrapper id='membersMenu'>
        <StyledTextGroup fullwidth>
          <Text
            text={t('common:attendees')}
            textStyle='subtitle'
            textColor={theme.color.light[100]}
            tag='p'
            className='title'
          />
        </StyledTextGroup>
        <StyledGroup fullwidth>
          {members &&
            Object.entries(members).map(member => {
              if (!member[1]) return;
              // /return <Avatar />;
              return (
                <StyledAvatarWrapper key={member[0]}>
                  <ContextMenuTrigger
                    id='usermenuRemove'
                    holdToDisplay={1}
                    key={member[0]}
                  >
                    <StyledAvatar
                      source={
                        member[1] &&
                        member[1].avatar &&
                        member[1].avatar.cropped
                      }
                      size='small'
                      status={member[1] && member[1].indicator}
                      data-for={'usermenu' + member[0]}
                      data-delay-show='0'
                      data-tip=''
                      title={member[1] && member[1].name}
                      color={member[1] && member[1].color && member[1].color}
                      onClick={() => setHandleMember(member[0])}
                    />
                  </ContextMenuTrigger>
                  <Tooltip
                    id={'usermenu' + member[0]}
                    disable={
                      disableHover ||
                      maxExceededDashboards ||
                      !member[1].name ||
                      member[1].name === ''
                        ? true
                        : false
                    }
                    noSpacing
                    content={{
                      text: {
                        text:
                          member[1].name &&
                          member[1].name !== '' &&
                          member[1].name,
                        textColor: '#fff',
                        align: 'center',
                      },
                    }}
                  />
                </StyledAvatarWrapper>
              );
            })}
          <ContextMenuItems
            id='usermenuRemove'
            bgColor={theme.color.dark[80]}
            html={handleMember !== uuid && handleHTML(handleMember)}
            onShow={() => {
              setDisableHover(true);
            }}
            onHide={() => {
              setDisableHover(false);
            }}
            data={
              handleMember !== uuid
                ? [
                    {
                      id: 'usermenuRemove',
                      icon: 'remove_circle_outline',
                      text: t('button:remove.user'),
                      special: true,
                      backgroundColor: theme.color.danger,
                      click: () => {
                        props.modalOpen(true);
                        props.modalType([
                          'removeUser',
                          activeDashboardId,
                          handleMember,
                          false,
                        ]);
                      },
                    },
                  ]
                : [
                    {
                      id: 'userLogOut',
                      icon: 'power_settings_new',
                      text: t('button:logout.text'),
                      special: true,
                      backgroundColor: theme.color.warning,
                      click: () => {
                        props.modalOpen(true);
                        props.modalType([
                          'logoutUser',
                          activeDashboardId,
                          handleMember,
                          false,
                        ]);
                      },
                    },
                  ]
            }
          />
          <StyledButton
            id='bubble-attendees'
            variant='icon'
            bgColor={theme.color.light[80]}
            onClick={() => copyText()}
            icon={{
              type: 'icon',
              padding: rem(4),
              size: 18,
              icon: 'person_add',
            }}
            disabled={maxExceededMembers ? true : false}
            data-for='shareablelink'
            data-delay-show='0'
            data-tip=''
          >
            <Tooltip
              id='shareablelink'
              disable={maxExceededMembers ? true : false}
              left
              arrowColor='transparent'
              delayHide={500}
              content={{
                title: {
                  text: t('tooltip:compactmenu.link'),
                  textStyle: 'h6',
                  textColor: '#fff',
                  align: 'center',
                },
                animation: 'share',
                animationWidth: rem(60),
                animationHeight: rem(60),
                text: {
                  text: t('tooltip:compactmenu.linktext'),
                  textStyle: 'subtitle',
                  textColor: '#fff',
                  align: 'center',
                },
              }}
              html={inputLink}
            />
            {maxExceededMembers && (
              <React.Fragment>
                <StyledAnimation
                  animation='alert'
                  animationWidth={20}
                  animationHeight={20}
                  data-for='exeededMembers'
                  data-tip=''
                />
                <Tooltip
                  id='exeededMembers'
                  content={{
                    title: {
                      text: t('tooltip:errors.members.title'),
                      textStyle: 'h6',
                      textColor: '#fff',
                      align: 'center',
                    },
                    animation: 'alert',
                    animationWidth: rem(60),
                    animationHeight: rem(60),
                    text: {
                      text: t('tooltip:errors.members.text'),
                      textStyle: 'subtitle',
                      textColor: '#fff',
                      align: 'center',
                    },
                  }}
                />
              </React.Fragment>
            )}
          </StyledButton>
        </StyledGroup>
      </StyledItemWrapper>
      <StyledCenter />
      <StyledItemWrapper id='dashboardMenu'>
        <StyledTextGroup fullwidth>
          <Text
            text={t('common:dashboards')}
            textStyle='subtitle'
            textColor={theme.color.light[100]}
            tag='p'
            className='title'
            align='right'
          />
        </StyledTextGroup>
        <StyledGroup fullwidth>
          {isMaster && (
            <StyledButtonDash
              id='dashboardAdd'
              tooltip={{
                text: t('tooltip:dashboard.new'),
                disable: maxExceededDashboards ? true : false,
              }}
              data-for='addnewdashboard'
              data-tip=''
              variant='icon'
              disabled={maxExceededDashboards ? true : false}
              bgColor={theme.color.light[80]}
              onClick={() => {
                props.modalOpen(true);
                props.modalType(['adddashboard']);
              }}
              icon={{
                type: 'icon',
                padding: rem(4),
                size: 18,
                icon: 'add',
              }}
            >
              <Tooltip
                id='addnewdashboard'
                disable={maxExceededDashboards ? true : false}
                noSpacing
                content={{
                  text: {
                    text: t('tooltip:dashboard.new'),
                    textColor: '#fff',
                    align: 'center',
                  },
                }}
              />
              {maxExceededDashboards && (
                <React.Fragment>
                  <StyledAnimation
                    animation='alert'
                    animationWidth={20}
                    animationHeight={20}
                    data-for='exeededDashboards'
                    data-tip=''
                  />
                  <Tooltip
                    id='exeededDashboards'
                    content={{
                      title: {
                        text: t('tooltip:errors.dashboard.title'),
                        textStyle: 'h6',
                        textColor: '#fff',
                        align: 'center',
                      },
                      animation: 'alert',
                      animationWidth: rem(60),
                      animationHeight: rem(60),
                      text: {
                        text: t('tooltip:errors.dashboard.text'),
                        textStyle: 'subtitle',
                        textColor: '#fff',
                        align: 'center',
                      },
                    }}
                  />
                </React.Fragment>
              )}
            </StyledButtonDash>
          )}
          <OverviewSelector activeDashboardId={activeDashboardId} compact />
        </StyledGroup>
      </StyledItemWrapper>
    </StyledWrapper>
  ) : (
    <React.Fragment />
  );
};

CompactMenu.propTypes = {
  /** Active dashboard */
  activeDashboardId: PropTypes.string,
};

CompactMenu.defaultProps = {
  activeDashboardId: null,
};

/** @component */
export default connect(null, {
  modalOpen,
  modalType,
})(CompactMenu);
