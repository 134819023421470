import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Ink from 'react-ink';
import FileViewer from 'react-file-viewer';
import { useTranslation } from 'react-i18next';
import {
  modalContent,
  modalOpen,
  modalType,
  globalDragInfo,
  storeWidgetDrag,
} from '../../redux/actions';
import EmptyState from '../EmptyState';
import Animation from '../LottieControl';
import Tooltip from '../Tooltip';
import Grid from '../Grid';
import Group from '../Group';
import Button from '../Button';
import Image from '../Image';
import Text from '../Text';
import Help from '../Help';
import Icon from '../Icon';
import theme, { rem } from '../../theme';
import firebase, {
  documentSharingRef,
  uploadSingleDocument,
  documentSharingListner,
} from '../../firebase';

const StyledMainWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding-bottom: ${rem(3)};
`;

const StyledInnerWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 15px 15px 0 15px;
  padding: 8px 0 15px 0;
  border-radius: 15px;
  background: white;
`;

const StyledWrapper = styled.div`
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-grow: 1;
  height: auto;
  min-height: 100%;
`;

const StyledContentWrapper = styled.div`
  background: #fff;
  align-content: flex-start;
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  padding-top: ${rem(12)};
  padding-bottom: ${rem(50)};

  .pg-viewer-wrapper {
    overflow-y: unset;

    .pg-viewer {
      width: 100%;

      video {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

const StyledGrid = styled(Grid)`
  height: ${rem(120)};
  width: ${rem(100)};
  margin: 0 0 ${rem(4)} 0;

  .track-vertical {
    top: 0;
    bottom: 0;
  }
`;

const StyledGroup = styled(Group)`
  position: absolute;
  bottom: ${rem(25)};
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;

  button {
    margin-right: ${rem(8)};
    min-width: ${rem(30)};
    min-height: ${rem(30)};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledGroupUpload = styled(Group)`
  position: absolute;
  bottom: ${rem(10)};
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: ${rem(155)};
  width: ${rem(100)};
  margin-right: ${rem(8)};

  &:last-child {
    margin-right: 0;
  }

  .fileoption {
    opacity: 0;
    transition: 0.5s;
  }

  &:hover,
  &:focus {
    .fileoption {
      opacity: 1;
    }
  }
`;

const StyledButton = styled(Button)`
  position: absolute;
  top: -10px;
  right: 4px;
  min-width: ${rem(30)};
  min-height: ${rem(30)};
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.color.dark[8]};
  width: ${rem(45)};
  height: ${rem(45)};
  border-radius: ${rem(40)};
  position: relative;
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${theme.color.primary[100]};
    background: ${theme.color.dark[6]};
  }

  input {
    display: none;
  }
`;

const StyledEmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const StyleImage = styled(Image)`
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: help;
`;

const StyledIndicator = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${theme.color.dark[60]};
  width: ${rem(30)};
  height: ${rem(30)};
  z-index: 5;
  right: ${rem(30)};
  margin-left: auto;
  margin-right: auto;
  transition: 0.2s;
  opacity: 1;
`;

const StyledTextIndicator = styled(Text)`
  font-weight: 600;
  font-size: ${rem(10)};
`;

const StyledImage = styled(Image)`
  min-height: 100%;
  cursor: grab;
`;

const StyledTempImage = styled(Image)`
  cursor: grab;
`;

const StyledTitle = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledExtension = styled.span``;

/** DrawContent */
const DocumentSharingContent = props => {
  const { activeDashboardId, currentId } = props;

  //States
  const [files, setFiles] = useState([]);
  const [maxExceeded, setMaxExceeded] = useState(false);
  const [errorSize, setErrorSize] = useState(false);
  const [errorType, setErrorType] = useState(false);
  const [documents, setDocuments] = useState();
  const [totalItems, setTotalItems] = useState(0);
  const [maxItems, setMaxItems] = useState();

  //Refs
  const indicatorRef = useRef();

  //Translation
  const [t] = useTranslation(['help', 'emptystate', 'tooltip']);

  useEffect(() => {
    if (currentId) setListners();
  }, []);

  /**
   * Handle drop
   */
  const handleDrop = async (ev, id) => {
    if (!ev && !ev.dataTransfer) return;
    ev.preventDefault();
    document.getElementById(id).style.backgroundColor = '';
    document.getElementById(id).style.transition = '';

    if (ev && ev.dataTransfer && ev.dataTransfer.types) {
      for (var i = 0; i < ev.dataTransfer.types.length; i++) {
        if (ev.dataTransfer.types && ev.dataTransfer.types[i] == 'Files') {
          let currentFile = ev.dataTransfer.items[i];
          let type = currentFile.type.slice(0, currentFile.type.indexOf('/'));
          let file = ev.dataTransfer.files[0];
          let typeSmall = file.name.split('.').pop();
          const path = 'documents/' + currentId + '/';
          const name = file.name;
          const acceptedTypes = ['xlsx', 'doc', 'docx', 'txt', 'pdf', 'ppt'];
          const reader = new FileReader();

          if (type === 'image') typeSmall = 'image';

          if (type !== 'image' && !acceptedTypes.includes(typeSmall)) {
            setErrorType(true);
          } else {
            reader.onloadend = function() {
              uploadSingleDocument(
                reader.result,
                path,
                currentId,
                name,
                typeSmall
              );
              setErrorType(false);
            };
            reader.readAsDataURL(file);
          }
        }
      }
    }
  };

  /**
   * Handle Drag Over
   */
  const handleDragOver = (ev, id) => {
    ev.preventDefault();

    if (props.storeDrag) return;

    if (ev.dataTransfer.types && ev.dataTransfer.types[0] == 'Files') {
      document.getElementById(id).style.backgroundColor = theme.color.success;
      document.getElementById(id).style.transition = '0.35s';
      //Drag from outside the browser
    } else if (props.dragInfo && props.dragInfo.name) {
      document.getElementById(id).style.backgroundColor = theme.color.success;
      document.getElementById(id).style.transition = '0.35s';
    }
  };

  /** Setup the listners */
  const setListners = () => {
    documentSharingListner
      .child(currentId)
      .child('documents')
      .on('value', snapshot => {
        setDocuments(snapshot.val());
      });

    //Get the max number of documents
    documentSharingListner.child(currentId).on('value', snapshot => {
      if (snapshot.val()) {
        if (
          snapshot.val().totalItems &&
          snapshot.val().totalItems >= snapshot.val().maxItems
        ) {
          setMaxExceeded(true);
        }
        setTotalItems(
          snapshot.val().totalItems ? snapshot.val().totalItems : '0'
        );
        setMaxItems(snapshot.val().maxItems);
      }
    });

    documentSharingListner.child(currentId).on('child_changed', snap => {
      if (snap.key === 'totalItems') {
        setTotalItems(snap.val());
        documentSharingListner
          .child(currentId)
          .child('maxItems')
          .once('value', snapshot => {
            if (snap.val() < snapshot.val()) {
              setMaxExceeded(false);
            } else {
              setMaxExceeded(true);
            }
          });
      }
    });
  };

  /** Readfile */
  const readFile = file => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  /** OnFileChange */
  const onFileChange = async e => {
    if (e && e.target.files && e.target.files.length > 0) {
      if (errorSize) setErrorSize(false);
      if (errorType) setErrorType(false);
      //Check if max of 5MB
      if (e.target.files[0].size > 5000000) {
        setErrorSize(true);
      } else {
        const path = 'documents/' + currentId + '/';
        const name = e.target.files[0].name;
        const typeSmall = name.split('.').pop();
        const type = e.target.files[0].type;
        const acceptedTypes = ['xlsx', 'doc', 'docx', 'txt', 'pdf', 'ppt'];

        if (!type.includes('image') && !acceptedTypes.includes(typeSmall)) {
          setErrorType(true);
        } else {
          const imageDataUrl = await readFile(e.target.files[0]);
          if (imageDataUrl && !maxExceeded) {
            uploadSingleDocument(
              imageDataUrl,
              path,
              currentId,
              name,
              typeSmall
            );
          }
        }
      }
    }
  };

  /** Error handling */
  const onError = e => {
    //console.log('ERROR', e);
  };

  /** Download the file */
  const downloadHandler = (url, type) => {
    const file_url = url;
    var el = document.createElement('a');
    el.download = file_url;
    el.href = url;
    el.target =
      type !== 'ppt' && type !== 'xlsx' && type !== 'doc' && type !== 'docx'
        ? '_blank'
        : '_self';
    document.body.appendChild(el);
    el.click();
    el.remove();
  };

  /** Preview the file */
  const previewHandler = (typeSmall, url, type, name) => {
    props.modalOpen(true);
    props.modalType(['previewFile', typeSmall, url, type, name]);
  };

  /** Handle delete */
  const handleDelete = (fileName, documentId) => {
    props.modalOpen(true);
    props.modalType(['removeFile', currentId, fileName, documentId]);
  };

  /** Get the corresponding image */
  const getTemplate = (data, id) => {
    switch (data.typeSmall) {
      default:
      case 'docx': {
        return (
          <StyledTempImage
            draggable
            src='/resources/docx.svg'
            height={rem(120)}
            data-type='document'
            data-id={id}
            data-url={data.url}
            data-name={data.name}
            data-datatype={data.type}
          />
        );
      }
      case 'xlsx': {
        return (
          <StyledTempImage
            draggable
            src='/resources/excel.svg'
            height={rem(120)}
            data-type='document'
            data-id={id}
            data-url={data.url}
            data-name={data.name}
            data-datatype={data.type}
          />
        );
      }
      case 'doc': {
        return (
          <StyledTempImage
            draggable
            src='/resources/doc.svg'
            height={rem(120)}
            data-type='document'
            data-id={id}
            data-url={data.url}
            data-name={data.name}
            data-datatype={data.type}
          />
        );
      }
      case 'txt': {
        return (
          <StyledTempImage
            draggable
            src='/resources/txt.svg'
            height={rem(120)}
            data-type='document'
            data-id={id}
            data-url={data.url}
            data-name={data.name}
            data-datatype={data.type}
          />
        );
      }
      case 'ppt': {
        return (
          <StyledTempImage
            draggable
            src='/resources/ppt.svg'
            height={rem(120)}
            data-type='document'
            data-id={id}
            data-url={data.url}
            data-name={data.name}
            data-datatype={data.type}
          />
        );
      }
    }
  };

  return (
    <StyledMainWrapper
      onDrop={e => handleDrop(e, currentId)}
      onDragOver={e => handleDragOver(e, currentId)}
    >
      <Help
        outside
        activeDashboardId={activeDashboardId}
        currentId={currentId}
        url='/resources/sharinghelp.gif'
        title={t('documentsharing.title')}
        text={t('documentsharing.text')}
      />
      <StyledInnerWrapper>
        <Grid rows='1fr' scrollbar>
          <StyledWrapper className='editable'>
            <StyledContentWrapper>
              {documents && Object.keys(documents).length > 0 ? (
                Object.entries(documents).map(document => {
                  return (
                    <StyledItemWrapper key={document[1].time + 'wrapper'}>
                      <StyledButton
                        tooltip={{ text: t('tooltip:documentsharing.delete') }}
                        variant='icon'
                        onClick={() =>
                          handleDelete(document[1].name, document[0])
                        }
                        bgColor={theme.color.danger}
                        className='fileoption'
                        icon={{
                          icon: 'delete',
                          color: theme.color.light[100],
                        }}
                      />
                      <StyledGrid
                        rows='1fr'
                        scrollbar={
                          document[1].typeSmall !== 'ppt' &&
                          document[1].typeSmall !== 'xlsx' &&
                          document[1].typeSmall !== 'doc' &&
                          document[1].typeSmall !== 'docx' &&
                          document[1].typeSmall !== 'txt'
                            ? true
                            : false
                        }
                      >
                        {document[1].typeSmall !== 'ppt' &&
                        document[1].typeSmall !== 'xlsx' &&
                        document[1].typeSmall !== 'doc' &&
                        document[1].typeSmall !== 'docx' &&
                        document[1].typeSmall !== 'txt' &&
                        !document[1]['type'].includes('image') ? (
                          <FileViewer
                            fileType={document[1].typeSmall}
                            filePath={document[1].url}
                            key={document[1].time}
                            onError={() => onError()}
                          />
                        ) : document[1]['type'].includes('image') ? (
                          <StyledImage
                            data-type='document'
                            data-datatype={document[1].type}
                            data-id={document[0]}
                            data-url={document[1].url}
                            draggable
                            src={document[1].url}
                            data-name={document[1].name}
                          />
                        ) : (
                          getTemplate(document[1], document[0])
                        )}
                      </StyledGrid>

                      <Group justify='center'>
                        <StyledTitle>
                          {document[1]['name']
                            .split('.')
                            .slice(0, -1)
                            .join('.')}
                        </StyledTitle>
                        <StyledExtension>
                          {'.' + document[1].typeSmall}
                        </StyledExtension>
                      </Group>
                      <StyledGroup className='editable fileoption'>
                        {document[1].typeSmall !== 'ppt' &&
                          document[1].typeSmall !== 'xlsx' &&
                          document[1].typeSmall !== 'doc' &&
                          document[1].typeSmall !== 'docx' && (
                            <Button
                              tooltip={{
                                text: t('tooltip:documentsharing.preview'),
                              }}
                              variant='icon'
                              onClick={() =>
                                previewHandler(
                                  document[1].typeSmall,
                                  document[1].url,
                                  document[1].type,
                                  document[1].name
                                )
                              }
                              bgColor={theme.color.light[100]}
                              icon={{
                                icon: 'remove_red_eye',
                                color: theme.color.dark[100],
                              }}
                            />
                          )}
                        <Button
                          tooltip={{
                            text: t('tooltip:documentsharing.download'),
                          }}
                          variant='icon'
                          onClick={() =>
                            downloadHandler(
                              document[1].url,
                              document[1].typeSmall
                            )
                          }
                          bgColor={theme.color.light[100]}
                          icon={{
                            icon: 'get_app',
                            color: theme.color.dark[100],
                          }}
                        />
                      </StyledGroup>
                      <Tooltip delayShow={800} backgroundColor='#4057a2' />
                    </StyledItemWrapper>
                  );
                })
              ) : (
                <StyledEmptyWrapper
                  onClick={() =>
                    document
                      .getElementById('previewListAttachment' + currentId)
                      .click()
                  }
                >
                  <EmptyState
                    animation='empty-box'
                    height={200}
                    text={t('emptystate:documentsharing.title')}
                    hasButton={false}
                  />
                </StyledEmptyWrapper>
              )}
            </StyledContentWrapper>
          </StyledWrapper>
        </Grid>
      </StyledInnerWrapper>
      <StyledGroupUpload className='editable'>
        {documents &&
          Object.keys(documents).length > 0 &&
          totalItems &&
          maxItems && (
            <StyledIndicator ref={indicatorRef}>
              <StyledTextIndicator
                text={`${totalItems}/${maxItems}`}
                textColor='#fff'
                align='center'
                textStyle='description2'
              />
            </StyledIndicator>
          )}
        <Tooltip
          noSpacing
          id='documentupload'
          delayShow={800}
          backgroundColor='#4057a2'
          content={{
            title: {
              text: t('tooltip:documentsharing.upload'),
              textColor: '#fff',
              align: 'center',
            },
          }}
        />
        <StyledLabel data-tip='' data-for='documentupload'>
          <Ink background={false} />
          <Icon icon='add' iconSize='20' size={20} />
          <input
            type='file'
            id={`previewListAttachment` + currentId}
            onChange={e => onFileChange(e)}
            disabled={maxExceeded ? maxExceeded : false}
          />
          {maxExceeded && (
            <React.Fragment>
              <StyleImage
                src='/resources/information.svg'
                width={rem(20)}
                height={rem(20)}
                data-for={'tooltip-' + currentId}
                data-tip=''
              />
              <Tooltip
                id={'tooltip-' + currentId}
                content={{
                  title: {
                    text: t('tooltip:errors.documentsharing.title'),
                    textStyle: 'h6',
                    textColor: '#fff',
                    align: 'center',
                  },
                  animation: 'cry',
                  text: {
                    text: t('tooltip:errors.documentsharing.text'),
                    textStyle: 'subtitle',
                    textColor: '#fff',
                    align: 'center',
                  },
                }}
              />
            </React.Fragment>
          )}
          {errorSize && (
            <React.Fragment>
              <StyleImage
                src='/resources/information.svg'
                width={rem(20)}
                height={rem(20)}
                data-for={'tooltipSize-' + currentId}
                data-tip=''
              />
              <Tooltip
                id={'tooltipSize-' + currentId}
                content={{
                  title: {
                    text: t('tooltip:errors.documentsharing.largetitle'),
                    textStyle: 'h6',
                    textColor: '#fff',
                    align: 'center',
                  },
                  animation: 'cry',
                  text: {
                    text: t('tooltip:errors.documentsharing.largetext'),
                    textStyle: 'subtitle',
                    textColor: '#fff',
                    align: 'center',
                  },
                }}
              />
            </React.Fragment>
          )}
          {errorType && (
            <React.Fragment>
              <StyleImage
                src='/resources/information.svg'
                width={rem(20)}
                height={rem(20)}
                data-for={'tooltipType-' + currentId}
                data-tip=''
              />
              <Tooltip
                id={'tooltipType-' + currentId}
                content={{
                  title: {
                    text: t('tooltip:errors.documentsharing.typetitle'),
                    textStyle: 'h6',
                    textColor: '#fff',
                    align: 'center',
                  },
                  animation: 'cry',
                  text: {
                    text: t('tooltip:errors.documentsharing.typetext'),
                    textStyle: 'subtitle',
                    textColor: '#fff',
                    align: 'center',
                  },
                }}
              />
            </React.Fragment>
          )}
        </StyledLabel>
        {/* <Upload
            type="button"
            subType="documents"
            currentId={currentId}
            iconPadding={rem(12)}
            iconSize={20}
            iconName="add"
            preview
            previewId="previewListAttachment"
            onFileSelect={handleFiles}
            newFiles={files}
            bgColor={theme.color.dark[10]}
            iconColor={theme.color.dark[100]}
            isGallery
          /> */}
      </StyledGroupUpload>
    </StyledMainWrapper>
  );
};

/** pass currentUser and currentChannel from redux to global props */
const mapStateToProps = state => ({
  dragInfo: state.globaldraginfo.globalDragInfo,
  storeDrag: state.storewidgetdrag.storeWidgetDrag,
});

export default connect(mapStateToProps, {
  modalContent,
  modalOpen,
  modalType,
  globalDragInfo,
  storeWidgetDrag,
})(DocumentSharingContent);
