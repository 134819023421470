import { rem as polishedREM, mix, transparentize } from 'polished';

const DEFAULT_FONT = "'Roboto', sans-serif";
const DEFAULT_PX_SIZE = 16;

const colors = {
  primary: '#f95738',
  secondary: '#00b778',
  tertiary: '#0075ff',
  dark: '#0D1F2E',
  light: '#fff',
  success: '#00b778',
  warning: '#DEA051',
  danger: '#C94F5B',
  cancel: '#3e3e3e',
};

const theme = {
  color: {
    indicator: {
      settings: '#f2be4b',
      colors: '#609797',
      notifications: '#42bb9b',
      email: '#292e3a',
      powersettingsnew: '#D63232',
    },
    primary: {
      100: colors.primary,
      80: mix('0.8', colors.primary, '#fff'),
      60: mix('0.6', colors.primary, '#fff'),
      40: mix('0.4', colors.primary, '#fff'),
      20: mix('0.2', colors.primary, '#fff'),
      10: mix('0.1', colors.primary, '#fff'),
      8: mix('0.08', colors.primary, '#fff'),
      6: mix('0.06', colors.primary, '#fff'),
      4: mix('0.04', colors.primary, '#fff'),
      2: mix('0.02', colors.primary, '#fff'),
    },
    secondary: {
      100: colors.secondary,
      80: mix('0.8', colors.secondary, '#fff'),
      60: mix('0.6', colors.secondary, '#fff'),
      40: mix('0.4', colors.secondary, '#fff'),
      20: mix('0.2', colors.secondary, '#fff'),
      10: mix('0.1', colors.secondary, '#fff'),
      8: mix('0.08', colors.secondary, '#fff'),
      6: mix('0.06', colors.secondary, '#fff'),
      4: mix('0.04', colors.secondary, '#fff'),
      2: mix('0.02', colors.secondary, '#fff'),
    },
    tertiary: {
      100: colors.tertiary,
      80: mix('0.8', colors.tertiary, '#fff'),
      60: mix('0.6', colors.tertiary, '#fff'),
      40: mix('0.4', colors.tertiary, '#fff'),
      20: mix('0.2', colors.tertiary, '#fff'),
      10: mix('0.1', colors.tertiary, '#fff'),
      8: mix('0.08', colors.tertiary, '#fff'),
      6: mix('0.06', colors.tertiary, '#fff'),
      4: mix('0.04', colors.tertiary, '#fff'),
      2: mix('0.02', colors.tertiary, '#fff'),
    },
    dark: {
      100: colors.dark,
      80: transparentize('0.2', colors.dark),
      60: transparentize('0.4', colors.dark),
      40: transparentize('0.6', colors.dark),
      20: transparentize('0.8', colors.dark),
      10: transparentize('0.9', colors.dark),
      8: transparentize('0.92', colors.dark),
      6: transparentize('0.94', colors.dark),
      4: transparentize('0.96', colors.dark),
      2: transparentize('0.98', colors.dark),
    },
    light: {
      100: colors.light,
      80: transparentize('0.2', colors.light),
      60: transparentize('0.4', colors.light),
      40: transparentize('0.6', colors.light),
      20: transparentize('0.8', colors.light),
      10: transparentize('0.9', colors.light),
      8: transparentize('0.92', colors.light),
      6: transparentize('0.94', colors.light),
      4: transparentize('0.96', colors.light),
      2: transparentize('0.98', colors.light),
    },
    success: colors.success,
    warning: colors.warning,
    danger: colors.danger,
    cancel: colors.cancel,
  },
  font: {
    family: DEFAULT_FONT,
  },
  overLay: {
    modal: `rgba(0, 0, 0, 0.9)`,
  },
  spacing: {
    default: rem(8),
  },
  textStyle: {
    body: `
      font-family: ${DEFAULT_FONT};
      font-size: ${rem(14)};
      font-weight: 400;
      letter-spacing: ${rem(0.25)};
      line-height: ${rem(24)};
    `,
    body2: `
      font-family: ${DEFAULT_FONT};
      font-size: ${rem(16)};
      font-weight: 400;
      letter-spacing: ${rem(0.5)};
      line-height: ${rem(24)};
    `,
    button: `
      font-family: ${DEFAULT_FONT};
      font-size: ${rem(14)};
      font-weight: 500;
    `,
    largeButton: `
      font-size: ${rem(16)};
      font-weight: 800;
      text-transform: uppercase;
    `,
    caption: `
      font-family: ${DEFAULT_FONT};
      font-size: ${rem(10)};
      font-weight: 500;
      letter-spacing: ${rem(0.4)};
      line-height: ${rem(14)};
    `,
    description: `
      font-family: ${DEFAULT_FONT};
      font-size: ${rem(11)};
      font-weight: 500;
    `,
    description2: `
      font-family: ${DEFAULT_FONT};
      font-size: ${rem(11)};
      font-weight: 400;
    `,
    h1: `
      font-family: ${DEFAULT_FONT};
      font-size: ${rem(40)};
      font-weight: 500;
      letter-spacing: ${rem(-1)};
      line-height: ${rem(48)};
    `,
    h2: `
      font-family: ${DEFAULT_FONT};
      font-size: ${rem(32)};
      font-weight: 500;
      letter-spacing: ${rem(-0.5)};
    `,
    h3: `
      font-family: ${DEFAULT_FONT};
      font-size: ${rem(24)};
      font-weight: 300;
      line-height: ${rem(48)};
    `,
    h4: `
      font-family: ${DEFAULT_FONT};
      font-size: ${rem(20)};
      font-weight: 400;
      letter-spacing: ${rem(0.28)};
      line-height: ${rem(24)};
    `,
    h5: `
      font-family: ${DEFAULT_FONT};
      font-size: ${rem(18)};
      font-weight: 300;
      line-height: ${rem(22)};
    `,
    h6: `
      font-family: ${DEFAULT_FONT};
      font-size: ${rem(16)};
      font-weight: 600;
      letter-spacing: ${rem(0.16)};
      line-height: ${rem(20)};
    `,
    overline: `
      font-family: ${DEFAULT_FONT};
      font-size: ${rem(10)};
      font-weight: 500;
      letter-spacing: ${rem(0.4)};
      text-transform: uppercase;
    `,
    subtitle: `
      font-family: ${DEFAULT_FONT};
      font-size: ${rem(14)};
      font-weight: 800;
      letter-spacing: ${rem(0.1)};
      line-height: ${rem(24)};
    `,
  },
};

/**
 * Function to convert px to rem
 * @param {string} pxval
 * @param {string} base
 */
export function rem(pxval, base) {
  return polishedREM(pxval, base || DEFAULT_PX_SIZE);
}

export default theme;
