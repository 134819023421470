/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { connect } from 'react-redux';
import theme, { rem } from '../../theme';
import firebase, {
  createNewWidget,
  dashboardListner,
  userListner,
  userDashboardListner,
  documentSharingListner,
  masterListner,
} from '../../firebase';
import {
  cardItemIsDragging,
  storeWidgetDrag,
  storeWidgetDelete,
  modalType,
  modalOpen,
  globalDragInfo,
} from '../../redux/actions';
import Card from '../Card';
import Tour from '../Intro';
import { updateCursorEvent } from '../Cursor';
import CardContent from '../CardContent';
import Layoutwidget from '../LayoutWidget';

//Styles
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

const StyledMainWrapper = styled.div``;

const StyledLayout = styled(ResponsiveGridLayout)`
  ${({ cardColor }) => `
    min-height: 100vh;
    opacity: ${props => (props.ready ? 1 : 0)};
    * {
      box-sizing: border-box;
    }

    .react-grid-item.react-draggable-dragging {
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000;
      transition: none;
      z-index: 3;
    }

    .react-grid-item {
      background-color: ${cardColor};

      img {
        pointer-events: auto;
        user-select: none;  
      }

      &.react-grid-placeholder {
        border-top-left-radius: ${rem(30)};
        border-top-right-radius: ${rem(30)};
        border-bottom-left-radius: ${rem(30)};
        border-bottom-right-radius: ${rem(30 / 4)};
        background-color: ${theme.color.primary[100]};
      }

      > .react-resizable-handle {
        bottom: 3px !important;
        right: 3px !important;

        &:after {
          width: 8px;
          height: 8px;
          border-right: 2px solid rgba(0, 0, 0, 1);
          border-bottom: 2px solid rgba(0, 0, 0, 1);
        }
      }

      main {
        flex-grow: 1;
      }
    }
  `}

  .react-resizable {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: ${rem(18)};
  }
`;

const StyledEmptyWrapper = styled.div`
  border-top-left-radius: ${rem(30)};
  border-top-right-radius: ${rem(30)};
  border-bottom-left-radius: ${rem(30)};
  border-bottom-right-radius: ${rem(30 / 4)};
`;

/** Returns styled Layout */
const Layout = props => {
  const {
    activeDashboardId,
    userData,
    isPayedUser,
    storeWidget,
    storeDelete,
  } = props;
  const [ready, setReady] = useState(false);
  const uuid = firebase.auth().currentUser.uid;
  const [layoutWidgets, setLayoutWidgets] = useState({});
  const [layout, setLayout] = useState();
  const [itemBackgroundColor, setItemBackgroundColor] = useState();
  const [payedUser, setPayedUser] = useState();
  const [continueTour, setContinueTour] = useState(false);

  //Permissions
  const [permissionResize, setPermissionResize] = useState();
  const [permissionDrag, setPermissionDrag] = useState();
  const [isMaster, setIsMaster] = useState(false);
  const [canDrop, setCanDrop] = useState(true);

  //States
  const testValue = useRef(false);
  const dragRef = useRef();
  //Refs
  const ref = firebase
    .database()
    .ref('dashboards')
    .child(activeDashboardId);

  /** onDrag */
  const onDrag = layout => {
    testValue.current.value = layout;
  };

  /**
   * On Drag Enter
   */
  const dragEnterHandler = (e, id) => {
    //console.log('BINGO', dragRef.current.value);
  };

  /**
   * On Drag Enter
   */
  const dragLeaveHandler = (e, id) => {
    const parentNode = document.getElementById(id);
    const childNode = e.target;

    /**
     * Check if child is a parent
     * @param {*} parentNode
     * @param {*} childNode
     */
    function checkIfChild(parentNode, childNode) {
      if ('contains' in parentNode) {
        return parentNode.contains(childNode);
      } else {
        return parentNode.compareDocumentPosition(childNode) % 16;
      }
    }

    if (checkIfChild(parentNode, childNode)) {
      document.getElementById(id).style.backgroundColor = '';
    }

    // if (!parent.contains(child)) {
    //   console.log('BINGO SB', id);
    //   document.getElementById(id).style.backgroundColor = '';
    // }
    //document.getElementById(id).style.backgroundColor = '';
  };

  /** Handle the tour drop */
  const handleTour = () => {
    document.body.classList.remove('progresstut');
    document.body.classList.remove('tut');
    setContinueTour(true);
  };

  /** On drop function */
  const onDrop = elemParams => {
    const { storeWidget, storeWidgetDrag, cardItemIsDragging } = props;
    const latestLayout = testValue.current.value;
    const newDataArray = [];

    if (!storeWidget || Object.keys(storeWidget).length <= 0) {
      props.cardItemIsDragging(false);
      props.storeWidgetDrag(false);
      dragRef.current.value = false;
      return;
    }
    //Check if it is a drop from the tutorial
    if (document.body.classList.contains('progresstut')) {
      handleTour();
    } else {
      setContinueTour(false);
    }

    Object.values(latestLayout).map(item => {
      if (item.i) {
        newDataArray.push(item);
      }
    });
    cardItemIsDragging(false);
    storeWidgetDrag(false);
    dragRef.current.value = false;

    //Path
    let path = 'dashboards/' + activeDashboardId + '/widgets/';

    // Database Reference
    const widgetRef = firebase
      .database()
      .ref()
      .child(path);
    // Get a key for a new Post.
    const newPostKey = widgetRef.push().key;

    const newItem = newDataArray.slice(-1).pop();
    if (elemParams['e']) delete elemParams['e'];
    newItem['i'] = newPostKey;
    newItem['minW'] = 4;
    newItem['minH'] = 8;
    newItem['moved'] = false;
    newItem['static'] = false;

    newDataArray.pop();
    newDataArray.unshift(newItem);

    setLayout({ lg: newDataArray });

    firebase
      .database()
      .ref('dashboards')
      .child(activeDashboardId)
      .update({
        layout: JSON.stringify(newDataArray),
        lastEditedBy: uuid,
      })
      .then(() => {
        createNewWidget(
          storeWidget,
          activeDashboardId,
          activeDashboardId,
          newPostKey
        );
      });
  };

  useEffect(() => {
    document.getElementById('root').addEventListener(
      'dragleave',
      function(e) {
        if (!e.clientX && !e.clientY) {
          props.cardItemIsDragging(false);
          props.storeWidgetDrag(false);
          dragRef.current.value = false;
        }
      },
      false
    );

    document.getElementById('root').addEventListener('dragstart', function(e) {
      // console.log('MARTIEM start', e.srcElement.childElementCount);
      if (e.srcElement.childElementCount === 0) {
        props.globalDragInfo(e.target.dataset);
        dragRef.current.value = false;
      } else {
        dragRef.current.value = true;
      }
    });

    document.getElementById('root').addEventListener(
      'dragenter',
      function(e) {
        if (!dragRef.current.value) {
          props.cardItemIsDragging(false);
          props.storeWidgetDrag(false);
          setCanDrop(false);
        } else {
          setCanDrop(true);
        }
        if (e.target.id !== 'dragActionButton') {
          props.cardItemIsDragging(false);
          if (dragRef.current.value) props.storeWidgetDrag(true);
        }
      },
      false
    );

    masterListner.child('members').once('value', snapMaster => {
      if (snapMaster.exists()) {
        snapMaster.forEach(function(snapMasterChild) {
          if (snapMasterChild.key === uuid) {
            setIsMaster(true);
          }
        });
      }
    });

    userListner
      .child(uuid)
      .child('isPayedUser')
      .on('value', snap => {
        if (snap.val()) {
          setPayedUser(snap.val());
        }
      });

    /** User permissions */
    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('permissions')
      .child('resize')
      .on('value', snap => {
        setPermissionResize(snap.val());
      });

    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('permissions')
      .child('drag')
      .on('value', snap => {
        setPermissionDrag(snap.val());
      });

    dashboardListner
      .child(activeDashboardId)
      .once('value', snap => {
        if (snap.val()) {
          if (!ready && snap.val().layout) {
            setLayout({ lg: JSON.parse(snap.val().layout) });
            setLayoutWidgets(snap.val().widgets);
          }
        }
      })
      .then(() => {
        /** User Dashboard Listner */
        userDashboardListner
          .child(uuid)
          .child(activeDashboardId)
          .child('colors')
          .child('cardColor')
          .on('value', snapDashboard => {
            setItemBackgroundColor(snapDashboard.val());
          });
      })
      .then(() => {
        dashboardListner
          .child(activeDashboardId)
          .child('widgets')
          .orderByChild('time')
          .startAt(Date.now())
          .on('child_added', snap => {
            if (snap.key) {
              props.cardItemIsDragging(false);
              if (layoutWidgets) {
                layoutWidgets[snap.key] = {};
                layoutWidgets[snap.key] = snap.val();
                setLayoutWidgets(prevState => {
                  // Object.assign would also work
                  return { ...prevState, ...layoutWidgets };
                });
                setReady(true);
              }
            }
          });

        dashboardListner
          .child(activeDashboardId)
          .child('widgets')
          .on('child_removed', snap => {
            if (snap.key) {
              delete layoutWidgets[snap.key];
              dashboardListner
                .child(activeDashboardId)
                .child('widgets')
                .once('value', snapShot => {
                  setLayoutWidgets(snapShot.val());
                })
                .then(() => {
                  dashboardListner
                    .child(activeDashboardId)
                    .child('layout')
                    .once('value', snapChild => {
                      if (snapChild.val()) {
                        const currentLayout = JSON.parse(snapChild.val());
                        const newDataArray = [];
                        Object.values(currentLayout).map(item => {
                          if (item.i && item.i !== snap.key) {
                            newDataArray.push(item);
                          }
                        });

                        if (newDataArray.length > 0) {
                          setLayout({ lg: newDataArray });
                          dashboardListner.child(activeDashboardId).update({
                            layout: JSON.stringify(newDataArray),
                            lastEditedBy: uuid,
                          });
                        } else {
                          dashboardListner
                            .child(activeDashboardId)
                            .child('layout')
                            .remove();
                        }
                      }
                    });
                });
            }
          });
      })
      .then(() => {
        const dashboardLayoutListner = dashboardListner.child(
          activeDashboardId
        );

        dashboardLayoutListner.child('layout').once('value', snap => {
          if (snap.val()) {
            setLayout({ lg: JSON.parse(snap.val()) });
          }
        });

        dashboardLayoutListner.child('layout').on('value', snap => {
          if (snap.val()) {
            const parentListner = snap.ref.parent;

            parentListner.child('lastEditedBy').once('value', snapParent => {
              if (snapParent.val() && snapParent.val() !== uuid) {
                setLayout({ lg: JSON.parse(snap.val()) });
              }
            });
          }
        });
        setReady(true);
        testValue.current.value = null;
      });
  }, [permissionDrag]);

  /** Remove item images */
  const removeItemImages = (items, type, typeId) => {
    Object.values(items).map(item => {
      if (item.images && item.images !== '') {
        const images = item.images;
        Object.values(images).map(image => {
          if (image) {
            firebase
              .storage()
              .ref()
              .child(type)
              .child(typeId)
              .child('items')
              .child('public')
              .child(image.storageId)
              .delete();
          }
        });
      }
    });
  };

  /** Calls when drag starts. */
  const onDragStart = (layout, oldItem, newItem, placeholder, e, element) => {
    const cardId = element.id;

    if (document.getElementById(cardId)) {
      document.getElementById(cardId).style.backgroundColor = '';
      document.getElementById(cardId).style.transition = '';
    }
    // eslint-disable-next-line react/prop-types
    const {
      storeDrag,
      cardItemIsDragging,
      storeWidgetDelete,
      globalDragInfo,
    } = props;
    // storeWidgetDrag(false);
    if (!storeDrag) cardItemIsDragging(true);
    storeWidgetDelete(false);
    globalDragInfo({});
    updateCursorEvent(uuid, activeDashboardId, 'dragging');
  };

  /** Remove WIDGET */
  const removeWidget = (cardId, event) => {
    if (event && Object.keys(event).length > 0) {
      const { storeWidgetDelete } = props;
      delete layoutWidgets[cardId];
      //Loop through the widgets and find the widget that have to be removed
      Object.entries(event).map(item => {
        if (item[1].i === cardId) {
          dashboardListner
            .child(activeDashboardId)
            .child('widgets')
            .child(cardId)
            .once('value', snapShot => {
              // Remove from the pivot table also
              const table = snapShot.val().type;
              const pivotName =
                'user' + table.charAt(0).toUpperCase() + table.slice(1);

              firebase
                .database()
                .ref()
                .child(table)
                .child(snapShot.val().typeId)
                .child('attendees')
                .once('value', snapREM => {
                  snapREM.forEach(function(childattendee) {
                    const pivotListner = firebase
                      .database()
                      .ref()
                      .child(pivotName)
                      .child(childattendee.key);

                    //Loop through all pivot items and check if the cardId === widgetId
                    pivotListner.once('value', function(snapRemove) {
                      snapRemove.forEach(function(childSnapshotRemove) {
                        if (childSnapshotRemove.val().widgetId === cardId) {
                          documentSharingListner
                            .child(childSnapshotRemove.val().id)
                            .once('value', snapGetFile => {
                              if (
                                snapGetFile.val() &&
                                snapGetFile.val().documents
                              ) {
                                var promises = [];
                                Object.values(snapGetFile.val().documents).map(
                                  file => {
                                    firebase
                                      .storage()
                                      .ref()
                                      .child('documents')
                                      .child(childSnapshotRemove.val().id)
                                      .child(file.name)
                                      .delete()
                                      .then(() => {
                                        // firebase
                                        //   .database()
                                        //   .ref()
                                        //   .child(table)
                                        //   .child(childSnapshotRemove.val().id)
                                        //   .remove();

                                        promises.push(
                                          childSnapshotRemove.val().id
                                        );
                                      });
                                  }
                                );

                                Promise.all(promises).then(() => {
                                  firebase
                                    .database()
                                    .ref()
                                    .child(table)
                                    .child(childSnapshotRemove.val().id)
                                    .remove();
                                });
                              } else {
                                firebase
                                  .database()
                                  .ref()
                                  .child(table)
                                  .child(childSnapshotRemove.val().id)
                                  .child('items')
                                  .once('value', snapRemoveData => {
                                    //Remove all images from the storage (if there are any)
                                    if (snapRemoveData.exists()) {
                                      removeItemImages(
                                        snapRemoveData.val(),
                                        table,
                                        childSnapshotRemove.val().id
                                      );
                                    }
                                  })
                                  .then(() => {
                                    firebase
                                      .database()
                                      .ref()
                                      .child(table)
                                      .child(childSnapshotRemove.val().id)
                                      .set({ remove: true });
                                    setTimeout(function() {
                                      firebase
                                        .database()
                                        .ref()
                                        .child(table)
                                        .child(childSnapshotRemove.val().id)
                                        .remove();
                                    }, 1500);
                                  });
                              }
                            })
                            .then(() => {
                              pivotListner
                                .child(childSnapshotRemove.val().id)
                                .remove();
                            });
                        }
                      });
                    });
                  });
                });

              // dashboardListner
              //   .child(activeDashboardId)
              //   .child('removedWidgets')
              //   .child(cardId)
              //   .set(snapShot.val());
            })
            .then(() => {
              dashboardListner
                .child(activeDashboardId)
                .child('widgets')
                .child(cardId)
                .remove();
            })
            .then(() => {
              //Remove the userDashboard widget and handle the number
              dashboardListner
                .child(activeDashboardId)
                .child('members')
                .once('value', function(snap) {
                  snap.forEach(function(childSnapshot) {
                    if (childSnapshot.val()) {
                      userDashboardListner
                        .child(childSnapshot.key)
                        .child(activeDashboardId)
                        .child('widgets')
                        .child(cardId)
                        .remove()
                        .then(() => {
                          userDashboardListner
                            .child(childSnapshot.key)
                            .child(activeDashboardId)
                            .child('totalWidgets')
                            .transaction(function(totalWidgets) {
                              return (totalWidgets || 1) - 1;
                            });
                        });
                    }
                  });
                });
            });
        }
      });
      storeWidgetDelete(false);
    }
  };

  /** Calls when drag is complete. */
  const onDragStop = (event, oldItem) => {
    if (ready) {
      if (storeDelete) {
        const cardId = oldItem.i;
        props.modalOpen(true);
        props.modalType([
          'removeWidget',
          activeDashboardId,
          uuid,
          cardId,
          removeWidget,
          event,
        ]);
        updateCursorEvent(uuid, activeDashboardId, 'default');
      } else {
        const newDataArray = [];
        Object.values(event).map(item => {
          if (item.i) {
            newDataArray.push(item);
          }
        });
        ref
          .update({
            layout: JSON.stringify(newDataArray),
            lastEditedBy: uuid,
          })
          .then(() => {
            updateCursorEvent(uuid, activeDashboardId, 'default');
          });
      }
    }
    // eslint-disable-next-line react/prop-types
    const { cardItemIsDragging } = props;
    cardItemIsDragging(false);
  };

  /** Calls when resizing is complete. */
  const onResizeStop = event => {
    if (ready) {
      ref
        .update({
          layout: JSON.stringify(event),
          lastEditedBy: uuid,
        })
        .then(() => {
          updateCursorEvent(uuid, activeDashboardId, 'default');
        });
    }
  };

  /** Calls when resizing is starting. */
  const onResize = () => {
    if (ready) {
      console.log('test');
      updateCursorEvent(uuid, activeDashboardId, 'layoutResize');
    }
  };

  /** Mouse enter handler */
  const mouseEnterHandler = id => {
    document.getElementById(id).style.zIndex = 10;
  };

  /** Mouse leave handler */
  const mouseLeaveHandler = id => {
    document.getElementById(id).style.zIndex = '';
  };

  const children = useMemo(() => {
    return (
      layout &&
      layoutWidgets &&
      Object.entries(layout['lg']).map(widget => {
        widget[1].isDraggable = permissionDrag || isMaster ? true : false;

        const widgetId = widget[1].i;
        if (!layoutWidgets[widgetId]) {
          return <StyledEmptyWrapper key={widgetId} />;
        }
        return (
          widgetId && (
            <Card
              onMouseLeave={() =>
                mouseLeaveHandler(
                  layoutWidgets[widgetId] && layoutWidgets[widgetId].typeId
                )
              }
              onMouseEnter={() =>
                mouseEnterHandler(
                  layoutWidgets[widgetId] && layoutWidgets[widgetId].typeId
                )
              }
              onDragEnter={e =>
                dragEnterHandler(
                  e,
                  layoutWidgets[widgetId] && layoutWidgets[widgetId].typeId
                )
              }
              onDragLeave={e =>
                dragLeaveHandler(
                  e,
                  layoutWidgets[widgetId] && layoutWidgets[widgetId].typeId
                )
              }
              activeDashboardId={activeDashboardId}
              id={layoutWidgets[widgetId] && layoutWidgets[widgetId].typeId}
              key={layoutWidgets[widgetId] && layoutWidgets[widgetId].id}
              type={layoutWidgets[widgetId] && layoutWidgets[widgetId].type}
              UserIndicator='right'
              data-grid={widget[1]}
              dragInfo={props.listItemDragInfo}
            >
              <CardContent scrollbar={false} className='editable'>
                <Layoutwidget
                  data={layoutWidgets[widgetId] && layoutWidgets[widgetId]}
                  type={layoutWidgets[widgetId] && layoutWidgets[widgetId].type}
                  id={widgetId}
                  widgetId={widgetId}
                  hasOverview={
                    layoutWidgets[widgetId] &&
                    layoutWidgets[widgetId].hasOverview
                  }
                  userData={userData}
                  activeDashboardId={activeDashboardId}
                  resizeMax={
                    layoutWidgets[widgetId] && layoutWidgets[widgetId].resizeMax
                  }
                  resizeMin={
                    layoutWidgets[widgetId] && layoutWidgets[widgetId].resizeMin
                  }
                />
              </CardContent>
            </Card>
          )
        );
      })
    );
  });

  return ready && storeWidget ? (
    <StyledMainWrapper ref={dragRef}>
      {continueTour && <Tour start tutNumber={2} />}
      <StyledLayout
        {...props}
        ref={testValue}
        ready={ready}
        className='layout'
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 8, sm: 4, xs: 4, xxs: 2 }}
        draggableCancel='.editable'
        margin={[15, 15]}
        useCSSTransforms
        layouts={layout}
        initialLayout={layout}
        onDrag={onDrag}
        onDragStart={onDragStart}
        onDragStop={onDragStop}
        onResizeStop={onResizeStop}
        onResize={onResize}
        onDrop={onDrop}
        droppingItem={{
          i: storeWidget && storeWidget.typeId ? storeWidget.typeId : 'empty',
          w: 4,
          h: 8,
        }}
        measureBeforeMount
        cardColor={payedUser ? itemBackgroundColor : '#fff'}
        isDroppable={canDrop}
        isResizable={permissionResize || isMaster}
      >
        {children}
      </StyledLayout>
    </StyledMainWrapper>
  ) : (
    <React.Fragment />
  );
};

Layout.propTypes = {
  /** Dashboard Id */
  activeDashboardId: PropTypes.string,
  /** Overall classname */
  className: PropTypes.string,
  /** Handle function when the layout is changed */
  onLayoutChange: PropTypes.func,
  /** Defines the row Height of the layout */
  rowHeight: PropTypes.number,
  /** The column breakpoints */
  cols: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /** Generate layout function */
  initialLayout: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  /** The current user */
  currentUser: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /** userData */
  userData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /** dashboardData */
  dashboardData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /** userDashboardData */
  userDashboardData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Layout.defaultProps = {
  activeDashboardId: null,
  className: 'layout',
  rowHeight: 30,
  /** onLayoutChange function */
  onLayoutChange: function() {},
  cols: { lg: 12, md: 8, sm: 8, xs: 4, xxs: 2 },
  initialLayout: null,
  currentUser: null,
  userData: null,
  dashboardData: null,
  userDashboardData: null,
};

/** pass currentUser and currentChannel from redux to global props */
const mapStateToProps = state => ({
  storeWidget: state.storewidgetinfo.storeWidgetInfo,
  storeDrag: state.storewidgetdrag.storeWidgetDrag,
  storeDelete: state.storewidgetdelete.storeWidgetDelete,
  cardDragging: state.cardisdragging.cardisDragging,
});

/** @component */
export default connect(mapStateToProps, {
  cardItemIsDragging,
  storeWidgetDrag,
  storeWidgetDelete,
  modalType,
  modalOpen,
  globalDragInfo,
})(Layout);
