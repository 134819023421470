import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';
import { notificationType, modalOpen, modalType } from '../../redux/actions';
import theme, { rem } from '../../theme';
import Group from '../ButtonGroup';
import Icon from '../Icon';
import Button from '../Button';
import { removeSubItem } from '../../firebase';

const animation = keyframes`
  from {
    opacity: 0;
    visibility: visible;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
`;

const MixinFadeIn = css`
  transform-origin: top;
  animation: ${animation} 0.3s ease;
  animation-delay: 0s;
  animation-fill-mode: forwards;
`;

const buttons = css`
  ${MixinFadeIn}
  visibility: hidden;
  width: calc(100% + 28px);
  margin-top: 14px;
  margin-left: -14px;
  margin-right: -14px;
  margin-bottom: -14px;
  flex-grow: 1;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.1);
`;

const overlay = css`
  position: absolute;
  margin: 0;
  width: 100%;
`;

const StyledWrapper = styled.div`
  position: ${props => (props.showOptions ? 'relative' : 'absolute')};
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;

  ${props => (props.showOptions ? buttons : null)}
  ${props => (props.styleType === 'overlay' ? overlay : null)}
`;

const StyledGroup = styled(Group)`
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  position: absolute;
  top: -30px;
  transform: ${props => (props.showOptions ? 'rotate(180deg)' : null)};

  &:focus {
    box-shadow: none;
  }
`;

/**
 * Return a styled component
 */
const OptionGroup = props => {
  const {
    showDelete,
    showShare,
    showChat,
    showNote,
    showMail,
    showImage,
    itemId,
    parentId,
    data,
    notificationType,
    groupType,
    table,
    itemTypes,
    styleType,
  } = props;
  const [showOptions, setShowOptions] = useState(false);

  /** Onclick handler */
  const HandleOnclick = () => {
    setShowOptions(!showOptions);
  };

  /** Onclick handler Share Between Cards */
  const handleShareBetweenCards = () => {
    notificationType(null);
    notificationType(
      JSON.stringify({
        name: 'shareBetweenCards',
        text: ' ',
        listId: parentId,
        itemId: itemId,
        table: table,
        itemTypes: itemTypes,
        path: table + '/' + parentId + '/' + itemTypes + '/' + itemId,
      })
    );
  };

  /** Onclick on a button */
  const HandleActionClick = type => {
    switch (type) {
      default:
      case 'removeItem':
        notificationType(null);
        notificationType(
          JSON.stringify({
            parentId: parentId,
            itemId: itemId,
            name: 'removeItem',
            path: table + '/' + parentId + '/' + itemTypes + '/' + itemId,
            text: 'Are you sure you want to delete this item?',
            table: table,
          })
        );

        document.getElementsByClassName(itemId)[0].classList.add('deletion');
        break;
    }
  };

  /** Trigger the preview modal onClick */
  const imageOnclickHandler = () => {
    props.modalOpen(true);
    props.modalType(['gallery', data['images'], itemId, parentId]);
  };

  const buttonOptions = (
    <StyledGroup>
      {showDelete ? (
        <Button
          variant="icon"
          onClick={() => removeSubItem(table, parentId, itemId)}
          bgColor={theme.color.danger}
          className="image-gallery-custom-action"
        >
          <Icon icon="delete" padding={rem(6)} size={16} color="#fff" />
        </Button>
      ) : null}
      {showImage ? (
        <Button
          variant="icon"
          onClick={() => imageOnclickHandler()}
          bgColor={theme.color.dark[10]}
        >
          <Icon icon="image" padding={rem(6)} size={16} color="#fff" />
        </Button>
      ) : null}
    </StyledGroup>
  );

  /** Return the correct template */
  const template = () => {
    switch (groupType) {
      default:
        return (
          <StyledWrapper
            styleType={styleType}
            showOptions={showOptions}
            className={showOptions ? 'optionGroup active' : 'optionGroup'}
          >
            <StyledButton
              variant="icon"
              onClick={() => HandleOnclick(data, parentId, itemId)}
              showOptions={showOptions}
            >
              <Icon
                icon="expand_more"
                padding={rem(6)}
                size={24}
                color="#fff"
              />
            </StyledButton>
            {showOptions ? buttonOptions : null}
          </StyledWrapper>
        );
    }
  };

  return template();
};
OptionGroup.propTypes = {
  parentId: PropTypes.string,
  itemId: PropTypes.string,
  showDelete: PropTypes.bool,
  showShare: PropTypes.bool,
  showChat: PropTypes.bool,
  showNote: PropTypes.bool,
  showMail: PropTypes.bool,
  showImage: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.object]),
  notificationType: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  groupType: PropTypes.oneOf(['default', 'inNotification']),
  styleType: PropTypes.oneOf(['default', 'overlay']),
};

OptionGroup.defaultProps = {
  parentId: '',
  itemId: '',
  showDelete: false,
  showShare: false,
  showChat: false,
  showNote: false,
  showMail: false,
  showImage: false,
  data: null,
  notificationType: null,
  groupType: 'default',
  styleType: 'default',
};

OptionGroup.displayName = 'OptionGroup';

/**
 * @component
 */
/** @component */
export default connect(
  null,
  { notificationType, modalOpen, modalType }
)(OptionGroup);
