import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as MaterialDesign from 'react-icons/md';
import theme, { rem } from '../../theme';

const defaultStyles = `
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  font-style: normal;
`;

const StyledIcon = styled.i`
  ${defaultStyles}
  color: ${props => props.color};
  background-color: ${props => props.background};
  ${props => shapeStyle[props.shape]}
  ${props => props.shape && shapeSize(props.size)};
  font-size: ${props => rem(props.size)};
  padding: ${props => props.padding};
  border: ${rem(1)} solid ${props => props.borderColor};
`;

const StyledIconText = styled(StyledIcon)`
  ${theme.textStyle.body};
  font-size: ${props => rem(props.size - props.size / 2.4)};
`;

const shapeStyle = {
  round: {
    borderRadius: '50%',
  },
  square: {
    borderRadius: 'none',
  },
};

/**
 * Shape size
 */
const shapeSize = size => `
  width: ${rem(size + 12)};
  height: ${rem(size + 12)};
`;

/**
 * @param {string} text
 * @param {string} color
 * @param {string} padding
 * @param {string} borderColor
 * @param {string} id
 * @param {string} icon
 * @param {number} size
 * @param {string} background
 * @param {string} shape
 */
const Icon = ({
  text,
  color,
  padding,
  borderColor,
  id,
  background,
  shape,
  icon,
  size,
  ...props
}) => {
  let mdIcon = null;
  let name = '';

  if (icon) {
    name = icon
      .toLowerCase()
      .split('_')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join('');
    mdIcon = React.createElement(MaterialDesign[`Md${name}`]);
  }

  let child = (
    <StyledIcon
      size={size}
      name={name}
      background={background}
      shape={shape}
      padding={padding}
      borderColor={borderColor}
      id={id}
      color={color}
      {...props}
    >
      {mdIcon}
    </StyledIcon>
  );

  if (text) {
    child = (
      <StyledIconText
        size={size}
        color={color}
        text={text}
        background={background}
        shape={shape}
        id={id}
        {...props}
      >
        {text}
      </StyledIconText>
    );
  }

  return child;
};

Icon.propTypes = {
  /** Border color */
  borderColor: PropTypes.string,

  /** Element Id */
  id: PropTypes.string,

  /** Background color */
  background: PropTypes.string,

  /** Icon shape */
  shape: PropTypes.oneOf(['round', 'square']),

  /** Icon size (using pixels as a reference) */
  size: PropTypes.oneOf([
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    14,
    16,
    18,
    20,
    24,
    32,
    36,
    40,
    48,
    56,
    64,
    72,
    76,
  ]),

  /** Icon name reference */
  icon: PropTypes.string.isRequired,

  /** Icon color */
  color: PropTypes.string,

  /** Icon text */
  text: PropTypes.string,

  /** Spacing */
  padding: PropTypes.string,
};

Icon.defaultProps = {
  borderColor: 'transparent',
  size: 16,
  padding: '0',
  text: null,
  background: 'transparent',
  shape: null,
  color: 'inherit',
  id: null,
};

Icon.displayName = 'Icon';

/** @component */
export default Icon;
