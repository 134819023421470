//user action types
export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';

//channel action types
export const SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL';

//list action types
export const SET_CURRENT_LIST = 'SET_CURRENT_LIST';

//colors
export const SET_COLOR_PRIMARY = 'SET_COLOR_PRIMARY';
export const SET_COLOR_BACKGROUND = 'SET_COLOR_BACKGROUND';
export const SET_COLOR_CARD = 'SET_COLOR_CARD';

export const SET_COLOR_SIDEBAR = 'SET_COLOR_SIDEBAR';
export const SET_COLOR_ICON = 'SET_COLOR_ICON';

export const SET_COLOR_LISTITEM = 'SET_COLOR_LISTITEM';
export const SET_COLOR_CHECKBOX = 'SET_COLOR_CHECKBOX';

//isDragging table
export const LISTITEM_IS_DRAGGING = 'LISTITEM_IS_DRAGGING';

//isDragging for cards
export const CARDITEM_IS_DRAGGING = 'CARDITEM_IS_DRAGGING';

//modalContent for cards
export const MODAL_CONTENT = 'MODAL_CONTENT';

//modalOpen for cards
export const MODAL_OPEN = 'MODAL_OPEN';

//modalType for cards
export const MODAL_TYPE = 'MODAL_TYPE';

//listUpdated for cards
export const LIST_UPDATED = 'LIST_UPDATED';

//userSelection for modal
export const USER_SELECTION = 'USER_SELECTION';

//createdList for modal
export const CREATED_LIST = 'CREATED_LIST';

//selectedList for lists
export const SELECTED_LIST = 'SELECTED_LIST';

//selectedList for chats
export const SELECTED_CHAT = 'SELECTED_CHAT';

//selectedList for notes
export const SELECTED_NOTE = 'SELECTED_NOTE';

//selectedList for drawings
export const SELECTED_DRAW = 'SELECTED_DRAW';

//notificationType for lists
export const NOTIFICATION_TYPE = 'NOTIFICATION_TYPE';

//listItemDragInfo for drags
export const LISTITEM_DRAG_INFO = 'LISTITEM_DRAG_INFO';

//globalDragInfo for drags
export const GLOBAL_DRAG_INFO = 'GLOBAL_DRAG_INFO';

//listItemDropInfo for drags
export const LISTITEM_DROP_INFO = 'LISTITEM_DROP_INFO';

//storeWidgetInfo for drags
export const STORE_WIDGET_INFO = 'STORE_WIDGET_INFO';

//trigger widgetstore to go down on StoreWidget dragStart
export const STORE_WIDGET_DRAG = 'STORE_WIDGET_DRAG';

export const STORE_WIDGET_DELETE = 'STORE_WIDGET_DELETE';

//Can be removed later
export const ADD_TODO = 'ADD_TODO';
export const TOGGLE_TODO = 'TOGGLE_TODO';
export const SET_FILTER = 'SET_FILTER';

//set video location active
export const ACTIVE_VIDEO_LOCATION = 'ACTIVE_VIDEO_LOCATION';

//migrate channel call key
export const CHANNEL_KEY = 'CHANNEL_KEY';

//set videocall active
export const TRANSFER_MY_VIDEO = 'TRANSFER_MY_VIDEO';
export const TRANSFER_REMOTE_VIDEO = 'TRANSFER_REMOTE_VIDEO';
