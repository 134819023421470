/* eslint-disable react/no-find-dom-node */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { modalOpen, modalType } from '../../redux/actions';
import PropTypes from 'prop-types';
import Group from '../Group';
import Button from '../Button';
import Text from '../Text';
import Image from '../Image';
import Lottie from '../LottieControl';
import { addNotification, dashboardListner, userDashboardListner } from '../../firebase';
import theme, { rem } from '../../theme';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledTextIntro = styled(Text)`
  margin-bottom: ${rem(20)};
`;

const StyledImage = styled(Image)`
  margin-bottom: ${rem(20)};
  width: 100%;
  height: ${rem(250)};
  object-fit: contain;
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(10)};
`;

const StyledContentWrapper = styled(Group)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

const StyledButtonGroup = styled(Group)`
  margin-top: ${rem(20)};

  button {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: ${rem(20)};
    width: ${rem(350)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledMessage = styled(Text)`
  background: ${theme.color.dark[40]};
  padding: ${rem(8)} ${rem(10)};
  border-radius: ${rem(5)};
}
`;

const StyledLoading = styled(Lottie)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledGroup = styled(Group)`
  flex-direction: column;
  margin-bottom: ${rem(10)};

  &:last-child {
    margin-bottom: 0;
  }
`;

/** todo */
const savePermissions = props => {
  const { activeDashboardId, saveObject, closeModals } = props;

  //States
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
    document.getElementById('globalModal').firstElementChild.style.height =
      'auto';
    document.getElementById('globalModal').firstElementChild.style.width =
      '25rem';

    setLoading(false);
  }, []);

  /** Get all the dashboard data */
  const getData = () => {};

  /** Save the permissions */
  const save = () => {
    Object.entries(saveObject).map(item => {
      dashboardListner
        .child(activeDashboardId)
        .child('permissions')
        .update({[item[0]]: item[1]})
        .then(() => {
          //@TODO::CHECK ALL MEMBERS WITHOUT AN ASSIGNED ROLE AND ASSIGN THEM WITH THIS NEW PERMISSIONS
          dashboardListner
            .child(activeDashboardId)
            .child('members')
            .once('value', snap => {
              if (snap.val()) {
                snap.forEach(function(snapMember) {
                  userDashboardListner
                    .child(snapMember.key)
                    .child(activeDashboardId)
                    .child('role')
                    .once('value', snapCheck => {
                      if (!snapCheck.val()) {
                          dashboardListner
                            .child(activeDashboardId)
                            .once('value', snapPerm => {
                              if (!snapPerm.val()['creators'].hasOwnProperty(snapMember.key)) {
                                userDashboardListner
                                  .child(snapMember.key)
                                  .child(activeDashboardId)
                                  .child('permissions')
                                  .once('value', snapshot => {
                                    snapshot.forEach(function(snapType) {
                                      userDashboardListner
                                        .child(snapMember.key)
                                        .child(activeDashboardId)
                                        .child('permissions')
                                        .update({[snapType.key]: snapPerm.val()['permissions'].everybody});
                                    });
                                  });
                              }
                            });
                      }
                    });
                });
              }
            })
            .then(() => {
              closeModals();
            });
        });
    });
  };

  /** Return Chat messages */
  return loading ? (
    <StyledLoading animation="loading" animationWidth={400} />
  ) : (
    <StyledWrapper>
      <StyledContentWrapper fullwidth>
        <StyledText
          align="center"
          text="Save permissions"
          textColor="#fff"
          textStyle="h3"
          tag="h1"
        />

        <StyledTextIntro
          align="center"
          text={`Are you sure you want to save these permissions settings?`}
          textColor="#fff"
          textStyle="h6"
          tag="p"
        />

        <StyledButtonGroup fullwidth>
          <Button
            variant="contained"
            text={{
              align: 'center',
              text: 'Save',
              textColor: '#fff',
            }}
            onClick={() => save()}
            bgColor={theme.color.success}
          />
          <Button
            variant="contained"
            onClick={() => closeModals()}
            bgColor={theme.color.cancel}
            text={{
              align: 'center',
              text: 'Cancel',
              textColor: '#fff',
            }}
          />
        </StyledButtonGroup>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

savePermissions.propTypes = {
  /** Chat Id */
  chatId: PropTypes.string,
  /** Message Id */
  messageId: PropTypes.string,
  /** Close function for the modal */
  closeModals: PropTypes.func,
};

savePermissions.defaultProps = {
  chatId: null,
  messageId: null,
  closeModals: null,
};

export default savePermissions;
