// customToast.js
import { toast } from 'react-toastify';
import { css } from 'glamor';

/** add custom styling to toast component */
const CustomToast = {
  /** task succesfull override */
  default(msg, options = {}) {
    return toast(msg, {
      ...options,
      className: css({
        color: '#000 !important',
        borderRadius: '5px !important',
        background: '#fff !important',
        borderLeft: '5px solid #34A853 !important',
        boxShadow: '0px 1px 5px rgba(248, 175, 175, 0.1) !important',
        margin: '5px !important',
        right: '10px !important',
        bottom: '30px !important',
        height: 'auto !important',
      }),
      progressClassName: css({
        background: '#34A853 !important',
      }),
      position: 'bottom-right',
      hideProgressBar: false,
      newestOnTop: true,
      closeOnClick: false,
      pauseOnHover: true,
      autoClose: 8000,
    });
  },
  success(msg, options = {}) {
    return toast.success(msg, {
      ...options,
      className: css({
        color: '#fff !important',
        borderRadius: '5px !important',
        background: '#2F3036 !important',
        borderLeft: '5px solid #34A853 !important',
        boxShadow: '0px 1px 5px rgba(248, 175, 175, 0.1) !important',
        margin: '5px !important',
        right: '10px !important',
        bottom: '30px !important',
        height: '80px !important',
      }),
      progressClassName: css({
        background: '#34A853 !important',
      }),
      position: 'bottom-right',
      hideProgressBar: false,
      newestOnTop: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  },
  /** task failed override */
  error(msg, options = {}) {
    return toast.error(msg, {
      ...options,
      className: css({
        color: '#fff !important',
        borderRadius: '5px !important',
        background: '#2F3036 !important',
        borderLeft: '5px solid #bb0000 !important',
        boxShadow: '0px 1px 5px rgba(248, 175, 175, 0.1) !important',
        margin: '5px !important',
        right: '10px !important',
        bottom: '30px !important',
        height: '80px !important',
      }),
      progressClassName: css({
        background: '#bb0000 !important',
      }),
      position: 'bottom-right',
      hideProgressBar: false,
      newestOnTop: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  },
  /** task succesfull override */
  info(msg, options = {}) {
    return toast.error(msg, {
      ...options,
      className: css({
        color: '#fff !important',
        borderRadius: '5px !important',
        background: '#2F3036 !important',
        borderLeft: '5px solid #fff700 !important',
        boxShadow: '0px 1px 5px rgba(248, 175, 175, 0.1) !important',
        margin: '5px !important',
        right: '10px !important',
        bottom: '30px !important',
        height: '80px !important',
      }),
      progressClassName: css({
        background: '#fff700 !important',
      }),
      position: 'bottom-right',
      hideProgressBar: false,
      newestOnTop: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  },
};

export default CustomToast;
