import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import firebase from '../../firebase';
import Animation from '../LottieControl';
import UserIndication from '../UserIndication';
import ChatsLayout from './layouts/Chats';
import ListsLayout from './layouts/Lists';
import NotesLayout from './layouts/Notes';
import CallsLayout from './layouts/Calls';
import ThirdPartyLayout from './layouts/ThirdParty';
import WhiteboardsLayout from './layouts/Whiteboards';
import DocumentSharingLayout from './layouts/DocumentSharing';
import Text from '../Text';
import Button from '../Button';
import theme, { rem } from '../../theme';

const StyledAnimationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(20)};
`;

/**
 * Overview component
 * @param {object} main
 * @param {number} numberItems
 * @param {string} widgetId
 */
const LayoutContent = props => {
  const {
    numberItems,
    type,
    widgetId,
    main,
    activeItem,
    activeDashboardId,
  } = props;

  const [mainData, setMainData] = useState(
    main[activeItem] ? main[activeItem] : {}
  );
  const [ready, setReady] = useState(false);

  //The current userId
  const uuid = firebase.auth().currentUser.uid;

  //Translation
  const [t] = useTranslation('text');

  useEffect(() => {
    if (Object.keys(mainData) <= 0 && activeItem) {
      settingsListner();
    }
    setReady(true);
  }, []);

  useEffect(() => {
    if (type && activeItem) settingsListner();
  }, [activeItem]);

  /** settingsListner */
  const settingsListner = () => {
    //Listners
    const listner = firebase
      .database()
      .ref()
      .child(type)
      .child(activeItem);

    listner.once('value', snap => {
      if (snap.val()['attendees'].hasOwnProperty(uuid)) {
        mainData[snap.key] = snap.val();
      } else {
        mainData[snap.key] = {};
        mainData[snap.key]['empty'] = true;
      }
      setMainData(prevState => {
        return { ...prevState, ...mainData };
      });
    });

    listner.child('attendees').on('value', snap => {
      const activeId = snap.ref.parent.key;
      if (mainData[activeId] && !mainData[activeId]['attendees']) {
        mainData[activeId]['attendees'] = {};
        mainData[activeId]['attendees'][snap.key] = snap.val();
      } else if (mainData[activeId]) {
        mainData[activeId]['attendees'] = snap.val();
      }

      setMainData(prevState => {
        return { ...prevState, ...mainData };
      });
    });
  };

  /** Handle type of animation */
  const handleAnimation = () => {
    if (ready) {
      return 'empty';
    } else {
      return 'loadingv2';
    }
  };

  /** handle empty button click */
  const handleOnclick = () => {
    document.getElementById('additem-' + widgetId).click();
  };

  return Object.keys(mainData).length > 0 ? (
    <React.Fragment>
      {type === 'thirdParties' && (
        <ThirdPartyLayout
          type={type}
          main={mainData[activeItem]}
          activeId={activeItem}
          activeDashboardId={activeDashboardId}
          widgetId={widgetId}
        />
      )}

      {type === 'chats' && (
        <ChatsLayout
          type={type}
          main={mainData[activeItem]}
          activeId={activeItem}
          activeDashboardId={activeDashboardId}
          widgetId={widgetId}
        />
      )}

      {type === 'lists' && (
        <ListsLayout
          type={type}
          main={mainData[activeItem]}
          activeId={activeItem}
          activeDashboardId={activeDashboardId}
          widgetId={widgetId}
        />
      )}

      {type === 'notes' && (
        <NotesLayout
          type={type}
          main={mainData[activeItem]}
          activeId={activeItem}
          activeDashboardId={activeDashboardId}
          widgetId={widgetId}
        />
      )}

      {type === 'whiteboards' && (
        <WhiteboardsLayout
          type={type}
          main={mainData[activeItem]}
          activeId={activeItem}
          activeDashboardId={activeDashboardId}
          widgetId={widgetId}
        />
      )}

      {type === 'documentsharing' && (
        <DocumentSharingLayout
          type={type}
          main={mainData[activeItem]}
          activeId={activeItem}
          activeDashboardId={activeDashboardId}
          widgetId={widgetId}
        />
      )}

      {type === 'calls' && (
        <CallsLayout
          type={type}
          main={mainData[activeItem]}
          activeId={activeItem}
          activeDashboardId={activeDashboardId}
          widgetId={widgetId}
        />
      )}
      {/* {Template(type, mainData[activeItem], activeItem, userData)} */}
      {/* <UserIndication
        activeDashboardId={activeDashboardId}
        attendees={
          mainData[activeItem] &&
          mainData[activeItem].attendees &&
          mainData[activeItem].attendees
        }
        widgetId={widgetId}
        activeId={activeItem}
        data={mainData[activeItem]}
      /> */}
    </React.Fragment>
  ) : (
    <StyledAnimationWrapper className="editable">
      <Animation
        animation={handleAnimation()}
        animationWidth="14rem"
        animationHeight="14rem"
      />
      {ready && (
        <React.Fragment>
          <StyledText
            text={t('layoutcontent.empty', { type: type })}
            textColor={theme.color.dark[80]}
            textStyle="h6"
            align="center"
          />
          {type !== 'thirdParties' && (
            <Button
              onClick={() => handleOnclick()}
              text={{ text: t('layoutcontent.emptybutton'), textColor: '#fff' }}
              bgColor={theme.color.primary[100]}
            />
          )}
        </React.Fragment>
      )}
    </StyledAnimationWrapper>
  );
};

LayoutContent.propTypes = {
  /** The main data */
  main: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  type: PropTypes.string,
  activeItem: PropTypes.string,
  activeDashboardId: PropTypes.string,
  numberItems: PropTypes.number,
  widgetId: PropTypes.string,
};

LayoutContent.defaultProps = {
  numberItems: null,
  main: null,
  type: null,
  activeItem: null,
  activeDashboardId: null,
  widgetId: null,
};

/** @component */
export default LayoutContent;
