/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme, { rem } from '../../theme';

const StyledList = styled.ul`
  display: flex;
  margin-left: ${rem(10)};
  flex-direction: ${props => (!props.inline ? 'column' : 'row')};
  width: ${props => (!props.autoWidth ? `calc(100% - ${rem(10)})` : 'auto')};
  padding-top: ${props => (props.topPadding ? props.topPadding : 0)};
  padding-right: ${props => (props.rightPadding ? props.rightPadding : 0)};
  padding-bottom: ${props => (props.bottomPadding ? props.bottomPadding : 0)};
  padding-left: ${props => (props.leftPadding ? props.leftPadding : 0)};
`;

/**
 * Return a styled List component
 * @param {node} children
 * @param {string} listId
 * @param {bool} inline
 * @param {object} padding
 * @param {bool} autoWidth
 */
const List = props => {
  const {
    ref,
    evenRadius,
    listId,
    children,
    inline,
    padding,
    hasButtons,
    autoWidth,
    ...other
  } = props;

  return (
    <StyledList
      id={listId}
      inline={inline}
      autoWidth={autoWidth}
      className='editable'
      topPadding={padding && padding[0] && padding[0].top}
      rightPadding={padding && padding[0] && padding[0].right}
      bottomPadding={padding && padding[0] && padding[0].bottom}
      leftPadding={padding && padding[0] && padding[0].left}
      ref={ref}
      {...other}
    >
      {children}
    </StyledList>
  );
};

List.propTypes = {
  /** Child components that creates the list items */
  children: PropTypes.node,
  /** The ID of the list */
  listId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** Showing the list items inline instead of new line */
  inline: PropTypes.bool,
  /** Defines padding around the component */
  padding: PropTypes.arrayOf(
    PropTypes.shape({
      top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  /** Auto width of the list */
  autoWidth: PropTypes.bool,
};

List.defaultProps = {
  padding: null,
  children: null,
  listId: null,
  inline: false,
  autoWidth: false,
};

export default List;
