/* eslint-disable react/no-find-dom-node */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Picker, emojiIndex } from 'emoji-mart';
import Group from '../Group';
import Button from '../Button';
import Text from '../Text';
import Icon from '../Icon';
import TextField from '../TextField';
import Lottie from '../LottieControl';
import { chatListner, updateChatMessage } from '../../firebase';
import theme, { rem } from '../../theme';
import 'emoji-mart/css/emoji-mart.css';
import UploadAvatar from '../UploadAvatar/UploadAvatar.web';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledSmallButton = styled(Button)`
  min-width: auto;
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(10)};
`;

const StyledGroup = styled(Group)`
  flex-direction: column;
  margin-bottom: ${rem(10)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledContentWrapper = styled(Group)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

const StyledButtonGroup = styled(Group)`
  margin-top: ${rem(20)};

  button {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: ${rem(20)};
    width: ${rem(350)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledLoading = styled(Lottie)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

/** todo */
const editChatMessage = props => {
  const { chatId, messageId, closeModals } = props;

  //States
  const [saveData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chatData, setChatData] = useState({});
  const [message, setMessage] = useState();
  const [emojiPicker, setEmojiPicker] = useState(false);
  const [maxLength] = useState(500);

  //Refs
  const textRef = useRef();
  const emojiRefModal = useRef();
  const imageRef = useRef();

  //Translation
  const [t] = useTranslation(['modal', 'textfield', 'button']);

  useEffect(() => {
    //getDashboardData();
    chatId && getChatData();
    document.getElementById('globalModal').firstElementChild.style.height =
      'auto';
    document.getElementById('globalModal').firstElementChild.style.width =
      '25rem';
  }, []);

  /** Handles click outside event */
  const handleOutsideClick = event => {
    try {
      let node = ReactDOM.findDOMNode(emojiRefModal.current);
      if (!node.contains(event.target)) {
        // Handle outside click here
        setEmojiPicker(false);
      }
    } catch (error) {
      return null;
    }
  };
  document.addEventListener('mousedown', handleOutsideClick);

  /** handleTogglePicker */
  const handleTogglePicker = () => {
    setEmojiPicker(!emojiPicker);
  };

  /** colonToUnicode */
  const colonToUnicode = message => {
    return message.replace(/:[A-Za-z0-9_+-]+:/g, x => {
      x = x.replace(/:/g, '');
      let emoji = emojiIndex.emojis[x];
      if (typeof emoji !== 'undefined') {
        let unicode = emoji.native;
        if (typeof unicode !== 'undefined') {
          return unicode;
        }
      }
      x = ':' + x + ':';
      return x;
    });
  };

  /** handleAddEmoji */
  const handleAddEmoji = emoji => {
    const oldMessage = message;
    const newMessage = colonToUnicode(` ${oldMessage} ${emoji.colons} `);

    setMessage(newMessage);
    setEmojiPicker(false);
    textRef.current.focus();
    textRef.current.value = newMessage;
  };

  /** Get all the dashboard data */
  const getChatData = () => {
    chatListner
      .child(chatId)
      .child('items')
      .child(messageId)
      .once('value', snapshot => {
        if (snapshot.val()) {
          setChatData(snapshot.val());
          setLoading(false);
          setMessage(snapshot.val().text);
        }
      });
  };

  /** Handle image */
  const handleImage = () => {
    let image = '';

    if (chatData.images.cropped) {
      image = chatData.images.cropped;
    } else {
      Object.entries(chatData.images).map(item => {
        image = item[1].url;
      });
    }
    return image;
  };

  /** Handle update */
  const handleUpdate = () => {
    saveData['text'] =
      textRef.current && textRef.current.value.substring(0, maxLength);
    saveData['images'] = imageRef.current
      ? imageRef.current.generateImage()
      : [];

    updateChatMessage(chatId, messageId, saveData);
    closeModals();
  };

  /** Return Chat messages */
  return loading ? (
    <StyledLoading animation='loading' animationWidth={400} />
  ) : (
    <StyledWrapper>
      <StyledContentWrapper fullwidth>
        <StyledText
          align='center'
          text={t('modal:editchatmessage.title')}
          textColor='#fff'
          textStyle='h3'
          tag='h1'
        />

        {chatData.images && (
          <UploadAvatar
            rounded={false}
            type='avatar'
            ref={imageRef}
            currentImage={
              chatData.images && Object.keys(chatData.images).length > 0
                ? handleImage()
                : ''
            }
            width={100}
            height={100}
            padding={20}
            scale={1}
            rotate={0}
            cropRadius={75}
            hasButton={false}
          />
        )}
        <StyledGroup>
          <Text
            align='left'
            text={t('textfield:message.label')}
            textStyle='description'
            textColor='#fff'
          />
          <Group>
            {emojiPicker && (
              <Picker
                ref={emojiRefModal}
                set='apple'
                onClick={handleOutsideClick}
                onMouseLeave={handleOutsideClick}
                onSelect={handleAddEmoji}
                className='emojipicker'
                title="Emoji's"
                style={{
                  position: 'absolute',
                  top: '-19rem',
                  left: '0',
                  zIndex: '3',
                  bottom: '4.2rem',
                  width: '20rem',
                  height: '20rem',
                  overflow: 'hidden',
                }}
                emoji='point_up'
                // perLine={20}
              />
            )}
            <StyledSmallButton
              variant='icon'
              style={{ marginRight: rem(4) }}
              onClick={handleTogglePicker}
            >
              <Icon
                icon='insert_emoticon'
                padding={rem(6)}
                size={16}
                color='#fff'
              />
            </StyledSmallButton>
            <TextField
              textcolor='#fff'
              placeholdercolor='#fff'
              backgroundcolor={theme.color.dark[40]}
              placeholder={t('textfield:message.placeholder')}
              inputId='chatMessage'
              borders={false}
              value={
                chatData.text ? chatData['text'].substring(0, maxLength) : ''
              }
              ref={textRef}
              isTextArea
              maxHeight={rem(70)}
            />
          </Group>
        </StyledGroup>

        <StyledButtonGroup fullwidth>
          <Button
            variant='contained'
            text={{
              align: 'center',
              text: t('button:update.message'),
              textColor: '#fff',
            }}
            onClick={() => {
              handleUpdate();
            }}
            bgColor={theme.color.success}
          />
          <Button
            variant='contained'
            onClick={() => {
              closeModals();
            }}
            bgColor={theme.color.cancel}
            text={{
              align: 'center',
              text: t('button:cancel.cancel'),
              textColor: '#fff',
            }}
          />
        </StyledButtonGroup>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

editChatMessage.propTypes = {
  /** Chat Id */
  chatId: PropTypes.string,
  /** Message Id */
  messageId: PropTypes.string,
  /** Close function for the modal */
  closeModals: PropTypes.func,
};

editChatMessage.defaultProps = {
  chatId: null,
  messageId: null,
  closeModals: null,
};

export default editChatMessage;
