import React, { useState } from 'react';
import styled from 'styled-components';
import ResponsiveModal from 'react-responsive-modal';
import { connect } from 'react-redux';
import { propTypes, defaultProps } from './Modal.props';
import {
  modalOpen,
  modalType,
  userSelection,
  createdList,
  notificationType,
} from '../../redux/actions';
import theme, { rem } from '../../theme';
import { webStyles } from './Modal.styles';
import firebase from '../../firebase';

import ShareContent from './shareContent';
import UpdateContent from './updateContent';
import NewContent from './newContent';
import RemoveContent from './removeContent';

import LogIn from './login';
import Registration from './registration';
import PasteImage from './pasteImage';
import ImagePreview from './imagePreview';
import ResetPassword from './resetPassword';
import RemoveWidget from './removeWidget';
import EditChatMessage from './editChatMessage';
import RemoveChatMessage from './removeChatMessage';
import RemoveFile from './removeFile';
import RemoveImage from './removeImage';
import PreviewFile from './previewFile';
import LeaveDashboard from './leaveDashboard';
import RemoveDashboard from './removeDashboard';
import UpdateDashboard from './updateDashboard';
import NewDashboard from './newDashboard';
import EditRoles from './EditRoles';
import CreateRole from './CreateRole';
import UpdateRole from './updateRole';
import RemoveRole from './removeRole';
import Feedback from './feedback';
import RemoveUser from './removeUser';
import LogoutUser from './logoutUser';
import SetMaster from './setMaster';
import RemoveMaster from './removeMaster';
import SavePermissions from './savePermissions';

import Gallery from './galleryContent';

/** mapStateToProps */
const mapStateToProps = state => ({
  type: state.modaltype.modalType,
  selection: [state.userselection.userSelection],
  NotificationType: state.notificationtype.notificationType,
  ModalOpen: state.modalopen.modalOpen,
});

// Styling
const StyledModal = styled.div`
  #closeModalIcon & {
    background: #f3f3f3;
    border-radius: 50%;
    padding: 0.5rem;
  }
`;
const ChildHolder = styled.div`
  ${() => webStyles.childHolder()}
`;
const StyledWrapper = styled.div`
  ${({ theme }) => webStyles.wrapper(theme)}
`;
const StyledContentWrapper = styled.div`
  ${({ theme }) => webStyles.mainContent(theme)}
`;

/**
 * Modal component
 * @param {string} id - Id of the modal
 * @param {boolean} isOpen - Indicates wheather the modal is open or not
 * @param {func} closeModal - On close function
 * @param {string} backgroundColor - Background color of the modal
 * @param {string} height - Height of the modal
 * @param {string} width - Width of the modal
 * @param {string} padding - Padding of the modal
 * @param {node} children - Content of the modal
 * @param {string} type - Type of the given modal, based on this type a specific template is loaded
 * @param {object} data - Data to create the modal
 */
const Modal = props => {
  //The current userId
  let userId = '';
  if (firebase.auth() && firebase.auth().currentUser)
    userId = firebase.auth().currentUser.uid;

  const {
    id,
    isOpen,
    content,
    padding,
    type,
    activeDashboardId,
    width,
    height,
  } = props;

  // States
  const [userFriends] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState({});

  // The styling of the highest content parentd
  const modalStyle = {
    boxShadow: `0 2px 16px 0 ${theme.color.dark[4]}, 0 0 0 0 ${theme.color.dark[2]}`,
    background: type[0] === 'gallery' ? 'transparent' : 'rgb(47, 49, 54)',
    height: height ? height : '40rem',
    width: width ? width : '40rem',
    maxWidth: 'none',
    padding: rem(padding),
    overflow: 'visible',
    borderRadius: rem(25),
  };

  /** changeChannel */
  const closeModals = () => {
    if (document.getElementById('closeNotification'))
      document.getElementById('closeNotification').click();
    props.modalType('');
    props.userSelection({});
    setSelectedUsers({});
    props.modalOpen(false);
  };

  /** Set the modal content */
  const setContent = () => {
    if (userId && type.length && type[0] === 'dashboardSelect') {
      // const dash = dashboardSelect(dashboardData);
      // setDashboardSwitch(dash);
      return;
    }
  };

  return (
    <StyledModal>
      <ResponsiveModal
        overlayId={id}
        open={isOpen}
        onClose={closeModals}
        showCloseIcon
        closeIconId='closeModalIcon'
        closeIconSize={24}
        content={content}
        onEntered={setContent}
        styles={{
          overlay: { background: theme.overLay.modal },
          modal: modalStyle,
          closeButton: {
            backgroundColor: '#f3f3f3',
            borderRadius: '50%',
            paddingTop: '0.5rem',
            paddingLeft: '0.5rem',
            paddingBottom: '0.5rem',
            paddingRight: '0.5rem',
            top: rem(20),
            right: rem(20),
            cursor: 'pointer',
            position: 'fixed',
          },
        }}
      >
        <ChildHolder>
          <StyledWrapper>
            <StyledContentWrapper>
              {type[0] === 'edititem' && (
                <UpdateContent
                  data={type[4]}
                  activeDashboardId={type[7]}
                  widgetId={type[4].id}
                  friends={userFriends}
                  selectedUsers={selectedUsers}
                  type={type[1]}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'additem' && (
                <NewContent
                  data={type[4]}
                  widgetId={type[2]}
                  friends={userFriends}
                  selectedUsers={selectedUsers}
                  type={type[1]}
                  closeModals={closeModals}
                  activeDashboardId={activeDashboardId}
                />
              )}

              {type[0] === 'shareItem' && (
                <ShareContent
                  type={type[1]}
                  activeId={type[2]}
                  typeId={type[3]}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'removeItem' && (
                <RemoveContent
                  type={type[1]}
                  activeId={type[2]}
                  typeId={type[3]}
                  closeModals={closeModals}
                  activeDashboardId={activeDashboardId}
                />
              )}

              {type[0] === 'leaveDashboard' && (
                <LeaveDashboard
                  userId={type[2]}
                  dashboardId={type[1]}
                  closeModals={closeModals}
                  activeDashboardId={activeDashboardId}
                />
              )}

              {type[0] === 'removeDashboard' && (
                <RemoveDashboard
                  userId={type[2]}
                  dashboardId={type[1]}
                  closeModals={closeModals}
                  activeDashboardId={activeDashboardId}
                />
              )}

              {type[0] === 'removeWidget' && (
                <RemoveWidget
                  userId={type[2]}
                  dashboardId={type[1]}
                  widgetId={type[3]}
                  removeWidget={type[4]}
                  event={type[5]}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'editdashboard' && (
                <UpdateDashboard
                  dashboardId={type[1]}
                  closeModals={closeModals}
                  activeDashboardId={activeDashboardId}
                />
              )}

              {type[0] === 'login' && <LogIn closeModals={closeModals} />}

              {type[0] === 'removeChatMessage' && (
                <RemoveChatMessage
                  chatId={type[1]}
                  messageId={type[2]}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'removeFile' && (
                <RemoveFile
                  currentId={type[1]}
                  fileId={type[2]}
                  documentId={type[3]}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'removeImage' && (
                <RemoveImage
                  typeId={type[1]}
                  itemId={type[2]}
                  imageId={type[3]}
                  table={type[4]}
                  gallery={type[5]}
                  activeDashboardId={activeDashboardId}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'previewFile' && (
                <PreviewFile
                  typeSmall={type[1]}
                  url={type[2]}
                  type={type[3]}
                  name={type[4]}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'editChatMessage' && (
                <EditChatMessage
                  chatId={type[1]}
                  messageId={type[2]}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'registration' && (
                <Registration closeModals={closeModals} />
              )}

              {type[0] === 'pasteImage' && (
                <PasteImage
                  images={type[1]}
                  typeId={type[2]}
                  text={type[3]}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'resetPassword' && (
                <ResetPassword closeModals={closeModals} />
              )}

              {type[0] === 'adddashboard' && (
                <NewDashboard
                  closeModals={closeModals}
                  activeDashboardId={activeDashboardId}
                />
              )}

              {type[0] === 'gallery' && (
                <Gallery
                  images={type[1]}
                  itemId={type[2]}
                  typeId={type[3]}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'editRoles' && (
                <EditRoles
                  activeDashboardId={activeDashboardId}
                  userId={type[2]}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'createRole' && (
                <CreateRole
                  activeDashboardId={activeDashboardId}
                  reference={type[2]}
                  userId={type[3]}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'updateRole' && (
                <UpdateRole
                  id={type[3]}
                  activeDashboardId={activeDashboardId}
                  role={type[2]}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'removeRole' && (
                <RemoveRole
                  id={type[3]}
                  activeDashboardId={activeDashboardId}
                  role={type[2]}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'setMaster' && (
                <SetMaster
                  id={type[1]}
                  activeDashboardId={activeDashboardId}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'removeMaster' && (
                <RemoveMaster
                  id={type[1]}
                  activeDashboardId={activeDashboardId}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'removeUser' && (
                <RemoveUser
                  id={type[2]}
                  activeDashboardId={activeDashboardId}
                  returnModal={type[3]}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'logoutUser' && (
                <LogoutUser
                  id={type[2]}
                  activeDashboardId={activeDashboardId}
                  returnModal={type[3]}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'feedback' && (
                <Feedback
                  id={type[1]}
                  activeDashboardId={activeDashboardId}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'savePermissions' && (
                <SavePermissions
                  activeDashboardId={activeDashboardId}
                  saveObject={type[2]}
                  closeModals={closeModals}
                />
              )}

              {type[0] === 'imagePreview' && (
                <ImagePreview image={type[1]} closeModals={closeModals} />
              )}
              {/* {showGallery ? showGallery : null}
                  {dashboardSwitch ? dashboardSwitch : null} */}
            </StyledContentWrapper>
          </StyledWrapper>
        </ChildHolder>
      </ResponsiveModal>
    </StyledModal>
  );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

/** @component */
export default connect(mapStateToProps, {
  modalOpen,
  modalType,
  userSelection,
  createdList,
  notificationType,
})(Modal);
