/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import { Provider, connect } from "react-redux";
import "./components/App.css";
import App from "./components/App";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Welcome from "./components/Auth/Welcome";
import Full from "./components/Auth/Full";
import CreateDashboard from "./components/Auth/CreateDashboard";
import BuildDashboard from "./components/Auth/BuildDashboard";
import NotFound from "./components/Auth/NotFound";
import SelectDashboard from "./components/Auth/SelectDashboard";
import registerServiceWorker from "./registerServiceWorker";
import firebase, {
  userDashboardListner,
  userListner,
  inviteKeyListner,
  dashboardListner,
  createExternalUser,
  createRandomColor,
} from "./firebase";
import "semantic-ui-css/semantic.min.css";
import { setUser } from "./redux/actions";
import store from "./redux/store";
import "./translations/config";
// import io from 'socket.io-client';

// const socket = io.connect("http://localhost:3001");

/**
 * Default routing for project
 */
const Root = props => {
  const { history, setUser } = props;
  const [check, setCheck] = useState(false);
  const [activeDashboardId, setActiveDashboardId] = useState();
  const [inviteKey, setInviteKey] = useState({});
  const [currentUser, setCurrentUser] = useState();
  const [jwtToken, setJwtToken] = useState();

  const parseJwt = async token => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  //Initialize the application
  useEffect(() => {
    if (!check) {
      firebaseCheck();
    }
  }, [check]);

  useEffect(() => {
    if (jwtToken) {
      history.push("/selectdashboard");
    }
  }, [jwtToken]);

  /** User handler */
  const userHandler = customBearer => {
    firebase.auth().onAuthStateChanged(user => {
      let dataObject = "";
      if (user) {
        setUser(user);
        userDashboardListner
          .child(user.uid)
          .once("value", snap => {
            snap.forEach(function(snap) {
              if (snap.val() && snap.val().active === true) {
                dataObject = snap.key;
              }
            });
          })
          .then(() => {
            if (dataObject !== "" && !customBearer) {
              setActiveDashboardId(dataObject);
              setCheck(true);
            } else {
              //At this point the user dont have any active dashboards
              userListner.child(user.uid).once("value", snapUser => {
                //Check if external login
                if (
                  (snapUser.val() && snapUser.val().isSingleSignOnUser) ||
                  customBearer === "checkSignIn"
                ) {
                  if (customBearer === "checkSignIn") {
                    history.push("/selectdashboard");
                  } else {
                    let replaceBear = customBearer.replace("bearer=", "");
                    if (replaceBear.length > 0) {
                      parseJwt(replaceBear).then(result => {
                        setJwtToken(result);
                      });
                    }
                  }
                }
                //Check if user is in DB but is not a ghost
                else if (snapUser.val() && !snapUser.val().isGhostUser) {
                  setCurrentUser(user.uid);

                  if (!snapUser.val().isFirstTimeUser) {
                    history.push("/selectdashboard");
                  } else {
                    history.push("/building");
                  }
                  //User most likely a ghost
                } else {
                  if (snapUser.val() && snapUser.val().isGhostUser) {
                    history.push("/");
                  } else {
                    history.push("/login");
                  }
                }
              });
            }
          });

        history.push("/");
      } else {
        if (customBearer.startsWith("bearer")) {
          let replaceBear = customBearer.replace("bearer=", "");
          firebase
            .auth()
            .signInWithCustomToken(replaceBear)
            .then(result => {
              if (
                result.additionalUserInfo &&
                result.additionalUserInfo.isNewUser
              ) {
                parseJwt(replaceBear).then(response => {
                  const userId = result.user.uid;
                  const name = response.username;
                  const color = createRandomColor();

                  userListner
                    .child(userId)
                    .set({
                      id: userId,
                      isPayedUser: true, //TODO: set this back on release
                      isGhostUser: false,
                      isFirstTimeUser: true,
                      isSingleSignOnUser: true,
                      totalDashboards: 0,
                      maxDashboards: 10,
                      public: {
                        name: name ? name : "",
                        indicator: "online",
                        status: "let's learn today!",
                        color: color,
                        avatar: {
                          cropped: "",
                          original: "",
                          position: {
                            x: 0,
                            y: 0,
                          },
                          rotate: 0,
                          scale: 1,
                          userId: userId,
                        },
                      },
                    })
                    .then(() => {
                      setJwtToken(result);
                    });
                });
              } else {
                parseJwt(replaceBear).then(response => {
                  setJwtToken(response);
                });
              }
            });
        } else {
          history.push("/login");
        }
      }
    });
  };

  /**
   * Check if user is authorised
   */
  const firebaseCheck = () => {
    let pathName = window.location.pathname.split("/")[1];
    if (
      pathName !== "" &&
      pathName !== "login" &&
      pathName !== "create" &&
      !pathName.startsWith("bearer") &&
      !pathName.startsWith("checkSignIn")
    ) {
      inviteKeyListner.child(pathName).once("value", snap => {
        firebase.auth().onAuthStateChanged(user => {
          if (!snap.val()) {
            userHandler(pathName);
            return;
          }
          const listner = dashboardListner.child(snap.val().dashboardId);
          listner.once("value", snapShot => {
            if (
              snapShot.val() &&
              snapShot.val().totalMembers < snapShot.val().maxMembers
            ) {
              if (user && snap.val()) {
                if (snap.val().friendId !== user.uid) {
                  if (user.uid in snapShot.val().members) {
                    userHandler();
                  } else {
                    setInviteKey(snap.val());
                    history.push("/welcome");
                  }
                } else {
                  userHandler();
                }
              } else if (snap.val()) {
                setInviteKey(snap.val());
                history.push("/welcome");
              } else {
                userHandler();
              }
            } else {
              setInviteKey(snap.val());
              history.push("/full");
            }
          });
        });
      });
    } else {
      userHandler(pathName);
    }
  };

  /** loginHandler */
  const loginHandler = () => {
    return <App activeDashboardId={activeDashboardId} />;
  };

  /** Extra handle layer */
  const handleRoute = page => {
    switch (page) {
      case "login": {
        return <Login />;
      }
      case "register": {
        return Register;
      }
      case "full": {
        return <Full inviteKey={inviteKey} />;
      }
      case "welcome": {
        return <Welcome inviteKey={inviteKey} />;
      }
      case "create": {
        return <CreateDashboard userId={currentUser} />;
      }
      case "building": {
        return <BuildDashboard userId={currentUser} />;
      }
      case "selectdashboard": {
        return <SelectDashboard userData={jwtToken} />;
      }
      case "notfound": {
        return <NotFound userData={currentUser} />;
      }
      default:
        return <NotFound userId={currentUser} />;
    }
  };

  /**
   * Default rout rendering for project
   */
  return (
    <Switch>
      <Route exact path="/" render={() => (check ? loginHandler() : null)} />
      <Route path="/login" render={() => handleRoute("login")} />
      <Route path="/register" render={() => handleRoute("register")} />
      <Route path="/full" render={() => handleRoute("full")} />
      <Route path="/welcome" render={() => handleRoute("welcome")} />
      <Route path="/create" render={() => handleRoute("create")} />
      <Route path="/building" render={() => handleRoute("building")} />
      <Route path="/notfound" render={() => handleRoute("notfound")} />
      <Route
        path="/selectdashboard"
        render={() => handleRoute("selectdashboard")}
      />
    </Switch>
  );
};

const RootWithAuth = withRouter(
  connect(null, {
    setUser,
  })(Root)
);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <RootWithAuth />
    </Router>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
