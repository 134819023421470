import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Group from '../Group';
import Button from '../Button';
import Text from '../Text';
import Lottie from '../LottieControl';
import firebase, { dashboardListner } from '../../firebase';
import theme, { rem } from '../../theme';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(10)};
`;

const StyledTextIntro = styled(Text)`
  margin-bottom: 0;
`;

const StyledTextSub = styled(Text)`
  margin-bottom: ${rem(30)};
`;

const StyledContentWrapper = styled(Group)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

const StyledButtonGroup = styled(Group)`
  button {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: ${rem(20)};
    width: ${rem(350)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledLoading = styled(Lottie)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

/** todo */
const removeWidgetContent = props => {
  const {
    userId,
    widgetId,
    dashboardId,
    closeModals,
    removeWidget,
    event,
  } = props;

  //The current userId
  let uuid = userId;
  if (!uuid) uuid = firebase.auth().currentUser.uid;

  //Translation
  const [t] = useTranslation(['modal', 'widgets', 'button']);

  //States
  const [loading, setLoading] = useState(true);
  const [widgetData, setWidgetData] = useState({});

  useEffect(() => {
    getWidgetData();

    document.getElementById('globalModal').firstElementChild.style.height =
      'auto';
  }, []);

  /** Get all the dashboard data */
  const getWidgetData = () => {
    let dataObject = {};
    dashboardListner
      .child(dashboardId)
      .child('widgets')
      .child(widgetId)
      .once('value', snapshot => {
        if (snapshot.val()) {
          const type = snapshot.val().type;
          const typeName = type.toUpperCase().slice(0, -1);
          const typeId = snapshot.val().typeId;
          firebase
            .database()
            .ref()
            .child(type)
            .child(typeId)
            .once('value', snap => {
              if (snap.val()) {
                dataObject = snap.val();
                dataObject['typename'] = typeName;
              }
            })
            .then(() => {
              setWidgetData(dataObject);
              setLoading(false);
            });
        }
      });
  };

  /** Handle Text */
  const handleText = name => {
    switch (name) {
      default:
      case 'default': {
        return name;
      }
      case 'NOTE': {
        return t('widgets:notes.single').toUpperCase();
      }
      case 'CALL': {
        return t('widgets:calls.single').toUpperCase();
      }
      case 'WHITEBOARD': {
        return t('widgets:whiteboards.single').toUpperCase();
      }
      case 'LIST': {
        return t('widgets:lists.single').toUpperCase();
      }
      case 'CHAT': {
        return t('widgets:chats.single').toUpperCase();
      }
      case 'DOCUMENTSHARIN': {
        return t('widgets:sharing.single').toUpperCase();
      }
    }
  };

  /** Return Chat messages */
  return loading ? (
    <StyledLoading animation='loading' animationWidth={400} />
  ) : (
    <StyledWrapper>
      <StyledContentWrapper fullwidth>
        <StyledText
          align='center'
          text={t('modal:removewidget.title')}
          textColor='#fff'
          textStyle='h3'
          tag='h1'
        />
        <Lottie animation='alert' animationWidth={200} />

        {widgetData && Object.keys(widgetData).length > 0 && (
          <StyledTextIntro
            align='center'
            text={t('modal:removewidget.text', {
              name: handleText(widgetData.typename),
            })}
            textColor='#fff'
            textStyle='h6'
            tag='p'
          />
        )}
        <StyledTextSub
          align='center'
          text={t('modal:removewidget.subtext')}
          textColor='#fff'
          textStyle='h6'
          tag='p'
        />

        <StyledButtonGroup fullwidth>
          <Button
            variant='contained'
            onClick={() => {
              event && removeWidget(widgetId, event);
              closeModals();
            }}
            bgColor={theme.color.danger}
            text={{
              align: 'center',
              text: t('button:remove.widget'),
              textColor: '#fff',
            }}
          />
          <Button
            variant='contained'
            text={{
              align: 'center',
              text: t('button:cancel.cancel'),
              textColor: '#fff',
            }}
            onClick={() => {
              closeModals();
            }}
            bgColor={theme.color.cancel}
          />
        </StyledButtonGroup>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

removeWidgetContent.propTypes = {
  /** User Id */
  userId: PropTypes.string,
  /** Selected widget Id */
  widgetId: PropTypes.string,
  /** Selected dashboard Id */
  dashboardId: PropTypes.string,
  /** Close function for the modal */
  closeModals: PropTypes.func,
  /** Remove function */
  removeWidget: PropTypes.func,
  /** Items array or object */
  event: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

removeWidgetContent.defaultProps = {
  userId: null,
  widgetId: null,
  dashboardId: null,
  closeModals: null,
  removeWidget: null,
  event: null,
};

export default removeWidgetContent;
