/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme, { rem } from '../../theme';
import getSpacingString from '../../utils/withMemo';
import Button from '../Button';
import Badge from '../Badge';
import Icon from '../Icon';

const StyledButton = styled(Button)`
  height: ${rem(40)};
  width: ${rem(40)};
  box-shadow: ${props =>
    props.variant === 'shadow'
      ? `0 ${rem(1)} ${rem(6)} rgba(0, 0, 0, 0.15)`
      : 'none'};
  background-color: ${theme.color.light[60]};
`;

const StyledBadge = styled(Badge)`
  transition: 0.4s;
  transform: scale(1.3);
  position: absolute;
  top: -${rem(7)};
  right: -${rem(5)};
  z-index: 4;
`;

const StyledWrapper = styled.div`
  ${({ currentSpacing, position, show }) => `
    transition: 0.4s;
    transform: scale(${show ? '1' : '0'});
    margin: ${currentSpacing};
    bottom: ${position.bottom || position.bottom};
    top: ${position.top || position.top};
    left: ${position.left || position.left};
    right: ${position.right || position.right};
    position: fixed;
    z-index: 3;
  `}
`;

/**
 * Overview component
 * @param {function} onclick
 * @param {bool} chunksFromTop
 * @param {string | bool} badge
 */
const ScrollIndicator = props => {
  const { spacing, position, show, onclick, chunksFromTop, badge } = props;
  const currentSpacingString = getSpacingString(spacing, 0);
  return (
    <StyledWrapper
      currentSpacing={currentSpacingString}
      position={position}
      show={show}
    >
      {badge !== 0 && badge !== '' && badge && <StyledBadge text={badge} />}
      <StyledButton
        className="editable"
        variant="icon"
        onClick={onclick}
        bgColor={theme.color.dark[8]}
      >
        <Icon
          padding={rem(12)}
          icon={chunksFromTop ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
          size={20}
        />
      </StyledButton>
    </StyledWrapper>
  );
};

ScrollIndicator.propTypes = {
  spacing: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
  position: PropTypes.oneOfType([PropTypes.object]),
  show: PropTypes.bool,
  onclick: PropTypes.func,
  /** Direction the scrollbar */
  chunksFromTop: PropTypes.bool,
  /** Content for the badge above the button */
  badge: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
};

ScrollIndicator.defaultProps = {
  spacing: null,
  position: null,
  show: false,
  onclick: null,
  chunksFromTop: true,
  badge: null,
};

/** @component */
export default ScrollIndicator;
