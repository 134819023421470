/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle, keyframes, css } from 'styled-components';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Tour from 'reactour';
import { useTranslation } from 'react-i18next';
import Animation from '../LottieControl';
import Text from '../Text';
import Icon from '../Icon';
import Button from '../Button';
import Image from '../Image';
import theme, { rem } from '../../theme';
import firebase, {
  userListner,
  userDashboardListner,
  masterListner,
} from '../../firebase';

//CSS animations
const FadeIn = keyframes`
  from {
    opacity: 0;
    transform-origin: center bottom;
  }

  to {
    opacity: 0.9;
  }
`;

const AnimationFadeIn = css`
  animation: ${FadeIn} 0.5s ease;
  animation-delay: 0;
  animation-fill-mode: forwards;
`;

const GlobalStyle = createGlobalStyle`
  .mask {
    ${AnimationFadeIn};
  }
`;

const StyledIconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: ${rem(10)} 0;
`;

const StyledWrapper = styled.div`
  .mask {
    ${AnimationFadeIn};
  }

  .tut {
    #___reactour .mask {
      ${AnimationFadeIn};
    }
  }
`;

const StyledTour = styled(Tour)``;

const StyledIntroWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${rem(20)} 0;
`;

const StyledButton = styled(Button)`
  margin-top: ${rem(10)};
`;

/**
 * Return a styled Note component
 * @param {bool} start
 * @param {number} tutNumber
 */
const Intro = props => {
  const { start, tutNumber, activeDashboardId } = props;

  const [tourOpen, setTourOpen] = useState(start);
  const [tourStarted, setTourStarted] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [isMaster, setIsMaster] = useState(false);
  const [stepNumber, setStepNumber] = useState(tutNumber);

  //UserId
  const uuid = firebase.auth().currentUser.uid;

  useEffect(() => {
    setPermissions();
    masterListner.once('value', snapshot => {
      if (snapshot.exists() && snapshot.val().members) {
        Object.keys(snapshot.val().members).map(member => {
          if (member === uuid) {
            setIsMaster(true);
          }
        });
      }
    });

    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('permissions')
      .on('value', snap => {
        setPermissions(snap.val());
      });
  }, []);

  useEffect(() => {
    setStepNumber(tutNumber);
  }, [tutNumber]);

  //Translation
  const [t] = useTranslation('intro');

  /** Disable body */
  const disableBody = target => disableBodyScroll(target);

  /** Enable body */
  const enableBody = target => enableBodyScroll(target);

  /** Stop the tour */
  const closeTour = () => {
    setTourOpen(false);
    updateFirstTimeUser();
    document.body.classList.remove('tut');
    document.body.classList.remove('progresstut');
  };

  /** updateFirstTimeUser */
  const updateFirstTimeUser = () => {
    userListner.child(uuid).update({ isFirstTimeUser: false });
  };

  /** Handle the step number */
  const handleStepNumber = (goTo, type) => {
    /**
     * menu
     * members
     * invitemember
     * dashboards
     * newdashboard
     * draghandle
     * widgetplace
     * finish
     */
    switch (type) {
      default: {
        setStepNumber(goTo);
        break;
      }
      case 'menu': {
        let number = goTo;
        if (!permissions.compactmenu && !permissions.store) {
          number = 8;
        } else if (!permissions.compactmenu) {
          number = 6;
        }
        setStepNumber(number);
        return;
      }
      case 'draghandle': {
        let number = goTo;
        if (!permissions.store) {
          number = 9;
        }
        setStepNumber(number);
        return;
      }

      case 'newdashboard': {
        let number = goTo;
        if (!isMaster) {
          number = 6;
        }
        setStepNumber(number);
        return;
      }
    }
  };

  const tourConfig = [
    {
      content: () => (
        <React.Fragment>
          <Text textStyle='h6' text={t('welcome.title')} align='center' />
          <StyledIntroWrapper>
            <Image src='/resources/mainlogo.webp' width={170} />
          </StyledIntroWrapper>
          <Text
            text={
              !permissions.compactmenu && !permissions.store
                ? t('notavailable.text')
                : t('welcome.text')
            }
            align='center'
          />
          {(permissions.compactmenu || permissions.store) && (
            <StyledButton
              text={{
                text: t('welcome.button'),
                textColor: '#fff',
                align: 'center',
              }}
              fullWidth
              bgColor={theme.color.success}
              onClick={() => {
                handleStepNumber(1, 'menu');
              }}
            />
          )}
        </React.Fragment>
      ),
      action: () => {
        setTourStarted(false);
        setStepNumber(0);
        document.body.classList.remove('tut');
      },
    },
    {
      selector: '#smallMenu',
      content: () => (
        <React.Fragment>
          <Text textStyle='h6' text={t('menu.title')} align='center' />
          <StyledIntroWrapper>
            <Animation animation='serve' animationWidth={200} />
          </StyledIntroWrapper>
          <Text text={t('menu.text')} align='center' />
          <StyledButton
            text={{
              text: t('menu.button'),
              textColor: '#fff',
              align: 'center',
            }}
            fullWidth
            bgColor={theme.color.success}
            onClick={() => {
              handleStepNumber(2, 'members');
            }}
          />
        </React.Fragment>
      ),
      action: () => {
        handleStepNumber(1, 'menu');
      },
    },
    {
      selector: '#membersMenu',
      content: () => (
        <React.Fragment>
          <Text textStyle='h6' text={t('members.title')} align='center' />
          <StyledIntroWrapper>
            <Animation animation='working' animationHeight={160} />
          </StyledIntroWrapper>
          <Text text={t('members.text')} align='center' />
          <StyledButton
            text={{
              text: t('members.button'),
              textColor: '#fff',
              align: 'center',
            }}
            fullWidth
            bgColor={theme.color.success}
            onClick={() => {
              handleStepNumber(3, 'invitemember');
            }}
          />
        </React.Fragment>
      ),
      action: () => {
        handleStepNumber(2, 'members');
      },
    },
    {
      selector: '#bubble-attendees',
      content: () => (
        <React.Fragment>
          <Text textStyle='h6' text={t('memberinvite.title')} align='center' />
          <StyledIntroWrapper>
            <Animation animation='hello' animationWidth={200} />
          </StyledIntroWrapper>
          <Text text={t('memberinvite.text')} align='center' />
          <StyledButton
            text={{
              text: t('memberinvite.button'),
              textColor: '#fff',
              align: 'center',
            }}
            fullWidth
            bgColor={theme.color.success}
            onClick={() => {
              handleStepNumber(4, 'dashboards');
            }}
          />
        </React.Fragment>
      ),
      action: () => {
        handleStepNumber(3, 'invitemember');
      },
    },
    {
      selector: '#dashboardMenu',
      content: () => (
        <React.Fragment>
          <Text textStyle='h6' text={t('dashboard.title')} align='center' />
          <StyledIntroWrapper>
            <Animation animation='multidash' animationWidth={200} />
          </StyledIntroWrapper>
          <Text text={t('dashboard.text')} align='center' />
          <StyledButton
            text={{
              text: t('dashboard.button'),
              textColor: '#fff',
              align: 'center',
            }}
            fullWidth
            bgColor={theme.color.success}
            onClick={() => {
              handleStepNumber(5, 'newdashboard');
            }}
          />
        </React.Fragment>
      ),
      action: () => {
        handleStepNumber(4, 'dashboards');
      },
    },
    {
      selector: '#dashboardAdd',
      content: () => (
        <React.Fragment>
          <Text textStyle='h6' text={t('dashboardadd.title')} align='center' />
          <StyledIntroWrapper>
            <Animation animation='build' animationHeight={140} />
          </StyledIntroWrapper>
          <Text text={t('dashboardadd.text')} align='center' />
          <StyledButton
            text={{
              text: t('dashboardadd.button'),
              textColor: '#fff',
              align: 'center',
            }}
            fullWidth
            bgColor={theme.color.success}
            onClick={() => {
              handleStepNumber(6, 'draghandle');
            }}
          />
        </React.Fragment>
      ),
      action: () => {
        handleStepNumber(5, 'newdashboard');
      },
    },
    {
      selector: '#draghandle',
      content: () => (
        <React.Fragment>
          <Text textStyle='h6' text={t('draghandle.title')} align='center' />
          <StyledIntroWrapper>
            <Animation animation='up' animationWidth={200} />
          </StyledIntroWrapper>
          <Text text={t('draghandle.text')} align='center' />
          <StyledButton
            text={{
              text: t('draghandle.button'),
              textColor: '#fff',
              align: 'center',
            }}
            fullWidth
            bgColor={theme.color.success}
            onClick={() => {
              handleStepNumber(7, 'widgetplace');
            }}
          />
        </React.Fragment>
      ),
      action: () => {
        handleStepNumber(6, 'draghandle');
      },
    },
    {
      selector: '[data-tut="reactour__draghandle"]',
      content: () => (
        <React.Fragment>
          <Text textStyle='h6' text={t('widgetplace.title')} align='center' />
          <Animation animation='puzzle' animationWidth={200} />
          <Text text={t('widgetplace.text')} align='center' />
          <StyledButton
            text={{
              text: t('widgetplace.button'),
              textColor: '#fff',
              align: 'center',
            }}
            fullWidth
            bgColor={theme.color.success}
            onClick={() => {
              handleStepNumber(8, 'finish');
            }}
          />
        </React.Fragment>
      ),
      action: () => {
        if (!tourStarted) setTourOpen(false);
        document.body.classList.add('tut');
        if (!tourStarted) {
          setTourStarted(true);
          setTimeout(function() {
            setTourOpen(true);
            if (document.getElementsByClassName('bubbleContent')[0])
              document.getElementsByClassName(
                'bubbleContent'
              )[0].style.transform = 'scale(1)';
            if (document.getElementsByClassName('bubbleContent')[0])
              document.getElementsByClassName(
                'bubbleContent'
              )[0].style.opacity = '0';
          }, 500);
        }
      },
    },
    {
      // selector: '[data-tut="reactour__draghandle"]',
      content: () => (
        <React.Fragment>
          <Text textStyle='h6' text={t('finish.title')} align='center' />
          <Animation animation='fireworks' animationWidth={200} />
          <Text text={t('finish.text')} align='center' />
          <StyledButton
            text={{
              text: t('finish.button'),
              textColor: '#fff',
              align: 'center',
            }}
            fullWidth
            bgColor={theme.color.success}
            onClick={() => {
              closeTour();
            }}
          />
        </React.Fragment>
      ),
      action: () => {
        if (document.getElementsByClassName('bubbleContent')[0])
          document.getElementsByClassName('bubbleContent')[0].style = '';
        if (!tourStarted) setTourOpen(false);
        if (!tourStarted) {
          setTourStarted(true);
          setTimeout(function() {
            setTourOpen(true);
          }, 400);
          updateFirstTimeUser();
        }
      },
    },
    {
      // selector: '[data-tut="reactour__draghandle"]',
      content: () => (
        <React.Fragment>
          <Text textStyle='h6' text={t('finish.title')} align='center' />
          <Animation animation='fireworks' animationWidth={200} />
          <Text text={t('finish.text')} align='center' />
          <StyledButton
            text={{
              text: t('finish.button'),
              textColor: '#fff',
              align: 'center',
            }}
            fullWidth
            bgColor={theme.color.success}
            onClick={() => {
              closeTour();
            }}
          />
        </React.Fragment>
      ),
      action: () => {
        handleStepNumber(9, 'finish');
      },
    },
  ];

  return (
    <StyledWrapper>
      <GlobalStyle />
      <StyledTour
        disableKeyboardNavigation
        onRequestClose={closeTour}
        steps={tourConfig}
        goToStep={stepNumber}
        isOpen={tourOpen}
        maskClassName='mask'
        className='helper'
        rounded={5}
        accentColor={theme.color.primary[100]}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        showButtons={false}
        showNavigation={false}
      />
    </StyledWrapper>
  );
};

Intro.propTypes = {
  /** Starting the introduction */
  start: PropTypes.bool,
  /** Go to introduction part */
  tutNumber: PropTypes.number,
};

Intro.defaultProps = {
  start: null,
  tutNumber: 0,
};

export default Intro;
