import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from '../../theme';

const StyledImage = styled.img`
  object-fit: cover;
  width: ${props => props.width};
  height: ${props => props.height};
  max-width: 100%;
  border-radius: ${props => (props.hasBorderRadius ? rem(10) : '')};
`;

/**
 * @class Image Component Basic template
 */
class Image extends React.Component {
  /**
   * @method constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      dimensions: {
        height: props.height,
        width: props.width,
      },
    };
  }

  /** On image load */
  onImgLoad = ({ target: img }) => {
    this.setState({
      dimensions: {
        height: img.naturalHeight,
        width: img.naturalWidth,
      },
    });
  };

  /**
   * @param {string} alt
   * @param {string} width
   * @param {string} src
   * @param {bool} hasBorderRadius
   * @param {string} height
   */
  render() {
    const { alt, hasBorderRadius, imageShape, src, ...props } = this.props;
    const { dimensions } = this.state;

    return (
      <StyledImage
        width={dimensions.width}
        onLoad={this.onImgLoad}
        draggable={false}
        hasBorderRadius={hasBorderRadius}
        src={src}
        alt={alt}
        height={dimensions.height}
        {...props}
      />
    );
  }
}

Image.propTypes = {
  /** Alt text of the image */
  alt: PropTypes.string,

  /** The height of the image */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /** Image source */
  src: PropTypes.string.isRequired,

  /** The width of the image */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /** Define if the image should have a border radius */
  hasBorderRadius: PropTypes.bool,
};

Image.defaultProps = {
  alt: 'image',
  imageShape: 'default',
  height: '',
  width: '',
  hasBorderRadius: false,
};

Image.displayName = 'Image';

/** @component */
export default Image;
