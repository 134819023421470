import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import UploadAvatar from '../UploadAvatar';
import Group from '../Group';
import Button from '../Button';
import Avatar from '../Avatar';
import Grid from '../Grid';
import Text from '../Text';
import Badge from '../Badge';
import Switch from '../Switch';
import TextField from '../TextField';
import Image from '../Image';
import Lottie from '../LottieControl';
import Searchbar from '../SearchBar';
import FilterSearch from '../FilterSearch';
import firebase, {
  userListner,
  createDashboard,
  dashboardListner,
  userDashboardListner,
  masterListner,
  feedbackListner,
} from '../../firebase';
import theme, { rem } from '../../theme';
import { webStyles } from './Modal.styles';

const StyledSideWrapper = styled.div`
  ${({ theme, rem }) => webStyles.sideContent(theme, rem)}
`;

//CSS animations
const JackInTheBox = keyframes`
  from {
    opacity: 0;
    transform: scale(0.1) rotate(30deg);
    transform-origin: center bottom;
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const AnimationJackInTheBox = css`
  animation: ${JackInTheBox} 0.5s ease;
  animation-delay: 0;
  animation-fill-mode: forwards;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledUserSearch = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledAddedUserWrapper = styled.div`
  ${AnimationJackInTheBox};
  margin-bottom: ${rem(10)}
  
  &:last-child {
    margin-bottom: 0;
  };
`;

const StyledGroup = styled(Group)`
  flex-direction: column;
  margin-bottom: ${rem(10)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledGroupSwitch = styled(Group)`
  flex-direction: column;
  margin-bottom: ${rem(10)};
  margin-top: ${rem(10)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledNavGroup = styled(Group)`
  margin-top: ${rem(20)};

  button {
    margin-right: ${rem(20)};

    &:last-child {
      margin-right: 0;
    }

    &.active {
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${theme.color.success};
      }
    }
  }
`;

const StyledContent = styled(Grid)`
  border-radius: ${rem(10)};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  opacity: 0;

  &.active {
    opacity: 1;
  }
`;

const StyledButtonGroup = styled(Group)`
  margin-top: ${rem(15)};
  justify-content: flex-end;

  button {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: ${rem(20)};
    width: ${rem(350)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledUploadAvatar = styled(UploadAvatar)`
  width: auto;
  margin: ${rem(15)} !important;
`;

const StyledContentWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledMainContent = styled(Grid)`
  width: 100%;
  height: 100%;
`;

const StyledTextWrapper = styled(Group)`
  align-self: flex-start;
  width: auto;

  i {
    margin-left: ${rem(6)};
  }
`;

const StyledMainWrapper = styled(Group)`
  display: none;
  z-index: 1;

  &.active {
    display: block;
  }
`;

const StyledDashboardImage = styled(Image)`
  ${({ active }) => `
    cursor: pointer;
    opacity: ${active ? '1' : '0.5'};
    transition: 0.2s;
    padding-right: ${rem(10)};

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      opacity: 1;
    }
  `}
`;

const StyledDashboardGroup = styled(Group)`
  padding: ${rem(20)};
  flex-wrap: wrap;
`;

const StyledTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${rem(10)};
  min-width: ${rem(150)};
  width: auto;
  max-width: ${rem(300)};
  z-index: 999999;
`;

const StyledTooltip = styled(ReactTooltip)`
  border-radius: ${rem(5)} !important;
  padding: ${rem(5)} ${rem(10)} !important;
`;

const StyledBadge = styled(Badge)`
  margin-right: ${rem(5)};
  margin-bottom: ${rem(8)};

  &:last-child {
    margin-right: 0;
  }
`;

const StyledBadgeWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: -${rem(8)};
`;

const StyledWidgetsWrapper = styled(Group)`
  margin-top: ${rem(8)};
`;

const StyledDashboardWrapper = styled.div`
  display: flex;
  position: relative;
  width: calc(100% / 3);
  margin: ${rem(16)} 0 ${rem(8)} 0;
`;

const StyledDashboardInfoImage = styled(Image)`
  position: absolute;
  top: -${rem(3)};
  right: ${rem(7)};
  cursor: help;
`;

const SelectionWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .dashboardWrapper {
    &.active {
      img {
        opacity: 1;
      }
    }
  }
`;

const StyledButtonIndicatorGroup = styled(Group)`
  width: auto;
  position: relative;
`;

const StyledButtonIndicator = styled(Image)`
  position: absolute;
  top: -${rem(6)};
  right: -${rem(10)};
`;

/** todo */
const newContent = props => {
  const { closeModals, activeDashboardId } = props;
  //The current userId
  const userId = firebase.auth().currentUser.uid;

  //States
  const [saveData, setSaveData] = useState([]);
  const [newSelectedUsers, setNewSelectedUsers] = useState({});
  const [allPermissions, setAllPermissions] = useState(false);
  const [template, setTemplate] = useState();
  const [loading, setLoading] = useState(false);
  const [allDashboardMembers, setAllDashboardMembers] = useState({});
  const [userDashboards, setUserDashboards] = useState({});
  const [userDashboardDetails, setUserDashboardDetails] = useState({});
  const [clickedDashboard, setClickedDashboard] = useState();
  const [maxLength] = useState(100);

  //Reft
  const titleRef = useRef();
  const subTitleRef = useRef();
  const childRef = useRef();
  const dashboardRef = useRef();

  //Translation
  const [t] = useTranslation([
    'text',
    'modal',
    'textfield',
    'common',
    'button',
  ]);

  useEffect(() => {
    getAllDashboardMembers();

    document.getElementById('globalModal').firstElementChild.style.height =
      '47rem';
    document.getElementById('globalModal').firstElementChild.style.width =
      '40rem';
  }, []);

  /** Update the value in the DB */
  const getFriendsData = Uid => {
    /** Firebase get user info */
    let dataObject = {};

    userListner
      .child(Uid)
      .child('public')
      .once('value', snapshot => {
        dataObject = snapshot.val();
      });

    return dataObject;
  };

  /** Get all dashboard members */
  const getAllDashboardMembers = () => {
    /** Firebase get user info */
    let dataObject = {};

    userDashboardListner.child(userId).on('value', snap => {
      if (snap.val()) {
        snap.forEach(function(childSnapType) {
          dashboardListner.child(childSnapType.key).on('value', snapDetails => {
            if (
              snapDetails.val() &&
              Object.keys(snapDetails.val().creators).some(v => v == userId)
            ) {
              let dashboardWidgets = {};
              if (!userDashboards[childSnapType.key])
                userDashboards[childSnapType.key] = true;
              userDashboardDetails[childSnapType.key] = {};
              userDashboardDetails[childSnapType.key] = {
                name: snapDetails.val().name,
                avatar: snapDetails.val().avatar,
                roles: snapDetails.val().roles,
                maxMembers: snapDetails.val().maxMembers,
                maxRoles: snapDetails.val().maxRoles,
                maxWidgets: snapDetails.val().maxWidgets,
              };

              if (snapDetails.val().widgets) {
                Object.entries(snapDetails.val().widgets).map(widget => {
                  if (!dashboardWidgets[widget[1].type])
                    dashboardWidgets[widget[1].type] = true;
                });

                userDashboardDetails[childSnapType.key][
                  'widgets'
                ] = dashboardWidgets;
              }

              setUserDashboardDetails(prevState => {
                return { ...prevState, ...userDashboardDetails };
              });
            }
          });
          setUserDashboards(userDashboards);
        });
      } else {
        setUserDashboards({});
      }
    });

    dashboardListner
      .child(activeDashboardId)
      .child('members')
      .once('value', snapshot => {
        dataObject = snapshot.val();
      })
      .then(() => {
        setAllDashboardMembers(dataObject);
      });
  };

  /** Avatar remove handler */
  const onCloseHandler = () => {
    saveData['image'] = 'delete';
  };

  /** Handle click */
  const handleClick = type => {
    document.getElementById('editavatar').classList.remove('active');
    document.getElementById('editfriends').classList.remove('active');
    document.getElementById('edittemplates').classList.remove('active');
    if (document.getElementById('buttoneditfriends'))
      document.getElementById('buttoneditfriends').classList.remove('active');
    document.getElementById('buttoneditavatar').classList.remove('active');
    if (document.getElementById('buttonedittemplates'))
      document.getElementById('buttonedittemplates').classList.remove('active');

    //Active
    document.getElementById('edit' + type).classList.add('active');
    document.getElementById('buttonedit' + type).classList.add('active');
  };

  /** onItemClick */
  const onItemClick = useCallback(
    id => {
      if (!newSelectedUsers.hasOwnProperty(id)) {
        newSelectedUsers[id] = getFriendsData(id);
      } else {
        delete newSelectedUsers[id];
      }
      setNewSelectedUsers(prevState => {
        return { ...prevState, ...newSelectedUsers };
      });
    },
    [newSelectedUsers]
  );

  /** Get the active state of the feedback */
  const getFeedbackStatus = () => {
    let status = false;
    feedbackListner.child('active').once('value', snap => {
      if (snap.exists()) status = snap.val();
    });

    return status;
  };

  /** When pressed on create */
  const handleCreateItem = () => {
    saveData['title'] =
      titleRef.current && titleRef.current.value.substring(0, maxLength);
    saveData['subtitle'] =
      subTitleRef.current && subTitleRef.current.value.substring(0, maxLength);
    saveData['selectedFriends'] = newSelectedUsers;
    saveData['allPermissions'] = allPermissions;
    saveData['selectedFriends'][userId] = true;
    saveData['activeDashboardId'] = activeDashboardId;
    saveData['userId'] = userId;
    saveData['feedback'] = getFeedbackStatus();
    saveData['image'] = childRef.current
      ? childRef.current.generateImage()
      : [];

    masterListner.once('value', snapshot => {
      if (snapshot.exists() && snapshot.val()['dashboard']) {
        saveData['backgroundimage'] = snapshot.val().backgroundimage
          ? snapshot.val().backgroundimage
          : '';

        dashboardListner
          .child(snapshot.val()['dashboard'].id)
          .once('value', snap => {
            createDashboard(saveData, snap.val());
          });
      } else {
        createDashboard(saveData);
      }
      closeModals();
    });
  };

  /** Handle click */
  const handleDashboardClick = id => {
    const element = document.getElementById('selectedDashboard' + id);
    const parent = element.parentElement;

    if (parent.classList.contains('active')) {
      parent.classList.remove('active');
      dashboardRef.current.value = '';
    } else {
      dashboardRef.current.value = {
        id: id,
        name: userDashboardDetails[id].name,
      };
      const children = parent.parentElement.childNodes;
      for (var child of children) {
        if (child.classList.contains('active')) {
          child.classList.remove('active');
        }
      }
      parent.classList.add('active');
    }
  };

  /** Return Chat messages */
  return loading && Object.keys(allDashboardMembers).length > 0 ? (
    <Lottie animation='loading' animationWidth={400} />
  ) : (
    <StyledWrapper>
      <StyledGroup fullwidth>
        <Text
          align='left'
          text={t('textfield:createdashboard.title.label')}
          textStyle='description'
          textColor='#fff'
        />
        <TextField
          placeholdercolor='#fff'
          backgroundcolor={theme.color.dark[40]}
          isFocused
          textcolor='#fff'
          inputId='modalTitle'
          placeholder={t('textfield:createdashboard.title.placeholder')}
          borders={false}
          value={null}
          ref={titleRef}
        />
      </StyledGroup>

      <StyledGroup>
        <Text
          align='left'
          text={t('textfield:createdashboard.description.label')}
          textStyle='description'
          textColor='#fff'
        />
        <TextField
          textcolor='#fff'
          placeholdercolor='#fff'
          backgroundcolor={theme.color.dark[40]}
          placeholder={t('textfield:createdashboard.description.placeholder')}
          inputId='modalSubTitle'
          borders={false}
          value={null}
          ref={subTitleRef}
        />
      </StyledGroup>

      <StyledGroupSwitch fullWidth>
        <Text
          align='left'
          text={t('textfield:createdashboard.permissions.label')}
          textStyle='description'
          textColor='#fff'
        />
        <Switch
          name='allPermissonsSwitch'
          value='allPermissonsSwitch'
          label=''
          checked={allPermissions}
          onClick={() => setAllPermissions(!allPermissions)}
        />
      </StyledGroupSwitch>

      <StyledNavGroup>
        <Button
          id='buttoneditavatar'
          variant='text'
          onClick={() => handleClick('avatar')}
          className='active'
          bgColor={theme.color.dark[8]}
          text={{
            text: t('button:image.text'),
            textColor: '#fff',
            textStyle: 'description',
          }}
        />
        <Button
          id='buttoneditfriends'
          variant='text'
          onClick={() => handleClick('friends')}
          bgColor={theme.color.dark[8]}
          text={{
            text: t('button:attendees.edit'),
            textColor: '#fff',
            textStyle: 'description',
          }}
        />
        {userDashboardDetails && Object.keys(userDashboardDetails).length > 0 && (
          <StyledButtonIndicatorGroup>
            {dashboardRef.current &&
              dashboardRef.current.value &&
              dashboardRef.current.value != '' && (
                <StyledButtonIndicator
                  src='/resources/checked.svg'
                  height={rem(21)}
                  width={rem(21)}
                />
              )}

            <Button
              id='buttonedittemplates'
              variant='text'
              onClick={() => handleClick('templates')}
              bgColor={theme.color.dark[8]}
              text={{
                text: 'Templates',
                textColor: '#fff',
                textStyle: 'description',
              }}
            />
          </StyledButtonIndicatorGroup>
        )}
      </StyledNavGroup>

      <StyledContentWrapper>
        <StyledMainWrapper id='editavatar' className='active'>
          <StyledMainContent rows='1fr' bgColor={theme.color.dark[40]}>
            <StyledUploadAvatar
              rounded={false}
              type='overviewItem'
              itemType='dashboard'
              id='newDashboard'
              width={100}
              height={100}
              padding={20}
              ref={childRef}
              shadingColor='#000'
              saveData={saveData}
              onClose={preview => onCloseHandler(preview)}
              cropRadius={75}
              backgroundColor={theme.color.light[20]}
              activeDashboardId={activeDashboardId}
              hasButton={false}
            />
          </StyledMainContent>
        </StyledMainWrapper>

        <StyledContent
          rows='1fr'
          bgColor={theme.color.dark[40]}
          scrollbar={false}
          parentId='editfriends'
        >
          <StyledUserSearch>
            {/* <Searchbar
              elementDimensions={{ width: rem(592) }}
              backgroundColor="transparent"
              activeDashboardId={activeDashboardId}
              onItemClick={id => onItemClick(id)}
              setActive={newSelectedUsers}
            /> */}
            <FilterSearch
              id='newDashboardMembers'
              type='all'
              elementDimensions={{ width: rem(550) }}
              backgroundColor='transparent'
              onItemClick={id => onItemClick(id)}
              setActive={newSelectedUsers}
              activeDashboardId={activeDashboardId}
            />
          </StyledUserSearch>
        </StyledContent>
        <StyledMainWrapper id='edittemplates'>
          <StyledMainContent rows='1fr' bgColor={theme.color.dark[40]}>
            <StyledDashboardGroup>
              <Text
                align='left'
                text={t('text:templates.text')}
                textStyle='h6'
                textColor='#fff'
              />
              <Text
                align='left'
                text={t('text:templates.description')}
                textStyle='description'
                textColor='#fff'
              />
              <SelectionWrapper>
                {Object.keys(userDashboards).map(dashboard => {
                  return (
                    <StyledDashboardWrapper
                      key={'key-' + dashboard}
                      ref={dashboardRef}
                      className='dashboardWrapper'
                    >
                      <StyledDashboardImage
                        hasBorderRadius
                        id={'selectedDashboard' + dashboard}
                        src={
                          userDashboardDetails[dashboard]['avatar'].cropped
                            ? userDashboardDetails[dashboard]['avatar'].cropped
                            : '/resources/placeholder.png'
                        }
                        height={rem(100)}
                        width={rem(200)}
                        onClick={() => handleDashboardClick(dashboard)}
                      />
                      <StyledDashboardInfoImage
                        data-for={`tooltipDashboard-${dashboard}`}
                        data-tip=''
                        src='/resources/info2.svg'
                        height={rem(25)}
                        width={rem(25)}
                      />
                      <StyledTooltip
                        id={`tooltipDashboard-${dashboard}`}
                        getContent={() => {
                          return (
                            userDashboardDetails[dashboard].name && (
                              <StyledTooltipWrapper>
                                <Text
                                  text={userDashboardDetails[dashboard].name}
                                  textColor='#fff'
                                  align='center'
                                />
                                <Group fullwidth>
                                  <Text
                                    align='left'
                                    text={t('text:max.attendees', {
                                      maxMembers:
                                        userDashboardDetails[dashboard]
                                          .maxMembers,
                                    })}
                                    textStyle='description2'
                                    textColor='#fff'
                                  />
                                  <Text
                                    align='left'
                                    text={t('text:max.roles', {
                                      maxRoles:
                                        userDashboardDetails[dashboard]
                                          .maxRoles,
                                    })}
                                    textStyle='description2'
                                    textColor='#fff'
                                  />
                                  <Text
                                    align='left'
                                    text={t('text:max.widgets', {
                                      maxWidgets:
                                        userDashboardDetails[dashboard]
                                          .maxWidgets,
                                    })}
                                    textStyle='description2'
                                    textColor='#fff'
                                  />
                                </Group>

                                <Group fullwidth>
                                  <Text
                                    align='left'
                                    text={t('text:roles.text')}
                                    textStyle='description'
                                    textColor='#fff'
                                  />
                                  <StyledBadgeWrapper>
                                    {userDashboardDetails[dashboard] &&
                                      userDashboardDetails[dashboard].roles &&
                                      Object.entries(
                                        userDashboardDetails[dashboard].roles
                                      ).map(role => {
                                        return (
                                          <StyledBadge
                                            text={role[0]}
                                            color={
                                              role[1]['details'] &&
                                              role[1]['details'].color
                                            }
                                            variant='text'
                                          />
                                        );
                                      })}
                                  </StyledBadgeWrapper>
                                </Group>
                                {userDashboardDetails[dashboard].widgets && (
                                  <StyledWidgetsWrapper fullwidth>
                                    <Text
                                      align='left'
                                      text={t('text:widgets.text')}
                                      textStyle='description'
                                      textColor='#fff'
                                    />
                                    <StyledBadgeWrapper>
                                      {Object.keys(
                                        userDashboardDetails[dashboard].widgets
                                      ).map(widget => {
                                        return (
                                          <StyledBadge
                                            text={widget}
                                            variant='text'
                                          />
                                        );
                                      })}
                                    </StyledBadgeWrapper>
                                  </StyledWidgetsWrapper>
                                )}
                              </StyledTooltipWrapper>
                            )
                          );
                        }}
                      />
                    </StyledDashboardWrapper>
                  );
                })}
              </SelectionWrapper>
            </StyledDashboardGroup>
          </StyledMainContent>
        </StyledMainWrapper>
      </StyledContentWrapper>
      <StyledSideWrapper id='modalAside'>
        {Object.keys(newSelectedUsers).length > 0 &&
          Object.entries(newSelectedUsers).map(user => {
            if (userId !== user[0]) {
              return (
                <StyledAddedUserWrapper key={user[0]} data-reference={user[0]}>
                  <Avatar
                    size='medium'
                    source={user[1] && user[1].avatar && user[1].avatar.cropped}
                    alt={user[1] && user[1].name}
                    title={user[1] && user[1].name}
                  />
                </StyledAddedUserWrapper>
              );
            }
          })}
      </StyledSideWrapper>
      <StyledButtonGroup fullwidth>
        <Button
          onClick={handleCreateItem}
          id='closeModal'
          bgColor={theme.color.success}
          text={{
            align: 'center',
            text:
              dashboardRef.current &&
              dashboardRef.current.value &&
              dashboardRef.current.value != ''
                ? t('button:create.basedon', {
                    name: dashboardRef.current.value.name,
                  })
                : t('button:create.text'),
            textColor: '#fff',
          }}
        />

        <Button
          variant='contained'
          onClick={() => {
            closeModals();
          }}
          bgColor={theme.color.cancel}
          text={{
            align: 'center',
            text: t('button:cancel.cancel'),
            textColor: '#fff',
          }}
        />
      </StyledButtonGroup>
    </StyledWrapper>
  );
};

newContent.propTypes = {
  /** Current active dashboard ID */
  activeDashboardId: PropTypes.string,
  /** Close function for the modal */
  closeModals: PropTypes.func,
};

newContent.defaultProps = {
  activeDashboardId: null,
  closeModals: null,
};

//export default connect(mapStateToProps)(Messages);
export default newContent;
