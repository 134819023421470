import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import UploadAvatar from '../UploadAvatar';
import { useTranslation } from 'react-i18next';
import Group from '../Group';
import Button from '../Button';
import Grid from '../Grid';
import Text from '../Text';
import TextField from '../TextField';
import Lottie from '../LottieControl';
import firebase, { updateDashboard, dashboardListner } from '../../firebase';
import theme, { rem } from '../../theme';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledGroup = styled(Group)`
  flex-direction: column;
  margin-bottom: ${rem(10)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledButtonGroup = styled(Group)`
  margin-top: ${rem(15)};
`;

const StyledUploadAvatar = styled(UploadAvatar)`
  width: auto;
  margin: ${rem(15)} !important;
`;

const StyledContentWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledAvatarContent = styled(Grid)`
  width: 100%;
  height: 100%;
`;

const StyledAvatarWrapper = styled(Group)`
  display: none;
  z-index: 1;

  &.active {
    display: block;
  }
`;

/** todo */
const updateContent = props => {
  const { dashboardId, closeModals, activeDashboardId } = props;

  //The current userId
  const userId = firebase.auth().currentUser.uid;

  //States
  const [saveData] = useState([]);
  const [dashboardData, setDashboardData] = useState({});
  const [loading, setLoading] = useState(true);
  const [maxLength] = useState(100);
  const [maxLengthDescription] = useState(500);

  //Ref
  const titleRef = useRef();
  const subTitleRef = useRef();
  const childRef = useRef();

  //Translation
  const [t] = useTranslation(['textfield', 'button']);

  useEffect(() => {
    getDashboardData();
  }, []);

  /** Get all the dashboard data */
  const getDashboardData = () => {
    let dataObject = {};
    dashboardListner
      .child(dashboardId)
      .once('value', snapshot => {
        dataObject = snapshot.val();
      })
      .then(() => {
        setDashboardData(dataObject);
        setLoading(false);
      });
  };

  /** When pressed on update list */
  const handleEditItem = () => {
    saveData['dashboardId'] = dashboardId;
    saveData['title'] = titleRef.current.value.substring(0, maxLength);
    saveData['subtitle'] = subTitleRef.current.value.substring(
      0,
      maxLengthDescription
    );
    saveData['activeDashboardId'] = activeDashboardId;
    saveData['userId'] = userId;
    saveData['image'] = childRef.current
      ? childRef.current.generateImage()
      : [];

    updateDashboard(saveData);
    closeModals();
  };

  /** Avatar remove handler */
  const onCloseHandler = () => {
    saveData['image'] = 'delete';
  };

  /** Return Chat messages */
  return loading ? (
    <Lottie animation='loading' animationWidth={400} />
  ) : (
    <StyledWrapper>
      <StyledGroup fullwidth>
        <Text
          align='left'
          text={t('textfield:createdashboard.title.label')}
          textStyle='description'
          textColor='#fff'
        />
        <TextField
          placeholdercolor='#fff'
          backgroundcolor={theme.color.dark[40]}
          isFocused
          textcolor='#fff'
          inputId='modalTitle'
          placeholder={t('textfield:createdashboard.title.placeholder')}
          borders={false}
          value={
            dashboardData ? dashboardData['name'].substring(0, maxLength) : null
          }
          ref={titleRef}
        />
      </StyledGroup>

      <StyledGroup>
        <Text
          align='left'
          text={t('textfield:createdashboard.description.label')}
          textStyle='description'
          textColor='#fff'
        />
        <TextField
          textcolor='#fff'
          placeholdercolor='#fff'
          backgroundcolor={theme.color.dark[40]}
          placeholder={t('textfield:createdashboard.description.placeholder')}
          inputId='modalSubTitle'
          borders={false}
          value={
            dashboardData
              ? dashboardData['description'].substring(0, maxLengthDescription)
              : null
          }
          ref={subTitleRef}
        />
      </StyledGroup>
      <StyledContentWrapper>
        <StyledAvatarWrapper id='editavatar' className='active'>
          <StyledAvatarContent rows='1fr' bgColor={theme.color.dark[40]}>
            <StyledUploadAvatar
              rounded={false}
              ref={childRef}
              type='overviewItem'
              itemType='dashboard'
              id='newDashboard'
              currentImage={
                dashboardData && dashboardData.avatar
                  ? dashboardData.avatar.original
                  : ''
              }
              width={100}
              height={100}
              padding={20}
              shadingColor='#000'
              saveData={saveData}
              onClose={preview => onCloseHandler(preview)}
              cropRadius={75}
              backgroundColor={theme.color.light[20]}
              scale={dashboardData.avatar && dashboardData.avatar.scale}
              rotate={dashboardData.avatar && dashboardData.avatar.rotate}
              position={dashboardData.avatar && dashboardData.avatar.position}
              activeDashboardId={activeDashboardId}
              hasButton={false}
            />
          </StyledAvatarContent>
        </StyledAvatarWrapper>
      </StyledContentWrapper>
      <StyledButtonGroup justify='flex-end'>
        <Button
          onClick={dashboardData && handleEditItem}
          id='closeModal'
          bgColor={theme.color.success}
        >
          <Text textColor='#fff' text={t('button:update.text')} />
        </Button>
      </StyledButtonGroup>
    </StyledWrapper>
  );
};

updateContent.propTypes = {
  /** Selected dashboard */
  dashboardId: PropTypes.string,
  /** Close function for the modal */
  closeModals: PropTypes.func,
  /** Current actice dashboard */
  activeDashboardId: PropTypes.string,
};

updateContent.defaultProps = {
  dashboardId: null,
  closeModals: null,
  activeDashboardId: null,
};

//export default connect(mapStateToProps)(Messages);
export default updateContent;
