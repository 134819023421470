import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Lottie from 'react-lottie';

const StyledWrapper = styled.div``;

/**
 * @class LottieControl
 */
const LottieControl = props => {
  const {
    animation,
    animationWidth,
    animationHeight,
    isPaused,
    isStopped,
    loop,
    id,
    initStart,
    initEnd,
  } = props;

  const defaultOptions = {
    loop,
    autoplay: !isPaused,
    /* eslint-disable-next-line global-require, import/no-dynamic-require */
    animationData: require(`./${animation}.json`),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    initialSegment: initStart && initEnd ? [initStart, initEnd] : null,
  };

  return (
    <StyledWrapper id={id} {...props}>
      <Lottie
        options={defaultOptions}
        height={animationHeight}
        width={animationWidth}
        isStopped={isStopped}
        isPaused={isPaused}
        loop={loop}
        initStart={initStart}
        isClickToPauseDisabled
      />
    </StyledWrapper>
  );
};

LottieControl.propTypes = {
  /** Name */
  animation: PropTypes.string,

  /** Width */
  animationWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /** Height */
  animationHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /** Pause */
  isPaused: PropTypes.bool,

  /** Loop */
  loop: PropTypes.bool,

  /** Stopped */
  isStopped: PropTypes.bool,

  /** Id */
  id: PropTypes.string,

  /** Initial start of frame */
  initStart: PropTypes.number,

  /** Initial end of frame */
  initEnd: PropTypes.number,
};

LottieControl.defaultProps = {
  animation: 'funny',
  animationWidth: 200,
  animationHeight: 200,
  isPaused: false,
  isStopped: false,
  loop: true,
  id: null,
  initStart: null,
  initEnd: null,
};

LottieControl.displayName = 'LottieControl';

/** @component */
export default LottieControl;
