/* eslint-disable require-jsdoc */
import textVersion from 'textversionjs';
import { getHTML } from './getHTMLFromNote';
import { getImage } from './getImageFromWhiteboard';
import { getData } from '../../../../firebase';

/** Selecting user in a modal */
const getText = (table, itemId) => {
  if (!itemId) return;
  const listInfo = getData(table, itemId);
  let text = '';
  let checkedText = '';
  let unCheckedText = '';

  //Get List info
  switch (table) {
    default:
    case 'lists':
      //Loop through the list items array
      Object.values(listInfo['items']).map(item => {
        const checked = item.checked;

        if (checked) {
          checkedText += '%5B%58%5D%20' + item.text + '%0A';
        } else {
          unCheckedText += '%5B%20%5D%20' + item.text + '%0A';
        }
        return true;
      });
      text = unCheckedText + '%0A' + checkedText;
      break;
    case 'whiteboards':
      text = getImage(table, itemId);
      //IMage here
      break;
    case 'notes': {
      const htmlText = getHTML(table, itemId).toString();

      const styleConfig = {
        headingStyle: 'indention',
        uIndentionChar: '.',
        listIndentionTabs: 2,
        listStyle: 'indention',
      };
      text = textVersion(htmlText, styleConfig);
    }
  }
  return text;
};

/** Default return function */
const basic = () => {};

export { getText };

export default basic;
