/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { Portal } from 'react-portal';
import styled from 'styled-components';
import theme, { rem } from '../../theme';
import Text from '../Text';
import Icon from '../Icon';
import Image from '../Image';

const StyledContextMenu = styled(ContextMenu)`
  ${({ bgColor }) => `
    z-index: 10;
    border-radius: ${rem(8)};
    padding: ${rem(8)};
    position: relative;
    opacity: 0;

    &:after {
      content: '';
      z-index: -1;
      background-color: ${bgColor ? bgColor : 'rgba(0, 0, 0, 0.5)'};
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border-radius: ${rem(8)};
    }

    &.react-contextmenu--visible {
      opacity: 1;
      transition: 0.15s;
      transition-delay: 0.1s;
    }
  `}
`;

const StyledMenuItem = styled(MenuItem)`
  ${({ special, backgroundColor, number, hide, hasBackgroundHover }) => `
    background: ${backgroundColor ? backgroundColor : '#000'};
    padding: ${rem(2)} ${rem(12)} ${rem(2)} ${rem(5)};
    margin: ${special && number > 1 ? rem(15) : 0} 0 ${rem(5)} 0;
    border-radius: ${rem(8)};
    display: ${hide ? 'none' : 'flex'};
    align-items: center;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: ${
        hasBackgroundHover
          ? theme.color.tertiary[100]
          : backgroundColor
          ? backgroundColor
          : theme.color.dark[80]
      };
      color: #fff;
    }

    &.react-contextmenu-item--disabled {
      cursor: default;

      div {
        color: #fff;
      }
    }
  `}
`;

const StyledImage = styled(Image)`
  padding: ${rem(8)};
`;

/** options for after clicking user in search
 * @param {object} data
 * @param {string} id
 * @param {object} clickedInvite
 * @param {object} friend
 * @param {string} bgColor
 * @param {bool} menuHide
 * @param {bool} hasBackgroundHover
 * @param {html} html
 * @param {function} onShow
 * @param {function} onHide
 */
const ContextMenuItems = props => {
  const {
    id,
    data,
    bgColor,
    menuHide,
    hasBackgroundHover,
    html,
    onShow,
    onHide,
  } = props;
  return (
    <Portal>
      {data && Object.keys(data).length > 0 && (
        <StyledContextMenu
          id={id}
          className='uniqueClassName'
          hide={menuHide}
          bgColor={bgColor}
          onShow={onShow}
          onHide={onHide}
        >
          {html && html}
          {Object.values(data).map(item => {
            return (
              <StyledMenuItem
                bgColor={bgColor}
                disabled={item.disabled}
                hide={item.hide}
                number={Object.keys(data).length}
                key={item.id}
                onClick={item.click}
                special={item.special}
                backgroundColor={item.backgroundColor}
                hasBackgroundHover={hasBackgroundHover}
              >
                {item.icon && (
                  <Icon
                    padding={rem(5)}
                    icon={item.icon}
                    color={item.iconColor ? item.iconColor : '#fff'}
                    size={20}
                  />
                )}

                {item.image && <StyledImage src={item.image} width={rem(33)} />}
                <Text
                  align='left'
                  text={item.text}
                  textStyle='body'
                  textColor={item.textColor ? item.textColor : '#fff'}
                />
              </StyledMenuItem>
            );
          })}
        </StyledContextMenu>
      )}
    </Portal>
  );
};

ContextMenuItems.propTypes = {
  /** Main data that will create the list items */
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /** Id of the clicked item */
  id: PropTypes.string,
  /** Background color */
  bgColor: PropTypes.string,
  /** Hide the menu */
  menuHide: PropTypes.bool,
  /** Indicates if items should have a hover color */
  hasBackgroundHover: PropTypes.bool,
  /** Extra custom HTML */
  html: PropTypes.any,
};

ContextMenuItems.defaultProps = {
  data: null,
  id: null,
  bgColor: null,
  menuHide: null,
  hasBackgroundHover: true,
  html: null,
};

export default ContextMenuItems;
