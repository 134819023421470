import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import withMemo from '../../utils/withMemo';
import theme, { rem } from '../../theme';
import Text from '../Text';
import Group from '../Group';
import Animation from '../LottieControl';
import WidgetPreview from '../WidgetPreview';
import callsImg from './calls.png';
import whiteBoardImg from './whiteboard.png';
import sharingImg from './sharing.png';
import chatImg from './chat.png';
import notesImg from './notes.png';
import listsImg from './lists.png';
import firebase, {
  dashboardListner,
  userDashboardListner,
} from '../../firebase';

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.3);
  margin: ${rem(20)};
  border-radius: ${rem(15)};
  height: auto;
`;

const StyledAnimation = styled(Animation)`
  cursor: help;
`;

const StyledTooltip = styled(ReactTooltip)`
  border-radius: ${rem(5)} !important;
  padding: ${rem(5)} ${rem(10)} !important;
`;

const StyledToolTipText = styled(Text)`
  margin-bottom: ${rem(15)};
`;

const StyledToolTipAnimation = styled(Animation)`
  margin-bottom: ${rem(15)};
`;

const StyledTextGroup = styled(Group)`
  padding-left: ${rem(24)};
  padding-bottom: ${rem(24)};
  padding-top: ${rem(12)};
`;

const StyledWidgetWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.5rem;
  width: 100%;
`;

const StyledIndicatorWrapper = styled.div`
  ${({ maxExceeded }) => `
    display: inline-flex;
    position: absolute;
    top: ${maxExceeded ? rem(2) : 'auto'};
    right: ${rem(10)};
    align-items: center;
    justify-content: flex-end;
  `}
`;

const StyledTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${rem(20)};
  max-width: ${rem(300)};
`;

const StyledIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${theme.color.light[8]};
  width: ${rem(30)};
  height: ${rem(30)};
  transition: 0.2s;
  position: absolute;
`;

const StyledIndicatorLabelText = styled(Text)`
  margin-right: ${rem(35)};
  font-weight: 600;
`;

const StyledIndicatorText = styled(Text)`
  font-weight: 600;
  font-size: ${rem(10)};
`;

/**
 * Overview component
 * @param {object} children
 * @param {string} activeDashboardId
 */
const WidgetStore = props => {
  const { activeDashboardId } = props;

  //UserId
  const uuid = firebase.auth().currentUser.uid;

  //States
  const [totalWidgets, setTotalWidgets] = useState();
  const [maxWidgets, setMaxWidgets] = useState();
  const [maxExceeded, setMaxExceeded] = useState(false);
  const [allWidgets, setAllWidgets] = useState({});

  //Translation
  const [t] = useTranslation(['common', 'widgets', 'tooltip']);

  useEffect(() => {
    dashboardListner
      .child(activeDashboardId)
      .child('widgets')
      .on('value', snap => {
        if (snap.exists()) {
          setAllWidgets(snap.val());
        } else {
          setAllWidgets({});
        }
      });

    dashboardListner
      .child(activeDashboardId)
      .child('maxWidgets')
      .once('value', snapshot => {
        setMaxWidgets(snapshot.val());

        userDashboardListner
          .child(uuid)
          .child(activeDashboardId)
          .child('totalWidgets')
          .on('value', snap => {
            setTotalWidgets(snap.val());
            if (snap.val() === 0) {
              setTotalWidgets('0');
            } else {
              setTotalWidgets(snap.val());
            }

            if (snap.val() < snapshot.val()) {
              setMaxExceeded(false);
            } else {
              setMaxExceeded(true);
            }
          });
      });
  }, []);

  /** Handle disable */
  const handleDisable = type => {
    let disableItem = false;
    if (maxExceeded) disableItem = true;
    Object.values(allWidgets).map(widget => {
      if (widget.singleton && type === widget.type) {
        disableItem = true;
      }
    });

    return disableItem;
  };

  return (
    <StyledWrapper>
      <Group
        padding={[
          {
            top: rem(20),
            right: rem(10),
            bottom: rem(10),
            left: rem(25),
          },
        ]}
        justify='space-between'
      >
        <Group fullwidth>
          <Text
            text={t('common:widgets.available')}
            textStyle='subtitle'
            textColor={theme.color.light[100]}
          />
        </Group>
        {totalWidgets && maxWidgets && (
          <StyledIndicatorWrapper maxExceeded={maxExceeded}>
            {maxExceeded && (
              <React.Fragment>
                <StyledAnimation
                  animation='alert'
                  animationWidth={45}
                  animationHeight={45}
                  data-for='tooltipStore'
                  data-tip=''
                />
                <StyledTooltip
                  id='tooltipStore'
                  getContent={() => {
                    return (
                      <StyledTooltipWrapper>
                        <StyledToolTipText
                          text={t('tooltip:errors.widgets.title')}
                          textStyle='h6'
                          textColor='#fff'
                          align='center'
                        />
                        <StyledToolTipAnimation
                          animation='cry'
                          animationWidth={rem(100)}
                          animationHeight={rem(100)}
                        />
                        <Text
                          text={t('tooltip:errors.widgets.text')}
                          textStyle='subtitle'
                          textColor='#fff'
                          align='center'
                        />
                      </StyledTooltipWrapper>
                    );
                  }}
                />
              </React.Fragment>
            )}
            <StyledIndicatorLabelText
              text={t('common:widgets.active')}
              textColor={theme.color.light[100]}
              textStyle='description2'
            />
            <StyledIndicator>
              <StyledIndicatorText
                text={`${totalWidgets}/${maxWidgets}`}
                textColor={theme.color.light[100]}
                align='center'
              />
            </StyledIndicator>
          </StyledIndicatorWrapper>
        )}
      </Group>
      <React.Fragment>
        <StyledWidgetWrapper>
          <WidgetPreview
            type='chats'
            singleton
            price={t('widgets:chats.pricew')}
            creator={t('common:main.creator')}
            hasHeader={false}
            resizeMax={180}
            hasOverview={false}
            hasPivot
            title={t('widgets:chats.title')}
            image={chatImg}
            StudentsPlus
            disable={handleDisable('chats')}
          />
          <WidgetPreview
            type='lists'
            price={t('widgets:lists.price')}
            creator={t('common:main.creator')}
            hasHeader={false}
            hasOverview={false}
            hasPivot
            title={t('widgets:lists.title')}
            image={listsImg}
            StudentsPlus
            disable={maxExceeded}
          />
          <WidgetPreview
            type='notes'
            price={t('widgets:notes.price')}
            creator={t('common:main.creator')}
            hasHeader={false}
            resizeMax={200}
            hasOverview={false}
            hasPivot
            title={t('widgets:notes.title')}
            image={notesImg}
            StudentsPlus
            disable={maxExceeded}
          />
          <WidgetPreview
            type='calls'
            price={t('widgets:calls.price')}
            singleton
            creator={t('common:main.creator')}
            hasHeader={false}
            hasOverview={false}
            hasPivot
            title={t('widgets:calls.title')}
            image={callsImg}
            StudentsPlus
            disable={handleDisable('calls')}
          />
          <WidgetPreview
            type='whiteboards'
            price={t('widgets:whiteboards.price')}
            creator={t('common:main.creator')}
            hasHeader={false}
            resizeMax={200}
            hasOverview={false}
            hasPivot
            title={t('widgets:whiteboards.title')}
            image={whiteBoardImg}
            StudentsPlus
            disable={maxExceeded}
          />
          <WidgetPreview
            type='documentsharing'
            price={t('widgets:sharing.price')}
            creator={t('common:main.creator')}
            hasHeader={false}
            resizeMax={200}
            hasOverview={false}
            hasPivot
            title={t('widgets:sharing.title')}
            image={sharingImg}
            StudentsPlus
            disable={maxExceeded}
          />
        </StyledWidgetWrapper>
        {/* <StyledWidgetWrapper>
          <StyledTextGroup fullwidth>
            <Text
              text="Third party"
              textStyle="h3"
              textColor={theme.color.light[100]}
            />
            <Text
              text="Experimental cross application widgets (do not work with realtime collaboration)"
              textStyle="body"
              textColor={theme.color.light[100]}
            />
          </StyledTextGroup>
          <WidgetPreview
            price="Free"
            type="thirdParties"
            url="https://www.photopea.com/"
            creator="Photopea"
            hasHeader={false}
            hasOverview={false}
            hasPivot
            title="Photopea"
            image="https://www.photopea.com/promo/thumb256.png"
            disable={maxExceeded}
          />
          <WidgetPreview
            price="Free"
            type="thirdParties"
            url="https://codesandbox.io/s/"
            creator="Codesandbox"
            hasHeader={false}
            hasOverview={false}
            hasPivot
            title="Codesandbox"
            image="https://arches.capital/wp-content/uploads/2019/05/codesandbox-pr.jpg"
            disable={maxExceeded}
          />
        </StyledWidgetWrapper> */}
      </React.Fragment>
    </StyledWrapper>
  );
};

WidgetStore.propTypes = {
  /** Current active dashboard Id */
  activeDashboardId: PropTypes.string,
};

WidgetStore.defaultProps = {
  activeDashboardId: null,
};

export default withMemo(WidgetStore, ['widgetData']);
