import React, { useEffect, useState, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { ContextMenuTrigger } from 'react-contextmenu';
import { modalOpen, modalType } from '../../redux/actions';
import theme, { rem } from '../../theme';
import Text from '../Text';
import Icon from '../Icon';
import UploadAvatar from '../UploadAvatar';
import CustomToast from '../CustomToast';
import Image from '../Image';
import List from '../List';
import ListItem from '../ListItem';
import Grid from '../Grid';
import OverviewSelector from '../OverviewSelector';
import Button from '../Button';
import Badge from '../Badge';
import Animation from '../LottieControl';
import ScrollSpyContent from '../ScrollSpyContent';
import withMemo from '../../utils/withMemo';
import ScrollSpyNav from '../ScrollSpyNav';
import Group from '../Group';
import TextField from '../TextField';
import SearchBar from '../SearchBar';
import ContextMenuItems from '../ContextMenuItems';
import firebase, {
  userListner,
  dashboardListner,
  undoRequest,
  acceptRequest,
  declineRequest,
  addNotification,
  inviteKeyListner,
  userDashboardListner,
} from '../../firebase';

const StyledMainWrapper = styled.div`
  ${({ elementDimensions }) => `
    transition: 0.4s;
    position: absolute;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: ${rem(10)};
  `}
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: ${rem(10)};
`;

const StyledInnerWrapper = styled.div`
  padding: 0 ${rem(20)} ${rem(20)} ${rem(20)};
  margin-top: -0.5rem;
`;

const StyledLoader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledAnimation = styled(Animation)`
  cursor: help;
`;

const StyledTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${rem(20)};
  width: ${rem(250)};
`;

const StyledTooltip = styled(ReactTooltip)`
  border-radius: ${rem(5)} !important;
  padding: ${rem(5)} ${rem(10)} !important;
`;

const StyledToolTipText = styled(Text)`
  margin-bottom: ${rem(15)};
`;

const StyledToolTipAnimation = styled(Animation)`
  margin-bottom: ${rem(15)};
`;

const StyledCard = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: ${rem(20)};
  background: rgba(0, 0, 0, 0.3);
  border-radius: ${rem(10)};
  margin-bottom: ${rem(15)};
  margin-top: ${rem(5)};
`;

const StyledList = styled(List)`
  width: 100%;
  margin: 0;
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(20)};
`;

const StyledButtonGroup = styled(Group)`
  position: absolute;
  bottom: -${rem(15)};
  width: 100%;
  justify-content: center;
`;

const StyledButtonGroupFirst = styled(Group)`
  position: absolute;
  top: -${rem(20)};
  width: 100%;
  justify-content: center;
`;

const StyledNavGroup = styled(Group)`
  margin-bottom: ${rem(15)};
  button {
    min-width: auto;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${theme.color.success};
      }
    }
  }
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  z-index: 1;
  display: flex;
  width: 100%;
  top: -${rem(10)};
`;

const StyledGridWrapper = styled(Grid)`
  z-index: 1;
  display: none;
  height: ${rem(250)};

  &.active {
    display: block;
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
`;

const StyledGroup = styled(Group)`
  flex-direction: column;
  margin-bottom: ${rem(10)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${theme.color.light[8]};
  width: ${rem(30)};
  height: ${rem(30)};
  transition: 0.2s;
  position: absolute;
  right: 0;
`;

const StyledIndicatorLabelText = styled(Text)`
  margin-right: ${rem(35)};
  font-weight: 600;
`;

const StyledIndicatorText = styled(Text)`
  font-weight: 600;
  font-size: ${rem(10)};
`;

const StyledIndicatorWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledBasicInfoWrapper = styled.div`
  margin-bottom: ${rem(20)};
`;

const StyledInnerSearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

const StyledInputGroup = styled(Group)`
  button {
    min-width: 1.5rem;
    min-height: 1.5rem;
    margin 0 !important;
  }
`;

const StyledTextField = styled(TextField)`
  margin-right: ${rem(50)};
  text-overflow: ellipsis;
`;

/**
 * Overview component
 *
 */
const UserDashboardSettings = props => {
  const {
    activeDashboardId,
    elementDimensions,
    menuColor,
    // eslint-disable-next-line react/prop-types
    myForwardedRef,
  } = props;

  //States
  const [userInfo, setUserInfo] = useState({});
  const [dashboardInfo, setDashboardInfo] = useState({});
  const [inviteInfo, setInviteInfo] = useState({});
  const [dashboardOut, setDashboardOut] = useState({});
  const [clickedInvite, setClickedInvite] = useState({});
  const [newInvite, setNewInvite] = useState(false);
  const [ready, setReady] = useState(false);
  const [maxMembers, setMaxMembers] = useState();
  const [maxDashboards, setMaxDashboards] = useState();
  const [totalMembers, setTotalMembers] = useState();
  const [totalDashboards, setTotalDashboards] = useState();
  const [maxExceeded, setMaxExceeded] = useState(false);
  const [maxExceededDashboards, setMaxExceededDashboards] = useState(false);
  const [invitePermission, setInvitePermission] = useState(false);
  const [detailsPermission, setDetailsPermission] = useState(false);
  const [maxLength] = useState(100);
  const [handleAttendee, setHandleAttendee] = useState();

  //UserId
  const uuid = firebase.auth().currentUser.uid;

  //Translation
  const [t] = useTranslation([
    'toasts',
    'tooltip',
    'text',
    'button',
    'emptystate',
  ]);

  //Refs
  const nameRef = useRef();
  const descriptionRef = useRef();
  const linkRef = useRef();

  useEffect(() => {
    if (activeDashboardId) {
      listnerSetup();
      createInviteObject();
    }
  }, []);

  /** Handle public change */
  const handlePublicChange = type => {
    if (nameRef.current && descriptionRef.current) {
      let value = nameRef.current.value;
      if (type === 'name')
        value = nameRef.current.value.substring(0, maxLength);
      if (type === 'description')
        value = descriptionRef.current.value.substring(0, maxLength);

      dashboardListner.child(activeDashboardId).update({ [type]: value });
    }
  };

  /** Setup the listners */
  const listnerSetup = () => {
    //DASHBOARD LISTNERS
    dashboardInfo[activeDashboardId] = {};

    userListner
      .child(uuid)
      .child('maxDashboards')
      .on('value', snap => {
        if (snap.val()) {
          setMaxDashboards(snap.val());
        }
      });

    userDashboardListner
      .child(uuid)
      .child(activeDashboardId)
      .child('permissions')
      .on('value', snap => {
        if (snap.val()) {
          setInvitePermission(snap.val().invite);
          setDetailsPermission(snap.val().details);
        }
      });

    //Get the max and total dasboard count and see if it exceeded
    userListner
      .child(uuid)
      .child('totalDashboards')
      .on('value', snapshot => {
        if (snapshot.val()) {
          userListner
            .child(uuid)
            .child('maxDashboards')
            .once('value', snap => {
              if (snapshot.val() >= snap.val()) {
                setMaxExceededDashboards(true);
              } else {
                setMaxExceededDashboards(false);
              }
            });
          setTotalDashboards(snapshot.val());
        }
      });

    dashboardListner.child(activeDashboardId).once('value', snap => {
      if (snap.val()) {
        setMaxMembers(snap.val().maxMembers);
        setTotalMembers(snap.val().totalMembers);
      }
    });

    //Get the max and total member count and see if it exceeded
    dashboardListner
      .child(activeDashboardId)
      .child('totalMembers')
      .on('value', snapshot => {
        if (snapshot.val()) {
          dashboardListner
            .child(activeDashboardId)
            .child('maxMembers')
            .once('value', snap => {
              if (snapshot.val() >= snap.val()) {
                setMaxExceeded(true);
              } else {
                setMaxExceeded(false);
              }
            });
          setTotalMembers(snapshot.val());
        }
      });

    //DASHBOARD INVITE OUT ONCE LISTNER
    userListner
      .child(uuid)
      .child('invites')
      .child('dashboards')
      .child('invitesOut')
      .once('value', snap => {
        let dataObject = {};
        if (snap.val()) {
          snap.forEach(function(invitedUserSnapshot) {
            if (invitedUserSnapshot.val()) {
              dataObject[invitedUserSnapshot.key] = {};
              dataObject[invitedUserSnapshot.key]['invite'] =
                invitedUserSnapshot.key;
              userListner
                .child(invitedUserSnapshot.val().to)
                .child('public')
                .once('value', snap => {
                  dataObject[invitedUserSnapshot.key]['user'] = snap.val();
                  dataObject[invitedUserSnapshot.key]['user'][
                    'id'
                  ] = invitedUserSnapshot.val().to;
                  setDashboardOut(dashboardOut);
                })
                .then(() => {
                  dashboardListner
                    .child(invitedUserSnapshot.val().dashboardId)
                    .child('avatar')
                    .once('value', snap => {
                      dataObject[invitedUserSnapshot.key]['dashboard'] = {};
                      dataObject[invitedUserSnapshot.key]['dashboard'][
                        'name'
                      ] = invitedUserSnapshot.val().name;
                      dataObject[invitedUserSnapshot.key]['dashboard'][
                        'image'
                      ] = snap.val().cropped;
                    })
                    .then(() => {
                      setDashboardOut(dataObject);
                    });
                });
            }
          });
          setDashboardOut(prevState => {
            // Object.assign would also work
            return { ...prevState, ...dashboardOut };
          });
        } else {
          setDashboardOut({});
        }
      });

    //DASHBOARD GLOBAL ONCE LISTNER
    dashboardListner
      .child(activeDashboardId)
      .once('value', snap => {
        if (snap.val()) {
          dashboardInfo[activeDashboardId]['avatar'] = snap.val().avatar;
          dashboardInfo[activeDashboardId]['name'] = snap.val().name;
          dashboardInfo[activeDashboardId][
            'description'
          ] = snap.val().description;
          dashboardInfo[activeDashboardId]['link'] = snap.val().link;
        }
      })
      .then(() => {
        setDashboardInfo(dashboardInfo);
        setReady(true);
      });

    //DASHBOARD LISTENING ON THE INVITES TABLE
    dashboardListner
      .child(activeDashboardId)
      .child('invites')
      .child('dashboards')
      .on('value', snap => {
        const objectItem = snap.val();
        setDashboardInfo(prevState => {
          // Object.assign would also work
          return { ...prevState, ...objectItem };
        });
      });

    //DASHBOARD LISTNER ON CHILD CHANGED
    dashboardListner.child(activeDashboardId).on('child_changed', snapShot => {
      const parentKey = snapShot.ref.parent.key;
      if (
        dashboardInfo &&
        dashboardInfo[parentKey] &&
        snapShot.key === 'avatar' &&
        snapShot.val().cropped
      ) {
        dashboardInfo[parentKey]['avatar'] = snapShot.val();
        setDashboardInfo(prevState => {
          return { ...prevState, ...dashboardInfo };
        });
      }

      if (
        snapShot.key === 'name' &&
        dashboardInfo &&
        dashboardInfo[parentKey]
      ) {
        dashboardInfo[parentKey]['name'] = snapShot.val();
        setDashboardInfo(prevState => {
          return { ...prevState, ...dashboardInfo };
        });
        nameRef.current.value = snapShot.val();
      }

      if (
        snapShot.key === 'description' &&
        dashboardInfo &&
        dashboardInfo[parentKey]
      ) {
        dashboardInfo[parentKey]['description'] = snapShot.val();
        setDashboardInfo(prevState => {
          return { ...prevState, ...dashboardInfo };
        });
        descriptionRef.current.value = snapShot.val();
      }
    });
    userListner
      .child(uuid)
      .child('invites')
      .child('dashboards')
      .child('invitesOut')
      .orderByChild('time')
      .startAt(Date.now())
      .on('child_added', snap => {
        dashboardOut[snap.key] = {};
        // userListner
        //   .child(uuid)
        //   .child('invites')
        //   .child('dashboards')
        //   .child('invitesIn')
        //   .off();

        dashboardOut[snap.key]['invite'] = snap.key;

        dashboardOut[snap.key]['dashboard'] = snap.val();
        userListner
          .child(snap.val().to)
          .child('public')
          .once('value', snapUser => {
            if (snapUser.key) {
              dashboardOut[snap.key]['user'] = {};
              dashboardOut[snap.key]['user'] = snapUser.val();
              dashboardOut[snap.key]['user']['id'] = snap.val().to;
            }
          })
          .then(() => {
            setDashboardOut(prevState => {
              return { ...prevState, ...dashboardOut };
            });
          });
      });

    //DASHBOARD LISTNER ON INVITE OUT CHILD_REMOVED
    userListner
      .child(uuid)
      .child('invites')
      .child('dashboards')
      .child('invitesOut')
      .on('child_removed', snap => {
        delete dashboardOut[snap.key];
        setDashboardOut(dashboardOut);
      });

    //DASHBOARD LISTNER ON MEMBERS
    dashboardListner
      .child(activeDashboardId)
      .child('members')
      .on('child_added', snap => {
        if (snap.key !== uuid) {
          userListner
            .child(snap.key)
            .child('public')
            .once('value', snapShot => {
              userInfo[snap.key] = snapShot.val();

              setUserInfo(prevState => {
                return { ...prevState, ...userInfo };
              });
            });
        }
      });

    dashboardListner
      .child(activeDashboardId)
      .child('members')
      .on('child_removed', snap => {
        if (uuid !== snap.key) {
          delete userInfo[snap.key];
          setUserInfo(userInfo);
        }
      });

    /** USER INVITE LISTNERS */

    //INVITE LISTNER ONLY WATCH SEEN
    userListner
      .child(uuid)
      .child('invites')
      .child('dashboards')
      .child('seen')
      .on('value', snapSeen => {
        setNewInvite(snapSeen.val());
      });

    //INVITE LISTNER CHILD ADDED INVITE IN, SO WHEN THERE HAS BEEN ADDED A NEW INVITE
    userListner
      .child(uuid)
      .child('invites')
      .child('dashboards')
      .child('invitesIn')
      .orderByChild('time')
      .startAt(Date.now())
      .on('child_added', snap => {
        inviteInfo[snap.key] = {};
        // userListner
        //   .child(uuid)
        //   .child('invites')
        //   .child('dashboards')
        //   .child('invitesIn')
        //   .off();

        inviteInfo[snap.key]['invite'] = snap.key;

        inviteInfo[snap.key]['dashboard'] = snap.val();
        userListner
          .child(snap.val().from)
          .child('public')
          .once('value', snapUser => {
            if (snapUser.key) {
              inviteInfo[snap.key]['user'] = {};
              inviteInfo[snap.key]['user'] = snapUser.val();
              inviteInfo[snap.key]['user']['id'] = snap.val().from;

              CustomToast.info(t('invites.new') + snapUser.val().name);

              addNotification(
                uuid,
                'dashboardInvites',
                activeDashboardId,
                t('invites.dashboard') + snap.val().name,
                'success',
                '/resources/dashboard.svg',
                { accept: true, decline: true },
                snap.key
              );
            }
          })
          .then(() => {
            userListner
              .child(uuid)
              .child('invites')
              .child('dashboards')
              .update({
                seen: false,
              });

            setInviteInfo(prevState => {
              // Object.assign would also work
              return { ...prevState, ...inviteInfo };
            });
          });
      });

    //INVITE USER CHILD REMOVED, SO WHEN THERE HAS BEEN AN INVITE REMOVED
    userListner
      .child(uuid)
      .child('invites')
      .child('dashboards')
      .child('invitesIn')
      .on('child_removed', snapRemove => {
        delete inviteInfo[snapRemove.key];
        createInviteObject();
      });
  };

  /** Create invite object */
  const createInviteObject = () => {
    userListner
      .child(uuid)
      .child('invites')
      .child('dashboards')
      .child('invitesIn')
      .once('value', snap => {
        let dataObject = {};
        if (snap.val()) {
          snap.forEach(function(snapshot) {
            if (
              snapshot.val() &&
              snapshot.val().from &&
              snapshot.val().type === 'dashboards'
            ) {
              dataObject[snapshot.key] = {};
              dataObject[snapshot.key]['invite'] = snapshot.key;
              dataObject[snapshot.key]['dashboard'] = snapshot.val();
              userListner
                .child(snapshot.val().from)
                .child('public')
                .once('value', snap => {
                  dataObject[snapshot.key]['user'] = snap.val();
                  dataObject[snapshot.key]['user']['id'] = snapshot.val().from;
                })
                .then(() => {
                  setInviteInfo(dataObject);
                });
            }
          });
        } else {
          setInviteInfo({});
          userListner
            .child(uuid)
            .child('invites')
            .child('dashboards')
            .update({
              seen: true,
            });
        }
      });
  };

  /** Handle click */
  const handleClick = (type, buttonType) => {
    //Element
    document.getElementById('attendeesList').classList.remove('active');
    document
      .getElementById('pendingInvitesDashboard')
      .classList.remove('active');
    document
      .getElementById('incomingDashboardRequests')
      .classList.remove('active');
    document.getElementById('searchUserDashboard').classList.remove('active');

    //Button
    document
      .getElementById('buttonDashboardAttendees')
      .classList.remove('active');
    document
      .getElementById('buttonInvitesDashboard')
      .classList.remove('active');
    document
      .getElementById('buttonIncomingInvitesDashboard')
      .classList.remove('active');
    document
      .getElementById('buttonSearchUserDashboard')
      .classList.remove('active');

    //Active
    document.getElementById(type).classList.add('active');
    document.getElementById(buttonType).classList.add('active');

    //Remove all numerical badges
    if (type === 'incomingDashboardRequests') {
      if (inviteInfo) handleRemoveNumber();
    }
  };

  /** handleRemoveNumber */
  const handleRemoveNumber = () => {
    userListner
      .child(uuid)
      .child('invites')
      .child('dashboards')
      .child('invitesIn')
      .once('value', snap => {
        snap.forEach(function(childSnapshot) {
          if (!childSnapshot.seen) {
            childSnapshot.ref.update({ seen: true });
            inviteInfo[childSnapshot.key]['dashboard']['seen'] = true;
          }
        });
      })
      .then(() => {
        userListner
          .child(uuid)
          .child('invites')
          .child('dashboards')
          .update({
            seen: true,
          });
      });
  };

  /** Handle Number */
  const handleNumber = () => {
    let counter = 0;
    Object.entries(inviteInfo).map(invite => {
      if (
        invite &&
        invite[1] &&
        invite[1]['dashboard'] &&
        !invite[1]['dashboard'].seen
      ) {
        counter++;
      }
    });
    return counter;
  };

  /** Generate a custom link */
  const generateLink = () => {
    dashboardListner
      .child(activeDashboardId)
      .child('link')
      .once('value', snap => {
        //20 minutes were passed from start
        if (Date.now() - snap.val().time >= 20000) {
          const randomId =
            Math.random()
              .toString(36)
              .substring(2, 15) +
            Math.random()
              .toString(36)
              .substring(2, 15);

          dashboardListner
            .child(activeDashboardId)
            .child('link')
            .update({
              id: randomId,
              time: Date.now(),
            })
            .then(() => {
              linkRef.current.value =
                'onlinedashboard.studentsplus.nl/' + randomId;
              copyText();
            })
            .then(() => {
              const oldKey = snap.val().id;
              inviteKeyListner
                .child(oldKey)
                .remove()
                .then(() => {
                  inviteKeyListner.update({
                    [randomId]: {
                      id: randomId,
                      dashboardId: activeDashboardId,
                      friendId: uuid,
                      time: Date.now(),
                    },
                  });
                });
            });
        }
      });
  };

  /** Copy the text */
  const copyText = () => {
    if (linkRef.current) {
      /* Get the text field */
      var copyText = linkRef.current;

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand('copy');
    }
  };

  /** sets a state to keep track of active clicked person in invited users tab */
  const setClickedInviteHandler = invite => {
    setClickedInvite(invite);
  };

  return (
    <StyledMainWrapper
      ref={myForwardedRef}
      elementDimensions={elementDimensions}
      {...props}
    >
      <Grid>
        <StyledWrapper>
          {ready ? (
            <StyledInnerWrapper menuColor={menuColor}>
              {dashboardInfo && (
                <ScrollSpyNav
                  fixed
                  itemId="dashboard"
                  iconColor="#fff"
                  icons="settings people view_carousel"
                  indicators="settings people view_carousel"
                  scrollTos="scrollBasicsdashboard scrollMembersdashboard scrollSwitchdashboard"
                  badges={`empty ${!newInvite ? 'information' : 'empty'} empty`}
                  toolTips={t('tooltip:menu.dashboard.tooltips')}
                />
              )}
              <ScrollSpyContent scrollIds="scrollBasicsdashboard scrollMembersdashboard scrollSwitchdashboard">
                <div>
                  <Text
                    align="left"
                    text={t('text:menu.labels.dashboard.basics')}
                    textStyle="h6"
                    textColor="#fff"
                  />
                  <StyledBasicInfoWrapper>
                    <Text
                      align="left"
                      text={t('text:menu.labels.dashboard.max', {
                        maxDashboards: maxDashboards,
                      })}
                      textStyle="description2"
                      textColor="#fff"
                    />
                    <Text
                      align="left"
                      text={t('text:menu.labels.dashboard.maxmembers', {
                        maxMembers: maxMembers,
                      })}
                      textStyle="description2"
                      textColor="#fff"
                    />
                  </StyledBasicInfoWrapper>
                  <StyledCard>
                    <StyledButtonGroupFirst justify="center">
                      <Button
                        id="addDashboardFirst"
                        disabled={maxExceededDashboards ? true : false}
                        variant="icon"
                        onClick={() => {
                          props.modalOpen(true);
                          props.modalType(['adddashboard']);
                        }}
                        bgColor={theme.color.light[80]}
                      >
                        <Icon
                          padding={rem(12)}
                          icon={maxExceededDashboards ? 'block' : 'add'}
                          size={20}
                        />
                      </Button>
                    </StyledButtonGroupFirst>
                    {invitePermission && (
                      <StyledGroup fullWidth>
                        <Text
                          align="left"
                          text={t('text:menu.labels.dashboard.sharelink')}
                          textStyle="description"
                          textColor="#fff"
                        />
                        <Group>
                          <StyledTextField
                            selectionBackground="yellow"
                            readOnly
                            hasSinlgeButton
                            textcolor="#fff"
                            placeholdercolor="#fff"
                            backgroundcolor={theme.color.dark[40]}
                            inputId="modalLink"
                            borders={false}
                            value={
                              dashboardInfo[activeDashboardId]['link']
                                ? 'https://onlinedashboard.studentsplus.nl/' +
                                  dashboardInfo[activeDashboardId]['link'].id
                                : null
                            }
                            ref={linkRef}
                          />
                          <StyledInputGroup type="inputOption">
                            <Button
                              variant="icon"
                              style={{ marginRight: rem(4) }}
                              title={t('text:menu.labels.dashboard.copylink')}
                              onClick={() => copyText()}
                            >
                              <Icon
                                icon="content_copy"
                                padding={rem(6)}
                                size={16}
                                color={theme.color.light[100]}
                              />
                            </Button>

                            <Button
                              variant="icon"
                              style={{ marginRight: rem(4) }}
                              title={t('text:menu.labels.dashboard.refresh')}
                              onClick={() => generateLink()}
                            >
                              <Icon
                                icon="refresh"
                                padding={rem(6)}
                                size={16}
                                color={theme.color.light[100]}
                              />
                            </Button>
                          </StyledInputGroup>
                        </Group>
                      </StyledGroup>
                    )}
                    {dashboardInfo && Object.keys(dashboardInfo).length > 0 && (
                      <StyledGroup fullWidth>
                        <Text
                          align="left"
                          text={t('text:menu.labels.dashboard.name')}
                          textStyle="description"
                          textColor="#fff"
                        />
                        {detailsPermission ? (
                          <TextField
                            value={
                              dashboardInfo[activeDashboardId]['name'] &&
                              dashboardInfo[activeDashboardId][
                                'name'
                              ].substring(0, maxLength)
                            }
                            textcolor="#fff"
                            placeholder={t(
                              'text:menu.placeholder.dashboard.name'
                            )}
                            placeholdercolor={theme.color.light[40]}
                            backgroundcolor={theme.color.dark[40]}
                            ref={nameRef}
                            borders={false}
                            extraOnblur={() => handlePublicChange('name')}
                          />
                        ) : (
                          <Text
                            align="left"
                            text={
                              dashboardInfo[activeDashboardId]['name'] &&
                              dashboardInfo[activeDashboardId][
                                'name'
                              ].substring(0, maxLength)
                            }
                            textColor="#fff"
                          />
                        )}
                      </StyledGroup>
                    )}

                    <StyledGroup fullWidth>
                      <Text
                        align="left"
                        text={t('text:menu.labels.dashboard.description')}
                        textStyle="description"
                        textColor="#fff"
                      />
                      {detailsPermission ? (
                        <TextField
                          value={
                            dashboardInfo[activeDashboardId]['description'] &&
                            dashboardInfo[activeDashboardId][
                              'description'
                            ].substring(0, maxLength)
                          }
                          textcolor="#fff"
                          placeholder={t(
                            'text:menu.placeholder.dashboard.description'
                          )}
                          placeholdercolor={theme.color.light[40]}
                          backgroundcolor={theme.color.dark[40]}
                          ref={descriptionRef}
                          borders={false}
                          extraOnblur={() => handlePublicChange('description')}
                        />
                      ) : (
                        <Text
                          align="left"
                          text={
                            dashboardInfo[activeDashboardId]['description'] &&
                            dashboardInfo[activeDashboardId][
                              'description'
                            ].substring(0, maxLength)
                          }
                          textColor="#fff"
                        />
                      )}
                    </StyledGroup>

                    <Text
                      align="left"
                      text={t('text:menu.labels.dashboard.image')}
                      textStyle="description2"
                      textColor="#fff"
                    />
                    {detailsPermission ? (
                      <UploadAvatar
                        fullWidth
                        rounded={false}
                        type="dashboard"
                        itemType="dashboard"
                        id={activeDashboardId}
                        buttonText={t('button:save.saveit')}
                        hasBackground={false}
                        currentImage={
                          dashboardInfo[activeDashboardId]['avatar']
                            ? dashboardInfo[activeDashboardId]['avatar']
                                .original
                            : '/resources/placeholder-logo.png'
                        }
                        width={160}
                        height={90}
                        padding={20}
                        scale={
                          dashboardInfo[activeDashboardId]['avatar'] &&
                          dashboardInfo[activeDashboardId]['avatar'].scale
                        }
                        rotate={
                          dashboardInfo[activeDashboardId]['avatar'] &&
                          dashboardInfo[activeDashboardId]['avatar'].rotate
                        }
                        position={
                          dashboardInfo[activeDashboardId]['avatar'] &&
                          dashboardInfo[activeDashboardId]['avatar'].position
                        }
                        activeDashboardId={activeDashboardId}
                      />
                    ) : (
                      <Image
                        src={
                          dashboardInfo[activeDashboardId]['avatar']
                            ? dashboardInfo[activeDashboardId]['avatar']
                                .original
                            : '/resources/placeholder-logo.png'
                        }
                      />
                    )}
                  </StyledCard>
                </div>
                <div>
                  <StyledTitleWrapper>
                    <Text
                      align="left"
                      text={t('text:menu.labels.dashboard.attendees')}
                      textStyle="h6"
                      textColor="#fff"
                      inline
                    />
                    <StyledIndicatorWrapper>
                      {maxExceeded && (
                        <React.Fragment>
                          <StyledAnimation
                            animation="alert"
                            animationWidth={30}
                            animationHeight={30}
                            data-for="tooltipMenuDashboardMembers"
                            data-tip=""
                          />
                          <StyledTooltip
                            id="tooltipMenuDashboardMembers"
                            getContent={() => {
                              return (
                                <StyledTooltipWrapper>
                                  <StyledToolTipText
                                    text="Oops.. seems like your dashboard is full"
                                    textStyle="h6"
                                    textColor="#fff"
                                    align="center"
                                  />
                                  <StyledToolTipAnimation
                                    animation="cry"
                                    animationWidth={rem(100)}
                                    animationHeight={rem(100)}
                                  />
                                  <Text
                                    text="You exceeded the max number of attendees."
                                    textStyle="subtitle"
                                    textColor="#fff"
                                    align="center"
                                  />
                                </StyledTooltipWrapper>
                              );
                            }}
                          />
                        </React.Fragment>
                      )}
                      <StyledIndicatorLabelText
                        text={t('text:menu.labels.dashboard.total')}
                        textColor={theme.color.light[100]}
                        textStyle="description2"
                      />
                      <StyledIndicator>
                        <StyledIndicatorText
                          text={`${totalMembers}/${maxMembers}`}
                          textColor={theme.color.light[100]}
                          align="center"
                        />
                      </StyledIndicator>
                    </StyledIndicatorWrapper>
                  </StyledTitleWrapper>
                  <StyledCard>
                    {/* DASHBOARD NAVIGATION */}
                    <StyledNavGroup>
                      <Button
                        id="buttonDashboardAttendees"
                        variant="text"
                        onClick={() =>
                          handleClick(
                            'attendeesList',
                            'buttonDashboardAttendees'
                          )
                        }
                        bgColor={theme.color.dark[8]}
                        className="active"
                        text={{
                          text: t('button:nav.attendees'),
                          textColor: '#fff',
                          textStyle: 'description',
                          align: 'center',
                        }}
                      />
                      <Button
                        id="buttonInvitesDashboard"
                        variant="text"
                        onClick={() =>
                          handleClick(
                            'pendingInvitesDashboard',
                            'buttonInvitesDashboard'
                          )
                        }
                        bgColor={theme.color.dark[8]}
                        text={{
                          text: t('button:nav.sendrequest'),
                          textColor: '#fff',
                          textStyle: 'description',
                          align: 'center',
                        }}
                      />
                      <ButtonWrapper>
                        {inviteInfo && Object.keys(inviteInfo).length > 0 && (
                          <StyledBadge text={handleNumber()} />
                        )}
                        <Button
                          id="buttonIncomingInvitesDashboard"
                          variant="text"
                          onClick={() =>
                            handleClick(
                              'incomingDashboardRequests',
                              'buttonIncomingInvitesDashboard'
                            )
                          }
                          bgColor={theme.color.dark[8]}
                          text={{
                            text: t('button:nav.receivedrequest'),
                            textColor: '#fff',
                            textStyle: 'description',
                            align: 'center',
                          }}
                        />
                      </ButtonWrapper>
                      <Button
                        id="buttonSearchUserDashboard"
                        variant="text"
                        onClick={() =>
                          handleClick(
                            'searchUserDashboard',
                            'buttonSearchUserDashboard'
                          )
                        }
                        bgColor={theme.color.dark[8]}
                        text={{
                          text: '',
                          textColor: '#fff',
                          textStyle: 'description',
                          align: 'center',
                        }}
                        icon={{
                          icon: 'search',
                          color: '#fff',
                          size: 24,
                        }}
                      />
                    </StyledNavGroup>

                    {/* DASHBOARD ATTENDEES */}
                    <StyledGridWrapper
                      columns="1fr"
                      parentId="attendeesList"
                      className="active"
                    >
                      {userInfo && Object.keys(userInfo).length > 0 ? (
                        <StyledList evenRadius autoWidth>
                          {Object.entries(userInfo).map(friend => {
                            return (
                              // eslint-disable-next-line react/jsx-key
                              <ContextMenuTrigger
                                id="dashboardFriend"
                                holdToDisplay={1}
                                key={friend[0]}
                              >
                                <ListItem
                                  evenRadius
                                  onItemClick={() => {
                                    setHandleAttendee(friend[0]);
                                  }}
                                  title={friend[1].name ? friend[1].name : ''}
                                  avatar={{
                                    status: friend[1].indicator,
                                    source:
                                      friend[1].avatar &&
                                      friend[1]['avatar'].cropped
                                        ? friend[1].avatar.cropped
                                        : friend[1].avatar,
                                  }}
                                  subTitle={friend[1].status}
                                  id={friend[0]}
                                  rightIcon={{ icon: 'more_vert', size: 24 }}
                                />
                              </ContextMenuTrigger>
                            );
                          })}
                          <ContextMenuItems
                            id="dashboardFriend"
                            data={[
                              {
                                id: 'dashboardFriend',
                                icon: 'remove_circle_outline',
                                text: t('button:remove.user'),
                                backgroundColor: theme.color.danger,
                                click: () => {
                                  props.modalOpen(true);
                                  props.modalType([
                                    'removeUser',
                                    activeDashboardId,
                                    handleAttendee,
                                    false,
                                  ]);
                                },
                              },
                            ]}
                          />
                        </StyledList>
                      ) : (
                        <StyledLoader>
                          <Animation
                            animation="empty"
                            animationHeight={rem(170)}
                          />
                          <StyledText
                            text={t('emptystate:attendees.title')}
                            align="center"
                            textColor="#fff"
                            textStyle="h6"
                          />
                        </StyledLoader>
                      )}
                    </StyledGridWrapper>

                    {/* DASHBOARD INVITES OUT */}
                    <StyledGridWrapper
                      columns="1fr"
                      parentId="pendingInvitesDashboard"
                    >
                      {dashboardOut ? (
                        Object.keys(dashboardOut).length > 0 ? (
                          <StyledList evenRadius autoWidth>
                            {Object.entries(dashboardOut)
                              .reverse()
                              .map(invite => {
                                return (
                                  // eslint-disable-next-line react/jsx-key
                                  <ContextMenuTrigger
                                    id="pendingDashboardFriendRequest"
                                    holdToDisplay={1}
                                  >
                                    <ListItem
                                      evenRadius
                                      onItemClick={() => {
                                        setClickedInviteHandler({
                                          id: invite[1]['invite'],
                                          userId: invite[1]['user'].id,
                                        });
                                      }}
                                      title={
                                        invite[1]['dashboard'] &&
                                        invite[1]['dashboard']['name']
                                      }
                                      avatar={{
                                        source:
                                          invite[1]['dashboard'] &&
                                          invite[1]['dashboard']['image']
                                            ? invite[1]['dashboard']['image']
                                            : '/resources/dashboard.svg',
                                      }}
                                      subTitle={
                                        invite[1]['user'] &&
                                        invite[1]['user'].name &&
                                        t('button:send.sendto') +
                                          invite[1]['user'].name
                                      }
                                      key={invite[0]}
                                      id={invite[0]}
                                      rightIcon={{
                                        icon: 'more_vert',
                                        size: 24,
                                      }}
                                    />
                                  </ContextMenuTrigger>
                                );
                              })}
                            <ContextMenuItems
                              id="pendingDashboardFriendRequest"
                              data={[
                                {
                                  id: 'pendingDashboardFriendRequest',
                                  icon: 'remove_circle_outline',
                                  text: t('button:undo.dashboardrequest'),
                                  click: () => {
                                    clickedInvite && clickedInvite.id
                                      ? undoRequest(
                                          clickedInvite,
                                          activeDashboardId,
                                          'dashboards'
                                        )
                                      : CustomToast.error(
                                          t('toasts:errors.default')
                                        );
                                  },
                                },
                              ]}
                            />
                          </StyledList>
                        ) : (
                          <StyledLoader>
                            <Animation
                              animation="empty"
                              animationHeight={rem(170)}
                            />
                            <StyledText
                              text={t('emptystate:requests.title')}
                              align="center"
                              textColor="#fff"
                              textStyle="h6"
                            />
                          </StyledLoader>
                        )
                      ) : (
                        <StyledLoader>
                          <Animation animation="loadingv2" />
                        </StyledLoader>
                      )}
                    </StyledGridWrapper>

                    {/* DASHBOARD INVITES IN */}
                    <StyledGridWrapper
                      columns="1fr"
                      parentId="incomingDashboardRequests"
                    >
                      {inviteInfo ? (
                        Object.keys(inviteInfo).length > 0 ? (
                          <StyledList evenRadius autoWidth>
                            {Object.entries(inviteInfo)
                              .reverse()
                              .map(invite => {
                                return (
                                  // eslint-disable-next-line react/jsx-key
                                  <ContextMenuTrigger
                                    id="incomingDashboardFriendRequest"
                                    holdToDisplay={1}
                                  >
                                    <ListItem
                                      evenRadius
                                      onItemClick={() => {
                                        setClickedInviteHandler({
                                          id: invite[1]['invite'],
                                          user: invite[1]['user'].id,
                                        });
                                      }}
                                      title={
                                        invite[1]['dashboard'] &&
                                        invite[1]['dashboard'].name
                                          ? invite[1]['dashboard'].name
                                          : 'StudentsPlus online dashboard'
                                      }
                                      avatar={{
                                        source:
                                          invite[1]['dashboard'] &&
                                          invite[1]['dashboard'].image &&
                                          invite[1]['dashboard'].image,
                                      }}
                                      subTitle={
                                        invite[1]['user'] &&
                                        'Invited by ' + invite[1]['user'].name
                                      }
                                      key={invite[0]}
                                      id={invite[0]}
                                      rightIcon={{
                                        icon: 'more_vert',
                                        size: 24,
                                      }}
                                    />
                                  </ContextMenuTrigger>
                                );
                              })}
                            <ContextMenuItems
                              id="incomingDashboardFriendRequest"
                              data={[
                                {
                                  id: 'incomingDashboardFriendRequest',
                                  icon: 'add',
                                  text: 'Accept request',
                                  click: () => {
                                    clickedInvite && clickedInvite.id
                                      ? acceptRequest(
                                          clickedInvite.user,
                                          clickedInvite.id,
                                          activeDashboardId,
                                          'dashboards',
                                          inviteInfo
                                        )
                                      : CustomToast.error(
                                          t('toasts:errors.default')
                                        );
                                  },
                                },
                                {
                                  id: 'incomingDashboardFriendRequest',
                                  icon: 'remove_circle_outline',
                                  text: 'Decline request',
                                  click: () => {
                                    clickedInvite && clickedInvite.id
                                      ? declineRequest(
                                          clickedInvite.user,
                                          clickedInvite.id,
                                          activeDashboardId,
                                          'dashboards'
                                        )
                                      : CustomToast.error(
                                          t('toasts:errors.default')
                                        );
                                  },
                                },
                              ]}
                            />
                          </StyledList>
                        ) : (
                          <StyledLoader>
                            <Animation
                              animation="empty"
                              animationHeight={rem(170)}
                            />
                            <StyledText
                              text="no pending requests, happy you!"
                              align="center"
                              textColor="#fff"
                              textStyle="h6"
                            />
                          </StyledLoader>
                        )
                      ) : (
                        <StyledLoader>
                          <Animation animation="loadingv2" />
                        </StyledLoader>
                      )}
                    </StyledGridWrapper>

                    {/* DASHBOARD USER SEARCH */}
                    <StyledGridWrapper
                      columns="1fr"
                      parentId="searchUserDashboard"
                    >
                      <StyledInnerSearchWrapper>
                        <SearchBar
                          innerSearch
                          activeDashboardId={activeDashboardId}
                          elementDimensions={elementDimensions}
                          backgroundColor={menuColor}
                          id="innerDashboardSearch"
                          dashboardInviteOnly
                        />
                      </StyledInnerSearchWrapper>
                    </StyledGridWrapper>
                  </StyledCard>
                </div>
                <div>
                  <StyledTitleWrapper>
                    <Text
                      align="left"
                      text="Dashboards"
                      textStyle="h6"
                      textColor="#fff"
                    />
                    <StyledIndicatorWrapper>
                      {maxExceededDashboards && (
                        <React.Fragment>
                          <StyledAnimation
                            animation="alert"
                            animationWidth={30}
                            animationHeight={30}
                            data-for="tooltipMenuDashboards"
                            data-tip=""
                          />
                          <StyledTooltip
                            id="tooltipMenuDashboards"
                            getContent={() => {
                              return (
                                <StyledTooltipWrapper>
                                  <StyledToolTipText
                                    text="Oops.. seems like you exceeded the number of dashboards"
                                    textStyle="h6"
                                    textColor="#fff"
                                    align="center"
                                  />
                                  <StyledToolTipAnimation
                                    animation="cry"
                                    animationWidth={rem(100)}
                                    animationHeight={rem(100)}
                                  />
                                  <Text
                                    text="You exceeded the max number of dashboards."
                                    textStyle="subtitle"
                                    textColor="#fff"
                                    align="center"
                                  />
                                </StyledTooltipWrapper>
                              );
                            }}
                          />
                        </React.Fragment>
                      )}
                      <StyledIndicatorLabelText
                        text={t('text:menu.labels.dashboard.total')}
                        textColor={theme.color.light[100]}
                        textStyle="description2"
                      />
                      <StyledIndicator>
                        <StyledIndicatorText
                          text={`${totalDashboards}/${maxDashboards}`}
                          textColor={theme.color.light[100]}
                          align="center"
                        />
                      </StyledIndicator>
                    </StyledIndicatorWrapper>
                  </StyledTitleWrapper>
                  <StyledCard>
                    <OverviewSelector activeDashboardId={activeDashboardId} />
                    <StyledButtonGroup justify="center">
                      <Button
                        id="addDashboard"
                        disabled={maxExceededDashboards ? true : false}
                        variant="icon"
                        onClick={() => {
                          props.modalOpen(true);
                          props.modalType(['adddashboard']);
                        }}
                        bgColor={theme.color.light[80]}
                      >
                        <Icon
                          padding={rem(12)}
                          icon={maxExceededDashboards ? 'block' : 'add'}
                          size={20}
                        />
                      </Button>
                    </StyledButtonGroup>
                  </StyledCard>
                </div>
              </ScrollSpyContent>
            </StyledInnerWrapper>
          ) : (
            <StyledLoader
              elementDimensions={elementDimensions}
              menuColor={menuColor}
            >
              <Animation animation="loadingv2" />
            </StyledLoader>
          )}
        </StyledWrapper>
      </Grid>
    </StyledMainWrapper>
  );
};

UserDashboardSettings.propTypes = {
  activeDashboardId: PropTypes.string,
  elementDimensions: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  menuColor: PropTypes.string,
};

UserDashboardSettings.defaultProps = {
  activeDashboardId: null,
  elementDimensions: null,
  menuColor: null,
};

const ConnectedMyComponent = connect(null, {
  modalOpen,
  modalType,
})(withMemo(UserDashboardSettings, ['elementDimensions']));

/** @component */
// eslint-disable-next-line react/display-name
export default forwardRef((props, ref) => (
  <ConnectedMyComponent {...props} myForwardedRef={ref} />
));
