import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Group from '../Group';
import Button from '../Button';
import Text from '../Text';
import Lottie from '../LottieControl';
import TextField from '../TextField';
import UploadAvatar from '../UploadAvatar';
import firebase, { createNewSubItem, updateSubTitle } from '../../firebase';
import theme, { rem } from '../../theme';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin-bottom: ${rem(10)};
`;

const StyledTextGroup = styled(Group)`
  flex-direction: column;
  margin-bottom: ${rem(40)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledContentWrapper = styled(Group)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

const StyledButtonGroup = styled(Group)`
  button {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: ${rem(20)};
    width: ${rem(350)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

/** todo */
const pasteImage = props => {
  const { images, typeId, text, userId, dashboardId, closeModals } = props;

  //The current userId
  let uuid = userId;
  if (!uuid) uuid = firebase.auth().currentUser.uid;

  //States
  const [loading, setLoading] = useState(true);
  const [saveData] = useState([]);
  const [maxLength] = useState(200);

  //Refs
  const imageRef = useRef();
  const textRef = useRef();

  //Translation
  const [t] = useTranslation('modal', 'button', 'textfield');

  useEffect(() => {
    //getDashboardData();
    setLoading(false);
    document.getElementById('globalModal').firstElementChild.style.height =
      'auto';
  }, []);

  /** Handle new item */
  const handleNewItem = () => {
    const saveImage = [];
    const image = imageRef.current ? imageRef.current.generateImage() : [];

    saveImage.push(image);
    createNewSubItem(
      firebase.auth().currentUser.uid,
      typeId,
      textRef.current.value.substring(0, maxLength),
      saveImage,
      'chats',
      'items',
      true
    );

    updateSubTitle('chats', typeId, '📷 Image');
  };

  /** Return Chat messages */
  return loading ? (
    <Lottie animation='loading' animationWidth={400} />
  ) : (
    <StyledWrapper>
      <StyledContentWrapper fullwidth>
        <StyledText
          align='center'
          text={t('modal:paste.image.title')}
          textColor='#fff'
          textStyle='h3'
          tag='h1'
        />
        <UploadAvatar
          rounded={false}
          type='overviewItem'
          itemType='dashboard'
          id='newPasteImage'
          width={150}
          height={100}
          padding={20}
          ref={imageRef}
          shadingColor='#000'
          currentImage={images}
          saveData={saveData}
          scale={1}
          rotate={0}
          cropRadius={75}
          backgroundColor={theme.color.light[20]}
          activeDashboardId={dashboardId}
          hasButton={false}
          hasRemoveButton={false}
        />

        <StyledTextGroup fullwidth>
          <Text
            align='left'
            text={t('textfield:paste.image.label')}
            textStyle='description'
            textColor='#fff'
          />
          <TextField
            placeholdercolor={theme.color.light[40]}
            backgroundcolor={theme.color.dark[40]}
            isFocused
            textcolor='#fff'
            inputId='modalTitle'
            placeholder={t('textfield:paste.image.placeholder')}
            borders={false}
            value={text ? text.substring(0, maxLength) : ''}
            ref={textRef}
          />
        </StyledTextGroup>

        <StyledButtonGroup fullwidth>
          <Button
            variant='contained'
            onClick={() => {
              handleNewItem();
              closeModals();
            }}
            bgColor={theme.color.success}
            text={{
              align: 'center',
              text: t('button:paste.text'),
              textColor: '#fff',
            }}
          />
          <Button
            variant='contained'
            text={{
              align: 'center',
              text: t('button:cancel.cancel'),
              textColor: '#fff',
            }}
            onClick={() => {
              closeModals();
            }}
            bgColor={theme.color.cancel}
          />
        </StyledButtonGroup>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

pasteImage.propTypes = {
  /** User Id */
  userId: PropTypes.string,
  /** Selected dashboard */
  dashboardId: PropTypes.string,
  /** Close function for the modal */
  closeModals: PropTypes.func,
  /** Current images in the clipboard */
  images: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /** Type Id */
  typeId: PropTypes.string,
  /** Possible text */
  text: PropTypes.string,
};

pasteImage.defaultProps = {
  userId: null,
  dashboardId: null,
  closeModals: null,
  images: null,
  typeId: null,
  text: null,
};

//export default connect(mapStateToProps)(Messages);
export default pasteImage;
