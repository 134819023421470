import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme, { rem } from '../../theme';
import Text from '../Text';
import Image from '../Image';
import Animation from '../LottieControl';

const StyledMainWrapper = styled.div``;

const StyledTooltip = styled(ReactTooltip)`
  ${theme.textStyle.body};
  ${({ top, left }) => `
    border-radius: ${rem(5)} !important;
    padding: ${rem(5)} ${rem(10)} '!important';
    top: ${top ? '0 !important' : ''};
    left: ${left ? '0 !important' : ''};
    text-align: center;
  `}
`;

const StyledTooltipWrapper = styled.div`
  ${({ width, height, noSpacing, fixed }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${noSpacing ? '0' : rem(20)};
    max-width: ${width ? '' : rem(300)};
    width: ${width ? width : ''};
    height: ${height ? height : ''};
    position: ${fixed ? 'fixed' : ''};
    left: ${fixed ? '0' : ''};
    right: ${fixed ? '0' : ''};
    top: ${fixed ? '0' : ''};
    bottom: ${fixed ? '0' : ''};
  `}
`;

const fixedPosition = {
  br: {
    top: rem(-55),
    right: rem(10),
  },
  brGhost: {
    bottom: rem(10),
    right: rem(10),
  },
};

const StyledToolTipText = styled(Text)`
  ${({ noSpacing }) => `
    margin-bottom: ${noSpacing ? '0' : rem(15)};
  `}
`;

const StyledToolTipAnimation = styled(Animation)`
  ${({ noSpacing }) => `
    margin-bottom: ${noSpacing ? '0' : rem(15)};
  `}
`;

const StyledHelpImage = styled(Image)`
  ${({ noSpacing }) => `
    margin-bottom: ${noSpacing ? '0' : rem(8)};
    max-height: ${rem(280)};
  `}
`;

/**
 * Returns a Tooltip component from the react-tooltip package
 * Including the correct theme font
 * @param {number} delayShow
 * @param {number} delayHide
 * @param {string} backgroundColor
 * @param {string} id
 * @param {object} content
 * @param {string} arrowColor
 * @param {string} place
 * @param {bool} clickable
 * @param {html} html
 * @param {bool} top
 * @param {bool} left
 * @param {bool} noSpacing
 * @param {bool} disable
 */
const Tooltip = props => {
  const {
    delayShow,
    delayHide,
    backgroundColor,
    id,
    content,
    arrowColor,
    place,
    clickable,
    html,
    top,
    left,
    noSpacing,
    disable,
  } = props;
  return (
    <StyledMainWrapper>
      {content ? (
        <StyledTooltip
          id={id}
          disable={disable}
          top={top}
          left={left}
          className='my-tooltip'
          delayShow={delayShow}
          delayHide={delayHide}
          delayUpdate={3}
          arrowColor={arrowColor}
          place={place}
          clickable={clickable}
          backgroundColor={backgroundColor}
          getContent={() => {
            return (
              content &&
              Object.keys(content).length > 0 && (
                <StyledTooltipWrapper
                  fixed={content.fixed}
                  width={content.width}
                  height={content.height}
                  noSpacing={noSpacing}
                >
                  {content.title && (
                    <StyledToolTipText
                      {...content.title}
                      noSpacing={noSpacing}
                    />
                  )}
                  {content.animation && (
                    <StyledToolTipAnimation
                      animation={content.animation}
                      animationWidth={
                        content.animationWidth
                          ? content.animationWidth
                          : rem(100)
                      }
                      animationHeight={
                        content.animationHeight
                          ? content.animationHeight
                          : rem(100)
                      }
                      noSpacing={noSpacing}
                    />
                  )}
                  {content.image && (
                    <StyledHelpImage {...content.image} noSpacing={noSpacing} />
                  )}
                  {content.text && content.text !== '' && (
                    <Text {...content.text} />
                  )}
                  {html && html}
                </StyledTooltipWrapper>
              )
            );
          }}
        />
      ) : (
        <StyledTooltip
          id={id}
          backgroundColor={backgroundColor}
          delayShow={delayShow}
        />
      )}
    </StyledMainWrapper>
  );
};

Tooltip.propTypes = {
  /** Delay of showing */
  delayShow: PropTypes.number,
  /** Delay of hiding */
  delayHide: PropTypes.number,
  /** Background color */
  backgroundColor: PropTypes.string,
  /** Custom Id */
  id: PropTypes.string,
  /** Custom content */
  content: PropTypes.oneOfType([PropTypes.object]),
  /** Arrow Color */
  arrowColor: PropTypes.string,
  /** Placement of the tooltip */
  place: PropTypes.string,
  /** Clickable */
  clickable: PropTypes.bool,
  /** HTML instead of text */
  html: PropTypes.any,
  /** Define a 0 top */
  top: PropTypes.bool,
  /** Define a 0 left */
  left: PropTypes.bool,
  /** noSpacing */
  noSpacing: PropTypes.bool,
  /** Disable tooltip */
  disable: PropTypes.bool,
};

Tooltip.defaultProps = {
  delayShow: null,
  delayHide: null,
  backgroundColor: null,
  id: null,
  content: null,
  arrowColor: null,
  place: null,
  clickable: null,
  html: null,
  top: null,
  left: null,
  noSpacing: null,
  disable: false,
};

export default Tooltip;
